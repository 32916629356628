<template>
	<div>
		<div class="row " >
			<div class="col-12 col-md-8 mx-auto">
				<txn-filter v-if="components.filter" :event="event" @search-clicked="searchClicked" :loading="isLoading.results"/>
			</div>
			<div class="col-12 mx-auto">
				<results v-if="components.results" :event="event" :transactions="transactions" :loginUser="loginUser" :loading="isLoading.results" :filter="filter"/>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import TxnFilter from './Filter';
	import Results from './Results';
	import { ElDivider, ElBadge, ElMessage } from "element-plus";
	import moment from 'moment';
	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			TxnFilter,
			Results,
			ElDivider, ElBadge,
			moment,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'info-fetched', 'loading-complete', ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
					vendors: [],
					stalls: [],
					transactions: [],
					components: { filter: false, list: false, results: false, },
					isLoading: { filter: false, list: false, results: false, },
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
				vendors: [],
				stalls: [],
				transactions: [],
				filter: [],
				components: { filter: true, list: false, results: true, },
				isLoading: { filter: true, list: false, results: false, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				// console.log(this.event.type);

				switch(this.event.type) {
					case 'event':
						this.components.filter = true;
						this.components.results = true;
						break;
					case 'clg-access':
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						url = this.$baseURL + '/Event/Details/';
						break;
					case 'reports':
					case 'admin':
						code = this.$route.params.code;
						url = this.$baseURL + '/Event/Details/';
						break;
					case 'vendor':
						code = u.id;
						url = this.$baseURL + '/Vendors/Details/';
						break;
					default:
						return ;
						break;
				}
				axios.get(
					url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							if(this.loginUser.loginType !== 'vendor') {
								this.setDefault('event');
								this.event = response.data.event;
								this.isLoading.event = false;
								// this.dataLoad();
								this.$emit('event-fetched', this.event);
								this.$emit('loading-complete');
							} else {
								this.vendor = response.data.vendor;
								this.event = response.data.event;
								this.vendors = [this.vendor];
								this.$emit('info-fetched', this.vendor);
								this.$emit('event-fetched', response.data.event);
								this.$emit('loading-complete');
							}
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
			arrangeFilters(filter) {
				this.filter = [];
				if(typeof filter.settings === 'undefined')	return ;
				for (const [key, value] of Object.entries(filter.settings)) {
					if(filter.settings[key] && Object.prototype.toString.call(filter[key]) === '[object Array]' && filter[key].length >0)	this.filter.push({ label: key, value: filter[key]});
					if(filter.settings[key] && (Object.prototype.toString.call(filter[key]) === '[object String]' || Object.prototype.toString.call(filter[key]) === '[object Number]') && filter[key] !== '')	this.filter.push({ label: key, value: filter[key]});
					// if(filter.settings[key] !== '' && filter.settings[key].length >0)	this.filter.push({ label: key, value: filter[key]});
				}
			},
			searchClicked(filter) {
				this.isLoading.results = true;
				this.transactions = [];
				this.arrangeFilters(filter);
				// return console.log(filter);
				if(filter.settings.dates)	{
					filter.dates[0] = moment(filter.dates2[0]).subtract(filter.dates2[0].getTimezoneOffset(), 'minutes');
					filter.dates[1] = moment(filter.dates2[1]).subtract(filter.dates2[1].getTimezoneOffset(), 'minutes');
					// filter.dates[0] = moment(filter.dates[0]).add(330, 'minutes').format('YYYY-MM-DD hh:mm:ss');
					// filter.dates[1] = moment(filter.dates[1]).add(330, 'minutes').format('YYYY-MM-DD hh:mm:ss');
					// filter.dates[0] = filter.dates[0].replace('T', '').replace('Z', '');
					// filter.dates[1] = filter.dates[1].replace('T', '').replace('Z', '');
				}
				// return console.log(filter);
				axios.post(
					this.$baseURL + '/Transactions/Filter',
					filter,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.transactions = response.data.transactions;
							this.isLoading.results = false;
							// window.scrollTo(0, this.$refs.resultsSection.offsetTop);
						}
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.results = false;
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');

			this.loadDetails();
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');

				this.loadDetails();
				this.transactions = [];
				this.filter = [];
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
