<template>

	<div class="row justify-content-center">
		<div class="col-12 m-auto d-md-none d-inline">
			<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
				<div class="card-body pr-0 pl-2">
					<div class=" h-50 ">
						<h5 class="text-white text-center">{{ getPeriodName() }}</h5>
						<canvas :id="headerChartID3"/>
					</div>
					<div class="" v-if="components.txnSummary2">
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<!--<h5 class="aml-border-radius-1 bg-white stats-title text-primary my-auto px-1 py-2 mx-auto aml-font-11 text-center">SALES</h5>-->
								<span class="badge bg-white aml-orange-2 border-0">SALES</span>
							</div>
							<div class="col-9 pr-0">
								<div v-if="summaryInfo.sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline" v-if="summaryInfo.no_nfc_sale.amount > 0">NFC </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class=""> ₹ </small>
											<!--<animated-number :value="1853"></animated-number>-->
											<strong class="">{{ $numFrm(summaryInfo.sale.amount, 'float', event.country)  }}</strong>
										</h5>
									</span>
									<!--<small class="col-2 text-right px-0 my-auto ml-2">(19,234)</small>-->
								</div>
								<div v-if="summaryInfo.no_nfc_sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline">POS </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class=""> ₹ </small>
											<strong class="">{{ $numFrm(summaryInfo.no_nfc_sale.amount, 'float', event.country) }}</strong>
										</h5>
									</span>
								</div>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.topup.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TOPUPS</span>
							</div>
							<div v-if="e" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class=""> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div v-if="!e" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">TOTAL </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class=""> ₹ </small>
									<small v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount" class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</small>
									<strong v-else class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div class="col-12 px-0" v-if="!e && (summaryInfo.topup.paidAmount !== summaryInfo.topup.amount)">
								<small class="d-inline">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class=""> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.return.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">RETURNS</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class=""> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.return.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center d-print-none mt-4" v-if="components.txnData">
		<!--last 5 TXN-->
		<div class="col-12">
			<div class="card card-chart">
				<div class="card-header">
					<h5 class="card-category">LATEST TRANSACTIONS</h5>
					<div v-if="isLoading.last5" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body aml-height-275">
					<div class="table-responsive">
						<table class="table aml-font-11 ">
							<thead class="text-primary">
								<th class="text-center">TYPE</th>
								<th class="text-left">STALL</th>
								<th class="text-center">DEVICE</th>
								<th class="text-right">AMOUNT</th>
								<th class="text-center" v-if="event.useNfc === 1">CARD</th>
								<th class="text-center">MODE</th>
								<th class="text-center">TIMESTAMP</th>
							</thead>
							<tbody>
								<tr v-for="txn in last5" :key="txn.id">
									<td class="text-center">{{ txn.type.toUpperCase() }}</td>
									<td class="text-left">{{ txn.name }}</td>
									<td class="text-center">{{ txn.device_printId }}</td>
									<td class="text-right">{{ $numFrm(txn.amount, 'float', event.country) }}</td>
									<td class="text-center" v-if="event.useNfc === 1">{{ parseInt(txn.card_id) === 0 ? '' : txn.card_id }}</td>
									<td class="text-center">{{ txn.invoice }}</td>
									<td class="text-center">{{ $formatDateTime(new Date(txn.txn_at), true) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
		AsyncWorldMap,
	} from "@/components";
	import {headerChart as headerChart3} from "../../dashboard/HeaderChart2";
	import {headerChart as headerChart4} from "../../dashboard/HeaderChart3";
	import headerChart from "../../dashboard/HeaderChart";
	import dataChart from "../../dashboard/dataChart";
	import axios from "axios";

	// import 'vue-loaders/dist/vue-loaders.css';
	// import {VueLoaders, VueLoadersBallBeat } from 'vue-loaders';

	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			AsyncWorldMap,
			// TopAttendance,
			// SkeletonLoaderVue,
			// VueLoaders, VueLoadersBallBeat
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType', '$armourxURL'],
		emits: ['info-fetched', 'summary-fetched', 'newDayClosedTurnOFF', 'loading-complete'],
		props: ['user', 'periodType', 'newDayClosed'],
		data() {
			return {
				v1: null,
				options: [ { value: 'Vue.js', label: 'JavaScript' }, ],

				loginUser: {},

				value: null,

				interval_fn: -1,

				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
					cards: {},
					cardsInfo: {
						unique: { amount: '-', count: '-' },
						activation: { amount: '-', count: '-' },
						perso: { amount: '-', count: '-' },
						avgTopup: { amount: '-', count: '-' },
						avgSale: { amount: '-', count: '-' },
						avgReturn: { amount: '-', count: '-' },
						devices: { amount: '-', count: '-' },
					},
					modeInfo: {
						topup: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
						},
						sale: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
						}
					},
					modeInfoAmounts: {
						topup: {
							cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
						},
						sale: {
							cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
						}
					},
					dashSettings: {
						add_activation_topup: 1,
						add_comp_topup: 1,
						add_coupon_topup: 1,
						auto_day_close: 0,
					},
					md: { v: [], d: [] },
					last5: [],
					accessGraph: [],
					accessCatg: [],
					accessGate: [],
					makerChecker: [],
					txnGraph: [],
					popularFoods: { v: [], d: [] },
					popularDrinks: { v: [], d: [] },
					popularOverall: { v: [], d: [] },
					popularStalls: { v: [], d: [] },

					dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
					weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

					eventLoad: false,
					isLoading: {
						summary: true, cards: true, last5: true, mode: true,
						accessGate: true, accessCatg: true, makerChecker: true, attendancePersonsInfo: true,
						attdWeeklyGraph: true, topAttendance: true,
					},

					components: {
						txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
						modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,

						attendanceGraph: false, attendanceSummary1: false, attendanceSummary2: false, attendanceWeeklyGraph:false,
					},
				},

				info: { name: '', country: 'india' },
				e: this.$route.query.e,
				summary: {},
				dashSettings: {
					add_activation_topup: 1,
					add_comp_topup: 1,
					add_coupon_topup: 1,
					auto_day_close: 0,
				},
				summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
				cards: {},
				cardsInfo: {
					unique: { amount: '-', count: '-' },
					activation: { amount: '-', count: '-' },
					perso: { amount: '-', count: '-' },
					avgTopup: { amount: '-', count: '-' },
					avgSale: { amount: '-', count: '-' },
					avgReturn: { amount: '-', count: '-' },
					devices: { amount: '-', count: '-' },
				},
				modeInfo: {
					topup: {
						cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
					},
					sale: {
						cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
					}
				},
				modeInfoAmounts: {
					topup: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					}
				},
				modeInfoCounts: {
					topup: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					}
				},
				md: { v: [], d: [] },
				last5: [],
				makerChecker: [],
				txnGraph: [],
				popularFoods: { v: [], d: [] },
				popularDrinks: { v: [], d: [] },
				popularOverall: { v: [], d: [] },
				popularStalls: { v: [], d: [] },

				components: {
					txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
					modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,

					attendanceGraph: false, attendanceSummary1: false, attendanceSummary2: false, attendanceWeeklyGraph:false,
					topAttendance: false,
				},

				dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
				weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

				eventLoad: false,
				isLoading: { summary: true, cards: true, last5: true, mode: true, accessGate: true, accessCatg: true, makerChecker: true, attendancePersonsInfo: true, attdWeeklyGraph: true, },

				headerChartID: "headerChart",
				headerChartID2: "headerChart2",
				headerChartID3: "headerChart3",
				headerChartID4: "headerChart4",
				foodChartID: "foodChart",
				drinkChartID: "drinkChart",
				overallChartID: "overallChart",
				stallChartID: "stallChart",

				txnChart: headerChart,
				modeChart: headerChart4,
				summaryChart: headerChart3,
				dataChart: dataChart,

				progress: 0,
				activeUsersChartID: "activeUsersChart",
				emailsCampaignChartID: "emailsCampaignChart",
				activeCountriesChartID: "activeCountriesChart",

				lastTxnId: 0,
				secondLastTxnId: 0,
			};
		},
		methods: {
			setLoading() {
				for (const [key, value] of Object.entries(this.isLoading)) {
					this.isLoading[key] = true;
				}
			},
			getPeriodName() {
				// if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0 && this.periodType.toLowerCase() === 'yesterday')
				// 	return this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].name;
				return this.periodType.toUpperCase();
			},
			dataLoad() {
				if(this.$isCheckEventType('fnb', this.event.type)) {
					this.loadData('cards');
					// this.loadData('summary');
					this.loadData('mode');
					this.loadData('last5');
					this.loadData('txnGraph');
					this.loadData('popularFoods');
					this.loadData('popularDrinks');
					this.loadData('popularOverall');
					this.loadData('popularStalls');
					if (this.event.useMakerChecker === 1) this.loadData('makerChecker');
				}
				if(this.event.useAccessx === 1) {
					if (this.$isCheckEventType('fnb', this.event.type)) {
						this.loadData('accessCatg');
						this.loadData('accessGate');
					}
				}
				if (this.$isCheckEventType('clg-access', this.event.type)) {
					this.loadData('collegeAccessInfo');
					this.loadData('accessGraph');
					this.loadData('topAttendance');
					this.loadData('attendancePersonsInfo'); this.isLoading['attdWeeklyGraph'] = true;
				}
			},
			loadDetails() {
				let code = this.loginUser.id;
				let url = '/IISER/Admin/Details/';
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						// console.log(response.data);
						this.$emit('loading-complete');
						this.$emit('info-fetched', response.data.info);
						this.updateLocal('summary', {data: [this.dashSettings] });
					} else {
						//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
			},
			updateLocal(dataType, d) {
				switch (dataType) {
					case 'summary':
						this[dataType] = d.data;
						this.setDefault('summaryInfo');

						d.data.forEach(info => {
							if(info.type_ !== 'activated_card_amount')
								this.summaryInfo[info.type_] = { amount: info.amount, count: info.count  };
							else this.cards.activated_card_amount = info.amount;
						});

						// debugger;
						this.summaryInfo.topup.paidAmount = this.summaryInfo.topup.amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_activation_topup ? 0 : this.cards.activated_card_amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_comp_topup ? 0 : this.modeInfoAmounts.topup.comp;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_coupon_topup ? 0 : this.modeInfoAmounts.topup.coupon;
						// this.dataLoad();
						break;
					case 'last5':
						this[dataType] = d.data;
						break;
				}
				this.isLoading[dataType] = false;
			},
			loadData(dataType, product = 'event') {
				this.isLoading[dataType] = true;
				let baseURL = '', id = 0;
				id = this.loginUser.id;
				axios.post(
					this.$baseURL + '/IISER/loadDashboardData/',
					{
						id, dataType, eventType: this.event.type,
						info: this.info, periodType: this.periodType,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) { this.updateLocal(dataType, response.data); }
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading[dataType] = false;
				});
			},
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}

				// if( this.$isCheckEventType('clg-access', this.event.type) && this.event.useAccessx === 0 ) this.setColgFilterDefaults();
			},
		},
		mounted() {
			this.e = this.$route.query.e;
			if(!this.e) {
				// setTimeout(this.loadDetails, 500);
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				// if(_.isEmpty(u))	return this.$router.push('/login');
				if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
			} else { this.e = atob(this.e); }

			this.loadDetails();
			this.interval_fn = setInterval(this.loadDetails, 60000);

			// this.setColgFilterDefaults();

			// setTimeout(this.loadData, 1000, 'summary');
			// this.loadData('summary');

			// setTimeout(this.loadData, 2000, 'cards');
			// this.loadData('cards');

			// setTimeout(this.loadData, 3000, 'mode');
			// this.loadData('cards');

			// setTimeout(this.loadData, 4000, 'last5');
			// this.loadData('cards');
		},
		watch:{
			$route (to, from){
				console.log('route change detected');
				// this.setLoading();
				clearInterval(this.interval_fn);
				this.loadDetails();
				this.interval_fn = setInterval(this.loadDetails, 60000);
				// this.event = {};
				this.setDefault();
			},
			newDayClosed (to, from) {
				if(to)	{
					this.setDefault();
					this.loadDetails();
					this.$emit('newDayClosedTurnOFF');
				}
			},
			// $periodType (to, from){
			// 	console.log('$periodType change detected');
			// 	this.loadDetails();
			// },
			periodType: {
				handler(a) {
					console.log('period change detected');
					this.loadDetails();
					// switch (a) {
					// 	case 'yesterday':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'current':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'all':
					// 		this.lastTxnId = 0;
					// 	default:
					// 		break;
					// }
				}
			},
			summaryInfo: {
				handler(a) {
					// console.log("summaryInfo update detect", a);
					this.$emit('summary-fetched', a);

					if(!a)  return;
					let summaryChartData = [
						+this.summaryInfo.sale.amount + +this.summaryInfo.no_nfc_sale.amount,
						this.summaryInfo.topup.amount,
						this.summaryInfo.return.amount,
					];
					this.summaryChart.createChart(this.headerChartID3, [ 'SALE', 'TOPUP', 'RETURN' ], summaryChartData);
					this.summaryChart.createChart(this.headerChartID2, [ 'SALE', 'TOPUP', 'RETURN' ], summaryChartData);
				},
				deep: true,
			},
			info: {
				handler(a) {
					// console.log("event update detect", a);
					this.$emit('info-fetched', a);
				},
				deep: true,
			},
			modeInfo: {
				handler(a) {
					this.modeChart.createChart(this.headerChartID4, this.md.v, this.md.d);
				},
				deep: true,
			},
			// txnGraph(to, from) {
			// 	this.txnChart.createChart(this.headerChartID, to);
			// },
			txnGraph: {
				handler(a) {
					// this.txnChart.createChart(this.headerChartID, a);
				},
				deep: true,
			},
			popularFoods: {
				handler(a) {
					this.dataChart.createChart(this.foodChartID, a.d, a.v);
				},
				deep: true,
			},
			popularDrinks: {
				handler(a) {
					this.dataChart.createChart(this.drinkChartID, a.d, a.v, '#ff6b59', '#FF7815');
				},
				deep: true,
			},
			popularOverall: {
				handler(a) {
					this.dataChart.createChart(this.overallChartID, a.d, a.v);
				},
				deep: true,
			},
			popularStalls: {
				handler(a) {
					this.dataChart.createChart(this.stallChartID, a.d, a.v, '#36b9ac', '#1398af');
				},
				deep: true,
			},
			collegeAccessInfo: {
				handler(a) {
					// console.log('collegeAccessInfo emit now');
					this.$emit('colgaccess-fetched', a);
					try {
						// console.log('chartId', this.headerChartID3);
						this.summaryChart.createChart(this.headerChartID2, ['PRESENT', 'ABSENT'], [a.present, a.absent]);
						this.summaryChart.createChart(this.headerChartID3, ['PRESENT', 'ABSENT'], [a.present, a.absent]);
					} catch(e) { console.log(e) }
				},
				deep: true,
			},
			accessGraph: {
				handler(a) {
					this.txnChart.createChart(this.headerChartID, a);
				},
				deep: true,
			},
			attendancePersons: {
				handler(a) {
					// this.setColgFilterDefaults();
				},
				deep: true,
			},
			colgFilters: {
				handler(a) {
					this.setAttdGraph();
				},
				deep: true,
			},
			clgCategories: {
				handler(a) {
					// this.setColgFilterDefaults();
					this.setAttdGraph();
				},
				deep: true,
			},

			// popularFoods(to, from) {
			// 	this.dataChart.createChart(this.foodChartID, to.d, to.v);
			// },
			// popularDrinks(to, from) {
			// 	this.dataChart.createChart(this.drinkChartID, to.d, to.v, '#ff6b59', '#FF7815');
			// },
			// popularOverall(to, from) {
			// 	this.dataChart.createChart(this.overallChartID, to.d, to.v);
			// },
			// popularStalls(to, from) {
			// 	this.dataChart.createChart(this.stallChartID, to.d, to.v, '#36b9ac', '#1398af');
			// },
		}
	};
</script>
