import Chart from "chart.js";

function _interpolation(a, b, factor) {
	var ret = [];

	for(var i = 0; i < Math.min(a.length, b.length); i++) {
		ret[i] = a[i] * (1 - factor) + b[i] * factor;
	}

	return ret;
}

/**
 * Generate colors sequence.
 *
 * @param {String} start Start color (any CSS format should work - both hex and names should be fine).
 * @param {String} end End colot.
 * @param {type} n Number of colors to generate.
 * @returns {Array} Array of generated colors.
 */
function getColors(start, end, n) {
	var ret = [];

	var a = new Color(start);
	var b = new Color(end);

	for(var i = 0; i < n; i++) {
		var color = new Color();
		// debugger;
		var rgb = _interpolation(a.rgbArray(), b.rgbArray(), i / (n - 1));
		color.setValues('rgb', [rgb[0], rgb[1], rgb[2]]);
		ret.push(color.hexString());
	}
	// debugger;
	return ret;
}

export const dataChart = {
  createChart(chartId, d = [ 10, 20 ], v = [ 'CASH', 'CARD' ], startColor = "#ffb955", endColor = "#CD754D") {
    let chartColor = "#FFFFFF";
    const ctx = document.getElementById(chartId).getContext("2d");
    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);

    let gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.24)");
    var chart_options_a = {
	    type: 'pie',
	    data: {
		    borderColor: '#fff',
		    backgroundColor: '#fff',
		    pointBorderColor: '#fff',
		    datasets: [{
			    data: d,
			    // backgroundColor: [ "#fb8f5e", "#fb8f5e", "#1398af", "#36b9ac", "#0f7a8c", "#1398af","#36b9ac", "#0f7a8c", "#1398af", "#36b9ac", "#0f7a8c", "#1398af", ],
			    backgroundColor: getColors(startColor, endColor, d.length),
			    label: 'Dataset 1'
		    }],
		    labels: v
	    },
	    options: {
		    responsive: false,
		    layout: {
			    padding: {
				    left: 10,
				    right: 20,
				    top: 0,
				    bottom: 0,
			    },
		    },
		    legend: {
			    position: 'left',
			    display: true,
		    },
		    title: {
			    display: false,
			    text: 'Chart.js Doughnut Chart'
		    },
		    animation: {
			    animateScale: true,
			    animateRotate: true
		    }
	    }
    };
    const myChart = new Chart(ctx, chart_options_a);
  },
	props: ['data']
};

export default dataChart;
