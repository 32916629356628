<template>
	<div>
		<div class="row " >
			<div class="mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-body">
						<div class="my-4">
							<el-input placeholder="Search" prefix-icon="el-icon-search" v-model="search"/>
							<small class="aml-text-gray-2 mt-1 mb-3">Search by NAME / BOOKING-ID / AWB / CARD ID </small> <br>
							<el-button class="aml-bg-teal-3 border-0 my-auto mt-2" type="primary"
									   :disabled="this.search.toString().length <=2"
									   :loading="isLoading.users" @click="searchData">Search</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 row mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">USERS ( {{ users.length }} )</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.users">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body row"  style="height: 550px; overflow:auto;">
						<div class="col-6 col-md-3 border-bottom" v-for="user in users">
							<el-card shadow="always" class="my-1">
								<div class="row" style="height: 275px !important;">
<!--									<el-image lazy :src="user.photo_url" v-if="event.id !== 1356" :preview-src-list="[user.photo_url, user.photo_url2]" class="col-5"/>-->
									<div class="col-12">
										<p class="text_bold font-weight-bold">{{ user.name }}</p>
										<p class="my-1">{{ user.booking_reference }}</p>
										<p class="my-1">{{ user.booking_reference2 }}</p>
										<p>{{ user.payment_status }} | <u>{{ user.tkt_issuance_mode }}</u></p>
										<p>{{ user.booking_status }} | <u>{{ user.reference }}</u></p>
										<p>{{ user.tkt_outlet_details }}</p>
										<p>
											<i class="far fa-address-card"/>
											<span v-if="user.data">
												<span class="mx-2">{{ user.data }}</span>
<!--												<i class="fa fa-times cursor-pointer" @click="whitelistReset"/>-->
												<el-button class="" icon="fa fa-times" circle :loading="isLoading.users" @click="whitelistReset(user)"/>
											</span>
										</p>
										<div>
											<div><h6 class="d-inline"><small>TOTAL PAX: {{ user.pax }}</small></h6></div>
											<div v-if="parseInt(user.tkt_info) > 0"><h6 class="d-inline"><small>PAX: {{ user.tkt_qty }}</small> <el-divider direction="vertical"/><small>COMPAX: {{ user.tkt_info }}</small></h6></div>
										</div>
										<p class="my-1 cursor-pointer d-inline" @click="whitelistResetLogsLoad(user)"><i class="fas fa-history"/> History</p>
										<p class="my-2 cursor-pointer d-inline ml-1" @click="whitelistItemList(user)"><i class="fas fa-utensils"/> Category</p>
									</div>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
			<span class="aml-dialog">
				<el-dialog :title="'LOGS FOR: ' + u_.booking_reference " v-model="userLogsDialog">
					<el-card shadow="always" class="my-1">
						<div class="row col-md-6 col-12 mx-auto" style="height: 250px !important;">
							<el-image lazy :src="u_.photo_url" :preview-src-list="[u_.photo_url, u_.photo_url2]" class="col-5" v-if="event.id !== 1356"/>
							<div class="col-7">
								<p class="text_bold font-weight-bold">{{ u_.name }}</p>
								<p class="my-1">{{ u_.booking_reference }}</p>
								<p class="my-1">{{ u_.booking_reference2 }}</p>
								<p>{{ u_.payment_status }} | <u>{{ u_.tkt_issuance_mode }}</u></p>
								<p>
									<i class="far fa-address-card"/>
									<span class="mx-2">{{ u_.data }}</span>
								</p>
								<div v-if="event.id === 1356">
									<div><h5 class="d-inline"><small>TOTAL PAX: {{ u_.pax }}</small></h5></div>
									<div v-if="parseInt(u_.tkt_info) > 0"><h5 class="d-inline"><small>PAX: {{ u_.tkt_qty }}</small> <el-divider direction="vertical"/><small>COMPAX: {{ u_.tkt_info }}</small></h5></div>
								</div>
							</div>
						</div>
					</el-card>

					<div class="row">
						<el-card shadow="always" class="my-1 col-6 col-md-3 ">
							<div class="row">
								<h6>ISSUANCE LOGS</h6>
								<div class="table-responsive pb-0">
									<table class="table aml-font-11">
										<thead class="text-primary">
											<th class="text-center">CARD IDs</th>
<!--											<th class="text-center">TIMESTAMP</th>-->
										</thead>
										<tbody>
											<tr v-if="u_.data">
												<td class="text-center cursor-pointer" @click="accessxLogsLoad(u_.data)">
													<u>{{ u_.data }}</u><br>{{ this.displaydt(u_.issued_at) }}
													<br><span class="badge badge-info">CURRENT</span>
														<br><i class="fas fa-chevron-right"/>
												</td>
											</tr>
											<tr v-for="ul in userLogs" :key="ul.id">
												<td class="text-center cursor-pointer" @click="accessxLogsLoad(ul.oldCardId)">
													<u>{{ ul.oldCardId }}</u><br>{{ this.displaydt(ul.timestampUnix) }}
													<br><span class="badge badge-primary text-white">REMOVED</span>
													<br><i class="fas fa-chevron-right"/>
												</td>
<!--												<td class="text-center">{{ this.displaydt(ul.timestampUnix) }}</td>-->
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</el-card>

						<el-card shadow="always" class="my-1 col-md-9 col-12">
							<div class="row">
								<h6>ACCESSX LOGS | <u>{{ cardAccessx }}</u></h6>
								<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
									<span class="badge badge-primary" v-if="isLoading.users">
										<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
									</span>
								</div>
								<el-table class="w-100 aml-font-11 aml-devices-table" :hover="true" :data="userAccessxLogs" >
									<el-table-column label="#" type="index" width="40"/>
									<el-table-column label="CARD ID" sortable prop="card_id"/>
									<el-table-column label="FACTORY ID" sortable prop="factory_id"/>
									<el-table-column label="CATEGORY" sortable prop="category_name"/>
									<el-table-column label="GATE" sortable prop="gate_name"/>
									<el-table-column label="DEVICE" sortable prop="device_print_id"/>
<!--									<el-table-column label="FACTORY ID" sortable prop="type"/>-->
									<el-table-column label="DIRECTION" sortable prop="direction"/>
<!--									<el-table-column label="FACTORY ID" sortable prop="validate_type"/>-->
									<el-table-column label="TIMESTAMP" sortable prop="timestamp">
										<template #default="scope">
											<span>{{ displaydt(scope.row.timestamp) }}</span>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-card>
					</div>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userLogsDialog = false" v-if="!userLoading">Cancel</el-button>
<!--						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>-->
						</span>
					</template>
				</el-dialog>
			</span>
			<span class="aml-dialog">
				<el-dialog :title="'ITEM LIST FOR: ' + u_.booking_reference " v-model="userItemListDialog">
					<el-card shadow="always" class="my-1">
						<div class="row col-md-6 col-12 mx-auto" style="height: 250px !important;">
							<el-image lazy :src="u_.photo_url" :preview-src-list="[u_.photo_url, u_.photo_url2]" class="col-5"/>
							<div class="col-7">
								<p class="text_bold font-weight-bold">{{ u_.name }}</p>
								<p class="my-1">{{ u_.mobile }} | {{ u_.reference }}</p>
								<p class="my-1">{{ u_.booking_reference }}</p>
								<p class="my-1">{{ u_.booking_reference2 }}</p>
								<p>
									<i class="far fa-address-card"/>
									<span class="mx-2">{{ u_.data }}</span>
								</p>
							</div>
						</div>
					</el-card>

					<div class="row">
						<el-card shadow="always" class="my-1 col-12">
							<div class="row">
								<h6>ITEM CATEGORY LIST</h6>
								<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
									<span class="badge badge-primary" v-if="isLoading.users">
										<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
									</span>
								</div>
								<el-table class="w-100 aml-font-11 aml-devices-table" :hover="true" :data="userItemList" >
									<el-table-column label="#" type="index" width="40"/>
									<el-table-column label="CATEGORY" sortable prop="category_name"/>
								</el-table>
							</div>
						</el-card>
					</div>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userItemListDialog = false" v-if="!userLoading">Cancel</el-button>
<!--						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>-->
						</span>
					</template>
				</el-dialog>
			</span>

			<span class="aml-dialog">
				<el-dialog :title="'Reset Card of: ' + u_.booking_reference " v-model="userDialog">
					<el-form :model="u_" class="">
						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-right my-auto">PASSWORD</span>
								<el-input class="col-md-8 col-12" v-model="u_.pass"/>
							</span>
						</el-form-item>
					</el-form>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userDialog = false" v-if="!userLoading">Cancel</el-button>
						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>
						</span>
					</template>
				</el-dialog>
			</span>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import {
		ElDivider,
		ElBadge,
		ElMessage,
		ElImage,
		ElCard,
		ElInput,
		ElButton,
		ElTag,
		ElMessageBox,
		ElDialog, ElForm, ElFormItem, ElTable, ElTableColumn,
	} from "element-plus";
	import moment from "moment/moment";

	export default {
		components: {
			ElTable,
			ElFormItem,
			ElForm,
			ElDialog,
			ElTag,
			ElButton,
			ElInput,
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElImage, ElCard,
			moment,
			ElDivider, ElBadge, ElMessageBox, ElMessage,ElTableColumn,
		},
		inject: ['$baseURL', '$accessxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					users: [],
					components: { users: true, },
					isLoading: { users: false, },
				},
				search: '',
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				users: [],
				users_: [],
				userLoading: false,
				u_: {},
				userDialog: false,
				userLogs: [],
				userItemList: [],
				userLogsDialog: false,
				userItemListDialog: false,
				userAccessxLogs: [],
				cardAccessx: 0,

				components: { users: true, event: true, },
				isLoading: { users: false, event: true, },
			};
		},
		methods: {
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('DD-MM-YYYY HH:mm:ss') : ''; },
			loadData() {
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/List/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.users = response.data.users;
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			whitelistItemList(user) {
				this.u_ = user;
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/ItemList/',
					{ params: { code: user.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// debugger;
							this.userItemList = response.data.itemList;
							this.userItemListDialog = true;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			whitelistResetLogsLoad(user) {
				this.u_ = user;
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/Issuance/Logs/',
					{ params: { wid: user.id, eventId: code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// debugger;
							this.userLogs = response.data.issuanceLogs.reverse();
							this.accessxLogsLoad(this.u_.data);
							this.userLogsDialog = true;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			accessxLogsLoad(cardId) {
				// debugger;
				this.cardAccessx = cardId;
				this.userAccessxLogs = [];
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/AccessX/Logs/',
					{ params: { cardId, eventId: code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.userAccessxLogs = response.data.entryLogs.reverse();
							this.isLoading.users = false;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			whitelistReset(user) {
				this.u_ = user;
				// this.userLoading = true;
				this.userDialog = true;

			},
			wResetCall() {
				this.userLoading = true;
				axios.post(
					this.$accessxURL + '/Whitelist/Issue/Reset/',
					{ id: this.u_.id, pass: this.u_.pass, },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						if(response.data.success) {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "done", type: 'info', grouping: true, duration: 5000,  });
							this.userDialog = false;
							this.u_.pass = '';

							if(this.u_.booking_reference && this.u_.booking_reference !== '' ) {
								this.search = this.u_.booking_reference;
								this.searchData();
							}
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000,  });
						}
						this.userLoading = false;
						// this.$emit('loading-complete');
					})
					.catch(e => { console.log(e); this.userLoading = false; ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>", type: 'danger', grouping: true, duration: 5000,  }); });
			},
			searchData() {
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/Search/',
					{ params: { code, search: this.search, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.users = response.data.users;
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('event');
						this.event = response.data.event;
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.$emit('loading-complete');
						// this.loadData();
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
