<template>
	<div class="col-6">
		<div class="row justify-content-center">
			<div class="col-12 m-auto d-md-none d-inline">
				<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
					<div class="card-body pr-0 pl-2" v-if="!loading">
						<h5 class="text-white text-center">{{ this.analytics.period.toUpperCase() }}</h5>
						<div class="">
							<div class="col-md text-right row py-1 pr-0">
								<div class="col-3 px-0 my-auto text-left">
									<span class="badge bg-white aml-orange-2 border-0">Pending</span>
								</div>
								<div class="col-9 px-0">
<!--									<small class="d-inline" v-if="+analytics.bills - +analytics.payments">PAID </small>-->
									<h5 class="info-title m-0 d-inline col-10 text-right px-0">
										<small class=""> ₹ </small>
										<strong class="">{{ $numFrm(+analytics.bills - +analytics.payments, 'float') }}</strong>
									</h5>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import { ElDivider, ElBadge } from 'element-plus';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge
		},
		inject: ['$baseURL', '$armourxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		props: [ 'analytics', 'loading' ],
		data() {
			return {
				defaultInfo: {
				},
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
		},
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
