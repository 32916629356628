<template>
	<div style="min-height: 2300px;">
		<div class="row " >
			<div class="col-12 col-md-8">
				<vendor-table v-if="components.vendors" @stall-add="stallAdded" :event="event" :vendors="vendors" :isLoading="isLoading.vendors"/>
				<stall-table v-if="components.stalls" :event="event" @devices-updated="devicesUpdated" :devices="devices" :stalls="stalls" :isLoading="isLoading.stalls"/>
				<accessx-tables v-if="event.useAccessx" :event="event" :categories="categories" :gatesmasters="gatesmasters" :gates="gates" :isLoading="isLoading.accessx"/>
        		<live-link-access-tables v-if="dashSettings.useLivelinkaccess === 1 && this.loginUser.loginType === 'admin'" :event="event" :logs="livelinkaccesslogs" :isLoading="isLoading.livelinkaccesslogs"/>

<!--        <link-table v-if="components.armourxLinks" :event="event" :links="armourxlinks" :isLoading="isLoading.armourxlinks"/>-->
			</div>

			<div class="col-12 col-md-4">
				<event-details
					v-if="components.eventDetails"
					:event="event"
					:dashSettings="dashSettings"
					:bankCredentials="bankCredentials"
					:is-loading="isLoading.event"
					@event-updated="eventUpdated"
				/>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
  // import axios, * as others from 'axios';
  // import * as axios from 'axios';

  import VendorTable from './VendorTables';
	import StallTable from './StallTables';
	import LinkTable from './armourx/LinkTables';
	import AccessxTables from './AccessxTables.vue';
	import EventDetails from './EventDetails';
	import { ElDivider,  ElMessage } from "element-plus";
	import LiveLinkAccessTables from "@/views/dashboard/config/LiveLinkAccessTables.vue";
	import moment from "moment/moment";


	export default {
		components: {
			LiveLinkAccessTables,
      AccessxTables,
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			VendorTable,
			StallTable,
			EventDetails,
			LinkTable,
			ElDivider,
			moment,
		},
		inject: ['$baseURL', '$armourxURL', '$accessxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'info-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
						event: { name: '', country: 'india', type: '' },
					summary: {},
					summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
					vendors: [],
					stalls: [],
					devices: [],
				  categories: [],
				  gatesmasters: [],
				  gates: [],
					armourxlinks: [],
					components: { vendors: false, stalls: false, eventDetails: false, armourxLinks: false, livelinkaccesslogs: false },
					isLoading: { vendors: true, stalls: true, event: true, armourxlinks: true, accessx: true, livelinkaccesslogs: true, },
					dashSettings: {
						addActivationTopup: 1,
						addCouponTopup: 1,
						addCompTopup: 1,
						autoDayClose: 0,
						useLivelinkaccess: 0,
						livelinkAccess: "",
					},
					bankCredentials: {},
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
				vendors: [],
				devices: [],
				stalls: [],
			categories: [],
			gatesmasters: [],
			gates: [],
			armourxlinks: [],
			livelinkaccesslogs: [],
			components: { vendors: false, stalls: false, eventDetails: false, armourxLinks: false, livelinkaccesslogs: false },
			isLoading: { vendors: true, stalls: true, event: true, armourxlinks: true, accessx: true, livelinkaccesslogs: true, },
			dashSettings: {
				addActivationTopup: 1,
				addCouponTopup: 1,
				addCompTopup: 1,
				autoDayClose: 0,
				useLivelinkaccess: 0,
				livelinkAccess: "",
			},
			bankCredentials: {},
			};
		},
		methods: {
			setComponentsDisplay () {
				this.setDefault('components');

				switch(this.event.type) {
					case 'fnb':
					case 'event':
						this.components.vendors = true;
						this.components.stalls = true;
						this.components.eventDetails = true;
						this.components.livelinkaccesslogs = this.dashSettings.useLivelinkaccess === 1 && this.loginUser.type === 'admin' && this.loginUser.id === 1480
						break;
					case 'clg-access':
						break;
					case 'armourx':
						this.components.armourxLinks = true;
						break;
				}
				this.$emit('loading-complete');
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			devicesUpdated(devices) { console.log('device-added', devices) },
			eventUpdated(event, dashSettings, bankCredentials) {
				if(!event)  return;
				this.event = event;
				this.$emit('event-fetched', event);
				if(dashSettings) this.dashSettings = dashSettings;
				if(bankCredentials) this.bankCredentials = bankCredentials;
			},
			dataLoad() {
				switch( this.event.type ) {
					case 'fnb':
					case 'event':
						switch (this.loginUser.loginType) {
							case 'admin':
								if(this.dashSettings.useLivelinkaccess === 1)	this.loadlivelinklogData();
							case 'event':
							case 'operator':
								this.loadConfigData('Vendors');
								if(this.event.useAccessx) {
								  this.loadAccessxData('Categories');
								  this.loadAccessxData('GatesMasters');
								  this.loadAccessxData('Gates');
								}
							case 'vendor':
								this.loadConfigData('Stalls');
								this.loadDevices();
								break;
							default:
								return ;
								break;
						}
						break;
					case 'armourx':
						this.loadConfigData('armourxLinks');
						break;
					case 'clg-access':
					default:
						break;
				}
			},
			loadData(dataType) {
				this.isLoading[dataType] = true;
				axios.get(
					this.$baseURL + '/Vendors/ViewAll',
					{
						id: this.$route.params.code, dataType, eventType: this.event.type,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) { this.updateLocal(dataType, response.data); }
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});

			},
			loadConfigData(dataType) {
				if(!dataType)   return;
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
					case 'vendor':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}

				switch (this.event.type) {
					case 'event':
					case 'fnb':
						switch (u.loginType) {
							case 'event':
							case 'operator':
							case 'admin':
								url = this.$baseURL + '/' + dataType + '/event-wise/';
								break;
							case 'vendor':
								url = this.$baseURL + '/' + dataType + '/vendor-wise/';
								break;
							default:
								return ;
								break;
						}
						break;
					case 'clg-access':
						break;
					case 'armourx':
						url = this.$armourxURL + '/' + dataType + '/event-wise/';
						break;
					default:
						break;
				}

				axios.get(
					url,
					{ params: { code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						this.setDefault(dataType.toLowerCase());
						this[dataType.toLowerCase()] = response.data[dataType.toLowerCase()];
						this.isLoading[dataType.toLowerCase()] = false;

						// if(dataType === 'Vendors') {
						// 	this.setDefault('vendorsFilter');
						// 	response.data.vendors.forEach(vendor => { this.vendorsFilter.push({ text: vendor.name, value: vendor.name }) });
						// }
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});
			},
			loadAccessxData(dataType) {
				if(!dataType)   return;
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
					case 'vendor':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}

				axios.get(
					this.$accessxURL + '/' + dataType + '/List/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log('accessx-data', dataType, response);
						this[dataType.toLowerCase()] = response.data[dataType.toLowerCase()];
						// debugger;
						if(dataType.toLowerCase() === 'gates' ) this.isLoading.accessx = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
					});
			},
			loadlivelinklogData() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
					case 'vendor':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}

				axios.get(
					this.$baseURL + '/Event/LiveLinkAccessLogs',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.livelinkaccesslogs = response.data.livelinkaccesslogs;
						this.isLoading.livelinkaccesslogs = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
					});
			},
			loadDevices() {
				let u = this.loginUser, code = 0;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Devices/List/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.devices = response.data.devices;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
					});
			},
			loadDetails() {
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
						url = this.$baseURL + '/Event/Details/';
						code = u.id;
						break;
					case 'admin':
						url = this.$baseURL + '/Event/Details/';
						code = this.$route.params.code;
						break;
					case 'operator':
						url = '/Users/Details/';
						code = u.id;
						break;
					case 'vendor':
						code = u.id;
						url = this.$baseURL + '/Vendors/Details/';
						break;
					default:
						return ;
						break;
				}
				axios.get(
					url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							if (this.loginUser.loginType !== 'vendor') {
								this.setDefault('event');
								this.event = response.data.event;
								if (response.data.dashSettings) this.dashSettings = response.data.dashSettings;
								if (response.data.bankCredentials) this.bankCredentials = response.data.bankCredentials;
								// console.log('bankCredentials', this.bankCredentials);
								this.isLoading.event = false;
								this.$emit('event-fetched', this.event);
							} else {
								this.vendor = response.data.vendor;
								this.event = response.data.event;
								this.isLoading.event = false;
								this.isLoading.vendors = false;
								this.vendors = [this.vendor];
								this.$emit('info-fetched', this.vendor);
								this.$emit('event-fetched', response.data.event);
								// this.$emit('loading-complete');
							}
							this.dataLoad();
							this.setComponentsDisplay();
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
					});
			},
			stallAdded(stall) {
				this.stalls.push(stall);
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('YYYY-MM-DD HH:mm:ss') : ''; },
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			// setTimeout(this.loadDetails, 500);
			this.loadDetails();
			this.vendors = [];
			this.stalls = [];
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				// setTimeout(this.loadDetails, 500);
				this.loadDetails();
				this.vendors = [];
				this.stalls = [];
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
