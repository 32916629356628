<template>
	<div class="card card-stats card-raised"  v-if="stalls.filter(data => (data.type === 'sale' || data.type === 'topup' )).length > 0">
		<div class="card-header">
			<h5 class="card-category">STALLS ( {{ stalls.filter(data => data.type === 'sale' || data.type === 'topup').length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Stall" size="mini" prefix-icon="el-icon-search" v-model="search" />
			<el-table class="w-100 aml-font-12"
					  :data="stalls.filter(data => (data.type === 'sale' || data.type === 'topup') && (!search || data.name.toLowerCase().includes(search.toLowerCase()) || data.vendor_name.toLowerCase().includes(search.toLowerCase())))"
					  :height="stalls.length <= 3 ? '200' : stalls.length > 10 ? '400' : '300'">
				<el-table-column label="#" prop="id" width="75" />
				<el-table-column sortable label="VENDOR" prop="vendor_name" />
				<el-table-column sortable label="STALL" prop="name" />
				<el-table-column label="DEVICES" align="center" width="125">
					<template #default="scope">
						<span class="col">{{ scope.row.devices_count }}</span>
						<span class="col cursor-pointer" @click="deviceOpen(scope.row)"><i class="fas fa-plus" /></span>
					</template>
				</el-table-column>
				<el-table-column label="MENU" align="center" width="80">
					<template #default="scope">
						<router-link class="d-inline" v-if="scope.row.type === 'sale' || scope.row.type === 'sale_menu'"
									 :to="(loginUser.loginType !== 'vendor' ? ('/Menu/' + event.id + '/' + scope.row.id) : ('/Vendor/Menu/' + scope.row.id))">
							<i class="fas fa-utensils text-black-50" />
						</router-link>
						<!--						<el-divider class="d-inline mx-1" v-if="scope.row.type==='sale' || scope.row.type==='sale_menu'" type="vertical"/>-->
						<!--						<router-link class="d-inline" v-if="scope.row.type==='sale' || scope.row.type==='sale_menu'" :to="'/Inventory/' + event.id + '/' + scope.row.id "> <i class="fas fa-upload text-black-50"></i> </router-link>-->
					</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="stallOpen(scope.row)"> <i class="fas fa-edit" />
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<div class="card card-stats card-raised aml-bg-inventory" v-if="stalls.filter(data => (data.type === 'inventory' || data.type === 'stockmaster' )).length > 0">
		<div class="card-header">
			<h5 class="card-category">STOCKROOMS ( {{ stalls.filter(data => data.type === 'inventory' || data.type === 'stockmaster' ).length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Stockroom" size="mini" prefix-icon="el-icon-search"
				v-model="search_inventory" />
			<el-table class="w-100 aml-font-12 aml-inventory-table"
				:data="stalls.filter(data => (data.type === 'inventory' || data.type === 'stockmaster') && (!search_inventory || data.name.toLowerCase().includes(search_inventory.toLowerCase()) || data.vendor_name.toLowerCase().includes(search_inventory.toLowerCase())))">
				<el-table-column label="#" prop="id" width="75" />
				<el-table-column sortable label="VENDOR" prop="vendor_name" />
				<el-table-column sortable label="STALL" prop="name" />
				<el-table-column label="DEVICES" align="center" width="125">
					<template #default="scope">
						<span class="col">{{ scope.row.devices_count }}</span>
						<span class="col cursor-pointer" @click="deviceOpen(scope.row)"><i class="fas fa-plus" /></span>
					</template>
				</el-table-column>
				<el-table-column label="MENU" align="center" width="80">
					<template #default="scope">
						<router-link class="d-inline"
							:to="(loginUser.loginType !== 'vendor' ? ('/Menu/' + event.id + '/' + scope.row.id) : ('/Vendor/Menu/' + scope.row.id))">
							<i class="fas fa-utensils text-black-50" />
						</router-link>
					</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="stallOpen(scope.row)"> <i class="fas fa-edit" />
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<div class="card card-stats card-raised aml-bg-table" v-if="stalls.filter(data => (data.type === 'tables') ).length > 0">
		<div class="card-header">
			<h5 class="card-category">TABLES ( {{ stalls.filter(data => data.type === 'tables').length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Table Stalls" size="mini" prefix-icon="el-icon-search"
				v-model="search_table" />
			<el-table class="w-100 aml-font-12 aml-table-table"
				:data="stalls.filter(data => (data.type === 'tables') && (!search_table || data.name.toLowerCase().includes(search_table.toLowerCase()) || data.vendor_name.toLowerCase().includes(search_table.toLowerCase())))">
				<el-table-column label="#" prop="id" width="75" />
				<el-table-column sortable label="VENDOR" prop="vendor_name" />
				<el-table-column sortable label="STALL" prop="name" />
				<!--				<el-table-column label="DEVICES" align="center" width="125">-->
				<!--					<template #default="scope">-->
				<!--						<span class="col">{{ scope.row.devices_count }}</span>-->
				<!--						<span class="col cursor-pointer" @click="deviceOpen(scope.row)"><i class="fas fa-plus"/></span>-->
				<!--					</template>-->
				<!--				</el-table-column>-->
				<el-table-column label="MENU" align="center" width="80">
					<template #default="scope">
						<router-link class="d-inline"
							:to="(loginUser.loginType !== 'vendor' ? ('/Menu/' + event.id + '/' + scope.row.id) : ('/Vendor/Menu/' + scope.row.id))">
							<i class="fas fa-utensils text-black-50" />
						</router-link>
					</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="stallOpen(scope.row)"> <i class="fas fa-edit" />
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<div class="card card-stats card-raised aml-bg-pass" v-if="stalls.filter(data => data.type === 'pass').length > 0">
		<div class="card-header">
			<h5 class="card-category">PASSES ( {{ stalls.filter(data => data.type === 'pass').length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Table Stalls" size="mini" prefix-icon="el-icon-search"
				v-model="search_table" />
			<el-table class="w-100 aml-font-12"
				:data="stalls.filter(data => (data.type === 'pass') && (!search_table || data.name.toLowerCase().includes(search_table.toLowerCase()) || data.vendor_name.toLowerCase().includes(search_table.toLowerCase())))">
				<el-table-column label="#" prop="id" width="75" />
				<el-table-column sortable label="VENDOR" prop="vendor_name" />
				<el-table-column sortable label="STALL" prop="name" />
				<!--				<el-table-column label="DEVICES" align="center" width="125">-->
				<!--					<template #default="scope">-->
				<!--						<span class="col">{{ scope.row.devices_count }}</span>-->
				<!--						<span class="col cursor-pointer" @click="deviceOpen(scope.row)"><i class="fas fa-plus"/></span>-->
				<!--					</template>-->
				<!--				</el-table-column>-->
				<el-table-column label="MENU" align="center" width="80">
					<template #default="scope">
						<router-link class="d-inline"
							:to="(loginUser.loginType !== 'vendor' ? ('/Menu/' + event.id + '/' + scope.row.id) : ('/Vendor/Menu/' + scope.row.id))">
							<i class="fas fa-utensils text-black-50" />
						</router-link>
					</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="stallOpen(scope.row)"> <i class="fas fa-edit" />
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<el-divider class="aml-bg-accessx" v-if="stalls.filter(data => data.type === 'accessx').length > 0">ACCESSX</el-divider>
	<div class="card card-stats card-raised aml-bg-accessx" v-if="stalls.filter(data => data.type === 'accessx').length > 0">
		<div class="card-header">
			<h5 class="card-category">AccessX Stalls ( {{ stalls.filter(data => data.type === 'accessx').length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search AccessX Stalls" size="mini" prefix-icon="el-icon-search"
					  v-model="search_accessx" />
			<el-table class="w-100 aml-font-12 aml-accessx-table"
					  :data="stalls.filter(data => (data.type === 'accessx') && (!search_accessx || data.name.toLowerCase().includes(search_accessx.toLowerCase()) || data.vendor_name.toLowerCase().includes(search_accessx.toLowerCase())))">
				<el-table-column label="#" prop="id" width="75" />
				<el-table-column sortable label="VENDOR" prop="vendor_name" />
				<el-table-column sortable label="STALL" prop="name" />
				<el-table-column label="DEVICES" align="center" width="125">
					<template #default="scope">
						<span class="col">{{ scope.row.devices_count }}</span>
						<span class="col cursor-pointer" @click="deviceOpen(scope.row)"><i class="fas fa-plus"/></span>
					</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="stallOpen(scope.row)"> <i class="fas fa-edit" /></span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<span class="aml-dialog">
		<el-dialog title="Edit Stall" v-model="stallDialog">
			<span class="mt-3 d-block">
				<el-form :model="stall">
					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Vendor</span>
							<el-input class="col-8" v-model="stall.vendor_name" disabled autocomplete="off" />
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Stall Name</span>
							<el-input class="col-8" v-model="stall.name" autocomplete="off" />
						</span>
					</el-form-item>

					<!-- <span class="d-inline col-1 px-0 my-auto text-center position-relative"> -->
					<!-- <el-form-item class="col my-auto"> -->
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Stall Banner
						</span>
						<div class="col-8 stall-image-container">
							<img v-if="stall.id"
								:src="`https://dashboard-atomx.s3.us-west-2.amazonaws.com/events/stalls/${stall.id}.jpg`"
								alt="Upload Image" height=100 class="" @mouseover="showUploadButton = stall.id"
								@mouseleave="showUploadButton = null" />
							<div
								class="overlay position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
								<label class="d-inline">
									<input :key="inputKey" type="file" @change="handleFileUpload($event, stall)"
										accept="image/*" ref="fileInput" class="d-none" />
									<i class="fa fa-upload fa-lg cursor-pointer" @click="triggerFileInput(stall)"></i>
								</label>
							</div>
						</div>
					</span>
					<!-- </el-form-item> -->
					<!-- <span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Stall Banner</span> -->


					<!-- </span> -->
					<div v-if="isCropModalVisible" class="modal fade show" tabindex="-1" role="dialog"
						style="display: block; background: rgba(0, 0, 0, 0.6);">
						<div class="modal-dialog" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title">Crop Image</h5>
									<button type="button" class="close" @click="closeCropModal">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
									<!-- <img :src="croppingImage" id="image-to-crop" /> -->
									<span class="">{{ stall.name }} ( {{ stall.id }} )</span>
									<img :src="croppingImage" id="image-to-crop" />
									<ul class="text-danger">
										<li>
											<small>Image should be landscape.</small>
										</li>
										<li><small>Minimum width should be 500px.</small></li>
									</ul>
								</div>

								<div class="modal-footer">
									<button type="button" class="btn btn-secondary"
										@click="closeCropModal">Cancel</button>
									<button type="button" class="btn btn-primary" @click="cropImage">Crop</button>
								</div>
							</div>
						</div>
					</div>
					<div v-if="isErrorModal" class="modal fade show" tabindex="-1" role="dialog"
						style="display: block; background: rgba(0, 0, 0, 0.6);">
						<div class="modal-dialog" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title">Invalid Format</h5>
									<!-- <button type="button" class="close" @click="closeCropModal">
											<span aria-hidden="true">&times;</span>
										</button> -->
								</div>
								<div class="modal-body">
									<ul class="text-danger">
										<li>
											<small>Image should be landscape.</small>
										</li>
										<li><small>Minimum width should be 500px.</small></li>
									</ul>

									<!-- <span class="">{{ event.name }}({{ event.id }})</span> -->
									<!-- <img :src="croppingImage" id="image-to-crop" /> -->
								</div>

								<div class="modal-footer">
									<button type="button" class="btn btn-secondary"
										@click="closeErrorModal">Cancel</button>
								</div>
							</div>
						</div>
					</div>


					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left">Type</span>
							<el-select class="select-aml-teal col-6" v-model="stall.type" id="stallEdit_type" filterable
								automatic-dropdown placeholder="Select Type">
								<el-option v-for="type in defaultStallTypes" :key="type" :label="type.toUpperCase()"
									:value="type" @change="stallTypeChange" />
							</el-select>
						</span>
					</el-form-item>


					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Payment
								Modes</span>
							<el-switch v-model="stall.payOption" id="stallEdit_paymentModes"
								active-text="Accept All Modes" inactive-text="Only NFC" active-color="#13ce66"
								inactive-color="#ccc" :active-value="1" :inactive-value="0" />
						</span>
					</el-form-item>

					<el-form-item v-if="stall.payOption === 1">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Mode
								Options</span>
							<el-select class="select-aml-teal col-8" v-model="stall.payOptions"
								id="stallEdit_payOptions" filterable placeholder="Select max 4 Options"
								@change="optchange()" multiple :multiple-limit="4">
								<el-option v-for="format in defaultModeOptions" :key="format.value"
									:label="format.label.toUpperCase()" :value="format.value" />
							</el-select>
						</span>
					</el-form-item>

					<el-form-item v-if="stall.type !== 'topup'">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">GRN Mode</span>
							<el-switch v-model="stall.useGrn" id="stallEdit_useGrn" active-color="#13ce66"
								inactive-color="#ccc" :active-value="1" :inactive-value="0" />
						</span>
					</el-form-item>

					<el-form-item v-if="stall.type === 'sale'">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Cash
								Disabled</span>
							<el-switch v-model="stall.cashDisabled" id="stallEdit_cashDisabled" active-color="#13ce66"
								inactive-color="#ccc" :active-value="1" :inactive-value="0" />
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Bank Payment</span>
							<el-select class="select-aml-teal col-6" v-model="stall.bankOption" filterable
								automatic-dropdown placeholder="Select Bank Device Type">
								<el-option v-for="bank in defaultBanksTypes" :key="bank.value"
									:label="bank.label.toUpperCase()" :value="bank.value" />
							</el-select>
						</span>
					</el-form-item>

					<el-form-item class="col">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">KOT LAN</span>
							<el-switch v-model="stall.kotLan" id="stallEdit_kotLan" active-color="#13ce66"
								inactive-color="#ccc" :active-value="1" :inactive-value="0" />
						</span>
					</el-form-item>

					<el-form-item class="col">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">MODE INFO
								MANDATORY</span>
							<el-switch v-model="stall.modeInfoMandatory" id="stallEdit_modeInfoMandatory"
								active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0" />
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Scan Mode</span>
							<el-select class="select-aml-teal col-6" v-model="stall.scanMode" automatic-dropdown
								placeholder="Select Scan Mode">
								<el-option v-for="mode in defaultScanModes" :key="mode.value"
									:label="mode.label.toUpperCase()" :value="mode.value" />
							</el-select>
						</span>
					</el-form-item>

					<span class=""
						v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">
						<el-form-item class="col my-auto">
							<span class="row1">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Add Discount
									In Card</span>
								<el-switch v-model="stall.addDiscountInCard" id="stall_addDiscountInCard"
									active-color="#13ce66" inactive-color="#ccc" :active-value="1"
									:inactive-value="0" />
							</span>
						</el-form-item>
					</span>

					<span class=""
						v-if="(loginUser.type === 'admin' && loginUser.id === 1480) || (loginUser.admin_id === 1480)">
						<el-divider class="">SMS</el-divider>

						<span class="row col-md-8 col-11 mx-auto">
							<el-form-item class="col my-auto">
								<span class="row1">
									<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Send
										SMS</span>
									<el-switch v-model="stall.sendSms" id="stallEdit_sendSms" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								</span>
							</el-form-item>

							<el-form-item v-if="stall.sendSms" class="col my-auto">
								<span class="row1">
									<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">SMS
										Format</span>
									<el-select class="select-aml-teal col-8" v-model="stall.smsFormat" filterable
										placeholder="Select SMS Format">
										<el-option v-for="format in defaultSmsFormats" :key="format.value"
											:label="format.label.toUpperCase()" :value="format.value" />
									</el-select>
								</span>
							</el-form-item>
						</span>
					</span>

					<span class="">
						<el-divider class="">TapX Items</el-divider>

						<span class="row col-md-6 col-10 mx-auto">
							<el-form-item class="col">
								<span class="row1">
									<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Show in
										TapX</span>
									<el-switch v-model="stall.showStall" id="stallEdit_showStall" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								</span>
							</el-form-item>
							<el-form-item v-if="stall.showStall" class="col">
								<span class="row1">
									<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Show
										Price</span>
									<el-switch v-model="stall.showPrice" id="stallEdit_showPrice" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								</span>
							</el-form-item>

							<el-form-item v-if="stall.showStall && stall.showPrice" class="col">
								<span class="row1">
									<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Add to
										Cart</span>
									<el-switch v-model="stall.cartAdd" id="stallEdit_addToCard" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								</span>
							</el-form-item>
						</span>

						<!--				<el-form-item label="Show Price">-->
						<!--					<el-switch v-model="stall.showPrice" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>-->
						<!--				</el-form-item>-->

						<!--				<el-form-item v-if="stall.showPrice" label="Add to Cart">-->
						<!--					<el-switch v-model="stall.cartAdd" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>-->
						<!--				</el-form-item>-->
					</span>

					<span v-if="stall.type === 'accessx'">
						<el-divider class="">AccessX Settings</el-divider>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left">NFC Settings</span>
								<el-select class="select-aml-teal col-6" v-model="stall.accessxSettings.nfcSettings"
										   placeholder="Select NFC Setting" filterable allow-create >
									<el-option v-for="nfcsetting in ['off', 'logic', 'whitelist']"
											   :key="nfcsetting" :label="nfcsetting.toUpperCase()" :value="nfcsetting"/>
								</el-select>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left">QR Settings</span>
								<el-select class="select-aml-teal col-6" v-model="stall.accessxSettings.qrSettings"
										   placeholder="Select QR Setting" filterable allow-create >
									<el-option v-for="qrsetting in ['off', 'logic', 'whitelist']"
											   :key="qrsetting" :label="qrsetting.toUpperCase()" :value="qrsetting"/>
								</el-select>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">LOCATION ID</span>
								<el-input type="text" class="col-md-2 col-4" v-model="stall.accessxSettings.qrLogicLocationId" minlength="2" maxlength="2" autocomplete="off" />
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">EVENT / MATCH ID</span>
								<el-input class="col-md-2 col-4" v-model="stall.accessxSettings.qrLogicEventMatchId" minlength="4" maxlength="4" autocomplete="off" />
							</span>
						</el-form-item>

						<el-form-item class="col">
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">USE ONLINE TOPUPS</span>
								<el-switch v-model="stall.accessxSettings.useOnlineTopups" id="stallAccess_use_online_toups"
										   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
							</span>
						</el-form-item>

						<el-form-item class="col">
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">USE IN-OUT LOGIC</span>
								<el-switch v-model="stall.accessxSettings.useInOut" id="stallAccess_use_useInOut"
										   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
							</span>
						</el-form-item>

						<el-form-item class="col">
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">FETCH DETAILS</span>
								<el-switch v-model="stall.accessxSettings.useFetchDetails" id="stallAccess_use_fetch_details"
										   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
							</span>
						</el-form-item>

						<el-form-item class="col">
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">CHECK ONLINE UNIQUE</span>
								<el-switch v-model="stall.accessxSettings.checkOnlineUnique" id="stallAccess_check_online_unique"
										   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
							</span>
						</el-form-item>

						<el-divider/>
					</span>

				</el-form>
			</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="stallDialog = false" v-if="!stallLoading">Cancel</el-button>
					<el-button type="primary" :loading="stallLoading" @click="saveStall">Confirm</el-button>
				</span>
			</template>
		</el-dialog>

		<!--		<el-dialog :title="'Add Devices to ' + (typeof device.stall !== 'undefined' ? device.stall.name + ' | Vendor: ' + device.stall.vendor_name : '')" v-model="deviceDialog">-->
		<el-dialog v-model="deviceDialog">
			<template #title>
				<span class="el-dialog__title">
					<div>Add Devices to Stall: {{ (typeof device.stall !== 'undefined' ? device.stall.name : '') }}</div>
					<small>Vendor: {{ (typeof device.stall !== 'undefined' ? device.stall.vendor_name : '') }}</small>
				</span>
			</template>
			<span class="d-block mt-3">
				<el-form :model="device" class="">
					<span>
						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Type</span>
								<el-select class="select-aml-teal col-6" v-model="device.type" filterable
									automatic-dropdown default-first-option placeholder="Select Type">
									<el-option
										v-for="type in
								device.stall.type === 'sale' ? [...defaultSaleDeviceTypes, ...defaultTopupDeviceTypes] :
									(device.stall.type === 'inventory') ? defaultInventoryDeviceTypes :
									(device.stall.type === 'accessx' && event.id === 1356) ? defaultPachaAccessxDeviceTypes :
									(device.stall.type === 'accessx') ? defaultAccessxDeviceTypes :
										[...defaultTopupDeviceTypes, ...defaultSaleDeviceTypes]"
									:key="type.value" :label="type.label.toUpperCase()" :value="type.value" />
								</el-select>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label"
									class="col-md-3 col-12 text-md-right text-left my-auto">Printer</span>
								<el-select class="select-aml-teal col-6" v-model="device.printer" allow-create
									filterable automatic-dropdown placeholder="Select Printer">
									<el-option v-for="printer in defaultPrinters" :key="printer.value"
										:label="printer.label.toUpperCase()" :value="printer.value" />
								</el-select>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label"
									  class="col-md-3 col-12 text-md-right text-left my-auto">Devices<small>( {{ device.devices.length }} )</small></span>
								<el-select class="select-aml-teal col-6 text-white pl-3 devices-search" v-model="device.devices" :loading="loadingd"
									   ref="device" multiple remote :remote-method="remoteDeviceMethod" reserve-keyword
									   filterable placeholder="Type 3 digits to Search">
									<el-option v-for="device in allDevices" :key="device.id" :label="device.printId" :value="device.id">
										<span style="float: left">{{ device.printId }}</span>
										<span style="float: right; color: #8492a6; font-size: 13px">{{ device.hardwareId }}</span>
									</el-option>
								</el-select>
							</span>
						</el-form-item>

					</span>

					<span class="row bg-light d-block">
						<el-divider><i class="fas fa-info" /></el-divider>
						<el-form-item>
							<span class="row d-none">
								<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Vendor</span>
								<el-input class="col-8" v-model="device.stall.vendor_name" disabled
									autocomplete="off"></el-input>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label"
									class="col-md-3 col-10 text-md-right text-left my-auto">Details</span>
								<span class="col-8">
									<span class="badge badge-default ml-3"><span>Stall Type: <h5>{{
		device.stall.type.toUpperCase() }}</h5></span></span>
									<span class="badge badge-default ml-3"><span>Pay Modes: <h5>{{
		device.stall.pay_option === 1 ? 'ALL' : 'Only NFC' }}</h5></span></span>
									<span class="badge badge-default ml-3" v-if="event.country === 'india'"><span>Bank
											Mode: <h5>{{ device.stall.bank_option ?
		device.stall.bank_option.split('-')[0].toUpperCase() : '' }}</h5>
										</span></span>
								</span>
							</span>
						</el-form-item>

						<el-form-item v-if="typeof device.stall !== 'undefined'">
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Stall Devices
									<small>( {{ stallDevices.length }} )</small></span>
								<span class="col-8">
									<span v-if="device.stall.devices_count > 0">
										<span class="badge badge-default ml-3" v-for="d in stallDevices" :key="d">{{ d
											}}</span>
									</span>
									<span v-else>-</span>
								</span>

							</span>
						</el-form-item>
						<el-divider><i class="fas fa-info" /></el-divider>
					</span>
				</el-form>
			</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="deviceDialog = false" v-if="!deviceLoading">Cancel</el-button>
					<el-button class="aml-bg-teal-3 border-0" type="primary" :loading="deviceLoading"
						@click="saveDevice">Confirm</el-button>
				</span>
			</template>
		</el-dialog>
	</span>
</template>

<script>
import {
	ElTable, ElTableColumn, ElInput, ElButton,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElCollapse, ElCollapseItem, ElMessage
} from "element-plus";
import axios from 'axios';

import Cropper from "cropperjs";

export default {
	components: {
		ElTable,
		ElTableColumn,
		ElInput, ElButton, ElDialog,
		ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElCollapse, ElCollapseItem,
	},
	props: ['stalls', 'isLoading', 'event', 'devices'],
	inject: ['$baseURL',],
	emits: ['devices-updated'],
	data() {
		return {
			isErrorModal: false,
			croppingImage: null,
			inputKey: 0,
			showUploadButton: null,
			currentItem: null,
			cropper: null,
			isCropModalVisible: false, // Add this property
			search: '',
			search_inventory: '',
			search_table: '',
			search_accessx: '',
			stallDialog: false,
			stallLoading: false,
			stall: {  },
			deviceDialog: false,
			deviceLoading: false,
			device: { type: '', },
			stallRow: {},
			stallDevices: [],
			allDevices: [],
			loadingd: false,
			defaultStallTypes: ['topup', 'sale', 'accessx', 'inventory', 'stockmaster', 'tables'],
			defaultPayoutServices: ['razorpay',],
			defaultBanks: ['none', 'mswipe-wisepad', 'worldline', 'sunmiPay', 'MF919',],
			defaultBanksTypes: [
				{ label: 'MSWIPE', value: 'mswipe-wisepad' },
				{ label: 'SUNMIPAY', value: 'sunmiPay' },
				{ label: 'EZETAP', value: 'ezetap' },
				{ label: 'MOSAMBEE', value: 'mosambee' },
				{ label: 'WORLDLINE', value: 'worldline' },
				{ label: 'AIRPAY', value: 'airpay' },
				{ label: 'Pinelabs', value: 'pinelabs' },
				{ label: 'UTAP', value: 'utap' },
				{ label: 'NONE', value: 'none' },
			],
			defaultScanModes: [
				{ label: 'NONE', value: 'none' }, { label: 'MENU', value: 'menu' }, { label: 'TICKET', value: 'ticket' },
			],
			defaultPrinters2: ['wisepos+', 'panda', 'rugtek', 'worldline', 'sunmiPay', 'blue2', 'sprin_3', 'sprin_2', 'none',],
			defaultPrinters: [
				{ label: 'NONE', value: 'none' },
				{ label: 'Wisepos+', value: 'wisepos+' },
				{ label: 'EZETAP', value: 'airpay' },
				{ label: 'MF919', value: 'MF919' },
				{ label: 'MOSAMBEE | DX8000', value: 'DX8000' },
				{ label: 'Worldline', value: 'worldline' }, { label: 'SunmiPay', value: 'sunmiPay+' },
				{ label: 'AIPAY', value: 'airpay' },
				{ label: 'Pinelabs', value: 'pinelabs' },
				{ label: 'Rugtek', value: 'rugtek' },
				{ label: 'BT-2inch', value: 'blue2' }, // { label: 'BT-3inch', value: 'blue3' },
				{ label: 'SPrin-3inch', value: 'sprin_3' }, { label: 'SPrin-2inch', value: 'sprin_2' },
				{ label: 'Panda-3inch', value: 'panda' },
			],
			defaultTopupDeviceTypes: [
				{ label: 'TOPUP CASH', value: 'topup_cash' }, { label: 'TOPUP CARD', value: 'topup_card' },
				{ label: 'TOPUP COMPLIMENTARY', value: 'topup_comp' },
				{ label: 'TOPUP ITEMS', value: 'item_topup' },
				{ label: 'ACCESSX', value: 'accessx' },
				{ label: 'TOPUP CORPORATE', value: 'topup_corp' },
				{ label: 'TOPUP EMPLOYEE', value: 'topup_emp' }, { label: 'TOPUP SPECIAL', value: 'topup_spl' },
				{ label: 'TOPUP KIOSK', value: 'topup_kiosk' },
				{ label: 'TOPUP KIOSK ONLINE', value: 'topup_kiosk_online' },
				{ label: 'TOPUP KIOSK OFFLINE', value: 'topup_kiosk_offline' },
				{ label: 'TOPUP HOME DELIVERY', value: 'topup_hd' }, { label: 'TOPUP PICKUP', value: 'topup_pickup' },
				{ label: 'RETURN', value: 'return' }, { label: 'MERGE', value: 'merge' },
				// { label: 'ALL OPTIONS', value: 'all_options' },
				{ label: 'MASTER RETURN', value: 'master_return' },
				{ label: 'ISSUANCE', value: 'accessx_issue' },
			],
			defaultSaleDeviceTypes: [{ label: 'MENU', value: 'sale_menu' },{ label: 'TICKET MENU', value: 'ticket_menu' }, { label: 'INVOICE', value: 'sale_invoice' }, { label: 'SALE ITEMS', value: 'item_sale' }, { label: 'SALE KIOSK', value: 'sale_kiosk' },],
			defaultInventoryDeviceTypes: [{ label: 'MANAGER ASSIGN', value: 'inventory_assign' }, { label: 'INVENTORY DEPLOY', value: 'inventory_deploy' }, { label: 'INVENTORY RETURN', value: 'inventory_return' },],
			defaultAccessxDeviceTypes: [
				{ label: 'ACCESSX', value: 'accessx' }, { label: 'ISSUANCE', value: 'accessx_issue' },
			],
			defaultPachaAccessxDeviceTypes: [
				{ label: 'PACHA ACCESSX', value: 'accessx_pacha' }, { label: 'PACHA GUEST ISSUANCE', value: 'pacha_accessx_guest' }, { label: 'PACHA VIP ISSUANCE', value: 'pacha_accessx_vip' },
				{ label: 'ACCESSX', value: 'accessx' }, { label: 'ISSUANCE', value: 'accessx_issue' },
			],
			defaultSmsFormats: [
				// { label: 'SELFEE+TAPX', value: 'selfee_tapx' },
				// { label: 'ATOMX', value: 'default' },
			],
			defaultModeOptions: [
				{ label: 'CASH', value: 'cash' }, { label: 'CARD', value: 'card' },
				{ label: 'UPI', value: 'upi' },
			],


			value: null,
			value2: [],
			options: [
				{ id_: 'Vue.js', printId: 'JavaScript' },
				{ id_: 'Adonis', printId: 'JavaScript2' },
				{ id_: 'Rails', printId: 'Ruby' },
				{ id_: 'Sinatra', printId: 'Ruby2' },
				{ id_: 'Laravel', printId: 'PHP' },
				{ id_: 'Phoenix', printId: 'Elixir' }
			],

			loginUser: {},
		}
	},
	methods: {
		remoteDeviceMethod(query) {
			if (query !== '' && query.length >=3) {
				this.loadingd = true;
				setTimeout(() => {
					this.loadingd = false;
					this.allDevices = this.devices.filter(item => item.printId.toString().includes(query.toLowerCase()));
				}, 200);
			} else {
				this.allDevices = [];
			}
		},
		triggerFileInput(stall) {
			console.log(stall);
			this.currentItem = stall;
			// Set the current item
			// this.$refs.fileInput.click(); // Trigger the file input dialog
		},
		closeErrorModal() {
			this.isErrorModal = false;
		},
		handleFileUpload(event) {
			console.log("handle file")
			const file = event.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					this.croppingImage = e.target.result;
					this.isCropModalVisible = true;
					this.$nextTick(() => {
						const image = document.getElementById('image-to-crop');
						this.cropper = new Cropper(image, {
							// Do not enforce aspect ratio for free cropping
							viewMode: 1,
							autoCropArea: 1, // Ensure the initial crop area is large
							responsive: true, // Ensures the cropper adjusts on window resize
							movable: true, // Allow moving the image
							zoomable: true, // Allow zooming the image
						});
					});
				};
				reader.readAsDataURL(file);
			}
		},
		closeCropModal() {
			this.inputKey++;
			this.currentItem = null;
			this.isCropModalVisible = false;
			if (this.cropper) {
				this.cropper.destroy();
				this.cropper = null;
			}
		},
		cropImage() {
			// const canvas = this.cropper.getCroppedCanvas({
			//   width: 300,
			//   height: 300,
			//   imageSmoothingQuality: 'high',
			// });
			// Create a new canvas with 300x300 dimensions
			const croppedCanvas = this.cropper.getCroppedCanvas();
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const targetSize = 500;
			canvas.width = targetSize;
			canvas.height = (croppedCanvas.height / croppedCanvas.width) * targetSize;

			ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);


			if (croppedCanvas.width < croppedCanvas.height) {
				this.inputKey++;
				this.currentItem = null;
				this.isCropModalVisible = false;
				if (this.cropper) {
					this.cropper.destroy();
					this.cropper = null;
				}
				this.isErrorModal = true;
				return;
			}

			// // Calculate the width and height to maintain the aspect ratio
			// const ratio = Math.min(targetSize / croppedCanvas.width, targetSize / croppedCanvas.height);
			// console.log(ratio);
			// const width = croppedCanvas.width * ratio;
			// const height = croppedCanvas.height * ratio;

			// // Set canvas size to 300x300
			// canvas.width = targetSize;
			// canvas.height = targetSize;

			// // Fill the background with white (or any color you prefer)
			// ctx.fillStyle = '#ffffff';
			// ctx.fillRect(0, 0, canvas.width, canvas.height);

			// // Draw the cropped image in the center
			// const offsetX = (canvas.width - width) / 2;
			// const offsetY = (canvas.height - height) / 2;
			// ctx.drawImage(croppedCanvas, offsetX, offsetY, width, height);

			// Get the base64 string from the canvas
			const base64String = canvas.toDataURL('image/webp').split(',')[1]; // Remove the data URL prefix
			console.log(base64String);
			// Create the payload
			const payload = {
				file: base64String,
				stallId: this.currentItem.id,
				fileType: 'dashboard-stall-img',
			};

			axios.post('https://fnbxapi.atomx.in/fnbx/upload', payload)
				.then(response => {
					if (response.data.success) {
						this.currentItem.uploadedImageName = `${this.currentItem.name}.webp`;
						this.currentItem.uploadedImagePath = response.data.path;
						this.closeCropModal();
					} else {
						alert('Image upload failed!');
					}
				})
				.catch(error => {
					console.error('Image upload error:', error);
					alert('Image upload error!');
				});
		},

		deviceOpen(stall = {}) {
			// console.log(stall);
			this.stallDevices = stall.devices_count > 0 ? stall.devices_list.split(',') : [];
			this.device.devices = [];
			this.device.printer = this.event.printer;
			this.device.stall = JSON.parse(JSON.stringify(stall));
			this.device.stallId = stall.id;
			// this.device.vendor_name = stall.vendor_name;
			// this.device.stall_name = stall.name;
			// this.device.stall_type = stall.type;
			this.stallRow = stall;
			// debugger;
			// if(stall.type === 'accessx')	this.device.type = 'accessx';

			this.deviceDialog = true;
		},
		stallTypeChange() {
			if (this.stall.type !== 'sale') this.stall.useGrn = 0;
			else this.stall.useGrn = this.stallRow.use_grn;
			if (this.stall.type === 'inventory' || this.stall.type === 'tables') this.stall.useGrn = 1;
		},
		stallOpen(stall = {}) {
			let accessxSettings = {
				id: stall.accessxSettingsId,
				nfcSettings: stall.nfcSettings,
				qrSettings: stall.qrSettings,
				useOnlineTopups: stall.useOnlineTopups,
				checkOnlineUnique: stall.checkOnlineUnique,
				useFetchDetails: stall.useFetchDetails,
				useInOut: stall.useInOut,
				qrLogicLocationId: stall.qrLogicLocationId,
				qrLogicEventMatchId: stall.qrLogicEventMatchId,
			};
			// return console.log(this.stall);
			this.stall = JSON.parse(JSON.stringify(stall));
			// this.stall = stall;
			this.stall.payOption = this.stall.pay_option;
			this.stall.payOptions = this.stall.pay_options.split(',');
			this.stall.bankOption = this.stall.bank_option;
			this.stall.cashDisabled = this.stall.cash_disabled;
			this.stall.useGrn = this.stall.use_grn;
			this.stall.scanMode = this.stall.scan_mode;
			this.stall.showStall = this.stall.show_stall;
			this.stall.showPrice = this.stall.show_price;
			this.stall.cartAdd = this.stall.cart_add;
			this.stall.payoutStatus = this.stall.payout_status;
			this.stall.payoutService = this.stall.payout_service;
			this.stall.payoutId = this.stall.payout_id;
			this.stall.sendSms = this.stall.send_sms;
			this.stall.smsFormat = this.stall.sms_format;
			this.stall.accessxSettings = accessxSettings;

			this.stallRow = stall;
			// return console.log('s', this.stall);
			this.stallDialog = true;
		},
		filterVendor(value, row) {
			return row.vendor_name === value;
		},
		optchange(v) { console.log(v) },
		saveStall() {
			// return console.log(this.stall);
			if (this.stall.name === '') return console.log('');
			if (this.stall.payOptions.length === 0) return console.log('');
			try { this.stall.payOptions = this.stall.payOptions.join(','); } catch (e) { };

			this.stallLoading = true;
			axios.post(
				this.$baseURL + '/Stalls/Edit',
				this.stall,
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						this.stallRow.name = this.stall.name;
						this.stallRow.pay_option = this.stall.payOption;
						this.stallRow.pay_options = this.stall.payOptions;
						this.stallRow.payOptions = this.stall.payOptions.split(',');
						this.stallRow.type = this.stall.type;
						// this.stallRow.pay_option = this.stall.payOption;
						this.stallRow.bank_option = this.stall.bankOption;
						this.stallRow.show_price = this.stall.showPrice;
						this.stallRow.cart_add = this.stall.cartAdd;
						this.stallRow.payout_status = this.stall.payoutStatus;
						this.stallRow.payout_service = this.stall.payoutService;
						this.stallRow.payout_id = this.stall.payoutId;
						this.stallRow.send_sms = this.stall.sendSms;
						this.stallRow.sms_format = this.stall.smsFormat;
						this.stallRow.use_grn = this.stall.useGrn;
						this.stallRow.cash_disabled = this.stall.cashDisabled;
						// this.stallRow = { ...this.stallRow, ...this.stall.accessxSettings };
						// this.stallRow.accessxSettings = this.stall.accessxSettings;
						this.stallRow.accessxSettingsId = this.stall.accessxSettings.accessxSettingsId;
						this.stallRow.nfcSettings = this.stall.accessxSettings.nfcSettings;
						this.stallRow.qrSettings = this.stall.accessxSettings.qrSettings;
						this.stallRow.useOnlineTopups = this.stall.accessxSettings.useOnlineTopups;
						this.stallRow.checkOnlineUnique = this.stall.accessxSettings.checkOnlineUnique;
						this.stallRow.useFetchDetails = this.stall.accessxSettings.useFetchDetails;
						this.stallRow.useInOut = this.stall.accessxSettings.useInOut;
						this.stallRow.qrLogicLocationId = this.stall.accessxSettings.qrLogicLocationId;
						this.stallRow.qrLogicEventMatchId = this.stall.accessxSettings.qrLogicEventMatchId;
						// console.log('stallRow', this.stallRow, this.stall);

						this.stallDialog = false;
					}
					this.stallLoading = false;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.stallLoading = false;
				});
		},
		saveDevice() {
			// return console.log(this.device);
			// debugger;
			if (this.device.type === '') return ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>No type selected</b>", type: 'warning', grouping: true, duration: 3000, });
			if (this.device.printer === '') return ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>No printer selected</b>", type: 'warning', grouping: true, duration: 3000, });
			if (this.device.devices.length === 0) return ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>No devices selected</b>", type: 'warning', grouping: true, duration: 3000, });

			this.deviceLoading = true;
			axios.post(
				this.$baseURL + '/Devices/AddToStall',
				this.device,
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						this.stallRow.devices_list += (this.stallRow.devices_count > 0 ? ',' : '') + this.device.devices.join(',');
						this.stallRow.devices_count += this.device.devices.length;

						// this.$emit('devices-updated', this.device.devices);

						this.deviceDialog = false;
					}
					this.deviceLoading = false;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.deviceLoading = false;
				});
		},
	},
	mounted() {
		this.loginUser = JSON.parse(localStorage.getItem('user'));
		if (this.loginUser.type === 'admin') {
			if (this.loginUser.id === 1480) {
				this.defaultSmsFormats = [{ label: 'DEFAULT', value: 'default' },];
				this.stall.smsFormat = 'default';
			}
			this.defaultModeOptions.push({ label: 'COUPON', value: 'coupon' });
		} else if (this.loginUser.type === 'admin' && this.loginUser.id === 1702) {
			this.defaultSmsFormats = [{ label: 'SELFEE+TAPX', value: 'selfee_tapx' },];
			this.stall.smsFormat = 'selfee_tapx';
		}

		// if(this.event.country === 'india') this.stall.payOptions = ['cash', 'card', 'upi', 'coupon'];
		// else	this.stall.payOptions = ['cash', 'card', 'coupon'];
	},
};
</script>
<style>
.el-table td,
.el-table th {
	padding: 0.55rem 0 !important;
}

.el-form-item__content {
	line-height: 20px !important;
}

/*.aml-stall-devices .el-tag .el-select__tags-text {*/
/*margin-top: 5px !important;*/
/*margin-left: 5px !important;*/
/*}*/
/*.aml-stall-devices .el-select .el-select__tags .el-tag {*/
/*color: white !important;*/
/*font-size: 15px !important;*/
/*background-color: #197a8cb8 !important;*/
/*}*/
/*.aml-stall-devices .el-tag__close { font-size: 15px !important; top: 2px !important; }*/
/*.aml-stall-devices .el-select .el-select__tags { left: 20px !important; }*/
/*.aml-stall-devices .aml-select.el-select .el-input .el-input__inner {*/
/*background-color: #d8d8d8a8 !important;*/
/*color: #FFFFFF;*/
/*border-radius: 10px;*/
/*padding-left: 4px;*/
/*}*/
/*.aml-stall-devices .el-select .el-input .el-select__caret { color: #065213 !important; }*/
/*.aml-stall-devices .el-select__input {*/
/*!*background-color: #3a1e1e12 !important;*!*/
/*padding-left: 10px !important;*/
/*margin-top: 5px !important;*/
/*-webkit-border-radius: 3px !important;*/
/*-moz-border-radius: 3px !important;*/
/*border-radius: 3px !important;*/
/*}*/
</style>

<style>
.devices-search input {
	color: white;
}

.el-dialog__body {
	padding-top: 0px !important;
}

.modal-dialog {
	max-width: 500px;
}


.stall-image-container {
	position: relative;
	display: inline-block;
}

.stall-image-container img {
	display: block;

}

.overlay {
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	/* Optional: Background color with opacity */
	color: white;
	/* Text color for upload icon */
	opacity: 0;
	/* Hidden by default */
	transition: opacity 0.3s ease;
	/* Smooth transition */
	pointer-events: none;
	/* Allow clicks through the overlay */
}

.stall-image-container:hover .overlay {
	opacity: 1;
	/* Show overlay on hover */
	pointer-events: auto;
	/* Allow clicks on the overlay */
}

.cropper-container {
	display: none !important
}

.cropper-bg {
	display: none !important
}

.overlay i {
	font-size: 24px;
	/* Size of the upload icon */
}
</style>
