<template>
	<div>
		<div class="row " >
<!--			<div class="col-12 col-md-6 mx-auto d-none">-->
<!--				<div class="card card-stats card-raised">-->
<!--					<div class="card-body">-->
<!--						<div class="my-4">-->
<!--							<el-input placeholder="Search" prefix-icon="el-icon-search" v-model="search"/>-->
<!--							<small class="aml-text-gray-2 mt-1 mb-3">Search by NAME / BOOKING-ID / TAG ID / DATE </small> <br>-->
<!--							<el-button class="aml-bg-teal-3 border-0 my-auto mt-2" type="primary"-->
<!--									   :disabled="this.search.toString().length <=2"-->
<!--									   :loading="isLoading.users" @click="searchData">Search</el-button>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->


			<div class="row col-12">
				<div class="col-12 mt-2 mx-auto">
					<div class="m-2 row">
						<h4 class="col-md-3 col-12 mx-2 my-4 py-4 aml-bg-gray border-0 cursor-pointer text-center aml-border-radius-1 text-white"
							v-for="date in distinctPachaDates" @click="searchByDate(date.tkt_issuance_mode)">
							<div>{{ displaydt(date.tkt_issuance_mode) }}</div>
							<div>{{ displayday(date.tkt_issuance_mode) }}</div>
						</h4>
					</div>
				</div>
			</div>

			<el-divider direction="hirizontal"/>

			<div class="row justify-content-center col-12 mx-auto">
				<!-- Summary -->
				<div class="col-8 col-md-4 mx-auto d-flex pt-4" v-if="components.summary">
					<div class="card card-chart aml-print-dashboard-card mx-auto">
						<div class="card-header aml-border-radius-2 bg-light">
							<h4 class="card-category d-inline text-center aml-font-20">{{ this.selectedDate }}</h4>
							<div v-if="isLoading.summary" class="px-0 d-inline pull-right">
								<span class="badge badge-primary">
									<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
								</span>
							</div>
						</div>
						<div class="card-body " id="summary__data">
							<h5 class="mt-2"><small>TOTAL ISSUED:</small> {{ summary[0].count }}</h5>
							<h5><small>TOTAL REDEEMED:</small> {{ summary[1].count }}</h5>
							<h5><small>TOTAL INCORRECT ISSUED:</small> {{ summary[2].count }}</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center col-12 mx-auto d-none">
				<!-- SOURCE -->
				<div class="col-7 col-md-6 mx-auto d-flex" v-if="components.sources">
					<div class="card card-chart aml-print-dashboard-card mx-auto">
						<div class="card-header aml-border-radius-2 aml-font-12">
							<h5 class="card-category d-inline">BY SOURCE</h5>
							<div v-if="isLoading.sources" class="px-0 d-inline pull-right">
							<span class="badge badge-primary">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
							</div>
						</div>
						<div class="card-body" id="sources_data">
							<div class="table-responsive pb-0">
								<table class="table aml-font-11">
									<thead class="text-primary">
										<th class="text-left">SOURCE</th>
										<th class="text-right">ISSUED</th>
										<th class="text-right">REDEEMED</th>
									</thead>
									<tbody>
									<tr v-for="source in sources" :key="source.reference">
										<td class="text-left">{{ source.reference }}</td>
										<td class="text-right">{{ source.issuedCount }}</td>
										<td class="text-right">{{ source.redeemedCount }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row justify-content-center col-12 mx-auto">
				<!--CATEGORY WISE ACCESS-->
				<div class="col-md-5 col-12 d-flex d-none" v-if="components.accessCatg">
					<div class="card card-chart aml-print-dashboard-card mx-auto d-none">
						<div class="card-header aml-border-radius-2">
							<h5 class="card-category d-inline">BY ACCESS</h5>
							<div v-if="isLoading.accessCatg" class="px-0 d-inline pull-right">
							<span class="badge badge-primary">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
							</div>
						</div>
<!--						<div class="card-body " id="catg_access_data">-->
<!--							<div class="table-responsive pb-0">-->
<!--								<table class="table aml-font-11">-->
<!--									<thead class="text-primary">-->
<!--									<th class="text-left">CATEGORY</th>-->
<!--									<th class="text-right">UNIQUE</th>-->
<!--									</thead>-->
<!--									<tbody>-->
<!--									<tr v-for="access in accessCatg" :key="access.id">-->
<!--										<td class="text-left">{{ access.category_name }}</td>-->
<!--										<td class="text-right">{{ this.$numFrm(access.unique_count, 'int', this.event.country) }}</td>-->
<!--									</tr>-->
<!--									</tbody>-->
<!--								</table>-->
<!--							</div>-->
<!--						</div>-->
						<div class="card-body" id="access_data">
							<div class="table-responsive pb-0">
								<table class="table aml-font-11">
									<thead class="text-primary">
									<th class="text-left">ACCESS</th>
									<th class="text-right">ISSUED</th>
									<th class="text-right">REDEEMED</th>
									<th class="text-right">INCORRECT ISSUED</th>
									</thead>
									<tbody>
									<tr v-for="access in accessCatg" :key="access.booking_reference2">
										<td class="text-left">{{ access.booking_reference2 }}</td>
										<td class="text-right">{{ access.issuedCount }}</td>
										<td class="text-right">{{ access.redeemedCount }}</td>
										<td class="text-right">{{ access.resetCount }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<!--GATE WISE ACCESS-->
				<div class="col-md-5 col-12 d-flex" v-if="components.accessGate">
					<div class="card card-chart aml-print-dashboard-card mx-auto">
						<div class="card-header aml-border-radius-2">
							<h5 class="card-category d-inline">BY DEVICE / GUARD</h5>
							<div v-if="isLoading.accessGate" class="px-0 d-inline pull-right">
								<span class="badge badge-primary">
									<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
								</span>
							</div>
						</div>
						<div class="card-body " id="gate_access_data">
							<div class="table-responsive">
								<table class="table aml-font-11">
									<thead class="text-primary">
										<th class="text-left">DEVICE / GUARD</th>
										<th class="text-right">REDEEMED</th>
									</thead>
									<tbody>
									<tr v-for="access in accessGate" :key="access.id">
										<td class="text-left">{{ access.gate_name }}</td>
										<td class="text-right">{{ this.$numFrm(access.unique_count, 'int', this.event.country) }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row col-12 mt-2 mx-auto" v-if="components.reservations">
				<div class="col-12 mx-auto">
					<div class="card card-stats card-raised">
						<div class="card-header">
							<h5 class="card-category aml-font-12">RESERVATIONS ( {{ users.length }} )</h5>
							<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
								<span class="badge badge-primary" v-if="isLoading.reservations">
									<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
								</span>
							</div>
						</div>
						<div class="card-body">
							<div v-if="!isLoading.reservations">
								<el-input placeholder="Search by NAME / SOURCE / RESERVATION ID" prefix-icon="el-icon-search" v-model="searchResv"/>
<!--								<el-button @click="resetFilters">RESET ALL FILTERS</el-button>-->
								<el-table class="w-100 aml-font-12" ref="tableRef"
								  :data="users.filter(
									  data => !searchResv ||
									  	data.name.toString().toLowerCase().includes(searchResv.toLowerCase()) ||
									  	data.booking_reference.toString().toLowerCase().includes(searchResv.toLowerCase()) ||
									  	data.booking_reference2.toString().toLowerCase().includes(searchResv.toLowerCase()) ||
									  	data.prn.toString().toLowerCase().includes(searchResv.toLowerCase())
								  )"
								  resizable :height="1000" :stripe="true" :border="true"
								>
<!--									<el-table-column label="STALL" prop="stall_name" :filters="stalls" :filter-method="filterStallName"></el-table-column>-->
									<el-table-column label="GUEST NAME" prop="name" sortable width="200">
										<template #default="scope">
											<h5>{{ scope.row.name }}</h5>
											<h5>{{ scope.row.prn }} | {{ scope.row.booking_reference2 }}</h5>
											<p>{{ scope.row.tkt_outlet_details }}</p>
										</template>
									</el-table-column>
									<el-table-column label="RESERVATION ID" prop="booking_reference" sortable />
									<el-table-column label="TOTAL PAX" prop="pax" sortable />
									<el-table-column label="PAX" prop="tkt_qty" sortable />
									<el-table-column label="COMPAX" prop="tkt_info" sortable />
									<el-table-column label="SOURCE" prop="reference" sortable :filters="unique_sources" :filter-method="filterSource" />
									<el-table-column label="ZONE" prop="booking_reference2" sortable :filters="unique_zones" :filter-method="filterZone" />
									<el-table-column label="ISSUED COUNT" >
										<template #default="scope">{{ scope.row.cards.length }}</template>
									</el-table-column>
									<el-table-column label="REDEEMED COUNT" >
										<template #default="scope">{{ scope.row.redeemed }}</template>
									</el-table-column>
<!--									<el-table-column label="PERSONS">-->
								</el-table>
							</div>
							<div v-else>
								<el-skeleton :rows="5" animated />
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="row col-12 mt-2 mx-auto ">
				<div class="col-12 col-md-4 border-bottom" v-for="user in users">
				<el-card shadow="always" class="my-1">
					<div class="row" style="height: 220px !important; overflow:scroll; ">
						<!--									<el-image lazy :src="user.photo_url" :preview-src-list="[user.photo_url, user.photo_url2]" class="col-5"/>-->
						<div class="col-12 row mx-auto">
<!--							<p class="text_bold font-weight-bold aml-bg-gray2 text-center my-1">{{ user.name.toUpperCase() }}</p>-->
							<div class="col-12 row mx-auto">
								<h4 class="text_bold font-weight-bold aml-bg-gray2 text-center my-1 col-12">{{ user.name.toUpperCase() }}</h4>
								<h5 class=" text_bold text-center col-6"><b>{{ user.booking_reference2 }}</b></h5>
								<h6 class="mt-2 col-6"><b>{{ user.booking_reference }}</b></h6>
							</div>
							<div class="col-6">
								<h5>{{ user.reference }}</h5>
								<p class="my-1">{{ user.tkt_issuance_mode }} </p>
								<p class="my-1">{{ user.tkt_txn_at }}</p>
							</div>
							<div class="col-6 bg-light aml-border-radius-2">
								<h5 class="text-center">Issued: {{ user.cards.length }} / {{ user.pax }}</h5>
								<p v-for="u in user.whitelists">
									<i class="far fa-address-card"/>
									<span v-if="u.data">
												<span class="mx-2" @click="whitelistResetLogsLoad(u)">{{ u.data }}</span>
										<!--												<i class="fa fa-times cursor-pointer" @click="whitelistReset"/>-->
												<el-button class="" icon="fa fa-times" circle :loading="isLoading.users" @click="whitelistReset(u)"/>
											</span>
								</p>
							</div>
<!--							<p class="my-1"></p>-->
<!--							<p class="my-1">{{ user.booking_reference }}</p>-->
<!--							<p class="my-1">{{ user.tkt_issuance_mode }} {{ user.tkt_txn_at }}</p>-->
<!--							<p class="my-1">Total Pax: {{ user.pax }} | Issued: {{ user.cards.length }}</p>-->
<!--							<p class="my-1">Issued: {{ user.cards.length }}</p>-->

							<!--										<p class="my-1 cursor-pointer" ><i class="fas fa-history"/> Details</p>-->
						</div>
					</div>
				</el-card>
			</div>
			</div>

			<span class="aml-dialog">
				<el-dialog :title="'LOGS FOR: ' + u_.booking_reference " v-model="userLogsDialog">
					<el-card shadow="always" class="my-1 col-12 col-md-6 mx-auto">
						<div class="row " style="height: 150px !important;">
							<div class="col-12">
								<p class="text_bold font-weight-bold">{{ u_.name }}</p>
<!--								<h5 class="text_bold font-weight-bold">{{ u_.name }}</h5>-->
								<p class="my-1">{{ u_.booking_reference }}</p>
								<p class="my-1">{{ u_.booking_reference2 }}</p>
								<p>{{ u_.payment_status }} | <u>{{ u_.tkt_issuance_mode }}</u></p>
								<p>
									<i class="far fa-address-card"/>
									<span class="mx-2">{{ u_.data }}</span>
								</p>
							</div>
						</div>
						<div class="col-12 row mx-auto">
<!--							<p class="text_bold font-weight-bold aml-bg-gray2 text-center my-1">{{ user.name.toUpperCase() }}</p>-->
							<div class="col-12 row mx-auto">
								<h4 class="text_bold font-weight-bold aml-bg-gray2 text-center my-1 col-12">{{ u_.name }}</h4>
								<h5 class=" text_bold text-center col-6"><b>{{ u_.booking_reference2 }}</b></h5>
								<h6 class="mt-2 col-6"><b>{{ u_.booking_reference }}</b></h6>
							</div>
							<div class="col-6">
								<h5>{{ u_.reference }}</h5>
								<p class="my-1">{{ u_.tkt_issuance_mode }} </p>
								<p class="my-1">{{ u_.tkt_txn_at }}</p>
							</div>
							<div class="col-6 bg-light aml-border-radius-2">
								<p>
									<i class="far fa-address-card"/>
									<span class="mx-2">{{ u_.data }}</span>
								</p>
							</div>
						</div>
					</el-card>

					<div class="row">
						<el-card shadow="always" class="my-1 col-6 col-md-3 d-none">
							<div class="row">
								<h6>ISSUANCE LOGS</h6>
								<div class="table-responsive pb-0" style="height: 200px;">
									<table class="table aml-font-11">
										<thead class="text-primary">
											<th class="text-center">TAG IDs</th>
<!--											<th class="text-center">TIMESTAMP</th>-->
										</thead>
										<tbody>
											<tr v-if="u_.data">
												<td class="text-center cursor-pointer" @click="accessxLogsLoad(u_.data)">
													<u>{{ u_.data }}</u><br>{{ this.displaydt(u_.issued_at) }}
													<br><span class="badge badge-info">CURRENT</span>
														<br><i class="fas fa-chevron-right"/>
												</td>
											</tr>
											<tr v-for="ul in userLogs" :key="ul.id">
												<td class="text-center cursor-pointer" @click="accessxLogsLoad(ul.oldCardId)">
													<u>{{ ul.oldCardId }}</u><br>{{ this.displaydt(ul.timestampUnix) }}
													<br><span class="badge badge-primary text-white">REMOVED</span>
													<br><i class="fas fa-chevron-right"/>
												</td>
<!--												<td class="text-center">{{ this.displaydt(ul.timestampUnix) }}</td>-->
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</el-card>

						<el-card shadow="always" class="my-1 col-12">
							<div class="row">
								<h6>ACCESSX LOGS | <u>{{ cardAccessx }}</u></h6>
								<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
									<span class="badge badge-primary" v-if="isLoading.users">
										<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
									</span>
								</div>
								<el-table class="w-100 aml-font-11 aml-devices-table" :hover="true" :data="userAccessxLogs" >
									<el-table-column label="#" type="index" width="40"/>
									<el-table-column label="CARD ID" sortable prop="card_id">
										<template #default="scope">
											<div>{{ scope.row.card_id }}</div>
											<div>{{ scope.row.factory_id }}</div>
										</template>
									</el-table-column>
<!--									<el-table-column label="FACTORY ID" sortable prop="factory_id"/>-->
									<el-table-column label="CATEGORY" sortable prop="category_name"/>
									<el-table-column label="GATE" sortable prop="gate_name"/>
									<el-table-column label="DEVICE" sortable prop="device_print_id"/>
<!--									<el-table-column label="FACTORY ID" sortable prop="type"/>-->
<!--									<el-table-column label="DIRECTION" sortable prop="direction"/>-->
<!--									<el-table-column label="FACTORY ID" sortable prop="validate_type"/>-->
									<el-table-column label="TIMESTAMP" sortable prop="timestamp">
										<template #default="scope">
											<span>{{ displaydt(scope.row.timestamp) }}</span>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-card>
					</div>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userLogsDialog = false" v-if="!userLoading">Cancel</el-button>
<!--						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>-->
						</span>
					</template>
				</el-dialog>
			</span>
			<span class="aml-dialog">
				<el-dialog :title="'ITEM LIST FOR: ' + u_.booking_reference " v-model="userItemListDialog">
					<el-card shadow="always" class="my-1">
						<div class="row col-md-6 col-12 mx-auto" style="height: 250px !important;">
							<el-image lazy :src="u_.photo_url" :preview-src-list="[u_.photo_url, u_.photo_url2]" class="col-5"/>
							<div class="col-7">
								<p class="text_bold font-weight-bold">{{ u_.name }}</p>
								<p class="my-1">{{ u_.mobile }} | {{ u_.reference }}</p>
								<p class="my-1">{{ u_.booking_reference }}</p>
								<p class="my-1">{{ u_.booking_reference2 }}</p>
								<p>
									<i class="far fa-address-card"/>
									<span class="mx-2">{{ u_.data }}</span>
								</p>
							</div>
						</div>
					</el-card>

					<div class="row">
						<el-card shadow="always" class="my-1 col-12">
							<div class="row">
								<h6>ITEM CATEGORY LIST</h6>
								<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
									<span class="badge badge-primary" v-if="isLoading.users">
										<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
									</span>
								</div>
								<el-table class="w-100 aml-font-11 aml-devices-table" :hover="true" :data="userItemList" >
									<el-table-column label="#" type="index" width="40"/>
									<el-table-column label="CATEGORY" sortable prop="category_name"/>
								</el-table>
							</div>
						</el-card>
					</div>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userItemListDialog = false" v-if="!userLoading">Cancel</el-button>
<!--						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>-->
						</span>
					</template>
				</el-dialog>
			</span>

			<span class="aml-dialog">
				<el-dialog :title="'Reset Card of: ' + u_.booking_reference " v-model="userDialog">
					<el-form :model="u_" class="">
						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-right my-auto">PASSWORD</span>
								<el-input class="col-md-8 col-12" v-model="u_.pass"/>
							</span>
						</el-form-item>
					</el-form>

					<template #footer>
						<span class="dialog-footer">
						  <el-button @click="userDialog = false" v-if="!userLoading">Cancel</el-button>
						  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="userLoading" @click="wResetCall">Confirm</el-button>
						</span>
					</template>
				</el-dialog>
			</span>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import {
		ElDivider,
		ElBadge,
		ElMessage,
		ElImage,
		ElCard,
		ElInput,
		ElButton,
		ElTag,
		ElMessageBox,
		ElDialog, ElForm, ElFormItem, ElTable, ElTableColumn, ElSkeleton
	} from "element-plus";
	import moment from "moment/moment";

	export default {
		components: {
			ElSkeleton,
			ElTable,
			ElFormItem,
			ElForm,
			ElDialog,
			ElTag,
			ElButton,
			ElInput,
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElImage, ElCard,
			moment,
			ElDivider, ElBadge, ElMessageBox, ElMessage,ElTableColumn,
		},
		inject: ['$baseURL', '$accessxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					users: [],
					components: { users: true, },
					isLoading: { users: false, },
				},
				search: '',
				searchResv: '',
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				users: [],
				users_: [],
				userLoading: false,
				u_: {},
				userDialog: false,
				userLogs: [],
				userItemList: [],
				userLogsDialog: false,
				userItemListDialog: false,
				userAccessxLogs: [],
				cardAccessx: 0,
				distinctPachaDates: [],
				summary: {},
				accessGate: [],
				accessCatg: [],
				sources: [],
				reservations: [],
				unique_sources: [],
				unique_zones: [],
				resrvEntries: [],
				selectedDate: [],

				components: { users: false, event: false, reservations: true, accessGate: false, accessCatg: false, sources: false, summary: false, },
				isLoading: { users: false, event: true, reservations: false, accessGate: true, accessCatg: true, sources: true, summary: true, },
			};
		},
		methods: {
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			displaylocalUnixdt(dt) { return moment(dt).isValid() ? moment.unix(dt).local().format('HH:mm:ss') : ''; },
			displaylocaldt(dt) { return moment(dt).isValid() ? moment(dt).local().format('HH:mm:ss') : ''; },
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('ll') : ''; },
			displayday(dt) { return moment(dt).isValid() ? moment(dt).local().format('dddd') : ''; },
			resetFilters() {
				tableRef.value.clearFilter();
			},
			sortWhitelist() {
				this.users = [];
				let users_ = {};
				let entries = {};

				this.reservations.map(user =>{
					if(typeof users_[user.booking_reference] === 'undefined')	{
						users_[user.booking_reference] = user;
						users_[user.booking_reference].whitelists = [];
						users_[user.booking_reference].cards = [];
						users_[user.booking_reference].ids = {};
						users_[user.booking_reference].issued = 0;
						users_[user.booking_reference].redeemed = 0;
						users_[user.booking_reference].issuedAt = user.issue_at;
						users_[user.booking_reference].issuedAtUnix = user.issue_at_unix;
					}
					users_[user.booking_reference].whitelists.push(user);
					users_[user.booking_reference].ids[user.id] = { id: user.id, entries: [], issued: user.data, issuedAt: user.issue_at, issuedAtUnix: user.issue_at_unix, latestTap: null };
					if(user.data)	{
						users_[user.booking_reference].issued ++;
						users_[user.booking_reference].cards.push(user.data);
					}
				});

				this.resrvEntries.map( entry => {
					try {
						let reference = entry.reference.replace(/\u0000/g, "");
						if( typeof users_[reference] !== 'undefined' && typeof users_[reference].ids[parseInt(entry.prn)] !== 'undefined' ) {
							entry.id = parseInt(entry.prn);
							users_[reference].ids[parseInt(entry.prn)].entries.push(entry);
							users_[reference].ids[parseInt(entry.prn)].latestTap = entry.tapped_at_unix;
							users_[reference].redeemed++;
						}
					} catch (e) { console.log(e); }
				} );

				Object.values(users_).map(user => {
					this.users.push(user);
				});

				console.log(users_, this.users);
				this.isLoading.reservations = false;
			},
			filterSource: (value, row) => {
				return row.reference === value
			},
			filterZone: (value, row) => {
				return row.booking_reference2 === value
			},
			whitelistItemList(user) {
				this.u_ = user;
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/ItemList/',
					{ params: { code: user.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// debugger;
							this.userItemList = response.data.itemList;
							this.userItemListDialog = true;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			whitelistResetLogsLoad(user) {
				this.u_ = user;
				// console.log(user);
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/Issuance/Logs/',
					{ params: { wid: user.id, eventId: code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// debugger;
							this.userLogs = response.data.issuanceLogs.reverse();
							this.accessxLogsLoad(this.u_.data);
							this.userLogsDialog = true;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			accessxLogsLoad(cardId) {
				// debugger;
				this.cardAccessx = cardId;
				this.userAccessxLogs = [];
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/AccessX/Logs/',
					{ params: { cardId, eventId: code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.userAccessxLogs = response.data.entryLogs.reverse();
							this.isLoading.users = false;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			whitelistReset(user) {
				this.u_ = user;
				// this.userLoading = true;
				this.userDialog = true;

			},
			wResetCall() {
				this.userLoading = true;
				axios.post(
					this.$accessxURL + '/Whitelist/Issue/Reset/',
					{ id: this.u_.id, pass: this.u_.pass, },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						if(response.data.success) {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "done", type: 'info', grouping: true, duration: 5000,  });
							this.userDialog = false;
							this.u_.pass = '';

							if(this.u_.booking_reference && this.u_.booking_reference !== '' ) {
								this.search = this.u_.booking_reference;
								this.searchData();
							}
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000,  });
						}
						this.userLoading = false;
						// this.$emit('loading-complete');
					})
					.catch(e => { console.log(e); this.userLoading = false; ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>", type: 'danger', grouping: true, duration: 5000,  }); });
			},
			searchByDate(date) {
				this.search = date;
				this.selectedDate = date;
				// this.searchData();
				this.loadData('summary', date);
				this.loadData('sources', date);
				this.loadData('accessCatg', date);
				this.loadData('accessGate', date);
				this.searchData(date);
			},
			searchData(date) {
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.reservations = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Pacha/Whitelist/Data/',
					{ params: { code, date }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// this.users = response.data.users;
							// this.sortWhitelist(response.data.users);
							this.reservations = response.data.users;
							this.unique_sources = response.data.unique_sources;
							this.unique_zones = response.data.unique_zones;
							this.loadData('entries', date);
						}
						// this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.reservations = false;
					});
			},
			loadData(dataType, date) {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}

				switch(dataType) {
					case 'accessCatg':
						this.isLoading.accessCatg = true;
						break;
					case 'accessGate':
						this.isLoading.accessGate = true;
						break;
					case 'summary':
						this.isLoading.summary = true;
						break;
					case 'sources':
						this.isLoading.sources = true;
						break;
					case 'entries':
						this.isLoading.reservations = true;
						break;
					default:
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Incorrect DataType." + dataType, type: 'danger', grouping: true, duration: 5000, });
						break;
				}

				axios.post(
					this.$baseURL + '/Pacha/Load/Summary/',
					{ dataType, eventId: code, date }, { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// this.users = response.data.users;

							switch(dataType) {
								case 'accessCatg':
									this.accessCatg = response.data.accessCatg;
									this.isLoading.accessCatg = false;
									this.components.accessCatg = true;
									break;
								case 'accessGate':
									this.accessGate = response.data.accessGate;
									this.isLoading.accessGate = false;
									this.components.accessGate = true;
									break;
								case 'summary':
									this.summary = response.data.summaryData;
									console.log(this.summary);
									this.isLoading.summary = false;
									this.components.summary = true;
									break;
								case 'sources':
									this.sources = response.data.sourceData;
									console.log(this.sources);
									this.isLoading.sources = false;
									this.components.sources = true;
									break;
								case 'entries':
									this.isLoading.reservations = false;
									this.resrvEntries = response.data.entriesData;
									this.sortWhitelist(response.data.entriesData);
									this.components.reservations = true;
									break;
								default:
									ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>DataType Incorrect.", type: 'danger', grouping: true, duration: 5000, });
									break;
							}
						}

						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.rservations = false;
					});
			},
			whitelistDistinctPachaDate() {
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/Whitelist/Distinct/PachaDate/',
					{ params: { code, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// this.users = response.data.users;
							this.distinctPachaDates = response.data.whitelist_tktIssuanceMode;
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('event');
						this.event = response.data.event;
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.$emit('loading-complete');
						// this.loadData();
						this.whitelistDistinctPachaDate();
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
