<template>

	<div class="row justify-content-center">
		<div class="col-12 m-auto d-md-none d-inline">
			<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
				<div class="card-body pr-0 pl-2">
					<div class=" h-50 ">
						<h5 class="text-white text-center">{{ getPeriodName() }}</h5>
						<canvas :id="headerChartID3"/>
					</div>
					<div class="" v-if="components.txnSummary2">
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<!--<h5 class="aml-border-radius-1 bg-white stats-title text-primary my-auto px-1 py-2 mx-auto aml-font-11 text-center">SALES</h5>-->
								<span class="badge bg-white aml-orange-2 border-0">SALES</span>
							</div>
							<div class="col-9 pr-0">
								<div v-if="summaryInfo.sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline" v-if="summaryInfo.no_nfc_sale.amount > 0">NFC </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
											<!--<animated-number :value="1853"></animated-number>-->
											<strong class="">{{ $numFrm(summaryInfo.sale.amount, 'float', event.country)  }}</strong>
										</h5>
									</span>
									<!--<small class="col-2 text-right px-0 my-auto ml-2">(19,234)</small>-->
								</div>
								<div v-if="summaryInfo.no_nfc_sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline">POS </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
											<strong class="">{{ $numFrm(summaryInfo.no_nfc_sale.amount, 'float', event.country) }}</strong>
										</h5>
									</span>
								</div>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.topup.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TOPUPS</span>
							</div>
							<div v-if="e" class="col-9 px-0">
								<small class="d-inline" v-if="parseFloat(summaryInfo.topup.paidAmount) !== parseFloat(summaryInfo.topup.amount)">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div v-if="!e" class="col-9 px-0">
								<small class="d-inline" v-if="parseFloat(summaryInfo.topup.paidAmount) !== parseFloat(summaryInfo.topup.amount)">TOTAL </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<small v-if="parseFloat(summaryInfo.topup.paidAmount) !== parseFloat(summaryInfo.topup.amount)" class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</small>
									<strong v-else class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div class="col-12 px-0" v-if="!e && (parseFloat(summaryInfo.topup.paidAmount) !== parseFloat(summaryInfo.topup.amount))">
								<small class="d-inline">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.return.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">RETURNS</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.return.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>

					<div class="" v-if="components.attendanceSummary2">
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TODAY PRESENT</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<strong class="">{{ $numFrm(collegeAccessInfo.present, 'int', event.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TODAY ABSENT</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<strong class="">{{ $numFrm(collegeAccessInfo.absent, 'int', event.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row " style="min-height: 325px" v-if="components.txnData">

		<!--TXN MODE DATA-->
		<div class="col-12 col-md-4 d-flex" v-if="components.modeWiseData">
			<div class="card card-stats">
				<div class="card-header">
					<h5 class="card-category">MODE WISE ANALYTICS</h5>
					<div v-if="isLoading.mode" class="px-0" id="mode_analytics_badge" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body py-0">
					<div class="table-responsive">

						<table class="table aml-font-11 aml-table mb-0">
							<thead class="text-primary">
								<th>DESCRIPTION</th>
								<th class="text-right">SALES</th>
								<th class="text-right" v-if="event.useNfc === 1">TOPUPS</th>
							</thead>
							<tbody>
							<tr class="" v-if="parseFloat(modeInfo.sale.nfc) > 0 || parseFloat(modeInfo.topup.nfc) > 0">
								<td>NFC</td>
								<td class="text-right">
									{{ modeInfo.sale.nfc }}
									<div v-if="modeInfoAmounts.sale.nfc > 0"><small>{{ modeInfoCounts.sale.nfc }}</small></div>
								</td>
								<td class="text-right" v-if="event.useNfc === 1">
									{{ modeInfo.topup.nfc }}
									<div v-if="modeInfoAmounts.topup.nfc > 0"><small>{{ modeInfoCounts.topup.nfc }}</small></div>
								</td>
							</tr>
							<tr class="text-danger" v-if="parseFloat(modeInfo.reversal_sale.all) > 0 || parseFloat(modeInfo.reversal_topup.all) > 0">
								<td @click="loadSuspectData">REVERSALS</td>
								<td class="text-right">
									<span v-if="modeInfoAmounts.reversal_sale.all > 0">
										{{ modeInfo.reversal_sale.all }}
										<div class="text-danger"><small>{{ modeInfoCounts.reversal_sale.all }}</small></div>
									</span>
								</td>
								<td class="text-right">
									<span v-if="event.useNfc === 1 && modeInfoAmounts.reversal_topup.all > 0">
										{{ modeInfo.reversal_topup.all }}
										<div class="text-danger"><small>{{ modeInfoCounts.reversal_topup.all }}</small></div>
									</span>
								</td>
							</tr>
							<!--							<tr class="border-0" v-if="parseFloat(modeInfo.sale.nfc) > 0 || parseFloat(modeInfo.topup.nfc) > 0">-->
							<!--								<td class="text-right">1</td>-->
							<!--								<td class="text-right">2</td>-->
							<!--							</tr>-->
							<tr class="" v-if="parseFloat(modeInfo.sale.cash) > 0 || parseFloat(modeInfo.topup.cash) > 0">
								<td>CASH</td>
								<td class="text-right">{{ modeInfo.sale.cash }} <div v-if="modeInfoAmounts.sale.cash > 0"><small>{{ modeInfoCounts.sale.cash }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.cash }} <div v-if="modeInfoAmounts.topup.cash > 0"><small>{{ modeInfoCounts.topup.cash }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.card) > 0 || parseFloat(modeInfo.topup.card) > 0">
								<td>CARD</td>
								<td class="text-right">{{ modeInfo.sale.card }} <div v-if="modeInfoAmounts.sale.card > 0"><small>{{ modeInfoCounts.sale.card }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.card }} <div v-if="modeInfoAmounts.topup.card > 0"><small>{{ modeInfoCounts.topup.card }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.upi) > 0 || parseFloat(modeInfo.topup.upi) > 0">
								<td>UPI</td>
								<td class="text-right">{{ modeInfo.sale.upi }} <div v-if="modeInfoAmounts.sale.upi > 0"><small>{{ modeInfoCounts.sale.upi }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.upi }} <div v-if="modeInfoAmounts.topup.upi > 0"><small>{{ modeInfoCounts.topup.upi }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.coupon) > 0 || parseFloat(modeInfo.topup.coupon) > 0">
								<td>COUPON</td>
								<td class="text-right">{{ modeInfo.sale.coupon }} <div v-if="modeInfoAmounts.sale.coupon > 0"><small>{{ modeInfoCounts.sale.coupon }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.coupon }} <div v-if="modeInfoAmounts.topup.coupon > 0"><small>{{ modeInfoCounts.topup.coupon }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.comp) > 0 || parseFloat(modeInfo.topup.comp) > 0">
								<td>COMP/NC/FOC</td>
								<td class="text-right">{{ modeInfo.sale.comp }} <div v-if="modeInfoAmounts.sale.comp > 0"><small>{{ modeInfoCounts.sale.comp }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.comp }} <div v-if="modeInfoAmounts.topup.comp > 0"><small>{{ modeInfoCounts.topup.comp }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.topup.update) > 0">
								<td>ONLINE UPDATE</td>
								<td class="text-right">-</td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.update }} <div v-if="modeInfoAmounts.topup.update > 0"><small>{{ modeInfoCounts.topup.update }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.topup.tapx) > 0">
								<td>KIOSK UPDATE</td>
								<td class="text-right">-</td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.tapx }} <div v-if="modeInfoAmounts.topup.tapx > 0"><small>{{ modeInfoCounts.topup.tapx }}</small></div></td>
							</tr>
							<tr v-for="mode in Object.keys(uniqueModesExtra)">
								<td>{{ mode.replace(/_/g, " ").toUpperCase() }}</td>
								<td class="text-right">{{ modeInfo.sale[mode] }} <div v-if="modeInfoAmounts.sale[mode] > 0"><small>{{ modeInfoCounts.sale[mode] }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup[mode] }} <div v-if="modeInfoAmounts.topup[mode] > 0"><small>{{ modeInfoCounts.topup[mode] }}</small></div></td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--ACTIVATION DATA-->
		<div class="col-12 col-md-4" v-if="components.activationData">
			<div class="card card-stats card-raised">
				<div class="card-header">
					<h5 class="card-category">ACTIVATIONS</h5>
					<h4 class="card-title m-0 d-none">
						<small></small>
						<span id="activation_total_amount p-0">{{ cardsInfo.activation.amount }}</span>
					</h4>
					<div v-if="isLoading.cards" class="px-0" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body py-0">
					<div class="table-responsive p-0">
						<table class="table aml-font-11 m-0">
							<thead class="text-primary">
							<th>MODE</th>
							<th class="text-right">AMOUNT</th>
							<th class="text-right">COUNT</th>
							</thead>
							<tbody>
							<tr>
								<td>UNIQUE CARDS</td>
								<td class="text-right">-</td>
								<!--<td class="text-right" id="activation_card_count"><div class="ph-item m-0 p-0"><div class="ph-col-12 p-0"><div class="ph-row m-0"><div class="ph-col-12 m-0 big"></div></div></div></div></td>-->
								<td class="text-right">{{ cardsInfo.unique.count }}</td>
							</tr>
							<tr>
								<td>ACTIVATIONS</td>
								<td class="text-right">{{ cardsInfo.activation.amount }}</td>
								<td class="text-right">{{ cardsInfo.activation.count }}</td>
							</tr>
							<tr v-if="!this.e">
								<td>PERSO COUNT</td>
								<td class="text-right">{{ cardsInfo.perso.amount }}</td>
								<td class="text-right">{{ cardsInfo.perso.count }}</td>
							</tr>
							<tr>
								<td>AVG TOPUP</td>
								<td class="text-right">{{ cardsInfo.avgTopup.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgTopup.count }}</td>
							</tr>
							<tr>
								<td>AVG SALE</td>
								<td class="text-right">{{ cardsInfo.avgSale.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgSale.count }}</td>
							</tr>
							<tr>
								<td>AVG RETURN</td>
								<td class="text-right">{{ cardsInfo.avgReturn.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgReturn.count }}</td>
							</tr>
							<tr>
								<td>DEVICES</td>
								<td class="text-right"></td>
								<td class="text-right">{{ cardsInfo.devices.count }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--MODE DATA CHART-->
		<div class="col-12 col-md-4" v-if="components.modeWiseData && tapxSummary.length===0">
			<div class="position-md-absolute position-relative fixed-bottom">
				<div class="card-body py-0 d-block" style="height: 250px">
					<canvas :id="headerChartID4" class="mx-auto"/>
				</div>
			</div>
		</div>

		<!--TAPX SUMMARY-->
		<div class="col-12 col-md-3" v-if="tapxSummary.length > 0">
			<div class="position-md-absolute position-relative fixed-bottom">
				<div class="card card-stats">
					<div class="card-header">
						<h5 class="card-category">TAPX SUMMARY</h5>
						<div v-if="isLoading.mode" class="px-0" id="tapx_summary_badge" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
						</div>
					</div>
					<div class="card-body py-0 d-block" :style="tapxSummary.length>4 ? 'height: 250px' : 'height: 210px' ">
						<div class="table-responsive">
							<table class="table aml-font-11 aml-table mb-0">
								<thead class="text-primary">
									<th>DESCRIPTION</th>
									<th class="text-right">AMOUNT</th>
									<th class="text-right">COUNT</th>
								</thead>
								<tbody>
									<tr v-for="data in tapxSummary">
										<td class="text-uppercase">{{ data.status }}</td>
										<td class="text-right">{{ $numFrm(data.amount) }}</td>
										<td class="text-right">{{ $numFrm(data.count) }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>


	<div class="row justify-content-center">
		<!--MAKER CHECKER PENDING REQUESTS-->
		<div class="col-lg-4 col-md-6 d-none">
			<div class="card1 card-chart">
				<div class="card-header">
					<h5 class="card-category">MAKER CHECKER PENDING REQUESTS</h5>
					<div class="px-0" id="maker_checker_badge" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body" id="maker_checker_data" style="max-height: 275px; overflow: hidden;">
					<div class="table-responsive">
						<table class="table aml-font-11">
							<thead class="text-primary">
							<th><strong>VENDOR</strong></th>
							<th><strong>STALL</strong></th>
							<th class="text-right"><strong>REQUESTS</strong></th>
							</thead>
							<tbody id="maker_checker_tbody">

							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--CATEGORY WISE ACCESS-->
		<div class="col-md-6 col-12 d-flex" v-if="components.accessCatgwise">
			<div class="card card-chart aml-print-dashboard-card mx-auto">
				<div class="card-header aml-border-radius-2">
					<h5 class="card-category d-inline">CATEGORY WISE ACCESS</h5>
					<div v-if="isLoading.accessCatg" class="px-0 d-inline pull-right">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body aml-height-275" id="catg_access_data">
					<div class="table-responsive pb-0">
						<table class="table aml-font-11">
							<thead class="text-primary">
								<th class="text-left">GATE</th>
								<th class="text-left">CATEGORY</th>
								<th class="text-center">TYPE</th>
<!--								<th class="text-right">DIRECTION</th>-->
								<th class="text-right">COUNT</th>
								<th class="text-right">UNIQUE</th>
							</thead>
							<tbody>
							<tr v-for="access in accessCatg" :key="access.id">
								<td class="text-left">{{ access.gate_name }}</td>
								<td class="text-left">{{ access.category_name }}</td>
								<td class="text-center justify-content-end"><span class="col">{{ access.type.toUpperCase() }}</span> | <span class="col">{{ access.direction }}</span></td>
<!--								<td class="text-right">{{ access.direction }}</td>-->
								<td class="text-right">{{ this.$numFrm(access.count, 'int', this.event.country) }}</td>
								<td class="text-right">{{ this.$numFrm(access.unique_count, 'int', this.event.country) }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--GATE WISE ACCESS-->
		<div class="col-md-6 col-12 d-flex" v-if="components.accessGatewise">
			<div class="card card-chart aml-print-dashboard-card mx-auto">
				<div class="card-header aml-border-radius-2">
					<h5 class="card-category d-inline">GATE WISE ACCESS</h5>
					<div v-if="isLoading.accessGate" class="px-0 d-inline pull-right">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body aml-height-275" id="gate_access_data">
					<div class="table-responsive">
						<table class="table aml-font-11">
							<thead class="text-primary">
								<th class="text-left">GATE</th>
								<th class="text-center">TYPE</th>
<!--								<th class="text-right">DIRECTION</th>-->
								<th class="text-right">COUNT</th>
								<th class="text-right">UNIQUE</th>
							</thead>
							<tbody>
								<tr v-for="access in accessGate" :key="access.id">
									<td class="text-left">{{ access.name }}</td>
									<td class="text-center justify-content-end"><span class="col">{{ access.type.toUpperCase() }}</span> | <span class="col">{{ access.direction }}</span></td>
<!--									<td class="text-right">{{ access.direction }}</td>-->
									<td class="text-right">{{ this.$numFrm(access.count, 'int', this.event.country) }}</td>
									<td class="text-right">{{ this.$numFrm(access.unique_count, 'int', this.event.country) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center d-print-none mt-4" v-if="components.txnData">
		<!--last 5 TXN-->
		<div class="col-12">
			<div class="card card-chart">
				<div class="card-header">
					<h5 class="card-category">LATEST TRANSACTIONS</h5>
					<div v-if="isLoading.last5" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body aml-height-275">
					<div class="table-responsive">
						<table class="table aml-font-11 ">
							<thead class="text-primary">
								<th class="text-center">TYPE</th>
								<th class="text-left">STALL</th>
								<th class="text-center">DEVICE</th>
								<th class="text-right">AMOUNT</th>
								<th class="text-center" v-if="event.useNfc === 1">CARD</th>
								<th class="text-center">MODE</th>
								<th class="text-center">TIMESTAMP</th>
							</thead>
							<tbody>
								<tr v-for="txn in last5" :key="txn.id">
									<td class="text-center">{{ txn.type.toUpperCase() }}</td>
									<td class="text-left">{{ txn.name }}</td>
									<td class="text-center">{{ txn.device_printId }}</td>
									<td class="text-right">{{ $numFrm(txn.amount, 'float', event.country) }}</td>
									<td class="text-center" v-if="event.useNfc === 1">{{ parseInt(txn.card_id) === 0 ? '' : txn.card_id }}</td>
									<td class="text-center">{{ txn.invoice }}</td>
									<td class="text-center">{{ txn.txn_at.replace('T', ' ').replace('.000Z', '') }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center mt-4" v-if="components.popularCharts">

		<!--POPULAR 5 FOODS -->
		<div class="col-10 col-md-4">
			<div class="">
				<div class="text-center my-4"><h6>Popular Foods</h6></div>
				<div class="card-body py-0 d-block" style="height: 250px">
					<canvas :id="foodChartID" class="mx-auto"/>
				</div>
			</div>
		</div>

		<!--POPULAR 5 DRINKS -->
		<div class="col-10 mx-auto col-md-4">
			<div class="">
				<div class="text-center my-4"><h6>Popular Drinks</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="drinkChartID" class="mx-auto"/>
				</div>
			</div>
		</div>
		<!--POPULAR 5 OVERALL -->
		<div class="col-10 col-md-4 mx-auto">
			<div class="">
				<div class="text-center my-4"><h6>Popular Overall Items</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="overallChartID" class="mx-auto"/>
				</div>
			</div>
		</div>

	</div>

	<div class="row justify-content-center " v-if="components.popularCharts">
		<!--POPULAR 5 STALLS -->
		<div class="col-12">
			<div class="">
				<div class="text-center mb-4"><h6>Popular Stalls</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="stallChartID" class="mx-auto"/>
				</div>
			</div>
		</div>
	</div>

	<!--REVERSALS LIST-->
	<el-dialog width="75%" v-model="suspDialog" v-if="loginUser.loginType === 'admin' || loginUser.loginType === 'reports'">
		<template #title>
			<span class="el-dialog__title">REVERSED LIST</span>
			<el-button @click="downloadCSVData"><i class="fas fa-download"/> Download Dump</el-button>
		</template>
		<span class="d-block mt-3">
			<el-table :data="suspCards" :border="true" style="width: 100%">
				<el-table-column type="expand">
					<template #default="scope">
						<el-table :data="scope.row.transactions" :border="true">
							<el-table-column label="TYPE" prop="txn_type" align="center"/>
							<el-table-column sortable label="STATUS" align="center" prop="txn_status">
								<template #default="scope">
									<span class="badge "
										  :class="
											  scope.row.txn_status==='completed' ? 'badge-success' :
											  scope.row.txn_status==='void' ? 'badge-danger' :
											  scope.row.txn_status==='reversed' ? 'badge-warning' :
											  'badge-default'
											"
									>
										{{ scope.row.txn_status }}
									</span>
								</template>
							</el-table-column>
							<el-table-column sortable label="AMOUNT" align="center" prop="txn_amount">
								<template #default="scope">
									{{ $numFrm(scope.row.txn_amount, 'float', event.country) }}
								</template>
							</el-table-column>
							<el-table-column label="DEVICE" prop="txn_device_print_id" align="center"/>
							<el-table-column label="CARD UID" prop="txn_card_uid" align="center"/>
							<el-table-column label="TXN COUNTER" prop="txn_counter" align="center"/>
							<el-table-column sortable label="MODE" align="center" prop="txn_mode">
								<template #default="scope">
									<span class="badge badge-info">{{ scope.row.txn_mode }}</span> | {{ scope.row.txn_invoice }}
								</template>
							</el-table-column>

							<el-table-column sortable label="TIME" align="center">
								<template #default="scope">
									{{ scope.row.txn_at.replace('T', ' ').replace('.000Z', '') }}
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="!" align="center" width="50px">
					<template #default="scope">
						<div class="w-100 text-center"> <i class="fa fa-exclamation-circle text-danger fa-2x" v-if="susStyle(scope.row)"/> </div>
					</template>
				</el-table-column>
				<el-table-column label="CARD" prop="card_id">
					<template #default="scope">
						<span>{{ scope.row.card_id }}</span>
					</template>
				</el-table-column>
				<el-table-column label="CASH REVERSALS" >
					<template #default="scope">
						{{ $numFrm(scope.row.revCashT, 'float', event.country) }} (<small>{{ $numFrm(scope.row.revCashTCount, 'float', event.country) }}</small>)
						<div v-if="scope.row.revCashTCount>2"><i class="fa fa-exclamation-triangle text-danger"/></div>
					</template>
				</el-table-column>
				<el-table-column label="TOPUP REVERSALS" >
					<template #default="scope">
						{{ $numFrm(scope.row.revT, 'float', event.country) }} (<small>{{ $numFrm(scope.row.revTCount, 'float', event.country) }}</small>)
					</template>
				</el-table-column>
				<el-table-column label="SALE REVERSALS" >
					<template #default="scope">
						{{ $numFrm(scope.row.revS, 'float', event.country) }} (<small>{{ $numFrm(scope.row.revSCount, 'float', event.country) }}</small>)
						<div v-if="scope.row.revSCount>4"><i class="fa fa-exclamation-triangle text-danger"/></div>
					</template>
				</el-table-column>
				<el-table-column label="TOPUPS" >
					<template #default="scope">
						{{ $numFrm(scope.row.totT, 'float', event.country) }} (<small>{{ $numFrm(scope.row.totTCount, 'float', event.country) }}</small>)
					</template>
				</el-table-column>
				<el-table-column label="SALES" >
					<template #default="scope">
						{{ $numFrm(scope.row.totS, 'float', event.country) }} (<small>{{ $numFrm(scope.row.totSCount, 'float', event.country) }}</small>)
					</template>
				</el-table-column>
			</el-table>
		</span>
	</el-dialog>


</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
		AsyncWorldMap,
	} from "@/components";
	import headerChart_Summary from "./HeaderChart_summary";
	// import {headerChart as headerChart3} from "./HeaderChart2";
	import {headerChart as headerChart4} from "./HeaderChart3";
	import headerChart from "./HeaderChart";
	import HeaderChart_Dash from "./HeaderChart_Dash";
	import TopAttendance from "./colg-attendance/topAttendance";
	import dataChart from "./dataChart";
	import { barChart1 as attendanceChart, barChart2 as attendanceChart2 } from "./BarChart";
	import axios from "axios";

	import { ElMessage, ElMessageBox } from "element-plus";

	import moment from 'moment'

	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElDatePicker, ElSkeleton, ElTag,
	} from "element-plus";

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			AsyncWorldMap,
			TopAttendance,
			// SkeletonLoaderVue,
			// VueLoaders, VueLoadersBallBeat
			moment,
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider, ElDatePicker,
			ElSkeleton, ElTag,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType', '$armourxURL', '$setIntervalFn', '$getIntervalFn',],
		emits: ['event-fetched', 'summary-fetched', 'colgaccess-fetched', 'newDayClosedTurnOFF', 'loading-complete'],
		props: ['user', 'periodType', 'newDayClosed'],
		data() {
			return {
				v1: null,
				options: [ { value: 'Vue.js', label: 'JavaScript' }, ],

				loginUser: {},

				clgCategories: [ { value: 0, label: 'ALL' } ],
				clgCatgDisabled: true,
				clgHalls: [ { value: 0, label: 'ALL' } ],
				clgHallDisabled: true,
				clgSections: [ { value: 0, label: 'ALL' } ],
				clgSecDisabled: true,
				clgSubjects: [ { value: 0, label: 'ALL' } ],
				clgSubDisabled: true,
				value: null,

				interval_fn: -1,

				suspDialog: false,
				suspCards: [],
				suspTxns: [],

				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
					collegeAccessInfo: { present: 0, absent: 0 },
					attendancePersons: {},
					attendancePersonsInfo: {},
					topAttendance: [],
					colgFilters: { catg: 0, hall: 0, section: 0, subject: 0 },
					colgFilters2: { catg: -1, hall: -1, section: -1, subject: -1 },
					clgCategories: [ { value: 0, label: 'ALL' } ],
					clgHalls: [ { value: 0, label: 'ALL' } ],
					clgSections: [ { value: 0, label: 'ALL' } ],
					clgSubjects: [ { value: 0, label: 'ALL' } ],
					cards: {},
					cardsInfo: {
						unique: { amount: '-', count: '-' },
						activation: { amount: '-', count: '-' },
						perso: { amount: '-', count: '-' },
						avgTopup: { amount: '-', count: '-' },
						avgSale: { amount: '-', count: '-' },
						avgReturn: { amount: '-', count: '-' },
						devices: { amount: '-', count: '-' },
					},
					modeInfo: {
						topup: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
						},
						sale: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
						},
						reversal_sale: { all: '' },
						reversal_topup: { all: '' },
					},
					modeInfoAmounts: {
						topup: {
							// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
						},
						sale: {
							// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
						},
						reversal_sale: { all: 0 },
						reversal_topup: { all: 0 },
						return: { all: 0 },
					},
					modeInfoCounts: {
						topup: {
							// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
						},
						sale: {
							// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
						},
						reversal_sale: { all: 0 },
						reversal_topup: { all: 0 },
						return: { all: 0 },
					},
					tapxSummary: [],
					dashSettings: {
						addActivationTopup: 1,
						addCompTopup: 1,
						addCouponTopup: 1,
						autoDayClose: 0,
					},
					md: { v: [], d: [] },
					last5: [],
					accessGraph: [],
					accessCatg: [],
					accessGate: [],
					makerChecker: [],
					txnGraph: [],
					tGraph: {},
					popularFoods: { v: [], d: [] },
					popularDrinks: { v: [], d: [] },
					popularOverall: { v: [], d: [] },
					popularStalls: { v: [], d: [] },

					dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
					weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

					eventLoad: false,
					isLoading: {
						summary: true, cards: true, last5: true, mode: true,
						accessGate: true, accessCatg: true, makerChecker: true, attendancePersonsInfo: true,
						attdWeeklyGraph: true, topAttendance: true,
					},

					liveLinkAuthenticated: false,

					components: {
						txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
						modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,

						attendanceGraph: false, attendanceSummary1: false, attendanceSummary2: false, attendanceWeeklyGraph:false,
					},
				},
				liveLinkAuthenticated: false,

				event: { name: '', country: 'india' },
				e: this.$route.query.e,
				attendancePersons: {},
				attendancePersonsInfo: {},
				summary: {},
				dashSettings: {
					addActivationTopup: 1,
					addCompTopup: 1,
					addCouponTopup: 1,
					autoDayClose: 0,
				},
				summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
				collegeAccessInfo: { present: 0, absent: 0 },
				colgFilters: { catg: 0, hall: 0, section: 0, subject: 0 },
				colgFilters2: { catg: -1, hall: -1, section: -1, subject: -1 },
				cards: {},
				cardsInfo: {
					unique: { amount: '-', count: '-' },
					activation: { amount: '-', count: '-' },
					perso: { amount: '-', count: '-' },
					avgTopup: { amount: '-', count: '-' },
					avgSale: { amount: '-', count: '-' },
					avgReturn: { amount: '-', count: '-' },
					devices: { amount: '-', count: '-' },
				},
				uniqueModesExtra: [],
				modeInfo: {
					topup: {
						// cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
					},
					sale: {
						// cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
					},
					reversal_sale: { all: '' },
					reversal_topup: { all: '' },
				},
				modeInfoAmounts: {
					topup: {
						// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					},
					reversal_sale: { all: 0 },
					reversal_topup: { all: 0 },
					return: { all: 0 },
				},
				modeInfoCounts: {
					topup: {
						// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						// cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					},
					reversal_sale: { all: 0 },
					reversal_topup: { all: 0 },
					return: { all: 0 },
				},
				tapxSummary: [],
				md: { v: [], d: [] },
				last5: [],
				accessGraph: [],
				accessCatg: [],
				accessGate: [],
				makerChecker: [],
				txnGraph: [],
				tGraph: {},
				popularFoods: { v: [], d: [] },
				popularDrinks: { v: [], d: [] },
				popularOverall: { v: [], d: [] },
				popularStalls: { v: [], d: [] },

				components: {
					txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
					modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,

					attendanceGraph: false, attendanceSummary1: false, attendanceSummary2: false, attendanceWeeklyGraph:false,
					topAttendance: false,
				},

				dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
				weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

				eventLoad: false,
				isLoading: { summary: true, cards: true, last5: true, mode: true, accessGate: true, accessCatg: true, makerChecker: true, attendancePersonsInfo: true, attdWeeklyGraph: true, },

				attendanceChartID: "attendanceChart",
				headerChartID: "headerChart",
				headerChartID2: "headerChart2",
				headerChartID3: "headerChart3",
				headerChartID4: "headerChart4",
				foodChartID: "foodChart",
				drinkChartID: "drinkChart",
				overallChartID: "overallChart",
				stallChartID: "stallChart",

				txnChart: headerChart,
				modeChart: headerChart4,
				// summaryChart: headerChart3,
				dataChart: dataChart,
				attendanceChart: attendanceChart,
				attendanceChart2: attendanceChart2,

				progress: 0,
				activeUsersChartID: "activeUsersChart",
				emailsCampaignChartID: "emailsCampaignChart",
				activeCountriesChartID: "activeCountriesChart",

				lastTxnId: 0,
				secondLastTxnId: 0,
			};
		},
		methods: {
			setComponentsDisplay () {
				this.setDefault('components');

				switch(this.event.type) {
					case 'event':
						this.components.txnGraph = this.event.useNfc === 1;
						this.components.activationData = this.event.useNfc === 1;
						this.components.txnSummary1 = true;
						this.components.txnSummary2 = true;
						this.components.modeWiseData = true;
						this.components.txnData = true;
						this.components.accessCatgwise = this.event.useAccessx === 1;
						this.components.accessGatewise = this.event.useAccessx === 1;
						this.components.popularCharts = true;
						break;
					case 'clg-access':
						this.components.attendanceGraph = true;
						this.components.attendanceSummary1 = true;
						this.components.attendanceSummary2 = true;
						this.components.attendanceWeeklyGraph = true;
						this.components.topAttendance = true;
						break;
				}
			},
			clgFilterCategory(a) {
				if(a === undefined) return ;
				this.colgFilters.catg = a;

				this.$refs.filterHall.select(0);
				this.$refs.filterSection.select(0);
				this.$refs.filterSubject.select(0);
				this.clgHallDisabled = ( a === 0 );
				this.clgSecDisabled = true;
				this.clgSubDisabled = true;

				if(a)   this.setColgAttFilters('category');
			},
			clgFilterHall(a) {
				if(a === undefined) return ;

				this.colgFilters.hall = a;
				this.$refs.filterSection.select(0);
				this.$refs.filterSubject.select(0);
				this.clgSecDisabled = ( a === 0 );
				this.clgSubDisabled = true;

				if(a)   this.setColgAttFilters('hall');
			},
			clgFilterSection(a) {
				if(a === undefined) return ;

				this.colgFilters.section = a;
				this.$refs.filterSubject.select(0);
				this.clgSubDisabled = ( a === 0 );

				if(a)   this.setColgAttFilters('section');
			},
			clgFilterSubject(a) {
				if(a === undefined) return ;
				this.colgFilters.subject = a;
			},
			setLoading() {
				for (const [key, value] of Object.entries(this.isLoading)) {
					this.isLoading[key] = true;
				}
			},
			setColgAttFilters(type) {
				switch (type) {
					case 'category':
						this.clgHalls = this.attendancePersons.categories[this.colgFilters.catg].hallsList;
						break;
					case 'hall':
						this.clgSections = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sectionsList;
						break;
					case 'section':
						this.clgSubjects = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjectsList;
						break;
					case 'subject':
						break;
					default:
						break;

				}
			},
			getPeriodName() {
				if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0 && this.periodType.toLowerCase() === 'yesterday')
					return this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].name;
				return this.periodType.toUpperCase();
			},
			dataLoad() {
				if(this.$isCheckEventType('fnb', this.event.type)) {
					this.loadData('cards');
					// this.loadData('summary');
					this.loadData('mode');
					this.loadData('last5');
					this.loadData('txnGraph');
					this.loadData('popularFoods');
					this.loadData('popularDrinks');
					this.loadData('popularOverall');
					this.loadData('popularStalls');
					this.loadData('tapxSummary');
					if (this.event.useMakerChecker === 1) this.loadData('makerChecker');
				}
				if(this.event.useAccessx === 1) {
					if (this.$isCheckEventType('fnb', this.event.type)) {
						this.loadData('accessCatg');
						this.loadData('accessGate');
					}
				}
				if (this.$isCheckEventType('clg-access', this.event.type)) {
					this.loadData('collegeAccessInfo');
					this.loadData('accessGraph');
					this.loadData('topAttendance');
					this.loadData('attendancePersonsInfo'); this.isLoading['attdWeeklyGraph'] = true;
				}
			},
			setEventData(data) {
				this.event = data.event;
				this.dashSettings = data.dashSettings;
				// this.$event = this.event;
				this.eventLoad = true;
				// debugger;
				switch (this.event.type) {
					case 'event':
					case 'fnb':
						// this.loadData('dashSettings');
						// this.updateLocal('dashSettings', {data: [this.dashSettings] });
						let t = [0];
						// if(this.event.days) {
						// 	t = this.event.days.split(',');
						// 	if(t.length > 0) this.lastTxnId = parseInt(t[t.length - 1]) ?? 0;
						// 	else this.lastTxnId = 0;
						// } else this.lastTxnId = 0;

						if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
							this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId;
							if(this.event.dayCloseLogs.length > 1)	this.secondLastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 2].txnId;
							else this.secondLastTxnId = 0;
						} else this.lastTxnId = 0;

						// console.log(this.event.dayCloseLogs);

						// this.loadData('cards');
						// this.loadData('summary');

						// debugger;

						if( this.dashSettings.useLivelinkaccess === 1 && this.$route.query.e ) {
							let u_LL = JSON.parse(localStorage.getItem('user_LiveLink'));
							if (!u_LL || (u_LL && Object.keys(u_LL).length === 0 )) {
								ElMessageBox.prompt('Enter the Whatsapp Number registered', '', {
									confirmButtonText: 'OK',
									cancelButtonText: 'Cancel',
									inputPattern: /^\d+$/,
									inputErrorMessage: 'Invalid Mobile',
								})
									.then((mobile) => {
										try { this.sendOTP(mobile.value); ElMessage({ type: 'success', message: `OTP sent to: ${mobile.value}`, }); }
										catch (e) { return ElMessage({ type: 'warning', message: 'OTP not sent. Try again', }) }

										ElMessageBox.prompt('Enter OTP Sent to ' + mobile.value, 'VERIFY', {
											confirmButtonText: 'OK',
											cancelButtonText: 'Cancel',
											inputErrorMessage: 'Invalid Mobile',
										})
											.then((otp) => { this.verifyOTP(otp.value, mobile.value); })
											.catch(() => {
												ElMessage({
													type: 'warning',
													message: 'Input Canceled',
												})
											})
									})
									.catch(() => {
										ElMessage({
											type: 'warning',
											message: 'Input canceled',
										})
									})
							}
						}
						else {
							this.loadData('summary');
							this.$emit('loading-complete');
						}
						break;
					case 'clg-access':
						this.dataLoad();
						break;
					case 'armourx':
						this.loadData('armourx-summary', this.event.type);
						break;
					default:
						break;
				}
				// if (this.$isCheckEventType('fnb', this.event.type)) this.loadData('summary');
				// if (this.$isCheckEventType('clg-access', this.event.type)) {
				// 	this.dataLoad();
				// 	// this.setColgFilterDefaults();
				// }
			},
			loadDetails() {
				let code = 0;
				let url = '/Event/Details/';
				if(this.e)	code = this.e;
				else {
					let u = this.loginUser;
					switch (u.loginType) {
						case 'event':
							url = '/Event/Details/';
							code = u.id;
							break;
						case 'admin':
						case 'reports':
							url = '/Event/Details/';
							code = this.$route.params.code;
							break;
						case 'operator':
							url = '/Users/Details/';
							code = u.id;
							break;
						default:
							return;
							break;
					}
				}
				// url = '/' + (u.product === 'armourx' ? 'Users' : 'Event') +'/Details/';
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.setDefault('event');
						if(!this.e) {
							switch (this.loginUser.loginType) {
								case 'event':
								case 'admin':
								case 'reports':
									this.setEventData(response.data);
									break;
								case 'operator':
									// url = 'Users/Details/';
									break;
								default:
									return;
									break;
							}
						} else {
							this.setEventData(response.data);
						}
						// console.log(this.event.country);
					} else {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>" + response.data.message,
							type: 'warning',
							grouping: true,
							duration: 10000,
						});
						console.log("message:", response.data.message);
					}

				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
				});
			},
			updateLocal(dataType, d) {
				switch (dataType) {
					case 'dashSettings':
						this.setDefault('dashSettings');
						if(d.data.length > 0) this.dashSettings = d.data[0];

						break;
					case 'summary':
						this[dataType] = d.data;
						this.setDefault('summaryInfo');

						d.data.forEach(info => {
							if(info.type_ !== 'activated_card_amount')
								this.summaryInfo[info.type_] = { amount: info.amount, count: info.count  };
							else this.cards.activated_card_amount = info.amount;
						});

						// debugger;
						this.summaryInfo.topup.paidAmount = this.summaryInfo.topup.amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.addActivationTopup ? 0 : this.cards.activated_card_amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.addCompTopup ? 0 : this.modeInfoAmounts.topup.comp;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.addCouponTopup ? 0 : this.modeInfoAmounts.topup.coupon;
						this.dataLoad();
						break;
					case 'collegeAccessInfo':
						// this.setDefault('accessInfo');

						this.collegeAccessInfo.present = d.data[0].present_count;
						this.collegeAccessInfo.absent = d.data[0].total_count - d.data[0].present_count;
						break;
					case 'cards':
						this[dataType] = d.data[0];

						this.cardsInfo = {
							unique: { amount: '-', count: this.$numFrm(this.cards.card_count, 'int', this.event.country) },
							activation: { amount: this.$numFrm(this.cards.activated_card_amount, 'float', this.event.country), count: this.$numFrm(this.cards.activated_card_count, 'int', this.event.country) },
							perso: { amount: '-', count: this.$numFrm(this.cards.perso_count, 'int', this.event.country) },
							avgTopup: { amount: this.summaryInfo.topup.amount ? this.$numFrm(this.summaryInfo.topup.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.topup.count ? this.$numFrm(this.summaryInfo.topup.count / this.cards.card_count, 'float', this.event.country) : '-' },
							avgSale: { amount: this.summaryInfo.sale.amount ? this.$numFrm(this.summaryInfo.sale.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.sale.count ? this.$numFrm(this.summaryInfo.sale.count / this.cards.card_count, 'float', this.event.country) : '-' },
							avgReturn: { amount: this.summaryInfo.return.amount ? this.$numFrm(this.summaryInfo.return.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.return.count ? this.$numFrm(this.summaryInfo.return.count / this.cards.card_count, 'float', this.event.country) : '-' },
							devices: { amount: 0, count: this.$numFrm(this.cards.device_count, 'int', this.event.country) },
						};
						// this.loadData('summary');
						// this.loadData('mode');
						break;
					case 'mode':
						this.setDefault('modeInfo'); this.setDefault('modeInfoAmounts'); this.setDefault('md');
						// console.log('modeinfo', d.data)
						d.data.forEach(info => {
							try{
								// this.modeInfo[info.type][info.mode.toLowerCase()] = this.$numFrm(info.amount, 'float', this.event.country);
								// this.md.v.push(info.type + "|" + info.mode.toLowerCase());
								// this.md.d.push(info.amount);


								switch (info.type) {
									case 'topup':
									case 'sale':
										this.modeInfo[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = this.$numFrm(info.amount, 'float', this.event.country);
										this.modeInfoAmounts[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = info.amount;
										this.modeInfoCounts[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = this.$numFrm(info.count, 'int', this.event.country);
										this.md.v.push(info.type + "|" + info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase());
										this.md.d.push(info.amount);
										if(info.type === 'topup' && !['nfc', 'cash', 'card', 'comp', 'coupon', 'upi', 'update', 'tapx'].includes(info.invoice.toLowerCase()))
											this.uniqueModesExtra[info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = true;
										break;
									case 'reversal_topup':
									case 'reversal_sale':
										// debugger;
										this.modeInfo[info.type][info.invoice.toLowerCase()] = this.$numFrm(info.amount, 'float', this.event.country);
										this.modeInfoAmounts[info.type][info.invoice.toLowerCase()] = info.amount;
										this.modeInfoCounts[info.type][info.invoice.toLowerCase()] = this.$numFrm(info.count, 'int', this.event.country);
										this.md.v.push(info.type + "|" + info.invoice.toLowerCase());
										this.md.d.push(info.amount);
										break;
								}
							}
							catch (e) { console.log('mode_info error', e); }
						});
						console.log('loadDashboardData:mode', this.uniqueModesExtra);
						// console.log('modeinfo after', this.modeInfo, this.modeInfoAmounts, this.modeInfoCounts)
						if(this.summaryInfo.topup.amount > 0) {
							this.modeInfo.topup.nfc = this.$numFrm(this.summaryInfo.topup.amount, 'float', this.event.country);
							this.modeInfoAmounts.topup.nfc = this.summaryInfo.topup.amount;
							this.modeInfoCounts.topup.nfc = this.$numFrm(this.summaryInfo.topup.count, 'int', this.event.country);
						}
						// this.dataLoad();
						break;
					case 'last5':
					case 'accessCatg':
					case 'accessGate':
					case 'makerChecker':
						this[dataType] = d.data;
						break;
					case 'txnGraph':
						this.setDefault('tGraph');
						// debugger;
						// for (const [key, value] of Object.entries(d.data[0])) {
						d.data.forEach( value => {
							// this[dataType].push(value);
							if(typeof this.tGraph[value.type] !== 'undefined') {
								this.tGraph[value.type][value.hour] = value.amount;
							} else {
								this.tGraph[value.type] = [
															0, 0, 0, 0, 0, 0,
															0, 0, 0, 0, 0, 0,
															0, 0, 0, 0, 0, 0,
															0, 0, 0, 0, 0, 0,
														];
								this.tGraph[value.type][value.hour] = value.amount;
							}
						});
						// console.log(this.tGraph);

						let allHours = []; for(let i = 0; i < 24; i++) { allHours.push(i); }

						let nowHour = moment(new Date()).local().hour();

						let totalHours = this.event.periodType === ''

						let lastHours = [], cH = nowHour;
						for(let j = 0; j < 12; j++) {
							lastHours.push(nowHour--);
							if(nowHour === -1)	nowHour += 24;
						}
						lastHours = lastHours.reverse();
						// console.log(lastHours);

						let tg = {};
						lastHours.forEach( h => {
							for (const [key, value] of Object.entries(this.tGraph)) {
								tg[key] = [];
								lastHours.forEach( lh => { tg[key].push(this.tGraph[key][lh]) });
							}
						});
						// debugger;
						HeaderChart_Dash.createChart(this.headerChartID, lastHours, tg);
						// this.txnChart.createChart(this.headerChartID, tg.sale, lastHours);

						break;
					case 'accessGraph':
						this[dataType] = [];
						for (const [key, value] of Object.entries(d.data[0])) {
							this[dataType].push(value);
						}
						// console.log('aaaaaaa', this[dataType]);
						this.txnChart.createChart(this.headerChartID, this[dataType]);
						break;
					case 'popularFoods':
					case 'popularDrinks':
					case 'popularOverall':
						this.setDefault(dataType);

						d.data.forEach(info => {
							try{
								this[dataType].v.push(info.item_name.toLowerCase().substring(0,8));
								this[dataType].d.push(info.item_qty);
							}
							catch (e) { console.log('popular food error', e); }
						});
						break;
					case 'popularStalls':
						this.setDefault(dataType);

						d.data.forEach(info => {
							try{
								this.popularStalls.v.push(info.stall_name.toLowerCase().substring(0,10));
								this.popularStalls.d.push(info.txn_amount);
							}
							catch (e) { console.log('popular Stalls error', e); }
						});
						break;
					case 'tapxSummary':
						this.setDefault(dataType);
						this.tapxSummary = d.data;
						break;
					case 'attendancePersonsInfo':

						let count = 0;

						this.attendancePersonsInfo = { count: 0, sessions_count: 0, sessions: [0,0,0,0,0,0,0,], entries: [0,0,0,0,0,0,0,], categoriesList: [ { value: 0, label: "ALL" } ], categories: {  } };
						d.data.forEach(info => {
							try{
								count = info.count?? 0;
								if( info.category_id && !this.attendancePersonsInfo.categories[info.category_id] ) {
									this.attendancePersonsInfo.categories[info.category_id] = { name: info.category_name, count: 0, sessions_count: 0, hallsList: [ { value: 0, label: "ALL" } ], sessions: [0,0,0,0,0,0,0,], entries: [0,0,0,0,0,0,0,], halls: { } };
									this.attendancePersonsInfo.categoriesList.push({ value: info.category_id, label: info.category_name });
								}


								if( info.hall_id && !this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id] ) {
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id] = { name: info.hall_name, count: 0, sessions_count: 0, sectionsList: [ { value: 0, label: "ALL" } ], sessions: [0,0,0,0,0,0,0,], entries: [0,0,0,0,0,0,0,], sections: { } };
									this.attendancePersonsInfo.categories[info.category_id].hallsList.push({ value: info.hall_id, label: info.hall_name });
								}


								if( info.section_id && !this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id] ) {
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id] = { name: info.section_name, count: 0, sessions_count: 0, subjectsList: [ { value: 0, label: "ALL" } ], sessions: [0,0,0,0,0,0,0,], entries: [0,0,0,0,0,0,0,], subjects: { } };
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sectionsList.push({ value: info.section_id, label: info.section_name });

									this.attendancePersonsInfo.count += count;
									this.attendancePersonsInfo.categories[info.category_id].count += count;
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].count += count;
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].count += count;
								}


								if( info.subject_id && !this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjects[info.subject_id] )   {
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjects[info.subject_id] = { name: info.subject, count: 0, sessions_count: 0, sessions: [0,0,0,0,0,0,0,], entries: [0,0,0,0,0,0,0,] };
									this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjectsList.push({ value: info.subject_id, label: info.subject });
									// console.log('subject', info.subject);
									// console.log('subject', this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjectsList);
								}

								if( info.subject_id )   this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjects[info.subject_id].count += count;
							}
							catch (e) {
								console.log('attendancePersonsInfo error', e);
							}
						});
						// console.log('attendancePersonsInfo', this.attendancePersonsInfo);
						// console.log('info-data', d.data);
						// console.log('clg', this.clgCategories, this.clgHalls, this.clgSections, this.clgSubjects);
						// this.setColgFilterDefaults()

						this.loadData('attendanceSectionWise');
						break;
					case 'attendanceSectionWise':

						this.setDefault('dateArr');
						let todayDay = new Date().getDay(); //todayDay = todayDay===0 ? 7: todayDay;
						if(todayDay !== 7) for ( let i = 0, b = 0; i < todayDay ; i++ ) { b = this.dateArr.shift(); this.dateArr.push(b); }
						// todayDay = this.dateArr.indexOf(todayDay);

						d.data.forEach(info => {
							try{
								let info_day = this.dateArr.indexOf(info.day) + 1;
								this.attendancePersonsInfo.entries[info_day] += info.count;
								this.attendancePersonsInfo.sessions[info_day] ++;
								this.attendancePersonsInfo.categories[info.category_id].entries[info_day] += info.count;
								this.attendancePersonsInfo.categories[info.category_id].sessions[info_day] ++;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].entries[info_day] += info.count;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sessions[info_day] ++;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].entries[info_day] += info.count;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].sessions[info_day] ++;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjects[info.subject_id].entries[info_day] += info.count;
								this.attendancePersonsInfo.categories[info.category_id].halls[info.hall_id].sections[info.section_id].subjects[info.subject_id].sessions[info_day] ++;
							}
							catch (e) {
								console.log('attendanceSectionWise error', e);
							}
						});
						// console.log('attendanceSectionWise', this.attendancePersonsInfo);
						// console.log('sectionwise-data', d.data);

						// this.setDefault('attendancePersons');
						this.attendancePersons = this.attendancePersonsInfo;

						this.clgCategories = this.attendancePersonsInfo.categoriesList;
						if( this.colgFilters2.catg === -1) this.setColgFilterDefaults();
						this.clgCatgDisabled = false;
						this.isLoading['attdWeeklyGraph'] = false;
						// this.$refs.filterCategory.select(0);
						break;
					case 'topAttendance':
						this.topAttendance = d.data;
						break;
				}
				this.isLoading[dataType] = false;
			},
			loadData(dataType, product = 'event') {
				this.isLoading[dataType] = true;
				let baseURL = '', id = 0;
				switch( product ) {
					case 'armourx':
						baseURL = this.$armourxURL;
						break;
					default:
						baseURL = this.$baseURL;
						break;
				}
				if(!this.e) {
					let u = this.loginUser;
					switch (u.loginType) {
						case 'event':
						case 'operator':
							id = u.id;
							break;
						case 'admin':
						case 'reports':
							id = this.$route.params.code;
							break;
						default:
							return;
							break;
					}
				} else id = this.e;
				axios.post(
					baseURL + '/Event/loadDashboardData/',
					{
						id, dataType, eventType: this.event.type,
						dashSettings: this.dashSettings, event: this.event,
						secondLastTxnId: this.secondLastTxnId, lastTxnId: this.lastTxnId, periodType: this.periodType,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) { this.updateLocal(dataType, response.data); }
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading[dataType] = false;
				});
			},
			downloadCSVData(){
				// let csv = 'Item,Cost,MRP,Price,Happy Price,HSN,Barcode,Type(food/drink/ticket/other)\n';
				// csv += 'Item Name 1,0,0,0,0,,,food\n';
				// csv += 'Item Name 2,0,0,0,0,,,food\n';
				// this.csvdata.forEach((row) => {
				// 	csv += row.join(',');
				// 	csv += "\n";
				// });

				let csv = 'TxnID,Type,Receipt,Vendor,Stall,Device,Card,TxnCounter,Mode,Amount,Status,Timestamp\n';
				csv = [ 'TxnID', 'Type', 'Receipt', 'Vendor', 'Stall', 'Device', 'Card', 'Txn Counter', 'Mode', 'Amount', 'Status', 'Timestamp' ].join(',') + '\n';
				this.suspTxns.forEach( txn => {
					csv += [txn.txn_id, txn.txn_type, txn.txn_receipt, txn.vendor_name, txn.stall_name, txn.txn_device_print_id, txn.txn_card_id, txn.txn_counter, txn.txn_mode + ' | ' + txn.txn_invoice + ' | ' + txn.txn_mode_info, txn.txn_amount, txn.txn_status, txn.txn_at ].join(',') + '\n';
				});

				console.log(this.transactions, csv);
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'FilterTransactions.csv';
				anchor.click();
			},
			susStyle(cardData) { return cardData.revCashTCount > 0 && cardData.totTCount === 0 ; },
			loadSuspectData() {
				this.suspCards = [];
				this.suspTxns = [];
				this.suspDialog = true;
				// this.isLoading[dataType] = true;
				let baseURL = this.$baseURL, id = 0;
				if(!this.e) {
					let u = this.loginUser;
					switch (u.loginType) {
						case 'event':
						case 'operator':
							id = u.id;
							break;
						case 'admin':
						case 'reports':
							id = this.$route.params.code;
							break;
						default:
							this.suspDialog = false;
							return;
							break;
					}

					axios.post(
						baseURL + '/Event/susp/',
						{
							id, eventType: this.event.type,
							dashSettings: this.dashSettings, event: this.event,
							secondLastTxnId: this.secondLastTxnId, lastTxnId: this.lastTxnId, periodType: this.periodType,
						},
						{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
					)
						.then(response => {
							console.log(response);
							// debugger;
							if(response.data.success) {
								// console.log(response.data.reversalSummary);
								this.suspCards = response.data.reversalSummary;
								this.suspTxns = response.data.cardTransactions;
								// this.suspDialog = true;
							}
						})
						.catch( (error) => {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>Please try again.",
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
							console.log("err:", error);
							// this.isLoading[dataType] = false;
						});
				}
			},
			loadArmourxData(dataType) {
				this.isLoading[dataType] = true;
				axios.post(
					this.$armourxURL + '/Event/loadDashboardData/',
					{
						id: this.$route.params.code, dataType, eventType: this.event.type,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					// console.log(response);
					if(response.data.success) { this.updateLocal(dataType, response.data); }
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading[dataType] = false;
				});
			},
			todayWeekDay(mysqlDay = 1) {
				let d = new Date().getDay();
				d = d===0 ? 7: d;

				let b = 0, arr = [ 1, 2, 3, 4, 5, 6, 7, ];

				for ( let i = 0; i < new Date().getDay(); i++ ) { b = arr.shift(); arr.push(b); }

				return arr.indexOf(d);
			},
			weekDays() {
				let d = new Date().getDay();
				return [ 1, 2, 3, 4, 5, 6, 0 ][mysqlDay - 1];
				},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}

				// if( this.$isCheckEventType('clg-access', this.event.type) && this.event.useAccessx === 0 ) this.setColgFilterDefaults();
			},
			setAttdGraph() {
				if(!this.colgFilters || !this.attendancePersons)  return;
				let data_ = [ [], [] ], count = this.attendancePersons.count, i = 0, sessions = [], entries = [];

				if(this.colgFilters.catg === 0) {
					entries = this.attendancePersons.entries;
					sessions = this.attendancePersons.sessions;
				} else if( this.colgFilters.hall === 0 ) {
					entries = this.attendancePersons.categories[this.colgFilters.catg].entries;
					sessions = this.attendancePersons.categories[this.colgFilters.catg].sessions;
					count = this.attendancePersons.categories[this.colgFilters.catg].count;
				} else if( this.colgFilters.section === 0 ) {
					entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].entries;
					sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sessions;
					count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].count;
				} else if( this.colgFilters.subject === 0 ) {
					entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].entries;
					sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].sessions;
					count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].count;
				} else {
					entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].entries;
					sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].sessions;
					count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].count;
				}

				// if(this.colgFilters.catg === 0) {
				// 	entries = this.attendancePersons.entries;
				// 	sessions = this.attendancePersons.sessions;
				// } else if( this.colgFilters.hall === 0 ) {
				// 	entries = this.attendancePersons.categories[this.colgFilters.catg].entries;
				// 	sessions = this.attendancePersons.categories[this.colgFilters.catg].sessions;
				// 	count = this.attendancePersons.categories[this.colgFilters.catg].count * this.attendancePersons.categories[this.colgFilters.catg].sessions_count;
				// } else if( this.colgFilters.section === 0 ) {
				// 	entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].entries;
				// 	sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sessions;
				// 	count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].count * this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sessions_count;
				// } else if( this.colgFilters.subject === 0 ) {
				// 	entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].entries;
				// 	sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].sessions;
				// 	count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].count *  this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].sessions_count;
				// } else {
				// 	entries = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].entries;
				// 	sessions = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].sessions;
				// 	count = this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].count * this.attendancePersons.categories[this.colgFilters.catg].halls[this.colgFilters.hall].sections[this.colgFilters.section].subjects[this.colgFilters.subject].sessions_count;
				// }

				if(!entries || entries.length === 0)    return;
				// for(let i = 0; i < 7; i++) {
				// 	let present_per = (entries[i]/sessions[i]/count*100).toFixed(2);
				// 	data_[0].push(present_per);
				// 	data_[1].push(100 - present_per);
				// }
				[0, 1, 2, 3, 4, 5, 6].forEach( i => {
					let present_per = entries[i] > 0 ? (entries[i]/sessions[i]/count*100).toFixed(2) : 0;
					data_[0].push(present_per);
					data_[1].push(100 - present_per);
				} );

				this.setDefault('weekArr');
				let todayDay = new Date().getDay(); //todayDay = todayDay===0 ? 7: todayDay;
				if(todayDay !== 6) for ( let i = 0, b = ''; i < todayDay ; i++ ) { b = this.weekArr.shift(); this.weekArr.push(b); }

				// console.log("week", this.weekArr);
				this.attendanceChart2.createChart(this.attendanceChartID, data_, this.weekArr);
				},
			setColgFilterDefaults() {
				try {
					this.$refs.filterCatg.select(0);
					this.$refs.filterHall.select(0);
					this.$refs.filterSection.select(0);
					this.$refs.filterSubject.select(0);

					this.colgFilters.catg = 0;
					this.colgFilters.hall = 0;
					this.colgFilters.section = 0;
					this.colgFilters.subject = 0;


					this.clgCatgDisabled = true;
					this.clgHallDisabled = true;
					this.clgSecDisabled = true;
					this.clgSubDisabled = true;
				} catch (e) {
					console.log('attdGraph filter catch error', e);
				}
			},
			sendOTP (mobile) {
				axios.post(
					this.$baseURL + '/Messages/LiveLinkSendOTP',
					{
						// type: 'SMS',
						// OTP,
						number: mobile.toString(),
						// smsType: 'OTP',
						// sender: 'ATMXPS',
						// calledBy: 'Dashboard-Analytics-LiveLink',
						code: this.e,
					},
					{ headers: {
							"Access-Control-Allow-Origin": "*",
							'Content-Type': 'application/json',
						}
					}
				)
				// 	.then( response => { console.log(response) })
				// 	.catch( error => {
				// 		ElMessage({
				// 			showClose: true,
				// 			dangerouslyUseHTMLString: true,
				// 			message: "<b>Something went wrong</b><br>Unable to send OTP",
				// 			type: 'warning',
				// 			grouping: true,
				// 			duration: 10000,
				// 		});
				// 		return OTP;
				// 	} )
				// return OTP;
			},
			verifyOTP (otp, mobile) {
				// debugger;
				axios.post(
					this.$baseURL + '/Messages/LiveLinkVerifyOTP', { otp, mobile, },
					{ headers: {
							"Access-Control-Allow-Origin": "*",
							'Content-Type': 'application/json',
						}
					}
				)
					.then( response => {
						console.log(response.data);
						// if(response.success && response.verified) { this.liveLinkAuthenticated = true; }
						this.liveLinkAuthenticated = response.data.success && response.data.verified;
						if(this.liveLinkAuthenticated)	{
							this.loadData('summary');
							this.$emit('loading-complete');
						}
					})
					.catch( error => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Unable to verify OTP",
							type: 'warning',
							grouping: true,
							duration: 10000,
						});
						return otp;
					})
				return otp;
			},
		},
		mounted() {
			// debugger;
			this.e = this.$route.query.e;
			if(!this.e) {
				// setTimeout(this.loadDetails, 500);
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				// if(_.isEmpty(u))	return this.$router.push('/login');
				if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
				this.loadDetails();
			} else {
				this.e = atob(this.e);
				return this.loadDetails();
				let otp = 0;
				let u_LL = JSON.parse(localStorage.getItem('user_LiveLink'));
				if (!u_LL || (u_LL && Object.keys(u_LL).length === 0 )) {
					ElMessageBox.prompt('Enter the Whatsapp Number registered', '', {
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
						inputPattern: /^\d+$/,
						inputErrorMessage: 'Invalid Mobile',
					})
						.then((mobile) => {
							try { this.sendOTP(mobile.value); ElMessage({ type: 'success', message: `OTP sent to: ${mobile.value}`, }); }
							catch (e) { return ElMessage({ type: 'warning', message: 'OTP not sent. Try again', }) }

							ElMessageBox.prompt('Enter OTP Sent to ' + mobile.value, 'VERIFY', {
								confirmButtonText: 'OK',
								cancelButtonText: 'Cancel',
								inputErrorMessage: 'Invalid Mobile',
							})
								.then((otp) => { this.verifyOTP(otp.value, mobile.value); })
								.catch(() => {
									ElMessage({
										type: 'warning',
										message: 'Input Canceled',
									})
								})
						})
						.catch(() => {
							ElMessage({
								type: 'warning',
								message: 'Input canceled',
							})
						})
				}
			}

			// this.loadDetails();
			// this.interval_fn = setInterval(this.loadDetails, 60000);
			// this.$setIntervalFn(setInterval(this.loadDetails, 60000));
			// console.log(this.$getIntervalFn());

			// this.setColgFilterDefaults();

			// setTimeout(this.loadData, 1000, 'summary');
			// this.loadData('summary');

			// setTimeout(this.loadData, 2000, 'cards');
			// this.loadData('cards');

			// setTimeout(this.loadData, 3000, 'mode');
			// this.loadData('cards');

			// setTimeout(this.loadData, 4000, 'last5');
			// this.loadData('cards');
		},
		watch:{
			$route (to, from){
				console.log('route change detected, dash');
				// this.setLoading();
				// clearInterval(this.interval_fn);
				// clearInterval(this.$getIntervalFn());
				if(to.path.includes('Analytics')) {
					this.loadDetails();
					// this.interval_fn = setInterval(this.loadDetails, 60000);
					// this.$setIntervalFn(setInterval(this.loadDetails, 10000));
					// this.event = {};
				} else console.log('de-activated interval');
				this.setDefault();
			},

			// liveLinkAuthenticated (to, from) {
			// 	if(to)	{
			// 		this.setDefault();
			// 		this.loadDetails();
			// 		// this.$emit('newDayClosedTurnOFF');
			// 	}
			// },
			newDayClosed (to, from) {
				if(to)	{
					this.setDefault();
					this.loadDetails();
					this.$emit('newDayClosedTurnOFF');
				}
			},
			// $periodType (to, from){
			// 	console.log('$periodType change detected');
			// 	this.loadDetails();
			// },
			periodType: {
				handler(a) {
					console.log('period change detected');
					this.loadDetails();
					// switch (a) {
					// 	case 'yesterday':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'current':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'all':
					// 		this.lastTxnId = 0;
					// 	default:
					// 		break;
					// }
				}
			},
			summaryInfo: {
				handler(a) {
					// console.log("summaryInfo update detect", a);
					this.$emit('summary-fetched', a);

					if(!a)  return;
					let summary__ = {
						topup: this.summaryInfo.topup.amount,
						sale: +this.summaryInfo.sale.amount + +this.summaryInfo.no_nfc_sale.amount,
						return: this.summaryInfo.return.amount,
					}

					let summaryChartData = [], summaryChartLabels = [];
					[ 'sale', 'topup', 'return' ].forEach( type => { if( summary__[type] !== 0 ) { summaryChartData.push(summary__[type]); summaryChartLabels.push(type); } });
					// debugger;
					headerChart_Summary.createChart(this.headerChartID3, summaryChartLabels, summaryChartData);
					headerChart_Summary.createChart(this.headerChartID2, summaryChartLabels, summaryChartData);
					// this.summaryChart.createChart(this.headerChartID2, [ 'SALE', 'TOPUP', 'RETURN' ], summaryChartData);
				},
				deep: true,
			},
			event: {
				handler(a) {
					// console.log("event update detect", a);
					this.$emit('event-fetched', a);
					if(a) this.setComponentsDisplay();
				},
				deep: true,
			},
			modeInfo: {
				handler(a) {
					this.modeChart.createChart(this.headerChartID4, this.md.v, this.md.d);
				},
				deep: true,
			},
			// txnGraph(to, from) {
			// 	this.txnChart.createChart(this.headerChartID, to);
			// },
			txnGraph: {
				handler(a) {
					// this.txnChart.createChart(this.headerChartID, a);
				},
				deep: true,
			},
			popularFoods: {
				handler(a) {
					this.dataChart.createChart(this.foodChartID, a.d, a.v);
				},
				deep: true,
			},
			popularDrinks: {
				handler(a) {
					this.dataChart.createChart(this.drinkChartID, a.d, a.v, '#ff6b59', '#FF7815');
				},
				deep: true,
			},
			popularOverall: {
				handler(a) {
					this.dataChart.createChart(this.overallChartID, a.d, a.v);
				},
				deep: true,
			},
			popularStalls: {
				handler(a) {
					this.dataChart.createChart(this.stallChartID, a.d, a.v, '#36b9ac', '#1398af');
				},
				deep: true,
			},
			collegeAccessInfo: {
				handler(a) {
					// console.log('collegeAccessInfo emit now');
					this.$emit('colgaccess-fetched', a);
					try {
						// console.log('chartId', this.headerChartID3);
						this.summaryChart.createChart(this.headerChartID2, ['PRESENT', 'ABSENT'], [a.present, a.absent]);
						this.summaryChart.createChart(this.headerChartID3, ['PRESENT', 'ABSENT'], [a.present, a.absent]);
					} catch(e) { console.log(e) }
				},
				deep: true,
			},
			accessGraph: {
				handler(a) {
					this.txnChart.createChart(this.headerChartID, a);
				},
				deep: true,
			},
			attendancePersons: {
				handler(a) {
					// this.setColgFilterDefaults();
				},
				deep: true,
			},
			colgFilters: {
				handler(a) {
					this.setAttdGraph();
				},
				deep: true,
			},
			clgCategories: {
				handler(a) {
					// this.setColgFilterDefaults();
					this.setAttdGraph();
				},
				deep: true,
			},

			// popularFoods(to, from) {
			// 	this.dataChart.createChart(this.foodChartID, to.d, to.v);
			// },
			// popularDrinks(to, from) {
			// 	this.dataChart.createChart(this.drinkChartID, to.d, to.v, '#ff6b59', '#FF7815');
			// },
			// popularOverall(to, from) {
			// 	this.dataChart.createChart(this.overallChartID, to.d, to.v);
			// },
			// popularStalls(to, from) {
			// 	this.dataChart.createChart(this.stallChartID, to.d, to.v, '#36b9ac', '#1398af');
			// },
		}
	};
</script>
