<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">List</h5>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-4 mx-auto">Current</div>
				<div class="col-6">Existing</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElDatePicker
	} from "element-plus";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider, ElDatePicker,
		},
		props: [ 'vendors', 'isLoading', 'event' ],
		inject: [ '$baseURL', ],
		emits: [  ],
		data() {
			return {
				reportLoading: false,
				defaultTypes: [ { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' }, { value: 'all_txns', label: 'BASIC TRANSACTION DUMP' }, { value: 'balances', label: 'Remaining Balances' }, { value: 'partials', label: 'Partial Transactions' }, { value: 'summary', label: 'Summary' }, ],
				defaultReports: [ { value: 0, label: 'Event Report' }, ],
				filter: { dates: [], report: null, type: '' },
				shortcuts: [{
					text: 'Last week',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
						return [start, end]
					})(),
				}, {
					text: 'Last month',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
						return [start, end]
					})(),
				}, {
					text: 'Last 3 months',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
						return [start, end]
					})(),
				}],
			}
		},
		mounted() {

		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			reportTypeSelected(type) {
				if(type === 'all_txns') {

				}
			},
		},
		watch: {
			vendors(to, from) {
				this.defaultReports = [ { value: 0, label: 'Event Report' }, ];
				to.forEach(vendor => { this.defaultReports.push({ value: vendor.id, label: vendor.name }) });
			},
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
</style>
