// =========================================================
// * Vue Now UI Dashboard PRO - v2.0.0
// =========================================================
//
// * Product Page: http://www.creative-tim.com/product/vue-now-ui-dashboard-pro
// * Copyright 2019 Creative Tim (http://www.creative-tim.com)
//
// * Designed by www.invisionapp.com Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// import axios from 'axios';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import DashboardPlugin from "./dashboard-plugin";

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);

// import Notifications from './components/Notification'
// appInstance.use(Notifications);

appInstance.config.globalProperties.$event = {};
appInstance.config.globalProperties.$summaryInfo = {};
appInstance.config.globalProperties.$createElement = {};
appInstance.config.globalProperties.$appver = "";
appInstance.config.globalProperties.$dashcontroller = "";

// App version
appInstance.provide('$appver', "170125");
appInstance.provide('$dashcontroller', "ATOMX");

// appInstance.provide('$baseURL', "http://fnb.atomx.in:2100/dashboardAPI/v1"); appInstance.provide('$armourxURL', "http://fnb.atomx.in:2100/armourxAPI/v1/ArmorX");
appInstance.provide('$baseURL', "https://dashapi.atomx.in/dashboardAPI/v1"); appInstance.provide('$accessxURL', "https://dashapi.atomx.in/api/v1/AccessX"); appInstance.provide('$armourxURL', "https://dashapi.atomx.in/armourxAPI/v1/ArmorX");
// appInstance.provide('$baseURL', "http://localhost:2100/dashboardAPI/v1"); appInstance.provide('$accessxURL', "http://localhost:2100/api/v1/AccessX"); appInstance.provide('$armourxURL', "http://localhost:2100/armourxAPI/v1/ArmorX");
// appInstance.provide('$baseURL', "https://canteenx.atomx.in:2100/dashboardAPI/v1"); appInstance.provide('$armourxURL', "https://canteenx.atomx.in:2100/armourxAPI/v1/ArmorX");

// aws s3 sync . s3://dashboard.atomx.in

// appInstance.provide('$axios', axios);
// appInstance.config.globalProperties.axios=axios;
let i_fn = -1;
appInstance.provide('$setIntervalFn', (iFn) => { i_fn = iFn; });
appInstance.provide('$getIntervalFn', () => { return i_fn; });

appInstance.provide('$routerGoto', (path) => { window.location.href = path.path; });
appInstance.provide('$updateData', (arr1 = [], arr2 = []) => {
	return arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
});
appInstance.provide('$removeData', (arr = [], value = '', key = 'id') => {
	for( var i = 0; i < arr.length; i++ )    {
		if ( arr[i][key] === value) { arr.splice(i, 1); }
	}
});
appInstance.provide('$isCheckEventType', (type = 'fnb', eventType = '') => {
	switch (type) {
		case 'fnb':
			return eventType === 'event' || eventType === 'merchant';
			break;
		case 'access':
		case 'clg-access':
			return eventType === type;
			break;
		default:
			return false;
			break;
	}
});
appInstance.provide('$numFrm', (x = 0, type = 'int', country = 'india') => {
	// var country = $("#usable_country").val().toLowerCase();
	if(country.toLowerCase() === 'india' || country.toLowerCase() === 'usa')	return numFrm2(x);
	try {
		var amt = x;
		if (!country) return amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		if (type === 'float') amt = amt.toFixed(2);
		if (type === 'int') amt = amt.toFixed(0);
		amt = amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		if (country === 'india' || !country) return amt;
		amt = amt.replace(/,/g, '&');
		amt = amt.replace('.', ',');
		amt = amt.replace(/&/g, '.');
		return amt;
	} catch ( e ) { console.log('numerr', e);return 'NA'; }
});
function numFrm2( x = 0, ) {
	try {
		let x_ = x.toString().split('.');
		x = parseInt(x_[0]);
		let isFloat = x_.length > 1;
		let isNegative = x < 0;
		if (isNegative) x = x * -1;
		x = x.toString();
		let lastThree = x.substring(x.length - 3);
		let otherNumbers = x.substring(0, x.length - 3);
		if (otherNumbers !== '') lastThree = ',' + lastThree;
		return (isNegative ? '-' : '') + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + ( isFloat ? '.' + x_[1].substring(0, 2) : '' );
	} catch ( e ) { console.log('numerr', e);return 'NA'; }
}
appInstance.provide('$numFrm2', numFrm2);
appInstance.provide('$formatDateTime', (date, includeTime = true, format = 0) => {
	if(date == null)    return "";
	if(typeof date.getMonth != 'function') return "";
	date = new Date(date);
	var year = date.getFullYear().toString();
	var month = (date.getMonth() + 1).toString();
	if (month.length < 2)   month = "0" + month;
	var day = date.getDate().toString();
	if (day.length < 2)     day = "0" + day;
	var hour = date.getHours().toString();
	if (hour.length < 2)    hour = "0" + hour;
	var min = date.getMinutes().toString();
	if (min.length < 2)    min = "0" + min;
	var sec = date.getSeconds().toString();
	if (sec.length < 2)    sec = "0" + sec;

	let d_ = '', t_ = '';

	d_ = format ? year + '-' + month + '-' + day : day + "-" + month + "-" + year;
	t_ = includeTime ? " " + hour + ":" + min + ":" + sec : '';

	return d_ + t_ ;
});
appInstance.provide('$sweetAlert', {
	alert: {show: false, type: 'success', icon: "ni ni-like-2", message: {strong: 'Success!', normal: "This is a success alert—check it out!"}},
	selectedValue: null,
});


appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(DashboardPlugin);
appInstance.mount("#app");
