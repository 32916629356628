<template>
	<div>
		<div class="row " >
			<div class="col-12 col-md-10 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">List ( {{ events.length }} )</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.events">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body row">
						<div class="col-12 col-md-11 mx-auto">
							<el-input placeholder="Search By Name / Country / City / Login Code" size="mini" prefix-icon="el-icon-search" v-model="search"/>

							<div class="row border-bottom border-top mt-2 py-3 d-block justify-content-end" style="box-shadow: -4px 10px 10px 0px rgb(250 250 250);">
								<h5 class="aml-text-teal mb-0 col-9 col-md-12 d-md-none d-inline">NAME</h5>
								<h5 class="text-primary mb-0 col-9 col-md-12 d-md-inline d-none">NAME</h5>
								<h5 class="text-primary mb-0 col-3 d-none d-md-inline pull-right">SHORTCUTS</h5>
							</div>

							<div class="row" style="height: 750px; overflow:auto;">
								<div class="col-12 row border-bottom"
									 v-for="event in events.filter( data => !search
											|| data.name.toLowerCase().includes(search.toLowerCase())
											|| data.locationCity.toLowerCase().includes(search.toLowerCase())
											|| data.id.toString().includes(search.toLowerCase())
											|| data.country.toLowerCase().includes(search.toLowerCase())
											|| ( data.venue && data.venue.toLowerCase().includes(search.toLowerCase()) )
											|| ( data.organizer && data.organizer.toLowerCase().includes(search.toLowerCase()) )
											|| ( data.client && data.client.toLowerCase().includes(search.toLowerCase()) )
								 	)">
									<div class="col-12 col-md-9 cursor-pointer my-2 d-inline" @click="$routerGoto({ path: '/#/Analytics/' + event.id })">
										<div class="">
											<i class="fas fa-atom fa-2x aml-text-teal"/>
											<h5 class="d-inline ml-2 font-weight-lighter align-bottom">
												<strong class="">{{ event.id }}</strong>
												<el-divider direction="vertical"/>
												<strong class="text-primary d-md-none d-inline">{{ event.name }}</strong>
												<strong class="aml-text-teal d-md-inline d-none">{{ event.name }}</strong>
											</h5>
										</div>
										<div class="mt-2 ml-2">
											<small> <i class="fas fa-hourglass"/> <span class="aml-text-gray ml-1"> {{ eventDate(event.startAt, event.endAt) }}</span> </small>
											<el-divider direction="vertical"/>
											<small> <i class="fa fa-globe"/> <span class="aml-text-gray ml-1">{{ event.country }}</span> </small>
											<el-divider direction="vertical"/>
											<small> <i class="fa fa-map-marker-alt"/> <span class="aml-text-gray ml-1">{{ event.venue ? event.venue + ', ' : '' }} {{ event.locationCity }}</span> </small>
										</div>
										<div class="mt-2 ml-2" v-if="event.organizer || event.client">
											<small v-if="event.organizer"> <i class="fa fa-mars-stroke"/> <span class="aml-text-gray ml-1">{{ event.organizer }}</span> </small>
											<el-divider direction="vertical"/>
											<small v-if="event.client"> <i class="fa fa-mars-stroke"/> <span class="aml-text-gray ml-1">{{ event.client }}</span> </small>
										</div>
									</div>
									<div class="col-md-3 col-12 d-md-inline my-2">
										<span v-for="op in options">
											<span data-bs-toggle="tooltip" data-bs-placement="top" :title="op.name.toUpperCase()"
												  class="badge aml-bg-gray cursor-pointer col-2 col-md-3"  @click="$routerGoto({ path: '/#/' + op.name + '/' + event.id })">
												<i class="d-md-none d-inline" :class="op.icon"/>
												<i class="fa-2x d-none d-md-inline" :class="op.icon"/>
												<div class="d-md-none d-block">{{ op.short }}</div>
											</span>
										</span>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { ElDivider, ElBadge, ElTable, ElTableColumn, ElMessage } from "element-plus";
	import moment from 'moment';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			ElTable, ElTableColumn,
			moment,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType', '$routerGoto'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					events: [],
					isLoading: { events: false, },
				},
				options: [ { name: 'Config', icon: 'fas fa-cogs', short: 'Config', },
					{ name: 'Transactions', icon: 'fas fa-credit-card', short: 'Txn', },
					{ name: 'Reports', icon: 'fas fa-file-invoice', short: 'Report', },
					{ name: 'Devices', icon: 'fas fa-mobile', short: 'Device', },
					{ name: 'Blocked', icon: 'fas fa-ban', short: 'Block', },
					{ name: 'Volunteers', icon: 'fas fa-people-carry', short: 'Volunteer', },
					{ name: 'EventData', icon: 'fas fa-database', short: 'Event Data', },
				],
				search: '',
				events: [],
				isLoading: { events: false, },
			};
		},
		methods: {
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				this.isLoading.events = true;
				axios.get(
					this.$baseURL + '/Event/ViewAll/',
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.events = response.data.events;
						}
						this.isLoading.events = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.events = false;
					});
			},
			eventDate(startdt, enddt) {
				return startdt === enddt ? this.displaydt(startdt) : this.displaydt(startdt) + ' to ' + this.displaydt(enddt);
				// if(startdt === enddt)	return this.displaydt(startdt);
				// else return '';
			},
			displaydt(dt) {
				return dt ? moment(dt).local().format('ll') : 'NA';
			},
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if (u && Object.keys(u).length === 0 && u.constructor === Object && (u.loginType !== 'admin' && u.loginType !== 'reports') ) return this.$router.push('/login');

			if(u.loginType === 'reports')	this.options = [ { name: 'Reports', icon: 'fas fa-file-invoice', short: 'Report', }, { name: 'EventData', icon: 'fas fa-database', short: 'Event Data', }, { name: 'Transactions', icon: 'fas fa-credit-card', short: 'Txn', }, ];

			this.loadDetails();
			this.$emit('event-fetched', {name: "EVENT LIST"});
		}
	};
</script>
<style>
</style>
