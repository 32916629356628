<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">LINKS</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="col-3 text-right text-info cursor-pointer" @click="openLinkCreate"><i class="fa fa-plus"></i> ADD LINK</span>
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Links" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-12"
			          :data="links.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
			          :height="links.length > 5 ? '300' : '200'"
			>
				<el-table-column label="#" type="index"/>
				<el-table-column sortable label="NAME" prop="name" />
				<el-table-column sortable label="Expected Count" prop="count" align="center"/>
				<el-table-column label="LINK">
					<template #default="scope">
						<p class="d-inline"> http://armorx.atomx.in/#/?l={{ base64_aml(scope.row.id) }} </p>
						<span class="ml-3">
<!--							<span-->
<!--								class="text-dark"-->
<!--								v-clipboard:copy="'http://armorx.atomx.in/#/?l=' + base64_aml(scope.row.id)"-->
<!--							>-->
<!--								<i class="fa fa-clipboard"></i>-->
<!--							</span>-->
<!--							<button type="button"-->
<!--									v-clipboard:copy="'http://armorx.atomx.in/#/?l=' + base64_aml(scope.row.id)"-->
<!--							>-->
<!--								Copy!-->
<!--							</button>-->
						</span>
					</template>
				</el-table-column>
<!--				<el-table-column label="NAME" align="center" width="80">-->
<!--					<template #default="scope">-->
<!--						<h3 class="d-inline"> /{{ scope.row.eventId }}/{{ scope.row.name }} </h3>-->
<!--						<span class="ml-3"><a class="text-dark" href=""><i class="fa fa-external-link-square"></i></a></span>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column class="cursor-pointer" label="EDIT" align="center" width="80">-->
<!--					<template #default="scope">-->
<!--						<span class="d-block cursor-pointer" @click="linkOpen(scope.row)"> <i class="fas fa-edit"></i> </span>-->
<!--					</template>-->
<!--				</el-table-column>-->
			</el-table>
		</div>
	</div>


	<el-dialog :title="linkk.id ? 'Edit Link' : 'Create Link'" v-model="linkDialog">
		<span class="mt-3 d-block">
			<el-form :model="linkk">
				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-right pr-0 my-auto">Name</span>
						<el-input type="text" class="col-8 pl-0" v-model="linkk.name" autocomplete="off"/>
					</span>
				</el-form-item>
				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-right pr-0 my-auto">Expected Count</span>
						<el-input type="text" class="col-8 pl-0" v-model="linkk.count" autocomplete="off"/>
					</span>
				</el-form-item>
			</el-form>
		</span>
		<template #footer>
			<span class="dialog-footer">
			  <el-button @click="linkDialog = false" v-if="!linkLoading">Cancel</el-button>
			  <el-button type="primary" :loading="linkLoading" @click="saveLink">Confirm</el-button>
			</span>
		</template>
	</el-dialog>

</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider
	} from "element-plus";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider,
		},
		props: [ 'links', 'isLoading', 'event' ],
		inject: [ '$baseURL', '$armourxURL', ],
		emits: [ 'link-add' ],
		data() {
			return {
				search: '',
				linkCreateDialog: false,
				linkDialog: false,
				vendorDialog: false,
				linkLoading: false,
				vendorLoading: false,
				vendor: {},
				linkk: {},
				linkRow: { },
				vendorRow: { },
				formLabelWidth: '200px',
			}
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			base64_aml(v) { return btoa(v); },
			checkString(value) {
				this.linkk[value] = this.linkk[value].replace(/[^\x00-\x7F]/g, '').replace(/\r?\n|\r/g, '')//.replace(/'/g, '').replace(/"/g, '');
			},
			openLinkCreate( ) {
				this.linkk = { eventId: this.event.id, };
				this.linkDialog = true;
				// this.vendorDialog = true;
			},
			linkOpen( link = {} ) {
				this.linkk = JSON.parse(JSON.stringify(link));
				this.linkRow = link;
				this.linkDialog = true;
			},
			createLink() {
				if( this.linkk.name === '')  return console.log('link empty');

				this.linkLoading = true;
				axios.post(
					this.$armourxURL + '/Links/Create/',
					this.link,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.$emit('link-add', response.data.link[0]);
							this.linkCreateDialog = false;
						}
						this.linkLoading = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.linkLoading = false;
					});
			},
			saveLink() {
				if( this.linkk.name === '')  return console.log('');

				this.linkLoading = true;
				// this.link.eventId = this.eventId;
				axios.post(
					this.$armourxURL + '/Links/' + (this.linkk.id ? 'Edit' : 'Create'),
					this.linkk,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {

							if(this.linkk.id) for (const [key, value] of Object.entries(this.linkk)) { this.linkRow[key] = value; }
							else this.links.push(response.data.link);

							this.linkDialog = false;
						}
						this.linkLoading = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.linkLoading = false;
					});
			},
		},
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
</style>
