<template>
	<div class="flip-container">
		<div class="flipper" :class="flip ? 'aml-flip' : ''">
			<div class="front w-100">
				<!-- front content -->
				<div class="card card-stats card-raised aml-event-card pb-3">
					<div class="card-header aml-card-header aml-bg-teal-3 text-center text-white px-1">
						<!--<el-badge :value="{{ event.status }}" class="item" type="warning">-->
						<h3 class="mt-1 mb-2">{{ event.name }}</h3>
						<!--</el-badge>-->
						<div v-if="showEditBtn()" class="px-0 ml-1 cursor-pointer position-absolute"
							@click="editEventOpen" style="top: 5px; left: 5px;">
							<i class="fas fa-edit" />
						</div>
						<div class="px-0 position-absolute" style="top: 5px;right: 5px;" v-if="isLoading">
							<span class="badge text-primary bg-white border-0">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
							</span>
						</div>
						<span class="">
							<h6>
								<small>
									<i class="fas fa-globe-asia" />
									{{ event.country }} <el-divider direction="vertical" />
									<i class="fas fa-map-pin" />
									{{ event.locationCity }} <el-divider direction="vertical" />
									<i class="fas fa-money-bill-wave-alt" />
									{{ event.currency }}
								</small>
							</h6>
						</span>

						<span class="badge aml-text-teal-3 border-0 bg-white"><i class="fas fa-sitemap" /> {{ event.controller }}</span>
						<span class="badge aml-text-teal-3 border-0 bg-white ml-1"><i class="fas fa-archway" /> {{ event.type }}</span>
						<span class="badge aml-text-teal-3 border-0 bg-white ml-1">{{ event.masterKey }}</span>
						<span class="badge aml-text-teal-3 border-0 bg-white ml-1"><i class="fab fa-orcid" /> {{ event.locationId }}</span>

						<p class="pb-1">
							<small><i class="fas fa-hourglass-start" /> {{ $formatDateTime(new Date(event.startAt), false) }}</small> <el-divider direction="vertical" />
							<small><i class="fas fa-hourglass-end" /> {{ $formatDateTime(new Date(event.endAt), false) }}</small>
						</p>
					</div>
					<div class="d-flex justify-content-center">
						<img v-if="event.id"
							:src="`https://dashboard-atomx.s3.us-west-2.amazonaws.com/events/banners/${event.id}.jpg`"
							alt="Upload Image" height=100 onerror="this.style.display = 'none'" />
					</div>
					<div class="card-body pb-2" v-if="loginUser.type === 'admin' || loginUser.type === 'event'">

						<div class="mx-auto text-center">
							<span class="badge aml-text-teal-3 border-0 bg-white"><i
									:class="event.useNfc === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								NFC CASHLESS</span>
							<span v-if="event.useAccessx === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> ACCESS CONTROL</span>
							<span v-if="event.useMakerChecker === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> Maker Checker</span>
							<span v-if="event.gstSetting === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> Taxation</span>
							<span class="badge aml-text-teal-3 border-0 bg-white" v-if="event.useNfc === 1"><i
									:class="event.onlineTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Online Topup</span>
							<span class="badge aml-text-teal-3 border-0 bg-white" v-if="event.usePin === 1"><i
									:class="event.usePin === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Use Pin</span>
						</div>

						<el-divider class=""><small><i class="fas fa-cog text-secondary" /> POS</small></el-divider>
						<div v-if="event.useNfc === 1">
							<div v-if="event.gstSetting === 1 && event.country === 'india'" class="text-center">
								<span class="badge aml-bg-teal-3 border-0 text-white">
									<div class="aml-font-12"><small>TOPUP GST TYPE:</small></div> {{ event.topupGstType
									}}GST
								</span>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-1">
									<div class="aml-font-12"><small>TOPUP GST:</small></div> {{ event.topupGstPer }} %
								</span>
							</div>
							<div class="text-center">
								<div
									v-if="event.topupNames.split(',').length === 4 && event.topupValues.split(',').length === 4">
									<div class="text-left mt-2"><small>TOPUP AMOUNTS</small></div>
									<span v-for="v in [0, 1, 2, 3]" :key="v"
										class="badge aml-bg-teal-3 border-0 text-white ml-1">
										<small>{{ event.topupNames.split(',')[v] }}:</small> {{
			event.topupValues.split(',')[v] }}
									</span>
								</div>

								<span class="col ml-1"><small class="badge aml-bg-teal-3 border-0 text-white">TOKEN
										CONVERSION RATE:</small> <span>{{ event.tokenConversion }}</span></span>

								<div class="text-left mt-2"><small>PASSWORDS</small></div>
								<span v-if="event.useNfc === 1" class="col"><small
										class="badge aml-bg-teal-3 border-0 text-white">TOPUP:</small> <u>{{
			event.devicePasswordTopup === '' ? '-' : event.devicePasswordTopup }}</u></span>
								<span class="col ml-1"><small
										class="badge aml-bg-teal-3 border-0 text-white">Sale:</small> <u>{{
			event.devicePassword === '' ? '-' : event.devicePassword }}</u></span>
								<span class="col ml-1" v-if="loginUser.type === 'admin'"><small
										class="badge aml-bg-teal-3 border-0 text-white">ADMIN:</small> <u>{{
			event.devicePasswordAdmin === '' ? '-' : event.devicePasswordAdmin }}</u></span>

							</div>
						</div>

						<!--<el-divider class="my-3"></el-divider>-->
						<div class="mx-auto text-center mt-2">
							<span v-if="event.happyHour === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> HAPPY HOUR</span>
							<span v-if="event.roundOff === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> ROUND OFF</span>
							<span v-if="event.topupManual === 1 && event.useNfc === 1"
								class="badge aml-text-teal-3 border-0 bg-white"><i class="fas fa-check text-success" />
								MANUAL TOPUP</span>
							<span v-if="event.linkCard === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> LINK MOBILE</span>
							<span v-if="event.useClubCard === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									class="fas fa-check text-success" /> USE CLUB CARD</span>
							<span class="badge aml-text-teal-3 border-0 bg-white"><i class="fas fa-print fa-2x" /> {{
			event.printer }}</span>
						</div>

						<div class="text-center" v-if="event.useNfc === 1">
							<el-divider class=""><small><i class="fas fa-broadcast-tower text-secondary" />
									CARD</small></el-divider>
							<div>
								<span class="badge aml-bg-teal-3 border-0 text-white">
									<div class="aml-font-12"><small>FEE:</small></div> {{ event.cardFee }}
								</span>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-1">
									<div class="aml-font-12"><small>1st TOPUP:</small></div> {{ $numFrm(event.minTopup,
			'int', event.country) }}
								</span>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-1">
									<div class="aml-font-12"><small>MAX wallet:</small></div> {{
			$numFrm(event.maxTopupWallet, 'int', event.country) }}
								</span>
							</div>

							<div class="d-none">
								<span class="badge aml-text-teal-3 border-0 bg-white"><i
										:class="event.cardFeeReActivate === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Card Fee On New Balance Setting</span>
							</div>

							<div>
								<span class="badge aml-text-teal-3 border-0 bg-white"><i
										:class="event.returnCardFee === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Return Card Fee</span>
								<span class="badge aml-text-teal-3 border-0 bg-white"><i
										:class="event.returnCartValue === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Return Balance</span>
								<span class="badge aml-text-teal-3 border-0 bg-white"><i
										:class="event.returnCardFull === 0 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Return Bank Card Balance</span>
								<span class="badge aml-text-teal-3 border-0 bg-white"><i
										:class="event.returnCardFeeCash === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Return Card Fee In Cash</span>
							</div>

							<div>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-1">
									<div class="aml-font-12"><small>RETURN MIN AMOUNT:</small></div> {{
			$numFrm(event.returnMinAmount, 'int', event.country) }}
								</span>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-1">
									<div class="aml-font-12"><small>RETURN MAX AMOUNT:</small></div> {{
			$numFrm(event.returnMaxAmount, 'int', event.country) }}
								</span>
							</div>
						</div>

						<el-divider class=""><small><i class="fas fa-university text-secondary" /> DASH
								SETTINGS</small></el-divider>
						<div class="text-center">
							<span v-if="event.useNfc === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									:class="dashSettings.addActivationTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Activation Topup Data</span>
							<span v-if="event.useNfc === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									:class="dashSettings.addCouponTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Coupon Topup Data</span>
							<span v-if="event.useNfc === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									:class="dashSettings.addCompTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Comp Topup Data</span>
							<span v-if="event.useNfc === 1" class="badge aml-text-teal-3 border-0 bg-white"><i
									:class="dashSettings.autoDayClose === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
								Auto Reset Dashboard</span>
						</div>

						<div class="text-center" v-if="event.country === 'india'">
							<el-divider class=""><small><i class="fas fa-university text-secondary" /> MSWIPE
									DETAILS</small></el-divider>
							<div>
								<span class="badge aml-bg-teal-3 border-0 text-white"><span
										class="aml-font-12"><small><i class="fas fa-user-alt" /> </small></span> {{
			event.bankUsername }}</span>
								<span class="badge aml-bg-teal-3 border-0 text-white ml-2"><span
										class="aml-font-12"><small><i class="fas fa-key" /> </small></span> {{
			event.bankPassword }}</span>
							</div>
						</div>

					</div>

				</div>
			</div>
			<div class="back">
				<!-- back content -->
				<div class="card card-stats card-raised aml-event-card pb-3">
					<div class="card-header aml-card-header bg-default text-center text-white px-1">
						<!--<el-badge :value="{{ eventEdit.status }}" class="item" type="warning">-->
						<!--<h3 class="mt-1 mb-2">{{ eventEdit.name }}</h3>-->
						<!--</el-badge>-->
						<div class="px-0 ml-1 cursor-pointer position-absolute" @click="editEventCancel"
							style="top: 5px; left: 5px;">
							<small><i class="fas"
									:class="eventLoading ? 'fa-spin fa-spinner' : 'fa-times fa-2x'" /></small>
						</div>
						<div class="px-0 mr-1 cursor-pointer position-absolute" @click="editEventSave"
							style="top: 5px; right: 5px;">
							<small>SAVE <i class="fas"
									:class="eventLoading ? 'fa-spin fa-spinner' : 'fa-save fa-2x'" /></small>
						</div>

						<div class="mt-4 col-10 mx-auto">
							<el-input class="aml-event-edit-name" placeholder="Name" prefix-icon="fas fa-monument"
								v-model="eventEdit.name" />
						</div>
					</div>
					<div class="card-body pb-2">

						<div class="text-center mt-2">
							<span class="badge aml-text-teal-3 border-0 bg-white"><i class="fas fa-sitemap" /> {{ eventEdit.controller }}</span>
							<span class="badge aml-text-teal-3 border-0 bg-white ml-1"><i class="fas fa-archway" /> {{ eventEdit.type }}</span>
							<span class="badge aml-text-teal-3 border-0 bg-white ml-1"><i class="fab fa-orcid" /> {{ eventEdit.locationId }}</span>
						</div>
						<div class="row aml-text-teal-3 mx-auto" v-if="( loginUser.type === 'admin' && loginUser.id === 1480 )">
							<h6 class="mb-0"> Banner</h6>
							<div class="col-8 stall-image-container ">
								<img v-if="event.id"
									:src="`https://dashboard-atomx.s3.us-west-2.amazonaws.com/events/banners/${event.id}.jpg`"
									alt="Upload Image" class="" @mouseover="showUploadButton = event.id"
									@mouseleave="showUploadButton = null" onerror="this.style.display = 'none'" />
								<div
									class="overlay position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
									<label class="d-inline">
										<input :key="inputKey" type="file" @change="handleFileUpload($event, event)"
											accept="image/png, image/jpg, image/jpeg, image/webp" ref="fileInput"
											class="d-none" />
										<i class="fa fa-upload fa-lg cursor-pointer"
											@click="triggerFileInput(event)"></i>
									</label>
								</div>
							</div>
						</div>
						<div v-if="isCropModalVisible" class="modal fade show" tabindex="-1" role="dialog"
							style="display: block; background: rgba(0, 0, 0, 0.6);">
							<div class="modal-dialog" role="document">
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title ">Crop Image</h5>
										<button type="button" class="close" @click="closeCropModal">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body">

										<span class="">{{ event.name }} ( {{ event.id }} )</span>
										<img :src="croppingImage" id="image-to-crop" />
										<ul class="text-danger">
											<li>
												<small>Image should be landscape.</small>
											</li>
											<li><small>Minimum width should be 500px.</small></li>
										</ul>
									</div>

									<div class="modal-footer">
										<button type="button" class="btn btn-secondary"
											@click="closeCropModal">Cancel</button>
										<button type="button" class="btn btn-primary" @click="cropImage">Crop</button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="isErrorModal" class="modal fade show" tabindex="-1" role="dialog"
							style="display: block; background: rgba(0, 0, 0, 0.6);">
							<div class="modal-dialog" role="document">
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title">Invalid Format</h5>
										<!-- <button type="button" class="close" @click="closeCropModal">
											<span aria-hidden="true">&times;</span>
										</button> -->
									</div>
									<div class="modal-body">
										<ul class="text-danger">
											<li>
												<small>Image should be landscape.</small>
											</li>
											<li><small>Minimum width should be 500px.</small></li>
										</ul>

										<!-- <span class="">{{ event.name }}({{ event.id }})</span> -->
										<!-- <img :src="croppingImage" id="image-to-crop" /> -->
									</div>

									<div class="modal-footer">
										<button type="button" class="btn btn-secondary"
											@click="closeErrorModal">Cancel</button>
									</div>
								</div>
							</div>
						</div>

						<div class="row aml-text-teal-3">
							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-globe-asia" /> Country</h6>
								<el-select class="select-primary" v-model="eventEdit.country" filterable allow-create
									default-first-option placeholder="Select Country">
									<el-option v-for="country in defaultCountries" :key="country"
										:label="country.toUpperCase()" :value="country" />
								</el-select>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-globe-asia" /> Timezone</h6>
								<el-select class="select-primary" v-model="eventEdit.tz" filterable allow-create
									default-first-option placeholder="Select Timezone">
									<el-option v-for="tz in defaultTZ" :key="tz" :label="tz.toUpperCase()"
										:value="tz" />
								</el-select>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-map-pin" /> City</h6>
								<el-select class="select-primary" v-model="eventEdit.locationCity" filterable
									allow-create default-first-option placeholder="Select City">
									<el-option v-for="city in defaultCities" :key="city" :label="city.toUpperCase()"
										:value="city" />
								</el-select>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-money-bill-wave-alt" /> Currency</h6>
								<el-select class="select-primary" v-model="eventEdit.currency" filterable
									placeholder="Select Currency">
									<el-option v-for="currency in defaultCurrencies" :key="currency"
										:label="currency.toUpperCase()" :value="currency" />
								</el-select>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-ankh" /> Organizer</h6>
								<el-input placeholder="Venue" prefix-icon="fas fa-monument"
									v-model="eventEdit.organizer" />
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-ankh" /> Client</h6>
								<el-input placeholder="Venue" prefix-icon="fas fa-monument"
									v-model="eventEdit.client" />
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0"><i class="fas fa-ankh" /> Venue</h6>
								<el-input placeholder="Venue" prefix-icon="fas fa-monument" v-model="eventEdit.venue" />
							</span>
						</div>

						<div class="row my-2">
							<h6 class="mx-auto aml-text-teal-3 aml-font-10 col-3 my-auto text-right"><i
									class="fas fa-hourglass" /> Dates</h6>
							<div class="col-9 mx-auto">
								<el-date-picker v-model="defaultEventDates" type="daterange" align="right" unlink-panels
									range-separator="-" start-placeholder="Start date" end-placeholder="End date"
									:shortcuts="shortcuts" :default-value="defaultEventDates"
									@change="eventEdit.startAt = $formatDateTime(defaultEventDates[0], false, 1); eventEdit.endAt = $formatDateTime(defaultEventDates[1], false, 1)">
								</el-date-picker>
							</div>
						</div>




						<div class="text-center row mt-4">
							<span class="col-6">
								<el-switch v-model="eventEdit.useNfc" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.useNfc === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									NFC CASHLESS</div>
							</span>
							<span class="col-6" v-if="eventEdit.useNfc === 1">
								<el-switch v-model="eventEdit.onlineTopup" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.onlineTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Online Topup</div>
							</span>
							<span class="col-6" v-if="eventEdit.useNfc === 1">
								<el-switch v-model="eventEdit.usePin" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.usePin === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Use Pin</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.useAccessx" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.useAccessx === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Access Control</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.useMakerChecker" active-color="#13ce66"
									inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.useMakerChecker === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Maker Checker</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.gstSetting" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.gstSetting === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									Taxation</div>
							</span>
						</div>

						<div class="text-center row mt-4"
							v-if="eventEdit.cardSettings && (loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">
							<span class="col-6 mt-3 mx-auto">
								<el-switch v-model="eventEdit.cardSettings.discountUse" active-color="#13ce66"
									inactive-color="#ccc" :active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.cardSettings.discountUse === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									USE DISCOUNT</div>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0 aml-text-teal-3 my-auto text-right"><i class="el-icon-printer" /> Card
									Discount Type</h6>
								<el-select class="select-primary col-10" v-model="eventEdit.cardSettings.discountType"
									filterable allow-create default-first-option placeholder="Select Printer">
									<el-option v-for="type in ['flat', 'percent']" :key="type"
										:label="type.toUpperCase()" :value="type" />
								</el-select>
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0 aml-text-teal-3"><i class="fas fa-ankh" /> DISCOUNT AMOUNT</h6>
								<el-input placeholder="AMOUNT" prefix-icon="fas fa-monument"
									v-model="eventEdit.cardSettings.discountAmount" />
							</span>

							<span class="col-6 mt-3 mx-auto">
								<h6 class="mb-0 aml-text-teal-3"><i class="fas fa-ankh" /> MIN CART AMOUNT FOR DISCOUNT
								</h6>
								<el-input placeholder="AMOUNT" prefix-icon="fas fa-monument"
									v-model="eventEdit.cardSettings.discountMinCartAmount" />
							</span>
						</div>

						<el-divider class=""><small><i class="fas fa-cog text-secondary" /> POS</small></el-divider>

						<div class="text-center row mt-4">
							<span class="col-6">
								<el-switch v-model="eventEdit.happyHour" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.happyHour === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									HAPPYHOUR</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.roundOff" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.roundOff === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									ROUND OFF</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.topupManual" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.topupManual === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									MANUAL TOPUP</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.linkCard" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.linkCard === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									LINK MOBILE</div>
							</span>
							<span class="col-6">
								<el-switch v-model="eventEdit.useClubCard" active-color="#13ce66" inactive-color="#ccc"
									:active-value="1" :inactive-value="0" />
								<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
										:class="eventEdit.useClubCard === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
									USE CLUB CARD</div>
							</span>

							<span class="row mt-3 mx-auto">
								<h6 class="mb-0 aml-text-teal-3 col-5 my-auto text-right"><i class="el-icon-printer" />
									Printer</h6>
								<el-select class="select-primary col-7" v-model="eventEdit.printer" filterable
									allow-create default-first-option placeholder="Select Printer">
									<el-option v-for="printer in defaultPrinters" :key="printer.value"
										:label="printer.label.toUpperCase()" :value="printer.value" />
								</el-select>
							</span>
						</div>

						<div>
							<div class="text-center">
								<div class="row mx-auto mt-2"
									v-if="eventEdit.useNfc === 1 && eventEdit.gstSetting === 1 && eventEdit.country === 'india'">
									<span class="col-6">
										<div class="text-left mt-2 col-12"><small>TOPUP GST TYPE</small></div>
										<span class="col-12">
											<el-switch v-model="eventEdit.topupGstType" active-text="SGST"
												inactive-text="IGST" active-color="#999" inactive-color="#ccc"
												active-value="s" inactive-value="i" />
										</span>
									</span>
									<span class="col-8 col-md-6">
										<div class="text-left mt-2 col-12"><small>TOPUP GST %</small></div>
										<el-input-number class="col-12" v-model="eventEdit.topupGstPer" :precision="2"
											controls-position="right" :max="100" />
									</span>
								</div>

								<div class="row mt-3">
									<div class="text-left mt-2 col-12"><small>TOP-UP AMOUNT CONVERSION</small></div>
									<div class="col-6 mt-2">
										<h6 class="aml-text-teal-3 aml-font-10 d-inline">RATE</h6>
										<el-input type="number" :min="0.01" placeholder="RATE" prefix-icon="fas fa-key"
											v-model="eventEdit.tokenConversion" />
									</div>
								</div>

								<div v-if="eventEdit.useNfc === 1">
									<div v-if="eventEdit.useNfc === 1 && eventEdit.topupBtns && eventEdit.topupVals"
										class="row mt-3">
										<div class="text-left mt-2 col-12"><small>TOPUP BUTTONS</small></div>
										<span v-if="eventEdit.topupBtns.length > 0 && eventEdit.topupVals.length > 0">
											<!--<span v-for="v in [0,1,2,3]" :key="v" class="badge aml-bg-teal-3 border-0 text-white ml-1">-->
											<span v-for="v in [0, 1, 2, 3]" :key="v" class=" ml-1">
												<!--<small>{{ eventEdit.topupBtns[v] }}:</small> {{ eventEdit.topupVals[v] }}-->
												<div class="row col-12 mx-auto">
													<h6 class="aml-text-teal-3 aml-font-10 col-2 my-auto">#{{ v + 1 }}
													</h6>
													<span class="col-5"><el-input :placeholder="'Button #' + v"
															prefix-icon="fas fa-wallet"
															v-model="eventEdit.topupBtns[v]" /></span>
													<span class="col-5"><el-input :placeholder="'Amount #' + v"
															prefix-icon="fas fa-coins"
															v-model="eventEdit.topupVals[v]" /></span>
												</div>
											</span>
										</span>
									</div>
								</div>

								<div>
									<div class="row mt-3">
										<div class="text-left mt-2 col-12"><small>PASSWORDS</small></div>
										<div class="col-6 mt-2" v-if="eventEdit.useNfc === 1">
											<h6 class="aml-text-teal-3 aml-font-10">TOPUP PASSWORD</h6>
											<el-input placeholder="Topup Password" prefix-icon="fas fa-key"
												v-model="eventEdit.devicePasswordTopup" />
										</div>
										<div class="col-6 mt-2">
											<h6 class="aml-text-teal-3 aml-font-10">SALE PASSWORD</h6>
											<el-input placeholder="Sale Password" prefix-icon="fas fa-key"
												v-model="eventEdit.devicePassword" />
										</div>
										<div class="col-6 mt-2 mx-auto" v-if="loginUser.type === 'admin'">
											<h6 class="aml-text-teal-3 aml-font-10">ADMIN PASSWORD</h6>
											<el-input placeholder="Admin Password" prefix-icon="fas fa-key"
												v-model="eventEdit.devicePasswordAdmin" />
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="text-center" v-if="eventEdit.useNfc === 1">
							<el-divider class=""><small><i class="fas fa-broadcast-tower text-secondary" />
									CARD</small></el-divider>

							<div class="row">
								<div class="col-6 mx-auto">
									<h6 class="aml-text-teal-3 aml-font-10">CARD FEE</h6>
									<el-input type="number" placeholder="Card Fee" prefix-icon="fas fa-monument"
										v-model="eventEdit.cardFee" />
								</div>
								<div class="col-6 mx-auto">
									<h6 class="aml-text-teal-3 aml-font-10">1st TOPUP</h6>
									<el-input type="number" placeholder="1st Min Topup" prefix-icon="fas fa-monument"
										v-model="eventEdit.minTopup" />
								</div>
								<div class="col-6 mx-auto mt-3">
									<h6 class="aml-text-teal-3 aml-font-10">MAX WALLET</h6>
									<el-input type="number" placeholder="Max Wallet" prefix-icon="fas fa-monument"
										v-model="eventEdit.maxTopupWallet" />
								</div>
							</div>

							<div class="row" v-if="loginUser.type === 'admin' && loginUser.id === 1480">
								<div class="col-6 mt-3">
									<h6 class="aml-text-teal-3 aml-font-10">REVERSAL COUNT</h6>
									<el-input type="number" placeholder="Max Wallet" prefix-icon="fas fa-monument"
										v-model="eventEdit.reversalAllowedCount" />
								</div>
							</div>

							<div class="row mt-4" v-if="eventEdit.useNfc === 1">
								<div class="text-left mt-2 col-12"><small>RETURNS</small></div>
								<span class="col-11 mx-auto d-none">
									<el-switch v-model="eventEdit.cardFeeReActivate" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
											:class="eventEdit.cardFeeReActivate === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Card Fee On New Balance Setting</div>
								</span>
								<span class="col-6">
									<el-switch v-model="eventEdit.returnCardFee" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
											:class="eventEdit.returnCardFee === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Return Card Fee</div>
								</span>
								<span class="col-6">
									<el-switch v-model="eventEdit.returnCartValue" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
											:class="eventEdit.returnCartValue === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Return Balance</div>
								</span>
								<span class="col-6">
									<el-switch v-model="eventEdit.returnCardFull" active-color="#13ce66"
										inactive-color="#ccc" :active-value="0" :inactive-value="1" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
											:class="eventEdit.returnCardFull === 0 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Return Bank Card Balance</div>
								</span>
								<span class="col-6">
									<el-switch v-model="eventEdit.returnCardFeeCash" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i
											:class="eventEdit.returnCardFeeCash === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Return Card Fee In Cash</div>
								</span>

								<div class="row mt-4 col-12 mx-auto">
									<div class="col-6 mx-auto">
										<h6 class="aml-text-teal-3 aml-font-10">RETURN MIN AMOUNT</h6>
										<el-input type="number" :min="0" placeholder="Min Balance Return"
											prefix-icon="fas fa-monument" v-model="eventEdit.returnMinAmount" />
									</div>
									<div class="col-6 mx-auto">
										<h6 class="aml-text-teal-3 aml-font-10">RETURN MAX AMOUNT</h6>
										<el-input type="number" :min="0" placeholder="Max Balance Return"
											prefix-icon="fas fa-monument" v-model="eventEdit.returnMaxAmount" />
									</div>
								</div>
							</div>

						</div>

						<div class="text-center" v-if="eventEdit.country === 'india'">
							<el-divider class=""><small><i class="fas fa-university text-secondary" /> MSWIPE
									DETAILS</small></el-divider>
							<div class="row">
								<div class="col-6 mx-auto">
									<span class="demo-input-label">Username</span>
									<el-input placeholder="Username" prefix-icon="fas fa-user"
										v-model="eventEdit.bankUsername" />
								</div>
								<div class="col-6 mx-auto">
									<span class="demo-input-label">Password</span>
									<el-input placeholder="Password" prefix-icon="fas fa-key"
										v-model="eventEdit.bankPassword" />
								</div>
							</div>
							<div class="row">
								<div class="col-6 mx-auto">
									<span class="demo-input-label">Verify Client Code</span>
									<el-input placeholder="Client Code" prefix-icon="fas fa-user"
										v-model="bankCredentialsEdit.mswipeVerifyClientCode" />
								</div>
								<div class="col-6 mx-auto">
									<span class="demo-input-label">Verify UserId</span>
									<el-input placeholder="UserId" prefix-icon="fas fa-key"
										v-model="bankCredentialsEdit.mswipeVerifyUserId" />
								</div>
								<div class="col-6 mx-auto">
									<span class="demo-input-label">Verify Password</span>
									<el-input placeholder="Password" prefix-icon="fas fa-key"
										v-model="bankCredentialsEdit.mswipeVerifyPassword" />
								</div>
								<div class="col-6 mx-auto">
									<span class="demo-input-label">UPI API</span>
									<el-input placeholder="UPI API" prefix-icon="fas fa-key"
										v-model="bankCredentialsEdit.mswipeUpiApi" />
								</div>
							</div>
						</div>

						<div class="text-center">
							<el-divider class=""><small><i class="fas fa-university text-secondary" /> DASH
									SETTINGS</small></el-divider>
							<div class="row">
								<div class="col-6 mx-auto">
									<!--<span class="demo-input-label">Password</span>-->
									<el-input type="password" placeholder="Password" prefix-icon="fas fa-lock"
										v-model="eventEdit.password" />
								</div>
							</div>

							<div class="row mt-4">
								<span class="col-6" v-if="eventEdit.useNfc === 1">
									<el-switch v-model="dashSettingsEdit.addActivationTopup" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12">
										<i
											:class="dashSettingsEdit.addActivationTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Show Activation Data
										<el-tooltip
											content="Dashboard Summary Topup will show/hide activation amounts separately"
											placement="bottom"> <i class="fas fa-info-circle cursor-pointer" />
										</el-tooltip>
									</div>
								</span>
								<span class="col-6" v-if="eventEdit.useNfc === 1">
									<el-switch v-model="dashSettingsEdit.addCouponTopup" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12">
										<i
											:class="dashSettingsEdit.addCouponTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Show Coupon Data
										<el-tooltip
											content="Dashboard Summary Topup will show/hide Coupon Topup amounts separately"
											placement="bottom"> <i class="fas fa-info-circle cursor-pointer" />
										</el-tooltip>
									</div>
								</span>
								<span class="col-6" v-if="eventEdit.useNfc === 1">
									<el-switch v-model="dashSettingsEdit.addCompTopup" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12">
										<i
											:class="dashSettingsEdit.addCompTopup === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Show Comp Data
										<el-tooltip
											content="Dashboard Summary Topup will show/hide Complimentary topup amounts separately"
											placement="bottom"> <i class="fas fa-info-circle cursor-pointer" />
										</el-tooltip>
									</div>
								</span>
								<span class="col-6 mx-auto">
									<el-switch v-model="dashSettingsEdit.autoDayClose" active-color="#13ce66"
										inactive-color="#ccc" :active-value="1" :inactive-value="0" />
									<div class="badge aml-text-teal-3 border-0 bg-white col-12">
										<i
											:class="dashSettingsEdit.autoDayClose === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
										Auto Reset Dashboard
										<el-tooltip content="Dashboard Summary will reset after 00:00 daily if active"
											placement="bottom"> <i class="fas fa-info-circle cursor-pointer" />
										</el-tooltip>
									</div>
								</span>
							</div>

							<div class="row mt-3" v-if="loginUser.type === 'admin'">
								<h6 class="aml-text-teal-3 aml-font-12 col-12">Enter (+91) Mobile Numbers for Access to
									Event Live Link</h6>
								<div class="col-2 col-md-3 text-right align-middle align-content-around">
									<el-switch id="vendor_kot" v-model="dashSettingsEdit.useLivelinkaccess" class="ml-3"
										active-color="#13ce66" inactive-color="#ccc" :active-value="1"
										:inactive-value="0" />
								</div>
								<div class="col-md-6 col-10 ">
									<el-input maxlength="100" :disabled="dashSettingsEdit.useLivelinkaccess === 0"
										placeholder="Live Link Access" prefix-icon="fas fa-users"
										v-model="dashSettingsEdit.livelinkAccess" />
								</div>
								<h5 class="aml-text-teal-3 aml-font-11 mt-2 mx-auto">Mobile Numbers should be separated
									by commas (,) in CSV format</h5>
							</div>
						</div>

						<div class="text-center">
							<el-divider class=""><small><i class="fas fa-university text-secondary" /> New Balance
									Setting</small></el-divider>
							<div class="row">
								<div class="col-6 mx-auto">
									<el-button @click="updateBalanceSetting()" type="warning"
										:loading="balanceSettingLoading">
										<i class="fas fa-credit-card" /> Get New Balance Setting
									</el-button>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {
	ElDivider, ElBadge, ElInput,
	ElSelect, ElOption, ElDatePicker,
	ElTooltip, ElSwitch, ElButton, ElMessage, ElNotification,
} from 'element-plus';
import axios from 'axios';
import Cropper from 'cropperjs';

export default {
	components: {
		ElDivider, ElBadge, ElInput,
		ElSelect, ElOption, ElDatePicker,
		ElTooltip, ElSwitch, ElButton,
		ElMessage, ElNotification,
	},
	props: ['event', 'isLoading', 'dashSettings', 'bankCredentials',],
	inject: ['$baseURL', '$numFrm', '$formatDateTime',],
	emits: ['event-updated'],
	data() {
		return {
			croppingImage: null,
			inputKey: 0,
			showUploadButton: null,
			currentItem: null,
			cropper: null,
			isCropModalVisible: false,
			isErrorModal: false,
			search: '',
			flip: false,
			eventEdit: {},
			dashSettingsEdit: {
				addActivationTopup: 1,
				addCompTopup: 1,
				addCouponTopup: 1,
				autoDayClose: 0,
			},
			bankCredentialsEdit: {},
			defaultCities: ['goa', 'bali', 'pune', 'mumbai', 'delhi', 'bengaluru', 'chennai', 'jaipur', 'jakarta', 'hyderabad'],
			defaultCountries: ['india', 'indonesia', 'singapore', 'malaysia', 'dubai', 'srilanka'],
			defaultTZ: [],
			defaultCurrencies: ['inr', 'idr', 'sgd', 'myr', 'aed', 'lkr',],
			defaultControllers: ['atomx', 'bms',],
			defaultTypes: ['event', 'clg-access',],
			defaultPrinters2: ['rugtek', 'blue2', 'wisepos+', 'sprin_3', 'sprin_2', 'panda', 'none',],
			defaultPrinters: [
				{ label: 'NONE', value: 'none' },
				{ label: 'Wisepos+', value: 'wisepos+' },
				{ label: 'EZETAP', value: 'airpay' },
				{ label: 'SunmiPay', value: 'sunmiPay+' },
				{ label: 'MF919', value: 'MF919' },
				{ label: 'MOSAMBEE', value: 'Dx8000' },
				{ label: 'AIRPAY', value: 'airpay' },
				{ label: 'Worldline', value: 'worldline' },
				{ label: 'RUGTEK', value: 'rugtek' },
				{ label: 'BT-2inch', value: 'blue2' }, // { label: 'BT-3inch', value: 'blue3' },
				{ label: 'SPrin-3inch', value: 'sprin_3' }, { label: 'SPrin-2inch', value: 'sprin_2' },
				{ label: 'Panda-3inch', value: 'panda' },
			],
			defaultEventDates: [],
			eventLoading: false,
			balanceSettingLoading: false,
			loginUser: {},
			// showEditBtn: false,

			shortcuts: [{
				text: 'Last week',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					return [start, end]
				})(),
			}, {
				text: 'Last month',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					return [start, end]
				})(),
			}, {
				text: 'Last 3 months',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					return [start, end]
				})(),
			}],
		}
	},
	methods: {
		triggerFileInput(event) {
			console.log(event);
			this.currentItem = event;
			// Set the current item
			// this.$refs.fileInput.click(); // Trigger the file input dialog
		},
		handleFileUpload(event) {
			console.log("handle file")
			const file = event.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					this.croppingImage = e.target.result;
					this.isCropModalVisible = true;
					this.$nextTick(() => {
						const image = document.getElementById('image-to-crop');
						this.cropper = new Cropper(image, {
							// Do not enforce aspect ratio for free cropping
							viewMode: 1,
							autoCropArea: 1, // Ensure the initial crop area is large
							responsive: true, // Ensures the cropper adjusts on window resize
							movable: true, // Allow moving the image
							zoomable: true, // Allow zooming the image
						});
					});
				};
				reader.readAsDataURL(file);
			}
		},
		closeCropModal() {
			this.inputKey++;
			this.currentItem = null;
			this.isCropModalVisible = false;
			if (this.cropper) {
				this.cropper.destroy();
				this.cropper = null;
			}
		},
		closeErrorModal() {
			this.isErrorModal = false;
		},
		cropImage() {
			// const canvas = this.cropper.getCroppedCanvas({
			//   width: 300,
			//   height: 300,
			//   imageSmoothingQuality: 'high',
			// });
			// Create a new canvas with 300x300 dimensions
			const croppedCanvas = this.cropper.getCroppedCanvas();
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const targetSize = 500;
			canvas.width = targetSize;
			canvas.height = (croppedCanvas.height / croppedCanvas.width) * targetSize;

			ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);

			if (croppedCanvas.width < croppedCanvas.height) {
				this.inputKey++;
				this.currentItem = null;
				this.isCropModalVisible = false;
				if (this.cropper) {
					this.cropper.destroy();
					this.cropper = null;
				}
				this.isErrorModal = true;
				return;
			}

			// // Calculate the width and height to maintain the aspect ratio
			// const ratio = Math.min(targetSize / croppedCanvas.width, targetSize / croppedCanvas.height);
			// console.log(ratio);
			// const width = croppedCanvas.width * ratio;
			// const height = croppedCanvas.height * ratio;

			// // Set canvas size to 300x300
			// canvas.width = targetSize;
			// canvas.height = targetSize;

			// // Fill the background with white (or any color you prefer)
			// ctx.fillStyle = '#ffffff';
			// ctx.fillRect(0, 0, canvas.width, canvas.height);

			// // Draw the cropped image in the center
			// const offsetX = (canvas.width - width) / 2;
			// const offsetY = (canvas.height - height) / 2;
			// ctx.drawImage(croppedCanvas, offsetX, offsetY, width, height);

			// Get the base64 string from the canvas
			const base64String = canvas.toDataURL('image/webp').split(',')[1]; // Remove the data URL prefix
			console.log(base64String);
			// Create the payload
			const payload = {
				file: base64String,
				eventId: this.currentItem.id,
				fileType: 'dashboard-event-img',
			};

			axios.post('https://fnbxapi.atomx.in/fnbx/upload', payload)
				.then(response => {
					if (response.data.success) {
						this.currentItem.uploadedImageName = `${this.currentItem.name}.webp`;
						this.currentItem.uploadedImagePath = response.data.path;
						this.closeCropModal();
					} else {
						alert('Image upload failed!');
					}
				})
				.catch(error => {
					console.error('Image upload error:', error);
					alert('Image upload error!');
				});
		},

		editEventCancel() {
			this.eventEdit = this.event;
			this.flip = !this.flip;
		},
		// editEventSave() {
		// 	// this.eventEdit = this.event;
		// 	// this.flip = !this.flip;
		// 	console.log(this.eventEdit);
		// },
		editEventOpen() {
			// console.log(this.event);
			this.eventEdit = JSON.parse(JSON.stringify(this.event));
			console.log(this.eventEdit);
			this.dashSettingsEdit = JSON.parse(JSON.stringify(this.dashSettings));
			this.bankCredentialsEdit = JSON.parse(JSON.stringify(this.bankCredentials));
			try {
				this.eventEdit.topupBtns = this.event.topupNames.split(',');
				this.eventEdit.topupVals = this.event.topupValues.split(',');
			} catch (e) {
				console.log('topup values error', e);
				this.eventEdit.topupBtns = [];
				this.eventEdit.topupVals = [];
			}
			// console.log(this.eventEdit.topupBtns, this.eventEdit.topupVals);
			this.flip = !this.flip;
			this.defaultEventDates = [new Date(this.eventEdit.startAt), new Date(this.eventEdit.endAt)];
		},
		editEventSave() {
			// console.log('edit event', this.eventEdit, this.bankCredentialsEdit);
			this.eventEdit.topupNames = this.eventEdit.topupBtns.join(',');
			this.eventEdit.topupValues = this.eventEdit.topupVals.join(',');
			if (this.eventEdit.topupNames.split(',').length !== 4 || this.eventEdit.topupValues.split(',').length !== 4)
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Invalid Parameters</b><br>Topup Values and Names Invalid. Donot use commas.",
					type: 'warning',
					grouping: true,
					duration: 20000,
				});
			if (this.eventEdit.cardFee > 10000000 || this.eventEdit.minTopup > 10000000 || this.eventEdit.maxTopupWallet > 10000000)
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Invalid Parameters</b><br>Card Fee / Min First Topup / Max Wallet can be upto 1 Cr or 10 Million only.",
					type: 'warning',
					grouping: true,
					duration: 20000,
				});
			if (this.eventEdit.tokenConversion <= 0)
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Invalid Parameters</b><br>Token Conversion Rate should be a number and greater than 0.",
					type: 'warning',
					grouping: true,
					duration: 20000,
				});
			this.eventLoading = true;
			axios.post(
				this.$baseURL + '/Event/Edit/',
				{ event: this.eventEdit, dashSettings: this.dashSettingsEdit, bankCredentials: this.bankCredentialsEdit, },
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						this.flip = !this.flip;
						// console.log(response.data);
						this.$emit('event-updated', this.eventEdit, this.dashSettingsEdit, this.bankCredentialsEdit);
						window.scrollTo(0, 0);
					}
					this.eventLoading = false;

				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 10000,
					});
					console.log("err:", error);
					this.eventLoading = false;
				});
		},
		updateBalanceSetting() {
			if (!confirm("All Previous Cards will become Inactive after this process? Are You Sure?")) return;
			this.balanceSettingLoading = true;
			axios.post(
				this.$baseURL + '/Event/updateLocationId/',
				{ eventId: this.event.id },
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						this.flip = !this.flip;
						this.$emit('event-updated', response.data.event);
						window.scrollTo(0, 0);
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Please update all the devices for the changes to reflect</b>",
							type: 'success',
							grouping: true,
							duration: 10000,
						});
						ElNotification({
							title: 'Done',
							dangerouslyUseHTMLString: true,
							message: '<br/>Reference: <h2>' + response.data.event.locationId + '</h2><br/>Please update all the devices for the changes to reflect the above Reference number',
							type: 'success',
							duration: 0,
							offset: 100,
						})
					}
					this.balanceSettingLoading = false;

				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 10000,
					});
					// console.log("err:", error);
					this.balanceSettingLoading = false;
				});
		},
		showEditBtn() {
			// if( !this.dashSettings )	return true
			return (this.dashSettings && this.dashSettings.showEdit === 1)
		}

	},
	mounted() {
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		// if(u.loginType === 'admin')	this.showEditBtn = true;
		this.defaultTZ = [];
		for (let i = 0; i < 24; i++) {
			this.defaultTZ.push('GMT-' + ("00" + i).substr(-2) + ':' + '00');
			this.defaultTZ.push('GMT-' + ("00" + i).substr(-2) + ':' + '30');
		}
		for (let i = 0; i < 24; i++) {
			this.defaultTZ.push('GMT+' + ("00" + i).substr(-2) + ':' + '00');
			this.defaultTZ.push('GMT+' + ("00" + i).substr(-2) + ':' + '30');
		}
	},
	watch: {
		$route(to, from) {
			// console.log('route change detected');
			this.flip = false;
			// this.setLoading();
			// this.loadDetails();
			// this.event = {};
			// this.setDefault();
		},
		summaryInfo: {
			handler(a) {
				// console.log("summaryInfo update detect", a);
				this.$emit('summary-fetched', a);

				if (!a) return;
				let summaryChartData = [+this.summaryInfo.sale.amount + +this.summaryInfo.no_nfc_sale.amount, this.summaryInfo.topup.amount, this.summaryInfo.return.amount];
				this.summaryChart.createChart(this.headerChartID3, ['SALE', 'TOPUP', 'RETURN'], summaryChartData);
				this.summaryChart.createChart(this.headerChartID2, ['SALE', 'TOPUP', 'RETURN'], summaryChartData);
			},
			deep: true,
		},
		event: {
			handler(a) {
				// console.log("event update detect", a);
				// this.eventEdit = a;
				// this.defaultEventDates = [ new Date(a.startAt), new Date(a.endAt) ];
			},
			deep: true,
		},
	},
};
</script>
<style>
.el-table td,
.el-table th {
	padding: 0.55rem 0 !important;
}
</style>
<style>
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
}

/* flip the pane when hovered */
/*.flip-container:hover .flipper, .flip-container.hover .flipper {*/
.aml-flip {
	transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
	/*width: 320px;*/
	/*height: 480px;*/
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front,
.back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
}

.aml-event-edit-name input {
	background-color: transparent !important;
	color: white;
}

.aml-pass-badge {
	text-transform: none !important;
}
</style>
