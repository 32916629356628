<template>
	<div class="card card-stats card-raised">
		<div class="card-header" >
			<h5 class="card-category">FREEBIE LIST ({{ list.length }})</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute; z-index: 1500">
				<span class="col-3 text-right text-info cursor-pointer" @click="createFreebieDialog = true;"><i class="fa fa-plus"/> FREEBIE</span>
				<span class="badge badge-primary" v-if="isLoading.list">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Card" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-12"
					  :data="list.filter(data => !search
					  || data.rrn?.toString().includes(search.toLowerCase())
					  || data.receipt?.toString().includes(search.toLowerCase())
					  || data.devicePrintId?.toString().includes(search.toLowerCase())
					  || data.cardBin?.toString().includes(search.toLowerCase())
					  || data.cardNo?.toString().includes(search.toLowerCase())
					  || data.cardId?.toString().includes(search.toLowerCase())
					  )"
					  :row-class-name="tableRowClassName"
			>
				<el-table-column label="#" type="index"/>
				<el-table-column sortable label="BANK CARD">
					<template #default="scope">
						<div><h5 class="d-inline">{{ scope.row.cardBin }}</h5>xxxx<h5 class="d-inline">{{ scope.row.cardNo }}</h5></div>
						<div><strong>Amount:</strong> {{ scope.row.txAmount }}</div>
						<div v-if="scope.row.freebieAmount > 0"><strong>FREEBIE:</strong> {{ scope.row.freebieAmount }}</div>
					</template>
				</el-table-column>
				<el-table-column sortable label="CARD ID / DEVICE" prop="cardId">
					<template #default="scope">
						<div><strong>cardId:</strong> {{ scope.row.cardId }}</div>
						<div><strong>device:</strong> {{ scope.row.devicePrintId }}</div>
						<div>
							<h5 class="d-inline" :class="scope.row.action === 'tap' ? 'text-success' : 'text-warning'">{{ scope.row.action.toUpperCase() }}</h5>
							<span class="ml-2">{{ displaydt(scope.row.createdAt) }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column sortable label="TX DETAILS">
					<template #default="scope">
						<div><u>RRN:</u> {{ scope.row.rrn }} | {{ scope.row.mode }}</div> <div><u>RECEIPT:</u> {{ scope.row.receipt }}</div>
						<div>{{ scope.row.cardProcessor }} | {{ scope.row.cardType }} | {{ scope.row.bankProcessor }} | {{ scope.row.acquirer }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<span class="aml-dialog">
		<el-dialog title="CHECK & CREATE FREEBIE" v-model="createFreebieDialog">
			<el-form class="my-3 col-md-8 col-12 mx-auto">
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">CARD BIN*</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="cardId" v-model="filter.cardBin" placeholder="CARD BIN" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">LAST 4 DIGITS*</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="cardId" v-model="filter.cardNo" placeholder="LAST 4 DIGITS" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">CARD ID*</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="cardId" v-model="filter.cardId" placeholder="CARD ID" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">AMOUNT*</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="cardId" v-model="filter.amount" placeholder="AMOUNT" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">DEVICE</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="orderId" v-model="filter.devicePrintId" placeholder="DEVICE ID" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">RRN</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="orderId" v-model="filter.rrn" placeholder="RRN" autocomplete="off"/>
					</div>
				</span>
				<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
					<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">RECEIPT</h6>
					<div class="ml-2 col-md-7 col-10 mx-auto">
						<el-input ref="paymentId" v-model="filter.receipt" placeholder="RECEIPT" autocomplete="off"/>
					</div>
				</span>
				<div class="text-center my-2">
					<el-button type="primary" class="aml-bg-teal-3" :loading="isLoading.addFreebie" @click="addNewFreebie()">ADD</el-button>
				</div>
			</el-form>
			<div class="col-md-6 col-10 mx-auto my-2" v-if="this.newFreebieLoaded || this.freebieUtilized">
				<div class="card card-stats card-raised">
					<div class="card-body text-center mt-2">
						<div v-if="this.newFreebieLoaded">
							<div class="mt-2"><i class="fas fa-check-circle fa-3x text-success"/></div>
							<h5>SUCCESS</h5>
							<h6>FREEBIE AMOUNT: {{ freebieAmount }}</h6>
						</div>
						<div v-if="this.freebieUtilized">
							<div class="mt-2"><i class="fas fa-exclamation-circle fa-3x text-warning"/></div>
							<h5>OFFER INVALID</h5>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</span>
</template>
<script>
import {
	Card,
	Table as NTable,
	AnimatedNumber,
	Progress as NProgress,
} from "@/components";
import axios from 'axios';
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import {
	ElDivider, ElBadge, ElDialog,
	ElButton, ElTable, ElTableColumn, ElForm
} from "element-plus";
import moment from "moment/moment";

export default {
	components: {
		ElForm,
		Card,
		NTable,
		AnimatedNumber,
		NProgress,
		ElDivider, ElBadge, ElDialog,
		ElButton, ElTable, ElTableColumn,
	},
	inject: ['$baseURL', '$numFrm2', '$formatDateTime', ],
	props: ['event'],
	data() {
		return {
			isLoading: { list: true, event: true, addFreebie: false, },
			addFreebie: false,
			cardId: null,
			search: null,
			list: [],
			createFreebieDialog: false,
			newFreebieLoaded: false,
			freebieUtilized: false,
			freebieUtilizedMessage: "",
			freebieAmount: 0,
			defaultInfo: {
				filter: {
					"eventId": "",
					"rrn": "",
					"receipt": "",
					"txType": "topup",
					"mode": "card",
					"amount": "",
					"acquirer": "manual",
					"bankProcessor": "manual",
					"cardProcessor": "visa",
					"cardType": "credit",
					"action": "tap",
					"cardBin": "",
					"cardNo": "",
					"cardId": "",
					devicePrintId: ""
				},
			},
			filter: {
				"eventId": "",
				"rrn": "",
				"receipt": "",
				"txType": "topup",
				"mode": "card",
				"amount": "",
				"acquirer": "manual",
				"bankProcessor": "manual",
				"cardProcessor": "visa",
				"cardType": "credit",
				"action": "tap",
				"cardBin": "",
				"cardNo": "",
				"cardId": "",
				devicePrintId: ""
			},
		};
	},
	methods: {
		runToast(text, pos, type, ownText, ownIcon) {
			// const text =
			// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
			const icon = "now-ui-icons ui-1_bell-53";
			const content = {
				component: Notification,

				props: {
					ownText: ownText,
					ownIcon: ownIcon,
					icon: icon,
					text: text,
					type: type,
				},
			};
			const toast = useToast();
			toast(content, {
				hideProgressBar: true,
				icon: false,
				closeButton: false,
				position: pos,
			});
		},
		setDefault(key = 'all') {
			if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		tableRowClassName({row, rowIndex}) {
			if (row.status == 'completed') return 'success-row';
			return 'warning-row';
		},
		displaydt(dt) {
			return dt ? moment(dt).local().format("YYYY-MM-DD HH:mm:ss") : 'NA';
		},
		loadDetails() {
			let u = this.loginUser;
			let code = 0;
			let url = '/Freebies/List/';
			switch (u.loginType) {
				case 'event':
				case 'operator':
					code = u.id;
					break;
				case 'admin':
					code = this.$route.params.code;
					break;
				default:
					return ;
					break;
			}
			this.isLoading.list = true;
			this.list = [];
			axios.get(
				this.$baseURL + url,
				{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) this.list = response.data.list;
					else	this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message , "fas fa-bomb fa-4x");
					this.isLoading.list = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.list = false;
				});
		},
		addNewFreebie() {
			if( !this.filter.cardBin || !this.filter.cardNo || !this.filter.cardId || !this.filter.amount )
				return this.runToast('', 'top-center', 'danger', "<b>MISSING PARAMETERS</b><br> Fill in the required details", "fas fa-bomb fa-4x");
			let u = this.loginUser;
			let code = 0;
			let url = 'https://fnbxapi.atomx.in/freebie/check';
			switch (u.loginType) {
				case 'event':
				case 'operator':
					code = u.id;
					break;
				case 'admin':
					code = this.$route.params.code;
					break;
				default:
					return ;
					break;
			}
			this.isLoading.addFreebie = true;
			this.newFreebieLoaded = false;
			this.freebieUtilized = false;
			this.filter.eventId = parseInt(code);
			this.filter.amount = parseFloat(this.filter.amount);
			this.filter.rrn = this.filter.rrn === "" ? new Date().getTime().toString() : this.filter.rrn;
			axios.post( url, this.filter )
				.then(response => {
					if(response.data.success) {
						this.runToast('', 'top-right', 'success', "<b>SAVED</b><br>", "fas fa-bomb fa-4x");
						this.newFreebieLoaded = true;
						this.freebieAmount = response.data.freebie;
					}
					else	{
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message , "fas fa-bomb fa-4x");
						this.freebieUtilized = true;
						this.freebieUtilizedMessage = response.data.message;
					}
					this.isLoading.addFreebie = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.addFreebie = false;
				});
		},
	},
	mounted() {
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
		// setTimeout(this.loadDetails, 500);
		if(this.event.id) this.loadDetails();
	},
	watch: {
		event (to, from){
			// console.log('$event change detected');
			this.loadDetails();
		},
	}
};
</script>
<style>
.aml-card-header {
	border-radius: .5875rem !important;
	min-height: 100px;
	/*background-color: #2198af !important;*/
}
.aml-event-card {
	/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
	box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
}
.el-table .warning-row {
	background: rgb(255 209 126 / 11%);
}
.el-table .success-row {
	background: rgb(218 252 190 / 41%);
}
</style>
