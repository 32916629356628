<template>
	<div>
		<div class="row " >
			<div class="col-12 col-md-10 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">PERSONS ( {{ persons.length }} )</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.persons">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body">
						<el-input placeholder="Search" size="mini" prefix-icon="el-icon-search" v-model="search"/>

						<div class="row border-bottom border-top mt-2 py-3 d-block justify-content-end" style="box-shadow: -4px 10px 10px 0px rgb(250 250 250);">
							<h5 class="text-primary mb-0 col-12 ">PERSONS</h5>
						</div>

						<div style="height: 500px; overflow: auto;">
							<div class="col-12 row border-bottom"
								 v-for="person in persons.filter(
								 	data => !search ||
								 	data.name.toLowerCase().includes(search.toLowerCase()) ||
								 	data.idCard.toLowerCase().includes(search.toLowerCase()) ||
								 	data.id.toString().toLowerCase().includes(search.toLowerCase())
								)">
								<div class="col-2 d-none d-md-block my-auto">
									<div class="row">
										<span class="col p-0 m-1">
											<img class="rounded" :alt="person.name" :src="person.image">
										</span>
									</div>
								</div>
								<div class="col-12 col-md-10 cursor-pointer my-2 d-inline bg-light" @click="personOpen(person)">
									<h5>
										<strong class="aml-text-teal">{{ person.idCard }}</strong>
										<el-divider direction="vertical"/>{{ person.name }}
									</h5>
									<div class="row d-md-none ">
										<span class="col p-0 mx-1">
											<img class="rounded-circle" :alt="person.name" :src="person.image">
										</span>
									</div>
									<div class="mt-2">
										<span class=""><strong>ATOMX-ID:</strong> {{ person.id }}</span>
										<el-divider direction="vertical"/>
										<span class=""><i class="fas fa-clock"/>
											{{ new Date(person.updatedAt).getTime() }}
											<el-divider direction="vertical"/>
											{{ displaydt(person.updatedAt) }}
										</span>
									</div>
									<div class="mt-2">
										<span class="aml-text-teal_ aml-bg-teal-3 text-white px-1 badge">{{ person.type }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<span class="aml-dialog">
					<el-dialog :title="person.name" v-model="personDialog">
						<div class="my-3 row col-12 col-md-6 mx-auto">
							<div class="col-2 d-none my-auto">
								<div class="row">
									<span class="col p-0 mx-1">
										<img class="rounded-circle" :alt="person.name" :src="person.image">
									</span>
								</div>
							</div>
							<div class="col-12  my-2 d-inline bg-light">
								<h5>
									<strong class="aml-text-teal_ aml-bg-teal-3 text-white px-1">{{ person.idCard }}</strong>
									<el-divider direction="vertical"/>
									<span class="aml-text-teal-3">{{ person.id }}</span>
									<el-divider direction="vertical"/>{{ person.name }}
								</h5>
								<div class="mt-2">
	<!--								<span class=""><strong>ATOMX-ID:</strong> {{ person.id }}</span>-->
	<!--								<el-divider direction="vertical"/>-->
									<div class="">
										<strong1>REGISTRY: </strong1>
										<i class="fas fa-clock"/>
										{{ displaydt(person.registryAt) }}
									</div>
									<div class="">
										<strong1>UPDATED: </strong1>
										<i class="fas fa-clock"/>
										{{ displaydt(person.updatedAt) }}
										<el-divider direction="vertical"/>
										( {{ new Date(person.updatedAt).getTime() }} )
									</div>
								</div>
								<div class="row mx-auto mt-2" v-if="person.faces && person.faces.length >0">
	<!--								<div class="my-2"> <h6>FACES</h6> </div>-->
									<div class="">
										<span v-for="face in person.faces" class="col p-0 mx-1">
											<img :alt="person.name" :src="face.imgPath">
										</span>
									</div>
								</div>
								<div class="mt-2">
									<span class=" ">
										<el-select class="select-primary " v-model="newPersonType" placeholder="Select Type" autocomplete="off">
											<el-option v-for="type in [ 'staff', 'intern', ]" :key="type" :label="type.toUpperCase()" :value="type"/>
										</el-select>
									</span>
<!--									<button class="col btn btn-light" v-if="newPersonType !== person.type" @click="updateType()" :loading="isLoading.updateType">-->
<!--										Update Type-->
<!--									</button>-->
									<el-button class="ml-2 aml-bg-teal-3 text-white" circle v-if="newPersonType !== person.type"
											   :loading="isLoading.report" icon="fas fa-upload" @click="updateType()"/>
								</div>
							</div>
						</div>
						<div class="">
							<div class="row col-12 border-bottom border-top mt-2 py-3 d-block justify-content-end" style="box-shadow: -4px 10px 10px 0px rgb(250 250 250);">
								<h5 class="text-primary mb-0 col-12">RECORDS</h5>
								<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
									<span class="badge badge-primary" v-if="isLoading.records">
										<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
									</span>
								</div>
							</div>
							<div class="col-12 row border-bottom" v-for="record in records">
								<div class="col-2 d-md-block my-auto">
									<img :alt="record.id" :src="'https://atomx.s3.ap-south-1.amazonaws.com/armorx/clients/' + record.clientId + '/records/faces/' + record.id + '.jpg'">
								</div>
								<div class="col-10 cursor-pointer my-2 d-inline bg-light my-auto">
									<h5 class="d-inline">
										<i class="fas fa-temperature-high mr-1"/>
										<strong :class="record.visitorTemp < 38 ? 'aml-text-teal' : 'text-danger'">{{ record.visitorTemp }}</strong>
									</h5>
									<el-divider direction="vertical"/>
									<strong>{{ record.scanType ? record.scanType.toUpperCase() : '' }}</strong>
									<el-divider direction="vertical"/>
									<i class="fas fa-clock"/> {{ displaydt(record.scanAt) }}
<!--									| {{ $formatDateTime(new Date(record.scanAt), true) }}-->
								</div>
							</div>
						</div>
						<template #footer>
							<span class="dialog-footer">
							  <el-button @click="personDialog = false">Cancel</el-button>
<!--							  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="isLoading.records" @click="saveVolunteer">Confirm</el-button>-->
							</span>
						</template>
					</el-dialog>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import {
		ElDivider, ElBadge, ElDialog, ElButton,
		ElForm, ElFormItem, ElSelect, ElOption,
		ElTable, ElTableColumn,
	} from 'element-plus';
	import moment from 'moment';
	import login from "../../../services/login";


	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge, ElDialog, ElButton, ElForm,
			ElFormItem, ElSelect, ElOption,
			ElTable, ElTableColumn,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'info-fetched', 'loading-complete' ],
		props: [ 'user', ],
		data() {
			return {
				defaultInfo: {
					info: { name: '', country: 'india', },
					persons: [],
					records: [],
					person: { name: '' },
					components: { persons: true, records: true, },
					isLoading: { persons: true, records: true, },
				},
				loginUser: {},
				info: { name: '', country: 'india', },
				persons: [],
				records: [],
				newPersonType: 'staff',
				person: { name: '' },
				components: { persons: true, records: true, },
				isLoading: { persons: true, records: true, updateType: false, },
				search: '',
				personDialog: false,
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				axios.get(
					this.$baseURL + '/FaceX/Admin/Details/',
					{ params: { code: this.loginUser.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.info = response.data.info;
						this.loadPersons();
					}
					this.isLoading.volunteers = false;
					this.$emit('loading-complete');
					this.$emit('info-fetched', response.data.info);
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.$emit('loading-complete');
				});
			},
			displaydt(dt) {
				return dt ? moment(dt).local().format('DD-MM-YYYY hh:mm:ss') : 'NA';
			},
			loadPersons() {
				axios.get(
					this.$baseURL + '/FaceX/Users/',
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.persons = response.data.users;
						// console.log(this.persons);
					}
					this.isLoading.persons = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.persons = false;
				});
			},
			loadRecords( code ) {
				this.isLoading.records = true;
				this.records = [];
				this.newPersonType = this.person.type;
				axios.get(
					this.$baseURL + '/FaceX/User/Details',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.records = response.data.records;
						this.person = response.data.person;
					}
					this.isLoading.records = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.records = false;
				});
			},
			updateType() {
				this.isLoading.updateType = true;
				axios.get(
					this.$baseURL + '/FaceX/User/UpdateType',
					{ params: { code: this.person.id, type: this.newPersonType }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.person.type = this.newPersonType;
						}
						this.isLoading.updateType = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.updateType = false;
					});
			},
			personOpen(person = { name: '', }) {
				this.personRow = person ;
				this.person = person;
				this.loadRecords(person.personDeviceId);
				this.personDialog = true;
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		}
	};
</script>
<style>
	.avatar img {
		border-radius: 50%;
		position: relative;
		left: -5px;
		z-index: 1;
	}
</style>
