<template>
	<div style="min-height: 2000px;">
		<div class="row justify-content-around" >
			<div class="col-12 col-md-4">
				<SearchItems v-if="components.items"
							 :event="event" :stall="stall"
							 :menu="menu" :allItems="allItems"
							 :loading="isLoading.allItems"
							 @Item-Searched="itemSearched"
							 @Add-Items="addItems"
				/>
			</div>
			<div class="col-12 col-md-7">
				<Items v-if="components.items"
					   :event="event" :stall="stall" :allItems="allItems"
					   :items="items" :categorySelected="categorySelected"
					   @item-remove="itemRemove" @save-complete="saveComplete"
				/>
			</div>
		</div>

		<div class="col-12">
			<Notes v-if="components.items" :loading="isLoading.notes"
				   :event="event" :stall="stall" :notes="notes"
			/>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import Items from './components/items';
	import Notes from './components/notes';
	import SearchItems from './components/SeachItems';
	import { ElDivider, ElBadge } from 'element-plus';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			Items, SearchItems, Notes,
		},
		inject: ['$baseURL', '$numFrm', ],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: {  },
					stall: {},
					components: { items: false,  },
					isLoading: { items: true, allItems: true, notes: true, },
					menu: {}, allItems: [], items: {}, notes: [],
				},
				loginUser: {},
				categorySelected: "1",
				event: { name: '', country: 'india', type: '' },
				stall: {},
				menu: {}, allItems: [], ogItems: [], items: {}, notes: [],
				components: { items: false,  },
				isLoading: { items: true, allItems: true, notes: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				switch(this.event.type) {
					case 'fnb':
					case 'event':
						this.components.items = true;
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			saveComplete(note) {
				this.setDefault('items');
				this.allItems = JSON.parse(JSON.stringify(this.ogItems));
				this.notes.unshift(note);
			},
			itemSearched(item) {
				if(typeof this.items[ item.categoryId + "|" + item.category ] === 'undefined')	this.items[ item.categoryId + "|" + item.category ] = [];
				this.items[ item.categoryId + "|" + item.category ].push(item);
				this.categorySelected = item.categoryId.toString();
			},
			addItems(items_) {
				debugger;
				this.items = {};
				items_.forEach((item_)=>{
					let grn = item_.split(',');
					if(grn.length < 6)	return;
					grn[5] = grn[5].replace('\r', '');
					if(isNaN(grn[5]) || parseInt(grn[5]) <= 0)	return;
					let itm = this.ogItems.find(i => i.id === parseInt(grn[2]));
					if(itm) {
						if (typeof this.items[itm.categoryId + "|" + itm.category] === 'undefined') this.items[itm.categoryId + "|" + itm.category] = [];
						itm.qty = parseInt(grn[5]);
						this.items[itm.categoryId + "|" + itm.category].push(itm);
					}
				});
			},
			itemRemove(item) {
				item.qty = '';
				if(this.items[item.categoryId + '|' + item.category].length ===0)	{
					delete this.items[item.categoryId + '|' + item.category];
					this.categorySelected = Object.keys(this.items).length > 0 ? Object.keys(this.items)[0].split('|')[0] : '';
				}
				this.allItems.push(item);
			},
			loadData(dataType) {
				this.isLoading[dataType] = true;
				axios.get(
					this.$baseURL + '/Vendors/ViewAll',
					{
						id: this.$route.params.code, dataType, eventType: this.event.type,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) { this.updateLocal(dataType, response.data); }
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});

			},
			loadDetails() {
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
						url = '/Event/Details/';
						code = u.id;
						break;
					case 'vendor':
					case 'admin':
						url = '/Event/Details/';
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						this.setDefault('event');
						// console.log(this.event);
						this.event = response.data.event;
						// console.log(this.event);
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.setComponentsDisplay();
						this.loadItems(this.$route.params.stallCode, this.event.id);
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});

				axios.get(
					this.$baseURL + '/Stalls/Details/',
					{ params: { code: this.$route.params.stallCode }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						this.setDefault('stall');
						// console.log(this.event);
						this.stall = response.data.stall;
						this.loadNotes(this.stall.id);
						// console.log(this.event);
						// this.isLoading.stall = false;
						// this.dataLoad();
						// this.$emit('event-fetched', this.event);
						// this.setComponentsDisplay();
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong. Unable to load Stall Details</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});
			},
			loadItems(stall, code) {
				this.isLoading.allItems = true;
				axios.get(
					this.$baseURL + "/Menu/List",
					{ params: { stall, code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							this.allItems = [];
							this.menu = {};
							response.data.menu.forEach(category => {
								if(category.status === 'active') {
									category.items.forEach(item => {
										if(item.status === 'active') {
											item.category = category.name;
											this.allItems.push(item);
											this.ogItems.push(item);
											let catg = category.id + "|" + category.name;
											if (typeof this.menu[catg] === 'undefined') this.menu[catg] = [];
											this.menu[catg].push(item);
										}
									});
								}
							});
							// console.log(this.allItems);
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					});
			},
			loadNotes(stall) {
				this.isLoading.notes = true;
				axios.get(
					this.$baseURL + "/Notes/List",
					{ params: { stall, inventoryType: this.$route.params.inventoryType },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							this.notes = response.data.notes;
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						this.isLoading.notes = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.notes = false;
					});
			},
		},
		mounted() {
			if(this.$route.params.inventoryType === 'GRN' || this.$route.params.inventoryType === 'RN' ) {
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
				// setTimeout(this.loadDetails, 500);
				this.loadDetails();
				window.scrollTo(0, 0);
			}
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				if(this.$route.params.inventoryType === 'GRN' || this.$route.params.inventoryType === 'RN' ) {
					let u = JSON.parse(localStorage.getItem('user'));
					this.loginUser = u;
					if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
					this.setDefault();
					this.loadDetails();
				}
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
