<template>
	<navbar :show-navbar="showNavbar" id="navigation">
		<div class="navbar-wrapper">
			<div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
				<navbar-toggle-button v-if="!$route.query.e" @click="toggleSidebar"/>
			</div>
			<h3 class="d-md-inline-block d-none">
				{{ pageHeading }}
			</h3>
			<h3 class="d-inline-block d-md-none">
				{{ pageHeading ? pageHeading.substring(0, 12) : '' }}
			</h3>
		</div>
		<button
			@click="toggleNavbar"
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navigation"
			aria-controls="navigation-index"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span v-if="!$route.query.e">
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
			</span>
		</button>

		<template v-slot:navbar-menu>

		</template>
	</navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "@/components";
import { copyText } from 'vue3-clipboard';

import { ElSwitch, ElTooltip, ElRadio, ElRadioGroup, ElRadioButton, } from 'element-plus';

import { ElDialog, ElForm, ElFormItem, ElButton, } from 'element-plus';
import axios from "axios";

export default {
	components: {
		Navbar,
		NavbarToggleButton,
		copyText,
		ElSwitch, ElTooltip,
		ElDialog, ElForm, ElFormItem, ElButton,
		ElRadio, ElRadioGroup, ElRadioButton,
	},
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	inject: [ '$baseURL' ],
	props: ['pageHeading', 'code', ],
	emits: [ 'dayClosed', ],
	data() {
		return {
			activeNotifications: false,
			showNavbar: false,

			dayCloseDialog: false,
			dayCloseLoading: false,
			dayCloseName: '',
		};
	},
	methods: {
		base64_aml(v) { return btoa(v); },
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		toggleNavbar() {
			this.showNavbar = !this.showNavbar;
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		copyLink(url) {
			copyText(url, undefined, (error, event) => {
				if (error) {
					alert('Can not copy')
					console.log(error)
				} else {
					alert('Copied')
					console.log(event)
				}
			})
		},
		closeDay(){
			this.dayCloseLoading = true;
			axios.post(
				this.$baseURL + '/Event/DayClose/',
				{
					eventId: this.code, day: this.dayCloseName,
				},
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) {
						this.dayCloseDialog = false; this.dayCloseName = '';
						this.$emit('dayClosed', response.data.log);
					}
					this.dayCloseLoading = false;
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.dayCloseLoading = false;
				});
		},
	},
	watch: {
		$route(to, from) {
			this.dayCloseLoading = false;
			this.dayCloseName = '';
			this.dayCloseDialog = false;

		},
	}
};
</script>
<style>
.nav-item .el-switch__label {
	color: #1398b0 !important;
}
.nav-item .el-switch__label.is-active {
	color: white !important;
}
.nav-item .el-switch__core {
	border-color: white !important;
}
</style>
