<template>
	<div>
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category">{{ $route.params.inventoryType.toUpperCase() }} LIST</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<span class="badge badge-primary" v-if="loading">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div v-if="!loading">
					<el-table class="w-100 aml-font-12" :data="notes" height="700">
						<el-table-column label="#" type="index"/>
						<el-table-column align="center" width="50">
							<template #header> <i class="fas fa-download"/> </template>
							<template #default="scope">
								<span class="cursor-pointer" @click="downloadCSVData(scope.row)"> <i class="fas fa-download"/> </span>
							</template>
						</el-table-column>
						<el-table-column type="expand">
							<template #default="scope" class="p-0">
								<span class="bg-light p-0 m-0 d-block">
									<span class="row col-11 mx-auto">
										<div class="col-md-9 col-12 border-right">
											<el-divider class="">
												<small><i class="fas fa-utensils text-secondary"></i> ITEMS <el-divider direction="vertical"/>
													<span class="cursor-pointer" @click="downloadCSVData(scope.row)"> <i class="fas fa-download"/> </span></small>
											</el-divider>
											<el-table class="w-100 aml-font-11 aml-devices-table" :data="scope.row.items">
												<el-table-column label="ITEM">
													<template #default="scope_">
														<div><h6 class="d-inline">{{ scope_.row.item_name }}</h6></div>
														<div><h6 class="d-inline"><small>{{ scope_.row.category_name }}</small></h6></div>
<!--														<div>-->
<!--															<h6 class="d-inline"><small>COST: {{ $numFrm(scope_.row.costPrice, 'float', event.country) }}</small></h6>-->
<!--															<el-divider direction="vertical"/>-->
<!--															<h6 class="d-inline"><small>MRP: {{ $numFrm(scope_.row.mrp&lt;!&ndash;, 'float', event.country) }}</small></h6>-->
<!--															<el-divider direction="vertical"/>&ndash;&gt;-->
<!--															<h6 class="d-inline"><small>SP: {{ $numFrm(scope_.row.price, 'float', event.country) }}</small></h6>-->
<!--														</div>-->
<!--														<div><h6 class="d-inline"><small>HSN: {{ scope_.row.hsn }}</small></h6> <el-divider direction="vertical"/> <h6 class="d-inline"><i class="fas fa-barcode"/> <small>: {{ scope_.row.barcode }}</small></h6></div>-->
													</template>
												</el-table-column>
												<el-table-column label="QTY" prop="qty"/>
											</el-table>
										</div>
									</span>
								</span>
							</template>
						</el-table-column>
						<el-table-column label="Timestamp">
							<template #default="scope">
								{{ displaydt(scope.row.created_at) }}
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div v-else>
					<el-skeleton :rows="5" animated />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
		ElForm, ElFormItem, ElButton, ElDialog, ElDivider, ElUpload,
		ElTable, ElTableColumn,
	} from 'element-plus';
	import axios from "axios";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import moment from 'moment';

	export default {
		components: {
			ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
			ElForm, ElFormItem, ElButton, ElDialog, ElDivider, useToast, Notification,
			ElUpload, ElTable, ElTableColumn,
			moment,
		},
		inject: ['$baseURL', '$numFrm', '$removeData', ],
		props: [ 'event', 'stall', 'notes', 'loading', ],
		data() {
			return {
			}
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			displaydt(dt) {
				return dt ? moment(dt).local().format('DD-MM-YY hh:mm:ss') : 'NA';
			},
			downloadCSVData(note){
				let csv = '';
				csv += ',,,\n';
				csv += this.stall.name + ',,,\n';
				csv += 'Type:' + note.type + ',,Date:' + moment(note.timestamps.createdAt).local().format('DD-MM-YY hh:mm:ss') + ',\n';
				csv += ',,,\n';
				csv += 'ITEMS:,,,\n';
				csv += 'Category,Item,Barcode,Quantity\n';
				// csv += 'Item Name 1,0,0,0,0,,,food\n';
				// csv += 'Item Name 2,0,0,0,0,,,food\n';
				// this.csvdata.forEach((row) => {
				// 	csv += row.join(',');
				// 	csv += "\n";
				// });

				note.items.forEach((item) => {
					csv += [item.category, item.name, item.barcode, item.qty].join(',') + "\n";
				});

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = this.stall.name + "|" +  note.type +
					moment(note.timestamps.createdAt).local().format('DD-MM-YY hh:mm:ss')
						.replaceAll('-', '').replace(' ', '').replaceAll(':', '')
					+ '.csv';
				anchor.click();
			},
		},
		mounted() {},
		watch: {}
	}
</script>
<style>
.aml-text-danger .el-input__inner {
	color: #ff6363 !important;
	border-color: #ff6363 !important;
	background-color: #f003 !important;
}
</style>
