<template>
	<div class="panel-header panel-header-lg row mx-auto">
		<div class="col-12 d-none d-print-inline-block position-absolute fixed-top mt-4 text-white"><h2 class="text-uppercase">{{  }}</h2></div>

		<div class="col-md-8 h-100">
			<canvas :id="headerChartID"/>
		</div>
		<div class="col-md-4 m-auto  d-md-inline d-none">
			<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white mb-0 py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
				<div class="card-body pr-0 pl-2">
					<div class=" h-50 ">
						<h5 class="text-white text-center"> {{ getPeriodName() }}</h5>
						<canvas :id="headerChartID2"/>
					</div>
					<div >
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left" v-if="summaryInfo.sale.amount > 0 || summaryInfo.no_nfc_sale.amount > 0">
								<!--<h5 class="aml-border-radius-1 bg-white stats-title text-primary my-auto px-1 py-2 mx-auto aml-font-11 text-center">SALES</h5>-->
								<span class="badge bg-white aml-orange-2 border-0">SALES</span>
							</div>
							<div class="col-9 pr-0">
								<div v-if="summaryInfo.sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline" v-if="summaryInfo.no_nfc_sale.amount > 0">NFC </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country === 'india'"> ₹ </small>
											<!--<animated-number :value="1853"></animated-number>-->
											<strong class="">{{ $numFrm(summaryInfo.sale.amount, 'float', eventInfo.country)  }}</strong>
										</h5>
									</span>
									<!--<small class="col-2 text-right px-0 my-auto ml-2">(19,234)</small>-->
								</div>
								<div v-if="summaryInfo.no_nfc_sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline">POS </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country === 'india'"> ₹ </small>
											<strong class="">{{ $numFrm(summaryInfo.no_nfc_sale.amount, 'float', eventInfo.country) }}</strong>
										</h5>
									</span>
								</div>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.topup.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TOPUPS</span>
							</div>
							<div v-if="v" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', eventInfo.country) }}</strong>
								</h5>
							</div>
							<div v-if="!v" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">TOTAL </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country === 'india'"> ₹ </small>
									<small v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount" class="">{{ $numFrm(summaryInfo.topup.amount, 'float', eventInfo.country) }}</small>
									<strong v-else class="">{{ $numFrm(summaryInfo.topup.amount, 'float', eventInfo.country) }}</strong>
								</h5>
							</div>
							<div class="col-12 px-0" v-if="!v && (summaryInfo.topup.paidAmount !== summaryInfo.topup.amount)">
								<small class="d-inline">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', eventInfo.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.return.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">RETURNS</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.return.amount, 'float', eventInfo.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>i	</div>
</template>

<script>
	import headerChart from "../../dashboard/HeaderChart";
	import {headerChart as headerChart2} from "../../dashboard/HeaderChart2";
	// import doughnutChart1 from "./DoughnutChart";

	export default {
		name: "overview-header",
		inject: ['$numFrm2', '$numFrm', ],
		props: ['info', 'summaryInfo', 'user', 'periodType', 'eventInfo',],
		data() {
			return {
				headerChartID: "headerChart",
				headerChartID2: "headerChart2",
				v: this.$route.query.v,
			};
		},
		methods: {
			getPeriodName() {
				if(this.eventInfo.dayCloseLogs && this.eventInfo.dayCloseLogs.length > 0 && this.periodType.toLowerCase() === 'yesterday')
					return this.eventInfo.dayCloseLogs[this.eventInfo.dayCloseLogs.length - 1].name;
				return this.periodType.toUpperCase();
			},
		},
		watch:{
		},
	};
</script>

<style scoped></style>
