<template>

	<div class="row justify-content-center">
		<div class="col-12 m-auto d-md-none d-inline">
			<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
				<div class="card-body pr-0 pl-2">
					<div class=" h-50 ">
						<h5 class="text-white text-center">{{ getPeriodName() }}</h5>
						<canvas :id="headerChartID3"/>
					</div>
					<div class="" v-if="components.txnSummary2">
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<!--<h5 class="aml-border-radius-1 bg-white stats-title text-primary my-auto px-1 py-2 mx-auto aml-font-11 text-center">SALES</h5>-->
								<span class="badge bg-white aml-orange-2 border-0">SALES</span>
							</div>
							<div class="col-9 pr-0">
								<div v-if="summaryInfo.sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline" v-if="summaryInfo.no_nfc_sale.amount > 0">NFC </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
											<!--<animated-number :value="1853"></animated-number>-->
											<strong class="">{{ $numFrm(summaryInfo.sale.amount, 'float', event.country)  }}</strong>
										</h5>
									</span>
									<!--<small class="col-2 text-right px-0 my-auto ml-2">(19,234)</small>-->
								</div>
								<div v-if="summaryInfo.no_nfc_sale.amount > 0">
									<span class="col-9 px-0">
										<small class="d-inline">POS </small>
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
											<strong class="">{{ $numFrm(summaryInfo.no_nfc_sale.amount, 'float', event.country) }}</strong>
										</h5>
									</span>
								</div>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.topup.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TOPUPS</span>
							</div>
							<div v-if="v" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div v-if="!v" class="col-9 px-0">
								<small class="d-inline" v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount">TOTAL </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<small v-if="summaryInfo.topup.paidAmount !== summaryInfo.topup.amount" class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</small>
									<strong v-else class="">{{ $numFrm(summaryInfo.topup.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
							<div class="col-12 px-0" v-if="!v && (summaryInfo.topup.paidAmount !== summaryInfo.topup.amount)">
								<small class="d-inline">PAID </small>
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.topup.paidAmount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0" v-if="summaryInfo.return.amount > 0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">RETURNS</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class="" v-if="event && event.country.toLowerCase() === 'india'"> ₹ </small>
									<strong class="">{{ $numFrm(summaryInfo.return.amount, 'float', event.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>

					<div class="" v-if="components.attendanceSummary2">
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TODAY PRESENT</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<strong class="">{{ $numFrm(collegeAccessInfo.present, 'int', event.country) }}</strong>
								</h5>
							</div>
						</div>
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">TODAY ABSENT</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<strong class="">{{ $numFrm(collegeAccessInfo.absent, 'int', event.country) }}</strong>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row " style="min-height: 325px" v-if="components.txnData">

		<!--ACTIVATION DATA-->
		<div class="col-12 col-md-4" v-if="components.activationData">
			<div class="card card-stats card-raised">
				<div class="card-header">
					<h5 class="card-category">ACTIVATIONS</h5>
					<h4 class="card-title m-0 d-none">
						<small></small>
						<span id="activation_total_amount p-0">{{ cardsInfo.activation.amount }}</span>
					</h4>
					<div v-if="isLoading.cards" class="px-0" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body py-0">
					<div class="table-responsive p-0">
						<table class="table aml-font-11 m-0">
							<thead class="text-primary">
							<th>MODE</th>
							<th class="text-right">AMOUNT</th>
							<th class="text-right">COUNT</th>
							</thead>
							<tbody>
							<tr>
								<td>UNIQUE CARDS</td>
								<td class="text-right">-</td>
								<!--<td class="text-right" id="activation_card_count"><div class="ph-item m-0 p-0"><div class="ph-col-12 p-0"><div class="ph-row m-0"><div class="ph-col-12 m-0 big"></div></div></div></div></td>-->
								<td class="text-right">{{ cardsInfo.unique.count }}</td>
							</tr>
							<tr>
								<td>ACTIVATIONS</td>
								<td class="text-right">{{ cardsInfo.activation.amount }}</td>
								<td class="text-right">{{ cardsInfo.activation.count }}</td>
							</tr>
							<tr>
								<td>PERSO COUNT</td>
								<td class="text-right">{{ cardsInfo.perso.amount }}</td>
								<td class="text-right">{{ cardsInfo.perso.count }}</td>
							</tr>
							<tr>
								<td>AVG TOPUP</td>
								<td class="text-right">{{ cardsInfo.avgTopup.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgTopup.count }}</td>
							</tr>
							<tr>
								<td>AVG SALE</td>
								<td class="text-right">{{ cardsInfo.avgSale.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgSale.count }}</td>
							</tr>
							<tr>
								<td>AVG RETURN</td>
								<td class="text-right">{{ cardsInfo.avgReturn.amount }}</td>
								<td class="text-right">{{ cardsInfo.avgReturn.count }}</td>
							</tr>
							<tr>
								<td>DEVICES</td>
								<td class="text-right"></td>
								<td class="text-right">{{ cardsInfo.devices.count }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--TXN MODE DATA-->
		<div class="col-12 col-md-4 d-flex" v-if="components.modeWiseData">
			<div class="card card-stats">
				<div class="card-header">
					<h5 class="card-category">MODE WISE ANALYTICS</h5>
					<div v-if="isLoading.mode" class="px-0" id="mode_analytics_badge" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body py-0">
					<div class="table-responsive">

						<table class="table aml-font-11 aml-table mb-0">
							<thead class="text-primary">
							<th>DESCRIPTION</th>
							<th class="text-right">SALES</th>
							<th class="text-right" v-if="event.useNfc === 1">TOPUPS</th>
							</thead>
							<tbody>
							<tr class="" v-if="parseFloat(modeInfo.sale.nfc) > 0 || parseFloat(modeInfo.topup.nfc) > 0">
								<td>NFC</td>
								<td class="text-right">{{ modeInfo.sale.nfc }}<div v-if="modeInfoAmounts.sale.nfc > 0"><small>{{ modeInfoCounts.sale.nfc }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.nfc }} <div v-if="modeInfoAmounts.topup.nfc > 0"><small>{{ modeInfoCounts.topup.nfc }}</small></div></td>
							</tr>
<!--							<tr class="border-0" v-if="parseFloat(modeInfo.sale.nfc) > 0 || parseFloat(modeInfo.topup.nfc) > 0">-->
<!--								<td class="text-right">1</td>-->
<!--								<td class="text-right">2</td>-->
<!--							</tr>-->
							<tr class="" v-if="parseFloat(modeInfo.sale.cash) > 0 || parseFloat(modeInfo.topup.cash) > 0">
								<td>CASH</td>
								<td class="text-right">{{ modeInfo.sale.cash }} <div v-if="modeInfoAmounts.sale.cash > 0"><small>{{ modeInfoCounts.sale.cash }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.cash }} <div v-if="modeInfoAmounts.topup.cash > 0"><small>{{ modeInfoCounts.topup.cash }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.card) > 0 || parseFloat(modeInfo.topup.card) > 0">
								<td>CARD</td>
								<td class="text-right">{{ modeInfo.sale.card }} <div v-if="modeInfoAmounts.sale.card > 0"><small>{{ modeInfoCounts.sale.card }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.card }} <div v-if="modeInfoAmounts.topup.card > 0"><small>{{ modeInfoCounts.topup.card }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.upi) > 0 || parseFloat(modeInfo.topup.upi) > 0">
								<td>UPI</td>
								<td class="text-right">{{ modeInfo.sale.upi }} <div v-if="modeInfoAmounts.sale.upi > 0"><small>{{ modeInfoCounts.sale.upi }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.upi }} <div v-if="modeInfoAmounts.topup.upi > 0"><small>{{ modeInfoCounts.topup.upi }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.coupon) > 0 || parseFloat(modeInfo.topup.coupon) > 0">
								<td>COUPON</td>
								<td class="text-right">{{ modeInfo.sale.coupon }} <div v-if="modeInfoAmounts.sale.coupon > 0"><small>{{ modeInfoCounts.sale.coupon }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.coupon }} <div v-if="modeInfoAmounts.topup.coupon > 0"><small>{{ modeInfoCounts.topup.coupon }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.sale.comp) > 0 || parseFloat(modeInfo.topup.comp) > 0">
								<td>COMPLIMENTARY</td>
								<td class="text-right">{{ modeInfo.sale.comp }} <div v-if="modeInfoAmounts.sale.comp > 0"><small>{{ modeInfoCounts.sale.comp }}</small></div></td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.comp }} <div v-if="modeInfoAmounts.topup.comp > 0"><small>{{ modeInfoCounts.topup.comp }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.topup.update) > 0">
								<td>ONLINE UPDATE</td>
								<td class="text-right">-</td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.update }} <div v-if="modeInfoAmounts.topup.update > 0"><small>{{ modeInfoCounts.topup.update }}</small></div></td>
							</tr>
							<tr class="" v-if="parseFloat(modeInfo.topup.tapx) > 0">
								<td>ONLINE UPDATE</td>
								<td class="text-right">-</td>
								<td class="text-right" v-if="event.useNfc === 1">{{ modeInfo.topup.tapx }} <div v-if="modeInfoAmounts.topup.tapx > 0"><small>{{ modeInfoCounts.topup.tapx }}</small></div></td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<!--MODE DATA CHART-->
		<div class="col-12 col-md-4" v-if="components.modeWiseData">
			<div class="position-md-absolute position-relative fixed-bottom">
				<div class="card-body py-0 d-block" style="height: 250px">
					<canvas :id="headerChartID4" class="mx-auto"></canvas>
				</div>
			</div>
		</div>

	</div>

	<div class="row justify-content-center d-print-none mt-4" v-if="components.txnData">
		<!--last 5 TXN-->
		<div class="col-12">
			<div class="card card-chart">
				<div class="card-header">
					<h5 class="card-category">LATEST TRANSACTIONS</h5>
					<div v-if="isLoading.last5" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
				</div>
				<div class="card-body aml-height-275">
					<div class="table-responsive">
						<table class="table aml-font-11 ">
							<thead class="text-primary">
								<th class="text-center">TYPE</th>
								<th class="text-left">STALL</th>
								<th class="text-center">DEVICE</th>
								<th class="text-right">AMOUNT</th>
								<th class="text-center" v-if="event.useNfc === 1">CARD</th>
								<th class="text-center">MODE</th>
								<th class="text-center">TIMESTAMP</th>
							</thead>
							<tbody>
								<tr v-for="txn in last5" :key="txn.id">
									<td class="text-center">{{ txn.type.toUpperCase() }}</td>
									<td class="text-left">{{ txn.name }}</td>
									<td class="text-center">{{ txn.device_printId }}</td>
									<td class="text-right">{{ $numFrm(txn.amount, 'float', event.country) }}</td>
									<td class="text-center" v-if="event.useNfc === 1">{{ parseInt(txn.card_id) === 0 ? '' : txn.card_id }}</td>
									<td class="text-center">{{ txn.invoice }}</td>
									<td class="text-center">{{ txn.txn_at.replace('T', ' ').replace('.000Z', '') }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center mt-4" v-if="components.popularCharts">

		<!--POPULAR 5 FOODS -->
		<div class="col-10 col-md-4">
			<div class="">
				<div class="text-center my-4"><h6>Popular Foods</h6></div>
				<div class="card-body py-0 d-block" style="height: 250px">
					<canvas :id="foodChartID" class="mx-auto"/>
				</div>
			</div>
		</div>

		<!--POPULAR 5 DRINKS -->
		<div class="col-10 mx-auto col-md-4">
			<div class="">
				<div class="text-center my-4"><h6>Popular Drinks</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="drinkChartID" class="mx-auto"/>
				</div>
			</div>
		</div>
		<!--POPULAR 5 OVERALL -->
		<div class="col-10 col-md-4 mx-auto">
			<div class="">
				<div class="text-center my-4"><h6>Popular Overall Items</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="overallChartID" class="mx-auto"/>
				</div>
			</div>
		</div>

	</div>

	<div class="row justify-content-center " v-if="components.popularCharts">
		<!--POPULAR 5 STALLS -->
		<div class="col-12">
			<div class="">
				<div class="text-center mb-4"><h6>Popular Stalls</h6></div>
				<div class="card-body py-0 d-block" >
					<canvas :id="stallChartID" class="mx-auto"/>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
		AsyncWorldMap,
	} from "@/components";
	import {headerChart as headerChart3} from "../../dashboard/HeaderChart2";
	import {headerChart as headerChart4} from "../../dashboard/HeaderChart3";
	import headerChart from "../../dashboard/HeaderChart";
	import dataChart from "../../dashboard/dataChart";
	import axios from "axios";

	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	import { ElMessage } from "element-plus";

	// import SkeletonLoaderVue from 'skeleton-loader-vue';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			AsyncWorldMap,
			// SkeletonLoaderVue,
			// VueLoaders, VueLoadersBallBeat
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType', '$armourxURL'],
		emits: ['info-fetched', 'event-fetched', 'summary-fetched', 'loading-complete', 'newDayClosedTurnOFF', 'hide-sidebar'],
		props: ['user', 'periodType', 'newDayClosed'],
		data() {
			return {
				v1: null,
				options: [ { value: 'Vue.js', label: 'JavaScript' }, ],

				loginUser: {},

				value: null,

				interval_fn: -1,

				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					vendor: { },
					summary: {},
					summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
					cards: {},
					cardsInfo: {
						unique: { amount: '-', count: '-' },
						activation: { amount: '-', count: '-' },
						perso: { amount: '-', count: '-' },
						avgTopup: { amount: '-', count: '-' },
						avgSale: { amount: '-', count: '-' },
						avgReturn: { amount: '-', count: '-' },
						devices: { amount: '-', count: '-' },
					},
					modeInfo: {
						topup: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
						},
						sale: {
							cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
						}
					},
					modeInfoAmounts: {
						topup: {
							cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
						},
						sale: {
							cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
						}
					},
					dashSettings: {
						add_activation_topup: 1,
						add_comp_topup: 1,
						add_coupon_topup: 1,
						auto_day_close: 0,
					},
					md: { v: [], d: [] },
					last5: [],
					makerChecker: [],
					txnGraph: [],
					popularFoods: { v: [], d: [] },
					popularDrinks: { v: [], d: [] },
					popularOverall: { v: [], d: [] },
					popularStalls: { v: [], d: [] },

					dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
					weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

					eventLoad: false,
					isLoading: {
						summary: true, cards: true, last5: true, mode: true,
						accessGate: true, accessCatg: true,
						makerChecker: true,
					},

					components: {
						txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
						modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,
					},
				},

				event: { name: '', country: 'india' },
				vendor: { },
				v: this.$route.query.v,
				attendancePersons: {},
				attendancePersonsInfo: {},
				summary: {},
				dashSettings: {
					add_activation_topup: 1,
					add_comp_topup: 1,
					add_coupon_topup: 1,
					auto_day_close: 0,
				},
				summaryInfo: { topup: { amount: 0, paidAmount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 }, pass_topup: { amount: 0, count: 0 } },
				cards: {},
				cardsInfo: {
					unique: { amount: '-', count: '-' },
					activation: { amount: '-', count: '-' },
					perso: { amount: '-', count: '-' },
					avgTopup: { amount: '-', count: '-' },
					avgSale: { amount: '-', count: '-' },
					avgReturn: { amount: '-', count: '-' },
					devices: { amount: '-', count: '-' },
				},
				modeInfo: {
					topup: {
						cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '', update: '', tapx: '',
					},
					sale: {
						cash: '', card: '', upi: '', coupon: '', comp: '', nfc: '',
					}
				},
				modeInfoAmounts: {
					topup: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					}
				},
				modeInfoCounts: {
					topup: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0, update: 0, tapx: 0,
					},
					sale: {
						cash: 0, card: 0, upi: 0, coupon: 0, comp: 0, nfc: 0,
					}
				},
				md: { v: [], d: [] },
				last5: [],
				makerChecker: [],
				txnGraph: [],
				popularFoods: { v: [], d: [] },
				popularDrinks: { v: [], d: [] },
				popularOverall: { v: [], d: [] },
				popularStalls: { v: [], d: [] },

				components: {
					txnGraph: false, txnData: false, txnSummary1: false, txnSummary2: false, activationData: false, modeWiseData: false,
					modeWiseGraph:false, lastTxns: false, accessCatgwise: false, accessGatewise: false, popularCharts: false,
				},

				dateArr: [ 1, 2, 3, 4, 5, 6, 7, ],
				weekArr: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],

				eventLoad: false,
				isLoading: { summary: true, cards: true, last5: true, mode: true, accessGate: true, accessCatg: true, makerChecker: true, attendancePersonsInfo: true, attdWeeklyGraph: true, },

				attendanceChartID: "attendanceChart",
				headerChartID: "headerChart",
				headerChartID2: "headerChart2",
				headerChartID3: "headerChart3",
				headerChartID4: "headerChart4",
				foodChartID: "foodChart",
				drinkChartID: "drinkChart",
				overallChartID: "overallChart",
				stallChartID: "stallChart",

				txnChart: headerChart,
				modeChart: headerChart4,
				summaryChart: headerChart3,
				dataChart: dataChart,

				progress: 0,
				activeUsersChartID: "activeUsersChart",
				emailsCampaignChartID: "emailsCampaignChart",
				activeCountriesChartID: "activeCountriesChart",

				lastTxnId: 0,
				secondLastTxnId: 0,
			};
		},
		methods: {
			setComponentsDisplay () {
				this.setDefault('components');

				switch(this.event.type) {
					case 'event':
						this.components.txnGraph = this.event.useNfc === 1;
						this.components.activationData = this.event.useNfc === 1;
						this.components.txnSummary1 = true;
						this.components.txnSummary2 = true;
						this.components.modeWiseData = true;
						this.components.txnData = true;
						this.components.accessCatgwise = this.event.useAccessx === 1;
						this.components.accessGatewise = this.event.useAccessx === 1;
						this.components.popularCharts = true;
						break;
				}
			},
			setLoading() {
				for (const [key, value] of Object.entries(this.isLoading)) {
					this.isLoading[key] = true;
				}
			},
			getPeriodName() {
				if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0 && this.periodType.toLowerCase() === 'yesterday')
					return this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].name;
				return this.periodType.toUpperCase();
			},
			dataLoad() {
				if(this.$isCheckEventType('fnb', this.event.type)) {
					this.loadData('cards');
					// this.loadData('summary');
					this.loadData('mode');
					this.loadData('last5');
					this.loadData('txnGraph');
					this.loadData('popularFoods');
					this.loadData('popularDrinks');
					this.loadData('popularOverall');
					this.loadData('popularStalls');
					if (this.event.useMakerChecker === 1) this.loadData('makerChecker');
				}
			},
			setEventData(data) {
				this.event = data;
				// this.$event = this.event;
				this.eventLoad = true;
				// debugger;
				switch (this.event.type) {
					case 'event':
					case 'fnb':
						// this.loadData('dashSettings');
						this.updateLocal('dashSettings', {data: [this.dashSettings] });
						let t = [0];
						// if(this.event.days) {
						// 	t = this.event.days.split(',');
						// 	if(t.length > 0) this.lastTxnId = parseInt(t[t.length - 1]) ?? 0;
						// 	else this.lastTxnId = 0;
						// } else this.lastTxnId = 0;

						if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
							this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId;
							if(this.event.dayCloseLogs.length > 1)	this.secondLastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 2].txnId;
							else this.secondLastTxnId = 0;
						} else this.lastTxnId = 0;

						// console.log(this.event.dayCloseLogs);

						// this.loadData('cards');
						// this.loadData('summary');
						this.$emit('loading-complete');
						this.loadData('summary');
						break;
					default:
						break;
				}
				// if (this.$isCheckEventType('fnb', this.event.type)) this.loadData('summary');
				// if (this.$isCheckEventType('clg-access', this.event.type)) {
				// 	this.dataLoad();
				// 	// this.setColgFilterDefaults();
				// }
			},
			loadDetails() {
				let code = 0;
				let url = '/Vendors/Details/';
				// let u = JSON.parse(localStorage.getItem('user'));
				if(this.v)	code = this.v;
				else {
					switch (this.loginUser.loginType) {
						case 'vendor':
							code = this.loginUser.id;
							break;
						case 'event':
						case 'admin':
							code = this.$route.params.code;
							break;
						default:
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>Please try again.",
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
							return;
							break;
					}
				}
				// url = '/' + (u.product === 'armourx' ? 'Users' : 'Event') +'/Details/';
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						// this.setDefault('event');
						this.vendor = response.data.vendor;
						// this.event = response.data.event;
						// this.updateLocal('dashSettings', response.data.dashSettings);
						// this.vendor.id = this.vendor.code;
						this.$emit('info-fetched', this.vendor);
						this.$emit('event-fetched', response.data.event);
						if(!this.v) {
							switch (this.loginUser.loginType) {
								case 'event':
								case 'vendor':
								case 'admin':
									this.setEventData(response.data.event);
									break;
								case 'operator':
									// url = 'Users/Details/';
									break;
								default:
									ElMessage({
										showClose: true,
										dangerouslyUseHTMLString: true,
										message: "<b>Something went wrong</b><br>Please try again.",
										type: 'danger',
										grouping: true,
										duration: 5000,
									});
									return;
									break;
							}
						} else {
							this.setEventData(response.data.event);
						}
						this.setComponentsDisplay();
					} else {
						//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}

				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
			},
			updateLocal(dataType, d) {
				switch (dataType) {
					case 'dashSettings':
						this.setDefault('dashSettings');
						if(d.data.length > 0) this.dashSettings = d.data[0];

						break;
					case 'summary':
						this[dataType] = d.data;
						this.setDefault('summaryInfo');

						d.data.forEach(info => {
							if(info.type_ !== 'activated_card_amount')
								this.summaryInfo[info.type_] = { amount: info.amount, count: info.count  };
							else this.cards.activated_card_amount = info.amount;
						});

						this.summaryInfo.topup.paidAmount = this.summaryInfo.topup.amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_activation_topup ? 0 : this.cards.activated_card_amount;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_comp_topup ? 0 : this.modeInfoAmounts.topup.comp;
						this.summaryInfo.topup.paidAmount -= this.dashSettings.add_coupon_topup ? 0 : this.modeInfoAmounts.topup.coupon;
						this.dataLoad();
						break;
					case 'cards':
						this[dataType] = d.data[0];

						this.cardsInfo = {
							unique: { amount: '-', count: this.$numFrm(this.cards.card_count, 'int', this.event.country) },
							activation: { amount: this.$numFrm(this.cards.activated_card_amount, 'float', this.event.country), count: this.$numFrm(this.cards.activated_card_count, 'int', this.event.country) },
							perso: { amount: '-', count: this.$numFrm(this.cards.perso_count, 'int', this.event.country) },
							avgTopup: { amount: this.summaryInfo.topup.amount ? this.$numFrm(this.summaryInfo.topup.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.topup.count ? this.$numFrm(this.summaryInfo.topup.count / this.cards.card_count, 'float', this.event.country) : '-' },
							avgSale: { amount: this.summaryInfo.sale.amount ? this.$numFrm(this.summaryInfo.sale.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.sale.count ? this.$numFrm(this.summaryInfo.sale.count / this.cards.card_count, 'float', this.event.country) : '-' },
							avgReturn: { amount: this.summaryInfo.return.amount ? this.$numFrm(this.summaryInfo.return.amount / this.cards.card_count, 'float', this.event.country) : '-', count:  this.summaryInfo.return.count ? this.$numFrm(this.summaryInfo.return.count / this.cards.card_count, 'float', this.event.country) : '-' },
							devices: { amount: 0, count: this.$numFrm(this.cards.device_count, 'int', this.event.country) },
						};
						// this.loadData('summary');
						// this.loadData('mode');
						break;
					case 'mode':
						this.setDefault('modeInfo'); this.setDefault('modeInfoAmounts'); this.setDefault('md');

						d.data.forEach(info => {
							try{
								// this.modeInfo[info.type][info.mode.toLowerCase()] = this.$numFrm(info.amount, 'float', this.event.country);
								// this.md.v.push(info.type + "|" + info.mode.toLowerCase());
								// this.md.d.push(info.amount);
								this.modeInfo[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = this.$numFrm(info.amount, 'float', this.event.country);
								this.modeInfoAmounts[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = info.amount;
								this.modeInfoCounts[info.type][info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase()] = this.$numFrm(info.count, 'int', this.event.country);
								this.md.v.push(info.type + "|" + info[info.type === 'topup' ? 'invoice' : 'mode'].toLowerCase());
								this.md.d.push(info.amount);
							}
							catch (e) { console.log('mode_info error', e); }
						});
						if(this.summaryInfo.topup.amount > 0) {
							this.modeInfo.topup.nfc = this.$numFrm(this.summaryInfo.topup.amount, 'float', this.event.country);
							this.modeInfoAmounts.topup.nfc = this.summaryInfo.topup.amount;
							this.modeInfoCounts.topup.nfc = this.$numFrm(this.summaryInfo.topup.count, 'int', this.event.country);
						}
						// this.dataLoad();
						break;
					case 'last5':
					case 'accessCatg':
					case 'accessGate':
					case 'makerChecker':
						this[dataType] = d.data;
						break;
					case 'txnGraph':
					case 'accessGraph':
						this[dataType] = [];
						for (const [key, value] of Object.entries(d.data[0])) {
							this[dataType].push(value);
						}
						// console.log('aaaaaaa', this[dataType]);
						this.txnChart.createChart(this.headerChartID, this[dataType]);
						break;
					case 'popularFoods':
					case 'popularDrinks':
					case 'popularOverall':
						this.setDefault(dataType);

						d.data.forEach(info => {
							try{
								this[dataType].v.push(info.item_name.toLowerCase().substring(0,8));
								this[dataType].d.push(info.item_qty);
							}
							catch (e) { console.log('popular food error', e); }
						});
						break;
					case 'popularStalls':
						this.setDefault(dataType);

						d.data.forEach(info => {
							try{
								this.popularStalls.v.push(info.stall_name.toLowerCase().substring(0,10));
								this.popularStalls.d.push(info.txn_amount);
							}
							catch (e) { console.log('popular Stalls error', e); }
						});
						break;
				}
				this.isLoading[dataType] = false;
			},
			loadData(dataType, product = 'event') {
				this.isLoading[dataType] = true;
				let baseURL = '', id = 0;
				id = this.loginUser.id;

				if(this.v)	id = this.v;
				else {
					switch (this.loginUser.loginType) {
						case 'vendor':
							id = this.loginUser.id;
							break;
						case 'event':
						case 'admin':
							id = this.$route.params.code;
							break;
						default:
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>Please try again.",
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
							return;
							break;
					}
				}

				axios.post(
					this.$baseURL + '/Vendors/loadDashboardData/',
					{
						id, dataType, eventType: this.event.type, eventId: this.event.id,
						dashSettings: this.dashSettings, event: this.event,
						secondLastTxnId: this.secondLastTxnId, lastTxnId: this.lastTxnId, periodType: this.periodType,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) { this.updateLocal(dataType, response.data); }
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading[dataType] = false;
				});
			},
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}

				// if( this.$isCheckEventType('clg-access', this.event.type) && this.event.useAccessx === 0 ) this.setColgFilterDefaults();
			},
		},
		mounted() {
			console.log('bc');
			// debugger;
			this.v = this.$route.query.v;
			if(!this.v) {
				// setTimeout(this.loadDetails, 500);
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				// if(_.isEmpty(u))	return this.$router.push('/login');
				if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
			} else { this.v = atob(this.v); }

			this.loadDetails();
			// this.interval_fn = setInterval(this.loadDetails, 60000);

			// this.setColgFilterDefaults();

			// setTimeout(this.loadData, 1000, 'summary');
			// this.loadData('summary');

			// setTimeout(this.loadData, 2000, 'cards');
			// this.loadData('cards');

			// setTimeout(this.loadData, 3000, 'mode');
			// this.loadData('cards');

			// setTimeout(this.loadData, 4000, 'last5');
			// this.loadData('cards');
		},
		watch:{
			$route (to, from){
				console.log('route change detected');
				// this.setLoading();
				this.setDefault();
				clearInterval(this.interval_fn);
				this.loadDetails();
				this.interval_fn = setInterval(this.loadDetails, 60000);
				// this.event = {};
			},
			newDayClosed (to, from) {
				if(to)	{
					this.setDefault();
					this.loadDetails();
					this.$emit('newDayClosedTurnOFF');
				}
			},
			// $periodType (to, from){
			// 	console.log('$periodType change detected');
			// 	this.loadDetails();
			// },
			periodType: {
				handler(a) {
					console.log('period change detected');
					this.loadDetails();
					// switch (a) {
					// 	case 'yesterday':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'current':
					// 		if(this.event.dayCloseLogs && this.event.dayCloseLogs.length > 0) {
					// 			this.lastTxnId = this.event.dayCloseLogs[this.event.dayCloseLogs.length - 1].txnId
					// 		} else	this.lastTxnId = 0;
					// 		break;
					// 	case 'all':
					// 		this.lastTxnId = 0;
					// 	default:
					// 		break;
					// }
				}
			},
			summaryInfo: {
				handler(a) {
					// console.log("summaryInfo update detect", a);
					this.$emit('summary-fetched', a);

					if(!a)  return;
					let summaryChartData = [
						+this.summaryInfo.sale.amount + +this.summaryInfo.no_nfc_sale.amount,
						this.summaryInfo.topup.amount,
						this.summaryInfo.return.amount,
					];
					this.summaryChart.createChart(this.headerChartID3, [ 'SALE', 'TOPUP', 'RETURN' ], summaryChartData);
					this.summaryChart.createChart(this.headerChartID2, [ 'SALE', 'TOPUP', 'RETURN' ], summaryChartData);
				},
				deep: true,
			},
			event: {
				handler(a) {
					// console.log("event update detect", a);
					// this.$emit('event-fetched', a);
					// if(a) this.setComponentsDisplay();
				},
				deep: true,
			},
			modeInfo: {
				handler(a) {
					// this.modeChart.createChart(this.headerChartID4, this.md.v, this.md.d);
				},
				deep: true,
			},
			// txnGraph(to, from) {
			// 	this.txnChart.createChart(this.headerChartID, to);
			// },
			txnGraph: {
				handler(a) {
					// this.txnChart.createChart(this.headerChartID, a);
				},
				deep: true,
			},
			popularFoods: {
				handler(a) {
					this.dataChart.createChart(this.foodChartID, a.d, a.v);
				},
				deep: true,
			},
			popularDrinks: {
				handler(a) {
					this.dataChart.createChart(this.drinkChartID, a.d, a.v, '#ff6b59', '#FF7815');
				},
				deep: true,
			},
			popularOverall: {
				handler(a) {
					this.dataChart.createChart(this.overallChartID, a.d, a.v);
				},
				deep: true,
			},
			popularStalls: {
				handler(a) {
					this.dataChart.createChart(this.stallChartID, a.d, a.v, '#36b9ac', '#1398af');
				},
				deep: true,
			},

			// popularFoods(to, from) {
			// 	this.dataChart.createChart(this.foodChartID, to.d, to.v);
			// },
			// popularDrinks(to, from) {
			// 	this.dataChart.createChart(this.drinkChartID, to.d, to.v, '#ff6b59', '#FF7815');
			// },
			// popularOverall(to, from) {
			// 	this.dataChart.createChart(this.overallChartID, to.d, to.v);
			// },
			// popularStalls(to, from) {
			// 	this.dataChart.createChart(this.stallChartID, to.d, to.v, '#36b9ac', '#1398af');
			// },
		}
	};
</script>
