<template>
	<div>
		<div class="row d-flex justify-content-center">
			<div class="col-md-10 mr-auto ml-auto">
				<simple-wizard>
					<template v-slot:header>
						<h3 class="card-title">CREATE</h3>
						<h3 class="description">
							<!--This information will let us know more about you.-->
						</h3>
					</template>

					<wizard-tab :before-change="() => validateStep('step1')" label="About" icon="now-ui-icons education_atom" >
						<first-step ref="step1" @on-validated="onStepValidated" @on-event-sunburn="onEventSunburn" :user="user" :event="event" :accmgrs="accmgrs" />
					</wizard-tab>

					<wizard-tab :before-change="() => validateStep('step2')" label="POS" icon="fas fa-mobile" >
						<second-step ref="step2" @on-validated="onStepValidated" :user="user" :event="event" />
					</wizard-tab>

					<wizard-tab :before-change="() => validateStep('step3')" label="Cashless " icon="icon-nfc-icon">
						<third-step ref="step3" @on-validated="wizardComplete" :user="user" :selectedCardSetting="selectedCardSetting" :events="events" :defaultEvents="defaultEvents" :event="event"/>
					</wizard-tab>
				</simple-wizard>
			</div>
		</div>
	</div>
</template>
<script>
	import FirstStep from "./create/FirstStep.vue";
	import SecondStep from "./create/SecondStep.vue";
	import ThirdStep from "./create/ThirdStep.vue";
	import Swal from "sweetalert2";
	import { SimpleWizard, WizardTab } from "@/components";
	import axios from "axios";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import { ElMessage } from "element-plus";

	export default {
		data() {
			return {
				wizardModel: {},
				event: {  },
				events: [],
				accmgrs: [],
				defaultEvents: [],
				selectedCardSetting: 0,
				defaultInfo: {
					defaultEvents: [],
					events: [],
					isLoading: { events: false, },
				},
				isLoading: { events: false, },
			};
		},
		props: [ 'user' ],
		emits: ['loading-complete', 'loading-update', 'setPageHeading'],
		inject: ['$baseURL', '$routerGoto', ],
		components: {
			FirstStep,
			SecondStep,
			ThirdStep,
			SimpleWizard,
			WizardTab,
			useToast, Notification,
		},
		methods: {
			validateStep(ref) {
				return this.$refs[ref].validate();
			},
			onStepValidated(validated, model) {
				let e = JSON.parse(JSON.stringify(this.event));
				let e_ = JSON.parse(JSON.stringify(validated.event));
				this.event = { ...e, ...e_ };
				// console.log( this.event );
				this.wizardModel = { ...this.wizardModel, ...model };
			},
			onEventSunburn(isEventSunburn) {
				this.defaultEvents = isEventSunburn ? [ { id: 44, name: "Sunburn Settings" }, { id: 0, name: "New Setting" } ] : JSON.parse(JSON.stringify(this.events));
				this.selectedCardSetting = this.defaultEvents[0].id;
			},
			wizardComplete(validated) {
				let e = JSON.parse(JSON.stringify(this.event));
				let e_ = JSON.parse(JSON.stringify(validated.event));
				this.event = { ...e, ...e_ };
				// console.log( this.event );

				this.$emit('loading-update');
				// return;

				axios.post(
					this.$baseURL + '/Event/Create/',
					this.event,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							// this.updateLocal(dataType, response.data);
							this.$routerGoto({ path: '/#/Config/' + response.data.event.id })
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>" + response.data.message + "</b><br>Please try again.",
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						// this.isLoading[dataType] = false;
					});

				// Swal.fire({
				// 	title: "Good job!",
				// 	text: "You clicked the finish button!",
				// 	type: "success",
				// 	confirmButtonClass: "btn btn-success",
				// 	buttonsStyling: false,
				// });
			},
			loadAccMgrs() {
				this.isLoading.events = true;
				axios.get(
					this.$baseURL + '/Event/AccMgrList/',
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.accmgrs = response.data.accmgrs;
						}
						this.isLoading.events = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.events = false;
					});
			},
			loadDetails() {
				this.isLoading.events = true;
				axios.get(
					this.$baseURL + '/Event/ViewAll/',
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.events = [{ id: 0, name: "New Setting" }].concat(response.data.events);
						} else { this.events = [{ id: 0, name: "New Setting" }]; }
						this.defaultEvents = JSON.parse(JSON.stringify(this.events));
						this.isLoading.events = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.events = false;
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			this.loadAccMgrs();
			this.loadDetails();
			// this.$emit('event-fetched', {});
		},
		watch:{
			'event.name' (to, from){
				this.$emit("setPageHeading", to);
				// if(to) this.event_.controller = to.controller;
				// if(this.user.controller === 'bms') this.event_.onlineTopup = false;
			},
		}
	};
</script>
