<template>
	<div class="row">
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category">{{ stall.name }} | All Items</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<span class="badge badge-primary" v-if="loading">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div class="d-block my-2 justify-content-center">
					<span class="col text-info cursor-pointer"
						  @click="$router.push({ name: loginUser.loginType === 'operator' ? 'StallMenuPage' : 'StallMenu', params: { ...$route.params, ...{ inventoryType: 'Menu'} } })">
						<i class="fa fa-chevron-left"/> MENU
					</span>
					<span class="col text-info cursor-pointer"
						  v-if="stall && stall.useGrn === 1" @click="$router.push({ name: loginUser.loginType === 'operator' ? 'InventoryPage' : 'Inventory', params: { ...$route.params, ...{ inventoryType: $route.params.inventoryType === 'GRN' ? 'RN' : 'GRN'} } })">
						<i class="fa" :class="$route.params.inventoryType === 'GRN' ? 'fa-minus-circle' : 'fa-plus-circle'"/> {{ $route.params.inventoryType === 'GRN' ? 'RN' : 'GRN' }}
					</span>
					<el-button class="col-3 aml-text-teal-3" type="default" @click="downloadItemData()">
						<i class="fas fa-download"/> LIST
					</el-button>
					<el-upload
						ref="upload"
						action=""
						class="d-inline col "
						:auto-upload="false"
						:on-change="csvChange"
						:data="csvData"
					>
						<el-button type="default" class="aml-bg-teal-3 text-white">
							<i class="fas fa-upload"/> GRN
						</el-button>
					</el-upload>
				</div>
				<div v-if="!loading">
					<el-input v-model="search" @change="searchInput" :clearable="true" placeholder="Search By Item, Category, HSN, Barcode"/>
					<el-table class="w-100 aml-font-12"
							  :data="allItems.filter(
							  	data => !search ||
							  	( data.name && data.name.toLowerCase().includes(search.toLowerCase()) ) ||
							  	( data.category && data.category.toLowerCase().includes(search.toLowerCase()) ) ||
							  	( data.hsn && data.hsn.toLowerCase().includes(search.toLowerCase()) ) ||
							  	( data.barcode && data.barcode.toString().toLowerCase().includes(search.toLowerCase()) )
							  	)"
							  height="500"
					>
<!--						<el-table-column label="#" type="index"/>-->
<!--						<el-table-column sortable label="CATEGORY" prop="category" width="100">-->
<!--							<template #default="scope">-->
<!--								<div><h6 class="d-inline"><small>{{ scope.row.category }}</small></h6></div>-->
<!--							</template>-->
<!--						</el-table-column>-->
						<el-table-column label="ITEM" prop="name">
							<template #default="scope">
								<div><h6 class="d-inline">{{ scope.row.name }}</h6></div>
								<div><h6 class="d-inline"><small>{{ scope.row.category }}</small></h6></div>
								<div>
									<h6 class="d-inline"><small>COST: {{ $numFrm(scope.row.costPrice, 'float', event.country) }}</small></h6>
									<el-divider direction="vertical"/>
									<h6 class="d-inline"><small>MRP: {{ $numFrm(scope.row.mrp, 'float', event.country) }}</small></h6>
								</div>
								<div>
									<!--									<el-divider direction="vertical"/>-->
									<h6 class="d-inline"><small>SP: {{ $numFrm(scope.row.price, 'float', event.country) }}</small></h6>
								</div>
								<div><h6 class="d-inline"><small>HSN: {{ scope.row.hsn }}</small></h6> <el-divider direction="vertical"/> <h6 class="d-inline"><i class="fas fa-barcode"/> <small>: {{ scope.row.barcode }}</small></h6></div>
							</template>
						</el-table-column>
						<el-table-column label="QUANTITY">
							<template #default="scope">
								<div class="row">
									<div class="d-inline col-8"><el-input type="number" :ref="'itemQty'+scope.row.id" placeholder="Quantity" v-model="scope.row.qty" :disabled="isLoading.inventory"/></div>
									<el-button circle type="info" icon="el-icon-right" @click="addItem(scope.row)" :loading="isLoading.inventory"/>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div v-else>
					<el-skeleton :rows="5" animated />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
		ElForm, ElFormItem, ElButton, ElDialog, ElDivider, ElUpload,
		ElTable, ElTableColumn,
	} from 'element-plus';
	import axios from "axios";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
			ElForm, ElFormItem, ElButton, ElDialog, ElDivider, useToast, Notification,
			ElUpload, ElTable, ElTableColumn,
		},
		inject: ['$baseURL', '$numFrm', '$removeData', ],
		props: [ 'event', 'stall', 'allItems', 'menu', 'items', 'loading', ],
		emit: [ 'Item-Searched', 'Add-Items' ],
		data() {
			return {
				originalMenu: [ ],
				inventory: [ ],
				downloadMenu: { event: null, eventName: null, stall: null, stalls: [] },
				dEvents: [],
				dStalls: [],
				search: '',
				defaultGST: ['0', '5', '18', '28'],
				defaultTypes: ['inclusive', 'exclusive'],
				defaultItemTypes: ['food', 'drink', 'ticket', 'other'],
				categorySelected: "1",
				downloadMenuDialog: false,
				menuDialog: false,
				isLoading: { menu: false, stalls: false, inventory: false, allItems: true, },
				menuUpdated: false, menuLoaded: false,
				multipleItems: {},
				loginUser: JSON.parse(localStorage.getItem('user')),
				csvData: null,
			}
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			async csvChange(file) {
				// let catg = this.menu.find((catg) =>
				// 	catg.id_.toString() === this.categorySelected
				// );
				this.$refs['upload'].clearFiles();
				let items_ = await file.raw.text();
				let items = items_.split('\n');
				let header = items.shift();
				if(header.split(',').length < 6)	return this.runToast('', 'top-right', 'warning', "<b>File Error</b><br>File Not Formatted Correctly", "fas fa-bomb fa-4x");;
				this.$emit('Add-Items', items);
			},
			downloadItemData(){
						let csv = 'CATEGORY,ITEM,ID,HSN,BARCODE,QUANTITY\n';
				this.allItems.forEach(item => {
					csv += [ item.category, item.name, item.id, item.hsn.toString(), item.barcode.toString(), 0, ].join(',') + '\n';
				});
				// return console.log(csv);
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = this.stall.name + ' Items' + '.csv';
				anchor.click();
			},
			searchInput() {
				// if(this.allItems.length > 0)
				// 	this.$refs['itemQty'+this.allItems[0].id.toString()].focus();
			},
			removeItem(items, item) {
				this.$removeData(items, item.id, 'id');
			},
			addItem(item) {
				this.isLoading.inventory = true;
				this.$emit('Item-Searched', item);
				this.$removeData(this.allItems, item.id, 'id');
				this.isLoading.inventory = false;
			},
			saveInventory() {
				this.isLoading.inventory = true;
				// return console.log("menu: ", this.menu);

				axios.post(
					this.$baseURL + '/Menu/' + this.$route.params.stallCode + '/Save',
					this.menu,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.isLoading.inventory = false;
						if(response.data.success) {
							this.alert = {show: true, type: 'success', icon: "ni ni-like-2", message: {strong: 'Success!', normal: "Menu Save Success!"}};
							this.$emit('save-complete');
						} else {
							this.alert = {show: true, type: 'danger', icon: "ni ni-like-2", message: {strong: 'Failed!', normal: "Menu Save Failed!"}};
						}
					})
					.catch( (error) => {
						console.error(error);
						this.isLoading.inventory = false;
						this.alert = { show: true, type: 'warning', icon: "ni ni-like-2", message: { strong: 'Error!', normal: "Something went wrong!" } };
					});
			},
		},
		mounted() {},
		watch: {
			event: {
				handler(a) {
					// console.log("event update detect", a);
					if(a) this.loadItems(this.$route.params.stallCode, this.event.id, true);
				},
				deep: true,
			},
			menu: {
				handler(a) {
					// let b = JSON.stringify({menu: this.originalMenu}) === JSON.stringify({ menu: a });
					// this.menuUpdated = !b;
					// console.log("menu update detect", b, a);
					// this.menuUpdated = this.menuLoaded;
					if(this.menuLoaded) this.menuLoaded = false;
					else this.menuUpdated = true;
				},
				deep: true,
			},
		}
	}
</script>
<style>
.aml-text-danger .el-input__inner {
	color: #ff6363 !important;
	border-color: #ff6363 !important;
	background-color: #f003 !important;
}
</style>
