<template>
	<div style="min-height: 2000px;">
		<div class="row justify-content-around" >
			<div class="col-12 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">{{ vendor.name }} | All Items</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.allItems">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body">
						<div class="d-block my-2 justify-content-center">
							<el-button class="col-3 aml-text-teal-3" type="default" @click="downloadItemData()">
								<i class="fas fa-download"/> LIST
							</el-button>
							<span class="col-3 text-right text-info cursor-pointer" v-if="!isLoading.allItems" @click="StallCloseDialog = true;">
							  <i class="fa fa-alert" /> Close Stall Items
							</span>
						</div>
						<div v-if="!isLoading.allItems">
<!--							<el-input v-model="search" :clearable="true" placeholder="Search By Item, Category, Stock Room, Bar"/>-->
<!--							:data="stockList.filter(-->
<!--							data => !search ||-->
<!--							( data.item_name && data.item_name.toLowerCase().includes(search.toLowerCase()) ) ||-->
<!--							( data.item_barcode && data.item_barcode.toString().toLowerCase().includes(search.toLowerCase()) )-->
<!--							)"-->
							<el-table class="w-100 aml-font-12 aml-height-500"
								  :data="stockList"
								  :height="600"
								  :stripe="true" :border="true"
									  @expand-change="expandDetails"
							>
<!--								  @expand-change="expandDetails"-->

								<el-table-column type="expand">
									<template #default="scope" class="p-0">
										<span class="bg-light p-0 m-0 d-block">
											<span v-if="itemHistoryData.length > 0" class="row col-11 mx-auto">
												<el-table class="w-100 aml-font-12" :data="itemHistoryData">
													<el-table-column label="TIMESTAMP" sortable>
														<template #default="scope">{{ displaydtinsec(scope.row.txn_unixAt) }}</template>
													</el-table-column>
													<el-table-column label="ITEM" prop="stockroom_name"/>
													<el-table-column label="ITEM" prop="stall_name"/>
													<el-table-column label="ITEM" prop="item_name"/>
													<el-table-column label="TYPE" prop="note_type"/>
													<el-table-column label="QTY" prop="note_qty"/>
												</el-table>
											</span>
										</span>
									</template>
								</el-table-column>
								<el-table-column label="STALL" prop="stall_name" :filters="stalls" :filter-method="filterStallName">
									<template #default="scope">
										<div><h6 class="d-inline">{{ scope.row.stall_name }}</h6></div>
										<div><h6 class="d-inline"><small>{{ scope.row.stall_type }}</small></h6></div>
									</template>
								</el-table-column>
								<el-table-column label="ITEM" prop="name">
									<template #default="scope">
										<div><h6 class="d-inline">{{ scope.row.item_name }}</h6></div>
										<div><h6 class="d-inline"><small>{{ scope.row.category_name }}</small></h6></div>
										<div v-if="scope.row.item_barcode && scope.row.item_barcode !== '' && scope.row.item_barcode !== ' '"><h6 class="d-inline"><i class="fas fa-barcode"/> <small>: {{ scope.row.item_barcode }}</small></h6></div>
									</template>
								</el-table-column>
								<el-table-column label="QUANTITY">
									<template #default="scope">
										<div><h5>{{ parseFloat(scope.row.item_quantity).toFixed(3) }}</h5></div>
									</template>
								</el-table-column>
<!--								<el-table-column label="Details">-->
<!--									<template #default="scope">-->
<!--										<div><i class="fas fa-info" @click="expandDetails_(scope.row)"/></div>-->
<!--									</template>-->
<!--								</el-table-column>-->
							</el-table>
						</div>
						<div v-else>
							<el-skeleton :rows="5" animated />
						</div>
					</div>
				</div>
			</div>
		</div>

<!--		Deploy/Return/Close History-->
<!--		<div class="row">-->
<!--			<div class="col-12">-->
<!--				<div class="card card-chart">-->
<!--					<div class="card-header">-->
<!--						<h5 class="card-category">LATEST ACTIVITY</h5>-->
<!--						<div v-if="isLoading.allItems" style="top: 5px;right: 5px; position: absolute;">-->
<!--						<span class="badge badge-primary">-->
<!--							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>-->
<!--						</span>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="card-body">-->
<!--						<el-table class="w-100 aml-font-12 aml-height-500" :data="stockHistory.reverse()"-->
<!--								  row-key="txn_id"-->
<!--								  :stripe="true" :border="true"-->
<!--						>-->
<!--							<el-table-column type="expand">-->
<!--								<template #default="scope" class="p-0">-->
<!--									<span class="bg-light p-0 m-0 d-block">-->
<!--										<span v-if="scope.row.items" class="row col-11 mx-auto">-->
<!--											<el-table class="w-100 aml-font-12" :data="scope.row.items">-->
<!--												<el-table-column label="ITEM" prop="item_name"/>-->
<!--												<el-table-column label="QTY" prop="note_qty"/>-->
<!--											</el-table>-->
<!--										</span>-->
<!--									</span>-->
<!--								</template>-->
<!--							</el-table-column>-->
<!--							<el-table-column label="TIMESTAMP" sortable>-->
<!--								<template #default="scope">{{ displaydtinsec(scope.row.txn_unixAt) }}</template>-->
<!--							</el-table-column>-->
<!--							<el-table-column label="TYPE" prop="note_type" sortable/>-->
<!--							<el-table-column label="STOCKROOM" prop="stockroom" sortable/>-->
<!--							<el-table-column label="STALL" prop="stall" sortable/>-->
<!--						</el-table>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

		<span class="aml-dialog">
			<el-dialog title="Close and Reset Stall Inventory" v-model="StallCloseDialog">
				<el-form-item>
				  	<span class="row mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">STALLS</h6>
						<el-select class="col-8 select-aml-teal" v-model="stallCloseData" multiple placeholder="Select Stall">
					  		<el-option v-for="stall in stalls.filter(
									data => data.type !== 'inventory' && data.type !== 'stockmaster'
								)" :key="stall.id" :label="stall.text" :value="stall.id" />
						</el-select>
				  	</span>
				</el-form-item>
				<template #footer>
				  	<span class="dialog-footer">
						<el-button @click="StallCloseDialog = false" v-if="!isLoading.allItems">Cancel</el-button>
						<el-button class="aml-bg-teal-3 text-white" :loading="isLoading.allItems" @click="closeInventory" :disabled="stalls.length === 0">CLOSE</el-button>
				  	</span>
				</template>
		  	</el-dialog>
		</span>

		<span class="aml-dialog">
			<el-dialog title="Item History" v-model="itemHistoryDialog">
<!--				<el-table class="w-100 aml-font-12" :data="itemHistoryData">-->
<!--					<el-table-column label="STOCKROOM" prop="stockroom_name" sortable/>-->
<!--					<el-table-column label="STALL" prop="stall_name" sortable/>-->
<!--					<el-table-column label="TYPE" prop="note_type" sortable/>-->
<!--					<el-table-column label="ITEM" prop="item_name"/>-->
<!--					<el-table-column label="QTY" prop="note_qty"/>-->
<!--					<el-table-column label="TIMESTAMP" prop="txn_unixAt" sortable>-->
<!--						<template #default="scope">{{ displaydtinsec(scope.row.txn_unixAt) }}</template>-->
<!--					</el-table-column>-->
<!--				</el-table>-->

				<div class="mt-3 row mx-auto">
					<div class="col-12 table-responsive">
						<table class="table table-bordered col-10 mx-auto">
							<thead class="text-primary">
								<th>STOCKROOM</th>
								<th>STALL</th>
								<th>TYPE</th>
								<th>ITEM</th>
								<th>QTY</th>
								<th>TIMESTAMP</th>
							</thead>
							<tbody>
								<tr v-for="item in itemHistoryData">
									<td>{{ item.stockroom_name }}</td>
									<td>{{ item.stall_name }}</td>
									<td>{{ item.note_type }}</td>
									<td>{{ item.item_name }}</td>
									<td>{{ item.note_qty }}</td>
									<td>{{ displaydtinsec(scope.row.txn_unixAt) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
		  	</el-dialog>
		</span>

	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import moment from 'moment';
	import SearchItems from './SeachItems';
	import {
		ElDivider,
		ElBadge,
		ElButton,
		ElUpload,
		ElTable,
		ElSkeleton,
		ElTableColumn,
		ElDialog, ElFormItem, ElSelect, ElOption,
	} from "element-plus";

	export default {
		components: {
			ElSelect, ElOption,
			ElFormItem,
			ElDialog,
			ElSkeleton,
			ElTable,
			ElUpload,
			ElButton,
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			SearchItems,
			ElTableColumn,
			moment,
		},
		inject: ['$baseURL', '$numFrm', ],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: {  },
					stall: {},
					components: { items: false,  },
					isLoading: { items: true, allItems: true, notes: true, },
					menu: {}, allItems: [], items: {}, notes: [],
				},
				search: "",
				loginUser: {},
				categorySelected: "1",
				event: { name: '', country: 'india', type: '' },
				vendor: {},
				menu: {}, allItems: [], ogItems: [], items: {}, notes: [],
				stalls: [],
				stallCloseData: [],
				stockList: [],
				stockItemList: [],
				stockHistory: [],
				stockListHistory: [],
				stockItemsHistory: {},
				StallCloseDialog: false,
				itemHistoryDialog: false,
				itemHistoryData: [],
				components: { items: false,  },
				isLoading: { items: true, allItems: true, notes: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				switch(this.event.type) {
					case 'fnb':
					case 'event':
						this.components.items = true;
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			displaydt(dt) { return dt ? moment(dt).local().format('LLL') : 'NA'; },
			displaydtinsec(dt) { return dt ? moment.unix(dt).local().format('LLL') : 'NA'; },
			expandDetails(row) {
				// console.log(row);
				this.itemHistoryData = row.stall_type === 'sale' ? this.stockItemsHistory[row.item_name].stallHistory[row.stall_name].history : this.stockItemsHistory[row.item_name].srHistory[row.stall_name].history;
				// console.log(this.itemHistoryData);
				// this.itemHistoryDialog = true;
			},
			loadData(dataType) {
				this.isLoading[dataType] = true;
				axios.get(
					this.$baseURL + '/Vendors/ViewAll',
					{
						id: this.$route.params.code, dataType, eventType: this.event.type,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) { this.updateLocal(dataType, response.data); }
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});

			},
			loadDetails() {
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
					case 'vendor':
						url = '/Vendors/Details/';
						code = u.id;
						break;
					case 'admin':
						url = '/Vendors/Details/';
						code = this.$route.params.vendorCode;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Vendors/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						if(response.data.success) {
							this.setDefault('event');
							// console.log(this.event);
							this.event = response.data.event;
							this.vendor = response.data.vendor;
							// console.log(this.event);
							this.isLoading.event = false;
							// this.dataLoad();
							this.$emit('event-fetched', this.event);
							this.setComponentsDisplay();
							// this.loadItems(code);
							this.loadStockList();
						} else this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});

			},
			loadItems(code) {
				// debugger;
				let u = this.loginUser;
				// let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
					case 'vendor':
						url = '/Vendors/Details/';
						code = u.id;
						break;
					case 'admin':
						url = '/Vendors/Details/';
						code = this.$route.params.vendorCode;
						break;
					default:
						return ;
						break;
				}
				this.isLoading.allItems = true;
				axios.get(
					this.$baseURL + "/Menu/Inventory/List",
					{ params: { code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							this.allItems = response.data.menu;
							this.stalls = response.data.stalls;
							// this.loadStockHistory(code);
							// console.log('stalls', this.stalls);
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					});
			},
			loadStockHistory() {
				// debugger;
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
					case 'vendor':
						url = '/Vendors/Details/';
						code = u.id;
						break;
					case 'admin':
						url = '/Vendors/Details/';
						code = this.$route.params.vendorCode;
						break;
					default:
						return ;
						break;
				}
				this.isLoading.allItems = true;
				axios.get(
					this.$baseURL + "/Notes/StockItemList",
					{ params: { code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							this.stockHistory = [];
							Promise.all(Object.keys(response.data.stockHistory).map( sh => {
								this.stockHistory.push(response.data.stockHistory[sh]);
							} ));
							this.stockListHistory = response.data.stockListHistory;
							this.stockItemsHistory = response.data.stockItemsHistory;
							this.stocklist = response.data.stocklist;
							// console.log('stalls', this.stalls);
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					});
			},
			loadStockList() {
				// debugger;
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
					case 'vendor':
						url = '/Vendors/Details/';
						code = u.id;
						break;
					case 'admin':
						url = '/Vendors/Details/';
						code = this.$route.params.vendorCode;
						break;
					default:
						return ;
						break;
				}
				this.isLoading.allItems = true;
				axios.get(
					this.$baseURL + "/Notes/StockItemTotals",
					{ params: { code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							this.stockList = response.data.stocklist;
							this.stockItems = response.data.stockItems;
							this.stalls = response.data.stallList;
							this.loadStockHistory();
							// console.log('stalls', this.stalls);
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						// this.isLoading.allItems = false;
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.allItems = false;
						this.$emit('loading-complete');
					});
			},
			filterItemName: (value, row) => {
				// debugger;
				return row.item_name === value
			},
			filterStallName: (value, row) => {
				return row.stall_id === value
			},
			closeInventory() {
				// return console.log(this.stallCloseData);
				this.isLoading.allItems = true;
				axios.post(
					this.$baseURL + '/Menu/Inventory/Stalls/Close',
					this.stallCloseData,
					{ params: { code: this.event.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							console.log('close done');
							this.isLoading.allItems = false;
							this.StallCloseDialog = false;
							this.stallCloseData = [];
							this.loadDetails();
							// window.scrollTo(0, this.$refs.resultsSection.offsetTop);
						}
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.allItems = false;
					});
			},
			downloadItemData(){
				let csv = 'STALL,CATEGORY,ITEM,ITEM-ID,BARCODE,QUANTITY\n';
				this.stockList.forEach(item => {
					csv += [ item.stall_name, item.category_name, item.item_name, item.item_id, item.item_barcode.toString(), item.item_quantity, ].join(',') + '\n';
				});
				// return console.log(csv);
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = this.event.id + ' | ' + this.event.name + ' | ' + this.vendor.name + ' | Inventory Items' + '.csv';
				anchor.click();
			}
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
			// setTimeout(this.loadDetails, 500);
			this.loadDetails();
			window.scrollTo(0, 0);
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				if(this.$route.params.inventoryType === 'GRN' || this.$route.params.inventoryType === 'RN' ) {
					let u = JSON.parse(localStorage.getItem('user'));
					this.loginUser = u;
					if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
					this.setDefault();
					this.loadDetails();
				}
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
