<template>
	<div>
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category"> MENU</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<span class="badge badge-primary" v-if="isLoading.menu">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<div v-if="!isLoading.menu">
					<div class="my-2 text-center">
						<span class="d-inline col-1 px-0 text-center mx-2 ">
							Inactive Categories:
							<el-tooltip content="Show Inactive Categories" placement="top">
								<el-switch
									v-model="catg.showInactive"
									active-color="#13ce66"
									inactive-color="#ff6363"
									:active-value=true
									:inactive-value=false
									class="my-auto"
									:loading="catg.loading"
								/>
							</el-tooltip>
						</span>
						<span class="col-3 text-right text-info cursor-pointer" v-if="!isLoading.menu" @click="addCategory()">
							<i class="fa fa-plus"/> Category
						</span>
						<el-button
							class="mr-2"
							v-if="!isLoading.menu"
							size="small" plain :icon="isLoading.saveMenu ? 'fas fa-spin fa-spinner' : 'el-icon-upload'"
							:disabled="!menuUpdated || isLoading.saveMenu"
							@click="confirmMenu()"
						> Save</el-button>
					</div>

					<el-form  ref="form">
						<el-tabs v-model="categorySelected" type="border-card" tab-position="top">
							<el-tab-pane
								v-for="(category, index) in menu.filter(
										catg => this.catg.showInactive || (catg.status === 'active'))"
								:key="category.id_"
								:label="category.name"
								:name="category.id_.toString()"
							>
								<template #label>
									<span :class="category.status === 'active' ? '' : 'aml-text-danger-1 text-bold'">{{ category.name }} ( {{ itemCount(category.items) }} )</span>
									<span v-if="category.id === 0" class="cursor-pointer ml-2" @click="removeCategory(category.id_)"><i class="fa fa-close"/></span>
								</template>
								<div class="mt-3 row col-md-11 col-12 mx-auto">
									<el-form-item class="col-12" prop="name" >
										<span class="row">
											<h6 class="col-md-2 col-12 my-auto text-left"><i class="now-ui-icons education_atom"/> Name</h6>
											<el-input class="col-md-4 col-12"
													  v-model="category.name"
													  prefix-icon="now-ui-icons education_atom" placeholder="Category"/>
											<el-tooltip :content="'Status: ' + category.status" placement="top">
												<el-switch
													v-model="category.status"
													active-color="#13ce66"
													inactive-color="#ff6363"
													active-value="active"
													inactive-value="inactive"
													class="my-auto"
												/>
											</el-tooltip>
										</span>
									</el-form-item>
								</div>

								<!--ITEMS-->
								<div class="col-12 mx-auto px-0">
	<!--								<el-button class="my-3" @click="addItem(catg)" type="dark" icon="fa fa-plus">ITEM</el-button>-->
									<el-button class="mx-auto my-4" type="info" @click="addItem(category)" icon="fas fa-plus"> ITEM</el-button>
	<!--								<el-divider direction="vertical"></el-divider>-->
									<span class="d-inline col-1 px-0 text-center mx-2">
										Inactive Items:
										<el-tooltip content="Show Inactive Items" placement="top">
											<el-switch
												v-model="itm.showInactive"
												active-color="#13ce66"
												inactive-color="#ff6363"
												:active-value=true
												:inactive-value=false
												class="my-auto"
												@change=""
												:loading="itm.loading"
											/>
										</el-tooltip>
									</span>
									<el-button class="float-right mt-4 aml-text-teal-3" type="default" @click="downloadCSVData(category.name)">
										<i class="fas fa-download"/> Sample
									</el-button>
									<el-upload
										:ref="'upload' + category.id_"
										action=""
										class="d-inline ml-2 float-right mt-4"
										:auto-upload="false"
										:on-change="csvChange"
										:data="csvData"
									>
										<el-button type="default" class="aml-bg-teal-3 text-white">
											<i class="fas fa-upload"/> Menu
										</el-button>
									</el-upload>


									<div class="" v-if="category.items && category.items.length > 0">
										<el-input class="mb-2" placeholder="Search Items" prefix-icon="el-icon-search" v-model="searchItems[category.id_]"/>
										<div class="row bg-light justify-content-center">
											<h5 class="col-7 text-dark text-left my-1"><small>Item</small></h5>
											<h5 class="col-2 text-dark text-center my-1"><small>Price</small></h5>
											<h5 class="col-2 text-dark text-center my-1"><small>Code</small></h5>
											<h5 class="col-1 text-dark text-center my-1"><small></small></h5>
										</div>
										<div class="row aml-item py-2 justify-content-center"
											 v-for="item in category.items.filter(
												item_ => ( this.itm.showInactive || (item_.status === 'active') )  &&
														( !searchItems[category.id_] || item_.name.toLowerCase().includes(searchItems[category.id_]) || item_.code.toLowerCase().includes(searchItems[category.id_]) )
												)"
											 :key="item.id_" >
											<span class="col-7">
												<span v-if="item.isNew" class="col-1 my-auto text-right"><i @click="removeItem(category, item)" class="fa fa-times-circle cursor-pointer"/></span>
												<el-input
													class="mt-2 px-0"
													:class="(item.isNew ? ' col-10 ' : ' col-12 ') + (item.status === 'active' ? '' : ' aml-text-danger ') "
													v-model="item.name"
													placeholder="Item Name"
												/>
											</span>
											<el-input class="mb-0 px-1 col-2 my-auto" type="number" v-model="item.price" step=any />
											<el-input class="mb-0 px-1 col-2 my-auto" type="number" v-model="item.code" step=any />
											<span class="d-inline col-1 px-0 my-auto text-center">
												<el-tooltip :content="'Status: ' + item.status" placement="top">
												<el-switch
													v-model="item.status"
													active-color="#13ce66"
													inactive-color="#ff6363"
													active-value="active"
													inactive-value="inactive"
													class="my-auto"
												/>
											</el-tooltip>
											</span>
										</div>
									</div>
								</div>

							</el-tab-pane>
						</el-tabs>
					</el-form>
				</div>
				<div v-else>
					<el-skeleton :rows="5" animated />
				</div>
			</div>
		</div>
		<span class="aml-dialog">
			<el-dialog title="Confirm Menu Updates" v-model="saveMenuDialog">
				<span class="mt-3 d-block">
					<el-form  ref="form_">
						<el-tabs v-model="categorySelected2" type="border-card" tab-position="top">
							<el-tab-pane
								v-for="(category, index) in menu_updates"
								:key="category.id_"
								:label="category.name"
								:name="category.id_.toString()"
							>
								<template #label>
									<span :class="category.status === 'active' ? '' : 'aml-text-danger-1 text-bold'">{{ category.name }} ( {{ itemCount(category.items) }} )</span>
								</template>
								<div class="mt-3 row mx-auto">
									<div class="col-12 table-responsive">
										<table class="table table-bordered col-10 mx-auto">
											<thead class="text-primary">
												<th>Field</th>
												<th>New Value</th>
												<th>Old Value</th>
											</thead>
											<tbody>
												<tr v-for="( value, key ) in category">
													<td colspan="3" v-if="key === 'id' || key === 'id_' || key === 'items' || key === 'old'"></td>
													<td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'"><h6>{{ key }}</h6></td>
													<td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'">{{ value }}</td>
													<td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'" class="text-secondary">
														<small v-if="category.old">{{ category.old[key] }}</small>
														<small v-else>NEW</small>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<!--ITEMS-->
								<div class="col-12 mx-auto px-0 mt-3" v-if="typeof category.items !== 'undefined' && category.items.length > 0">
									<el-input class="mb-2" placeholder="Search Items" prefix-icon="el-icon-search" v-model="searchItems[category.id_]"/>

									<div class="mt-3 row mx-auto">
										<div class="col-12 table-responsive">
											<table class="table table-bordered col-12 mx-auto">
												<thead class="text-primary">
													<th><small>ID</small></th>
													<th><small>Item</small></th>
													<th><small>Price</small></th>
													<th><small>Code</small></th>
													<th><small>Status</small></th>
												</thead>
												<tbody>
													<tr v-for="item in category.items" :key="item.id_">
														<td><small>{{ item.id }}</small></td>
														<td>{{ item.name }} <small v-if="item.isNew">( NEW )</small></td>
														<td>{{ item.price }} <small v-if="item.old && item.old.price">( {{ item.old.price }} )</small></td>
														<td>{{ item.code }} <small v-if="item.old && item.old.code">( {{ item.old.code }} )</small></td>
														<td>{{ item.status }} <small v-if="item.old && item.old.status">( {{ item.old.status }} )</small></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>

							</el-tab-pane>
						</el-tabs>
					</el-form>
				</span>
				<template #footer>
					<span class="dialog-footer">
					  <el-button @click="saveMenuDialog = false" v-if="!isLoading.saveMenu">Cancel</el-button>
					  <el-button class="aml-bg-teal-3 text-white" :loading="isLoading.saveMenu" @click="saveMenu()" >Save</el-button>
					</span>
				</template>
			</el-dialog>
		</span>
	</div>
</template>
<script>
	import {
		ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
		ElForm, ElFormItem, ElButton, ElDialog, ElDivider, ElUpload,
	} from 'element-plus';
	import axios from "axios";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
			ElForm, ElFormItem, ElButton, ElDialog, ElDivider, useToast, Notification,
			ElUpload,
		},
		inject: ['$baseURL', '$numFrm', '$removeData', ],
		props: [ 'info', 'vendor', 'user', ],
		data() {
			return {
				originalMenu: [ ],
				menu: [ ],
				menu_updates: [ ],
				menuFiltered: [ ],
				downloadMenu: { event: null, eventName: null, vendor: null, vendors: [] },
				dEvents: [],
				dStalls: [],
				loginUser: JSON.parse(localStorage.getItem('user')),
				defaultGST: ['0', '5', '12', '18', '28'],
				defaultTypes: ['inclusive', 'exclusive'],
				defaultItemTypes: ['food', 'drink', 'ticket', 'other'],
				categorySelected: "1",
				categorySelected2: "1",
				downloadMenuDialog: false,
				saveMenuDialog: false,
				isLoading: { menu: false, saveMenu: false, downloadMenu: false, vendor: false, },
				menuUpdated: false, menuLoaded: false,
				csvData: null,
				showInactiveCatgs: false,
				catg: { showInactive: true, loading: false },
				itm: { showInactive: true, loading: false },
				showInactiveItems: false,
				searchItems: {},
				vendorId: 0,
				duplicate: false,
				duplicateCode: null,
			}
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			async csvChange(file) {
				let catg = this.menu.find((catg) =>
					catg.id_.toString() === this.categorySelected
				);
				this.$refs['upload' + this.categorySelected].clearFiles();
				let items_ = await file.raw.text();
				let items = items_.split('\n');
				let header = items.shift();
				if(header.split(',').length < 4)	return this.runToast('', 'top-right', 'warning', "<b>File Error</b><br>File Not Formatted Correctly", "fas fa-bomb fa-4x");;
				// console.log(header, items);
				// { categoryId: catg.id, isNew: true, id_: catg.items.length + 1, price: 0, happyPrice: 0, type: 'food', status: 'active', };
				items.forEach(item => {
					let itm = item.split(',');
					if(itm.length<8)	return;
					if(typeof catg.items === 'undefined')	catg.items = [];
					catg.items.push({
						categoryId: this.categorySelected, isNew: true, id_: catg.items.length + 1,
						name: itm[0] ?? '',
						costPrice: isNaN(parseFloat(itm[1])) ? 0 : parseFloat(itm[1]),
						mrp: isNaN(parseFloat(itm[2])) ? 0 : parseFloat(itm[2]),
						price: isNaN(parseFloat(itm[3])) ? 0 : parseFloat(itm[3]),
						happyPrice: isNaN(parseFloat(itm[4])) ? 0 : parseFloat(itm[4]),
						hsn: itm[5] ?? '', barcode: itm[6] ?? '', type: itm[7] ?? 'food', status: 'active', });
				});
			},
			downloadCSVData(categoryName){
				let csv = 'Item,Cost,MRP,Price,Happy Price,HSN,Barcode,Type(food/drink/ticket/other)\n';
				csv += 'Item Name 1,0,0,0,0,,,food\n';
				csv += 'Item Name 2,0,0,0,0,,,food\n';
				// this.csvdata.forEach((row) => {
				// 	csv += row.join(',');
				// 	csv += "\n";
				// });

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = categoryName + '.csv';
				anchor.click();
			},
			downloadInventory() {
				let csv = 'CATEGORY,CATEGORY STATUS,ITEM,COST,MRP,PRICE,HAPPY PRICE,BARCODE,QUANTITY,ITEM STATUS\n';
				this.menu.forEach(category => {
					category.items.forEach(item => {
						csv += [ category.name, category.status,
							item.name, item.costPrice, item.mrp, item.price, item.happyPrice,
							item.barcode, item.quantity, item.status
						].join(',') + '\n';
					});
				});
				// return console.log(csv);
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = this.vendor.name + ' Inventory Counts' + '.csv';
				anchor.click();

			},
			async catgStatusToggle() {
				this.catg.loading = true;
				// await new Promise((resolve, reject) => this.filterCatg() );
				// await new Promise(async (resolve, reject) => {
				// 	this.menuFiltered = await this.menu.filter(
				// 		catg => this.catg.showInactive || (catg.status === 'active'));
				//
				// 		setTimeout(() => { resolve( true ); }, 1500);
				// 	}
				// )
				// .then( (resolve, reject) => {}  )
				// ;
				// this.updateLoading(this.catg.loading);
				// this.catg.loading = false;
				// setTimeout(() => { this.catg.loading = false; }, 1500);

				// this.menuFiltered = async(m, e) =>

				// const asyncFilter = async (arr, predicate) => {
				// 	const results = await Promise.all(arr.map(predicate));
				// 	return arr.filter((_v, index) => results[index]);
				// }

				const asyncFilter = async (arr, predicate) =>
					arr.reduce(async (memo, e) =>
							await predicate(e) ? [...await memo, e] : memo
						, []);

				this.menuFiltered = await asyncFilter(this.menu, async (i) => {
					// await sleep(10);
					return this.catg.showInactive || (i.status === 'active');
				});

				this.catg.loading = false;
			},
			itemCount(items) {
				return items ? items.filter( item => item.status === 'active' ).length : 0;
			},
			updateLoading(q) { q = !q },
			itemStatusToggle(items) {
				let i = items.filter(item => this.itm.showInactive ||
					( item.status === 'active') );
				return i
			},
			addItem(catg) {
				if(catg.items) catg.items.push({ categoryId: catg.id, isNew: true, id_: catg.items.length + 1, code: null, price: 0, status: 'active', });
				else catg.items = [{ categoryId: catg.id, isNew: true, id_: 1, price: 0, code: null, status: 'active', }];
			},
			addCategory() {
				let id_ = (this.menu.length + 1).toString() + new Date().getTime().toString();
				this.menu.push({ id: 0, id_, name: "New Category", status: 'active', vendorId: this.vendorId, items:[], });
				this.categorySelected = id_;
				// setTimeout(() => { this.$refs[ 'catg' + this.menus.length ][0].click(); }, 50);
			},
			loadVendor() {
				this.isLoading.vendor = true;
				if(this.downloadMenu.event === '' || this.downloadMenu === null)	{
					this.runToast('', 'top-right', 'danger', "<b>Event Not Selected</b><br>Please try again.", "fas fa-bomb fa-4x");;
					return this.isLoading.vendor = false;
				}
				axios.get(
					this.$baseURL + '/Vendors/List',
					{ params: { code: this.downloadMenu.event },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
				.then(response => {
					if(response.data.success) {
						this.downloadMenu.vendors = response.data.vendors;
						if(response.data.vendors && response.data.vendors.length > 0)
							this.downloadMenu.eventName = response.data.vendors[0].event_name;
						else {
							this.downloadMenu.eventName = null;
							this.downloadMenu.vendor = null;
						}
					}
					this.isLoading.vendor = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.vendor = false;
				});
			},
			loadMenu() {
				this.loadItems(this.downloadMenu.vendor, false);
			},
			removeCategory(id_) {
				// this.menu.forEach(category => {
				//
				// });
				this.$removeData(this.menu, id_, 'id_');
				this.categorySelected = this.menu.length > 0 ? this.menu[0].id_.toString() : null;
			},
			removeItem(catg, item) {
				this.$removeData(catg.items, item.id_, 'id_');
			},
			verifyItemCodes(all_data) {
				var item_codes = [], j = 0;
				this.duplicate = false;
				this.duplicateCode = null;
				all_data.forEach( categories => {
					if(this.duplicate)	return;
					categories.items.forEach(item => {
						if(this.duplicate)	return;
						if( item.code != '' && item.code != null && item_codes.includes(parseInt(item.code))) {
							this.duplicate = true;
							this.duplicateCode = parseInt(item.code);
							return;
						}
						item_codes[j++] = parseInt(item.code);
					});
				});
				return item_codes;
			},
			saveMenu() {
				this.verifyItemCodes(this.menu);
				if(this.duplicate)	return alert("Duplicate Item Codes not allowed: ( " + this.duplicateCode + " )");
				// return;
				this.isLoading.saveMenu = true;
				// return console.log("menu: ", this.menu_updates);

				axios.post(
					this.$baseURL + '/IISER/Menu/Save',
					this.menu_updates,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log("saved menu", response);
						this.isLoading.saveMenu = false;
						if(response.data.success) {
							this.alert = {show: true, type: 'success', icon: "ni ni-like-2", message: {strong: 'Success!', normal: "Menu Save Success!"}};
							// this.menus = response.data.menus;
							this.loadItems(this.vendorId, true);
							this.saveMenuDialog = false;
						} else {
							this.alert = {show: true, type: 'danger', icon: "ni ni-like-2", message: {strong: 'Failed!', normal: "Menu Save Failed!"}};
						}
					})
					.catch( (error) => {
						console.error(error);
						this.isLoading.saveMenu = false;
						this.alert = { show: true, type: 'warning', icon: "ni ni-like-2", message: { strong: 'Error!', normal: "Something went wrong!" } };
					});
			},
			confirmMenu() {
				this.menu_updates = [];
				this.duplicate = false; this.duplicateCode = null;
				this.menu.forEach( function(category, i) {
					let catg = { }, catgUpdated = false;
					catg.old = {}; catg.name = category.name
					if( !category.id )	{
						catg = JSON.parse(JSON.stringify(category))
						this.menu_updates.push( catg );
					}
					else {
						Object.entries(category).forEach( (c) => {
							let key = c[0], value = c[1];
							if(key !== 'id_' && key !== 'items' && value !== this.originalMenu[i][key])
							{ catg[key] = value; catg.old[key] = this.originalMenu[i][key]; catgUpdated = true; }
						});
						if( category.items && category.items.length > 0) {
							category.items.forEach((item, j) => {
								let itm = {}, itemUpdated = false;
								itm.old = {};
								itm.name = item.name;
								if (!item.id) {
									if (typeof catg.items === 'undefined') {
										catg.items = [];
									}
									itm = JSON.parse(JSON.stringify(item));
									catg.items.push(itm);
									catgUpdated = true;
									// console.log(item, itm);
								} else {
									Object.entries(item).forEach((c) => {
										let key = c[0], value = c[1];
										if (key !== 'id_' && key !== 'id_' && value !== this.originalMenu[i].items[j][key]) {
											itm[key] = value;
											itm.old[key] = this.originalMenu[i].items[j][key];
											itemUpdated = true;
										}
									});
									if (itemUpdated) {
										if (typeof catg.items === 'undefined') catg.items = [];
										itm.id = itm.id_ = item.id;
										catg.items.push(itm);
										catgUpdated = true;
									}
								}
							});
						} else { category.items = []; }

						if( catgUpdated )	{
							catg.id_ = category.id_;
							catg.id = category.id;
							this.menu_updates.push( catg );
						}
					}
				}.bind({originalMenu: this.originalMenu, menu_updates: this.menu_updates}));
				// console.log(this.menu_updates);
				if(this.menu_updates.length > 0)	{
					this.categorySelected2 = this.menu_updates[0].id_.toString();
					return this.saveMenuDialog = true;
				} else this.menuUpdated = false;

			},
			loadItems(vendor, isDefault) {
				this.isLoading.menu = true;
				this.isLoading.downloadMenu = true;
				this.catg.loading = true;
				this.itm.loading = true;
				axios.get(
					this.$baseURL + "/IISER/Menu/List",
					{ params: { vendor, },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) {
							// console.log(response.data);
							if(isDefault) {
								this.menuLoaded = true;
								this.menu = response.data.menu;
								this.menu.forEach(c => { c.id_ = c.id; });
								this.originalMenu = JSON.parse(JSON.stringify(this.menu));
								if (this.menu.length > 0) this.categorySelected = this.menu[0].id.toString();
								else this.categorySelected = "0";
							} else {
								let newMenu = response.data.menu;
								let n = new Date().getTime().toString();
								newMenu.forEach(category => {
									category.id_ = category.id + n;
									category.id = 0;
									category.vendorId = this.vendorId;
									category.items.forEach(item => {
										item.isNew = true;
										item.categoryId = 0;
										item.id_ = item.id + n;
										delete item.id;
										item.quantity = 0;
									});
								});
								// console.log(newMenu);
								this.menu = [...this.menu, ...newMenu];
								this.menu_updates = [  ];
								this.categorySelected = newMenu[0].id_.toString();
							}
						}
						this.menuFiltered = JSON.parse(JSON.stringify(this.menu.filter(catg => this.catg.showInactive || ( catg.status === 'active') )));
						this.isLoading.menu = false;
						this.isLoading.downloadMenu = false;
						this.downloadMenuDialog = false;
						this.catg.loading = false;
						this.itm.loading = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.menu = false;
						this.isLoading.downloadMenu = false;
					});
			},
		},
		mounted() {
			this.vendorId = this.$route.params.vendorCode ?? ( this.user.id ?? 0 );
			this.loadItems(this.vendorId, true);
		},
		watch: {
			event: {
				handler(a) {
					// console.log("event update detect", a);
					if(a) this.loadItems(this.$route.params.vendorCode, true);
				},
				deep: true,
			},
			menu: {
				handler(a) {
					// let b = JSON.stringify({menu: this.originalMenu}) === JSON.stringify({ menu: a });
					// this.menuUpdated = !b;
					// console.log("menu update detect", b, a);
					// this.menuUpdated = this.menuLoaded;
					// debugger;
					if(this.menuLoaded) this.menuLoaded = false;
					else this.menuUpdated = true;
				},
				deep: true,
			},
			menu_updates: {
				handler(a) {
					// let b = JSON.stringify({menu: this.originalMenu}) === JSON.stringify({ menu: a });
					// this.menuUpdated = !b;
					// console.log("menu update detect", b, a);
					// this.menuUpdated = this.menuLoaded;
					// debugger;
					console.log(a, this.menu_updates);
					// if(this.menuLoaded) this.menuLoaded = false;
					// else this.menuUpdated = true;
				},
				deep: true,
			},
		}
	}
</script>
<style>
.aml-text-danger .el-input__inner {
	color: #ff6363 !important;
	border-color: #ff6363 !important;
	background-color: #f003 !important;
}
.el-input__inner {
	padding: 0px 0px 0px 5px;
}
</style>
