import Chart from "chart.js";

export const headerChart = {
	createChart(chartId, v = [ ], ds = [],) {
		let chartColor = "#FFFFFF";
		const ctx = document.getElementById(chartId).getContext("2d");
		let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
		gradientStroke.addColorStop(0, "#80b6f4");
		gradientStroke.addColorStop(1, chartColor);

		let gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
		gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
		gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.8)");

		let colourStop = { sale: '0.3', topup: '0.6', return: '0.9' };
		let bgColours = {
			sale: "#fff",
			topup: gradientFill,
			return: "#04404a",
		};

		let datasets = [];

		// debugger;
		// for (const [i, value] of ds) {
		ds.forEach( (value, i) => {
			datasets.push({
				// type: "bar",
				label: v[i].toUpperCase(),
				data: [parseFloat(value)],
				borderColor: chartColor,
				backgroundColor: bgColours[v[i]],
				// pointBorderColor: chartColor,
				// pointBackgroundColor: "#1e3d60",
				// pointHoverBackgroundColor: "#1e3d60",
				// pointHoverBorderColor: chartColor,
				// pointBorderWidth: 1,
				// pointHoverRadius: 7,
				// pointHoverBorderWidth: 2,
				// pointRadius: 5,
				// fill: true,
				// borderWidth: 2,
			});
		});

		// console.log(datasets);

		let config = {
			type: "horizontalBar",
			data: {
				// labels: v,
				datasets,
			},
			options: {
				responsive: true,
				indexAxis: 'y',
				layout: {
					padding: {
						left: 0,
						right: 10,
						top: 0,
						bottom: 0,
					},
				},
				// maintainAspectRatio: false,
				tooltips: {
					backgroundColor: "#fff",
					titleFontColor: "#333",
					bodyFontColor: "#666",
					bodySpacing: 4,
					xPadding: 12,
				},
				legend: {
					position: "bottom",
					fillStyle: "#FFF",
					display: false,
				},
				scales: {
					yAxes: [
						{
							ticks: {
								fontColor: "rgba(255,255,255,0.4)",
								fontStyle: "bold",
								beginAtZero: true,
								maxTicksLimit: 5,
								padding: 10,
							},
							gridLines: {
								drawTicks: true,
								drawBorder: false,
								display: true,
								color: "rgba(255,255,255,0.1)",
								zeroLineColor: "transparent",
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								zeroLineColor: "transparent",
								display: false,
							},
							ticks: {
								padding: 10,
								fontColor: "rgba(255,255,255,0.4)",
								fontStyle: "bold",
								beginAtZero: true,
							},
						},
					],
				},
			},
		};

		// console.log(config);

		const myChart_ = new Chart(ctx, config);
	},
};

export default headerChart;
