<template>
	<div :class="{ 'fullscreen': fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
		<qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">

			<div v-if="validationSuccess" class="validation-success">
				{{ result }}
			</div>

			<div v-if="validationFailure" class="validation-failure">
				{{ error }}
			</div>

			<div v-if="validationPending" class="validation-pending">
				Please wait...
			</div>

			<div class="loading-indicator" v-if="loading">
				Loading...
			</div>

			<button @click="fullscreen = !fullscreen" class="fullscreen-button">
				<i class="fas" :class="fullscreenIcon"></i>
			</button>

			<div v-if="camera === 'off'" @click="resetCamera" class="position-absolute ml-2 mt-2 text-primary">
				<i class="fas fa-redo-alt fa-3x"></i>
			</div>

		</qrcode-stream>

		<p class="decode-result">Last result: <b>{{ result }}</b></p>


		<div class="card">
			<span class="text-primary"><i class="fas fa-close fa-3x"></i></span>
			<button class="btn btn-primary" v-if="camera === 'off'" @click="resetCamera">reset</button>
		</div>
	</div>
</template>

<script>
	import { QrcodeStream } from 'vue3-qrcode-reader'

	// NOTE: calling `requestFullscreen` might prompt the user with another
	// permission dialog. You already asked for camera access permission so this is
	// a rather invasive move.
	//
	// Even without calling `requestFullscreen` the entire viewport is covered
	// by the camera stream. So consider skipping `requestFullscreen` in your
	// implementation.

	export default {

		components: { QrcodeStream },

		data () {
			return {
				fullscreen: false,
				isValid: undefined,
				camera: 'auto',
				result: null,
				error: null,

				loading: false,
				destroyed: false,
			}
		},

		setup() {
			// alert(btoa(JSON.stringify({personId: 1, bloodGroup: 'O+', address: 'Mandrem, Goa'})));
		},

		computed: {
			fullscreenIcon() {
				if (this.fullscreen) {
					return "fa-compress"
					// return "/fullscreen-exit.svg"
				} else {
					return "fa-expand"
					// return "/fullscreen.svg"
				}
			},

			validationPending () {
				return this.isValid === undefined
					&& this.camera === 'off'
			},

			validationSuccess () {
				return this.isValid === true
			},

			validationFailure () {
				return this.isValid === false
			},
		},

		watch: {
			fullscreen(enterFullscreen) {
				if (enterFullscreen) {
					this.requestFullscreen()
				} else {
					this.exitFullscreen()
				}
			}
		},

		methods: {
			onFullscreenChange(event) {
				// This becomes important when the user doesn't use the button to exit
				// fullscreen but hits ESC on desktop, pushes a physical back button on
				// mobile etc.

				this.fullscreen = document.fullscreenElement !== null
			},

			requestFullscreen() {
				const elem = this.$refs.wrapper

				if (elem.requestFullscreen) {
					elem.requestFullscreen();
				} else if (elem.mozRequestFullScreen) { /* Firefox */
					elem.mozRequestFullScreen();
				} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
					elem.webkitRequestFullscreen();
				} else if (elem.msRequestFullscreen) { /* IE/Edge */
					elem.msRequestFullscreen();
				}
			},

			exitFullscreen() {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozCancelFullScreen) { /* Firefox */
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
					document.webkitExitFullscreen();
				} else if (document.msExitFullscreen) { /* IE/Edge */
					document.msExitFullscreen();
				}
			},

			logErrors (promise) {
				promise.catch(console.error)
			},

			// onInit (promise) {
			// 	promise
			// 		.catch(console.error)
			// 		.then(this.resetValidationState)
			// },

			async onInit (promise) {
				this.loading = true;

				await promise
					.catch(console.error)
					.then(this.resetValidationState)
					.then(this.loading = false)

			},

			resetValidationState () {
				this.isValid = undefined
			},

			validate(content) {
				try {
					this.result = JSON.parse(atob(content));
					return true;
				}catch (e) {
					this.error = "Invalid QR";
					console.log(e.message);
					return false;
				}
			},

			async onDecode (content) {
				// alert(content);
				this.result = content;
				this.turnCameraOff();

				// pretend it's taking really long
				await this.timeout(100);
				this.isValid = this.validate(content) // content.startsWith('http')

				// some more delay, so users have time to read the message
				// await this.timeout(2000)
				//
				// this.turnCameraOn()
			},

			resetCamera () {
				this.turnCameraOn();
			},

			turnCameraOn () {
				this.camera = 'auto'
			},

			turnCameraOff () {
				this.camera = 'off'
			},

			timeout (ms) {
				return new Promise(resolve => {
					window.setTimeout(resolve, ms)
				})
			}
		}

	}
</script>

<style scoped>
	.fullscreen {
		position: fixed;
		z-index: 1000;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.fullscreen-button {
		background-color: white;
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 1rem;
	}
	.fullscreen-button img {
		width: 2rem;
	}
</style>

<style scoped>
	.validation-success,
	.validation-failure,
	.validation-pending {
		position: absolute;
		width: 100%;
		height: 100%;

		background-color: rgba(255, 255, 255, .8);
		text-align: center;
		font-weight: bold;
		font-size: 1.4rem;
		padding: 10px;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
	.validation-success {
		color: green;
	}
	.validation-failure {
		color: red;
	}
</style>
