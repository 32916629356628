<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">DEVICES ( {{ eventDevices.length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="isLoading.eventDevices">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Device" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-11 aml-devices-table"
	          :data="eventDevices.filter(
	          	data => !search ||
	          		data.stall_name.toLowerCase().includes(search.toLowerCase()) ||
	          		data.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
	          		data.devices_main_printId.toString().includes(search.toLowerCase()) ||
	          		data.device_type.toLowerCase().includes(search.toLowerCase()) ||
	          		data.device_day_closed.toString().includes(search.toLowerCase() === 'yes' ? '1' : search.toLowerCase() === 'no' ? '0' : search.toLowerCase())
				)"
					  :height="eventDevices.length <= 5 ? '200' : eventDevices.length > 10 ? '500' : eventDevices.length > 20 ? '800' : '300' "
			>
				<el-table-column label="#" type="index" width="40"/>
				<el-table-column label="DEVICE" sortable prop="devices_main_printId">
					<template #default="scope">
						<h5 class="d-inline font-weight-lighter align-bottom">
							<strong class="aml-bg-teal text-white font-weight-bold px-1 rounded">{{ scope.row.devices_main_printId }}</strong>
							<el-divider direction="vertical"/> <span class="d-inline-block cursor-pointer" @click="deviceLog(scope.row)"> <i class="fas fa-clipboard-list"/> </span>
							<el-divider direction="vertical"/> <small><i class="fas fa-mobile aml-text-teal"/>: </small> <strong>{{ scope.row.device_type.replace('sale_', '').replace('topup_', '').toUpperCase() }}</strong>
							<el-divider direction="vertical"/> <small><i class="fas fa-print aml-text-teal"/>: </small> {{ scope.row.device_printer }}
<!--							<el-divider direction="vertical"/> <strong1><strong class="aml-text-teal">-v{{ scope.row.device_latest_app_version }}</strong></strong1>-->
						</h5>
						<div>
<!--							<small><i class="fas fa-mobile aml-text-teal"/>: </small> {{ scope.row.device_type.replace('sale_', '').replace('topup_', '').toUpperCase() }}-->
							<span class="d-inline-block cursor-pointer rounded-circle font-weight-bold px-1 text-primary" @click="deviceEdit(scope.row)"> <i class="fas fa-edit"/> </span>
							<el-divider direction="vertical"/>
							<strong>STALL:</strong> {{ scope.row.stall_name }}
							<el-divider direction="vertical"/>
							<strong>VENDOR:</strong> {{ scope.row.vendor_name }}
						</div>
					</template>
				</el-table-column>
<!--				<el-table-column sortable label="VENDOR" prop="vendor_name"/>-->
<!--				<el-table-column sortable label="STALL" prop="stall_name"/>-->
<!--				<el-table-column sortable label="DEVICE" prop="devices_main_printId" align="center"/>-->
				<!--<el-table-column sortable label="DEVICE" prop="devices_main_type" align="center"/>-->
<!--				<el-table-column sortable label="TYPE" align="center" prop="device_type">-->
<!--					<template #default="scope">-->
<!--						<p> {{ scope.row.device_type.replace('sale_', '').replace('topup_', '').toUpperCase() }} </p>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column sortable label="PRINTER" prop="device_printer" align="center"/>-->
				<el-table-column sortable label="LOGIN AT" prop="device_last_login_at_unix" align="center" :formatter="formatter" width="150"/>
				<el-table-column sortable label="-v" prop="device_latest_app_version" align="center" width="75">
					<template #default="scope"> <strong class="aml-text-teal">{{ scope.row.device_latest_app_version }}</strong> </template>
				</el-table-column>
<!--				<el-table-column sortable label="LOGIN AT" prop="device_day_closed" align="center" :formatter="formatter" width="50"/>-->
<!--				<el-table-column sortable label="LOGIN AT" prop="device_last_login_at" align="center" width="150">-->
<!--					<template #default="scope">-->
<!--						<span>{{ displaydt(scope.row.device_last_login_at) }}</span>-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column sortable align="center" prop="device_day_closed" width="75">
					<template #header>
						<small><i class="fas fa-ban"/></small>
					</template>
					<template #default="scope">
						<h6 :class="scope.row.device_day_closed === 1 ? 'text-success' : 'text-danger'"> {{ scope.row.device_day_closed === 1 ? 'YES' : 'NO' }} </h6>
					</template>
				</el-table-column>
				<el-table-column sortable label="CLOSED AT" prop="device_day_closed_at_unix" align="center" :formatter="formatter" width="150"/>
<!--				<el-table-column sortable label="CLOSED" prop="device_day_closed_at" align="center"/>-->
<!--				<el-table-column sortable label="CLOSED" prop="device_day_closed_at" align="center" width="150">-->
<!--					<template #default="scope">-->
<!--						<span>{{ displaydt(scope.row.device_day_closed_at) }}</span>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column label="LOGS" align="center" width="70">-->
<!--					<template #default="scope">-->
<!--						<span class="d-block cursor-pointer" @click="deviceLog(scope.row)"> <i class="fas fa-clipboard-list"></i> </span>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column label="EDIT" align="center" width="70">-->
<!--					<template #default="scope">-->
<!--						<span class="d-block cursor-pointer" @click="deviceEdit(scope.row)"> <i class="fas fa-edit"></i> </span>-->
<!--					</template>-->
<!--				</el-table-column>-->
			</el-table>
		</div>
	</div>

	<span class="aml-dialog">
		<el-dialog :title="'Update Device: ' + device.device + ' | ' + device.id" v-model="deviceDialog">
			<span class="d-block mt-3">
				<el-form :model="device" class="">
				<span>
					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-right my-auto">VENDOR</span>
							<el-input class="col-md-8 col-12" v-model="deviceRow.vendor_name" disabled/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-right my-auto">STALL</span>
							<el-select class="select-aml-teal col-6" v-model="device.stall" filterable automatic-dropdown placeholder="Select Stall">
								<el-option v-for="stall in stalls" :key="stall.id" :label="stall.name.toUpperCase()" :value="stall.id"/>
							</el-select>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-right my-auto">Type</span>
							<el-select class="select-aml-teal col-6" v-model="device.type" filterable automatic-dropdown placeholder="Select Type">
								<el-option v-for="type in defaultDeviceTypes"
								           :key="type.value" :label="type.label.toUpperCase()" :value="type.value"/>
							</el-select>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-right my-auto">Printer</span>
<!--							<el-select class="select-aml-teal col-6" v-model="device.printer" filterable automatic-dropdown placeholder="Select Printer">-->
<!--								<el-option v-for="printer in defaultPrinters"-->
<!--								           :key="printer" :label="printer.toUpperCase()" :value="printer"/>-->
<!--							</el-select>-->
							<el-select class="select-aml-teal col-6" v-model="device.printer" allow-create filterable automatic-dropdown placeholder="Select Printer">
								<el-option v-for="printer in defaultPrinters"
										   :key="printer.value" :label="printer.label.toUpperCase()" :value="printer.value"/>
							</el-select>
						</span>
					</el-form-item>
				</span>
			</el-form>
			</span>
			<template #footer>
			    <span class="dialog-footer">
			      <el-button @click="deviceDialog = false" v-if="!deviceLoading">Cancel</el-button>
			      <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="deviceLoading" @click="saveDevice">Confirm</el-button>
			    </span>
			</template>
		</el-dialog>
	</span>

</template>

<script>
import {
	ElTable, ElTableColumn, ElInput, ElButton,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElDatePicker, ElOptionGroup, ElMessage
} from "element-plus";
	import axios from 'axios';

	import moment from "moment";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption, ElOptionGroup,
			ElSwitch, ElDivider, ElDatePicker,
			moment,
		},
		props: [ 'event' ],
		inject: [ '$baseURL', '$formatDateTime', '$isCheckEventType', ],
		emits: [  ],
		data() {
			return {
				search: '',
				reportLoading: false,
				defaultTypes: [ { value: 'summary', label: 'Summary' }, { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' }, { value: 'all_txns', label: 'BASIC TRANSACTION DUMP' }, { value: 'balances', label: 'Remaining Balances' }, { value: 'partials', label: 'Partial Transactions' }, ],
				defaultReports: [ ],
				eventDevices: [],
				vendors: [], stalls: [], deviceDialog: false,
				device: {},
				deviceRow: {},
				defaultInfo: {  eventDevices: [], vendors: [], stalls: [], },
				deviceLoading: false,
				isLoading: { eventDevices: false, },
				filter: { dates: [], report: null, type: '', settings: { details: false, summary: false, type: 'xlsx', dates: false, mobile: false, discount: false, sc: false, nfc: false, mode: false, bank: false, } },
				searchLoading: false,
				defaultDeviceTypes: [
					{ label: 'TOPUP CASH', value: 'topup_cash' }, { label: 'TOPUP CARD', value: 'topup_card' },
					{ label: 'TOPUP COMPLIMENTARY', value: 'topup_comp' }, { label: 'TOPUP ITEMS', value: 'item_topup' },
					{ label: 'ACCESSX', value: 'accessx' },
					{ label: 'TOPUP CORPORATE', value: 'topup_corp' },
					{ label: 'TOPUP EMPLOYEE', value: 'topup_emp' }, { label: 'TOPUP SPECIAL', value: 'topup_spl' },
					{ label: 'TOPUP KIOSK', value: 'topup_kiosk' }, { label: 'TOPUP KIOSK ONLINE', value: 'topup_kiosk_online' },
					{ label: 'TOPUP KIOSK OFFLINE', value: 'topup_kiosk_offline' },
					{ label: 'TOPUP HOME DELIVERY', value: 'topup_hd' }, { label: 'TOPUP PICKUP', value: 'topup_pickup' },
					{ label: 'RETURN', value: 'return' }, { label: 'MERGE', value: 'merge' },
					{ label: 'SALE MENU', value: 'sale_menu' }, { label: 'TICKET MENU', value: 'ticket_menu' }, { label: 'SALE INVOICE', value: 'sale_invoice' }, { label: 'SALE ITEMS', value: 'item_sale' }, { label: 'SALE KIOSK', value: 'sale_kiosk' },
					{ label: 'ALL OPTIONS', value: 'all_options' },
					{ label: 'MASTER RETURN', value: 'master_return' },
					{ label: 'MANAGER ASSIGN', value: 'inventory_assign' }, { label: 'INVENTORY DEPLOY', value: 'inventory_deploy' }, { label: 'INVENTORY RETURN', value: 'inventory_return' },
					{ label: 'ISSUANCE', value: 'accessx_issue' },
					// { label: 'PACHA VIP ISSUANCE', value: 'accessx_pacha' }, { label: 'PACHA GUEST ISSUANCE', value: 'pacha_accessx_guest' }, { label: 'PACHA ACCESSX', value: 'pacha_accessx_vip' },
				],
				defaultPachaAccessxDeviceTypes: [
					{ label: 'PACHA ACCESSX', value: 'accessx_pacha' }, { label: 'PACHA GUEST ISSUANCE', value: 'pacha_accessx_guest' }, { label: 'PACHA VIP ISSUANCE', value: 'pacha_accessx_vip' },
					{ label: 'ACCESSX', value: 'accessx' }, { label: 'ISSUANCE', value: 'accessx_issue' },
				],
				defaultInventoryDeviceTypes: [ { label: 'MANAGER ASSIGN', value: 'inventory_assign' }, { label: 'INVENTORY DEPLOY', value: 'inventory_deploy' } ],
				defaultPrinters2: [ 'wisepos+', 'panda','rugtek', 'worldline', 'sunmiPay', 'blue2', 'sprin_3', 'sprin_2', 'none', ],
				defaultPrinters: [
					{ label: 'NONE', value: 'none' },
					{ label: 'Wisepos+', value: 'wisepos+' },
					{ label: 'EZETAP', value: 'airpay' },
					{ label: 'MF919', value: 'MF919' },
					{ label: 'MOSAMBEE | DX8000', value: 'DX8000' },
					{ label: 'Worldline', value: 'worldline' }, { label: 'SunmiPay', value: 'sunmiPay+' },
					{ label: 'AIPAY', value: 'airpay' },
					{ label: 'Pinelabs', value: 'pinelabs' },
					{ label: 'Rugtek', value: 'rugtek' },
					{ label: 'BT-2inch', value: 'blue2' }, // { label: 'BT-3inch', value: 'blue3' },
					{ label: 'SPrin-3inch', value: 'sprin_3' }, { label: 'SPrin-2inch', value: 'sprin_2' },
					{ label: 'Panda-3inch', value: 'panda' },
				],

			}
		},
		mounted() {

		},
		methods: {
			formatter(row, column) {
				switch (column.property) {
					case 'device_last_login_at':
					case 'device_last_login_at_unix':
					case 'device_day_closed_at':
					case 'device_day_closed_at_unix':
						return this.displaydt(row[column.property.split('_unix')[0]]);
						break;
					case 'device_day_closed':
						return "<h6 class='" + ( row.device_day_closed === 1 ? 'text-success' : 'text-danger' ) + "'>" + ( row.device_day_closed === 1 ? 'YES' : 'NO' ) + "</h6>";
					// return <h6 :class="scope.row.device_day_closed === 1 ? 'text-success' : 'text-danger'"> {{ scope.row.device_day_closed === 1 ? 'YES' : 'NO' }} </h6>;
					// 	return this.displaydt(row[column.property]);
						break;
					default:
						break;
				}

			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('YYYY-MM-DD HH:mm:ss') : ''; },
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			deviceEdit(device) {
				this.device.id = device.device_id;
				this.device.stall = device.device_stall;
				this.device.printer = device.device_printer;
				this.device.device = device.devices_main_printId;
				this.device.type = device.device_type;

				this.deviceRow = device;

				this.deviceDialog = true;
			},
			saveDevice() {
				this.deviceLoading = true;
				axios.post(
					this.$baseURL + '/Devices/Update',
					this.device,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// console.log(this.stalls.find(stall => stall.id === this.device.stall));
							this.deviceRow.stall_name = this.stalls.find(stall => stall.id === this.device.stall).name; //this.device.stall;
							this.deviceRow.stall_id = this.device.stall; //this.device.stall;
							this.deviceRow.device_stall = this.device.stall; //this.device.stall;
							this.deviceRow.device_printer = this.device.printer;
							this.deviceRow.device_type = this.device.type;
							this.deviceRow.vendor_name = this.vendors.find(vendor => vendor.id === this.stalls.find(stall => stall.id === this.device.stall).vendor_id ).name;
							this.deviceDialog = false;
						}
						this.deviceLoading = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.deviceLoading = false;
					});
			},
			getDeviceList(dataType) {
				this.isLoading.eventDevices = true;
				axios.get(
					this.$baseURL + '/Devices/List/',
					{ params: { code: this.event.id, type: dataType },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(dataType === 'event-wise') {
						this.setDefault('eventDevices');
						this.eventDevices = response.data.devices;
						this.isLoading.eventDevices = false;
					}
					console.log(this.event, 'this.event');
					if(this.event.id=== 1356) {
						this.defaultDeviceTypes = [ ...this.defaultDeviceTypes, ...this.defaultPachaAccessxDeviceTypes ];
						// this.defaultPachaAccessxDeviceTypes
					}
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.eventDevices = false;
				});
			},
			loadConfigData(dataType) {
				if(!dataType)   return;
				axios.get(
					this.$baseURL + '/' + dataType + '/event-wise/',
					{ params: { code: this.event.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then( (response) => {
						// console.log(dataType, response);
						if(response.data.success) {
							this.setDefault(dataType.toLowerCase());
							this[dataType.toLowerCase()] = response.data[dataType.toLowerCase()];
						}

					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
					});
			},
		},
		watch: {
			event(newEvent, from) {
				if(this.$isCheckEventType('fnb', newEvent.type)) {
					this.getDeviceList('event-wise');
					this.loadConfigData('Vendors');
					this.loadConfigData('Stalls');
				}
			},
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
	.aml-report-date-filter .el-date-editor .el-range-input { width: 80% !important; }
	.aml-devices-table.el-table table > thead > tr > th { font-size: 12px; }
</style>
