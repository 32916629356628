<template>
	<Form
		@submit="onSubmit"
		:validation-schema="schema">
		<div class="col-md-4 ml-auto mr-auto">
			<card class="card-login card-plain">
				<template v-slot:header>
					<div>
						<div class="logo-container">
							<img src="img/x-logo.png" alt="" />
						</div>
					</div>
				</template>

				<div>
					<vee-input
						name="username"
						type="text"
						inputClasses="no-border form-control-lg"
						placeholder="Username"
						addon-left-icon="now-ui-icons ui-1_email-85"
					/>

					<vee-input
						name="password"
						type="password"
						inputClasses="no-border form-control-lg"
						placeholder="Password"
						addon-left-icon="now-ui-icons ui-1_lock-circle-open"
					/>
				</div>

				<template v-slot:footer>
					<div>
						<n-button native-type="submit" :type="buttons.loginColour" :disabled="isLoading.login" round block>
							{{ buttons.login.toUpperCase() }} <i class="fas fa-spin fa-spinner fa-sm" :class="isLoading.login ? '' : 'd-none'"></i>
						</n-button>
						<div class="pull-left mt-2">
							<h6>
								<span class="link footer-link cursor-pointer" @click="loginTypeChange(buttons.btn1)"> {{ buttons.btn1 }} </span>
							</h6>
						</div>

						<div class="pull-right mt-2">
							<h6>
								<span class="link footer-link cursor-pointer" @click="loginTypeChange(buttons.btn2)"> {{ buttons.btn2 }} </span>
							</h6>
						</div>
					</div>
				</template>
			</card>
		</div>
	</Form>
</template>
<script>
	import { VeeInput } from "@/components";
	import axios from "axios";
	import { Form, Field, ErrorMessage } from "vee-validate";
	import * as Yup from "yup";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";


	export default {
		name: "login",
		inject: ['$baseURL'],
		components: {
			VeeInput,
			Form,
			Field, ErrorMessage,
		},
		data() {
			return {
				isLoading: { login: false },
				allTypes: { admin: { name: 'admin', btnColour: 'primary'}, operator: { name: 'operator', btnColour: 'info'}, attendance: { name: 'attendance', btnColour: 'success'} },
				type: this.$route.params.type ?? 'operator',
				login_types: [ 'admin', 'operator', '' ],
				buttons: { login: 'operator', loginColour: 'info', btn1: 'admin', btn2: '', extraBtn: 'extra' },
				currentLoginType: 'operator',
			}
		},
		mounted: function() {
			// alert(this.$baseURL);
			// let u = JSON.parse(localStorage.getItem('user'));
			// if(u.loginType === 'employee')   return this.$router.push('/Company/Employee');
			// if(u.loginType === 'admin')   return this.$router.push('/ViewAll');
			// if(u.loginType === 'event')   return this.$router.push('/Analytics');
			// if(u.loginType === 'operator')   return this.$router.push('/Analytics');
		},
		methods: {
			onSubmit(credentials) {
				this.isLoading.login = true;
				axios.post(this.$baseURL + '/Users/Login/', {
					username: credentials.username,
					password: credentials.password,
					type: this.buttons.login,
				},
					)
					.then(response => {
						// console.log(response);
						// let is_admin = response.data.user.is_admin
						// debugger;
						if(response.data.success) {
							// debugger;
							if(response.data.loginType) response.data.user.loginType = response.data.loginType;
							if(response.data.product) response.data.user.product = response.data.product;
							localStorage.setItem('user', JSON.stringify(response.data.user));
							localStorage.setItem('xToken', response.data.token);

							if (localStorage.getItem('xToken')) {
								if (this.$route.params.nextUrl != null) {
									this.$router.push(this.$route.params.nextUrl)
								} else {
									switch(response.data.user.product) {
										case 'iiser':
											if(response.data.user.loginType === 'admin')   return this.$router.push('/IISER/Admin');
											if(response.data.user.loginType === 'vendor')   return this.$router.push('/IISER/Vendor/Menu');
											break;
										case 'facex':
											if(response.data.user.loginType === 'admin')   return this.$router.push('/FaceX/Users');
											break;
										case 'company':
											if(response.data.user.loginType === 'company')   return this.$router.push('/Company/');
											if(response.data.user.loginType === 'employee')   return this.$router.push('/Company/Employee');
											break;
										case 'event':
											switch(response.data.user.loginType) {
												case 'admin':
													return this.$router.push('/ViewAll');
													break;
												case 'event':
												case 'operator':
													if(response.data.user.id === 1356) return this.$router.push('/Pacha/Logs');
													return this.$router.push('/Analytics');
													break;
												case 'vendor':
													return this.$router.push('/Vendor/Analytics');
													break;
												case 'reports':
													return this.$router.push('/ViewAll');
													break;
												case 'data':
													return window.location.href = 'https://data-dev.atomx.in'
													break;
											}
											break;
										default:
											break;
									}
									if(response.data.user.other_id === 113)   return this.$router.push('/Analytics/' + response.data.user.other_id);
									if(response.data.user.other_id === 115)   return this.$router.push('/Analytics/' + response.data.user.other_id);

									if(response.data.user.loginType === 'operator')   {
										debugger;
										if(response.data.user.id === 1356) return this.$router.push('/Pacha/Logs');
										return this.$router.push('/Analytics');
									}
									if(response.data.user.loginType === 'iiser')   return this.$router.push('/IISER/');
									return this.$router.push('/Analytics/103')
								}
							} else {
								this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
								this.isLoading.login = false;
							}
						} else {
							this.runToast('', 'top-right', 'danger', "<b>INVALID CREDENTIALS</b><br>Please try again.", "fas fa-bomb fa-4x");
							this.isLoading.login = false;

						}
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.login = false;
					});
			},
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			usernameChange(username) {
				if( isNaN(username) || (username.search('armourx.') === 0) || (username.search('employee.') === 0) || (username.search('company.') === 0) )
					this.loginTypeChange(this.login_types[1]);
			},
			loginTypeChange(selected) {
				this.buttons.login = selected;
				this.currentLoginType = selected;
				this.buttons.loginColour = this.allTypes[selected].btnColour;
				switch(selected) {
					default:
						this.loginTypeChange('operator');
						break;
					case 'admin':
						this.buttons.btn1 = this.login_types[1];
						this.buttons.btn2 = this.login_types[2];
						break;
					case 'operator':
						this.buttons.loginColour = this.allTypes[selected].btnColour
						this.buttons.btn1 = this.login_types[0];
						this.buttons.btn2 = this.login_types[2];
						break;
					case 'attendance':
						this.buttons.loginColour = this.allTypes[selected].btnColour
						this.buttons.btn1 = this.login_types[0];
						this.buttons.btn2 = this.login_types[1];
						break;
				}
			}
		},
		setup() {
			// function

			const schema = Yup.object().shape({
				username: Yup.string().required().label("The Username"),
				password: Yup.string().required().label("The Password"),
			});

			return {
				// onSubmit,
				schema,
			};
		},
		watch: {
			// currentLoginType(to, from) {
			// 	debugger;
			// 	this.usernameChange(to);
			// }
		}
	};
</script>
<style>
	/*.navbar-nav .nav-item p {*/
		/*line-height: inherit;*/
		/*margin-left: 5px;*/
	/*}*/
	.btn:disabled, .btn[disabled], .btn.disabled {
		opacity: .75 !important;
		cursor: not-allowed;
	}
	img { border-radius: 0px !important; }
</style>
