<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
		<side-bar class="d-print-none" v-if="showSidebar">
			<template v-slot:links>
				<!--<user-menu></user-menu>-->

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item">
					<!--<template v-slot:links>-->
						<sidebar-item v-for="child in item.children" :key="child.name" :link="child"/>
					<!--</template>-->
				</sidebar-item>

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item"/>

			</template>
		</side-bar>
		<div class="main-panel w-print-100" :class="showSidebar ? '' : 'w-100'">
			<top-navbar :pageHeading="pageHeading"/>
			<router-view name="header" :info="info" />

			<div
				:class="{ content: !$route.meta.hideContent }"
				@click="toggleSidebar"
			>
				<router-view @summary-fetched="summaryFetched" @data-fetched="dataFetched"
					 :info="info"
					 :user="user"
				/>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"/>
		</div>
	</div>
</template>
<script>
	import TopNavbar from "./CompanyTopNavbar";
	import ContentFooter from "../ContentFooter.vue";
	import UserMenu from "../extra/UserMenu.vue";
	import SidebarItem from "../../../../components/SidebarPlugin/SidebarItem";

	export default {
		components: {
			SidebarItem,
			TopNavbar,
			ContentFooter,
			UserMenu,
		},
		data() {
			return {
				sidebarItems: [],
				pageHeading: 'Dashboard',
				info: { billAmount: 0, paidAmount: 0 },
				data: {  },
				user: {},
				showSidebar: true,
			};
		},
		methods: {
			summaryFetched(info) { this.info = info; console.log(info); },
			dataFetched(data) { this.data = data; this.pageHeading = data.name },
			updateSidebarItems(userType = 'all') {
				switch (userType) {
					case 'all':
						if(!localStorage.getItem('xToken') || typeof localStorage.getItem('xToken') === "undefined" || !localStorage.getItem('user')) {
							this.$router.push('/Login')
						} else {
							this.user = JSON.parse(localStorage.getItem('user'));
							this.sidebarItems = [];
							switch (this.user.loginType) {
								case 'company':
									this.sidebarItems.push({ name: 'Home', icon: 'now-ui-icons design_app', path: '/Company' });
									break;
								case 'employee':
									this.sidebarItems.push({ name: 'Home', icon: 'now-ui-icons design_app', path: '/Company/Employee' });
									// this.sidebarItems.push({ name: 'Bills', icon: 'fas fa-bezier-curve', path: '/Bills' });
									// this.sidebarItems.push({ name: 'Payments', icon: 'fas fa-piggy-bank', path: '/Payments' });
									// this.sidebarItems.push({
									// 	name: 'Event', icon: 'now-ui-icons education_atom',
									// 	children: [
									// 		{ name: 'Create', icon: 'now-ui-icons design_app', path: '/Create' },
									// 		{ name: 'View All', icon: 'now-ui-icons design_app', path: '/ViewAll' }
									// 	]
									// });
									break;

							}
							this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });
						}
						break;
				}

			},
			toggleSidebar() {
				if (this.$sidebar.showSidebar) {
					this.$sidebar.displaySidebar(false);
				}
			},
		},
		mounted() {
			this.updateSidebarItems();
			this.user = JSON.parse(localStorage.getItem('user'));
			if (!this.user || this.user.v < 2) this.$router.push('/Login');
		},
	};
</script>
<style lang="scss"></style>
