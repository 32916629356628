<template>
	<div>
		<div class="row " >
			<div class="row">
				<Analytics
					:loading="isLoading.analytics"
					:analytics="analytics"
				/>
			</div>
			<div class="row">
				<div class="col-6"></div>
				<div class="col-6"></div>
			</div>
			<div class="row">

			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import Analytics from './Analytics';
	import { ElDivider, ElBadge } from 'element-plus';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			Analytics,
			ElDivider, ElBadge
		},
		inject: ['$baseURL', '$armourxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		data() {
			return {
				defaultInfo: {
					isLoading: { analytics: true, },
					analytics: { bills: 0, payments: 0, period: '' },
				},
				loginUser: {},
				analytics: { bills: 0, payments: 0, period: '' },
				isLoading: { analytics: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');

				switch(this.event.type) {
					case 'fnb':
					case 'event':
						this.components.vendors = true;
						this.components.stalls = true;
						this.components.eventDetails = true;
						break;
					case 'clg-access':
						break;
					case 'armourx':
						this.components.armourxLinks = true;
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			dataLoad() {
				switch( this.event.type ) {
					case 'fnb':
					case 'event':
						this.loadConfigData('Vendors');
						this.loadConfigData('Stalls');
						this.loadDevices();
						break;
					case 'armourx':
						this.loadConfigData('armourxLinks');
						break;
					case 'clg-access':
					default:
						break;
				}
			},
			loadDevices() {
				let u = this.loginUser, code = 0;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Devices/List/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.devices = response.data.devices;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});
			},
			loadDetails() {
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'admin':
						url = '/Employee/analyticsData/';
						code = u.id;
						break;
					case 'operator':
						url = '/Employee/analyticsData/';
						code = u.id;
						break;
					default:
						return this.$router.push('/login');
						break;
				}
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('analytics');
						this.analytics = response.data.analytics;
						this.isLoading.analytics = false;
						this.dataLoad();
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});
			},
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			// setTimeout(this.loadDetails, 500);
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				// setTimeout(this.loadDetails, 500);
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
