<template>
	<div>
<!--		<el-dialog width="60%" title="" v-model="uploadDialog">-->
			<el-form>
<!--				<v-container class="py-0 mt-4">-->

				<div class="row col-md-8 col-11 mx-auto mt-3">
					<span slot="label" class="col-md-4 col-12 text-md-right text-left my-auto">TYPE</span>
					<div class="col-12 col-md-8">
						<el-select class="select-primary " v-model="billData.type" @change="uploadFormOnChange" filterable allow-create placeholder="Select Type" autocomplete="off">
							<el-option v-for="type in [ 'travel', 'food', 'office', 'courier', 'bill', ]" :key="type" :label="type.toUpperCase()" :value="type"/>
						</el-select>
					</div>
				</div>

				<div class="row col-md-8 col-11 mx-auto mt-3">
					<h6 class="col-md-4 col-12 my-auto px-md-0 text-md-right text-left"><i class="now-ui-icons education_atom"></i> Amount</h6>
					<el-input class="col-12 col-md-8" placeholder="Amount" @change="uploadFormOnChange" prefix-icon="fas fa-monument" v-model="billData.amount"/>
				</div>

				<div class="row col-md-8 col-11 mx-auto mt-3">
					<span slot="label" class="col-md-4 col-12 text-md-right text-left my-auto">CURRENCY</span>
					<div class="col-12 col-md-8">
						<el-select class="select-primary " v-model="billData.currency" @change="uploadFormOnChange" filterable allow-create placeholder="Select Currency" autocomplete="off">
							<el-option v-for="currency in [ 'inr', 'usd', ]" :key="currency" :label="currency.toUpperCase()" :value="currency"/>
						</el-select>
					</div>
				</div>

				<div class="row col-md-8 col-11 mx-auto mt-3">
					<span slot="label" class="col-md-4 col-12 text-md-right text-left my-auto">EVENT</span>
					<div class="col-12 col-md-8">
						<el-select class="select-primary" v-model="billData.event" @change="uploadFormOnChange" filterable placeholder="Select Event" autocomplete="off" value-key="id">
							<el-option v-for="event in eventsList" :key="event.id" :label="event.id + ' | ' + event.name" :value="event"/>
						</el-select>
					</div>
					<small class="text-secondary mx-auto">Use <strong>'47'</strong> for Office</small>
				</div>

				<div class="row col-md-8 col-11 mx-auto mt-4">
					<h6 class="aml-text-teal-3 col-12 col-md-4 my-auto text-md-right text-left"><i class="fas fa-hourglass"></i> Date</h6>
					<div class="col-12 col-md-8 mt-3 my-auto">
						<el-date-picker
							v-model="billData.date_"
							:class="billDateError ? 'border-danger' : ''"
							type="date"
							@blur="billDateError = billData.date_ === null"
							@change="uploadDateSelect"
						/>
						<span class="el-form-item__error" v-if="billDateError">Date is Required</span>
					</div>
				</div>

				<div class="row col-md-8 col-11 mx-auto mt-3">
					<h6 class="col-md-4 col-12 my-auto px-md-0 text-md-right text-left"><i class="now-ui-icons education_atom"></i> Notes</h6>
					<el-input type="textarea" class="col-12 col-md-8" placeholder="Notes" @change="uploadFormOnChange" prefix-icon="fas fa-monument" v-model="billData.notes"/>
				</div>

<!--					</div>-->

<!--				</v-container>-->
				<div class="row col-md-8 col-11 mx-auto mt-3">
					<el-upload
						ref="upload"
						class="upload-demo mx-auto"
						action="" :limit="1" :auto-upload="false" accept="application/pdf,image/jpeg,image/png,image/jpg"
						:on-exceed="handleExceed" :on-change="handleOnChange" :on-remove="handleOnRemove"
					>
						<template #trigger>
							<el-button type="primary">select file</el-button>
						</template>
						<span class="d-none">asdsad</span>
					</el-upload>

					<div class="row col-12 text-center mt-5">
						<el-button :disabled="uploadDisabled" :loading="uploadLoading" class="mx-auto" type="warning" @click="uploadBill"><span><i class="fa fa-cloud-upload mr-3"/></span>Save</el-button>
					</div>
				</div>
			</el-form>
<!--		</el-dialog>-->
	</div>
</template>

<script>
	import UploadFile from "../../../../services/uploadFile";

	import {
		ElDivider, ElBadge, ElTable, ElTableColumn, ElDialog,
		ElForm, ElOption, ElButton, ElUpload, ElSelect, ElDatePicker,
	} from 'element-plus';
	import { useToast } from "vue-toastification";

	export default {
		components: {
			ElDivider, ElBadge, ElTable, ElTableColumn, ElDialog,
			ElForm, ElOption, ElButton, ElUpload, ElSelect, ElDatePicker,
		},
		inject: ['$formatDateTime'],
		props: [ 'personLoading', 'uploadDialog', 'eventsList', ],
		emits: [ 'bill-uploaded', ],

		data: () => ({
			loader: null,
			loading: false,
			rules: [ value => !value || value.size < 2000000 || 'File size should be less than 2 MB!', ],
			newBill: null,
			billData: {  },
			billDateError: false,
			billFile: [],
			uploadLoading: false,
			uploadDisabled: true,
		}),
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			uploadBill() {
				// debugger;
				// this.loader = 'loading';
				this.uploadLoading = true;

				// return console.log(this.billData);
				// this.$emit('bill-uploaded', this.billData);

				UploadFile.upload(this.billData)
					.then(result => {
						// console.log('then', result);
						// this.runToast('', 'top-center', 'danger', result.data.message, "fas fa-bomb fa-4x");
						// alert(result.data.message);
						if(result.data.success) {
							// debugger;
							this.$emit('bill-uploaded', result.data.bill);
							this.billData = {};

						}
						this.uploadLoading = false;
					}, error => {
						alert(error);
						console.log('error on upoad', error);
						this.uploadLoading = false;
						this.runToast('', 'top-center', 'danger', error, "fas fa-bomb fa-4x");
					});
			},
			handleExceed(files) {
				// upload.value.clearFiles();
				// upload.value.handleStart(files[0]);
				this.$refs['upload'].clearFiles();
				this.$refs['upload'].handleStart(files[0]);
				// console.log(files);
				// console.log(this.$refs['upload']);
			},
			handleOnChange(file) {
				// console.log("Bill selected", file);
				const reader = new FileReader();
				reader.readAsDataURL(file.raw);
				reader.onload = e => {
					if (!e.target.result) alert('File Error');
					this.billData.billFile = e.target.result;

					if (!this.billData.billFile) {
						this.loader = null;
						this.loading = null;
						return alert('Bill Decode Error.');
					}

					this.uploadFormOnChange();
					// console.log(this.billData.billFile);
				};
			},
			handleOnRemove(files) {
				this.billData.billFile = null;
			},
			uploadDateSelect() {
				this.billData.date = this.$formatDateTime(this.billData.date_, false);
				console.log(this.billData.date_, this.billData.date);
				this.uploadFormOnChange();
			},
			uploadFormOnChange(a) {
				this.uploadDisabled = false;
				if(!this.billData.type)	{
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.amount) {
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.currency) {
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.event) {
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.date) {
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.notes) {
					// debugger;
					return this.uploadDisabled = true;
				}
				if(!this.billData.billFile) {
					// debugger;
					return this.uploadDisabled = true;
				}
				// console.log(this.billData);
			}

		},
	}
</script>
