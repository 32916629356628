<style>
td {
	min-width: 100px;
	padding-left: 3px;
}

.selectedList {
	background-color: rgb(210, 207, 207);
}

@media (max-width: 768px) {
	.el-dialog-fullwidth {
		width: 100% !important;
	}
}

@media (max-width: 600px) {
	.text-responsive {
		font-size: 12px;
	}
}
</style>

<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">DATA LIST ( {{ list.length }} )</h5>
			<div class="px-0" style="top: 5px; right: 5px; position: absolute">
				<span class="col-3 text-right text-info cursor-pointer" @click="dataDialog = true"><i class="fa fa-plus" />
					ADD</span>
				<span class="badge badge-primary" v-if="isLoading.list">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body pre-scrollable" style="max-height: 75vh">
			<div class="row">
				<!--				<div class="col-12">-->
				<!--					<h5>LIST</h5>-->
				<!--				</div>-->
				<div class="col-12  pt-2 mb-1 border-top border-bottom cursor-pointer"
					:class="{ selectedList: this.details && data.id === this.details.id }" @click="selectData(data)"
					v-for="data in list">
					<h6 class="mb-1 d-inline">{{ getInfoName(data.info) }}</h6>
					<!-- <el-divider direction="vertical" /> -->
					<div class="mb-1 text-secondary">
						<!-- {{ displaydt(data.timestamp.createdAt) }} -->
						{{ displaydtrange(data.info.minTxnAt, data.info.maxTxnAt) }}
					</div>
				</div>
			</div>
		</div>

		<span class="aml-dialog">

			<el-dialog class="el-dialog-fullwidth" style="margin-top:1vh !important;"
				:title="'CREATE DATA LOG FOR ' + event.name" v-model="dataDialog">
				<span class="mt-3 d-block">
					<el-form>
						<!-- Period Selection -->
						<div class="col-10 col-md-8 mx-auto bg-light p-0">
							<el-divider direction="horizontal"><i class="" /> Period Selection</el-divider>

							<span class="row mt-3 mx-auto">
								<h6 class="mb-0 aml-text-teal-3 col-3 my-auto text-right">
									<el-switch v-model="newData.settings.dates" active-color="#13ce66"
										@change="newData.settings.dates ? clearDay() : ''" inactive-color="#ccc"
										:active-value="true" :inactive-value="false" />
									<i class="far fa-clock ml-2" /> Dates
								</h6>
								<div class="col-9 mx-auto aml-report-date-filter">
									<el-date-picker v-model="newData.dates2" type="datetimerange" align="right"
										unlink-panels :disabled="!newData.settings.dates" range-separator="-"
										start-placeholder="Start" end-placeholder="End" :shortcuts="shortcuts"
										ref="dates" />
								</div>
							</span>

							<span class="row mt-3 mx-auto bg-light">
								<h6 class="mb-0 aml-text-teal-3 col-3 my-auto text-right">
									<el-switch v-model="newData.settings.day" active-color="#13ce66" @change="
										newData.settings.day
											? (newData.settings.dates = false)
											: (newData.day = []);
									$refs.day.focus();
									" inactive-color="#ccc" :active-value="true" :inactive-value="false" />
									<i class="far fa-clock ml-2" /> Days
								</h6>
								<el-select ref="day" multiple :multiple-limit="2" class="select-primary col-7 ml-2"
									v-model="filterDays" @change="dayChange()" :disabled="!newData.settings.day" filterable
									placeholder="Select Day">
									<el-option label="Current Day" :value="-1" />
									<el-option-group label="Days">
										<el-option v-for="(   day, index   ) in


	event.dayCloseLogs
		? event.dayCloseLogs.slice().reverse()
		: []


" :key="day.id" :label="day.name" :value="index" />
									</el-option-group>
								</el-select>
								<!--								<i class="fas fa-spinner fa-spin my-auto text-primary" v-if="isLoading"/>-->
							</span>
							<span class="row mt-3">
								<span class="ml-4">
									<el-button class="aml-bg-teal-3 border-0" type="primary" @click="loadData()"
										icon="fas fa-download" :loading="isLoading.load">
										LOAD
									</el-button>
								</span>
							</span>

							<el-divider />
						</div>

						<div class="">
							<!--LOADER-->
							<div class="position-relative h-100 w-100 bg-light" style="z-index: 1029" v-if="isLoading.load">
								<div class="custom-loading text-center position-relative" style="top: 30%">
									<svg class="circular" viewBox="-10, -10, 50, 50">
										<path class="path" d="
											M 30 15
											L 28 17
											M 25.61 25.61
											A 15 15, 0, 0, 1, 15 30
											A 15 15, 0, 1, 1, 27.99 7.5
											L 15 15
										  " style="stroke-width: 1px; fill: rgba(0, 0, 0, 0)" />
									</svg>
									<!--									<h5 class="text-primary">ALMOST THERE</h5>-->
								</div>
							</div>

							<!-- Data -->
							<div class="bg-light pb-2" v-if="Object.keys(dat).length > 0 && !isLoading.load">
								<el-divider class=""><i class="" />DATA</el-divider>

								<div class="row justify-content-center">
									<span class="badge aml-bg-gray">{{
										displaydt(dat.info.timestamp)
									}}</span>
									<span class="badge aml-bg-gray">EVENT: {{ dat.info.event.name }} |
										{{ dat.info.event.id }}</span>
									<span class="badge aml-bg-gray">DAY: {{ getInfoName(dat.info) }}</span>
									<span class="badge aml-bg-gray">{{
										dat.info.minTxnAt.replace("T", " ").replace(".000Z", "")
									}}
										to
										{{
											dat.info.maxTxnAt.replace("T", " ").replace(".000Z", "")
										}}</span>
								</div>
								<div class="table-responsive text-responsive">
									<table class="m-0 table-bordered table-hover">
										<tbody class="table-group-divider border-top-0">
											<tr class="table-secondary">
												<th scope="col" class="table-secondary font-weight-bold">
													TRANSACTIONS
												</th>
												<th scope="col" class="table-secondary font-weight-bold text-center"
													colspan="3">
													COUNT
												</th>
												<th scope="col" class="table-secondary font-weight-bold text-center"
													colspan="1">
													AMOUNT
												</th>
											</tr>

											<tr class="align-items-center" v-for="   type    in


	Object.keys(dat.summary).filter(
		(t) => t !== 'days' && t !== 'devices'
	)


">
												<td class="" v-if="typeof dat.summary[type].count !== 'undefined'">
													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('summary', type)" />
													<!--<input style="border: none; background-color: transparent"
													placeholder="Note" v-model="dat.summary[type].info" />-->
													<span class="pl-2 pr-sm-4 pr-2">{{ dat.summary[type].info
													}}</span>
													<i class="fas fa-plus-circle cursor-pointer my-auto"
														@click="addActivationShare(dat.summary[type].count)" v-if="type === 'activations' &&
															!dat.summary.activation_share
															" />
												</td>
												<td></td>
												<td class="" v-if="typeof dat.summary[type].count !== 'undefined'">
													<input style="border: none; width: 100%" type="number"
														placeholder="Count" v-model="dat.summary[type].count" />
												</td>
												<td></td>
												<td class="" v-if="typeof dat.summary[type].amount !== 'undefined'">
													<input type="number" style="border: none; width: 100%"
														placeholder="Amount" v-model="dat.summary[type].amount" />
												</td>
											</tr>
											<tr v-if="this.dat.summary.topups">
												<td class="">BREAKAGE</td>
												<td colspan="3"></td>
												<td class="">
													<input type="number" style="border: none; width: 100%"
														placeholder="Amount" v-model="dat.summary.breakage" />
												</td>
											</tr>
											<tr v-if="this.dat.summary.topup_comps ||
												this.dat.summary.sale_comps
												">
												<td class="">PAID BREAKAGE
												</td>
												<td colspan="3">
												</td>
												<td class="">
													<input type="number" style="border: none; width: 100%"
														placeholder="Amount" v-model="dat.summary.paid_breakage" />
												</td>
											</tr>
											<tr class="" v-for="   type    in


		Object.keys(dat.summary).filter(
			(t) => t == 'days' || t == 'devices'
		)
">
												<td class="" v-if="typeof dat.summary[type].count !== 'undefined'">
													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('summary', type)" />
													<!--<input style="border: none; background-color: transparent"
													placeholder="Note" v-model="dat.summary[type].info" />-->
													<span class="pl-2 pr-sm-4 pr-2">{{ dat.summary[type].info
													}}</span>
													<i class="fas fa-plus-circle cursor-pointer my-auto"
														@click="addActivationShare(dat.summary[type].count)" v-if="type === 'activations' &&
															!dat.summary.activation_share
															" />
												</td>
												<td></td>
												<td class="" colspan="1"
													v-if="typeof dat.summary[type].count !== 'undefined'">
													<input style="border: none; width: 100%" type="number"
														placeholder="Count" v-model="dat.summary[type].count" />
												</td>
												<td colspan="2"></td>
											</tr>
										</tbody>
										<tbody class="border-top-0">
											<tr class="">
												<td class="table-secondary font-weight-bold">
													AMOUNT WITH ATOMX
													<el-button icon="fas fa-money-bill-wave" class="aml-bg-teal-3 border-0"
														type="primary" size="small" circle v-if="dat.monies.cashless.CARD"
														@click="dat.monies.cashless.CASH_PENDING = {
															amount: 0,
															count: 0,
														}" />
													<el-button icon="fas fa-plus" class="aml-bg-teal-3 border-0"
														type="primary" size="small" circle
														@click="addItem('monies', 'cashless')" />
												</td>
												<td class="table-secondary font-weight-bold text-center" colspan="3">
													COUNT
												</td>
												<td class="table-secondary font-weight-bold">AMOUNT</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%"
											v-if="typeof dat.monies.cashless !== 'undefined'">
											<tr class="" v-for="   type    in    Object.keys(dat.monies.cashless)   ">
												<td class="">
													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('monies', 'cashless', type)" />
													<span v-if="!dat.monies.cashless[type].isNew"
														class="pl-2 pr-sm-4 pr-2">{{
															type
														}}</span>
													<input v-if="dat.monies.cashless[type].isNew"
														style="border: none; background-color: transparent"
														placeholder="NOTE" v-model="dat.monies.cashless[type].name" @change="checkItemName('monies',
															'cashless',
															type)" />
													<i class="fas fa-info-circle cursor-pointer"
														@click="openInfoBox(dat.monies.cashless[type])" />
													<el-input v-if="dat.monies.cashless[type].isDescOpen"
														v-model="dat.monies.cashless[type].desc" :rows="2" type="textarea"
														placeholder="Please input"
														@focusout="openInfoBox(dat.monies.cashless[type])" />
												</td>

												<td class="">
													<el-switch v-model="dat.monies.cashless[type].handover"
														active-color="#13ce66" inactive-color="#ccc" :active-value="true"
														:inactive-value="false" class="d-inline" active-text="HandOver"
														@change="updateMoniesTotal()" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" type="number"
														placeholder="Count" v-model="dat.monies.cashless[type].count" />
												</td>
												<td></td>
												<td class="">
													<input style="border: none; width: 100%" type="number"
														placeholder="Amount" v-model="dat.monies.cashless[type].amount"
														@change="updateMoniesTotal()" />
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%" v-if="typeof dat.monies.pos !== 'undefined'">
											<tr class="col-12 col-md-6"
												v-for="   type    in    Object.keys(dat.monies.pos)   ">
												<td class="">
													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('monies', 'cashless', type)" />
													<span v-if="!dat.monies.pos[type].isNew" class="pl-2 pr-sm-4 pr-2">POS
														{{
															type
														}}</span>
													<input v-if="dat.monies.pos[type].isNew"
														style="border: none; background-color: transparent"
														placeholder="NOTE" v-model="dat.monies.pos[type].name" @change="checkItemName('monies',
															'cashless',
															type)" />
													<i class="fas fa-info-circle cursor-pointer"
														@click="openInfoBox(dat.monies.pos[type])" />
													<el-input v-if="dat.monies.pos[type].isDescOpen"
														v-model="dat.monies.pos[type].desc" :rows="2" type="textarea"
														placeholder="Please input"
														@focusout="openInfoBox(dat.monies.pos[type])" />
												</td>
												<td class="">
													<el-switch v-model="dat.monies.pos[type].handover"
														active-color="#13ce66" inactive-color="#ccc" :active-value="true"
														:inactive-value="false" class="d-inline" active-text="HandOver"
														@change="updateMoniesTotal()" />
												</td>
												<td class=""><input style="border: none; width: 100%" type="number"
														placeholder="Count" v-model="dat.monies.pos[type].count" />
												</td>
												<td></td>
												<td class="" colspan="1">
													<input style="border: none; width: 100%" type="number"
														placeholder="Amount" v-model="dat.monies.pos[type].amount"
														@change="updateMoniesTotal()" />
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class=" font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">Sub-Total</td>

												<td class="">
													{{ dat.monies.moniesSubTotal.toLocaleString('en-IN') }}
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class="">
												<td class="">MDR Bank Deductions</td>
												<td class="">
													CardAmount<input style="border: none; width: 100%" type="number"
														@change="updateAmount2()" placeholder="cardAmount"
														v-model="dat.monies.mdr.cardAmount" />
												</td>
												<td class="">
													Rate<input style="border: none; width: 100%" type="number"
														@change="updateAmount2()" placeholder="rate"
														v-model="dat.monies.mdr.rate" />
												</td>
												<td class="">
													GST<input style="border: none; width: 100%" placeholder="GST(%)"
														v-model="dat.monies.mdr.gst" @change="updateAmount2()" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" placeholder="Amount" disabled
														v-model="dat.monies.mdr.amount" @change="updateMoniesTotal()" />
												</td>
											</tr>
										</tbody>

										<tbody class="" style="width: 100%">
											<tr class=" font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">Total</td>

												<td class="">
													{{ dat.monies.moniesTotal.toLocaleString('en-IN') }}
												</td>
											</tr>
										</tbody>
										<tbody class="border-top-0">
											<tr class="">
												<td class="table-secondary font-weight-bold">
													INVOICE
													<el-button icon="fas fa-plus" class="aml-bg-teal-3 border-0"
														type="primary" size="small" circle
														@click="addItem('invoice', 'cashless')" />
												</td>
												<td class="table-secondary font-weight-bold">COUNT</td>
												<td class="table-secondary font-weight-bold">RATE</td>
												<td class="table-secondary font-weight-bold">GST</td>
												<td class="table-secondary font-weight-bold">AMOUNT</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class="" v-for="   type    in    Object.keys(dat.invoice)   ">
												<td class="">
													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('invoice', 'cashless', type)" />
													<span v-if="!dat.invoice[type].isNew"
														class="text-uppercase pl-2 pr-sm-4 pr-2">{{
															type
														}}</span>
													<input v-if="dat.invoice[type].isNew"
														style="border: none; background-color: transparent"
														placeholder="NOTE" v-model="dat.invoice[type].name"
														@change="checkItemName1('invoice', type)" />
													<i class="fas fa-info-circle cursor-pointer"
														@click="openInfoBox(dat.invoice[type])" />
													<el-input v-if="dat.invoice[type].isDescOpen"
														v-model="dat.invoice[type].desc" :rows="2" type="textarea"
														placeholder="Please input"
														@focusout="openInfoBox(dat.invoice[type])" />
												</td>

												<td class="">
													<input style="border: none; width: 100%" type="number"
														@change="updateAmount(type)" placeholder="Count"
														v-model="dat.invoice[type].count" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" type="number"
														@change="updateAmount(type)" placeholder="rate"
														v-model="dat.invoice[type].rate" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" placeholder="GST(%)"
														v-model="dat.invoice[type].gst" @change="updateAmount(type)" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" placeholder="Amount" disabled
														v-model="dat.invoice[type].amount" />
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class=" font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">Sub Total</td>
												<td class="">
													{{ getSubTotal().toLocaleString('en-IN') }}
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class="font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">GST</td>
												<td class="">
													{{ parseFloat(getGst().toFixed(2)).toLocaleString('en-IN', {
														currency: 'INR',
														minimumFractionDigits: 2,
														maximumFractionDigits: 2
													}).endsWith('.00') ?
														parseFloat(getGst().toFixed(2)).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}).replace('.00', '') :
														parseFloat(getGst().toFixed(2)).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}) }}
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class=" font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">Total</td>
												<td class="">
													{{ parseFloat(getInvoiceTotal()).toLocaleString('en-IN', {
														currency: 'INR',
														minimumFractionDigits: 2,
														maximumFractionDigits: 2
													}).endsWith('.00') ?
														parseFloat(getInvoiceTotal()).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}).replace('.00', '') :
														parseFloat(getInvoiceTotal()).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}) }}
												</td>
											</tr>
										</tbody>
										<tbody class="border-top-0">
											<tr class="">
												<td class="table-secondary font-weight-bold">
													ADD TDS
													<el-switch v-model="dat.tds.isActive" active-color="#13ce66"
														inactive-color="#ccc" :active-value="true" :inactive-value="false"
														class="d-inline" />
												</td>
												<td class="table-secondary font-weight-bold"></td>
												<td class="table-secondary font-weight-bold">PERCENT</td>
												<td class="table-secondary font-weight-bold"></td>
												<td class="table-secondary font-weight-bold">AMOUNT</td>
											</tr>
										</tbody>
										<tbody v-if="dat.tds.isActive" class="" style="width: 100%">
											<tr class="">
												<td class="">
												</td>
												<td class="">
												</td>
												<td class="">
													<input style="border: none; width: 100%" type="number"
														@change="updateTds()" placeholder="Percent"
														v-model="dat.tds.percent" />
												</td>
												<td class=""></td>
												<td class="">
													{{ dat.tds.amount.toLocaleString('en-IN') }}
												</td>
											</tr>
										</tbody>
										<tbody class="border-top-0">
											<tr class="">
												<td class="table-secondary font-weight-bold">
													EXTRA CHARGES
													<el-button icon="fas fa-plus" class="aml-bg-teal-3 border-0"
														type="primary" size="small" circle
														@click="addItem('extraCharges', 'cashless')" />
												</td>
												<td class="table-secondary font-weight-bold">COUNT</td>
												<td class="table-secondary font-weight-bold">RATE</td>
												<td class="table-secondary font-weight-bold"></td>
												<td class="table-secondary font-weight-bold">AMOUNT</td>
											</tr>
										</tbody>
										<tbody v-if="dat.extraCharges" class="" style="width: 100%">
											<tr class="" v-for="   type    in    Object.keys(dat.extraCharges)   ">
												<td class="">
													<!-- <i class="fas fa-times-circle cursor-pointer"
													@click="removeItem('extraCharges', 'cashless', type)" />
												<input style="border: none; background-color: transparent"
													placeholder="NOTE" v-model="type" /> -->

													<i class="fas fa-times-circle cursor-pointer"
														@click="removeItem('extraCharges', 'cashless', type)" />
													<span v-if="!dat.extraCharges[type].isNew"
														class="text-uppercase pl-2 pr-sm-4 pr-2">{{
															type
														}}</span>
													<input v-if="dat.extraCharges[type].isNew"
														style="border: none; background-color: transparent"
														placeholder="NOTE" v-model="dat.extraCharges[type].name"
														@change="checkItemName1('extraCharges', type)" />
													<i class="fas fa-info-circle cursor-pointer"
														@click="openInfoBox(dat.extraCharges[type])" />

													<el-input v-if="dat.extraCharges[type].isDescOpen"
														v-model="dat.extraCharges[type].desc" :rows="2" type="textarea"
														placeholder="Please input"
														@focusout="openInfoBox(dat.extraCharges[type])" />
												</td>

												<td class="">
													<input style="border: none; width: 100%" type="number"
														@change="updateAmount1(type)" placeholder="Count"
														v-model="dat.extraCharges[type].count" />
												</td>
												<td class="">
													<input style="border: none; width: 100%" type="number"
														@change="updateAmount1(type)" placeholder="rate"
														v-model="dat.extraCharges[type].rate" />
												</td>
												<td class=""></td>
												<td class="">
													<input style="border: none; width: 100%" placeholder="Amount"
														@change="updateExtraChargesTotal(type)"
														v-model="dat.extraCharges[type].amount" />
												</td>
											</tr>
										</tbody>
										<tbody class="border-top-0"
											v-if="dat.extraCharges && Object.keys(dat.extraCharges).length > 1">
											<tr>
												<td class="">
												</td>
												<td class=""></td>
												<td clas=""></td>
												<td class="">Total</td>
												<td class="">{{
													getExtraChargesTotal().toLocaleString('en-IN') }}
												</td>
											</tr>
										</tbody>
										<tbody class="" style="width: 100%">
											<tr class=" font-weight-bold">
												<td class=""></td>
												<td class=""></td>
												<td class=""></td>
												<td class="">Payable</td>
												<td class="">
													{{ parseFloat(getPayable()).toLocaleString('en-IN', {
														currency: 'INR',
														minimumFractionDigits: 2,
														maximumFractionDigits: 2
													}).endsWith('.00') ?
														parseFloat(getPayable()).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}).replace('.00', '') :
														parseFloat(getPayable()).toLocaleString('en-IN', {
															currency: 'INR',
															minimumFractionDigits: 2,
															maximumFractionDigits: 2
														}) }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<!--<div class="mt-2 pb-2 bg-white col-11 mx-auto" v-if="summaryHas('cashless')">
									<el-divider class="mb-2" content-position="left">
										<h6>Cashless Summary</h6>
									</el-divider>

									<div class="row justify-content-start">
										<div class="col-12 col-md-6" v-for="type in Object.keys(
											dat.summary
										).filter(
											(t) =>
												t !== 'days' &&
												t !== 'devices'
										)">
											<div v-if="typeof dat.summary[type] !==
												'undefined'
												" class="bg-light pb-2">
												<el-divider><small>{{
													type.toUpperCase()
												}}</small>
													<i class="fas fa-times-circle cursor-pointer" @click="
													removeItem(
														'summary',
														type
													)
														" /></el-divider>
												<div class="row mx-auto">
													<el-input class="col-10 ml-2" placeholder="Note" v-model="dat.summary[type]
														.info
														" />
													<i class="fas fa-plus-circle cursor-pointer my-auto" @click="
														addActivationShare(
															dat.summary[
																type
															].count
														)
														" v-if="type ===
		'activations' &&
		!dat.summary
			.activation_share
		" />
												</div>
												<div class="row mx-auto">
													<div class="col-md-7 col-6" v-if="typeof dat.summary[
														type
													].amount !==
														'undefined'
														">
														<p class="mb-0">
															AMOUNT
														</p>
														<el-input type="number" placeholder="Amount" v-model="dat.summary[
															type
														].amount
															" />
													</div>
													<div class="col-md-5 col-6" v-if="typeof dat.summary[
														type
													].count !==
														'undefined'
														">
														<p class="mb-0">
															COUNT
														</p>
														<el-input type="number" placeholder="Count" v-model="dat.summary[
															type
														].count
															" />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="row justify-content-start">
										<div class="col-12 col-md-6" v-for="type in ['devices', 'days']">
											<div v-if="typeof dat.summary[type] !==
												'undefined'
												" class="bg-light pb-2">
												<el-divider><small>{{
													type.toUpperCase()
												}}</small>
													<i class="fas fa-times-circle cursor-pointer" @click="
													removeItem(
														'summary',
														type
													)
														" /></el-divider>
												<div class="row mx-auto">
													<div class="col-md-7 col-6" v-if="typeof dat.summary[
														type
													].info !==
														'undefined'
														">
														<p class="mb-0">INFO</p>
														<el-input placeholder="Note" v-model="dat.summary[
															type
														].info
															" />
													</div>
													<div class="col-md-5 col-6" v-if="typeof dat.summary[
														type
													].count !==
														'undefined'
														">
														<p class="mb-0">
															COUNT
														</p>
														<el-input type="number" placeholder="Count" v-model="dat.summary[
															type
														].count
															" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="mt-2 pb-2 bg-white col-11 mx-auto">
									<el-divider class="mb-2" content-position="left">
										<h6>Monies</h6>
									</el-divider>

									<div v-for="moneyType in ['pos', 'cashless']">
										<div class="mt-2 pb-2 bg-light col-12" v-if="typeof dat.monies[moneyType] !==
											'undefined'
											">
											<el-divider content-position="left">
												<h6>
													{{
														moneyType.toUpperCase()
													}}
												</h6>
											</el-divider>

											<div class="my-2">
												<el-button class="border-0" icon="fas fa-plus" type="info" v-if="!dat.monies[moneyType].cash_pending" @click="dat.monies[moneyType].CASH_PENDING = {amount: 0,count: 0,}">
													CASH PENDING
												</el-button>
												<el-button class="border-0" icon="fas fa-plus" type="info" @click="
													addItem(
														'monies',
														'cashless'
													)
													">
													ITEM
												</el-button>
											</div>

											<div class="row justify-content-center">
												<div class="bg-white pb-2 col-12 col-md-5 mt-md-2 mx-auto" v-for="type in [
													'CASH',
													'CASH_PENDING',
													'COUPON',
													'COMP',
												]">
													<div v-if="dat.monies[
														moneyType
													][type]
														">
														<el-divider class="mb-md-3 mb-5">
															<small class="d-inline">{{
																type
															}}</small>
															<i class="fas fa-times-circle cursor-pointer d-inline mx-2"
																@click="
																removeItem(
																	'monies',
																	'cashless',
																	type
																)
																	" />
															<el-switch v-model="dat.monies[
																moneyType
															][type]
																.handover
																" active-color="#13ce66" inactive-color="#ccc" :active-value="true
																" :inactive-value="false
																" class="d-inline" active-text="Handed Over" />
														</el-divider>

														<div class="row mx-auto">
															<div class="col-lg-7 col-6">
																<p class="mb-0">
																	AMOUNT
																</p>
																<el-input type="number" placeholder="Amount" v-model="dat
																	.monies[
																	moneyType
																][type]
																	.amount
																	" />
															</div>
															<div class="col--5 col-6">
																<p class="mb-0">
																	COUNT
																</p>
																<el-input type="number" placeholder="Count" v-model="dat
																	.monies[
																	moneyType
																][type]
																	.count
																	" />
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="row justify-content-center">
												<div class="bg-white pb-2 col-12 col-md-5 mt-md-2 mx-auto" v-for="type in Object.keys(
													dat.monies[moneyType]
												).filter(
													(t) =>
														t !== 'CASH' &&
														t !==
														'CASH_PENDING' &&
														t !== 'COUPON' &&
														t !== 'COMP'
												)">
													<el-divider class="mb-md-3 mb-5">
														<small class="d-inline">{{ type }}</small>
														<i class="fas fa-times-circle cursor-pointer d-inline mx-2" @click="
															removeItem(
																'monies',
																'cashless',
																type
															)
															" />
														<el-switch v-model="dat.monies[
															moneyType
														][type].handover
															" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false
		" class="d-inline" active-text="Handed Over" />
													</el-divider>

													<div v-if="dat.monies[
														moneyType
													][type].isNew
														">
														<el-input placeholder="Name" v-model="dat.monies[
															moneyType
														][type].name
															" @change="
		checkItemName(
			'monies',
			'cashless',
			type
		)
		" />
													</div>
													<div class="row mx-auto">
														<div class="col-md-7 col-6">
															<p class="mb-0">
																AMOUNT
															</p>
															<el-input type="number" placeholder="Amount" v-model="dat.monies[
																moneyType
															][type]
																.amount
																" />
														</div>
														<div class="col-md-5 col-6">
															<p class="mb-0">
																COUNT
															</p>
															<el-input type="number" placeholder="Count" v-model="dat.monies[
																moneyType
															][type]
																.count
																" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="mt-2 pb-2 bg-light col-12"><el-divider content-position="left">
											<h6>MDR Bank Deductions
											</h6>
										</el-divider>
										<div class="row justify-content-start">
											<div class="bg-white pb-2 col-12 col-md-10 mt-md-2 mx-auto">
												<div>
													<el-divider class=" mb-md-3 mb-5">
														<small class="d-inline">
															MDR_AMOUNT
														</small>
													</el-divider>

													<div class="row mx-auto">
														<div class="col-lg-3 col-6">
															<p class="mb-0">
																CARD AMOUNT
															</p>
															<el-input type="number" @change="updateAmount2()"
																placeholder="cardAmount" v-model="dat
																	.monies.mdr.cardAmount
																	" />
														</div>
														<div class="col-lg-3 col-6">
															<p class="mb-0">
																RATE %
															</p>
															<el-input type="number" @change="updateAmount2()"
																placeholder="rate" v-model="dat
																	.monies.mdr.rate
																	" />
														</div>
														<div class="col-lg-2 col-4">
															<p class="mb-0">GST %</p>
															<el-input type="number" @change="updateAmount2()"
																placeholder="GST(%)" v-model="dat
																	.monies.mdr.gst
																	" />
														</div>
														<div class="col-lg-4 col-8">
															<p class="mb-0">
																AMOUNT
															</p>
															<el-input type="number" placeholder="Amount" disabled v-model="dat
																.monies.mdr.amount
																" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="mt-2 pb-2 bg-white col-12 mx-auto">
									<el-divider class="mb-2" content-position="left">
										<h6>INVOICE</h6>
									</el-divider>
									<div class="mt-4">
										<el-button class="border-0" icon="fas fa-plus" type="info" @click="
											addItem('invoice', 'cashless')
											">
											ITEM
										</el-button>
									</div>

									<div class="row justify-content-start">
										<div class="col-12 col-md-6" v-for="type in Object.keys(
											dat.invoice
										)">
											<div v-if="typeof dat.invoice[type] !==
												'undefined'
												" class="bg-light pb-2">
												<el-divider><small>{{
													type.toUpperCase()
												}}</small>
													<i class="fas fa-times-circle cursor-pointer" @click="
													removeItem(
														'invoice',
														'cashless',
														type
													)
														" /></el-divider>
												<div class="col-md-4 col-2 mx-auto" v-if="dat.invoice[type].isNew
													">
													<el-input placeholder="Name" v-model="dat.invoice[type]
														.name
														" @change="
		checkItemName1(
			'invoice',
			type
		)
		" />
												</div>
												<div class="row mx-auto">
													<div class="col-md-4 col-2" v-if="typeof dat.invoice[
														type
													].info !==
														'undefined'
														">
														<p class="mb-0">INFO</p>
														<el-input placeholder="Note" v-model="dat.invoice[
															type
														].info
															" />
													</div>
													<div class="col-lg-3 col-6" v-if="typeof dat.invoice[
														type
													].count !==
														'undefined'
														">
														<p class="mb-0">
															COUNT
														</p>
														<el-input type="number" @change="
															updateAmount(
																type
															)
															" placeholder="Count" v-model="dat.invoice[
		type
	].count
		" />
													</div>
													<div class="col-lg-3 col-6" v-if="typeof dat.invoice[
														type
													].rate !==
														'undefined'
														">
														<p class="mb-0">RATE</p>
														<el-input type="number" @change="
															updateAmount(
																type
															)
															" placeholder="Rate" v-model="dat.invoice[
		type
	].rate
		" />
													</div>
													<div class="col-lg-2 col-4" v-if="typeof dat.invoice[
														type
													].gst !==
														'undefined'
														">
														<p class="mb-0">GST</p>
														<el-input type="number" min="1" @change="
															updateAmount(
																type
															)
															" placeholder="GST(%)" v-model="dat.invoice[
		type
	].gst
		" />
													</div>
													<div class="col-lg-4 col-8">
														<p class="mb-0">
															AMOUNT
														</p>
														<el-input type="number" placeholder="Amount" disabled v-model="dat.invoice[
															type
														].amount
															" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="mt-2 pb-2 bg-white col-12 mx-auto">
									<el-divider class="mb-2" content-position="left">
										<h6>EXTRA CHARGES</h6>
									</el-divider>
									<div class="mt-4">
										<el-button class="border-0" icon="fas fa-plus" type="info" @click="
											addItem(
												'extraCharges',
												'cashless'
											)
											">
											ITEM
										</el-button>
									</div>
									<div class="row justify-content-start">
										<div class="col-12 col-md-6" v-for="type in Object.keys(
											dat.extraCharges
										)">
											<div v-if="typeof dat.extraCharges[
												type
											] !== 'undefined'
												" class="bg-light pb-2">
												<el-divider><small>{{
													type.toUpperCase()
												}}</small>
													<i class="fas fa-times-circle cursor-pointer" @click="
													removeItem(
														'extraCharges',
														'cashless',
														type
													)
														" /></el-divider>
												<div class="col-md-4 col-2 mx-auto" v-if="dat.extraCharges[type]
													.isNew
													">
													<el-input placeholder="Name" v-model="dat.extraCharges[
														type
													].name
														" @change="
		checkItemName1(
			'extraCharges',
			type
		)
		" />
												</div>
												<div class="row mx-auto">
													<div class="col-md-4 col-2" v-if="typeof dat
														.extraCharges[
														type
													].info !==
														'undefined'
														">
														<p class="mb-0">INFO</p>
														<el-input placeholder="Note" v-model="dat
															.extraCharges[
															type
														].info
															" />
													</div>
													<div class="col-lg-3 col-6" v-if="typeof dat
														.extraCharges[
														type
													].count !==
														'undefined'
														">
														<p class="mb-0">
															COUNT
														</p>
														<el-input type="number" @change="
															updateAmount1(
																type
															)
															" placeholder="Count" v-model="dat
		.extraCharges[
		type
	].count
		" />
													</div>
													<div class="col-lg-3 col-6" v-if="typeof dat
														.extraCharges[
														type
													].rate !==
														'undefined'
														">
														<p class="mb-0">RATE</p>
														<el-input type="number" @change="
															updateAmount1(
																type
															)
															" placeholder="Rate" v-model="dat
		.extraCharges[
		type
	].rate
		" />
													</div>
													<div class="col-lg-4 col-8">
														<p class="mb-0">
															AMOUNT
														</p>
														<el-input type="number" placeholder="Amount" disabled v-model="dat
															.extraCharges[
															type
														].amount
															" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>-->

								<el-divider />
							</div>
						</div>
					</el-form>
				</span>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dataDialog = false" v-if="!isLoading.createNew">Cancel</el-button>
						<el-button class="aml-bg-teal-3 border-0" type="primary" :loading="isLoading.createNew"
							@click="createLog" v-if="Object.keys(dat).length > 0">Confirm</el-button>
					</span>
				</template>
			</el-dialog>
		</span>
	</div>
</template>
<script>
import { Card } from "@/components";
import axios from "axios";
import {
	ElDivider,
	ElDialog,
	ElForm,
	ElFormItem,
	ElInput,
	ElSelect,
	ElOption,
	ElOptionGroup,
	ElDatePicker,
	ElSwitch,
	ElButton,
	ElMessage,
} from "element-plus";
import moment from "moment";

export default {
	components: {
		Card,
		ElDivider,
		ElDialog,
		ElForm,
		ElFormItem,
		ElInput,
		ElSelect,
		ElOption,
		ElOptionGroup,
		ElDatePicker,
		ElSwitch,
		ElButton,
		moment,
	},
	inject: ["$baseURL"],
	emits: ["newData", "dataSelected"],
	props: ["event", "list", "details"],
	data() {
		return {
			defaultInfo: {},
			loginUser: {},
			code: 0,
			isLoading: {
				dataDetails: true,
				list: false,
				createNew: false,
				load: false,
			},
			dataDialog: false,
			filterDays: [],
			newData: {
				day: [],
				dates: [],
				dates2: [],
				settings: { day: false, dates: false },
			},
			dat: {},
			dataIndex: {
				topup: "Cashless Topups",
				sale: "Cashless Redemptions",
				return: "Cashless Returns",
				activations: "Cashless Activations",
				no_nfc_sale: "POS Sales",

				monies: {
					cashless: {
						cash: 0,
						cash_handover: 0,
						cash_pending: 0,
						card: 0,
						upi: 0,
					},
					pos: {
						cash: 0,
						cash_handover: 0,
						cash_pending: 0,
						card: 0,
						upi: 0,
					},
				},
			},
			shortcuts: [
				{
					text: "Last week",
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end];
					})(),
				},
				{
					text: "Last month",
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end];
					})(),
				},
				{
					text: "Last 3 months",
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end];
					})(),
				},
			],
		};
	},
	methods: {
		setDefault(key = "all") {
			if (key !== "all")
				this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		getExtraChargesTotal() {
			if (!this.dat.extraCharges) {
				return 0;
			}
			const invoiceArray = Object.values(this.dat.extraCharges);
			return invoiceArray.reduce((acc, currentObject) => {
				if (currentObject.hasOwnProperty("amount")) {
					return acc + +currentObject["amount"];
				} else {
					return acc;
				}

			}, 0);
		},

		getMoniesSubTotal() {
			let total = 0;
			Object.keys(this.dat.monies).map((obj) => {
				Object.keys(this.dat.monies[obj]).map((obj1) => {

					if (this.dat.monies[obj][obj1].handover == false) {
						total = total + parseFloat(this.dat.monies[obj][obj1].amount);
					}
				});
			});
			this.moniesSubTotal = total;
			return this.moniesSubTotal;
		},
		getMoniesTotal() {
			this.moniesTotal = this.getMoniesSubTotal() - parseFloat(this.dat.monies.mdr.amount);
			return this.moniesTotal;
		},
		getSubTotal() {
			const invoiceArray = Object.values(this.dat.invoice);
			return invoiceArray.reduce((acc, currentObject) => {
				if (currentObject.hasOwnProperty("amount")) {
					return acc + +currentObject["amount"];
				} else {
					return acc;
				}
			}, 0);
		},
		getGst() {
			const invoiceArray = Object.values(this.dat.invoice);
			return invoiceArray.reduce((acc, currentObject) => {
				if (
					currentObject.hasOwnProperty("amount") &&
					currentObject.hasOwnProperty("gst")
				) {
					return acc + (+currentObject["amount"] * +currentObject["gst"]) / 100;
				} else {
					return acc;
				}
			}, 0);
		},
		getInvoiceTotal() {
			console.log("getInvoiceTotal")
			this.invoiceTotal = this.getGst() + this.getSubTotal();

			console.log(this.invoiceTotal.toFixed(2))
			return this.invoiceTotal.toFixed(2);
		},
		getPayable() {
			this.payable =
				this.moniesTotal - this.invoiceTotal - this.getExtraChargesTotal() + (this.dat.tds.isActive ? this.dat.tds.amount : 0);
			return this.payable;
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		dayChange() {
			switch (this.filterDays.length) {
				case 2:
					if (this.filterDays[0] > this.filterDays[1]) {
						let d = this.filterDays[0];
						this.filterDays[0] = this.filterDays[1];
						this.filterDays[1] = d;
					}
					break;
			}
		},
		displaydt(dt) {
			return moment(dt).isValid()
				? moment(dt).local().format("YYYY-MM-DD HH:mm:ss")
				: "";
		},
		displaydtrange(mindt, maxdt) {
			return moment(mindt).isValid() && moment(maxdt).isValid()
				? `${moment(mindt).local().format("YYYY-MM-DD")} to ${moment(maxdt).local().format("YYYY-MM-DD")}`
				: "";
		},
		clearDay() {
			this.newData.settings.day = false;
			this.filterDays = this.newData.day = [];
		},
		selectData(data) {
			this.$emit("dataSelected", data);
		},
		summaryHas(type) {
			switch (type) {
				case "cashless":
					return (
						this.dat &&
						this.dat.summary &&
						this.dat.summary &&
						(this.dat.summary.topup ||
							this.dat.summary.sale ||
							this.dat.summary.return ||
							this.dat.summary.activations)
					);
					break;
				case "pos":
					return (
						this.dat &&
						this.dat.summary &&
						this.dat.summary &&
						this.dat.summary.no_nfc_sale
					);
					break;
				default:
					return false;
					break;
			}
		},
		checkItemName1(for_, type) {
			if (this.dat[for_][type].name === "")
				return alert("Name incorrect for item");
			if (this.dat[for_][type].name.includes(" ")) {
				this.dat[for_][type].name = this.dat[for_][type].name.replace(/ /g, "");
				alert("Spaces Removed");
			}
			// debugger;
			if (
				typeof this.dat[for_][type][this.dat[for_][type].name] !==
				"undefined" &&
				this.dat[for_][type].name !== type
			) {
				return alert("Name exists, please change");
			} else {
				// let mode_ = this.dat[for_][type][mode];
				if (this.dat[for_][type].name !== type) {
					let name_ = this.dat[for_][type].name;
					this.dat[for_][name_] = this.dat[for_][type];
					delete this.dat[for_][type];
					delete this.dat[for_][name_].isNew;
					delete this.dat[for_][name_].name;
				} else {
					delete this.dat[for_][type].isNew;
					delete this.dat[for_][type].name;
				}
			}
		},
		checkItemName(for_, type, mode) {
			if (this.dat[for_][type][mode].name === "")
				return alert("Name incorrect for item");
			if (this.dat[for_][type][mode].name.includes(" ")) {
				this.dat[for_][type][mode].name = this.dat[for_][type][
					mode
				].name.replace(/ /g, "");
				alert("Spaces Removed");
			}
			// debugger;
			if (
				typeof this.dat[for_][type][this.dat[for_][type][mode].name] !==
				"undefined" &&
				this.dat[for_][type][mode].name !== mode
			) {
				return alert("Name exists, please change");
			} else {
				// let mode_ = this.dat[for_][type][mode];
				if (this.dat[for_][type][mode].name !== mode) {
					let name_ = this.dat[for_][type][mode].name;
					this.dat[for_][type][name_] = this.dat[for_][type][mode];
					delete this.dat[for_][type][mode];
					delete this.dat[for_][type][name_].isNew;
					delete this.dat[for_][type][name_].name;
				} else {
					delete this.dat[for_][type].isNew;
					delete this.dat[for_][type].name;
				}
			}
		},
		removeItem(for_, type, mode) {
			if (window.confirm("Are You Sure?")) {
				if (for_ === "summary") { return (delete this.dat.summary[type]) };
				if (for_ === "invoice") { return (delete this.dat.invoice[mode] && this.getInvoiceTotal() && this.updateInvoiceCalculations()) };
				if (for_ === "extraCharges") { return (delete this.dat.extraCharges[mode] && this.getExtraChargesTotal() && this.extraChargesTotal()) };
				if (for_ === "info") return delete this.dat.summary.info[type];
				else if (typeof this.dat[for_][type][mode] !== "undefined") { return (delete this.dat[for_][type][mode] && this.getMoniesTotal() && this.moniesTotal()) };
			}
		},
		openInfoBox(type) {
			type.isDescOpen = !type.isDescOpen;
		},
		addItem(for_, type, value = { amount: 0, count: 0 }) {
			switch (for_) {
				case "monies":
					this.dat[for_][type][
						"new" + (Object.keys(this.dat[for_][type]).length + 1)
					] = {
						amount: 0,
						count: 0,
						desc: "",
						isDescOpen: false,
						handover: false,
						isNew: true,
						name: "new" + (Object.keys(this.dat[for_][type]).length + 1),
					};
					break;
				case "invoice":
					this.dat[for_]["new" + (Object.keys(this.dat[for_]).length + 1)] = {
						amount: 0,
						count: 0,
						desc: "",
						isDescOpen: false,
						rate: 0,
						gst: 18,
						isNew: true,
						name: "new" + (Object.keys(this.dat[for_]).length + 1),
					};
					break;
				case "extraCharges":
					this.dat[for_]["new" + (Object.keys(this.dat[for_]).length + 1)] = {
						amount: 0,
						count: 0,
						desc: "",
						isDescOpen: false,
						rate: 0,
						isNew: true,
						name: "new" + (Object.keys(this.dat[for_]).length + 1),
					};
					break;
				case "summary":
					this.dat[for_][type] = value;
					break;
			}
		},
		addActivationShare(count) {
			this.dat.summary.activation_share = {
				amount: 0,
				count,
				info: "ACTIVATION SHARE ( " + count + " @" + this.event.cardFee + " )",
			};
		},
		getInfoName(info) {
			if (info.days.length > 0) {
				switch (info.days.length) {
					case 1:
						return info.days[0].name;
						break;
					case 2:
						return info.days[1].name + " to " + info.days[0].name;
						break;
				}
			} else if (info.dates.length > 0) {
				return `${moment(info.dates[0]).local().format("YYYY-MM-DD")} to ${moment(info.dates[0]).local().format("YYYY-MM-DD")}`;
			} else {
				return "Full Event";
			}
		},
		setDaysInfo() {
			this.filterDays.forEach((day, i) => {
				if (day === -1) this.dat.info.days.push({ id: 0, name: "Current Day" });
				else
					this.dat.info.days.push({
						id: this.event.dayCloseLogs.slice().reverse()[this.filterDays[i]].id,
						name: this.event.dayCloseLogs.slice().reverse()[this.filterDays[i]].name,
					});
			});
		},
		loadData() {
			// if ( _.isEmpty(this.filterDays)  )
			let event = {
				settings: this.newData.settings,
				days: this.filterDays,
				dates: this.newData.dates2,
				code: this.code,
				dayCloseLogs:
					typeof this.event.dayCloseLogs !== "undefined" &&
						this.event.dayCloseLogs.length > 0
						? this.event.dayCloseLogs.slice().reverse()
						: [],
				minTxnId: 0,
				maxTxnId: false, //default for current day ONLY
			};
			// return console.log("list-event", event);
			// debugger;
			if (this.newData.settings.day) {
				switch (event.days.length) {
					case 0:
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Days not selected</b><br>Please select atleast 1.",
							type: "danger",
							grouping: true,
							duration: 5000,
						});
						break;
					case 1:
						if (event.days[0] === -1) {
							if (event.dayCloseLogs.length > 0)
								event.minTxnId = event.dayCloseLogs[0].txnId;
						} else {
							event.maxTxnId = event.dayCloseLogs[event.days[0]].txnId;
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[0] + 1].txnId;
						}
						break;
					case 2:
						if (event.days[0] === -1) {
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[1] + 1].txnId;
						} else {
							event.maxTxnId = event.dayCloseLogs[event.days[0]].txnId;
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[1] + 1].txnId;
						}
						break;
					default:
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Invalid Parameters</b><br>Check Days Parameter.",
							type: "warning",
							grouping: true,
							duration: 5000,
						});
						break;
				}
			} else if (this.newData.settings.dates) {
				if (event.dates.length === 0)
					return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Days not selected</b><br>Select 1.",
						type: "warning",
						grouping: true,
						duration: 5000,
					});
			}
			// return console.log("list-event", event);

			this.isLoading.load = true;
			axios
				.post(this.$baseURL + "/Event/EventDayData/", event, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("xToken")}`,
					},
				})
				.then((response) => {
					// debugger;
					this.dat = {
						summary: {},
						monies: {},
						invoice: {},
						extraCharges: {},
						status: "active",
					};
					console.log(response.data);
					response.data.summary.forEach((summary) => {
						if (summary.count > 0)
							this.dat.summary[summary.type_] = {
								amount: summary.amount,
								count: summary.count,
								info:
									"TOTAL " +
									summary.type_.replace("_", " ").toUpperCase() +
									(summary.type_ === "activations"
										? " ( " + summary.count + " @" + this.event.cardFee + " )"
										: ""),
							};
						if (summary.type_ === "activations") {
							this.dat.invoice[summary.type_] = {
								count: summary.count,
								rate: this.event.cardFee,
								amount: summary.count * this.event.cardFee,
								gst: 18,
							};
						}
						// if (summary.mode === "activations") {
						// 	this.dat.invoice[summary.mode] = {
						// 		gst: 18,
						// 		count: summary.count,
						// 		rate: this.event.cardFee,
						// 		amount:
						// 			this.dat.invoice.activations.count *
						// 			this.dat.invoice.activations.rate,
						// 	};
						// }
					});

					// this.dat.summary.info = {};
					// response.data.info.forEach( (info) => {
					// 	this.dat.summary.info[info.info] = { count: info.count, notes: '' };
					// });

					this.dat.info = {
						createdAt: new Date().getTime(),
						event: { name: this.event.name, id: this.event.id, locationCity: this.event.locationCity },
						days: [],
						dates: this.newData.dates2,
						minTxnAt: response.data.info2[0].min ?? "",
						maxTxnAt: response.data.info2[0].max ?? "",
					};

					this.setDaysInfo();
					let card_total = 0;
					response.data.modeData.forEach((mode) => {
						if (mode.count > 0) {
							switch (mode.mode) {
								case "NFC":
								case "UPDATE":
									if (typeof this.dat.monies.cashless === "undefined")
										this.dat.monies.cashless = {};
									if (mode.type === "topup")
										if (
											mode.invoice == "BANK_CARD_FAIL" ||
											mode.invoice == "CARD"
										) {
											if (this.dat.monies.cashless["CARD"]) {
												const cardData = this.dat.monies.cashless["CARD"];
												this.dat.monies.cashless["CARD"] = {
													amount: mode.amount + cardData.amount,
													count: mode.count + cardData.count,
													rate: 0,
													desc: "",
													isDescOpen: false,
													handover:
														mode.invoice === "CASH" ||
														mode.invoice === "COUPON" ||
														mode.invoice === "COMP",
												};
											} else {
												this.dat.monies.cashless["CARD"] = {
													amount: mode.amount,
													count: mode.count,
													rate: 0,
													desc: "",
													isDescOpen: false,
													handover:
														mode.invoice === "CASH" ||
														mode.invoice === "COUPON" ||
														mode.invoice === "COMP",
												};
											}
										} else if (mode.invoice == "UPI_FAIL" ||
											mode.invoice == "UPI") {
											if (this.dat.monies.cashless["UPI"]) {
												const upiData = this.dat.monies.cashless["UPI"];
												this.dat.monies.cashless["UPI"] = {
													amount: mode.amount + upiData.amount,
													count: mode.count + upiData.count,
													rate: 0,
													desc: "",
													isDescOpen: false,
													handover:
														mode.invoice === "CASH" ||
														mode.invoice === "COUPON" ||
														mode.invoice === "COMP",
												};
											} else {
												this.dat.monies.cashless["UPI"] = {
													amount: mode.amount,
													count: mode.count,
													rate: 0,
													desc: "",
													isDescOpen: false,
													handover:
														mode.invoice === "CASH" ||
														mode.invoice === "COUPON" ||
														mode.invoice === "COMP",
												};
											}
										} else {
											this.dat.monies.cashless[mode.invoice] = {
												amount: mode.amount,
												count: mode.count,
												rate: 0,
												desc: "",
												isDescOpen: false,
												handover:
													mode.invoice === "CASH" ||
													mode.invoice === "COUPON" ||
													mode.invoice === "COMP",
											};
										}
									break;
								default:
									if (typeof this.dat.monies.cashless === "undefined")
										this.dat.monies.cashless = {};
									if (typeof this.dat.monies.pos === "undefined")
										this.dat.monies.pos = {};
									if (typeof this.dat.monies.pos[mode.mode] === "undefined") {
										this.dat.monies.pos[mode.mode] = {
											amount: mode.amount,
											count: mode.count,
											desc: "",
											isDescOpen: false,
											rate: 0,
											handover:
												mode.mode === "CASH" ||
												mode.mode === "COUPON" ||
												mode.mode === "COMP",
										};
									} else {
										this.dat.monies.pos[mode.mode].amount += mode.amount;
										this.dat.monies.pos[mode.mode].count += mode.count;
									}
									break;
							}
						}
					});
					this.dat.monies["mdr"] = {
						cardAmount: ((this.dat.monies.cashless && this.dat.monies.cashless.CARD) ? this.dat.monies.cashless.CARD.amount : 0) + ((this.dat.monies.pos && this.dat.monies.pos.CARD) ? this.dat.monies.pos.CARD.amount : 0),
						rate: 1.75,
						gst: 18,
						amount: (((((this.dat.monies.cashless && this.dat.monies.cashless.CARD ? this.dat.monies.cashless.CARD.amount : 0) + ((this.dat.monies.pos && this.dat.monies.pos.CARD) ? this.dat.monies.pos.CARD.amount : 0))
							* 1.75) / 100) * 1.18).toFixed(2),
					};
					if (this.dat.summary.topups) {
						if (this.dat.summary.topup_comps || this.dat.summary.sale_comps) {
							this.dat.summary.paid_breakage =
								parseInt(this.dat.summary.topups.amount) -
								(this.dat.summary.topup_comps != undefined
									? parseInt(this.dat.summary.topup_comps.amount)
									: 0) -
								parseInt(this.dat.summary.sales.amount) +
								(this.dat.summary.sale_comps != undefined
									? parseInt(this.dat.summary.sale_comps.amount)
									: 0) -
								(this.dat.summary.activations ? parseInt(this.dat.summary.activations.amount) : 0);
							this.dat.summary.breakage =
								parseInt(this.dat.summary.topups.amount) -
								parseInt(this.dat.summary.sales.amount) - (this.dat.summary.activations ? parseInt(this.dat.summary.activations.amount) : 0);
						} else {
							this.dat.summary.breakage =
								parseInt(this.dat.summary.topups.amount) -
								(this.dat.summary.topup_comps != undefined
									? parseInt(this.dat.summary.topup_comps.amount)
									: 0) -
								parseInt(this.dat.summary.sales.amount) +
								(this.dat.summary.sale_comps != undefined
									? parseInt(this.dat.summary.sale_comps.amount)
									: 0) -
								(this.dat.summary.activations ? parseInt(this.dat.summary.activations.amount) : 0);
						}
					}
					// invoice
					this.dat.invoice["manpower"] = {
						gst: 18,
						count: 0,
						rate: 0,
						amount: 0,
					};

					this.dat.invoice["licenceFee"] = {
						gst: 18,
						count: 0,
						rate: 0,
						amount: 0,
					};
					this.dat["tds"] = {
						isActive: false,
						percent: 10,
						amount: this.getSubTotal() * 0.10,
					};
					this.dat.extraChargesTotal = this.getExtraChargesTotal();
					this.dat.monies.moniesTotal = this.getMoniesTotal();
					this.dat.monies.moniesSubTotal = this.getMoniesSubTotal();
					this.dat.invoiceSubTotal = this.getSubTotal();
					this.dat.invoiceTotalGST = this.getGst().toFixed(2);
					this.dat.invoiceTotal = this.getInvoiceTotal();
					this.dat.payable = this.getPayable();
					this.isLoading.load = false;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: "danger",
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.load = false;
				});
		},
		updateAmount(type) {
			// console.log({ type });
			this.dat.invoice[type].amount =
				(this.dat.invoice[type].rate * this.dat.invoice[type].count).toFixed(2);
			this.updateInvoiceCalculations();
		},
		updateInvoiceCalculations() {
			this.updateInvoiceSubTotal();
			this.updateInvoiceTotalGST();
			this.updateInvoiceTotal();
			this.updatePayable();
		},
		updateTds() {
			this.dat.tds.amount = this.getSubTotal() * this.dat.tds.percent / 100
		},

		updateAmount1(type) {

			this.dat.extraCharges[type].amount =
				(this.dat.extraCharges[type].rate * this.dat.extraCharges[type].count).toFixed(2);
			this.updateExtraChargesTotal()

		},
		updateAmount2() {
			// console.log(this.dat.monies.mdr)
			this.dat.monies.mdr.amount = (
				((this.dat.monies.mdr.cardAmount * this.dat.monies.mdr.rate) / 100) *
				(1 + this.dat.monies.mdr.gst / 100)
			).toFixed(2);
			this.updateMoniesTotal();


		},
		updateMoniesTotal() {
			console.log("in update monies")
			this.dat.monies.moniesSubTotal = this.getMoniesSubTotal();
			this.dat.monies.moniesTotal = this.getMoniesTotal();
			this.updatePayable();
		},
		updateExtraChargesTotal() {
			// console.log("update extra charge total caling", this.getExtraChargesTotal())
			this.dat.extraChargesTotal = this.getExtraChargesTotal();
			this.updatePayable();
		},
		updateInvoiceSubTotal() {
			this.dat.invoiceSubTotal = this.getSubTotal();
			this.updateTds();

		},
		updateInvoiceTotalGST() {
			this.dat.invoiceTotalGST = this.getGst();
		},
		updateInvoiceTotal() {
			this.dat.invoiceTotal = this.getInvoiceTotal();
		},
		updatePayable() {
			console.log("dat", this.dat)
			this.dat.payable = this.getPayable();
		},
		createLog() {
			this.isLoading.createNew = true;
			let data = this.dat;

			console.log({ data });
			var isFormValid = true;
			Object.keys(data.invoice).map((key) => {
				if (data.invoice[key].amount <= 0) {
					isFormValid = false;
				}
			});
			Object.keys(data.extraCharges).map((key) => {
				if (data.extraCharges[key].amount <= 0) {
					isFormValid = false;
				}
			});
			Object.keys(data.monies).map((key) => {
				if (key !== "mdr") {
					if (data.monies[key].amount <= 0) {
						isFormValid = false;
					}
				} else {
					if (data.monies[key].amount < 0) {
						isFormValid = false;
					}
				}
			});

			Object.keys(data.invoice).map((key) => {

				if (data.invoice[key].amount <= 0) {
					isFormValid = false;
				}
			});

			if (isFormValid) {
				axios
					.post(this.$baseURL + "/Event/EventDataSave/", data, {
						headers: {
							Authorization: `Bearer ${localStorage.getItem("xToken")}`,
						},
					})



					.then((response) => {
						if (response.data.success) {
							this.dat = {};
							this.$emit("newData", response.data.log);
							this.$emit("dataSelected", response.data.log);
							this.dataDialog = false;
						}
						this.isLoading.createNew = false
					})
					.catch((error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: "danger",
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.update = false;
					});



				// .then((response) => {
				// 	if (response.data.success) {
				// 		this.$emit("newData", response.data.log);
				// 		this.dat = {};
				// 	}

				// 	this.isLoading.createNew = false;
				// })
				// .catch((error) => {
				// 	ElMessage({
				// 		showClose: true,
				// 		dangerouslyUseHTMLString: true,
				// 		message: "<b>Something went wrong</b><br>Please try again.",
				// 		type: "danger",
				// 		grouping: true,
				// 		duration: 5000,
				// 	});
				// 	console.log("err:", error);
				// 	this.isLoading.createNew = false;
				// });
			} else {
				this.isLoading.createNew = false;
				alert("Amount can't be zero");
			}
		},
	},
	mounted() {
		// setTimeout(this.loadDetails, 500);
		let u = JSON.parse(localStorage.getItem("user"));
		this.loginUser = u;
		if (u && Object.keys(u).length === 0 && u.constructor === Object)
			return this.$router.push("/login");
		// this.loadDetails();
		switch (u.loginType) {
			case "event":
			case "operator":
				this.code = u.id;
				break;
			case "admin":
			case "reports":
				this.code = this.$route.params.code;
				break;
			default:
				return;
				break;
		}
	},
	watch: {
		$route(to, from) {
			this.dataDialog = false;
			let u = JSON.parse(localStorage.getItem("user"));
			this.loginUser = u;
			if (u && Object.keys(u).length === 0 && u.constructor === Object)
				return this.$router.push("/login");
			// this.loadDetails();
			switch (u.loginType) {
				case "event":
				case "operator":
					this.code = u.id;
					break;
				case "admin":
					this.code = this.$route.params.code;
					break;
				default:
					return;
					break;
			}
		},
	},
};
</script>
