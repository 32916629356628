<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">VENDORS</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute; z-index: 1028">
				<span class="col-3 text-right text-info cursor-pointer" @click="vendorOpen({ type: 'fnb', password: '1234', rePrintPassword: new Date().getFullYear(), thankyouNote: 'Thank You for using AtomX POS', address: ' ', }, 'create')"><i class="fa fa-plus"/> ADD VENDOR</span>
				<span class="col-3 text-right text-info cursor-pointer d-none"><i class="fa fa-eye"/> PASSWORDS</span>
				<span class="badge badge-primary" v-if="isLoading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Vendor" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-12"
			          :data="vendors.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
			          :height="vendors.length > 3 ? '300' : '200'"
			>
				<el-table-column label="#" type="index"/>
				<el-table-column sortable label="NAME" prop="name"/>
				<el-table-column class="cursor-pointer" label="TYPE" align="center">
					<template #default="scope">
						<el-tag size="medium" effect="dark"
							:class="'aml-tag ' + 'aml-bg-tag-' + scope.row.type"
						>
							<span class="my-auto">{{ scope.row.type }}</span>
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="LOGIN CODE" prop="id" align="center" width="150"/>
				<el-table-column class="cursor-pointer" label="ITEMS" align="center" width="75">
					<template #default="scope">
						<router-link class="d-inline" :to="(loginUser.loginType !== 'vendor' ? ( '/VendorInventory/' + scope.row.id )  : ( '/VendorInventory' ) )">
							<i class="fas fa-utensils text-black-50"/>
						</router-link>
					</template>
				</el-table-column>
				<el-table-column class="cursor-pointer" label="LINK" align="center" width="75">
					<template #default="scope">
						<i class="fa fa-copy cursor-pointer" @click="copyLink(scope.row)"/>
					</template>
				</el-table-column>
				<el-table-column class="cursor-pointer" label="ADD STALL" align="center" width="125">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="openStallCreate(scope.row)"> <i class="fas fa-plus"/> </span>
					</template>
				</el-table-column>
				<el-table-column class="cursor-pointer" label="EDIT" align="center" width="80">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="vendorOpen(scope.row, 'edit')"> <i class="fas fa-edit"/> </span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<span class="aml-dialog">
		<el-dialog :title="'Create Stall for : ' + vendor.name" v-model="stallCreateDialog">
			<span class="mt-3 d-block">
				<el-form :model="stall">
			<el-form-item>
				<span class="row">
					<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">Stall Name</span>
					<el-input class="col-8" v-model="stall.name" autocomplete="off"/>
				</span>
			</el-form-item>

			<el-form-item>
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left">Type</span>
					<el-select class="select-aml-teal col-6" v-model="stall.type" filterable automatic-dropdown placeholder="Select Type">
						<el-option v-for="type in defaultStallTypes"
						           :key="type" :label="type.toUpperCase()" :value="type" @change="stallTypeChange"/>
					</el-select>
				</span>
			</el-form-item>

			<el-form-item>
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left">Payment Modes</span>
					<el-switch v-model="stall.payOption" id="stallCreate_paymentModes"
					           active-text="Accept All Modes" inactive-text="Only NFC"
					           active-color="#13ce66" inactive-color="#ccc"
					           :active-value="1" :inactive-value="0"
					/>
				</span>
			</el-form-item>

			<el-form-item v-if="stall.payOption === 1">
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Mode Options</span>
					<el-select class="select-aml-teal col-8" v-model="stall.payOptions" id="stallCreate_payOptions"
							   filterable placeholder="Select max 4 Options" @change="optchange()"
							   multiple :multiple-limit="4">
						<el-option v-for="format in defaultModeOptions"
								   :key="format.value" :label="format.label.toUpperCase()" :value="format.value"/>
					</el-select>
				</span>
			</el-form-item>

			<el-form-item v-if="stall.type!=='topup'">
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">GRN Mode</span>
					<el-switch v-model="stall.useGrn" id="stallCreate_useGrn"
							   active-color="#13ce66" inactive-color="#ccc"
							   :active-value="1" :inactive-value="0"/>
				</span>
			</el-form-item>

			<el-form-item v-if="stall.type==='sale'">
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Cash Disabled</span>
					<el-switch v-model="stall.cashDisabled" id="stallCreate_cashDisabled"
							   active-color="#13ce66" inactive-color="#ccc"
							   :active-value="1" :inactive-value="0"/>
				</span>
			</el-form-item>

			<el-form-item v-if="event.country==='india'">
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Bank Payment</span>
					<el-select class="select-aml-teal col-6" v-model="stall.bankOption" filterable automatic-dropdown placeholder="Select Bank Device Type">
						<el-option v-for="bank in defaultBanksTypes"
								   :key="bank.value" :label="bank.label.toUpperCase()" :value="bank.value"/>
					</el-select>
				</span>
			</el-form-item>

          <el-form-item class="col">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">KOT LAN</span>
							<el-switch v-model="stall.kotLan" id="stallCreate_kotLan"
                         active-color="#13ce66" inactive-color="#ccc"
                         :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>

          <el-form-item class="col">
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">MODE INFO MANDATORY</span>
							<el-switch v-model="stall.modeInfoMandatory" id="stallCreate_modeInfoMandatory"
                         active-color="#13ce66" inactive-color="#ccc"
                         :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>

			<el-form-item>
				<span class="row">
					<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Scan Mode</span>
					<el-select class="select-aml-teal col-6" v-model="stall.scanMode" automatic-dropdown placeholder="Select Scan Mode">
						<el-option v-for="mode in defaultScanModes"
								   :key="mode.value" :label="mode.label.toUpperCase()" :value="mode.value"/>
					</el-select>
				</span>
			</el-form-item>


			<span class="" v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45) )">
				<el-form-item class="col my-auto">
					<span class="row1">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Add Discount In Card</span>
						<el-switch v-model="stall.addDiscountInCard" id="stall_addDiscountInCard"
								   active-color="#13ce66" inactive-color="#ccc"
								   :active-value="1" :inactive-value="0"/>
					</span>
				</el-form-item>
			</span>

			<span class="" v-if="(loginUser.type === 'admin' && loginUser.id === 1480 ) || (loginUser.admin_id === 1480)">
				<el-divider class="">SMS</el-divider>

				<span class="row col-md-8 col-11 mx-auto">
					<el-form-item class="col my-auto">
						<span class="row1">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Send SMS</span>
							<el-switch v-model="stall.sendSms" id="stall_sendSms"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>

					<el-form-item v-if="stall.sendSms" class="col my-auto">
						<span class="row1">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">SMS Format</span>
							<el-select class="select-aml-teal col-8" v-model="stall.smsFormat" filterable placeholder="Select SMS Format">
								<el-option v-for="format in defaultSmsFormats"
										   :key="format.value" :label="format.label.toUpperCase()" :value="format.value"/>
							</el-select>
						</span>
					</el-form-item>
				</span>
			</span>

			<span class="">
				<el-divider class="">TapX Items</el-divider>
				<span class="row col-md-6 col-10 mx-auto">
					<el-form-item class="col">
						<span class="row1">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Show in TapX</span>
							<el-switch v-model="stall.showStall" id="stall_showStall"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>
					<el-form-item v-if="stall.showStall" class="col">
						<span class="row1">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Show Price</span>
							<el-switch v-model="stall.showPrice" id="stall_showPrice"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>

					<el-form-item v-if="stall.showStall && stall.showPrice" class="col">
						<span class="row1">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Add to Cart</span>
							<el-switch v-model="stall.cartAdd" id="stall_addToCard"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0"/>
						</span>
					</el-form-item>
				</span>
			</span>

			<span v-if="stall.type === 'accessx'">
				<el-divider class="">AccessX Settings</el-divider>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">NFC Settings</span>
						<el-select class="select-aml-teal col-6" v-model="stall.accessxSettings.nfcSettings" placeholder="Select NFC Setting"
								   filterable automatic-dropdown default-first-option allow-create >
							<el-option v-for="nfcsetting in ['off', 'logic', 'whitelist']"
							           :key="nfcsetting" :label="nfcsetting.toUpperCase()" :value="nfcsetting"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">QR Settings</span>
						<el-select class="select-aml-teal col-6" v-model="stall.accessxSettings.qrSettings" placeholder="Select QR Setting"
								   filterable automatic-dropdown default-first-option allow-create >
							<el-option v-for="qrsetting in ['off', 'logic', 'whitelist']"
							           :key="qrsetting" :label="qrsetting.toUpperCase()" :value="qrsetting"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">LOCATION ID</span>
						<el-input class="col-8" v-model="stall.accessxSettings.qrLogiclocationId" minlength="2" maxlength="2" autocomplete="off" />
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">EVENT / MATCH ID</span>
						<el-input class="col-8" v-model="stall.accessxSettings.qrLogicEventMatchId" minlength="4" maxlength="4" autocomplete="off" />
					</span>
				</el-form-item>

				<el-form-item class="col">
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">USE ONLINE TOPUPS</span>
						<el-switch v-model="stall.accessxSettings.useOnlineTopups" id="stallAccess_use_online_topups"
								   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					</span>
				</el-form-item>

				<el-form-item class="col">
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">USE IN-OUT LOGIC</span>
						<el-switch v-model="stall.accessxSettings.useInOut" id="stallAccess_use_useInOut"
								   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					</span>
				</el-form-item>

				<el-form-item class="col">
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">DETCH DETAILS</span>
						<el-switch v-model="stall.accessxSettings.useFetchDetails" id="stallAccess_use_fetch_details"
								   active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					</span>
				</el-form-item>

				<el-form-item class="col">
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">CHECK ONLINE UNIQUE</span>
						<el-switch v-model="stall.accessxSettings.checkOnlineUnique" id="stallAccess_check_online_unique"
								   active-color="#13ce66" inactive-color="#ccc"
								   :active-value="1" :inactive-value="0"/>
					</span>
				</el-form-item>

				<el-divider/>
			</span>

		</el-form>
			</span>
			<template #footer>
			    <span class="dialog-footer">
			      <el-button @click="stallCreateDialog = false" v-if="!stallLoading">Cancel</el-button>
			      <el-button type="primary" :loading="stallLoading" @click="createStall">Confirm</el-button>
			    </span>
			</template>
		</el-dialog>

		<el-dialog :title="vendor.id ? 'Edit Vendor' : 'Create Vendor'" v-model="vendorDialog">
			<span class="mt-3 d-block">
				<el-form :model="vendor">
					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-10 text-md-right text-left">Vendor Name</span>
							<el-input class="col-8" v-model="vendor.name" autocomplete="off"/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<span slot="label" class="col-md-3 col-12 text-md-right text-left">Type</span>
							<el-select class="select-aml-teal col-6" v-model="vendor.type" filterable automatic-dropdown placeholder="Select Type">
								<el-option v-for="type in defaultVendorTypes"
										   :key="type" :label="type.toUpperCase()" :value="type"/>
							</el-select>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Rev Share</span>
							<el-input-number class="col-8 col-md-4" v-model="vendor.revShare" :precision="2" controls-position="right" :max="100"/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">GSTIN</span>
							<el-input class="col-8 col-md-4" v-model="vendor.gstin" maxlength="20"/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">PAN</span>
							<el-input class="col-8 col-md-4" v-model="vendor.pan" maxlength="10"/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Service Charge</span>
							<el-input-number class="col-8 col-md-4" v-model="vendor.sc" :precision="2" controls-position="right" :max="100"/>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Service Charge TAX</span>
							<el-input-number class="col-8 col-md-4" v-model="vendor.scTax" :precision="2" controls-position="right" :max="100"/>
						</span>
					</el-form-item>

					<span class="row">
						<span class="col-md-4 col-12 text-md-right text-left">Show Print Details</span>
						<el-switch id="vendor_showPrint" v-model="vendor.showPrint" class="ml-3"
							   active-color="#13ce66" inactive-color="#ccc"
							   :active-value="true" :inactive-value="false" />
					</span>

					<div v-if="vendor.showPrint">
						<el-divider class=""><i class="fas fa-print"/> Print Details</el-divider>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Mobile</span>
							<el-input type="number" class="col-8" v-model="vendor.mobile" maxlength="15" show-word-limit autocomplete="off"/>
						</span>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Address</span>
							<el-input @input="checkString('address')" class="col-8" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="vendor.address" maxlength="128" show-word-limit autocomplete="off"/>
						</span>
						<span class="row mt-2">
							<span class="col-md-3 col-10 text-md-right text-left">Thank You Note</span>
							<el-input @input="checkString('thankyouNote')" class="col-8" type="textarea" :autosize="{ minRows: 3, maxRows:53}" v-model="vendor.thankyouNote" maxlength="128" show-word-limit autocomplete="off"/>
						</span>

						<el-form-item>
							<span class="row mt-2">
								<span class="col-md-3 col-12 text-md-right text-left">SAC</span>
								<el-switch id="vendor_sac" v-model="vendor.showSac" class="ml-3"
										   active-color="#13ce66" inactive-color="#ccc"
										   :active-value="1" :inactive-value="0" />
								<el-input class="col-6" v-if="vendor.showSac" v-model="vendor.sac" maxlength="10"/>
							</span>
						</el-form-item>

						<span class="row">
							<span class="col-md-3 col-12 text-md-right text-left">Auto Print</span>
							<el-switch id="vendor_autoPrint" v-model="vendor.autoPrint" class="ml-3"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0" />
						</span>

						<el-form-item>
							<span class="row mt-2">
								<span class="col-md-3 col-10 text-md-right text-left">Max Invoices</span>
								<el-input-number class="col-8 col-md-4" v-model="vendor.invoicePrintCount" controls-position="right"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row mt-2">
								<span class="col-md-3 col-12 text-md-right text-left">KOT</span>
								<el-switch id="vendor_kot" v-model="vendor.kotPrint" class="ml-3"
										   active-color="#13ce66" inactive-color="#ccc"
										   :active-value="1" :inactive-value="0" />
								<el-input-number v-if="vendor.kotPrint" class="col-6 col-md-4" v-model="vendor.kotPrintCount" controls-position="right"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-12 text-md-right text-left my-auto">Invoice Format</span>
								<el-switch v-model="vendor.printFormat" id="vendor_printFormat"
										   inactive-text="Default" active-text="Format2"
										   active-color="#13ce66" inactive-color="#66ce13"
										   :inactive-value="'format1'" :active-value="'format2'"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row mt-2">
								<span class="col-md-4 col-10 text-md-right text-left">Re-Print Password</span>
								<el-input class="col-8 col-md-4" v-model="vendor.rePrintPassword" show-password maxlength="10"/>
							</span>
						</el-form-item>

					</div>

					<el-divider class=""><i class="fas fa-tachometer-alt"/> Dashboard</el-divider>

					<el-form-item>
						<span class="row mt-2">
							<span class="col-md-4 col-10 text-md-right text-left">Dashboard Password</span>
							<el-input class="col-8 col-md-4" v-model="vendor.password" show-password maxlength="10"/>
						</span>
					</el-form-item>

				</el-form>
			</span>
			<template #footer>
			    <span class="dialog-footer">
			      <el-button @click="vendorDialog = false" v-if="!vendorLoading">Cancel</el-button>
			      <el-button type="primary" :loading="vendorLoading" @click="saveVendor">Confirm</el-button>
			    </span>
			</template>
		</el-dialog>
	</span>
</template>

<script>
import {
	ElTable, ElTableColumn, ElInput, ElButton,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElMessage, ElTag,
} from "element-plus";
import axios from 'axios';
import { copyText } from "vue3-clipboard";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider, ElTag,
		},
		props: [ 'vendors', 'isLoading', 'event' ],
		inject: [ '$baseURL', ],
		emits: [ 'stall-add' ],
		data() {
			return {
				search: '',
				stallCreateDialog: false,
				vendorDialog: false,
				stallLoading: false,
				vendorLoading: false,
				defaultStallTypes: [ 'topup', 'sale', 'accessx', 'inventory', 'stockmaster', 'tables' ],
				defaultVendorTypes: [ 'fnb', 'pass', 'ticket', 'sale', 'inventory', 'tables', 'accessx' ],
				defaultPayoutServices: [ 'razorpay', ],
				defaultBanks: [ 'none', 'mswipe', 'mswipe-wisepad', ],
				defaultBanksTypes: [
					{ label: 'MSWIPE', value: 'mswipe-wisepad' },
					{ label: 'SUNMIPAY', value: 'sunmiPay' },
					{ label: 'EZETAP', value: 'ezetap' },
          			{ label: 'MOSAMBEE', value: 'mosambee' },
					{ label: 'WORLDLINE', value: 'worldline' },
					{ label: 'Pinelabs', value: 'pinelabs' },
					{ label: 'AIRPAY', value: 'airpay' },
					{ label: 'NONE', value: 'none' },
				],
				defaultScanModes: [
					{ label: 'NONE', value: 'none' }, { label: 'MENU', value: 'menu' }, { label: 'TICKET', value: 'ticket' },
				],
				defaultSmsFormats: [
					// { label: 'SELFEE+TAPX', value: 'selfee_tapx' },
					{ label: 'ATOMX', value: 'default' },
				],
				defaultModeOptions: [
					{ label: 'CASH', value: 'cash' }, { label: 'CARD', value: 'card' },
					{ label: 'UPI', value: 'upi' },
				],
				vendor: { printFormat: 'format1', thankyouNote: 'Thank You for using AtomX POS', address: ' ' },
				vendorRow: { },
				stall: { name: '', type: 'sale', bankOption: 'none', scanMode: 'none', smsFormat: 'default', kotLan: 0, addDiscountInCard: 0, accessxSettings: { nfcSettings: 'logic', qrSettings: 'OFF', useOnlineTopups: 1, checkOnlineUnique: 0, useFetchDetails: 0, useInOut: 0 }, },
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				formLabelWidth: '200px',
				loginUser: {},
			}
		},
		methods: {
			base64_aml(v) { return btoa(v); },
			copyLink(vendor) {
				let link = 'https://dashboard.atomx.in/#/VendorLiveLink?v=' + this.base64_aml(vendor.id);
				let text = link + '\n\n' + vendor.name + ' | ' + vendor.id + ' | VENDOR LIVE LINK';
				copyText(link, undefined, (error, event) => {})
				// if(confirm("Do you want to open in new window?"))	window.open('https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id), '_blank');
				ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>LINK COPIED</b><br><br>Click <a href='" + link + "' target='_blank'>here</a> to open in new window.",
					type: 'success',
					grouping: true,
					duration: 5000,
				});
			},
			checkString(value) {
				// this.vendor[value] = this.vendor[value].replace(/[^\x00-\x7F]/g, '').replace(/\r?\n|\r/g, '')//.replace(/'/g, '').replace(/"/g, '');
			},
			stallTypeChange() {
				if(this.stall.type!=='sale') this.stall.useGrn = 0;
				if(this.stall.type==='inventory' || this.stall.type==='tables') this.stall.useGrn = 1;
			},
			optchange(v) { console.log(v) },
			openStallCreate( vendor ) {
				this.vendor = vendor;
				this.stall.vendorId = vendor.id;
				this.stall.name = vendor.name;
				this.stall.type = vendor.type === 'fnb' ? 'sale' : vendor.type;
				this.stallCreateDialog = true;
			},
			vendorOpen( vendor = { type: 'fnb', password: '1234', rePrintPassword: new Date().getFullYear(), }, operation = 'edit' ) {
				if(operation === 'create')	vendor.eventId = this.event.id;
				this.vendor = JSON.parse(JSON.stringify(vendor));
				this.vendorRow = vendor;
				this.vendorDialog = true;
			},
			createStall() {
				if( this.stall.name === '')  return console.log('');
				if( this.stall.type === '')  return console.log('');
				if( this.stall.bankOption === '')  return console.log('');
				if( this.stall.payOptions.length === 0)  return console.log('');
        try { this.stall.payOptions = this.stall.payOptions.join(','); } catch (e) {};

				this.stallLoading = true;
				axios.post(
					this.$baseURL + '/Stalls/Create/',
					this.stall,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.$emit('stall-add', response.data.stall[0]);
							this.stallCreateDialog = false;
						}
						this.stallLoading = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.stallLoading = false;
					});
			},
			saveVendor() {
				if( this.vendor.name === '')  return console.log('');
				delete this.vendor.showPrint;

				this.vendorLoading = true;
				// this.vendor.eventId = this.event.id;
				axios.post(
					this.$baseURL + '/Vendors/' + (this.vendor.id ? 'Edit' : 'Create'),
					this.vendor,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {

							if(this.vendor.id) for (const [key, value] of Object.entries(this.vendor)) { this.vendorRow[key] = value; }
							else this.vendors.push(response.data.vendor);

							this.vendorDialog = false;
						}
						this.vendorLoading = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.vendorLoading = false;
					});
			},
		},
		mounted() {
			this.loginUser = JSON.parse(localStorage.getItem('user'));
			if(this.loginUser.type === 'admin')	{
				if( this.loginUser.id === 1480 ) {
					this.defaultSmsFormats = [{ label: 'DEFAULT', value: 'default' },];
					this.stall.smsFormat = 'default';
				}
				this.defaultModeOptions.push({ label: 'COUPON', value: 'coupon' }) ;
				this.stall.bankOption = "mswipe-wisepad";
			} else if(this.loginUser.type === 'admin')	{
				this.defaultSmsFormats = [ { label: 'DEFAULT', value: 'default' }, ];
				this.stall.smsFormat = 'default';
			} else if(this.loginUser.type === 'admin' && this.loginUser.id === 1702)	{
				this.defaultSmsFormats = [ { label: 'SELFEE+TAPX', value: 'selfee_tapx' }, ];
				this.stall.smsFormat = 'selfee_tapx';
			}

			if(this.event.country === 'india') this.stall.payOptions = ['cash', 'card', 'upi'];
			else	this.stall.payOptions = ['cash', 'card', 'coupon'];
		},
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
</style>
