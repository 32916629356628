<template>
	<div class="card card-stats card-raised aml-dashboard-card">
		<div class="card-header">
			<h5 class="card-category">PERSO DEVICES ( {{ persoDevices.length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="col-3 text-right text-info cursor-pointer" @click="persoDeviceDialog = true"><i class="fa fa-plus"></i> ADD DEVICE</span>
				<span class="badge badge-primary" v-if="isLoading.persoDevices">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Device" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-11 aml-devices-table"
			          :data="persoDevices.filter( data => !search || data.device_mac.toLowerCase().includes(search.toLowerCase()) || data.device_type.toLowerCase().includes(search.toLowerCase()) || ('' + data.device).toLowerCase().includes(search.toLowerCase()) || data.status.toLowerCase().includes(search.toLowerCase()) )"
			          :height="persoDevices.length <= 5 ? '200' : persoDevices.length > 10 ? '500' : '300' "
			>
				<el-table-column label="#" type="index" width="50"/>
				<el-table-column sortable label="DEVICE" prop="device" align="center"/>
				<el-table-column sortable label="MAC" prop="device_mac" align="center"/>
				<!--<el-table-column sortable label="DEVICE" prop="devices_main_type" align="center"/>-->
				<el-table-column sortable label="TYPE" prop="device_type" align="center"/>
				<el-table-column sortable label="ADDED AT" prop="created_at" align="center">
					<template #default="scope">
						<span>{{ displaydt(scope.row.createdAt) }}</span>
					</template>
				</el-table-column>
				<el-table-column sortable label="STATUS" prop="status" align="center"/>
				<el-table-column label="ADD / REMOVE" align="center">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="persoDevice(scope.row, 'Update')" v-if="!pLoading">
							<i :class="scope.row.status === 'active' ? 'fas fa-times' : 'fas fa-check' "></i>
						</span>
						<span v-else><i class="fas fa-spinner fa-spin"/></span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<span class="aml-dialog">
		<el-dialog title="Add Perso Device" v-model="persoDeviceDialog">
			<span class="mt-3 d-block">
				<el-form :model="device">

<!--					<el-form-item>-->
<!--						<span class="row aml-stall-devices">-->
<!--							<span slot="label" class="col-md-3 col-12 text-right my-auto">Devices <small>( {{ device.devices.length }} )</small></span>-->
<!--							<span class="col-8">-->
<!--								<div class="card-body py-0" style="">-->
<!--									<Multiselect-->
<!--										v-model="device.devices"-->
<!--										:searchable="true" :multiple="false"-->
<!--										mode="tags" label="device" track-by="device"-->
<!--										:close-on-select="false" :clear-on-select="false" :preserve-search="true"-->
<!--										:allow-empty="false" :options="persoList"-->
<!--										placeholder="Select Device" noOptionsText="Loading"-->
<!--									/>-->
<!--								</div>-->
<!--							</span>-->
<!--						</span>-->
<!--					</el-form-item>-->

					<el-form-item>
							<span class="row">
								<span slot="label"
									  class="col-md-3 col-12 text-md-right text-left my-auto">Devices<small> ( {{ device.devices.length }} )</small></span>
								<el-select class="select-aml-teal col-6 text-white pl-3 devices-search" v-model="device.devices" :loading="loadingd"
										   ref="device" multiple remote :remote-method="remoteDeviceMethod" reserve-keyword
										   filterable placeholder="Type 3 digits to Search">
									<el-option v-for="device in allDevices" :key="device.id" :label="device.device" :value="device.id">
										<span style="float: left">{{ device.device }}</span>
										<span style="float: right; color: #8492a6; font-size: 13px">{{ device.device_type }} | {{ device.device_mac }}</span>
									</el-option>
								</el-select>
							</span>
						</el-form-item>

				</el-form>
			</span>
			<template #footer>
			    <span class="dialog-footer">
			      <el-button @click="persoDeviceDialog = false" v-if="!pLoading">Cancel</el-button>
			      <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="pLoading" @click="persoDevice(this.device, 'Add')">Confirm</el-button>
			    </span>
			</template>
		</el-dialog>
	</span>

</template>

<script>
import {
	ElTable, ElTableColumn, ElInput, ElButton,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElDatePicker, ElOptionGroup, ElMessage
} from "element-plus";
	import axios from 'axios';

	import moment from "moment";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption, ElOptionGroup,
			ElSwitch, ElDivider, ElDatePicker,
			moment,
		},
		props: [ 'event', ],
		inject: [ '$baseURL', '$isCheckEventType', ],
		emits: [  ],
		data() {
			return {
				search: '',
				reportLoading: false,
				defaultTypes: [ { value: 'summary', label: 'Summary' }, { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' }, { value: 'all_txns', label: 'BASIC TRANSACTION DUMP' }, { value: 'balances', label: 'Remaining Balances' }, { value: 'partials', label: 'Partial Transactions' }, ],
				defaultReports: [ ],
				device: { devices: [] },
				devices: [],
				defaultInfo: { persoDevices: [], persoList: [], },
				persoDevices: [],
				persoList: [],
				allDevices: [],
				loadingd: false,
				isLoading: { persoDevices: false, persoList: false, },
				filter: { dates: [], report: null, type: '', settings: { details: false, summary: false, type: 'xlsx', dates: false, mobile: false, discount: false, sc: false, nfc: false, mode: false, bank: false, } },
				searchLoading: false,
				persoDeviceDialog: false,
				pLoading: false,
			}
		},
		mounted() {

		},
		methods: {
			remoteDeviceMethod(query) {
				if (query !== '' && query.length >=3) {
					this.loadingd = true;
					setTimeout(() => {
						this.loadingd = false;
						this.allDevices = this.persoList.filter(item => item.device.toString().includes(query.toLowerCase()));
					}, 200);
				} else {
					this.allDevices = [];
				}
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('YYYY-MM-DD HH:mm:ss') : ''; },
			persoDevice(pDevice, type) {
				let pDevices = [], pDev = [];
				if(type === 'Add')  {
					if(pDevice.devices.length === 0)    return;
					this.pLoading = true;
					pDevice.devices.forEach(d => {
						pDevices.push({ eventId: this.event.id, deviceId: d, });
						pDev.push({ device: 1, id: d, status: 'active' });
					});
					pDevice.devices_ = pDevices;
				}
				axios.post(
					this.$baseURL + '/Devices/Perso/' + type,
					pDevice,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							// pDevice.status = response.data.device.status;
							// console.log(this.persoDevices.length);
							if( type === 'Update' ) pDevice.status = response.data.device.status;
							else if( type === 'Add' ) {
								// console.log('idhar aaya');
								this.persoDevices = this.persoDevices.concat(pDev);
								this.persoDeviceDialog = false;
							}
							console.log(this.persoDevices.length);
						}
						this.pLoading = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.pLoading = false;
					});
			},
			updatePerso(pDevice) {
				this.pLoading = true;
				axios.post(
					this.$baseURL + '/Devices/Perso/Update',
					{ id: pDevice.id, status: pDevice.status },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						pDevice.status = response.data.device.status;
					}
					this.pLoading = false;
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.pLoading = false;
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			getDeviceList(dataType) {
				if(dataType === 'perso-wise') this.isLoading.persoDevices = true;
				else if(dataType === 'perso-all-wise') {
					this.isLoading.persoList = true;
					this.loadingd = true;
				}

				axios.get(
					this.$baseURL + '/Devices/List/',
					{ params: { code: this.event.id, type: dataType },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(dataType === 'perso-wise') {
							this.setDefault('persoDevices');
							this.persoDevices = response.data.devices;
							this.isLoading.persoDevices = false;
						}else if(dataType === 'perso-all-wise') {
							this.setDefault('persoList');
							this.persoList = response.data.devices;
							// console.log("persoList", this.persoList);
							this.isLoading.persoList = false;
							this.loadingd = false;
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						if(dataType === 'perso-wise') this.isLoading.persoDevices = false;
						else if(dataType === 'perso-all-wise') this.isLoading.persoList = false;
					});
			},
		},
		watch: {
			event(newEvent, from) {
				if(this.$isCheckEventType('fnb', newEvent.type)) {
					this.getDeviceList('perso-wise');
					this.getDeviceList('perso-all-wise');
				}
			},
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
	.aml-report-date-filter .el-date-editor .el-range-input { width: 80% !important; }
	.aml-devices-table.el-table table > thead > tr > th { font-size: 12px; }
</style>
