<template>
	<div>
		<div class="row " >
			<div class="mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-body">
						<div class="my-4">
							<el-input placeholder="Search" prefix-icon="el-icon-search" v-model="search"/>
							<small class="aml-text-gray-2 mt-1 mb-3">Search by CARD ID / QR </small> <br>
							<el-button class="aml-bg-teal-3 border-0 my-auto mt-2" type="primary"
									   :disabled="this.search.toString().length <=2"
									   :loading="isLoading.users" @click="accessxLogsLoad">Search</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 row mx-auto">
				<el-card shadow="always" class="my-1 mx-auto col-12">
					<div class="row">
						<h6>ACCESSX LOGS | <u>{{ cardAccessx }}</u></h6>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.users">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
						<el-table class="w-100 aml-font-11 aml-devices-table" :hover="true" :data="userAccessxLogs" >
							<el-table-column label="#" type="index" width="40"/>
							<el-table-column label="CARD ID" sortable prop="card_id"/>
							<el-table-column label="FACTORY ID" sortable prop="factory_id"/>
							<el-table-column label="QR" sortable prop="qr"/>
							<el-table-column label="CATEGORY" sortable prop="category_name"/>
							<el-table-column label="GATE" sortable prop="gate_name"/>
							<el-table-column label="DEVICE" sortable prop="device_print_id"/>
							<!--									<el-table-column label="FACTORY ID" sortable prop="type"/>-->
							<el-table-column label="DIRECTION" sortable prop="direction"/>
							<el-table-column label="MODE" sortable prop="mode"/>
							<!--									<el-table-column label="FACTORY ID" sortable prop="validate_type"/>-->
							<el-table-column label="TIMESTAMP" sortable prop="timestamp">
								<template #default="scope">
									<span>{{ displaydt(scope.row.timestamp) }}</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
			</div>
		</div>
		<div class="row">
			<div class="col-12 row mx-auto">
				<el-card shadow="always" class="my-1 mx-auto col-12">
					<div class="row">
						<h6>MEAL LOGS</h6>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="isLoading.users">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
							<span class="">
								<el-button class="mr-2" size="small" plain @click="downloadMealReport()"> Download</el-button>
							</span>
						</div>

						<div class="table-responsive">
							<table class="table aml-font-11 ">
								<thead class="text-primary">
									<th class="text-left">PERSON</th>
									<th class="text-left">BOOKING ID</th>
									<th class="text-left">TEAM</th>
									<th class="text-center" colspan="1" v-for="gate in gates" :key="gate.gateId">{{ gate.gateName }}</th>
								</thead>
								<tbody>
								<tr v-for="(person, personId) in whitelist" :key="personId">
									<td class="text-left"> {{ person.name }} </td>
									<td>{{ person.bookingId }}</td>
									<td>{{ person.reference }}</td>
<!--									<span v-for="gate in gates" :key="gate.gateName">-->
<!--										<td>{{ ( person.categories && person.categories[gate.gateName.trim()] ) ? 'opt' : '' }}</td>-->
<!--										<td>{{ ( person.entries && person.entries[gate.gateName] ) ? 'used' : '' }}</td>-->
<!--									</span>-->
									<td v-for="gate in gates" :key="gate.gateName">
										{{ ( person.categories && person.categories[gate.gateName.trim()] ) ? 'opt' : '' }}
										| {{ ( person.entries && person.entries[gate.gateName] ) ? person.entries[gate.gateName].entry.length : '' }}
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import {
		ElDivider,
		ElBadge,
		ElMessage,
		ElImage,
		ElCard,
		ElInput,
		ElButton,
		ElTag,
		ElMessageBox,
		ElDialog, ElForm, ElFormItem, ElTable, ElTableColumn,
	} from "element-plus";
	import moment from "moment/moment";
	import { saveAs } from 'file-saver'
	import * as XLSX from 'xlsx';

	export default {
		components: {
			ElTable,
			ElFormItem,
			ElForm,
			ElDialog,
			ElTag,
			ElButton,
			ElInput,
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElImage, ElCard,
			moment,
			ElDivider, ElBadge, ElMessageBox, ElMessage,ElTableColumn,
			XLSX, saveAs,
		},
		inject: ['$baseURL', '$accessxURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					users: [],
					components: { users: true, },
					isLoading: { users: false, },
				},
				search: '',
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				users: [],
				users_: [],
				userLoading: false,
				u_: {},
				userDialog: false,
				userLogs: [],
				userLogsDialog: false,
				userAccessxLogs: [],
				cardAccessx: 0,

				whitelist: {},
				gates: [],
				tableData: [],

				components: { users: true, event: true, },
				isLoading: { users: false, event: true, },
			};
		},
		methods: {
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('DD-MM-YYYY HH:mm:ss') : ''; },
			downloadMealReport() {
				let excelData = [], summaryData = {}, sData = [];
				debugger;
				Promise.all(Object.keys(this.whitelist).map(personId => {
					let data_ = {
						name: this.whitelist[personId].name,
						bookingId: this.whitelist[personId].bookingId,
						reference: this.whitelist[personId].reference,
					}
					// console.log(this.whitelist);

					// debugger;
					this.gates.forEach(gate =>{
						if( typeof summaryData[gate.gateName] === 'undefined' ) {
							summaryData[gate.gateName] = { entries: 0 };
						}
						if( this.whitelist[personId].entries && this.whitelist[personId].entries[gate.gateName] && this.whitelist[personId].entries[gate.gateName].entry )
							summaryData[gate.gateName].entries += this.whitelist[personId].entries[gate.gateName].entry.length;

						data_[gate.gateName.trim() + '|OPT'] = ( this.whitelist[personId].categories && this.whitelist[personId].categories[gate.gateName.trim()] ) ? 'opt' : ''
						data_[gate.gateName + '|USED'] = ( this.whitelist[personId].entries && this.whitelist[personId].entries[gate.gateName] ) ? this.whitelist[personId].entries[gate.gateName].entry.length : '';
						// console.log(this.whitelist[personId].entries[gate.gateName].entry, this.whitelist[personId].entries[gate.gateName].entry.length);
					})

					excelData.push(data_);
				}));
				console.log(excelData);


				Promise.all(Object.keys(summaryData).map(gate => {
					sData.push({ name: gate, allowed: summaryData[gate].entries });
				}));

				const workbook = XLSX.utils.book_new();
				const summary_worksheet = XLSX.utils.json_to_sheet(sData);
				XLSX.utils.book_append_sheet(workbook, summary_worksheet, "Summary");
				const dump_worksheet = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(workbook, dump_worksheet, "Sheet1");

				// Create the Excel binary
				const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

				// Save as Excel file
				const data = new Blob([excelBuffer], { type: "application/octet-stream" });
				saveAs(data, "meal_data_" + this.event.id + ".xlsx");
			},
			accessxLogsLoad() {
				// debugger;
				this.cardAccessx = this.search;
				this.userAccessxLogs = [];
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/AccessX/Logs/',
					{ params: { cardId: this.search, eventId: code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.userAccessxLogs = response.data.entryLogs.reverse();
							this.isLoading.users = false;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			mealLogsLoad() {
				// debugger;
				this.cardAccessx = this.search;
				this.userAccessxLogs = [];
				let u = this.loginUser, code = 0, url = '';
				this.isLoading.users = true;
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$accessxURL + '/CrewMeal/Details/',
					{ params: { code, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.whitelist = response.data.whitelist;
							this.gates = response.data.gates.reverse();
							console.log(response.data);
							this.isLoading.users = false;
						} else {
							ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000, });
						}
						this.isLoading.users = false;
						// this.$emit('loading-complete');
					})
					.catch( (error) => {
						ElMessage({ showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please try again.", type: 'danger', grouping: true, duration: 5000, });
						console.log("err:", error);
						this.isLoading.users = false;
					});
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('event');
						this.event = response.data.event;
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.$emit('loading-complete');
						// this.loadData();
						this.mealLogsLoad();
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
