<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">Filter</h5>
		</div>
		<div class="card-body">
			<el-form>
				<div class="row">
				<div class="col-10 mx-auto">

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right"><i class="now-ui-icons education_atom"></i> EVENT</h6>
						<h6 class="col-md-6 col-8 mx-auto">{{ event.name }}</h6>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.dates" @change="$refs.dates.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							Dates
						</h6>
						<div class="col-8 mx-auto aml-report-date-filter">
							<el-date-picker
								ref="dates"
								v-model="filter.dates2"
								type="datetimerange"
								align="right"
								unlink-panels
								:disabled="!filter.settings.dates"
								range-separator="-"
								start-placeholder="Start"
								end-placeholder="End"
								:shortcuts="shortcuts"
							/>
						</div>
					</span>

					<span class="row">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.type" @change="$refs.type.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							Type
						</h6>
						<el-select ref="type" class="col-6" :class="filter.settings.type ? 'select-aml-teal' : 'select-aml-disabled'" v-model="filter.type" :disabled="!filter.settings.type" automatic-dropdown default-first-option placeholder="Select Type">
							<el-option v-for="type in defaultTypes"
							           :key="type.value" :label="type.label.toUpperCase()" :value="type.value"/>
						</el-select>
					</span>

					<el-form-item>
						<span class="row">
							<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.status" @change="$refs.status.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							Status
						</h6>
							<el-select ref="status" class="col-6" :class="filter.settings.status ? 'select-aml-teal' : 'select-aml-disabled'" v-model="filter.status" :disabled="!filter.settings.status" automatic-dropdown default-first-option placeholder="Select Type">
								<el-option v-for="status in [ 'completed', 'qr_pending', 'bank_pending', 'void' ]"
								           :key="status" :label="status.toUpperCase()" :value="status"/>
							</el-select>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.vendor" @change="$refs.vendor.focus()"
							   active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"
							   :disabled="loginUser.loginType === 'vendor'"
							/>
							VENDORS
						</h6>
							<el-select ref="vendor" class="col-6"
									   :class="filter.settings.vendor ? 'select-aml-teal' : 'select-aml-disabled'" v-model="filter.vendor"
									   :disabled="!filter.settings.vendor || loginUser.loginType === 'vendor'"
									   filterable default-first-option automatic-dropdown placeholder="Select Vendor">
								<el-option v-for="vendor in vendors" :key="vendor.id" :label="vendor.name.toUpperCase()" :value="vendor.id"/>
							</el-select>
						</span>
					</el-form-item>

					<el-form-item>
						<span class="row">
							<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.stall" @change="$refs.stall.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							STALLS
						</h6>
							<el-select ref="stall" class="col-6" :class="filter.settings.stall ? 'select-aml-teal' : 'select-aml-disabled'" v-model="filter.stall" :disabled="!filter.settings.stall" filterable default-first-option automatic-dropdown placeholder="Select Stall">
								<el-option v-for="stall in stalls" :key="stall.id" :label="stall.name.toUpperCase()" :value="stall.id"/>
							</el-select>
						</span>
					</el-form-item>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.cardId" @change="$refs.cardId.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							CARD ID
						</h6>
						<div class="col-md-6 col-8 mx-auto ">
							<el-input ref="cardId" v-model="filter.cardId" placeholder="Card" :disabled="!filter.settings.cardId" autocomplete="off"/>
						</div>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.txnId" @change="$refs.txnId.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							TXN-ID
						</h6>
						<div class="col-md-6 col-8 mx-auto ">
							<el-input ref="txnId" v-model="filter.txnId" placeholder="TXN-ID" :disabled="!filter.settings.txnId" autocomplete="off"/>
						</div>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.receipt" @change="$refs.receipt.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							RECEIPT
						</h6>
						<div class="col-md-6 col-8 mx-auto ">
							<el-input ref="receipt" v-model="filter.receipt" placeholder="Receipt" :disabled="!filter.settings.receipt" autocomplete="off"/>
						</div>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.device" @change="$refs.device.focus()" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							DEVICE
						</h6>
						<div class="col-md-6 col-8 mx-auto ">
							<el-input ref="device" v-model="filter.device" placeholder="Device" :disabled="!filter.settings.device" autocomplete="off"/>
						</div>
					</span>

					<span class="row my-3 text-center">
						<el-button class="aml-bg-teal-3 border-0 mx-auto" type="primary" :loading="loading" icon="fas fa-search" v-on:click="search()"> Search</el-button>
					</span>

				</div>
			</div>
			</el-form>
		</div>
	</div>

</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElDatePicker, ElOptionGroup,
	} from "element-plus";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption, ElOptionGroup,
			ElSwitch, ElDivider, ElDatePicker,
		},
		props: [ 'event', 'loading'],
		inject: [ '$baseURL', ],
		emits: [ 'search-clicked', ],
		data() {
			return {
				reportLoading: false,
				defaultTypes: [ { value: 'sale', label: 'SALE' }, { value: 'topup', label: 'TOPUP' }, { value: 'return', label: 'RETURN' }, { value: 'reversal_topup', label: 'REVERSAL TOPUP' }, { value: 'reversal_sale', label: 'REVERSAL SALE' }, ],
				defaultReports: [ ],
				filter: { cardId: '', receipt: '', dates: [], dates2: [], type: '', status: '', device: '', txnId: '',
					settings: { event: true, details: false, cardId: false, receipt: false, dates: false, type: false, status: false, stall: false, vendor: false, device: false, txnId: false, } },
				searchLoading: false,
				loginUser: {},
				vendors: [],
				stalls: [],
				shortcuts: [{
					text: 'Last week',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
						return [start, end]
					})(),
				}, {
					text: 'Last month',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
						return [start, end]
					})(),
				}, {
					text: 'Last 3 months',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
						return [start, end]
					})(),
				}],
			}
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			search() {
				// console.log(this.filter);
				this.$emit('search-clicked', this.filter);
			},
			loadConfigData(dataType) {
				if(!dataType)   return;
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						url = this.$baseURL + '/' + dataType + '/event-wise/';
						break;
					case 'admin':
						code = this.$route.params.code;
						url = this.$baseURL + '/' + dataType + '/event-wise/';
						break;
					case 'vendor':
						code = u.id;
						url = this.$baseURL + '/' + dataType + '/vendor-wise/';
						break;
					default:
						return ;
						break;
				}

				// url = this.$baseURL + '/' + dataType + '/event-wise/';

				axios.get(
					url,
					{ params: { code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						// console.log(response);
						// this.setDefault(dataType.toLowerCase());
						this[dataType.toLowerCase()] = response.data[dataType.toLowerCase()];
						// this.isLoading[dataType.toLowerCase()] = false;

						// if(dataType === 'Vendors') {
						// 	this.setDefault('vendorsFilter');
						// 	response.data.vendors.forEach(vendor => { this.vendorsFilter.push({ text: vendor.name, value: vendor.name }) });
						// }
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						// this.isLoading[dataType.toLowerCase()] = false;
					});
			},
		},
		watch: {
			event(to, from) {
				// console.log('event updated');
				this.filter.event = to.id;
				if(this.loginUser.loginType !== 'vendor') this.loadConfigData('Vendors');
				else {
					this.vendors = [this.loginUser];
					this.filter.vendor = this.loginUser.id;
					this.filter.settings.vendor = true;
				}
				this.loadConfigData('Stalls');
			},
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
	.aml-report-date-filter .el-date-editor .el-range-input { width: 80% !important; }
</style>
