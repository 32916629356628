import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "../views/dashboard/layout/DashboardLayout.vue";
import VendorLayout from "../views/dashboard/Events/vendors/Layout";
import EmployeeLayout from "../views/dashboard/layout/company/Employees/EmployeeLayout";
import CompanyLayout from "../views/dashboard/layout/company/CompanyLayout";
import Layout from "../views/dashboard/layout/generic/Layout";
import AuthLayout from "../views/dashboard/pages/AuthLayout.vue";

// Page Headers
import DashboardHeader from "../views/dashboard/dashboard/DashboardHeader.vue";
import DefaultHeader from "../views/dashboard/headers/DefaultHeader";
import EmployeeHeader from "../views/dashboard/company/employee/Header";
import CompanyHeader from "../views/dashboard/company/employee/Header";
import IISERHeader from "../views/dashboard/iiser/admin/Header";
import FaceXHeader from "../views/dashboard/facex/Header";
import IISERVendorHeader from "../views/dashboard/iiser/vendor/Header";
import VendorHeader from "../views/dashboard/Events/vendors/Header";
// import SweetAlertHeader from "../views/dashboard/headers/SweetAlertHeader";
// import VectorMapsHeader from "../views/dashboard/headers/VectorMapsHeader";
// import CalendarHeader from "../views/dashboard/headers/CalendarHeader";

// GeneralViews
import NotFound from "../views/generalViews/NotFoundPage.vue";

// Dashboard pages
import Dashboard from "../views/dashboard/dashboard/Dashboard.vue";
import VendorDashboard from "../views/dashboard/Events/vendors/Dashboard";
import Config from "../views/dashboard/config/Config";
import Blocked from "../views/dashboard/blocked/Blocked";
import Freebies from "../views/dashboard/freebies/Freebies";
import Report from "../views/dashboard/reports/Index";
import Devices from "../views/dashboard/devices/Index";
import EventData from "../views/dashboard/event-data/Index";
import Payouts from "../views/dashboard/payouts/Index";
import DevicesMasterlist from "../views/dashboard/devices/masterlist/Index";
import Whitelist from "../views/dashboard/whitelist/Index";
import AccessXLogs from "../views/dashboard/accessx/Index.vue";
import PachaAccessXLogs from "../views/dashboard/accessx/PachaAccessxIndex.vue";
import PachaWhitelist from "../views/dashboard/whitelist/IndexPacha.vue";
import Whitelist2 from "../views/dashboard/whitelist/IndexWhitelist.vue";
import Volunteers from "../views/dashboard/volunteers/Index";
// import VolunteerMasterlist from "../views/dashboard/volunteers/masterlist/Index";
import Transactions from "../views/dashboard/transactions/Index";
import ViewAll from "../views/dashboard/Events/ViewAll";
import Create from "../views/dashboard/Events/Create";
import Menu from "../views/dashboard/Menu/Menu";
import Inventory from "../views/dashboard/Inventory/Index";
import VendorInventory from "../views/dashboard/vendor-stock/Index";
import TapXTransactions from "../views/dashboard/tapx-transactions/Index";

// ArmourX Views
import Uploads from "../views/dashboard/armourx/Uploads";

// Company Views
import CompanyDashboard from "../views/dashboard/company/Home/Dashboard";

// FaceX Views
// import FacexDashboard from "../views/dashboard/facex/Dashboard";
import FacexUsers from "../views/dashboard/facex/Users";
import FacexReport from "../views/dashboard/facex/Report";

// IISER Views
import IISERAdminDashboard from "../views/dashboard/iiser/admin/Dashboard";
import IISERVendorDashboard from "../views/dashboard/iiser/vendor/Dashboard";
import IISERMenu from "../views/dashboard/iiser/vendor/Menu/Menu";

// Employee Views
import EmployeeDashboard from "../views/dashboard/company/employee/Dashboard";
import EmployeeBills from "../views/dashboard/company/employee/bills/Index";

// Auth Pages
// import Pricing from "../views/dashboard/pages/Pricing.vue";
// import Lock from "../views/dashboard/pages/Lock.vue";
import Register from "../views/dashboard/pages/Register.vue";
import Login from "../views/dashboard/pages/Login.vue";

let authPages = {
	path: "/",
	component: AuthLayout,
	name: "Authentication",
	children: [
		{
			path: "/",
			title: "LoginRoot",
			name: "LoginRoot",
			redirect: "/login",
			component: Login,
		},
		{
			path: "/login",
			title: "Login",
			name: "Login",
			component: Login,
		},
		{
			path: "/:type/login",
			title: "Login",
			name: "LoginType",
			component: Login,
		},
		{
			path: "/register",
			title: "Register",
			name: "register",
			component: Register,
		},
	],
};

// User
import QRScanner from "../views/dashboard/pages/QRScanner/QRScanner";
import QRScanner3 from "../views/dashboard/pages/QRScanner/QRScanner3";

let userPages = {
	path: "/user",
	component: DashboardLayout,
	name: "User",
	redirect: "/User/Scan",
	children: [
		{
			path: "qrscan",
			name: "QR Scan",
			components: { default: QRScanner, header: DefaultHeader },
		},
		{
			path: "qr3",
			title: "QR3",
			name: "qr3",
			// component: QRScanner3,
			components: { default: QRScanner3, header: DefaultHeader },
		},
	],
};

function IsAuthenticated() {
	return !!(localStorage.getItem("xToken") && localStorage.getItem("user"));
}
function checkAuth(to, from, next) {
	if (
		to.path !== "/d" &&
		to.path !== "/v" &&
		to.path !== "/LiveLink" &&
		to.path !== "/VendorLiveLink"
	) {
		if (IsAuthenticated()) next();
		else next("/login");
	} else next();
}

const routes = [
	{
		path: "/login",
		title: "Login",
		name: "Login",
		component: AuthLayout,
		components: { default: Login, header: DefaultHeader },
	},
	authPages,
	userPages,
	{
		path: "/LiveLink",
		component: DashboardLayout,
		redirect: "/login",
		// beforeEnter: checkAuth,
		meta: {
			title: "LiveLinkDashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "",
				title: "LiveLink",
				name: "LiveLinkPage",
				components: { default: Dashboard, header: DashboardHeader },
			},
		],
	},
	{
		path: "/VendorLiveLink",
		component: VendorLayout,
		redirect: "/login",
		// beforeEnter: checkAuth,
		meta: {
			title: "VendorLiveLinkDashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "",
				title: "VendorLiveLink",
				name: "VendorLiveLinkPage",
				components: { default: VendorDashboard, header: VendorHeader },
			},
		],
	},
	{
		path: "/",
		component: DashboardLayout,
		redirect: "/login",
		// beforeEnter: checkAuth,
		meta: {
			title: "Dashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "Analytics/:code",
				title: "Dashboard",
				name: "Dashboard",
				components: { default: Dashboard, header: DashboardHeader },
			},
			{
				path: "Analytics",
				title: "Dashboard",
				name: "DashboardPage",
				components: { default: Dashboard, header: DashboardHeader },
			},
			{
				path: "d",
				title: "Dashboard",
				name: "DashboardPage_",
				components: { default: Dashboard, header: DashboardHeader },
			},
			// {
			// 	path: "Dashboard",
			// 	title: 'Dashboard',
			// 	name: "DashboardPage_",
			// 	components: { default: Dashboard, },
			// },
			{
				path: "Config/:code",
				title: "Configuration",
				name: "Config",
				components: { default: Config, header: DefaultHeader },
			},
			{
				path: "Config",
				title: "Configuration",
				name: "ConfigPage",
				components: { default: Config, header: DefaultHeader },
			},
			{
				path: "Blocked/:code",
				title: "BlockedList",
				name: "BlockedList",
				components: { default: Blocked, header: DefaultHeader },
			},
			{
				path: "Blocked",
				title: "BlockedList",
				name: "BlockedListPage",
				components: { default: Blocked, header: DefaultHeader },
			},
			{
				path: "Freebies/:code",
				title: "FreebiesList",
				name: "FreebiesList",
				components: { default: Freebies, header: DefaultHeader },
			},
			{
				path: "Freebies",
				title: "FreebiesList",
				name: "FreebiesListPage",
				components: { default: Freebies, header: DefaultHeader },
			},
			{
				path: "Menu/:code/:stallCode",
				title: "Stall Menu",
				name: "StallMenu",
				components: { default: Menu, header: DefaultHeader },
			},
			{
				path: "Menu/:stallCode",
				title: "Stall Menu",
				name: "StallMenuPage",
				components: { default: Menu, header: DefaultHeader },
			},
			{
				path: ":inventoryType/:code/:stallCode",
				title: "Inventory",
				name: "Inventory",
				components: { default: Inventory, header: DefaultHeader },
			},
			{
				path: ":inventoryType/:stallCode",
				title: "Inventory Page",
				name: "InventoryPage",
				components: { default: Inventory, header: DefaultHeader },
			},
			{
				path: "VendorInventory/:vendorCode",
				title: "Vendor Inventory",
				name: "VendorInventory",
				components: { default: VendorInventory, header: DefaultHeader },
			},
			{
				path: "VendorInventory",
				title: "Vendor Inventory Page",
				name: "VendorInventoryPage",
				components: { default: VendorInventory, header: DefaultHeader },
			},
			// {
			// 	path: "RN/:code/:stallCode",
			// 	title: 'RN',
			// 	name: "RN",
			// 	components: { default: Inventory, header: DefaultHeader },
			// },
			// {
			// 	path: "RN/:stallCode",
			// 	title: 'RN',
			// 	name: "RNPage",
			// 	components: { default: Inventory, header: DefaultHeader },
			// },
			{
				path: "Reports/:code",
				title: "Reports",
				name: "Reports",
				components: { default: Report, header: DefaultHeader },
			},
			{
				path: "Reports",
				title: "Reports",
				name: "ReportsPage",
				components: { default: Report, header: DefaultHeader },
			},
			{
				path: "Masterlist/All",
				title: "DevicesMasterlist",
				name: "DevicesMasterlist",
				components: {
					default: DevicesMasterlist,
					header: DefaultHeader,
				},
			},
			{
				path: "Whitelist/:code",
				title: "Whitelist",
				name: "WhitelistsPage",
				components: { default: Whitelist, header: DefaultHeader },
			},
			{
				path: "Whitelist",
				title: "Whitelist",
				name: "Whitelists",
				components: { default: Whitelist, header: DefaultHeader },
			},
			{
				path: "AccessX/Logs/:code",
				title: "AccessX_Logs",
				name: "AccessX_LogsPage",
				components: { default: AccessXLogs, header: DefaultHeader },
			},
			{
				path: "AccessX/Logs",
				title: "AccessX_Logs",
				name: "AccessX_Logs",
				components: { default: AccessXLogs, header: DefaultHeader },
			},
			{
				path: "Pacha/Logs/:code",
				title: "PachaAccessX_Logs",
				name: "PachaAccessX_LogsPage",
				components: { default: PachaWhitelist, header: DefaultHeader },
			},
			{
				path: "Pacha/Logs",
				title: "PachaAccessX_Logs",
				name: "PachaAccessX_Logs",
				components: { default: PachaWhitelist, header: DefaultHeader },
			},
			{
				path: "Whitelist2/:code",
				title: "Whitelist2_Logs",
				name: "Whitelist2_LogsPage",
				components: { default: Whitelist2, header: DefaultHeader },
			},
			{
				path: "Whitelist2",
				title: "Whitelist2_Logs",
				name: "Whitelist2_Logs",
				components: { default: Whitelist2, header: DefaultHeader },
			},
			{
				path: "Payouts/:code",
				title: "Payouts",
				name: "Payouts",
				components: { default: Payouts, header: DefaultHeader },
			},
			{
				path: "Devices/:code",
				title: "Devices",
				name: "Devices",
				components: { default: Devices, header: DefaultHeader },
			},
			{
				path: "Devices",
				title: "Devices",
				name: "DevicesPage",
				components: { default: Devices, header: DefaultHeader },
			},
			{
				path: "EventData/:code",
				title: "EventData",
				name: "EventData",
				components: { default: EventData, header: DefaultHeader },
			},
			{
				path: "EventData",
				title: "EventData",
				name: "EventDataPage",
				components: { default: EventData, header: DefaultHeader },
			},
			// {
			// 	path: "VolunteerMasterlist",
			// 	title: "VolunteerMasterlist",
			// 	name: "VolunteerMasterlist",
			// 	components: {
			// 		default: VolunteerMasterlist,
			// 		header: DefaultHeader,
			// 	},
			// },
			{
				path: "Volunteers/:code",
				title: "Volunteers",
				name: "Volunteers",
				components: { default: Volunteers, header: DefaultHeader },
			},
			{
				path: "Volunteers",
				title: "Volunteers",
				name: "VolunteersPage",
				components: { default: Volunteers, header: DefaultHeader },
			},
			{
				path: "Transactions",
				title: "Transactions",
				name: "TransactionsPage",
				components: { default: Transactions, header: DefaultHeader },
			},
			{
				path: "Transactions/:code",
				title: "Transactions",
				name: "Transactions",
				components: { default: Transactions, header: DefaultHeader },
			},
			{
				path: "ViewAll",
				title: "View All",
				name: "ViewAll",
				components: { default: ViewAll, header: DefaultHeader },
			},
			{
				path: "Create",
				title: "Create",
				name: "Create",
				components: { default: Create, header: DefaultHeader },
			},
			{
				path: "TapXTransactions",
				title: "TapXTransactions",
				name: "TapXTransactions",
				components: {
					default: TapXTransactions,
					header: DefaultHeader,
				},
			},
			// ArmourX Paths
			{
				path: "Uploads",
				title: "Uploads",
				name: "Uploads",
				components: { default: Uploads, header: DefaultHeader },
			},
			// {
			//   path: "Create",
			//     title: 'Create',
			//   name: "Create",
			//   components: { default: EventCreate, header: DefaultHeader },
			// },
		],
	},
	{
		path: "/Company",
		component: CompanyLayout,
		redirect: "/login",
		beforeEnter: checkAuth,
		meta: {
			title: "Dashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "",
				title: "CompanyDashboard",
				name: "Company Dashboard",
				components: {
					default: CompanyDashboard,
					header: CompanyHeader,
				},
			},
			{
				path: "Employee",
				title: "EmployeeDashboard",
				name: "Employee DashboardPage",
				components: {
					default: EmployeeDashboard,
					header: EmployeeHeader,
				},
			},
			{
				path: "Employee/:code",
				title: "EmployeeDashboard",
				name: "EmployeeDashboard",
				components: {
					default: EmployeeDashboard,
					header: EmployeeHeader,
				},
			},
		],
	},
	{
		path: "/IISER",
		component: Layout,
		redirect: "/login",
		beforeEnter: checkAuth,
		meta: {
			title: "Dashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "Admin",
				title: "IiserAdminDashboard",
				name: "IISERAdminDashboard",
				components: {
					default: IISERAdminDashboard,
					header: IISERHeader,
				},
			},
			{
				path: "Vendor",
				title: "IiserVendorDashboard",
				name: "IISERVendorDashboard",
				components: {
					default: IISERVendorDashboard,
					header: IISERVendorHeader,
				},
			},
			{
				path: "Vendor/:code",
				title: "IiserVendorDashboardPage",
				name: "IISERVendorDashboardPage",
				components: {
					default: IISERVendorDashboard,
					header: IISERVendorHeader,
				},
			},
			{
				path: "Vendor/Menu/:vendorCode",
				title: "Vendor Menu",
				name: "VendorMenu",
				components: { default: IISERMenu, header: DefaultHeader },
			},
			{
				path: "Vendor/Menu",
				title: "VendorMenu",
				name: "VendorMenuPage",
				components: { default: IISERMenu, header: DefaultHeader },
			},
		],
	},
	{
		path: "/FaceX",
		component: Layout,
		redirect: "/login",
		// beforeEnter: checkAuth,
		meta: {
			title: "Dashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "Users",
				title: "FacexUsers",
				name: "FacexUsers",
				components: { default: FacexUsers, header: DefaultHeader },
			},
			{
				path: "Report",
				title: "FacexReport",
				name: "FacexReport",
				components: { default: FacexReport, header: DefaultHeader },
			},
		],
	},
	{
		path: "/Vendor",
		component: VendorLayout,
		redirect: "/login",
		beforeEnter: checkAuth,
		meta: {
			title: "VendorDashboard",
			metaTags: [
				{
					name: "description",
					content: "The about page of our example app.",
				},
				{
					property: "og:description",
					content: "The about page of our example app.",
				},
			],
		},
		children: [
			{
				path: "Analytics",
				title: "VendorDashboardPage",
				name: "VendorDashboardPage",
				components: { default: VendorDashboard, header: VendorHeader },
			},
			{
				path: "Config",
				title: "ConfigurationVendorPage",
				name: "ConfigVendorPage",
				components: { default: Config, header: DefaultHeader },
			},
			{
				path: "Reports",
				title: "Reports Vendor Page",
				name: "ReportsVendorPage",
				components: { default: Report, header: DefaultHeader },
			},
			{
				path: "Transactions",
				title: "Transactions Vendor Page",
				name: "TransactionsVendorPage",
				components: { default: Transactions, header: DefaultHeader },
			},
			{
				path: "Menu/:stallCode",
				title: "Stall Menu",
				name: "StallMenuPage",
				components: { default: Menu, header: DefaultHeader },
			},
		],
	},
	{ path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
	history: createWebHashHistory(),
	linkActiveClass: "active",
	routes,
});

// This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
// 	// This goes through the matched routes from last to first, finding the closest route with a title.
// 	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
// 	// `/nested`'s will be chosen.
// 	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
//
// 	// Find the nearest route element with meta tags.
// 	const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//
// 	// If a route with a title was found, set the document (page) title to that value.
// 	if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
//
// 	// Remove any stale meta tags from the document using the key attribute we set below.
// 	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
//
// 	// Skip rendering meta tags if there are none.
// 	if(!nearestWithMeta) return next();
//
// 	// Turn the meta tag definitions into actual elements in the head.
// 	nearestWithMeta.meta.metaTags.map(tagDef => {
// 		const tag = document.createElement('meta');
//
// 		Object.keys(tagDef).forEach(key => {
// 			tag.setAttribute(key, tagDef[key]);
// 		});
//
// 		// We use this to track which meta tags we create so we don't interfere with other ones.
// 		tag.setAttribute('data-vue-router-controlled', '');
//
// 		return tag;
// 	})
// 	// Add the meta tags to the document head.
// 		.forEach(tag => document.head.appendChild(tag));
//
// 	next();
// });

export default router;
