<template>
	<div>
		<div class="row justify-content-center">
			<div class="col-12 m-auto d-md-none d-inline">
				<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
					<div class="card-body pr-0 pl-2" v-if="!isLoading.summary">
						<div class=" h-50 ">
							<canvas :id="headerChartID3"/>
						</div>
						<div class="" v-if="components.summary">
							<div>
								<div class="col-md text-right row py-1 pr-0">
									<div class="col-3 px-0 my-auto text-left">
										<span class="badge bg-white aml-orange-2 border-0">Pending Bills</span>
									</div>
									<div class="col-9 px-0">
										<h5 class="info-title m-0 d-inline col-10 text-right px-0">
											<small class=""> ₹ </small>
											<strong class="">{{ $numFrm2(+info.billAmount - +info.paidAmount) }}</strong>
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-around">
			<div class="card card-stats card-raised col-md-3 col-4 mr-2" v-for="event in tData" :key="event.id">
				<div class="card-header">
					<h6>
						<span class="text-secondary cursor-pointer" @click="copyLink(event)">{{ event.name }}, {{ event.city }} <i class="fa fa-external-link"/></span>
						<el-divider direction="vertical"/>
						<small>{{ event.id }}</small>
					</h6>
					<table class="table aml-font-11 aml-table mb-0">
						<thead class="text-primary">
							<th>TYPE</th>
							<th>MODE</th>
							<th class="text-right">AMOUNT</th>
							<th class="text-right">COUNT</th>
						</thead>
						<tbody>
							<tr v-for="data in event.data">
								<td>{{ data.type === 'no_nfc_sale' ? 'sale' : data.type }}</td>
								<td>{{ data.mode }}</td>
								<td class="text-right">{{ $numFrm2(data.amount) }}</td>
								<td class="text-right">{{ $numFrm2(data.count) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			<!--BILLS-->
			<div class="card card-stats card-raised col-md-5 col-11 mx-auto">
				<div class="card-header">
					<h5 class="card-category">BILLS ( {{ bills.length }} )</h5>
					<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
<!--						<span class="col-3 text-right text-info cursor-pointer" @click="uploadDialog = true"><i class="fa fa-plus"></i> ADD BILL</span>-->
						<span class="badge badge-primary" v-if="isLoading.bills">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
					<el-divider/>
				</div>
				<div class="card-body row">
					<!--					<div class="card card-stats card-raised" v-for="bill in bills" :key="bill.id">-->
					<!--						<div class="card-body">{{ $numFrm2(bill.amount) }}</div>-->
					<!--					</div>-->
					<div class="col-12">
						<el-table class="w-100 aml-font-11"
								  :data="bills.filter( data => !billSearch || data.notes.toLowerCase().includes(billSearch.toLowerCase()) || data.amount.toLowerCase().includes(billSearch.toLowerCase()) || data.type.toString().includes(billSearch.toLowerCase()) )"
								  height="500" highlight-current-row stripe
						>
							<el-table-column type="expand">
								<template #default="scope">
									<p>{{ scope.row.notes }}</p>
								</template>
							</el-table-column>
							<el-table-column label="#" type="index" />
							<el-table-column label="BILL">
								<template #default="scope">
									<a :href="scope.row.bill" target="_blank" class="text-dark text-decoration-none"><h5 class="d-inline">₹ {{ $numFrm2(scope.row.amount) }} <small><i class=" fa fa-solid fa-external-link-alt text-primary ml-2"/></small></h5></a>
									<p class="mb-0">{{ scope.row.person ? scope.row.person.name : '' }} | {{ scope.row.event ? scope.row.event.name : '' }} | {{ scope.row.event ? scope.row.event.id : '' }}</p>
									<p class="mb-0 d-inline">{{ scope.row.date }}</p> <el-tag class="ml-2 align-middle" size="small" type="info"><span class="m-auto">{{ scope.row.type }}</span></el-tag>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<!--PAYMENTS-->
			<div class="card card-stats card-raised col-11 col-md-5 mx-auto">
				<div class="card-header">
					<h5 class="card-category">PAYMENTS ( {{ payments.length }} )</h5>
					<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
						<span class="badge badge-primary" v-if="isLoading.payments">
							<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
						</span>
					</div>
					<el-divider/>
				</div>
				<div class="card-body row">
					<div class="row col-12 border-1" v-for="payment in payments" :key="payment.id" style="border-radius: 2px">
						<div class="col-11 mx-auto">
							{{ $numFrm2(payment.amount) }}
						</div>
					</div>
					<!--					<div class="col-12">-->
					<!--						<el-table class="w-100 aml-font-11"-->
					<!--								  :data="payments.filter( data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.locationCity.toLowerCase().includes(search.toLowerCase()) || data.id.toString().includes(search.toLowerCase()) || data.country.toLowerCase().includes(search.toLowerCase()) )"-->
					<!--								  height="500"-->
					<!--						>-->
					<!--							<el-table-column label="PAYMENTS" width="450">-->
					<!--								<template #default="scope">-->
					<!--									<div>-->
					<!--										{{ scope.row }}-->
					<!--									</div>-->
					<!--								</template>-->
					<!--							</el-table-column>-->
					<!--						</el-table>-->
					<!--					</div>-->
				</div>
			</div>
		</div>

		<el-dialog width="60%" title="NEW BILL" v-model="uploadDialog">
<!--			<BillsUpload @bill-uploaded="billUploaded" :eventsList="eventsList"/>-->
		</el-dialog>

	</div>

</template>
<script>
import {
	Card,
	Table as NTable,
	AnimatedNumber,
	Progress as NProgress,
	AsyncWorldMap,
} from "@/components";
import {headerChart as headerChart3} from "../../dashboard/HeaderChart2";
import {headerChart as headerChart4} from "../../dashboard/HeaderChart3";
import headerChart from "../../dashboard/HeaderChart";
import dataChart from "../../dashboard/dataChart";
import { barChart1 as attendanceChart, barChart2 as attendanceChart2 } from "../../dashboard/BarChart";
import axios from "axios";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
// import BillsUpload from "./BillsUpload";
import { ElDivider, ElBadge, ElTable, ElTableColumn, ElDialog, ElTag, } from 'element-plus';
import { copyText } from "vue3-clipboard";

export default {
	components: {
		Card,
		NTable,
		AnimatedNumber,
		NProgress,
		AsyncWorldMap,
		ElDivider, ElBadge,
		ElTable, ElTableColumn,
		ElDialog, ElTag,
		copyText,
	},
	inject: ['$baseURL', '$numFrm', '$numFrm2', '$formatDateTime', '$isCheckEventType', '$armourxURL',],
	emits: [ 'summary-fetched', 'data-fetched', ],
	props: ['user', 'info',],
	data() {
		return {
			v1: null,
			options: [ { value: 'Vue.js', label: 'JavaScript' }, ],

			loginUser: {},

			// employeeInfo: { billAmount: 0, paidAmount: 0 },
			bills: [],
			billSearch: '',
			payments: [],
			period: "currentfy", //"lastfy", "all"
			uploadData: {
				personId: "data.personId",
				type: "data.type",
				amount: 0,
				date: "1990-02-21",
				status: "active",
				timestamp: { updatedAt: new Date().toString(), createdAt: new Date().toString() },
			},
			uploadDialog: false,
			uploadDialog_: false,
			eventsList: [ { id: 47, name: " AtomX Office " } ],

			components: {
				summary: true,
			},
			txnData: [],
			tData: [],

			isLoading: { summary: true, bills: true, payments: true, txn: true, },

			attendanceChartID: "attendanceChart",
			headerChartID: "headerChart",
			headerChartID2: "headerChart2",
			headerChartID3: "headerChart3",
			headerChartID4: "headerChart4",

			txnChart: headerChart,
			modeChart: headerChart4,
			summaryChart: headerChart3,
			dataChart: dataChart,

		};
	},
	methods: {
		runToast(text, pos, type, ownText, ownIcon) {
			// const text =
			// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
			const icon = "now-ui-icons ui-1_bell-53";
			const content = {
				component: Notification,

				props: {
					ownText: ownText,
					ownIcon: ownIcon,
					icon: icon,
					text: text,
					type: type,
				},
			};
			const toast = useToast();
			toast(content, {
				hideProgressBar: true,
				icon: false,
				closeButton: false,
				position: pos,
			});
		},
		setComponentsDisplay () {
			this.setDefault('components');
		},
		loadDetails() {
			// debugger;
			let code = 0;
			let url = '/Company/Details/';
			let u = this.loginUser;
			switch (u.loginType) {
				case 'company':
					url = '/Company/Details/';
					code = u.id;
					break;
				default:
					return;
					break;
			}
			// url = '/' + (u.product === 'armourx' ? 'Users' : 'Event') +'/Details/';
			axios.get(
				this.$baseURL + url,
				{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						// this.employeeInfo = response.data.employeeInfo;
						this.$emit('data-fetched', response.data.company);
						this.loadDashboardData();
						this.loadTransactions();
						this.loadEvents();
						this.load("Bills");
						this.load("Payments");
					} else {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
		},
		copyLink(event) {
			let text = 'https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id) + '\n\n' + event.name + ', ' + event.city + ' | ' + event.id + ' | LIVE LINK';
			copyText(text, undefined, (error, event) => {})
			if(confirm("Do you want to open in new window?"))	window.open('https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id), '_blank');
		},
		base64_aml(v) { return btoa(v); },
		loadTransactions() {
			this.isLoading.txn = true;
			axios.get(
				this.$baseURL + "/Company/EventAmounts",
				{ params: {  }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						this.txnData = response.data.data;
						response.data.data.forEach( event => {
							let id = event.id, name = event.name, city = event.city ;
							let amounts = event.amounts.split(','), counts = event.counts.split(','), types = event.types.split(','), modes = event.modes.split(',');
							let d = { id, name, city, data: [] };
							amounts.forEach( (amount, i) => {
								d.data.push( { amount, count: counts[i], type: types[i], mode: modes[i], } );
							});
							this.tData.push(d);
							// console.log(d);
						} );
						// console.log(this.tData);
					} else {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
					this.isLoading.txn = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.txn = false;
				});
		},
		loadDashboardData() {
			let code = 0;
			let url = '/Company/summaryData/';
			let u = this.loginUser;
			switch (u.loginType) {
				case 'company':
					url = '/Company/summaryData/';
					code = u.id;
					break;
				default:
					return;
					break;
			}
			// console.log('code', code);
			// url = '/' + (u.product === 'armourx' ? 'Users' : 'Event') +'/Details/';
			axios.get(
				this.$baseURL + url,
				{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						// this.employeeInfo = response.data.employeeInfo;
						this.$emit('summary-fetched', response.data.summaryInfo);
					} else {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
					this.isLoading.summary = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
		},
		load(loadType) {
			let code = 0, currentTime = new Date().getTime(), period = this.period;
			let url = '/Company/' + loadType + '/';
			let u = this.loginUser;
			switch (u.loginType) {
				case 'company':
					url = '/Company/' + loadType + '/';
					code = u.id;
					break;
				default:
					return;
					break;
			}
			// url = '/' + (u.product === 'armourx' ? 'Users' : 'Event') +'/Details/';
			axios.get(
				this.$baseURL + url,
				{ params: { code, currentTime, period, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						// this.bills = response.data.bills;
						this[loadType.toLowerCase()] = response.data[loadType.toLowerCase()];
					} else {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
					this.isLoading[loadType.toLowerCase()] = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading[loadType.toLowerCase()] = false;
				});
		},
		billUploaded(bill) {
			// debugger
			this.bills.push(bill);
			this.uploadDialog = false;
		},
		loadEvents() {
			// this.isLoading.events = true;
			axios.get(
				this.$baseURL + '/Event/ListAll/',
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						this.eventsList = [...this.eventsList, ...response.data.events];
					}
					// this.isLoading.events = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					// this.isLoading.events = false;
				});
		},
	},
	mounted() {
		// debugger;
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');

		this.loadDetails();
		// this.interval_fn = setInterval(this.loadDetails, 60000);
	},
	watch:{
		$route (to, from){
			console.log('route change detected');
			// this.setLoading();
			// clearInterval(this.interval_fn);
			this.loadDetails();
		},
		info: {
			handler(a) {
				// console.log("summaryInfo update detect", a);
				this.$emit('summary-fetched', a);

				if(!a)  return;
				let summaryChartData = [
					this.info.billAmount,
					this.info.paidAmount,
				];
				// this.summaryChart.createChart(this.headerChartID3, [ 'BILLS', 'PAYMENTS', ], summaryChartData);
				// this.summaryChart.createChart(this.headerChartID2, [ 'BILLS', 'PAYMENTS', ], summaryChartData);
			},
			deep: true,
		},
	}
};
</script>
