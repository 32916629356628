<template>
	<div>
		<div class="row ">
			<div class="col-12 col-md-4 mx-auto">
				<data-list :event="event" v-if="components.list" :list="list" @dataSelected="dataSelected"
					:details="details" />
			</div>
			<div class="col-12 col-md-8 mx-auto">
				<data-details :event="event" v-if="components.details" :details="details" @dataSave="dataSave"
					@dataSelected="dataSelected" />
			</div>

		</div>
	</div>
</template>
<script>
import {
	Card,
} from "@/components";
import axios from 'axios';
import DataDetails from './DataDetails';
import DataList from './DataList';
import { ElDivider, ElBadge, ElMessage } from "element-plus";

export default {
	components: {
		DataDetails,
		DataList,
		Card,
		ElDivider, ElBadge
	},
	inject: ['$baseURL', '$numFrm', '$numFrm2', '$formatDateTime', '$isCheckEventType'],
	emits: ['event-fetched', 'loading-complete'],
	data() {
		return {
			defaultInfo: {
				event: { name: '', country: 'india', type: '' },
				code: 0,
				details: {},
				list: [],
				isLoading: { list: true, details: true, },
			},
			loginUser: {},
			event: { name: '', country: 'india', type: '' },
			details: {},
			code: 0,
			list: [],
			isLoading: { list: true, details: true, },
			components: { list: false, details: false, },
		};
	},
	methods: {
		setDefault(key = 'all') {
			if (key !== 'all') this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		dataSelected(details) {
			this.details = details;
			// this.components.details = !_.isEmpty(details);
			this.components.details = Object.keys(details).length !== 0;
			// console.log(details);
			if (this.components.details) {
				// Object.keys(details).forEach( ( key ) => {  } );
				// [ 'monies', 'summary', ];

				// Object.keys(details.monies).forEach((moniesKey => {
				// 	Object.keys(details.monies[moniesKey]).forEach((type) => {
				// 		details.monies[moniesKey][type].amount = this.$numFrm2(details.monies[moniesKey][type].amount);
				// 		details.monies[moniesKey][type].count = this.$numFrm2(details.monies[moniesKey][type].count);
				// 	});
				// }));

				// Object.keys(details.summary).forEach((summaryKey) => {
				// 	if (summaryKey === 'info') return;
				// 	details.summary[summaryKey].amount = this.$numFrm2(details.summary[summaryKey].amount);
				// 	details.summary[summaryKey].count = this.$numFrm2(details.summary[summaryKey].count);
				// });
			}
		},
		dataSave() { },
		eventUpdated(event) {
			if (!event) return;
			this.event = event;
			this.$emit('event-fetched', event);
		},
		loadDetails() {
			let u = this.loginUser, code = 0, url = '';
			axios.get(
				this.$baseURL + '/Event/Details/',
				{ params: { code: this.code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					console.log("response:", response.data)
					this.setDefault('event');
					this.event = response.data.event;
					// console.log("aml", this.event, response.data);
					this.isLoading.event = false;
					// this.dataLoad();
					this.$emit('event-fetched', this.event);
					this.loadList();
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.$emit('loading-complete');
				});
		},
		loadList() {

			this.isLoading.list = false;
			this.$emit('loading-complete');
			this.components.list = true;
			// return this.list = [1,2,4];
			let u = this.loginUser, code = 0, url = '';
			axios.get(
				this.$baseURL + '/Payouts/Log/List/',
				{ params: { code: this.code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					console.log("loadlist", response.data)
					// this.setDefault('event');
					this.list = response.data.logs;
					this.isLoading.list = false;
					this.$emit('loading-complete');
					this.components.list = true;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.$emit('loading-complete');
				});
		},
	},
	mounted() {
		// setTimeout(this.loadDetails, 500);
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');

		switch (u.loginType) {
			case 'event':
			case 'operator':
				this.code = u.id;
				break;
			case 'admin':
				this.code = this.$route.params.code;
				break;
			default:
				return;
				break;
		}

		this.loadDetails();
	},
	watch: {
		$route(to, from) {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');

			// if( this.$router.currentRoute.value.name.includes("EventData") )
			// 	debugger;

			this.loadDetails();
			// console.log(this.$router);
		},
	}
};
</script>
<style>
.aml-card-header {
	border-radius: .5875rem !important;
	min-height: 100px;
	/*background-color: #2198af !important;*/
}

.aml-event-card {
	/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
	box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
}
</style>
