<template>
	<div>
		<div class="row mb-3" >
			<div class="col-12 mx-auto">
				<div class="row my-2 mx-auto mt-5">
					<h6 class="aml-text-teal-3 col-12 col-md-2 my-auto text-md-right text-left"><i class="fas fa-hourglass"/> MONTH</h6>
					<div class="col-md-6 col-12 mt-3 my-auto">
						<el-date-picker
							v-model="dateSelect"
							type="month"
							start-placeholder="Select Month"
						/>
					</div>
					<div class="col-md-2 col-12 mt-md-0 mt-2">
<!--						<button class="btn rounded btn-primary" @click="loadReport()" :disabled="isLoading.report">-->
<!--							<i class="fa fa-external-link-alt"/> GET REPORT-->
<!--						</button>-->
						<el-button class=" bg-primary text-white" :loading="isLoading.report" icon="fas fa-external-link-alt" v-on:click="loadReport()"> GET REPORT</el-button>
					</div>
				</div>
			</div>
		</div>

		<div class="row ">
			<div class="col-12 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">REPORT</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="badge badge-primary" v-if="$route.query.t">
								<small>TYPE: </small> {{ $route.query.t.toUpperCase() }}
							</span>
							<span class="ml-2" @click="convertnDownload()" v-if="Object.entries(rData).length > 0 && !isLoading.report">
								<button class="btn btn-info"><i class="fa fa-download"/> DOWNLOAD</button>
							</span>
							<span class="badge badge-primary ml-2" v-if="isLoading.report">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body ">
						<el-input v-if="Object.entries(rData).length > 0 && !isLoading.report" class="mt-4" placeholder="Search" size="mini" prefix-icon="el-icon-search" v-model="search"/>

						<div v-if="Object.entries(rData).length > 0 && !isLoading.report" style="overflow:scroll;" class="table-responsive">
							<table class="table align-items-center table-flush table-hover table-bordered" id="reportTable" v-if="rData">
								<thead class="thead-light aml-font-10">
									<tr>
										<th class="sort" rowspan="2" colspan="3">Name</th>
										<!--<th scope="col" class="text-center" data-sort="id_card">AtomX ID</th>-->
										<th rowspan="2">Reg ID</th>
										<th
											class="text-center" rowspan="1" colspan="3"
											v-for="d in days"
										>{{ d }} {{ months[month - 1] }}</th>
									</tr>
									<tr>
										<th class="text-left" rowspan="1" colspan="3" v-for="d in days">
											<div><small><i class="fa fa-clock"/> IN</small></div>
											<div><small><i class="fa fa-history"/> OUT</small></div>
											<div><small><i class="fa fa-business-time"/> HOURS</small></div>
										</th>
	<!--									<th class="text-center" rowspan="1" colspan="1">IN</th>-->
	<!--									<th class="text-center" rowspan="1" colspan="1">OUT</th>-->
	<!--									<th class="text-center" rowspan="1" colspan="1">HOURS</th>-->
									</tr>
								</thead>
								<tbody>
<!--									<tr v-for="[rgId, person] in Object.entries(rData)">-->
									<tr v-for="person in rData_.filter( data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.regId.includes(search.toLowerCase()) )">
										<td colspan="3">{{ person.name }}</td>
										<td class="text-center">
											<div>{{ person.regId }}</div>
											<div class="badge aml-text-teal">{{ person.type }}</div>
										</td>
										<td class="text-left" v-for="i in days" colspan="3">
											<span v-if="person[i]">
												<div><small><i class="fa fa-clock"/> {{ person[i].inTime }}</small></div>
												<div><small><i class="fa fa-history"/> {{ person[i].outTime }}</small></div>
												<div><small><i class="fa fa-business-time"/> {{ person[i].workHours }}</small></div>
											</span>
											<span v-else> - </span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import {
		ElDivider, ElBadge, ElDialog, ElButton,
		ElForm, ElFormItem, ElSelect, ElOption,
		ElTable, ElTableColumn, ElDatePicker,
	} from 'element-plus';
	import moment from 'moment';
	import login from "../../../services/login";


	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge, ElDialog, ElButton, ElForm,
			ElFormItem, ElSelect, ElOption,
			ElTable, ElTableColumn, ElDatePicker,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'info-fetched', 'loading-complete', 'hide-sidebar', ],
		props: [ 'user', ],
		data() {
			return {
				defaultInfo: {
					info: { name: '', country: 'india', },
					reportData: [],
					csvData: "NAME,REG ID,",
					components: { report: true, },
					isLoading: { report: true, },
				},
				c: this.$route.query.c,
				months: ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
				],
				rData: {},
				rData_: [],
				dateSelect: [],
				days: [],
				month: new Date().getMonth(),
				year: new Date().getFullYear(),
				department: null,
				type: null,
				loginUser: {},
				info: { name: '', country: 'india', },
				reportData: [],
				components: { report: true, },
				isLoading: { report: false, },
				search: '',
				personDialog: false,

				csvData: "NAME,REG ID,",
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				let code = this.c ?? this.loginUser.id;

				axios.get(
					this.$baseURL + '/FaceX/Admin/Details/',
					{ params: { code, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.info = response.data.info;
					}
					this.$emit('loading-complete');
					this.$emit('info-fetched', response.data.info);
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.$emit('loading-complete');
				});
			},
			displaydt(dt) {
				return dt ? moment(dt).local().format('DD-MM-YYYY HH:mm:ss') : 'NA';
			},
			displaytime(dt) {
				return moment(dt).local().format('HH:mm:ss');
			},
			daysInMonth(month, year) { return new Date(this.year, this.month, 0).getDate(); },
			setMonthDays() {
				this.days = [];
				for( let i=1; i<= this.daysInMonth(); i++ ) {
					this.days.push(i);
				}
			},
			loadReport() {
				if(!this.dateSelect)	return;
				this.month = new Date(this.dateSelect).getMonth() + 1;
				this.year = new Date(this.dateSelect).getFullYear();
				this.type = this.$route.query.t;
				let code = this.c ?? this.loginUser.id;

				this.setMonthDays();

				// console.log(this.dateSelect, this.month, this.year, this.days, this.department);

				this.isLoading.report = true;
				axios.get(
					this.$baseURL + '/FaceX/Report/',
					{ params: { code, month: this.month, year: this.year, type: this.type, }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.reportData = response.data.report;
						response.data.report.forEach( (pd) => {
							if( !this.rData[pd.reg_id] )  this.rData[pd.reg_id] = { name: pd.name, type: pd.type, id: pd.id, regId: pd.reg_id };
							if( !this.rData[pd.reg_id][pd.day] )
							this.rData[pd.reg_id][pd.day] = {
								inTime: this.displaytime(new Date(pd.in_time)),
								outTime: this.displaytime(new Date(pd.in_time)) === this.displaytime(new Date(pd.out_time)) ? '-' : this.displaytime(new Date(pd.out_time)),
								workHours: pd.work_hours,
							}
						});
						if(response.data.report && response.data.report.length >0) {
							this.rData_ = [];
							Object.entries(this.rData).forEach( (p) => {
								this.rData_.push(p[1]);
							} );
						}
						// console.log(this.rData_);
					}
					this.isLoading.report = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.report = false;
				});
			},
			setcsvRow1() {
				this.csvData = ",,";
				this.days.forEach( (day) => {
					this.csvData += day + ' ' + this.months[this.month - 1] + ',,,';
				});
				this.csvData += '\n';
			},
			setcsvRow2() {
				this.csvData += 'NAME,REG ID,';
				this.days.forEach( (day) => {
					this.csvData += 'IN,OUT,HOURS,';
				});
				this.csvData += '\n';
			},
			convertnDownload() {
				this.setcsvRow1();
				this.setcsvRow2();

				for (const [regId, person] of Object.entries(this.rData)) {
					this.csvData += person.name + ',' + regId + ',';
					this.days.forEach( (day) => {
						if(person[day])
							this.csvData += person[day].inTime + ',' + person[day].outTime + ',' + person[day].workHours + ',';
						else
							this.csvData += '-,-,-,';
					});
					this.csvData += '\n';
				}

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.csvData);
				anchor.target = '_blank';
				anchor.download = 'FaceX Attendance for' + this.months[this.month - 1] + ' ' + this.year + '.csv';
				anchor.click();
			},
		},
		mounted() {
			if( this.$route.query.t ) this.$emit('hide-sidebar', true);
			else {
				this.$emit('hide-sidebar', false);
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			}
			this.loadDetails();

			this.daysInMonth();

		},
		watch: {
			$route(to, from) {
				if( this.$route.query.t ) this.$emit('hide-sidebar', true);
				else {
					this.$emit('hide-sidebar', false);
					let u = JSON.parse(localStorage.getItem('user'));
					this.loginUser = u;
					if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				}
				this.loadDetails();

				this.daysInMonth();
				this.rData = {};
			},
		},
	};
</script>
<style>
	.avatar img {
		border-radius: 50%;
		position: relative;
		left: -5px;
		z-index: 1;
	}
</style>
