<template>
	<div>
		<div class="row " >
			<div class="col-12 col-md-4 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">Filter</h5>
					</div>
					<div class="card-body">
						<el-form class="my-3">
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">ORDER ID</h6>
								<div class="ml-2 col-md-7 col-10 mx-auto">
									<el-input ref="orderId" v-model="filter.orderId" placeholder="ORDER ID" autocomplete="off"/>
								</div>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center d-none">
								<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">PAYMENT ID</h6>
								<div class="ml-2 col-md-7 col-10 mx-auto">
									<el-input ref="paymentId" v-model="filter.paymentId" placeholder="PAYMENT ID" autocomplete="off"/>
								</div>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">CARD ID</h6>
								<div class="ml-2 col-md-7 col-10 mx-auto">
									<el-input ref="cardId" v-model="filter.cardId" placeholder="CARD ID" autocomplete="off"/>
								</div>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">MOBILE</h6>
								<div class="ml-2 col-md-7 col-10 mx-auto">
									<el-input ref="mob" v-model="filter.mobile" placeholder="MOBILE" autocomplete="off"/>
								</div>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<h6 class="mb-0 col-md-5 col-10 aml-text-teal-3 my-auto text-left text-md-right">EMAIL</h6>
								<div class="ml-2 col-md-7 col-10 mx-auto">
									<el-input ref="mob" v-model="filter.email" placeholder="EMAIL" autocomplete="off"/>
								</div>
							</span>

						</el-form>
					</div>
					<div class="card-footer justify-content-end row">
						<el-button type="text" class="text-black-50" v-if="!isLoading.results" @click="setDefault(filter)">Reset</el-button>
						<el-button type="primary" class="aml-bg-teal-3" :loading="isLoading.results" icon="fas fa-search mr-2" @click="search()">Search</el-button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">Results ( {{ transactions.length }} )</h5>
					</div>
					<div class="card-body">

						<el-collapse accordion>
							<el-collapse-item v-for="(txn, i) in transactions" :title="txn.receipt">
								<template #title>
									<div class="">
										<el-divider direction="vertical"/> <span class="badge" :class="getStatusClass(txn.status)">{{ txn.status }}</span>
										<el-divider direction="vertical"/> {{ txn.receipt }}
										<el-divider direction="vertical"/> {{ $numFrm(txn.amount) }}
										<span v-if="txn.code"> <el-divider direction="vertical"/>
											<span class="badge badge-default">{{ txn.code }}</span>
<!--											<el-tag type="" class="m-1" effect="plain" round>{{ txn.code }}</el-tag>-->
										</span>
										<el-divider direction="vertical"/> {{ displaydt(txn.createdAt) }}
									</div>
								</template>

								<div class="row bg-light pb-2">
									<div class="col-12 col-md-6">
										<el-divider><small>INFO</small></el-divider>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">TXN ID</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.id }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">ORDER ID</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.receipt }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">PAYMENT ID</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.pgorderId }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">TIMESTAMP</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.createdAt) }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">LAST UPDATED TIMESTAMP</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.updatedAt) }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">STATUS</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">
												{{ txn.status }}
												<i @click="openUpdateModal(txn.id, i)" v-if="!isLoading.results" class="fa fa-pencil cursor-pointer"/>
												<i v-if="isLoading.results" class="fa fa-spin fa-spinner"/>
											</strong>
										</span>
										<el-divider><small>CUSTOMER</small></el-divider>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">NAME</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerName }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MOBILE</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerMobile }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">EMAIL</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerEmail }}</strong>
										</span>
									</div>
									<div class="col-12 col-md-6">
										<el-divider><small>CARD</small></el-divider>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">CARD ID</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.cardId }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">CARD UID</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.cardUid }}</strong>
										</span>
										<el-divider><small>DEVICE INFO</small></el-divider>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">REQUEST AT</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.requestAt) }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">DEVICE</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.deviceRequestPrintId }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MAC</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.deviceRequestMac }}</strong>
										</span>
										<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
											<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">COMPLETED AT</small>
											<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.txnAt) }}</strong>
										</span>
										<el-divider><small>PAYMENT INFO</small></el-divider>
										<el-collapse accordion>
											<el-collapse-item v-for="t in txn.tapxBankTransactions" :title="t.status">
												<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
													<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MODE</small>
													<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.mode.toUpperCase() }}</strong>
												</span>
												<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
													<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">STATUS</small>
													<strong class="mb-0 col-md-8 col-11 my-auto text-left">
														<div>{{ t.status }} | {{ t.statusCode }}</div>
														<small>{{ t.msg }}</small>
													</strong>
												</span>
												<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
													<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">REFERENCE</small>
													<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.referenceId }}</strong>
												</span>
											</el-collapse-item>
										</el-collapse>
									</div>
								</div>

							</el-collapse-item>
						</el-collapse>

					</div>
					<div class="card-footer justify-content-end row">
					</div>
				</div>
			</div>

		</div>

		<el-dialog
			v-model="editStatusDialogVisible"
			title="Update Status"
		>
			<div class="row bg-light pb-2">
				<div class="col-12 col-md-6">
					<el-divider><small>INFO</small></el-divider>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">TXN ID</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.id }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">ORDER ID</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.receipt }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">PAYMENT ID</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.pgorderId }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">TIMESTAMP</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.createdAt) }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">LAST UPDATED TIMESTAMP</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.updatedAt) }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">STATUS</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left badge" :class="getStatusClass(txn.status)">{{ txn.status }}</strong>
					</span>
					<el-divider><small>CUSTOMER</small></el-divider>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">NAME</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerName }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MOBILE</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerMobile }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">EMAIL</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.customerEmail }}</strong>
					</span>
				</div>
				<div class="col-12 col-md-6">
					<el-divider><small>CARD</small></el-divider>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">CARD ID</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.cardId }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">CARD UID</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.cardUid }}</strong>
					</span>
					<el-divider><small>DEVICE INFO</small></el-divider>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">REQUEST AT</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.requestAt) }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">DEVICE</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.deviceRequestPrintId }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MAC</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ txn.deviceRequestMac }}</strong>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">COMPLETED AT</small>
						<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ displaydt(txn.txnAt) }}</strong>
					</span>
					<el-divider><small>PAYMENT INFO</small></el-divider>
					<el-collapse accordion>
						<el-collapse-item v-for="t in txn.tapxBankTransactions" :title="t.status">
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">MODE</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.mode.toUpperCase() }}</strong>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">STATUS</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">
									<div>{{ t.status }} | {{ t.statusCode }}</div>
									<small>{{ t.msg }}</small>
								</strong>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">REFERENCE</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.referenceId }}</strong>
							</span>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>

			<div class="row pb-2 mt-3">
				<div class="col-12 col-md-6">
					<el-divider><small>STATUS LOGS</small></el-divider>
					<el-collapse accordion>
						<el-collapse-item v-for="t in txn.tapxTxnStatusLogs.reverse()">
							<template #title>
								<div class="">
									<el-divider direction="vertical"/> <span class="badge" :class="getStatusClass(t.status)">{{ t.status }}</span>
									<el-divider direction="vertical"/> {{ displaydt(t.createdAt) }}
								</div>
							</template>

							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">OLD STATUS</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.oldStatus }}</strong>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">REASON</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.reason }}</strong>
							</span>
							<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
								<small class="mb-0 col-md-4 col-11 aml-text-teal-3 my-auto text-right">ADMIN</small>
								<strong class="mb-0 col-md-8 col-11 my-auto text-left">{{ t.adminId }} | {{ t.adminName }} | {{ t.adminType }}</strong>
							</span>
						</el-collapse-item>
					</el-collapse>
				</div>

				<div class="col-12 col-md-6 border py-2 my-auto aml-bg-gray2">
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<h6 class="mb-0 col-md-5 col-10 my-auto text-left text-md-right">STATUS</h6>
						<div class="ml-2 col-md-7 col-10 mx-auto">
							<el-select ref="status" class="select-aml-teal" v-model="statusUpdate.status" placeholder="Select">
								<el-option v-for="status in ['device_pending', 'cancel']"
										   :key="status" :label="status.toUpperCase()" :value="status"/>
							</el-select>
						</div>
					</span>
					<span class="row mt-2 mt-md-1 mx-auto justify-content-center">
						<h6 class="mb-0 col-md-5 col-10 my-auto text-left text-md-right">REASON</h6>
						<div class="ml-2 col-md-7 col-10 mx-auto">
							<el-input ref="orderId" v-model="statusUpdate.reason" placeholder="REASON" autocomplete="off"/>
						</div>
					</span>
				</div>
			</div>

			<template #footer>
			  	<span class="dialog-footer">
					<el-button @click="editStatusDialogVisible = false">Cancel</el-button>
					<el-button type="primary" @click="updateStatus()" :disabled="txn.status == statusUpdate.status" :loading="isLoading.results">
				  		Confirm
					</el-button>
			  	</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import {
		ElDivider, ElBadge, ElMessage, ElForm, ElButton, ElOption,
		ElCollapse, ElCollapseItem, ElIcon, ElTag, ElDialog, ElSelect,
	} from "element-plus";
	import moment from 'moment';
	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge, ElForm, ElButton, ElSelect, ElOption,
			ElCollapse, ElCollapseItem, ElIcon, ElTag, ElDialog,
			moment,
		},
		inject: ['$baseURL', '$numFrm', '$numFrm2', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'info-fetched', 'loading-complete', ],
		data() {
			return {
				defaultInfo: {
					filter: {
						orderId: "",
						paymentId: "",
						cardId: "",
						mobile: "",
						email: "",
					},
					transactions: [],
					txn: {},
					statusUpdate: {
						reason: '',
						status: 'cancel',
						oldStatus: '',
					},
					editStatusDialogVisible: false,
					components: { filter: false, list: false, results: false, },
					isLoading: { filter: false, list: false, results: false, },
				},
				loginUser: {},
				filter: {
					orderId: "",
					paymentId: "",
					cardId: "",
					mobile: "",
					email: "",
				},
				transactions: [],
				txn: {},
				statusUpdate: {
					reason: '',
					status: 'cancel',
					oldStatus: '',
				},
				editStatusDialogVisible: false,
				components: { filter: true, list: false, results: true, },
				isLoading: { filter: true, list: false, results: false, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('YYYY-MM-DD HH:mm:ss')  : ''; },
			getStatusClass(status) {
				switch (status) {
					case 'completed':	return 'aml-badge-completed';
					case 'device_pending':	return 'aml-badge-device-pending';
					case 'card_pending':	return 'aml-badge-card-pending';
					case 'pending':	return 'aml-badge-pending';
					default:	return 'badge-danger';
				}
			},
			updateStatus() {
				// console.log(this.statusUpdate);
				this.isLoading.results = true;
				this.statusUpdate.txnId = this.txn.id;
				axios.post(
					this.$baseURL.replace('dashboardAPI/v1', '') + 'TapX/updateStatus',
					this.statusUpdate,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.isLoading.results = false;
						if(response.data.success) {
							console.log(response.data);
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
						this.isLoading.results = false;
						this.editStatusDialogVisible = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.results = false;
					});
			},
			openUpdateModal(id, i) {
				this.isLoading.results = true;
				axios.post(
					this.$baseURL.replace('dashboardAPI/v1', '') + 'TapX/searchTxn',
					{ id },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.isLoading.results = false;
						if(response.data.success) {
							console.log(response.data);
							if(response.data.transactions.length > 0) {
								this.transactions[i] = response.data.transactions[0];
								this.txn = this.transactions[i];
								this.setDefault('statusUpdate');
								this.statusUpdate.oldStatus = this.txn.status;
								this.editStatusDialogVisible = true;
							}
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
						this.isLoading.results = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.results = false;
					});
			},
			search() {
				if(!this.filter.orderId && !this.filter.paymentId && !this.filter.cardId && !this.filter.mobile && !this.filter.email)
					return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Invalid Parameters</b><br>",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
				this.isLoading.results = true;
				this.transactions = [];
				let filter = this.filter;
				axios.post(
					this.$baseURL.replace('dashboardAPI/v1', '') + 'TapX/searchTxn',
					filter,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.isLoading.results = false;
						if(response.data.success) {
							console.log(response.data);
							this.transactions = response.data.transactions;
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
						this.isLoading.results = false;
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading.results = false;
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');

			// this.loadDetails();
			this.$emit('loading-complete');
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
	.aml-badge-completed { background-color: mediumseagreen !important; }
	.aml-badge-device-pending { background-color: #36b9ac !important; }
	.aml-badge-card-pending { background-color: #6da704 !important; }
	.aml-badge-pending { background-color: grey !important; }
</style>
