<template>
	<div>
		<div class="wrapper wrapper-full-page">
			<div
				class="full-page section-image"
				:class="pageClass"
				filter-color="black"
				data-image="/img/bg13.jpg"
			>
				<div class="content">
					<div class="container">
						<router-view></router-view>
					</div>
				</div>
				<footer class="footer">
					<div class="container-fluid">
						<nav>
							<ul>
								<!--<li>-->
									<!--<a href="https://www.creative-tim.com"> Creative Tim </a>-->
								<!--</li>-->
							</ul>
						</nav>
						<div class="copyright">
							 &copy; {{ year }},
							<a href="#">{{ $dashcontroller }}</a>. <small>{{ $appver }}</small>
						</div>
					</div>
				</footer>

				<div
					class="full-page-background"
					style="background-image: url('/img/bg13.jpg')"
				></div>
			</div>
		</div>
	</div>
</template>
<script>
	import { Navbar, NavbarToggleButton } from "@/components";

	export default {
		components: {
			Navbar,
			NavbarToggleButton,
		},
		props: {
			backgroundColor: {
				type: String,
				default: "black",
			},
		},
		inject: ["$appver"],
		data() {
			return {
				showMenu: false,
				menuTransitionDuration: 250,
				pageTransitionDuration: 200,
				year: new Date().getFullYear(),
				pageClass: `${this.$route.name}-page`.toLowerCase(),
			};
		},
		methods: {
			toggleNavbar() {
				document.body.classList.toggle("nav-open");
				this.showMenu = !this.showMenu;
			},
			closeMenu() {
				document.body.classList.remove("nav-open");
				this.showMenu = false;
			},
			setPageClass(to) {
				this.pageClass = `${to.name}-page`.toLowerCase();
			},
		},
		beforeUnmount() {
			this.closeMenu();
		},
		beforeRouteUpdate(to, from, next) {
			// Close the mobile menu first then transition to next page
			if (this.showMenu) {
				this.closeMenu();
				setTimeout(() => {
					next();
				}, this.menuTransitionDuration);
			} else {
				next();
			}
		},
		watch: {
			$route(to) {
				this.setPageClass(to);
			},
		},
	};
</script>
<style lang="scss"></style>
