<template>
	<navbar :show-navbar="showNavbar" id="navigation">
		<div class="navbar-wrapper">
			<div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
				<navbar-toggle-button v-if="!$route.query.v" @click="toggleSidebar"/>
			</div>
			<h3 class="d-md-inline-block d-none">
				{{ pageHeading }}
			</h3>
			<h3 class="d-inline-block d-md-none">
				{{ pageHeading ? pageHeading.substring(0, 12) : '' }}
			</h3>
		</div>
		<button
			@click="toggleNavbar"
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navigation"
			aria-controls="navigation-index"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span v-if="!$route.query.v">
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
			</span>
		</button>

		<template v-slot:navbar-menu>

			<ul class="navbar-nav" v-if="!$route.query.v && code">
				<li class="nav-item">
					<h1 class="d-inline"><strong>{{ code  }}</strong></h1>
				</li>
				<drop-down
					tag="li"
					position="right"
					class="nav-item"
					icon="now-ui-icons media-2_sound-wave"
				>
					<a class="dropdown-item cursor-pointer d-inline-block d-md-none text-white" @click="copyLink(  )">
						<i class="fa fa-link"></i> https://dashboard.atomx.in/#/VendorLiveLink?v={{ base64_aml(code) }}
					</a>
					<a class="dropdown-item cursor-pointer d-md-inline-block d-none" @click="copyLink(  )">
						<i class="fa fa-link"></i> https://dashboard.atomx.in/#/VendorLiveLink?v={{ base64_aml(code) }}
					</a>
					<a class="dropdown-item cursor-pointer">
						<div style="margin-top: 20px">
							<el-radio-group v-model="periodType_" @change="periodChange" size="mini">
								<el-radio-button v-for="period in ['all', 'yesterday', 'current']"
												 :key="period" :disabled="periodLoading"
												 :label="period.toUpperCase()" />
							</el-radio-group>
						</div>
					</a>
				</drop-down>
			</ul>

		</template>
	</navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "@/components";
import { copyText } from 'vue3-clipboard';

import { ElSwitch, ElTooltip, ElRadio, ElRadioGroup, ElRadioButton, ElMessage } from "element-plus";

import { ElDialog, ElForm, ElFormItem, ElButton, } from 'element-plus';
import axios from "axios";

export default {
	components: {
		Navbar,
		NavbarToggleButton,
		copyText,
		ElSwitch, ElTooltip,
		ElDialog, ElForm, ElFormItem, ElButton,
		ElRadio, ElRadioGroup, ElRadioButton,
	},
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	inject: [ '$baseURL' ],
	props: ['pageHeading', 'code', 'periodType', 'info',],
	emits: [ 'dayClosed', 'periodSelected', ],
	data() {
		return {
			activeNotifications: false,
			showNavbar: false,
			periodLoading: false,

			dayCloseDialog: false,
			dayCloseLoading: false,
			dayCloseName: '',
			dayVolCount: 0,
			periodType_: this.periodType.toUpperCase(),
		};
	},
	methods: {
		base64_aml(v) { return btoa(v); },
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		toggleNavbar() {
			this.showNavbar = !this.showNavbar;
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		periodChange(pt) {
			// console.log(this.periodType_, pt);
			this.periodLoading = true;
			this.$emit('periodSelected', pt.toLowerCase());
			setTimeout(() => { this.periodLoading = false; }, 5000);
		},
		// copyLink(url) {
		// 	copyText(url, undefined, (error, event) => {
		// 		if (error) {
		// 			alert('Can not copy')
		// 			console.log(error)
		// 		} else {
		// 			alert('Copied')
		// 			console.log(event)
		// 		}
		// 	})
		// },
		copyLink() {
			let link = 'https://dashboard.atomx.in/#/VendorLiveLink?v=' + this.base64_aml(this.code);
			let text = link + '\n\n' + this.info.name + ' | ' + this.info.id + ' | VENDOR LIVE LINK';
			copyText(text, undefined, (error, event) => {})
			// if(confirm("Do you want to open in new window?"))	window.open('https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id), '_blank');
			ElMessage({
				showClose: true,
				dangerouslyUseHTMLString: true,
				message: "<b>LINK COPIED</b><br><br>Click <a href='" + link + "' target='_blank'>here</a> to open in new window.",
				type: 'success',
				grouping: true,
				duration: 5000,
			});
		},
		closeDay(){
			this.dayCloseLoading = true;
			axios.post(
				this.$baseURL + '/Event/DayClose/',
				{
					eventId: this.code, day: this.dayCloseName, volunteerCount: this.dayVolCount,
				},
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) {
						this.dayCloseDialog = false; this.dayCloseName = '';
						this.$emit('dayClosed', response.data.log);
					}
					this.dayCloseLoading = false;
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.dayCloseLoading = false;
				});
		},
	},
	watch: {
		$route(to, from) {
			this.dayCloseLoading = false;
			this.dayCloseName = '';
			this.dayCloseDialog = false;

		},
	}
};
</script>
<style>
.nav-item .el-switch__label {
	color: #1398b0 !important;
}
.nav-item .el-switch__label.is-active {
	color: white !important;
}
.nav-item .el-switch__core {
	border-color: white !important;
}
</style>
