<template>
  <div>
    <div class="card card-stats card-raised" :class="stall.type === 'stockmaster1' ? 'aml-bg-inventory' : ''">
      <div class="card-header">
        <h5 class="card-category">{{ stall.name }} MENU</h5>
        <div class="px-0" style="top: 5px;right: 5px; position: absolute;">
          <span class="badge badge-primary" v-if="isLoading.menu">
            <small>LOADING</small> <i class="fas fa-spinner fa-spin" />
          </span>
        </div>
      </div>
      <div class="card-body">
        <div v-if="!isLoading.menu">
          <div class="my-2 text-center">
            <span class="d-inline col-1 px-0 text-center mx-2 ">
              Inactive Categories:
              <el-tooltip content="Show Inactive Categories" placement="top">
                <el-switch v-model="catg.showInactive" active-color="#13ce66" inactive-color="#ff6363"
                  :active-value=true :inactive-value=false class="my-auto" :loading="catg.loading" />
              </el-tooltip>
            </span>
            <el-button class="mr-2" size="small" plain
              :icon="isLoading.saveMenu ? 'fas fa-spin fa-spinner' : 'el-icon-download'" :disabled="isLoading.saveMenu"
              @click="downloadInventory()"> Download</el-button>
            <span class="col-3 text-right text-info cursor-pointer"
              @click="$router.push({ name: (loginUser.loginType === 'event' ? 'InventoryPage' : 'Inventory'), params: ( loginUser.loginType === 'event' ? {  inventoryType: 'GRN' } : { ...$route.params, ...{ inventoryType: 'GRN' } }) })">
              <i class="fa fa-plus-circle" /> GRN
            </span>
<!--			  v-if="stall && stall.useGrn === 1"-->
            <span class="col-3 text-right text-info cursor-pointer"
              @click="$router.push({ name: (loginUser.loginType === 'event' ? 'InventoryPage' : 'Inventory'), params: { ...$route.params, ...{ inventoryType: 'RN' } } })">
              <i class="fa fa-minus-circle" /> RN
            </span>
            <span class="col-3 text-right text-info cursor-pointer" v-if="!isLoading.menu" @click="loadMenus()">
              <i class="fa fa-cloud-download-alt" /> Menu
            </span>
            <span class="col-3 text-right text-info cursor-pointer" v-if="!isLoading.menu && stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'" @click="loadMenus(true)">
              <i class="fa fa-cloud-download-alt" /> STOCKLIST
            </span>
            	<span class="col-3 text-right text-info cursor-pointer" v-if="stall.type !== 'inventory' && !isLoading.menu" @click="addCategory()">
              <i class="fa fa-plus" /> Category
            </span>
            <el-button class="mr-2" v-if="!isLoading.menu" size="small" plain
              :icon="isLoading.saveMenu ? 'fas fa-spin fa-spinner' : 'el-icon-upload'"
              :disabled="!menuUpdated || isLoading.saveMenu" @click="confirmMenu()"> Save</el-button>
          </div>

          <el-form ref="form">
            <el-tabs v-model="categorySelected" type="border-card" tab-position="top">
              <el-tab-pane v-for="(category, index) in menu.filter(
          catg => this.catg.showInactive || (catg.status === 'active'))" :key="category.id_" :label="category.name" :name="category.id_.toString()">
                <template #label>
                  <span :class="category.status === 'active' ? '' : 'aml-text-danger-1 text-bold'">{{ category.name }} ({{ itemCount(category.items) }} )</span>
                  <span v-if="category.id === 0" class="cursor-pointer ml-2" @click="removeCategory(category.id_)"><i class="fa fa-close"> </i></span>
                </template>
                <div class="mt-3 row col-md-11 col-12 mx-auto">
                  <el-form-item class="col-12" prop="name">
                    <span class="row">
                      <h6 class="col-md-2 col-12 my-auto text-left"><i class="now-ui-icons education_atom"></i> Name</h6>
                      <el-input class="col-md-4 col-12" v-model="category.name"  :disabled="category.name === 'STOCKLIST'"
                        prefix-icon="now-ui-icons education_atom" placeholder="Category" />
                      <el-tooltip :content="'Status: ' + category.status" placement="top" v-if="category.name !== 'STOCKLIST'">
                        <el-switch v-model="category.status" active-color="#13ce66" inactive-color="#ff6363"
                          active-value="active" inactive-value="inactive" class="my-auto" />
                      </el-tooltip>
<!--                      <div class="col-md-6"-->
<!--                        v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">-->
<!--                        <h6>USE CARD DISCOUNT</h6>-->
<!--                        <el-switch v-model="category.useCardDiscount" active-color="#13ce66" inactive-color="#ff6363"-->
<!--                          :active-value="1" :inactive-value="0" class="my-auto" />-->
<!--                      </div>-->
                    </span>
                  </el-form-item>

					<div v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster'">
						<div class="mt-3 row col-12 col-md-6">
							<el-form-item class="col-6 mx-auto d-none" prop="vat">
						  <span class="row">
							<h6 class="col-12 my-auto text-left"><i class="now-ui-icons education_atom"></i> VAT</h6>
							<el-input class="col-12" v-model="category.vat" prefix-icon="now-ui-icons education_atom"
									  placeholder="VAT" />
						  </span>
							</el-form-item>
							<el-form-item class="col-6" prop="cess">
						  <span class="column">
							<span class="row">
							  <h6 class="col-12 my-auto text-left"><i class="now-ui-icons education_atom"></i> VAT</h6>
							  <el-input class="col-12" v-model="category.cess" prefix-icon="now-ui-icons education_atom"
										placeholder="CESS" />
							</span>
							<el-button class="aml-text-teal-3 btn btn-primary" type="default" @click="applyVat(category)">
							  Apply
							</el-button>
						  </span>
							</el-form-item>
						</div>

						<div class="mt-3 row col-12 col-md-6">
							<h6 class="col-12 my-auto text-left"><i class="now-ui-icons education_atom"></i> GST</h6>
							<span class="col border-right">
								<el-select class="select-primary " v-model="category.gstSlab" placeholder="Select Type"
									 autocomplete="off" allow-create filterable>
									<el-option v-for="gst in defaultGST" :key="gst" :label="gst + '%'" :value="gst" />
								</el-select>
                    		</span>
							<span class="col">
							  	<el-select class="select-primary " v-model="category.gstType" placeholder="Select Type"
										 autocomplete="off">
									<el-option v-for="type in defaultTypes" :key="type" :label="type.toUpperCase()" :value="type" />
							  	</el-select>
                    		</span>
						</div>
					</div>
                </div>

                <el-form-item class="col-12" prop="printerIp" v-if="stall.kotLan === 1">
                  <span class="row">
                    <h6 class="col-md-2 col-12 my-auto text-left"><i class="now-ui-icons education_atom"></i> Printer IP
                    </h6>
                    <el-input class="col-md-4 col-12" v-model="category.printerIp"
                      prefix-icon="now-ui-icons education_atom" placeholder="Printer IP" />
                  </span>
                </el-form-item>

                <!--ITEMS-->
                <div class="col-12 mx-auto px-0">
                  <!--								<el-button class="my-3" @click="addItem(catg)" type="dark" icon="fa fa-plus">ITEM</el-button>-->
<!--                  <el-button class="mx-auto my-4" type="info" @click="addItem(category)" icon="fas fa-plus" v-if="stall.type !== 'inventory' && category.name !== 'STOCKLIST'"> ITEM</el-button>-->
                  <el-button class="mx-auto my-4" type="info" @click="addItem(category)" icon="fas fa-plus"> ITEM</el-button>
                  <!--								<el-divider direction="vertical"></el-divider>-->
                  <span class="d-inline col-1 px-0 text-center mx-2">
                    Inactive Items:
                    <el-tooltip content="Show Inactive Items" placement="top">
                      <el-switch v-model="itm.showInactive" active-color="#13ce66" inactive-color="#ff6363"
                        :active-value=true :inactive-value=false class="my-auto" @change="" :loading="itm.loading" />
                    </el-tooltip>
                  </span>
                  <span>
                    <!--										<span class="d-inline col-1 px-0 text-center mx-2"></span>-->
                    <el-popover placement="top" :width="200" trigger="click">
                      <p>SET ITEM TYPE TO ALL</p>
                      <div>
                        <el-select class="select-primary" v-model="itemType_" placeholder="Select Type"
                          autocomplete="off" @change="setItemTypeTotAll(category.items)">
                          <el-option v-for="type in defaultItemTypes" :key="type" :label="type.toUpperCase()"
                            :value="type" />
                        </el-select>
                      </div>
                      <template #reference>
                        <el-button class="mx-auto my-4" type="info" icon="fab fa-typo3"> SET TYPE</el-button>
                      </template>
                    </el-popover>
                  </span>
                  <el-button class="float-right mt-4 aml-text-teal-3" type="default"
                    @click="downloadCSVData(category.name)" v-if="stall.type !== 'inventory' && category.name !== 'STOCKLIST'">
                    <i class="fas fa-download" /> Sample
                  </el-button>
                  <el-upload :ref="'upload' + category.id_" action="" class="d-inline ml-2 float-right mt-4"
                    :auto-upload="false" :on-change="csvChange" :data="csvData" v-if="stall.type !== 'inventory' && category.name !== 'STOCKLIST'">
                    <el-button type="default" class="aml-bg-teal-3 text-white">
                      <i class="fas fa-upload" /> Menu
                    </el-button>
                  </el-upload>


                  <div class="" v-if="category.items && category.items.length > 0">
                    <el-input class="mb-2" placeholder="Search Items" prefix-icon="el-icon-search"
                      v-model="searchItems[category.id_]" />
                    <div class="row bg-light justify-content-center">
                      	<h5 class="col-3 text-dark text-left pl-5 my-1"><small>Item</small></h5>

<!--						<h5 class="col-1 text-dark text-center my-1" v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'"><small>COST</small></h5>-->
						<h5 class="col-1 text-dark text-center my-1" v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'"><small></small></h5>
						<h5 class="col-1 text-dark text-center my-1" v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster'"><small>Price</small></h5>
						<h5 class="col-1 text-dark text-center my-1" v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster'"><small>Happy</small></h5>
						<h5 class="col-1 text-dark text-center my-1" v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster' && stall.scanMode !== 'none'"><small>HSN</small></h5>
						<h5 class="col-2 text-dark text-center my-1" v-if="stall.scanMode !== 'none'"><small><i class="fas fa-barcode" /></small></h5>

						<h5 class="col-1 text-dark text-center my-1"><small>Type</small></h5>
                      	<h5 class="col-1 text-dark text-center my-1"><small>Status</small></h5>
                      	<h5 class="col-1 text-dark text-center my-1"><small>Image</small></h5>
<!--						<div class="row col-12" v-if="(stall.type === 'stockmaster') || (hasStocklist && category.name !== 'STOCKLIST')">-->
<!--							<h5 class="col-2 text-dark text-center my-1" v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster' "><small>Parent Stock</small></h5>-->
<!--							<h5 class="col-1 text-dark text-center my-1" v-if="stall.useGrn === 1"><small>Portion ml/value</small></h5>-->
<!--							<h5 class="col-1 text-dark text-center my-1" v-if="stall.useGrn === 1 && stall.type !== 'stockmaster'"><small>Parent Stock Portion</small></h5>-->
<!--							<h5 class="col-1 text-dark text-center my-1" v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'"><small>Percent</small></h5>-->
<!--						</div>-->
                    </div>
                    <div class="row aml-item py-2 justify-content-center border-bottom" v-for="item in category.items.filter(
          item_ => (this.itm.showInactive || (item_.status === 'active')) &&
            (!searchItems[category.id_] || item_.name.toLowerCase().includes(searchItems[category.id_].toLowerCase()) || item_.barcode.toLowerCase().includes(searchItems[category.id_].toLowerCase()))
        )" :key="item.id_">
                      <span class="col-3">
                        <span v-if="item.isNew" class="col-1 my-auto text-right"><i @click="removeItem(category, item)"
                            class="fa fa-times-circle cursor-pointer"></i></span>
                        <el-input class="mt-2 px-0"
                          :class="(item.isNew ? ' col-10 ' : ' col-12 ') + (item.status === 'active' ? '' : ' aml-text-danger ')"
                          v-model="item.name"
								  :disabled="!item.isNew || category.name === 'STOCKLIST' "
								  placeholder="Item Name" />
                      </span>

						<span v-if="stall.useGrn === 1 && item.isNew && stall.type !== 'inventory' && stall.type !== 'stockmaster'" class="d-inline my-auto px-1 col-2 text-center">
<!--							<el-input class="mb-0 px-1 col-6 my-auto" type="number" v-model="item.costPrice" step=any :disabled="!item.isNew" />-->
<!--							<el-input class="mb-0 px-1 col-6 my-auto" type="number" v-model="item.mrp" step=any :disabled="!item.isNew" />-->
						</span>
						<span v-else-if="stall.useGrn === 1 && !item.isNew && stall.type !== 'inventory' && stall.type !== 'stockmaster'" class="d-inline mb-0 px-1 col-1 text-center">
<!--							<div>-->
<!--							  <h6 class="d-inline"><small>COST: {{ $numFrm(item.costPrice, 'float', event.country) }}</small></h6>-->
<!--							  <el-divider direction="vertical" />-->
<!--							  <h6 class="d-inline"><small>MRP: {{ $numFrm(item.mrp, 'float', event.country) }}</small></h6>-->
<!--							</div>-->
							<div>
							  <h6 class="d-inline"><small>QUANTITY: </small> {{ $numFrm(item.quantity, 'int', event.country) }}</h6>
							</div>
						</span>
						<el-input class="mb-0 px-1 col-1 my-auto" type="number" v-model="item.price" step=any v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster'"/>
						<el-input class="mb-0 px-1 col-1 my-auto" type="number" v-model="item.happyPrice" v-if="stall.type !== 'inventory' && stall.type !== 'stockmaster'"/>
						<el-input class="mb-0 px-1 col-1 my-auto" v-if="stall.scanMode !== 'none'" v-model="item.hsn" />
                      	<el-input class="mb-0 px-1 col-2 my-auto" v-if="stall.scanMode !== 'none'" v-model="item.barcode" />
					  	<span class="d-inline mb-0 px-1 col-1 text-center">
							<el-select class="select-primary" v-model="item.type" placeholder="Select Type" autocomplete="off">
						  		<el-option v-for="type in defaultItemTypes" :key="type" :label="type.toUpperCase()" :value="type" />
							</el-select>
					  	</span>
                      	<span class="d-inline col-1 px-0 my-auto text-center">
                        	<el-tooltip :content="'Status: ' + item.status" placement="top">
                          		<el-switch v-model="item.status" active-color="#13ce66" inactive-color="#ff6363"
										   active-value="active" inactive-value="inactive" class="my-auto" />
                        	</el-tooltip>
                      	</span>

                      <span class="d-inline col-1 px-0 my-auto text-center position-relative">
                        <div class="image-container">
                          <img v-if="item.id"
                            :src="`https://dashboard-atomx.s3.us-west-2.amazonaws.com/events/items/${item.id}.jpg`"
                            alt="Upload" class="img-fluid rounded" @mouseover="showUploadButton = item.id"
                            @mouseleave="showUploadButton = null" />
                          <div
                            class="overlay position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                            <label class="d-inline">
                              <input :key="inputKey" type="file" @change="handleFileUpload($event, item)"
                                accept="image/*" ref="fileInput" class="d-none" />
                              <i class="fa fa-upload fa-lg cursor-pointer" @click="triggerFileInput(item)"></i>
                              <!-- <i class="fa fa-upload fa-sm cursor-pointer" @click="triggerFileInput(item)"></i> -->
                              <!-- <span v-if="item.uploadedImageName" class="ml-2">{{ item.uploadedImageName }}</span> -->
                            </label>
                          </div>
                        </div>
                      </span>
<!--						<div>aml</div>-->
						<div class="row col-12" v-if="( stall.type === 'stockmaster' || stall.type === 'inventory' || (hasStocklist) )">
<!--							<el-input class="mb-0 px-1 col-1 my-auto"  v-model="item.bottleName" step=any >-->
							<span class="row col" v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'">
								<span class="col">Parent Stock Item:</span>
								<span class="d-inline mb-0 px-1 col text-center">
									<el-select class="select-primary" v-model="item.bottleName" placeholder="Select Parent Item" autocomplete="off"
											   @change="selectParentItem(item)" filterable allow-create clearable>
										<el-option v-for="parent in stocklist" :key="parent.id" :label="parent.name" :value="parent.name"/>
									</el-select>
								</span>
							</span>
<!--							</el-input>-->
							<span v-if=" item.bottleName || stall.type === 'stockmaster' || stall.type === 'inventory' " class="col-7 row">
								<span class="col row" v-if="stall.useGrn === 1">
									<span class="col">Portion (ml/value):</span>
									<el-input class="mb-0 px-1 my-auto col" type="number" v-model="item.portion" @change="item.bottlePercent = (item.portion / item.bottlePortion ).toFixed(4)" step=any />
								</span>
								<span class="col row" v-if="stall.useGrn === 1">
									<span class="col">Parent Portion:</span>
									<el-input class="mb-0 px-1 my-auto col" type="number" v-model="item.bottlePortion" @change="item.bottlePercent = (item.portion / item.bottlePortion ).toFixed(4)" step=any :disabled="stall.type !== 'stockmaster'"/>
								</span>
								<span class="col row " v-if="stall.useGrn === 1 && stall.type !== 'inventory' && stall.type !== 'stockmaster'">
									<span class="col">Percent:</span>
									<el-input class="mb-0 px-1 my-auto col" type="number" v-model="item.bottlePercent" step=any disabled/>
								</span>
							</span>
						</div>

                      <!-- <span class="d-inline col-1 px-0 my-auto text-center">
                        <label class="d-inline">
                          <input type="file" @change="handleFileUpload($event, item)" accept="image/*" ref="fileInput"
                            class="d-none" />
                          <i class="fa fa-upload fa-sm cursor-pointer" @click="triggerFileInput(item)"></i>
                          <span v-if="item.uploadedImageName" class="ml-2">{{ item.uploadedImageName }}</span>
                        </label>
                      </span> -->

                      <!-- <div class="modal fade" id="cropModal" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Crop Image</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="img-container">
                                <img :src="croppingImage" id="image-to-crop" />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                              <button type="button" class="btn btn-primary" @click="cropImage">Crop</button>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div v-if="isCropModalVisible" class="modal"
                        style="display: block; background: rgba(0, 0, 0, .3);">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Crop Image</h5>
                              <button type="button" class="close" @click="closeCropModal">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <!-- <div class="modal-body"> -->
                            <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
                              <span class="">{{ item.name }} ( {{ item.id }} )</span>
                              <img :src="croppingImage" id="imageCrop" />
                              <!-- <img :src="croppingImage" id="image-to-crop" /> -->
                              <ul class="text-danger">
                                <li>
                                  <small>Image should be landscape.</small>
                                </li>
                                <li><small>Minimum width should be 500px.</small></li>
                              </ul>
                            </div>
                            <!-- </div> -->
                            <div class=" modal-footer">
                              <button type="button" class="btn btn-secondary" @click="closeCropModal">Cancel</button>
                              <button type="button" class="btn btn-primary" @click="cropImage">Crop</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="isErrorModal" class="modal fade show" tabindex="-1" role="dialog"
                        style="display: block; background: rgba(0, 0, 0, 0.6);">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Invalid Format</h5>
                              <!-- <button type="button" class="close" @click="closeCropModal">
											<span aria-hidden="true">&times;</span>
										</button> -->
                            </div>
                            <div class="modal-body">
                              <ul class="text-danger">
                                <li>
                                  <small>Image should be landscape.</small>
                                </li>
                                <li><small>Minimum width should be 500px.</small></li>
                              </ul>

                              <!-- <span class="">{{ event.name }}({{ event.id }})</span> -->
                              <!-- <img :src="croppingImage" id="image-to-crop" /> -->
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" @click="closeErrorModal">Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
        <div v-else>
          <el-skeleton :rows="5" animated />
        </div>
      </div>
    </div>
    <span class="aml-dialog">
      <el-dialog title="Download Menu" v-model="downloadMenuDialog">
        <span class="mt-3 d-block">
          <el-form :model="downloadMenu">
            <el-form-item>
              <span class="row mt-3 mx-auto">
                <h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">Event Code</h6>
                <el-input class="col-4" placeholder="Event Code" prefix-icon="now-ui-icons education_atom" v-model="downloadMenu.event" />
                <el-button :loading="isLoading.stalls" @click="loadStalls" type="warning" icon="fa fa-download" circle />
              </span>
            </el-form-item>

            <el-form-item v-if="downloadMenu.eventName">
              <el-divider />
				  <span class="row mt-3 mx-auto">
					<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">Event Name</h6>
					<h6 class="mb-0 col-8">{{ downloadMenu.eventName }}</h6>
				  </span>
              <el-divider />
            </el-form-item>
            <el-form-item v-if="downloadMenu.stalls.length > 0">
              <span class="row mx-auto">
                <h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">{{ (stall.type !== "inventory") ? "Stalls" : "STOCKLIST" }}</h6>
                <el-select class="col-8 select-aml-teal" v-model="downloadMenu.stall" filterable :default-first-option="isStocklist"
                  placeholder="Select Stall" :disabled="downloadMenu.stalls.length === 0">
                  <el-option v-for="stall in downloadMenu.stalls" :key="stall.value" :label="stall.label" :value="stall.value" />
                </el-select>
              </span>
            </el-form-item>
          </el-form>
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="downloadMenuDialog = false" v-if="!isLoading.downloadMenu">Cancel</el-button>
            <el-button class="aml-bg-teal-3 text-white" :loading="isLoading.downloadMenu" @click="loadMenu" :disabled="downloadMenu.eventName === null">Load</el-button>
          </span>
        </template>
      </el-dialog>
    </span>
    <span class="aml-dialog">
      <el-dialog title="Confirm Menu Updates" v-model="saveMenuDialog">
        <span class="mt-3 d-block">
          <el-form ref="form_">
            <el-tabs v-model="categorySelected2" type="border-card" tab-position="top">
              <el-tab-pane v-for="(category, index) in menu_updates" :key="category.id_" :label="category.name" :name="category.id_.toString()">
                <template #label>
                  <span :class="category.status === 'active' ? '' : 'aml-text-danger-1 text-bold'">{{ category.name }} (
                    {{ itemCount(category.items) }} )</span>
                </template>
                <div class="mt-3 row mx-auto">
                  <div class="col-12 table-responsive">
                    <table class="table table-bordered col-10 mx-auto">
                      <thead class="text-primary">
                        <th>Field</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                      </thead>
                      <tbody>
                        <tr v-for="( value, key ) in category">
                          <td colspan="3" v-if="key === 'id' || key === 'id_' || key === 'items' || key === 'old'"></td>
                          <td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'">
                            <h6>{{ key }}</h6>
                          </td>
                          <td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'">{{ value }}</td>
                          <td v-if="key !== 'id' && key !== 'id_' && key !== 'items' && key !== 'old'"
                            class="text-secondary">
                            <small v-if="category.old">{{ category.old[key] }}</small>
                            <small v-else>NEW</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!--ITEMS-->
                <div class="col-12 mx-auto px-0 mt-3"
                  v-if="typeof category.items !== 'undefined' && category.items.length > 0">
                  <el-input class="mb-2" placeholder="Search Items" prefix-icon="el-icon-search"
                    v-model="searchItems[category.id_]" />

                  <div class="mt-3 row mx-auto">
                    <div class="col-12 table-responsive">
                      <table class="table table-bordered col-12 mx-auto">
                        <thead class="text-primary">
                          <th><small>Item</small></th>
                          <th v-if="stall.useGrn === 1"><small>COST</small></th>
                          <th v-if="stall.useGrn === 1"><small>MRP</small></th>
                          <th><small>Price</small></th>
                          <th><small>Happy</small></th>
                          <th><small>HSN</small></th>
                          <th v-if="stall.scanMode !== 'none'"><small><i class="fas fa-barcode" /></small></th>
                          <th><small>Type</small></th>
                          <th><small>Status</small></th>
                        </thead>
                        <tbody>
                          <tr v-for="item in category.items" :key="item.id_">
                            <td>{{ item.name }} <small v-if="item.isNew">( NEW )</small></td>
                            <td v-if="stall.useGrn === 1">{{ item.costPrice }} <small v-if="item.old && item.old.costPrice">( {{ item.old.costPrice }} )</small></td>
                            <td v-if="stall.useGrn === 1">{{ item.mrp }} <small v-if="item.old && item.old.mrp">( {{ item.old.mrp }} )</small></td>
                            <td>{{ item.price }} <small v-if="item.old && item.old.price">( {{ item.old.price }} )</small></td>
                            <td>{{ item.happyPrice }} <small v-if="item.old && item.old.happyPrice">( {{ item.old.happyPrice }} )</small></td>
                            <td>{{ item.hsn }} <small v-if="item.old && item.old.hsn">( {{ item.old.hsn }} )</small></td>
                            <td v-if="stall.scanMode !== 'none'">{{ item.barcode }} <small v-if="item.old && item.old.barcode">( {{ item.old.barcode }} )</small></td>
                            <td>{{ item.type }} <small v-if="item.old && item.old.type">( {{ item.old.type }} )</small></td>
                            <td>{{ item.status }} <small v-if="item.old && item.old.status">( {{ item.old.status }} )</small></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </el-tab-pane>
            </el-tabs>
          </el-form>
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveMenuDialog = false" v-if="!isLoading.saveMenu">Cancel</el-button>
            <el-button class="aml-bg-teal-3 text-white" :loading="isLoading.saveMenu" @click="saveMenu2()">Save</el-button>
          </span>
        </template>
      </el-dialog>
    </span>
  </div>
</template>
<script>
import {
  ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
  ElForm, ElFormItem, ElButton, ElDialog, ElDivider, ElUpload,
  ElPopover,ElInput,
} from 'element-plus';
import axios from "axios";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import Cropper from 'cropperjs';

export default {
  components: {
    ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
    ElForm, ElFormItem, ElButton, ElDialog, ElDivider, useToast, Notification,
    ElUpload, ElPopover, ElInput,
  },
  inject: ['$baseURL', '$numFrm', '$removeData',],
  props: ['event', 'stall',],
  data() {
    return {
      isErrorModal: false,
      croppingImage: null,
      showUploadButton: null,
      currentItem: null,
      cropper: null,
      inputKey: 0,
      isCropModalVisible: false, // Add this property

      originalMenu: [],
      // vat: 0,
      menu: [],
      menu_updates: [],
      menuFiltered: [],
      downloadMenu: { event: null, eventName: null, stall: null, stalls: [] },
      dEvents: [],
      dStalls: [],
      loginUser: JSON.parse(localStorage.getItem('user')),
      defaultGST: ['0', '5', '12', '18', '28'],
      defaultTypes: ['inclusive', 'exclusive'],
      defaultItemTypes: ['food', 'drink', 'merch', 'ticket', 'other'],
      categorySelected: "1",
      categorySelected2: "1",
      downloadMenuDialog: false,
		isStocklist: false,
		hasStocklist: false,
		stocklist: [],
      saveMenuDialog: false,
      isLoading: { menu: false, saveMenu: false, downloadMenu: false, stalls: false, },
      menuUpdated: false, menuLoaded: false,
      csvData: null,
      showInactiveCatgs: false,
      catg: { showInactive: true, loading: false },
      itm: { showInactive: true, loading: false },
      showInactiveItems: false,
      searchItems: {},
      itemTypesPopover: false,
      itemType_: 'food',
    }
  },
  methods: {
    // getItemPrice(price) {
    //   if (vat > 0) {
    //     return (price / (1 + vat / 100)).toFixed(2);
    //   } else {
    //     return price
    //   }
    // },
    closeErrorModal() {
      this.isErrorModal = false;
    },

    triggerFileInput(item) {
      console.log(item);
      this.currentItem = item;
      // Set the current item
      // this.$refs.fileInput.click(); // Trigger the file input dialog
    },
    handleFileUpload(event) {

      console.log("handle file")
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.croppingImage = e.target.result;
          this.isCropModalVisible = true;
          this.$nextTick(() => {
            const image = document.getElementById('imageCrop');
            this.cropper = new Cropper(image, {
              // Do not enforce aspect ratio for free cropping
              viewMode: 1,
              autoCropArea: 1, // Ensure the initial crop area is large
              responsive: true, // Ensures the cropper adjusts on window resize
              movable: true, // Allow moving the image
              zoomable: true, // Allow zooming the image
            });
          });
        };
        reader.readAsDataURL(file);
      }
    },
    closeCropModal() {
      console.log("closeCropModal")
      this.inputKey++;
      // console.log(this.$refs.fileInput.files);

      this.currentItem = null;
      this.isCropModalVisible = false;
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }

    },
    cropImage() {
      // const canvas = this.cropper.getCroppedCanvas({
      //   width: 300,
      //   height: 300,
      //   imageSmoothingQuality: 'high',
      // });
      // Create a new canvas with 300x300 dimensions
      const croppedCanvas = this.cropper.getCroppedCanvas();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const targetSize = 500;
      canvas.width = targetSize;
      canvas.height = (croppedCanvas.height / croppedCanvas.width) * targetSize;

      ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);

      if (croppedCanvas.width < croppedCanvas.height) {
        this.inputKey++;
        this.currentItem = null;
        this.isCropModalVisible = false;
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
        this.isErrorModal = true;
        return;
      }


      // // Calculate the width and height to maintain the aspect ratio
      // const ratio = Math.min(targetSize / croppedCanvas.width, targetSize / croppedCanvas.height);
      // console.log(ratio);
      // const width = croppedCanvas.width * ratio;
      // const height = croppedCanvas.height * ratio;

      // // Set canvas size to 300x300
      // canvas.width = targetSize;
      // canvas.height = targetSize;

      // // Fill the background with white (or any color you prefer)
      // ctx.fillStyle = '#ffffff';
      // ctx.fillRect(0, 0, canvas.width, canvas.height);

      // // Draw the cropped image in the center
      // const offsetX = (canvas.width - width) / 2;
      // const offsetY = (canvas.height - height) / 2;
      // ctx.drawImage(croppedCanvas, offsetX, offsetY, width, height);

      // Get the base64 string from the canvas
      const base64String = canvas.toDataURL('image/webp').split(',')[1]; // Remove the data URL prefix
      console.log(base64String);
      // Create the payload
      const payload = {
        file: base64String,
        itemId: this.currentItem.id,
        fileType: 'dashboard-item-img',
      };

      axios.post('https://fnbxapi.atomx.in/fnbx/upload', payload,)
        .then(response => {
          if (response.data.success) {
            this.currentItem.uploadedImageName = `${this.currentItem.name}.webp`;
            this.currentItem.uploadedImagePath = response.data.path;
            this.closeCropModal();
          } else {
            alert('Image upload failed!');
          }
        })
        .catch(error => {
          console.error('Image upload error:', error);
          alert('Image upload error!');
        });
    },
    applyVat(category) {
      if (category.vat != undefined && category.cess != 0) {
        category.vat = category.cess
        category.items.map((item) => {
          item.price = (item.price / (1 + category.cess / 100)).toFixed(2);
          return item;
        })
      }
      if (category.vat != category.cess && category.vat != undefined) {
        category.items.map((item) => {
          item.price = ((item.price * (1 + category.vat / 100)) / (1 + category.cess / 100)).toFixed(2);
          return item;
        }
        )
        category.vat = category.cess
      }
    },
    runToast(text, pos, type, ownText, ownIcon) {
      // const text =
      // 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
      const icon = "now-ui-icons ui-1_bell-53";
      const content = {
        component: Notification,

        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async csvChange(file) {
      let catg = this.menu.find((catg) =>
        catg.id_.toString() === this.categorySelected
      );
      this.$refs['upload' + this.categorySelected].clearFiles();
      let items_ = await file.raw.text();
      let items = items_.split('\n');
      let header = items.shift();
      if (header.split(',').length < 4) return this.runToast('', 'top-right', 'warning', "<b>File Error</b><br>File Not Formatted Correctly", "fas fa-bomb fa-4x");;
      // console.log(header, items);
      // { categoryId: catg.id, isNew: true, id_: catg.items.length + 1, price: 0, happyPrice: 0, type: 'food', status: 'active', };
      items.forEach(item => {
        let itm = item.split(',');
        if (itm.length < 8) return;
        if (typeof catg.items === 'undefined') catg.items = [];
        catg.items.push({
          categoryId: this.categorySelected, isNew: true, id_: catg.items.length + 1,
          name: itm[0] ?? '',
          costPrice: isNaN(parseFloat(itm[1])) ? 0 : parseFloat(itm[1]),
          mrp: isNaN(parseFloat(itm[2])) ? 0 : parseFloat(itm[2]),
          price: isNaN(parseFloat(itm[3])) ? 0 : parseFloat(itm[3]),
          happyPrice: isNaN(parseFloat(itm[4])) ? 0 : parseFloat(itm[4]),
          hsn: itm[5] ?? '',
			barcode: itm[6] ?? '', type: itm[7] ?? 'food', status: 'active',
        });
      });
    },
    downloadCSVData(categoryName) {
      let csv = 'Item,Cost,MRP,Price,Happy Price,HSN,Barcode,Type(food/drink/ticket/other)\n';
      csv += 'Item Name 1,0,0,0,0,,,food\n';
      csv += 'Item Name 2,0,0,0,0,,,food\n';
      // this.csvdata.forEach((row) => {
      // 	csv += row.join(',');
      // 	csv += "\n";
      // });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = categoryName + '.csv';
      anchor.click();
    },
    downloadInventory() {
      let csv = 'CATEGORY,CATEGORY STATUS,ITEM,COST,MRP,PRICE,HAPPY PRICE,BARCODE,QUANTITY,ITEM STATUS\n';
      this.menu.forEach(category => {
        category.items.forEach(item => {
          csv += [category.name, category.status,
          item.name, item.costPrice, item.mrp, item.price, item.happyPrice,
          item.barcode, item.quantity, item.status
          ].join(',') + '\n';
        });
      });
      // return console.log(csv);
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = this.stall.name + ' Inventory Counts' + '.csv';
      anchor.click();

    },
    async catgStatusToggle() {
      this.catg.loading = true;
      // await new Promise((resolve, reject) => this.filterCatg() );
      // await new Promise(async (resolve, reject) => {
      // 	this.menuFiltered = await this.menu.filter(
      // 		catg => this.catg.showInactive || (catg.status === 'active'));
      //
      // 		setTimeout(() => { resolve( true ); }, 1500);
      // 	}
      // )
      // .then( (resolve, reject) => {}  )
      // ;
      // this.updateLoading(this.catg.loading);
      // this.catg.loading = false;
      // setTimeout(() => { this.catg.loading = false; }, 1500);

      // this.menuFiltered = async(m, e) =>

      // const asyncFilter = async (arr, predicate) => {
      // 	const results = await Promise.all(arr.map(predicate));
      // 	return arr.filter((_v, index) => results[index]);
      // }

      const asyncFilter = async (arr, predicate) =>
        arr.reduce(async (memo, e) =>
          await predicate(e) ? [...await memo, e] : memo
          , []);

      this.menuFiltered = await asyncFilter(this.menu, async (i) => {
        // await sleep(10);
        return this.catg.showInactive || (i.status === 'active');
      });

      this.catg.loading = false;
    },
    setItemTypeTotAll(items) {
      items.forEach(itm => { itm.type = this.itemType_; });
    },
    itemCount(items) {
      return items ? items.filter(item => item.status === 'active').length : 0;
    },
    updateLoading(q) { q = !q },
    itemStatusToggle(items) {
      let i = items.filter(item => this.itm.showInactive ||
        (item.status === 'active'));
      return i
    },
    addItem(catg) {
      if (catg.items) catg.items.push({ categoryId: catg.id, isNew: true, id_: catg.items.length + 1, costPrice: 0, mrp: 0, price: 0, happyPrice: 0,
		  portion: 1 ,
		  bottlePercent: 100,
		  bottleName: '', type: 'food', status: 'active', });
      else catg.items = [{ categoryId: catg.id, isNew: true, id_: 1, costPrice: 0, mrp: 0, price: 0, happyPrice: 0,
		  portion: 1 ,
		  bottlePercent: 100,
		  bottleName: '', type: 'food', status: 'active', }];
    },
    addCategory() {
      let id_ = (this.menu.length + 1).toString() + new Date().getTime().toString();
      this.menu.push({ id: 0, id_, name: "New Category", gstSlab: 0, gstType: 'inclusive', vat: 0, cess: 0, status: 'active', stallId: this.$route.params.stallCode, items: [], });
      this.categorySelected = id_;
      // setTimeout(() => { this.$refs[ 'catg' + this.menus.length ][0].click(); }, 50);
    },
	  selectParentItem(item) {
		// console.log(item, item.bottleName);
		  this.stocklist.forEach(parent => {
			  if(parent.name === item.bottleName)	{
				  item.bottlePortion = parent.portion;
			  }
		  });
		  item.bottlePercent = (item.portion / item.bottlePortion).toFixed(4);
	  },
	  loadMenus(isStocklist = false) {
		  this.downloadMenuDialog = true;
		  this.downloadMenu.event =
			  (this.stall.type === 'inventory') ? this.event.id:
				  isStocklist ? this.event.id : '';
		  this.isStocklist = isStocklist;

	  },
    loadStalls() {
      this.isLoading.stalls = true;
      if (this.downloadMenu.event === '' || this.downloadMenu === null) {
        this.runToast('', 'top-right', 'danger', "<b>Event Not Selected</b><br>Please try again.", "fas fa-bomb fa-4x");;
        return this.isLoading.stalls = false;
      }
      axios.get(
        this.$baseURL + '/Stalls/List',
        {
          params: { code: this.downloadMenu.event,  type: this.stall.type, isStocklist: this.isStocklist },
          headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
        }
      )
        .then(response => {
          if (response.data.success) {
            this.downloadMenu.stalls = response.data.stalls;
            if (response.data.stalls && response.data.stalls.length > 0)
              this.downloadMenu.eventName = response.data.stalls[0].event_name;
            else {
              this.downloadMenu.eventName = null;
              this.downloadMenu.stall = null;
            }
          }
          this.isLoading.stalls = false;
        })
        .catch((error) => {
          this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
          console.log("err:", error);
          this.isLoading.stalls = false;
        });
    },
    loadMenu() {
      this.loadItems(this.downloadMenu.stall, this.downloadMenu.event, false);
    },
    removeCategory(id_) {
      // this.menu.forEach(category => {
      //
      // });
      this.$removeData(this.menu, id_, 'id_');
      this.categorySelected = this.menu.length > 0 ? this.menu[0].id_.toString() : null;
    },
    removeItem(catg, item) {
      this.$removeData(catg.items, item.id_, 'id_');
    },
    saveMenu() {
      this.menu_updates = [];
      this.menu.forEach(function (category, i) {
        let catg = { old: {}, name: category.name }, catgUpdated = false;
        if (category.id === 0) { catg = category; this.menu_updates.push(catg); }
        else {

          Object.entries(category).forEach((c) => {
            let key = c[0], value = c[1];
            if (key !== 'id_' && key !== 'items' && value !== this.originalMenu[i][key]) { catg[key] = value; catg.old[key] = this.originalMenu[i][key]; catgUpdated = true; }
          });
          // for( const [key, value] of Object.entries(category) ) {
          // 	if(key.toLowerCase() !== 'id_' && key.toLowerCase() !== 'items' && value !== this.originalMenu[i][key])
          // 		{
          // 			debugger;
          // 			catg[key] = value; catg_old[key] = value; catgUpdated = true;
          // 		}
          // }
          // if( category.name !== this.originalMenu[i].name )	{ catg.name = category.name; catg_old.name = this.originalMenu[i].name; catgUpdated = true; }
          // if( category.vat !== this.originalMenu[i].vat )	{ catg.vat = category.vat; catg_old.vat = this.originalMenu[i].vat; catgUpdated = true; }
          // if( category.cess !== this.originalMenu[i].cess )	{ catg.cess = category.cess; catg_old.cess = this.originalMenu[i].cess; catgUpdated = true; }
          // if( category.gstSlab !== this.originalMenu[i].gstSlab )	{ catg.gstSlab = category.gstSlab; catg_old.gstSlab = this.originalMenu[i].gstSlab; catgUpdated = true; }
          // if( category.gstType !== this.originalMenu[i].gstType )	{ catg.gstType = category.gstType; catg_old.gstType = this.originalMenu[i].gstType; catgUpdated = true; }
          // if( category.status !== this.originalMenu[i].status )	{ catg.status = category.status; catg_old.status = this.originalMenu[i].status; catgUpdated = true; }

          category.items.forEach((item, j) => {
            let itm = { old: {}, name: item.name }, itemUpdated = false;
            if (item.id === 0) {
              if (typeof catg.items === 'undefined') { catg.items = []; }
              itm.categoryId = category.id; catg.items.push(itm);
              catgUpdated = true;
            }
            else {
              Object.entries(item).forEach((c) => {
                let key = c[0], value = c[1];
                if (key !== 'id_' && value !== this.originalMenu[i].items[j][key]) { itm[key] = value; itm.old[key] = this.originalMenu[i].items[j][key]; itemUpdated = true; }
              });
              // for( const [key, value] of Object.entries(item) ) {
              // 	if(key !== 'id_' && value !== this.originalMenu[i].items[j][key])
              // 		{ itm[key] = value; itm_old[key] = value; itemUpdated = true; }
              // }

              // if( item.name !== this.originalMenu[i].items[j].name )	{ itm.name = item.name; itm_old.name = this.originalMenu[i].items[j].name; itemUpdated = true; }
              // if( item.price !== this.originalMenu[i].items[j].price )	{ itm.price = item.price; itm_old.price = this.originalMenu[i].items[j].price; itemUpdated = true; }
              // if( item.happyPrice !== this.originalMenu[i].items[j].happyPrice )	{ itm.happyPrice = item.happyPrice; itm_old.happyPrice = this.originalMenu[i].items[j].happyPrice; itemUpdated = true; }
              // if( item.costPrice !== this.originalMenu[i].items[j].costPrice )	{ itm.costPrice = item.costPrice; itm_old.costPrice = this.originalMenu[i].items[j].costPrice; itemUpdated = true; }
              // if( item.mrp !== this.originalMenu[i].items[j].mrp )	{ itm.mrp = item.mrp; itm_old.mrp = this.originalMenu[i].items[j].mrp; itemUpdated = true; }
              // if( item.itemCode !== this.originalMenu[i].items[j].itemCode )	{ itm.itemCode = item.itemCode; itm_old.itemCode = this.originalMenu[i].items[j].itemCode; itemUpdated = true; }
              // if( item.position !== this.originalMenu[i].items[j].position )	{ itm.position = item.position; itm_old.position = this.originalMenu[i].items[j].position; itemUpdated = true; }
              // if( item.type !== this.originalMenu[i].items[j].type )	{ itm.type = item.type; itm_old.type = this.originalMenu[i].items[j].type; itemUpdated = true; }
              // if( item.hsn !== this.originalMenu[i].items[j].hsn )	{ itm.hsn = item.hsn; itm_old.hsn = this.originalMenu[i].items[j].hsn; itemUpdated = true; }
              // if( item.barcode !== this.originalMenu[i].items[j].barcode )	{ itm.barcode = item.barcode; itm_old.barcode = this.originalMenu[i].items[j].barcode; itemUpdated = true; }
              // if( item.status !== this.originalMenu[i].items[j].status )	{ itm.status = item.status; itm_old.status = this.originalMenu[i].items[j].status;  itemUpdated = true; }

              if (itemUpdated) {
                if (typeof catg.items === 'undefined') { catg.items = []; }
                itm.id = item.id;
                catg.items.push(itm); catgUpdated = true;
              }
            }
          });

          if (catgUpdated) {
            catg.id_ = category.id_;
            catg.id = category.id;
            this.menu_updates.push(catg);
          }
        }
      }.bind({ originalMenu: this.originalMenu, menu_updates: this.menu_updates }));
      // console.log(this.menu_updates);
      return this.saveMenuDialog = true;
      this.isLoading.saveMenu = true;
      // return console.log("menu: ", this.menu);

      axios.post(
        this.$baseURL + '/Menu/' + this.$route.params.stallCode + '/Save',
        this.menu,
        { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
      )
        .then(response => {
          // console.log("saved menu", response);
          this.isLoading.saveMenu = false;
          if (response.data.success) {
            this.alert = { show: true, type: 'success', icon: "ni ni-like-2", message: { strong: 'Success!', normal: "Menu Save Success!" } };
            // this.menus = response.data.menus;
            this.loadItems(this.$route.params.stallCode, this.event.id, true);
          } else {
            this.alert = { show: true, type: 'danger', icon: "ni ni-like-2", message: { strong: 'Failed!', normal: "Menu Save Failed!" } };
          }
        })
        .catch((error) => {
          console.error(error);
          this.isLoading.saveMenu = false;
          this.alert = { show: true, type: 'warning', icon: "ni ni-like-2", message: { strong: 'Error!', normal: "Something went wrong!" } };
        });
    },
    saveMenu2() {
      this.isLoading.saveMenu = true;
      // return console.log("menu: ", this.menu);

      axios.post(
        this.$baseURL + '/Menu/' + this.$route.params.stallCode + '/Save2',
        this.menu_updates,
        { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
      )
        .then(response => {
          // console.log("saved menu", response);
          this.isLoading.saveMenu = false;
          if (response.data.success) {
            this.alert = { show: true, type: 'success', icon: "ni ni-like-2", message: { strong: 'Success!', normal: "Menu Save Success!" } };
            // this.menus = response.data.menus;
            this.loadItems(this.$route.params.stallCode, this.event.id, true);
            this.saveMenuDialog = false;
          } else {
            this.alert = { show: true, type: 'danger', icon: "ni ni-like-2", message: { strong: 'Failed!', normal: "Menu Save Failed!" } };
          }
        })
        .catch((error) => {
          console.error(error);
          this.isLoading.saveMenu = false;
          this.alert = { show: true, type: 'warning', icon: "ni ni-like-2", message: { strong: 'Error!', normal: "Something went wrong!" } };
        });
    },
    confirmMenu() {
      this.menu_updates = [];
      this.menu.forEach(function (category, i) {
        let catg = {}, catgUpdated = false;
        catg.old = {}; catg.name = category.name
        if (!category.id) {
          catg = JSON.parse(JSON.stringify(category))
          this.menu_updates.push(catg);
        }
        else {
          Object.entries(category).forEach((c) => {
            let key = c[0], value = c[1];
            if (key !== 'id_' && key !== 'items' && value !== this.originalMenu[i][key]) { catg[key] = value; catg.old[key] = this.originalMenu[i][key]; catgUpdated = true; }
          });
          if (category.items && category.items.length > 0) {
            category.items.forEach((item, j) => {
              let itm = {}, itemUpdated = false;
              itm.old = {};
              itm.name = item.name;
              if (!item.id) {
                if (typeof catg.items === 'undefined') {
                  catg.items = [];
                }
                itm = JSON.parse(JSON.stringify(item));
                catg.items.push(itm);
                catgUpdated = true;
                // console.log(item, itm);
              } else {
                Object.entries(item).forEach((c) => {
                  let key = c[0], value = c[1];
                  if (key !== 'id_' && key !== 'id_' && value !== this.originalMenu[i].items[j][key]) {
                    itm[key] = value;
                    itm.old[key] = this.originalMenu[i].items[j][key];
                    itemUpdated = true;
                  }
                });
                if (itemUpdated) {
                  if (typeof catg.items === 'undefined') catg.items = [];
                  itm.id = itm.id_ = item.id;
                  catg.items.push(itm);
                  catgUpdated = true;
                }
              }
            });
          } else { category.items = []; }

          if (catgUpdated) {
            catg.id_ = category.id_;
            catg.id = category.id;
            this.menu_updates.push(catg);
          }
        }
      }.bind({ originalMenu: this.originalMenu, menu_updates: this.menu_updates }));
      // console.log(this.menu_updates);
      // if(this.menu_updates.length > 0)	this.categorySelected2 = this.menu_updates[0].id_.toString();
      // return this.saveMenuDialog = true;
      if (this.menu_updates.length > 0) {
        this.categorySelected2 = this.menu_updates[0].id_.toString();
        return this.saveMenuDialog = true;
      } else this.menuUpdated = false;
    },
    loadItems(stall, code, isDefault) {
      this.isLoading.menu = true;
      this.isLoading.downloadMenu = true;
      this.catg.loading = true;
      this.itm.loading = true;
      axios.get(
        this.$baseURL + "/Menu/List",
        {
          params: { stall, code, isStocklist: this.isStocklist, },
          headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
        }
      )
        .then(response => {
          // console.log(response);
          if (response.data.success) {
            // console.log(response.data);
            if (isDefault) {
              this.menuLoaded = true;
              this.menu = response.data.menu;
				this.menu.forEach(c => {
					c.id_ = c.id;
					if(c.name === 'STOCKLIST') {
						this.hasStocklist = true;
						this.stocklist = c.items;
						console.log(this.stocklist);
					}
				});
				this.originalMenu = JSON.parse(JSON.stringify(this.menu));
				if (this.menu.length > 0) this.categorySelected = this.menu[0].id.toString();
				else this.categorySelected = "0";

				// if(this.stall.type === 'inventory') {
				// 	Promise.all(this.menu.map( (c) => {
				// 		items_ = items_.concat(c.items);
				// 		c.item
				// 	}));
				// }
				// console.log(this.menu);

            } else {
              let newMenu = response.data.menu;
				if(newMenu.length === 0) 	{
					this.isLoading.menu = false;
					this.isLoading.downloadMenu = false;
					return this.runToast('', 'top-right', 'warning', "<b>No Items in the selected stall</b><br>Please try again.", "fas fa-bomb fa-4x");
				}
              let n = new Date().getTime().toString();
              // newMenu.forEach(category => {
              newMenu.forEach((category, index, object) => {
				  if(this.isStocklist && category.name === 'STOCKLIST') {
					  this.hasStocklist = true;
					  this.stocklist = category.items;
					  console.log(this.stocklist);
				  }
				  if(!this.isStocklist && category.name === 'STOCKLIST') {
					  object.splice(index, 1);
					  return;
				  }
                category.id_ = category.id + n;
                category.id = 0;
                category.stallId = this.$route.params.stallCode;
                category.items.forEach(item => {
                  item.isNew = true;
                  item.categoryId = 0;
                  item.id_ = item.id + n;
                  delete item.id;
                  item.quantity = 0;
				  item.bottleName = this.isStocklist ? item.name : '';
				  // item.bottlePortion = this.isStocklist ? item.portion : 0;
				  // item.portion = this.isStocklist ? item.portion : 1;
				  item.bottlePercent = 1;
				  // item.status = this.isStocklist? 'inactive' : 'active';
                });

              });
              // console.log(newMenu);
              this.menu = [...this.menu, ...newMenu];
              this.menu_updates = [];
              this.categorySelected = newMenu[0].id_.toString();
            }
          }
          this.menuFiltered = JSON.parse(JSON.stringify(this.menu.filter(catg => this.catg.showInactive || (catg.status === 'active'))));
          this.isLoading.menu = false;
          this.isLoading.downloadMenu = false;
          this.downloadMenuDialog = false;
          this.catg.loading = false;
          this.itm.loading = false;
        })
        .catch((error) => {
          this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
          console.log("err:", error);
          this.isLoading.menu = false;
          this.isLoading.downloadMenu = false;
        });
    },
  },
  mounted() {
    console.log(this.$refs.fileInput);
    this.loadItems(this.$route.params.stallCode, this.event.id, true);
  },
  watch: {
    event: {
      handler(a) {
        // console.log("event update detect", a);
        if (a) this.loadItems(this.$route.params.stallCode, this.event.id, true);
      },
      deep: true,
    },
    menu: {
      handler(a) {
        // let b = JSON.stringify({menu: this.originalMenu}) === JSON.stringify({ menu: a });
        // this.menuUpdated = !b;
        // console.log("menu update detect", b, a);
        // this.menuUpdated = this.menuLoaded;
        if (this.menuLoaded) this.menuLoaded = false;
        else this.menuUpdated = true;
      },
      deep: true,
    },
  }
}
</script>
<style>
.aml-text-danger .el-input__inner {
  color: #ff6363 !important;
  border-color: #ff6363 !important;
  background-color: #f003 !important;
}

.el-input__inner {
  padding: 0px 0px 0px 5px;
}

.modal-dialog {
  max-width: 500px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 50px;
  height: auto;
}

.overlay {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: Background color with opacity */
  color: white;
  /* Text color for upload icon */
  opacity: 0;
  /* Hidden by default */
  transition: opacity 0.3s ease;
  /* Smooth transition */
  pointer-events: none;
  /* Allow clicks through the overlay */
}

.image-container:hover .overlay {
  opacity: 1;
  /* Show overlay on hover */
  pointer-events: auto;
  /* Allow clicks on the overlay */
}

.overlay i {
  font-size: 24px;
  /* Size of the upload icon */
}

.cropper-container {
  display: none !important
}

.cropper-bg {
  display: none !important
}

.hidden-input {
  display: none;
}
</style>
