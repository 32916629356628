<template>
	<div style="min-height: 2000px;">
		<div class="row " >
			<div class="col-12">
				<Items v-if="components.items" :info="info" :vendor="vendor" :user="loginUser"/>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import Items from './items/items';
	import { ElDivider, ElBadge } from 'element-plus';


	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			Items
		},
		inject: ['$baseURL', '$numFrm', ],
		emits: [ 'info-fetched', 'loading-complete' ],
		props: [ 'info', 'user', ],
		data() {
			return {
				defaultInfo: {
					info_: {},
					event: {  },
					stall: {  },
					components: { items: false,  },
					isLoading: { items: true, },
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				vendor: {  },
				components: { items: false,  },
				isLoading: { items: true, },
				vendorId: 0,
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				let code = this.loginUser.loginType === 'admin' ? this.loginUser.id : this.loginUser.clientId;
				let url = '/IISER/Admin/Details/';
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						// console.log(response.data);
						// this.$emit('loading-complete');
						this.info_ = response.data.info;
						// this.$emit('info-fetched', response.data.info);
						this.loadVendor();
					} else {
						//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
			},
			loadVendor() {
				let code = this.vendorId;
				let url = '/IISER/Vendor/Details/';
				axios.get(
					this.$baseURL + url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						this.vendor = response.data.info;
						this.components.items = true;
						this.info_.name = this.vendor.name;
						this.$emit('info-fetched', this.info_);
						this.$emit('loading-complete');
					} else {
						//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message, "fas fa-bomb fa-4x");
					}
				})
				.catch( (error) => {
					//this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
				});
			},
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			// setTimeout(this.loadDetails, 500);
			this.vendorId = this.$route.params.vendorCode ?? ( this.loginUser.id ?? 0 );

			this.loadDetails();
			// this.loadVendor();
			window.scrollTo(0,0);
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				// this.setLoading();
				// clearInterval(this.interval_fn);
				this.setDefault();
				this.loadDetails();
				// this.event = {};
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
