<template>
	<div>
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category">VOLUNTEERS ( {{ eventVolunteers.length }} )</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<span class="col-3 text-right text-info cursor-pointer" @click="volunteerDialog = true"><i class="fa fa-plus"></i> ADD Volunteer</span>
					<span class="badge badge-primary" v-if="isLoading.eventVolunteers">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<el-input placeholder="Search Volunteer" size="mini" prefix-icon="el-icon-search" v-model="search"/>
				<el-table class="w-100 aml-font-11 aml-volunteers-table"
				  :data="eventVolunteers.filter(
					data => !search ||
						(data.volunteer.name && data.volunteer.name.first.toLowerCase().includes(search.toLowerCase()) ) ||
						(data.volunteer.name && data.volunteer.name.last.toLowerCase().includes(search.toLowerCase()) ) ||
						(data.volunteer.kyc && data.volunteer.kyc.pan.toLowerCase().includes(search.toLowerCase()) ) ||
						(data.volunteer.kyc && data.volunteer.kyc.aadhar.toString().includes(search.toLowerCase()) ) ||
						(data.volunteer.kyc && data.volunteer.kyc.mobile.toString().includes(search.toLowerCase()) ) ||
						(data.volunteer.kyc && data.volunteer.kyc.mobile2.toString().includes(search.toLowerCase()) ) ||
						data.volunteer.city.toString().toLowerCase().includes(search.toLowerCase())
					)"
						  :height="eventVolunteers.length <= 5 ? '200' : eventVolunteers.length > 10 ? '500' : eventVolunteers.length > 20 ? '800' : '300' "
				>
					<el-table-column label="#" type="index" width="30"/>
	<!--				<el-table-column sortable label="Volunteer" prop="vendor_name"/>-->
	<!--				<el-table-column sortable label="STALL" prop="stall_name"/>-->
	<!--				<el-table-column sortable label="DEVICE" prop="volunteers_main_printId" align="center"/>-->
					<!--<el-table-column sortable label="DEVICE" prop="volunteers_main_type" align="center"/>-->
					<el-table-column sortable label="Volunteer" align="center">
						<template #default="scope">
							<h4 class="d-inline">{{ scope.row.volunteer.name ? scope.row.volunteer.name.first + ' ' + scope.row.volunteer.name.last : '' }}</h4> <span class="ml-3 badge badge-info">{{ scope.row.status.toLowerCase() }}</span>
							<h6 v-if="scope.row.volunteer.kyc"> {{ scope.row.volunteer.kyc.mobile }} <el-divider direction="vertical" v-if="scope.row.volunteer.kyc.mobile2"/> {{ scope.row.volunteer.kyc.mobile2 }} <el-divider direction="vertical" v-if="scope.row.volunteer.kyc.aadhar"/> {{ scope.row.volunteer.kyc.aadhar }} <el-divider direction="vertical" v-if="scope.row.volunteer.kyc.pan"/> {{ scope.row.volunteer.kyc.pan }} </h6>
						</template>
					</el-table-column>
<!--					<el-table-column sortable label="STATUS" prop="status"/>-->
<!--					<el-table-column label="EDIT" align="center" width="70">-->
<!--						<template #default="scope">-->
<!--							<span class="d-block cursor-pointer" @click="volunteerEdit(scope.row)"> <i class="fas fa-edit"/> </span>-->
<!--						</template>-->
<!--					</el-table-column>-->
				</el-table>
			</div>
		</div>

		<span class="aml-dialog">
			<el-dialog title="Add Volunteer" v-model="volunteerDialog">
				<el-input class="mt-4" placeholder="Search Volunteer" size="mini" prefix-icon="el-icon-search" v-model="search"/>
				<el-table class="w-100 aml-font-11 aml-volunteers-table"
						  :data="volunteersMasterlist.filter(
						data => !search ||
							(data.name && data.name.first.toLowerCase().includes(search.toLowerCase()) ) ||
							(data.name && data.name.last.toLowerCase().includes(search.toLowerCase()) ) ||
							(data.kyc && data.kyc.pan.toLowerCase().includes(search.toLowerCase()) ) ||
							(data.kyc && data.kyc.aadhar.toString().includes(search.toLowerCase()) ) ||
							(data.kyc && data.kyc.mobile.toString().includes(search.toLowerCase()) ) ||
							(data.kyc && data.kyc.mobile2.toString().includes(search.toLowerCase()) ) ||
							data.city.toString().toLowerCase().includes(search.toLowerCase())
						)"
						  :height="volunteersMasterlist.length <= 5 ? '200' : volunteersMasterlist.length > 10 ? '500' : volunteersMasterlist.length > 20 ? '800' : '300' "
						  ref="volunteerList"
						  @selection-change="handleSelectionChange"
				>
					<el-table-column type="selection" width="50" />
<!--					<el-table-column label="#" type="index" width="50"/>-->
					<el-table-column sortable label="Volunteer">
						<template #default="scope">
							<h4 class="d-inline">{{ scope.row.name ? scope.row.name.first + ' ' + scope.row.name.last : '' }}</h4> <span class="ml-3 badge badge-info">{{ scope.row.status.toLowerCase() }}</span>
							<h6 v-if="scope.row.kyc"> {{ scope.row.kyc.mobile }} <el-divider direction="vertical" v-if="scope.row.kyc.mobile2"/> {{ scope.row.kyc.mobile2 }} <el-divider direction="vertical" v-if="scope.row.kyc.aadhar"/> {{ scope.row.kyc.aadhar }} <el-divider direction="vertical" v-if="scope.row.kyc.pan"/> {{ scope.row.kyc.pan }} </h6>
						</template>
					</el-table-column>
				</el-table>
				<template #footer>
					<span class="dialog-footer">
					  <el-button @click="volunteerDialog = false" v-if="!isLoading.volunteers">Cancel</el-button>
					  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="isLoading.volunteers" @click="saveVolunteer">Confirm</el-button>
					</span>
				</template>
			</el-dialog>
		</span>
	</div>

</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElDatePicker, ElOptionGroup,
	} from "element-plus";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption, ElOptionGroup,
			ElSwitch, ElDivider, ElDatePicker,
		},
		props: [ 'event' ],
		inject: [ '$baseURL', '$formatDateTime', '$isCheckEventType', ],
		emits: [  ],
		data() {
			return {
				search: '',
				reportLoading: false,
				defaultTypes: [ { value: 'summary', label: 'Summary' }, { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' }, { value: 'all_txns', label: 'BASIC TRANSACTION DUMP' }, { value: 'balances', label: 'Remaining Balances' }, { value: 'partials', label: 'Partial Transactions' }, ],
				defaultReports: [ ],
				eventVolunteers: [],
				vendors: [], stalls: [], volunteerDialog: false,
				volunteer: {},
				volunteerRow: {},
				defaultInfo: {  eventVolunteers: [], volunteersMasterlist: [], stalls: [], },
				volunteerLoading: false,
				volunteersMasterlist: [],
				isLoading: { eventVolunteers: false, volunteers: false, },
				filter: { dates: [], report: null, type: '', settings: { details: false, summary: false, type: 'xlsx', dates: false, mobile: false, discount: false, sc: false, nfc: false, mode: false, bank: false, } },
				searchLoading: false,
				defaultVolunteerTypes: [
					{ label: 'TOPUP CASH', value: 'topup_cash' }, { label: 'TOPUP CARD', value: 'topup_card' },
					{ label: 'TOPUP COMPLIMENTARY', value: 'topup_comp' }, { label: 'TOPUP CORPORATE', value: 'topup_corp' },
					{ label: 'TOPUP EMPLOYEE', value: 'topup_emp' }, { label: 'TOPUP SPECIAL', value: 'topup_spl' },
					{ label: 'TOPUP KIOSK', value: 'topup_kiosk' }, { label: 'TOPUP KIOSK ONLINE', value: 'topup_kiosk_online' },
					{ label: 'TOPUP KIOSK OFFLINE', value: 'topup_kiosk_offline' },
					{ label: 'RETURN', value: 'return' }, { label: 'MERGE', value: 'merge' },
					{ label: 'SALE MENU', value: 'sale_menu' }, { label: 'SALE INVOICE', value: 'sale_invoice' }, { label: 'SALE KIOSK', value: 'sale_kiosk' },
				],
				defaultPrinters: [ 'rugtek', 'blue2', 'wisepos+', 'sprin_3', 'sprin_2', 'panda', 'none', ],
			}
		},
		mounted() {
			this.loadDetails();
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			volunteerEdit(volunteer) {
				// this.volunteer.id = volunteer.volunteer_id;
				// this.volunteer.stall = volunteer.volunteer_stall;
				// this.volunteer.printer = volunteer.volunteer_printer;
				// this.volunteer.volunteer = volunteer.volunteers_main_printId;
				// this.volunteer.type = volunteer.volunteer_type;
				//
				// this.volunteerRow = volunteer;

				this.volunteerDialog = true;
			},
			handleSelectionChange(vol) {
				console.log(vol);
				// this.$refs['volunteerList'].data;
				// debugger;
			},
			getVolunteerList(dataType) {
				this.isLoading.eventVolunteers = true;
				axios.get(
					this.$baseURL + '/Volunteers/List/',
					{ params: { code: this.event.id, type: dataType },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(dataType === 'event-wise') {
						this.setDefault('eventVolunteers');
						this.eventVolunteers = response.data.volunteers;
						this.isLoading.eventVolunteers = false;
					}
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.eventVolunteers = false;
				});
			},
			loadDetails() {
				this.isLoading.volunteersMasterlist = true;
				axios.get(
					this.$baseURL + '/Volunteers/Masterlist/All/',
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							this.volunteersMasterlist = response.data.volunteers;
						}
						this.isLoading.volunteersMasterlist = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.volunteersMasterlist = false;
					});
			},
			saveVolunteer() {
				// debugger;
				this.isLoading.volunteers = true;
				// let data = this.$refs['volunteerList'].data;
				if( !this.$refs['volunteerList'] || !this.$refs['volunteerList'].data || this.$refs['volunteerList'].data.length === 0 ) return;
				axios.post(
					this.$baseURL + '/Volunteers/Add',
					{ volunteers: this.$refs['volunteerList'].data, eventId: this.event.id },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							if(response.data.volunteer) this.eventVolunteers.push(response.data.volunteer);
							this.volunteerDialog = false;
						} else this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
						this.isLoading.volunteers = false;
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.volunteers = false;
					});
			},
		},
		watch: {
			event(newEvent, from) {
				if(this.$isCheckEventType('fnb', newEvent.type)) {
					this.getVolunteerList('event-wise');
					// this.loadConfigData('Vendors');
					// this.loadConfigData('Stalls');
				}
			},
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
	.aml-report-date-filter .el-date-editor .el-range-input { width: 80% !important; }
	.aml-volunteers-table.el-table table > thead > tr > th { font-size: 12px; }
</style>
