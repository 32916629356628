<template>
	<div>
		<div class="row " >
			<div class="col-12 mx-auto">
				<div class="card card-stats card-raised">
					<div class="card-header">
						<h5 class="card-category">DEVICES ( {{ devices.length }} )</h5>
						<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
							<span class="col-3 text-right text-info cursor-pointer" @click="deviceOpen('add')"><i class="fa fa-plus"></i> ADD Device</span>
							<span class="badge badge-primary" v-if="isLoading.devices">
								<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
							</span>
						</div>
					</div>
					<div class="card-body">
						<div class="col-md-11 col-12 mx-auto">
							<el-input placeholder="Search Device" size="mini" prefix-icon="el-icon-search" v-model="search"/>

							<div class="row border-bottom border-top mt-2 py-3 d-block justify-content-end" style="box-shadow: -4px 10px 10px 0px rgb(250 250 250);">
								<div class="row col-12">
									<h5 class="text-primary mb-0 col-12 col-md-10 d-inline">DEVICE</h5>
									<h5 class="text-primary mb-0 col-md-2 d-none d-md-inline pull-right text-center">EDIT</h5>
								</div>
							</div>

							<div class="row" style="height: 500px; overflow:auto;">
								<div class="col-12 row border-bottom"
									 v-for="device in devices.filter(
										data => !search ||
											data.type.toLowerCase().includes(search.toLowerCase()) ||
											data.hardwareId.toLowerCase().includes(search.toLowerCase()) ||
											(data.androidId?? '').toLowerCase().includes(search.toLowerCase()) ||
											data.printId.toString().includes(search.toLowerCase()) ||
											data.reference.toLowerCase().includes(search.toLowerCase()) ||
											data.description.toLowerCase().includes(search.toLowerCase())
										)">
									<div class="col-12 col-md-10 my-2 d-inline">
										<div class="">
											<!--										<i class="fas fa-atom fa-2x aml-text-teal"/>-->
											<h5 class="d-inline ml-2 font-weight-lighter align-bottom">
												<strong class="aml-bg-teal text-white font-weight-bold px-1 rounded">{{ device.printId }}</strong>
												<el-divider direction="vertical"/> <small>{{ device.hardwareId }}</small>
												<el-divider direction="vertical"/> <small>{{ device.androidId }}</small>
											</h5>
										</div>
										<div class="mt-2 ml-2">
											<span> <strong>S/N:</strong> <span class="aml-text-gray"> {{ device.reference }}</span> </span>
										</div>
										<div class="mt-2 ml-2">
											<small> <span class="badge bg-secondary"> {{ device.type }}</span> </small>
											<el-divider direction="vertical"/>
											<small> <strong>ID:</strong> <span class="aml-text-gray"> {{ device.id }}</span> </small>
											<span>
												<el-divider direction="vertical"/>
												<small> <strong>NFC:</strong> <span class="aml-text-gray"> {{ device.nfcType }}</span> </small>
											</span>
											<span>
												<el-divider direction="vertical"/>
												<small> <strong>MODEL:</strong> <span class="text-decoration-underline"> {{ device.model }}</span> </small>
											</span>
											<span>
												<el-divider direction="vertical"/>
												<small>  <strong>BANK:</strong> <span class="aml-text-gray"> {{ device.bank }}</span> </small>
										  	</span>
										</div>
										<div class="aml-text-gray ml-2 mt-2">
											<small><i class="fas fa-clock"/> <span class="border-bottom">{{ displaydt(device.createdAt) }}</span></small>
											<el-divider direction="vertical"/> {{ device.description }}
										</div>
									</div>
									<div class="col-2 my-2 d-inline text-center">
										<span class="aml-text-teal d-md-inline d-none cursor-pointer" @click="deviceOpen('edit', device)"><i class="fas fa-edit"/></span>
										<span class="aml-bg-teal badge d-inline d-md-none cursor-pointer" @click="deviceOpen('edit', device)"><i class="fas fa-edit"/> EDIT</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<span class="aml-dialog">
					<el-dialog :title="deviceOperation === 'edit' ? 'Edit Device ' + device.printId : 'Add Device'" v-model="deviceDialog">
						<span class="d-block mt-3">
							<el-form :model="device" class="">
								<span>
									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">DEVICE</span>
											<el-input class="col-md-8 col-12" v-model="device.printId" :disabled="!isDisabled()"/>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">MAC</span>
											<el-input class="col-md-8 col-12" v-model="device.hardwareId" :disabled="!isDisabled()"/>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">Android Id</span>
											<el-input class="col-md-8 col-12" v-model="device.androidId" :disabled="!isDisabled()"/>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">REFERENCE</span>
											<el-input class="col-md-8 col-12" v-model="device.reference" :disabled="!isDisabled()"/>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">DESCRIPTION</span>
											<el-input class="col-md-8 col-12" v-model="device.description" :disabled="!isDisabled()"/>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">Type</span>
											<el-select class="select-aml-teal col-6" allow-create filterable
													   :disabled="!isDisabled()"
													   v-model="device.type" placeholder="Select Type">
												<el-option v-for="type in defaultDeviceTypes"
														   :key="type.model" :label="type.name.toUpperCase()" :value="type.model"/>
											</el-select>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">Device Model</span>
											<el-select class="select-aml-teal col-6" allow-create filterable
													   :disabled="!isDisabled()"
													   v-model="device.model" placeholder="Select Device Model">
												<el-option v-for="m in defaultModelTypes"
														   :key="m.model" :label="m.name" :value="m.model"/>
											</el-select>
										</span>
									</el-form-item>

									<el-form-item>
										<span class="row">
											<span slot="label" class="col-md-3 col-12 text-right my-auto">Bank</span>
											<el-select class="select-aml-teal col-6"
													   :disabled="!isDisabled()" @change="deviceBankChange"
													   v-model="device.bank" placeholder="Select Bank">
												<el-option v-for="b in defaultBankTypes"
														   :key="b.bank" :label="b.name" :value="b.bank"/>
											</el-select>
										</span>
									</el-form-item>

								  	<div v-if="device.bankData">
										<el-form-item v-for="bankData in Object.keys(device.bankData)">
											<span class="row">
												<small slot="label" class="col-md-3 col-12 text-right my-auto">{{ bankData.toUpperCase() }}</small>
												<el-input class="col-md-8 col-12" v-model="device.bankData[bankData]" :disabled="!isDisabled()"/>
											</span>
									  	</el-form-item>
                  					</div>

								</span>
							</el-form>
						</span>
						<template #footer>
							<span class="dialog-footer">
							  <el-button @click="deviceDialog = false" v-if="!isLoading.devices">Cancel</el-button>
							  <el-button v-if="isDisabled()" class="aml-bg-teal-3 border-0" type="primary" :loading="isLoading.devices" @click="saveDevice">Confirm</el-button>
							</span>
						</template>
					</el-dialog>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import {
	Card,
	Table as NTable,
	AnimatedNumber,
	Progress as NProgress,
} from "@/components";
import axios from 'axios';
import {
	ElDivider, ElBadge, ElDialog, ElButton,
	ElForm, ElFormItem, ElSelect, ElOption,
	ElTable, ElTableColumn, ElMessage, ElInput
} from "element-plus";
import moment from 'moment';

export default {
	components: {
		Card,
		NTable,
		AnimatedNumber,
		NProgress,
		ElDivider, ElBadge, ElDialog, ElButton, ElForm,
		ElFormItem, ElSelect, ElOption,
		ElTable, ElTableColumn, ElInput,
		moment,
	},
	inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
	emits: [ 'event-fetched', 'loading-complete' ],
	data() {
		return {
			defaultInfo: {
				event: { name: '', country: 'india', type: '' },
				summary: {},
				devices: [],
				device: {},
				components: { devices: true, },
				isLoading: { devices: true, },
			},
			loginUser: {},
			event: { name: '', country: 'india', type: '' },
			summary: {},
			devices: [],
			device: { },
			deviceRow: {},
			bankDefaultCredentials: {
				'mswipe-atomx': {
					bank_mswipe_username: '9400100344',
					bank_mswipe_password: '555555',
					bank_mswipe_verify_client_code: 'ATOMXLIVE',
					bank_mswipe_verify_user_id: 'ATOMXLIVE@SOL',
					bank_mswipe_verify_password: 'ATOMX~DsD@281119',
					bank_mswipe_upi_api: 'sdk',
				},
				'mswipe-bms': {
					bank_mswipe_username: '9300066508',
					bank_mswipe_password: '555555',
					bank_mswipe_verify_client_code: 'BMSLIVE',
					bank_mswipe_verify_user_id: 'BMSLIVE@SOL',
					bank_mswipe_verify_password: 'BMSLIVE~DsD@061219',
					bank_mswipe_upi_api: 'sdk',
				},
				'utap-skypay-dxb': {
					bank_mswipe_username: '9000036987',
					bank_mswipe_password: '306090',
					bank_mswipe_verify_client_code: 'ATOMXLIVE',
					bank_mswipe_verify_user_id: 'ATOMXLIVE@SOL',
					bank_mswipe_verify_password: 'ATOMX~DsD@281119',
					bank_mswipe_upi_api: 'sdk',
				},
				'cashlez-bms-indonesia-demo': {
					bank_sunmipay_merchant_name: 'The Dragon',
					bank_sunmipay_transaction_type: 'SALE',
					bank_sunmipay_aggregator_id: 'pecinta mi',
					bank_sunmipay_mobile_user_id: 'test03',
					bank_sunmipay_cashlez_package_name: 'com.cashlez.android.garuda.allinone',
				},
				'cashlez-bms-indonesia': {
					bank_sunmipay_merchant_name: 'Bookmyshow ID',
					bank_sunmipay_transaction_type: 'SALE',
					bank_sunmipay_aggregator_id: 'BookmyshowID',
					bank_sunmipay_mobile_user_id: 'bookmyshowID',
					bank_sunmipay_cashlez_package_name: 'com.cashlez.android.garuda.allinone',
				},
				'worldline-atomx-axis': {
					bank_worldline_tid: '0447232U',
					bank_worldline_password: '12345678',
					bank_worldline_sim_username: 'atomx',
					bank_worldline_sim_password: 'U$er#09873',
					bank_worldline_bank_function_password: '3226',
					bank_worldline_customer_qr_action: 'com.example.menusample.YOUR_ACTION_BQR',
					bank_worldline_customer_card_action: 'cn.desert.newpos.payui.master.YOUR_ACTION',
				},
				'mosambee-atomx-sbi': {
					bank_mosambee_user_name: '5252871758',
					bank_mosambee_password: '5880',
					bank_mosambee_app_key: 'cGjhE$@fdhj4675riesae',
					bank_mosambee_merchant_code: 'HYLZYQJV6ZXZUO52J0K',
					bank_mosambee_merchant_key: '7DB342C21C56902D31DC166C7B33BBE760AAD7D796B6300A'
				},
				'ezetap-atomx-axis': {
					bank_ezetap_prod_app_key: '730077d9-de69-4961-85bb-6bfcfe3367c7',
					bank_ezetap_merchant_name: 'ATOMX CORPORATION PRIVATE LIMITED',
					bank_ezetap_user_name: '7875676169'
				},
				'pinelabs-atomx-hdfc': {
					bank_pinelabs_application_id: '43dab288a5d64e40b6b6aba51aaead59', //Default Test
					bank_pinelabs_user_id: '1234',
					bank_pinelabs_smart_action: 'com.pinelabs.masterapp.SERVER',
					bank_pinelabs_smart_package: 'com.pinelabs.masterapp'
				}
			},
			defaultDeviceTypes2: [ 'Famoco', 'wisepos+', 'sunmi', 'worldline', 'sunmiPay', 'ezetap', 'airpay', 'MF919', 'DX8000', 'other' ],
			defaultDeviceTypes: [
				{ name: 'Wisepos+', model: 'wisepos+' },
				{ name: 'Famoco', model: 'Famoco' },
				{ name: 'GateX Turnstile Reader', model: 'serialNFC' },
				{ name: 'Cashlez Sunmipay', model: 'sunmipay' },
				{ name: 'Mosambee MF919', model: 'MF919' },
				{ name: 'Mosambee | DX8000', model: 'DX8000' },
				{ name: 'Ezetap', model: 'ezetap' },
				{ name: 'Worldline', model: 'worldline' },
				{ name: 'Airpay', model: 'airpay' },
				{ name: 'PinelabsPax', model: 'pinelabsPax' },
				{ name: 'Sunmi', model: 'Sunmi' },
				{ name: 'utapSunmi', model: 'utapSunmi' },
				{ name: 'Other', model: 'other' },
			],
			defaultModelTypes: [
				{ name: 'Wisepos+', model: 'wisepos+' },
				{ name: 'Famoco', model: 'Famoco' },
				{ name: 'GateX Turnstile Reader', model: 'gatex' },
				{ name: 'Cashlez Sunmipay', model: 'sunmipay' },
				{ name: 'Mosambee MF919', model: 'MF919' },
				{ name: 'Mosambee | DX8000', model: 'DX8000' },
				{ name: 'Ezetap', model: 'ezetap' },
				{ name: 'Worldline', model: 'worldline' },
				{ name: 'Airpay', model: 'airpay' },
				{ name: 'PinelabsPax', model: 'pinelabsPax' },
				{ name: 'Sunmi', model: 'Sunmi' },
				{ name: 'utapSunmi', model: 'utapSunmi' },
				{ name: 'Other', model: 'other' },
			],
			defaultBankTypes: [
				{ name: 'MSWIPE ATOMX', bank: 'mswipe-atomx' },
				{ name: 'MSWIPE BMS', bank: 'mswipe-bms' },
				{ name: 'UTAP SKYPAY DXB', bank: 'utap-skypay-dxb' },
				{ name: 'CASHLEZ BMS INDONESIA', bank: 'cashlez-bms-indonesia' },
				{ name: 'MOSAMBEE ATOMX SBI', bank: 'mosambee-atomx-sbi' },
				{ name: 'EZETAP ATOMX AXIS', bank: 'ezetap-atomx-axis' },
				{ name: 'WORLDLINE ATOMX AXIS', bank: 'worldline-atomx-axis' },
				{ name: 'PINELABS ATOMX HDFC', bank: 'pinelabs-atomx-hdfc' },
				{ name: 'AIRPAY ATOMX AXIS', bank: 'airpay-atomx-axis' },
				{ name: 'CASHLEZ DEMO INDO', bank: 'cashlez-bms-indonesia-demo' },
				{ name: 'NONE', bank: 'none' },
			],
			deviceOperation: 'add',
			search: '',
			deviceDialog: false,
			components: { devices: true, },
			isLoading: { devices: true, },
		};
	},
	methods: {
		setDefault(key = 'all') {
			if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		isDisabled() { return true; this.loginUser.loginType === 'admin' },
		displaydt(dt) { return dt ? moment(dt).local().format('LLL') : 'NA'; },
		checkBankData(bank) { this.device.bankData = this.bankDefaultCredentials[bank]; },
		deviceBankChange(bank) {
			console.log('bank-selected', bank);
			this.checkBankData(bank);
		},
		loadDetails() {
			this.isLoading.devices = true;
			axios.get(
				this.$baseURL + '/Devices/Masterlist/All/',
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						this.devices = response.data.devices;
					}
					this.isLoading.devices = false;
					this.$emit('loading-complete');
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.devices = false;
				});
		},
		deviceOpen(operation = 'add', device = { }) {
			this.deviceOperation = operation;
			this.deviceDialog = true;
			this.device = operation === 'add' ? device : JSON.parse(JSON.stringify(device)) ;
			this.deviceRow = device ;
			if(operation === 'edit') this.checkBankData(device.bank);
		},
		saveDevice() {
			debugger;
			if( !this.device.printId ||  !this.device.hardwareId ||  !this.device.androidId ||  !this.device.reference ||  !this.device.description ||  !this.device.type ||  !this.device.model ||  !this.device.bank )
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Kindly fill in all the details</b><br>Please try again.",
					type: 'danger',
					grouping: true,
					duration: 5000,
				});
			this.isLoading.devices = true;
			axios.post(
				this.$baseURL + '/Devices/Masterlist/' + this.deviceOperation,
				this.device,
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						switch(this.deviceOperation) {
							case 'add':
								this.devices.push(response.data.device);
								break;
							case 'edit':
								['printId', 'hardwareId', 'androidId', 'reference', 'description'].forEach(o => {
									this.deviceRow[o] = this.device[o];
								});
								break;
							default:
								break;
						}
						this.deviceDialog = false;
					} else ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>" + response.data.message + "</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					this.isLoading.devices = false;
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.devices = false;
				});
		},
	},
	mounted() {
		// setTimeout(this.loadDetails, 500);
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
		this.loadDetails();
	}
};
</script>
<style>
.aml-card-header {
	border-radius: .5875rem !important;
	min-height: 100px;
	/*background-color: #2198af !important;*/
}
.aml-event-card {
	/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
	box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
}
</style>
