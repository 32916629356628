<template>
	<navbar :show-navbar="showNavbar" id="navigation">
		<div class="navbar-wrapper">
			<div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
				<navbar-toggle-button v-if="!$route.query.e && !$route.query.v" @click="toggleSidebar"/>
			</div>
			<h3 class="d-md-inline-block d-none">
				{{ pageHeading }}
			</h3>
			<h3 class="d-inline-block d-md-none">
				{{ pageHeading ? pageHeading.substring(0, 12) : '' }}
			</h3>
		</div>
		<button
			@click="toggleNavbar"
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navigation"
			aria-controls="navigation-index"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span v-if="!$route.query.e && !$route.query.v">
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
				<span class="navbar-toggler-bar navbar-kebab"/>
			</span>
		</button>

		<template v-slot:navbar-menu>

			<ul class="navbar-nav" v-if="!$route.query.e && !$route.query.v && code">
<!--				<li class="nav-item" v-if="$route.name === 'Dashboard' || $route.name === 'DashboardPage'">-->
<!--					<el-tooltip content="Reset Day" placement="bottom">-->
<!--						<i class="fas fa-power-off cursor-pointer" @click="dayCloseDialog=true"/>-->
<!--					</el-tooltip>-->
<!--				</li>-->
<!--				<li class="nav-item mx-2 d-none" v-if="$route.name === 'Dashboard' || $route.name === 'DashboardPage'">-->
<!--&lt;!&ndash;					Period: <br>&ndash;&gt;-->
<!--					<el-switch @change="periodChange"-->
<!--							   v-model="periodType" :loading="periodLoading"-->
<!--							   active-text="TODAY" inactive-text="ALL"-->
<!--							   active-color="#1398b0" inactive-color="#1398b0"-->
<!--							   :active-value="'current'" :inactive-value="'all'"/>-->
<!--				</li>-->
<!--				<li class="nav-item mx-2" v-if="$route.name === 'Dashboard' || $route.name === 'DashboardPage'">-->

<!--				</li>-->
				<li class="nav-item">
					<h1 class="d-inline"><strong>{{ code  }}</strong></h1>
				</li>
				<drop-down
					tag="li"
					position="right"
					class="nav-item"
					icon="now-ui-icons media-2_sound-wave"
				>
					<a class="dropdown-item cursor-pointer d-inline-block d-md-none text-white" @click="copyLink( event )">
						<i class="fa fa-link"></i> https://dashboard.atomx.in/#/LiveLink?e={{ base64_aml(code) }}
					</a>
					<a class="dropdown-item cursor-pointer d-md-inline-block d-none" @click="copyLink( event )">
						<i class="fa fa-link"></i> https://dashboard.atomx.in/#/LiveLink?e={{ base64_aml(code) }}
					</a>
					<a class="dropdown-item cursor-pointer">
						<div style="margin-top: 20px">
							<el-radio-group v-model="periodType_" @change="periodChange" size="mini">
								<el-radio-button v-for="period in ['all', 'yesterday', 'current']"
												 :key="period" :disabled="periodLoading"
												 :label="period.toUpperCase()" />
							</el-radio-group>
						</div>
					</a>
					<a class="dropdown-item cursor-pointer"  @click="dayCloseDialog=true">
						<i class="fas fa-power-off cursor-pointer"/> Reset Day for Dashboard
					</a>
				</drop-down>
			</ul>

			<el-dialog :title="' to Clear Dashboard Analytics for Today'" width="40%" v-model="dayCloseDialog">
				<template #title>
					<span class="aml-text-teal-3">
						<h2 class="mb-2">RESET DAY</h2>
						<p>This will clear the Dashboard Analaytics Data and Reset for New Transactions</p>
						<p>The full data can still be viewed using the time-period switch </p>
					</span>
				</template>
				<span class="d-block mt-3">
					<el-form class="">
						<div>
							<el-form-item>
								<span class="row">
									<span slot="label" class="col-md-3 col-12 text-right my-auto">Set Name</span>
									<el-input class="col-md-8 col-12" v-model="dayCloseName" />
								</span>
							</el-form-item>
<!--							<el-form-item>-->
<!--								<span class="row">-->
<!--									<span slot="label" class="col-md-3 col-12 text-right my-auto d-none">Volunteer Count</span>-->
<!--									<el-input class="col-md-8 col-12" v-model="dayVolCount" />-->
<!--								</span>-->
<!--							</el-form-item>-->
						</div>
					</el-form>
				</span>
				<template #footer>
					<span class="dialog-footer">
					  <el-button @click="dayCloseDialog = false" v-if="!dayCloseLoading">Cancel</el-button>
					  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="dayCloseLoading" @click="closeDay">Confirm</el-button>
					</span>
				</template>
			</el-dialog>

		</template>
	</navbar>

	<span class="aml-dialog-1">
		<el-dialog title="!! RESET DASHBOARD FAILED !!" v-model="pendingDeviceDialog" class="my-4">
			<h6 class="mt-2">KINDLY CLOSE ALL DEVICES TO PROCEED WITH RESET.</h6>
			<el-input placeholder="Search" class="my-2" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-11 aml-devices-table" :data="pendingDevices" :stripe="true" :border="true">
				<el-table-column sortable label="DEVICE" prop="print_id" align="center">
					<template #default="scope">
						<h5><b>{{ scope.row.print_id }}</b></h5>
					</template>
				</el-table-column>
				<el-table-column sortable label="TYPE" prop="device_type" align="center">
					<template #default="scope">
						<span>{{ scope.row.device_type }}</span> <el-divider class="my-1" direction="horizontal"/> <span>{{ scope.row.device_model }}</span>
					</template>
				</el-table-column>
				<el-table-column sortable label="MAC" prop="hardware_id" align="center">
					<template #default="scope">
						<span>{{ scope.row.hardware_id }}</span> <el-divider class="my-1" direction="horizontal"/> <span>{{ scope.row.android_id }}</span>
					</template>
				</el-table-column>
				<el-table-column sortable label="TIMESTAMPS" prop="last_login_at" align="center">
					<template #default="scope">
						<div class="text-danger font-weight-bold d-md-inline">LAST LOGIN AT: </div><span>{{ displaydt(scope.row.last_login_at) }}</span>
						<el-divider class="my-1" direction="horizontal"/>
						<div class="text-success font-weight-bold d-md-inline">LAST CLOSED AT: </div><span>{{ displaydt(scope.row.day_closed_at) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<template #footer>
					<span class="dialog-footer">
					  <el-button @click="dayCloseDialog = false; pendingDeviceDialog = false;" v-if="!dayCloseLoading">Cancel</el-button>
					  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="dayCloseLoading" @click="resetForce">RESET</el-button>
					</span>
				</template>
		</el-dialog>
	</span>

</template>
<script>
import { Navbar, NavbarToggleButton } from "@/components";
import { copyText } from 'vue3-clipboard';

import { ElSwitch, ElTooltip, ElRadio, ElRadioGroup, ElRadioButton, ElMessage, ElInput, ElDivider } from "element-plus";

import { ElDialog, ElForm, ElFormItem, ElButton, ElTable, ElTableColumn, } from 'element-plus';
import axios from "axios";
import moment from "moment/moment";

export default {
	components: {
		ElDivider,
		ElTable, ElTableColumn,
		ElInput,
		Navbar,
		NavbarToggleButton,
		copyText,
		ElSwitch, ElTooltip,
		ElDialog, ElForm, ElFormItem, ElButton,
		ElRadio, ElRadioGroup, ElRadioButton,
		moment
	},
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	inject: [ '$baseURL' ],
	props: ['pageHeading', 'code', 'periodType', 'event',],
	emits: [ 'dayClosed', 'periodSelected', ],
	data() {
		return {
			activeNotifications: false,
			showNavbar: false,
			periodLoading: false,

			search: "",
			pendingDeviceDialog: false,
			pendingDeviceLoading: false,
			pendingDevices: [ { device: 10021, loginAt: "2023-01-02" }, { device: 10022, loginAt: "2023-01-02" }, { device: 10023, loginAt: "2023-01-02" }, ],

			dayCloseDialog: false,
			dayCloseLoading: false,
			dayCloseName: '',
			dayVolCount: 0,
			periodType_: this.periodType.toUpperCase(),
		};
	},
	methods: {
		base64_aml(v) { return btoa(v); },
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		toggleNavbar() {
			this.showNavbar = !this.showNavbar;
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		periodChange(pt) {
			// console.log(this.periodType_, pt);
			this.periodLoading = true;
			this.$emit('periodSelected', pt.toLowerCase());
			setTimeout(() => { this.periodLoading = false; }, 5000);
		},
		// copyLink(url) {
		// 	copyText(url, undefined, (error, event) => {
		// 		if (error) {
		// 			alert('Can not copy')
		// 			console.log(error)
		// 		} else {
		// 			alert('Copied')
		// 			console.log(event)
		// 		}
		// 	})
		// },
		copyLink(event) {
			let link = 'https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id);
			let text = link + '\n\n' + event.name + ', ' + event.locationCity + ' | ' + event.id + ' | LIVE LINK';
			copyText(text, undefined, (error, event) => {})
			// if(confirm("Do you want to open in new window?"))	window.open('https://dashboard.atomx.in/#/LiveLink?e=' + this.base64_aml(event.id), '_blank');
			ElMessage({
				showClose: true,
				dangerouslyUseHTMLString: true,
				message: "<b>LINK COPIED</b><br><br>Click <a href='" + link + "' target='_blank'>here</a> to open in new window.",
				type: 'success',
				grouping: true,
				duration: 5000,
			});
		},
		displaydt(dt) { return dt ? moment(dt).local().format('LLL') : 'NA'; },
		closeDay(){
			if(this.dayCloseName.includes('/'))
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Do Not use '/' (slash)</b><br>Please Remove and try again.",
					type: 'danger',
					grouping: true,
					duration: 5000,
				});
			else if(!this.dayCloseName || this.dayCloseName === '')
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Name should not be empty</b>",
					type: 'danger',
					grouping: true,
					duration: 5000,
				});
			this.dayCloseLoading = true;
			this.pendingDeviceLoading = true;

			axios.get(
				this.$baseURL + '/Devices/deviceClosedList/',
				{ params: { code: this.code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
			.then(response => {
				if(response.data.success) {
					this.pendingDeviceLoading = false;

					if(response.data.devices && response.data.devices.length > 0) {
						this.pendingDevices = response.data.devices;
						this.pendingDeviceDialog = true;
						this.dayCloseDialog = false;
						this.dayCloseLoading = false;
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>KINDLY CLOSE ALL DEVICES TO PROCEES.</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
					} else this.resetForce();
				} else {
					this.dayCloseLoading = false;
					return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>" + response.data.message + "</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
				}

				this.dayCloseLoading = false;
			})
			.catch( (error) => {
				console.log("err:", error);
				this.pendingDeviceLoading = false;
				this.dayCloseLoading = false;
				return ElMessage({
					showClose: true,
					dangerouslyUseHTMLString: true,
					message: "<b>Something went wrong</b><br>Please try again.",
					type: 'danger',
					grouping: true,
					duration: 5000,
				});
				});

			// return this.pendingDeviceDialog = true;


		},
		resetForce() {
			this.dayCloseLoading = true;
			axios.post(
				this.$baseURL + '/Event/DayClose/',
				{
					eventId: this.code, day: this.dayCloseName, volunteerCount: this.dayVolCount,
				},
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					// console.log(response);
					// debugger;
					if(response.data.success) {
						this.dayCloseDialog = false;
						this.pendingDeviceDialog = false;
						this.dayCloseLoading = false;
						this.dayCloseName = '';
						this.$emit('dayClosed', response.data.log);
					} else {
						this.dayCloseLoading = false;
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>" + response.data.message + "</b>",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
					}
					this.dayCloseLoading = false;
				})
				.catch( (error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.dayCloseLoading = false;
				});
		},
	},
	watch: {
		$route(to, from) {
			this.dayCloseLoading = false;
			this.dayCloseName = '';
			this.dayCloseDialog = false;

		},
	}
};
</script>
<style>
.nav-item .el-switch__label {
	color: #1398b0 !important;
}
.nav-item .el-switch__label.is-active {
	color: white !important;
}
.nav-item .el-switch__core {
	border-color: white !important;
}
.aml-dialog-1 .el-dialog__header { background-color: #cc7b12 !important; }
.aml-dialog-1 .el-dialog__header .el-dialog__title { color: white !important; font-weight: bold !important; }
</style>
