<template>
	<div>
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category">{{ type.toUpperCase() }}</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<span class="badge badge-primary" v-if="isLoading">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<el-input placeholder="Search Vendor" size="mini" prefix-icon="el-icon-search" v-model="search"/>
				<el-table class="w-100 aml-font-12"
				  :data="list.filter( data => !search || data.personFName.toLowerCase().includes(search.toLowerCase()) || data.personLName.toLowerCase().includes(search.toLowerCase()) || data.uploadId.includes(search) )"
				  :height="list.length > 5 ? '300' : '200'"
				>
					<el-table-column label="#" type="index"/>
					<el-table-column class="cursor-pointer" label="NAME">
						<template #default="scope">
							<span class="d-block cursor-pointer" @click="viewUpload(scope.row)">{{ scope.row.personFName }} {{ scope.row.personLName }}</span>
						</template>
					</el-table-column>
<!--					<el-table-column sortable label="UPLOAD URL" prop="uploadURL"/>-->
					<el-table-column sortable align="center" label="UPLOAD ID" prop="uploadId"/>
					<el-table-column sortable label="UPLOAD AT" prop="uploadCreatedAt"/>
				</el-table>
			</div>
		</div>

		<el-dialog width="80%" :title="uploadData.personFName + ' ' + uploadData.personLName" v-model="uploadDialog" >
			<span class="d-block mt-3">
<!--				<pdf :src="uploadData.uploadURL" v-if="uploadData.ext === 'pdf'"/>-->
<!--				<pdf src="https://cdn.mozilla.net/pdfjs/tracemonkey.pdf" v-if="uploadData.ext === 'pdf'"/>-->
<!--				<pdf src="https://s3.amazonaws.com/armourx.atomx/uploads/tests/8.pdf" v-if="uploadData.ext === 'pdf'"/>-->
<!--				<vuePdfApp :pdf="uploadData.uploadURL" v-if="uploadData.ext === 'pdf'"/>-->
<!--				<imgZ style="width: 500px; height: 500px; border: solid 1px silver;" v-if="uploadData.ext !== 'pdf'">-->
<!--					<img-->
<!--						:src="uploadData.uploadURL"-->
<!--					  	style="object-fit: contain; width: 100%; height: 100%;"-->
<!--					>-->
<!--				</imgZ>-->
<!--				<v-zoomer style="width: 500px; height: 500px; border: solid 1px silver;" v-if="uploadData.ext !== 'pdf'">-->
<!--					<img-->
<!--						:src="uploadData.uploadURL"-->
<!--					  	style="width: 100%; height: 100%;"-->
<!--				  	>-->
<!--				</v-zoomer>-->
				<el-form>
					<div class="row">
						<iframe :src="uploadData.uploadURL" v-if="uploadData.ext === 'pdf'" width="100%" height="500px"/>
						<el-image
							v-else
							style="width: 50%;"
							:src="uploadData.uploadURL"
							:preview-src-list="[uploadData.uploadURL]"
							class="mx-auto"
						/>
					</div>
					<div class="row mt-2">
						<el-link :href="uploadData.uploadURL" target="_blank" class="mx-auto">
							Open certificate <i class="fa fa-external-link"></i>
						</el-link>
					</div>
					<el-divider class="">Details</el-divider>

					<div v-if="uploadData.uploadType === 'test'">

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">LAB</span>
								<el-input class="col-8" v-model="uploadDetails.lab" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">CITY</span>
								<el-input class="col-8" v-model="uploadDetails.city" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">REFERENCE NO.</span>
								<el-input class="col-8" v-model="uploadDetails.refNumber" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">SAMPLE AT</span>
								<span class="col-8">
									<el-date-picker
										type="datetime"
										placeholder="Select date and time"
										@blur="eventDate1Error = typeof uploadDetails.vdate1 === 'undefined'"
										v-model="uploadDetails.vdate1"
										:class="eventDate1Error ? 'border-danger' : ''"
									/>
								</span>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">RESULT AT</span>
								<span class="col-8">
									<el-date-picker
										type="datetime"
										placeholder="Select date and time"
										@blur="eventDate2Error = typeof uploadDetails.vdate2 === 'undefined'"
										v-model="uploadDetails.vdate2"
										:class="eventDate2Error ? 'border-danger' : ''"
									/>
								</span>
							</span>
						</el-form-item>

					</div>

					<div v-if="uploadData.uploadType === 'vaccine'">

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">DOSE</span>
								<el-select class="select-aml-teal col-6" v-model="uploadDetails.vdose" automatic-dropdown default-first-option placeholder="Select Dose">
									<el-option v-for="dose in defaultDoses"
										   :key="dose.value" :label="dose.label.toUpperCase()" :value="dose.value"/>
								</el-select>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">VACCINE NAME</span>
								<el-input class="col-8" v-model="uploadDetails.vname" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">CENTRE</span>
								<el-input class="col-8" v-model="uploadDetails.centre" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">CITY</span>
								<el-input class="col-8" v-model="uploadDetails.city" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">REFERENCE NO.</span>
								<el-input class="col-8" v-model="uploadDetails.refNumber" autocomplete="off"/>
							</span>
						</el-form-item>

						<el-form-item>
							<span class="row">
								<span slot="label" class="col-md-3 col-10 text-right my-auto">DATE</span>
								<span class="col-8">
									<el-date-picker
										type="date"
										placeholder="Select date"
										@blur="eventDate1Error = typeof uploadDetails.vdate === 'undefined'"
										v-model="uploadDetails.vdate"
										:class="eventDate1Error ? 'border-danger' : ''"
									/>
								</span>
							</span>
						</el-form-item>

					</div>


					<el-form-item>
						<span class="row">
<!--							<span slot="label" class="col-md-3 col-10 text-right my-auto">RESULT</span>-->
							<el-switch
								class="mx-auto"
								v-model="uploadDetails.vresult"
								active-color="#13ce66" inactive-color="#ce1313"
								active-text="VALID" inactive-text="INVALID"
								active-value="valid" inactive-value="invalid"/>
						</span>
					</el-form-item>

					<el-form-item v-if="uploadDetails.vresult !== 'valid'">
						<span class="row">
							<span slot="label" class="col-md-3 col-10 text-right my-auto">REMARKS</span>
							<el-input
								type="textarea" class="col-8"
								:autosize="{ minRows: 2, maxRows: 4}"
								maxlength="200" show-word-limit
								placeholder="Remarks" v-model="uploadDetails.remarks"/>
						</span>
					</el-form-item>
				</el-form>
			</span>
			<template #footer>
			    <span class="dialog-footer">
					<el-button @click="uploadDialog = false">Cancel</el-button>
			      	<el-button type="primary" :loading="loading" @click="save">Save</el-button>
			    </span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElLink, ElImage, ElDatePicker,
	} from "element-plus";
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import axios from "axios";

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider, ElLink,
			ElImage, ElDatePicker,
		},
		props: [ 'list', 'isLoading', 'type', ],
		inject: [ '$baseURL', '$armourxURL', ],
		emits: [ 'list-remove', ],
		data() {
			return {
				search: '',
				uploadDialog: false,
				uploadData: {},
				uploadDetails: { vresult: 'valid', },
				defaultDoses: [ { value: 'dose1', label: 'DOSE 1' }, { value: 'dose2', label: 'DOSE 2' }, ],
				eventDate1Error: false,
				eventDate2Error: false,
				loading: false,
				selIndex: 0,
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				formLabelWidth: '200px',
			}
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			checkString(value) {
				this.vendor[value] = this.vendor[value].replace(/[^\x00-\x7F]/g, '').replace(/\r?\n|\r/g, '')//.replace(/'/g, '').replace(/"/g, '');
			},
			viewUpload( data = {} ) {
				this.uploadData = data;
				this.uploadDetails = { vresult: 'valid', type: data.uploadType, uploadId: data.uploadId, };
				let ext = '';
				if(this.uploadData && this.uploadData.uploadURL) {
					let u = this.uploadData.uploadURL.split('.');
					if(u.length > 1) {
						ext = u[u.length - 1];
					}
				}
				this.uploadData.ext = ext;
				this.uploadDialog = true;
			},
			save() {
				this.loading = true;
				axios.post(
					this.$armourxURL + '/Upload/Verify',
					this.uploadDetails,
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.loading = false;
						if (response.data.success) {
							// this.$removeData(this.list, this.uploadDetails.uploadId, 'uploadId');
							this.$emit('list-remove', this.type, this.uploadDetails.uploadId, 'uploadId');
							this.uploadDetails = {};
							this.uploadDialog = false;
						}
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.loading = false;
					});
			},
		},
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;
	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
</style>
