<template>
	<div>
		<div class="card card-stats card-raised" v-if="Object.keys(items).length > 0">
			<div class="card-header">
				<h5 class="card-category">Items for {{ $route.params.inventoryType.toUpperCase() }}</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
					<el-button class="mr-2" size="small" plain
						:icon="isLoading.inventory ? 'fas fa-spin fa-spinner' : 'el-icon-upload'"
						:disabled="isLoading.inventory || Object.keys(items).length === 0" @click="saveInventory()">
						Save</el-button>
					<span class="badge badge-primary" v-if="loading">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
					</span>
				</div>
			</div>
			<div class="card-body">
				<div v-if="!loading">
					<el-form ref="form">
						<el-tabs v-model="categorySelected" type="border-card" tab-position="top">
							<el-tab-pane v-for="(items, category, index) in items" :key="category.split('|')[0]"
								:label="category.split('|')[1]" :name="category.split('|')[0]">
								<template #label>
									<span>{{ category.split('|')[1] }}</span>
								</template>

								<!--ITEMS-->
								<div class="col-12 mx-auto px-0">

									<div class="">
										<div class="row bg-light">
											<h5 class="col-8 text-dark text-left pl-5 my-1"><small>Item</small></h5>
											<h5 class="col-3 text-dark text-center my-1"><small>Quantity</small></h5>
										</div>
										<div class="row aml-item py-2" v-for="item in items" :key="item.id">

											<span class="col-8 row">
												<span class="col-1 my-auto text-right"><i @click="removeItem(items, item)"
														class="fa fa-times-circle cursor-pointer"></i></span>
												<div class="col-10">{{ item.name }}</div>
												<div class="col-10"><small>{{ item.category }}</small></div>
												<div class="col-10"><small>{{ item.hsn }}</small></div>
											</span>
											<el-input class="mb-0 px-1 col-3 my-auto" type="number" v-model="item.qty" />
										</div>
									</div>
								</div>

							</el-tab-pane>
						</el-tabs>
					</el-form>
				</div>
				<div v-else>
					<el-skeleton :rows="5" animated />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
	ElForm, ElFormItem, ElButton, ElDialog, ElDivider, ElUpload,
} from 'element-plus';
import axios from "axios";
import { useToast } from "vue-toastification";
import { Notification } from "@/components";

export default {
	components: {
		ElTabs, ElTabPane, ElSkeleton, ElSwitch, ElSelect, ElOption,
		ElForm, ElFormItem, ElButton, ElDialog, ElDivider, useToast, Notification,
		ElUpload,
	},
	inject: ['$baseURL', '$numFrm', '$removeData',],
	props: ['event', 'stall', 'allItems', 'menu', 'items', 'loading', 'categorySelected',],
	emit: ['item-remove', 'save-complete',],
	data() {
		return {
			originalMenu: [],
			// menu: {  },
			// inventory: [ ],
			// allItems: [ ],
			downloadMenu: { event: null, eventName: null, stall: null, stalls: [] },
			dEvents: [],
			dStalls: [],
			defaultGST: ['0', '5', '18', '28'],
			defaultTypes: ['inclusive', 'exclusive'],
			defaultItemTypes: ['food', 'drink', 'ticket', 'other'],
			downloadMenuDialog: false,
			menuDialog: false,
			isLoading: { menu: false, stalls: false, inventory: false, allItems: true, },
			menuUpdated: false, menuLoaded: false,
			multipleItems: {},
		}
	},
	methods: {
		runToast(text, pos, type, ownText, ownIcon) {
			// const text =
			// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
			const icon = "now-ui-icons ui-1_bell-53";
			const content = {
				component: Notification,

				props: {
					ownText: ownText,
					ownIcon: ownIcon,
					icon: icon,
					text: text,
					type: type,
				},
			};
			const toast = useToast();
			toast(content, {
				hideProgressBar: true,
				icon: false,
				closeButton: false,
				position: pos,
			});
		},
		removeItem(items, item) {
			this.$removeData(items, item.id, 'id');
			this.$emit('item-remove', item);
			// if(this.items[item.categoryId + '|' + item.category].length ===0)	{
			// 	delete this.items[item.categoryId + '|' + item.category];
			// 	this.categorySelected = Object.keys(this.items)[0].split('|')[0];
			// }
			// this.allItems.push(item);
		},
		saveInventory() {
			this.isLoading.inventory = true;
			// return console.log("items: ", this.items);

			let items_ = [];
			Object.keys(this.items).forEach(item => { this.items[item].forEach(item_ => { items_.push(item_) }) });

			axios.post(
				this.$baseURL + '/Menu/' + this.$route.params.stallCode + '/' + this.$route.params.inventoryType + '/Save',
				{ type: this.$route.params.inventoryType, event: this.event.id, vendor: this.stall.vendorId, stall: this.stall.id, items: items_, },
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					this.isLoading.inventory = false;
					if (response.data.success) {
						this.alert = { show: true, type: 'success', icon: "ni ni-like-2", message: { strong: 'Success!', normal: "Inventory Updated!" } };
						this.runToast('', 'top-center', 'success', "<b>" + this.$route.params.inventoryType.toUpperCase() + " Created", "fas fa-check fa-4x");
						this.$emit('save-complete', response.data.note);
					} else {
						this.runToast('', 'top-right', 'danger', "<b>" + response.data.message + "</b><br>Please try again.", "fas fa-bomb fa-4x");
					}
				})
				.catch((error) => {
					console.error(error);
					this.isLoading.inventory = false;
					this.runToast('', 'top-right', 'danger', "<b>Something Went Wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
				});
		},
	},
	mounted() { },
	watch: {
		menu: {
			handler(a) {
				// let b = JSON.stringify({menu: this.originalMenu}) === JSON.stringify({ menu: a });
				// this.menuUpdated = !b;
				// console.log("menu update detect", b, a);
				// this.menuUpdated = this.menuLoaded;
				if (this.menuLoaded) this.menuLoaded = false;
				else this.menuUpdated = true;
			},
			deep: true,
		},
	}
}
</script>
<style>
.aml-text-danger .el-input__inner {
	color: #ff6363 !important;
	border-color: #ff6363 !important;
	background-color: #f003 !important;
}
</style>
