<template>
	<div>
		<div class="row " >
			<div class="col-12 mx-auto">
				<event-wise v-if="components.eventVolunteers" :event="event"/>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import EventWise from './EventWise';
	import { ElDivider, ElBadge } from 'element-plus';

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			EventWise,
			ElDivider, ElBadge,
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					eventVolunteers: [],
					persoVolunteers: [],
					volunteers: [],
					components: { eventVolunteers: true, },
					isLoading: { eventVolunteers: true,  },
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				eventVolunteers: [],
				persoVolunteers: [],
				volunteers: [],
				components: { eventVolunteers: true, },
				isLoading: { eventVolunteers: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				// console.log(this.event.type);

				switch(this.event.type) {
					case 'event':
						this.components.eventWise = true;
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			volunteersUpdated(volunteers) { console.log('volunteer-added', volunteers) },
			eventUpdated(event) {
				if(!event)  return;
				this.event = event;
				this.$emit('event-fetched', event);
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('event');
						this.event = response.data.event;
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.setComponentsDisplay();
						this.$emit('loading-complete');
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
