<template>
	<div style="min-height: 2000px;">
		<div class="row " >
			<div class="col-12 col-md-10 mx-auto">
				<div v-for="type in [ 'tests', 'vaccines' ]">
					<upload-list
						v-if="components[type + 'List']"
						:list="this[type + 'List']"
						:isLoading="isLoading[type + 'List']"
						:type="type"
						@list-remove="listRemove"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import { ElDivider, ElBadge } from 'element-plus';
	import UploadList from "./UploadList";

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			UploadList,
		},
		inject: ['$baseURL', '$armourxURL', '$removeData', ],
		emit: [ 'event-fetched', ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					persons: [],
					components: { persons: true, testsList: true, vaccinesList: true, },
					isLoading: { persons: true, testsList: true, vaccinesList: true, },
				},
				event: { name: '', country: 'india', type: '' },
				persons: [],
				testsList: [],
				vaccinesList: [],
				components: { persons: true, testsList: true, vaccinesList: true, },
				isLoading: { persons: true, testsList: true, vaccinesList: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				this.components.persons = true;
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadUploads() {
				axios.get(
					this.$armourxURL + '/UploadsList/',
					{
						// params: { code: this.$route.params.code },
						headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
					}
				)
					.then(response => {
						this.isLoading.testsList = false;
						this.isLoading.vaccinesList = false;
						if(response.data.success) {
							this.vaccinesList = response.data.vaccineList;
							this.testsList = response.data.testList;
						} else this.runToast('', 'top-right', 'danger', "<b>Test & Vaccine List not updated</b><br>" + response.data.message + ".", "fas fa-bomb fa-4x");
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
						this.isLoading.testsList = false;
						this.isLoading.vaccinesList = false;
					});
			},
			listRemove(...args) {
				// console.log('args', args);
				this.$removeData( this[args[0] + 'List'], args[1], args[2] );
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			this.loadUploads();
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
