<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

		<side-bar class="d-print-none" v-if="showSidebar">
			<template v-slot:links>
				<!--<user-menu></user-menu>-->

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item">
					<!--<template v-slot:links>-->
						<sidebar-item v-for="child in item.children" :key="child.name" :link="child"/>
					<!--</template>-->
				</sidebar-item>

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item"/>

			</template>
		</side-bar>
		<div class="main-panel w-print-100" :class="showSidebar ? '' : 'w-100'">
			<div class="position-fixed h-100 w-100" style="z-index: 1029; background-color: #f6f0f0 !important" v-if="loading">
				<div class="custom-loading text-center position-relative" style="top: 30%">
					<svg class="circular" viewBox="-10, -10, 50, 50">
						<path
							class="path"
							d="
							M 30 15
							L 28 17
							M 25.61 25.61
							A 15 15, 0, 0, 1, 15 30
							A 15 15, 0, 1, 1, 27.99 7.5
							L 15 15
						  "
							style="stroke-width: 1px; fill: rgba(0, 0, 0, 0)"
						/>
					</svg>
					<h5 class="text-primary">ALMOST THERE</h5>
				</div>
			</div>

			<top-navbar :pageHeading="pageHeading" :code="info && info.id ? info.id : ''" :info="info"
						:periodType="periodType" @periodSelected="periodSelected" @dayClosed="dayClosed" />
			<router-view name="header"
			             :summaryInfo="summaryInfo"
			             :info="info"
			             :user="user"
						 :periodType="periodType"
			/>

			<div
				:class="{ content: !$route.meta.hideContent }"
				@click="toggleSidebar"
			>
				<router-view @summary-fetched="summaryFetched"
				             @info-fetched="infoFetched"
				             :user="user"
							 :periodType="periodType"
							 :newDayClosed="newDayClosed"
							 @newDayClosedTurnOFF="newDayClosedTurnOFF"
							 @loading-complete="loadingComplete"
							 @hide-sidebar="hideSidebar"
				/>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"/>
		</div>
	</div>
</template>
<script>
	/* eslint-disable no-new */
	// import PerfectScrollbar from "perfect-scrollbar";
	// import "perfect-scrollbar/css/perfect-scrollbar.css";

	// function hasElement(className) {
	//   return document.getElementsByClassName(className).length > 0;
	// }
	//
	// function initScrollbar(className) {
	//   if (hasElement(className)) {
	//     new PerfectScrollbar(`.${className}`);
	//   } else {
	//     // try to init it later in case this component is loaded async
	//     setTimeout(() => {
	//       initScrollbar(className);
	//     }, 100);
	//   }
	// }

	import TopNavbar from "./TopNavbar";
	import ContentFooter from "../ContentFooter.vue";
	import UserMenu from "../extra/UserMenu.vue";
	import SidebarItem from "../../../../components/SidebarPlugin/SidebarItem";

	export default {
		components: {
			SidebarItem,
			TopNavbar,
			ContentFooter,
			UserMenu,
		},
		data() {
			return {
				sidebarItems: [],
				pageHeading: 'Dashboard',
				summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
				user: {},
				info: { name: '', country: 'india', type: '' },
				showSidebar: true,

				periodType: 'current',

				loading: true,

				defaultInfo: {
					info: { name: '', country: 'india', type: '' },
					pageHeading: 'Dashboard',
					summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
				},
				newDayClosed: false,
			};
		},
		methods: {
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}

				// if( this.$isCheckEventType('clg-access', this.event.type) && this.event.useAccessx === 0 ) this.setColgFilterDefaults();
			},
			infoFetched(info) {
				// if(!event)  return;
					this.info = info;
				this.pageHeading = !info ? '' : info.name;
				if( info && Object.keys(info).length === 0 ) this.updateSidebarItems('all');
				else if(this.user && this.user.loginType === 'admin') this.updateSidebarItems('admin');
			},
			loadingComplete() { this.loading = false; },
			periodSelected(period) { this.periodType = period; },
			dayClosed() { this.newDayClosed = true; },
			newDayClosedTurnOFF() { this.newDayClosed = false; },
			summaryFetched(summary) { this.summaryInfo = summary; },
			toggleSidebar() {
				if (this.$sidebar.showSidebar) {
					this.$sidebar.displaySidebar(false);
				}
			},
			hideSidebar(status) {
				this.showSidebar = !status;
				},
			updateSidebarItems(userType = 'all') {
				if(!localStorage.getItem('xToken') || typeof localStorage.getItem('xToken') === "undefined" || !localStorage.getItem('user')) {
					return;
				} // else {
				//
				// }
				this.user = JSON.parse(localStorage.getItem('user'));
				this.sidebarItems = [];
				switch (this.user.product) {
					case 'iiser':
						switch (this.user.loginType) {
							case 'admin':
								// this.sidebarItems.push({ name: 'Dashboard', icon: 'now-ui-icons design_app', path: '/' });
								this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-bezier-curve', path: '/IISER/Admin/Devices/All' });
								this.sidebarItems.push({ name: 'Vendors', icon: 'fas fa-users', path: '/IISER/Admin/Vendors' });
								this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/IISER/Admin/Analytics' });
								this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/IISER/Admin/Blocked' });
								// this.sidebarItems.push({
								// 	name: 'Event', icon: 'now-ui-icons education_atom',
								// 	children: [
								// 		{ name: 'Create', icon: 'now-ui-icons design_app', path: '/Create' },
								// 		{ name: 'View All', icon: 'now-ui-icons design_app', path: '/ViewAll' }
								// 	]
								// });
								break;
							case 'vendor':
								// this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/IISER/Vendor/Analytics' });
								this.sidebarItems.push({ name: 'Menu', icon: 'fas fa-utensils', path: '/IISER/Vendor/Menu' });
								// this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/IISER/Vendor/Transactions' });
								// this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/IISER/Vendor/Reports' });
								// this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/IISER/Vendor/Blocked' });
								break;
						}
						break;
					case 'facex':
						switch (this.user.loginType) {
							case 'admin':
								this.sidebarItems.push({ name: 'Users', icon: 'fas fa-users', path: '/FaceX/Users' });
								this.sidebarItems.push({ name: 'Report', icon: 'fas fa-bezier-curve', path: '/FaceX/Report' });
								break;
						}
						break;
					case 'event':
						switch (this.user.loginType) {
							case 'vendor':
								this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Vendor/Analytics/' });
								this.sidebarItems.push({ name: 'Configuration', icon: 'el-icon-set-up', path: '/Vendor/Config/' });
								this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/Vendor/Reports/' });
								this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Vendor/Transactions/' });
								this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-mobile', path: '/Vendor/Devices/' });
								// this.sidebarItems.push({ name: 'Volunteers', icon: 'fas fa-people-arrows', path: '/Volunteers/' });
								this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/Vendor/Blocked/' });
								break;
						}
						break;
				}

				this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });

			},
		},
		mounted() {
			// let docClasses = document.body.classList;
			// let isWindows = navigator.platform.startsWith("Win");
			// if (isWindows) {
			//   // if we are on windows OS we activate the perfectScrollbar function
			//   initScrollbar("sidebar");
			//   initScrollbar("sidebar-wrapper");
			//
			//   docClasses.add("perfect-scrollbar-on");
			// } else {
			//   docClasses.add("perfect-scrollbar-off");
			// }


			this.updateSidebarItems();
			// this.user = JSON.parse(localStorage.getItem('user'));
			// if (!this.user || this.user.v < 2) this.$router.push('/Login');
		},
		watch: {
			$route (to, from) {
				this.loading = true;
			}
		}
	};
</script>
<style>
.custom-loading .circular {
	margin-right: 6px;
	width: 200px;
	height: 200px;
	animation: loading-rotate 2s linear infinite;
}
.custom-loading .circular .path {
	animation: loading-dash 1.5s ease-in-out infinite;
	stroke-dasharray: 90, 150;
	stroke-dashoffset: 0;
	stroke-width: 2;
	stroke: #f96332;
	stroke-linecap: round;
}
</style>
