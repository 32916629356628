<style>
td {
	min-width: 100px;
	padding-left: 3px;
}

.selectedList {
	background-color: rgb(210, 207, 207);
}

@media (max-width: 768px) {
	.el-dialog-fullwidth {
		width: 100% !important;
	}
}

@media (max-width: 600px) {
	.text-responsive {
		font-size: 12px;
	}
}
</style>
<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">DATA LIST ( {{ list.length }} )</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<!--				<span class="col-3 text-right text-info cursor-pointer" @click=" dataDialog = true;"><i class="fa fa-plus"/> ADD</span>-->
				<span class="badge badge-primary" v-if="isLoading.list">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin" />
				</span>
			</div>
		</div>
		<div class="card-body pre-scrollable" style="max-height: 75vh">
			<div class="row">
				<!--				<div class="col-12">-->
				<!--					<h5>LIST</h5>-->
				<!--				</div>-->
				<div class="col-12 pt-2 mb-1 border-top border-bottom cursor-pointer"
					:class="{ selectedList: this.details && data.id === this.details.id }" @click="selectData(data)"
					v-for="data in list">
					<h6 class="mb-1 d-inline">{{ data.stallName }}</h6>
					<el-divider direction="vertical" />

					<p class="mb-1 text-secondary d-inline">{{ displaydt(data.createdAt) }}</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	Card,
} from "@/components";
import axios from 'axios';
import {
	ElDivider, ElDialog, ElForm, ElFormItem, ElInput,
	ElSelect, ElOption, ElOptionGroup, ElDatePicker, ElSwitch, ElButton, ElMessage
} from "element-plus";
import moment from "moment";

export default {
	components: {
		Card,
		ElDivider, ElDialog,
		ElForm, ElFormItem, ElInput,
		ElSelect, ElOption, ElOptionGroup, ElDatePicker, ElSwitch, ElButton,
		moment,
	},
	inject: ['$baseURL',],
	emits: ['newData', 'dataSelected',],
	props: ['event', 'list', 'details'],
	data() {
		return {
			defaultInfo: {},
			loginUser: {},
			code: 0,
			isLoading: { dataDetails: true, list: false, createNew: false, load: false, },
			dataDialog: false,
			filterDays: [],
			newData: { day: [], dates: [], dates2: [], settings: { day: false, dates: false }, },
			dat: {},
			dataIndex: {
				topup: 'Cashless Topups', sale: 'Cashless Redemptions', return: 'Cashless Returns', activations: 'Cashless Activations',
				no_nfc_sale: 'POS Sales',

				monies: {
					cashless: {
						cash: 0, cash_handover: 0, cash_pending: 0,
						card: 0, upi: 0,
					},
					pos: {
						cash: 0, cash_handover: 0, cash_pending: 0,
						card: 0, upi: 0,
					}
				}
			},
			shortcuts: [{
				text: 'Last week',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					return [start, end]
				})(),
			}, {
				text: 'Last month',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					return [start, end]
				})(),
			}, {
				text: 'Last 3 months',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					return [start, end]
				})(),
			}],
		};
	},
	methods: {
		setDefault(key = 'all') {
			if (key !== 'all') this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		dayChange() {
			switch (this.filterDays.length) {
				case 2:
					if (this.filterDays[0] > this.filterDays[1]) {
						let d = this.filterDays[0];
						this.filterDays[0] = this.filterDays[1];
						this.filterDays[1] = d;
					}
					break;
			}
		},
		displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('YYYY-MM-DD HH:mm:ss') : ''; },
		clearDay() { this.newData.settings.day = false; this.filterDays = this.newData.day = []; },
		selectData(data) {
			this.$emit('dataSelected', data);
		},
		summaryHas(type) {
			switch (type) {
				case 'cashless':
					return this.dat && this.dat.summary && this.dat.summary && (this.dat.summary.topup || this.dat.summary.sale || this.dat.summary.return || this.dat.summary.activations)
					break;
				case 'pos':
					return this.dat && this.dat.summary && this.dat.summary && this.dat.summary.no_nfc_sale
					break;
				default:
					return false;
					break;
			}
		},
		checkItemName(for_, type, mode) {
			if (this.dat[for_][type][mode].name === '') return alert('Name incorrect for item');
			if (this.dat[for_][type][mode].name.includes(' ')) {
				this.dat[for_][type][mode].name = this.dat[for_][type][mode].name.replace(/ /g, "");
				alert('Spaces Removed');
			}
			// debugger;
			if (typeof this.dat[for_][type][this.dat[for_][type][mode].name] !== 'undefined' && this.dat[for_][type][mode].name !== mode) {
				return alert('Name exists, please change');
			} else {
				// let mode_ = this.dat[for_][type][mode];
				if (this.dat[for_][type][mode].name !== mode) {
					let name_ = this.dat[for_][type][mode].name;
					this.dat[for_][type][name_] = this.dat[for_][type][mode];
					delete this.dat[for_][type][mode];
					delete this.dat[for_][type][name_].isNew;
					delete this.dat[for_][type][name_].name;
				}
			}

			delete this.dat[for_][type][mode].isNew;
			delete this.dat[for_][type][mode].name;
		},
		removeItem(for_, type, mode) {
			if (window.confirm('Are You Sure?')) {
				if (for_ === 'summary') return delete this.dat.summary[type];
				if (for_ === 'info') return delete this.dat.summary.info[type];
				else if (typeof this.dat[for_][type][mode] !== 'undefined') return delete this.dat[for_][type][mode];
			}
		},
		addItem(for_, type, value = { amount: 0, count: 0, }) {
			switch (for_) {
				case 'monies':
					this.
						dat[for_][type]["new" + (Object.keys(this.dat[for_][type]).length + 1)] = {
						amount: 0,
						count: 0,
						isNew: true,
						name: "new" + (Object.keys(this.dat[for_][type]).length + 1),
					};
					break;
				case 'summary':
					this.dat[for_][type] = value;
					break;
			}
		},
		addActivationShare(count) { this.dat.summary.activation_share = { amount: 0, count, info: "ACTIVATION SHARE ( " + count + ' @' + this.event.cardFee + " )" } },
		// getInfoName(info) {
		// 	if (info.days.length > 0) {
		// 		switch (info.days.length) {
		// 			case 1:
		// 				return info.days[0].name
		// 				break;
		// 			case 2:
		// 				return info.days[1].name + " to " + info.days[0].name
		// 				break;
		// 		}
		// 	} else if (info.dates.length > 0) {
		// 		return info.dates[0] + " to " + info.dates[1];
		// 	} else { return "Full Event"; }
		// },
		setDaysInfo() {
			this.filterDays.forEach((day, i) => {
				if (day === -1) this.dat.info.days.push({ id: 0, name: "Current Day", });
				else this.dat.info.days.push({ id: this.event.dayCloseLogs[this.filterDays[i]].id, name: this.event.dayCloseLogs[this.filterDays[i]].name, });
			});
		},
		loadData() {
			// if ( _.isEmpty(this.filterDays)  )
			let event = {
				settings: this.newData.settings,
				days: this.filterDays, dates: this.newData.dates2,
				code: this.code, dayCloseLogs: typeof this.event.dayCloseLogs !== 'undefined' && this.event.dayCloseLogs.length > 0 ? this.event.dayCloseLogs.slice().reverse() : [],
				minTxnId: 0, maxTxnId: false, //default for current day ONLY
			};
			// return console.log("list-event", event);
			// debugger;
			if (this.newData.settings.day) {
				switch (event.days.length) {
					case 0: return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Days not selected</b><br>Please select atleast 1.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
						break;
					case 1:
						if (event.days[0] === -1) {
							if (event.dayCloseLogs.length > 0)
								event.minTxnId = event.dayCloseLogs[0].txnId;
						} else {
							event.maxTxnId = event.dayCloseLogs[event.days[0]].txnId;
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[0] + 1].txnId;
						}
						break;
					case 2:
						if (event.days[0] === -1) {
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[1] + 1].txnId;
						} else {
							event.maxTxnId = event.dayCloseLogs[event.days[0]].txnId;
							if (event.dayCloseLogs.length > 1)
								event.minTxnId = event.dayCloseLogs[event.days[1] + 1].txnId;
						}
						break;
					default:
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Invalid Parameters</b><br>Check Days Parameter.",
							type: 'warning',
							grouping: true,
							duration: 5000,
						});
						break;
				}
			} else if (this.newData.settings.dates) {
				if (event.dates.length === 0)
					return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Days not selected</b><br>Select 1.",
						type: 'warning',
						grouping: true,
						duration: 5000,
					});
			}
			// return console.log("list-event", event);

			this.isLoading.load = true;
			axios.post(
				this.$baseURL + '/Event/EventDayData/',
				event,
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					// debugger;
					this.dat = { summary: {}, monies: {}, invoice: {}, status: 'active', };
					response.data.summary.forEach((summary) => {
						if (summary.count > 0)
							this.dat.summary[summary.type_] = {
								amount: summary.amount,
								count: summary.count,
								info: 'TOTAL ' + summary.type_.replace('_', ' ').toUpperCase() + (summary.type_ === 'activations' ? ' ( ' + summary.count + ' @' + this.event.cardFee + ' )' : '')
							};
						if (summary.type_ === 'activations') {
							this.dat.invoice[summary.type_] = { count: summary.count, rate: this.event.cardFee, amount: summary.amount };
						}
					});

					// this.dat.summary.info = {};
					// response.data.info.forEach( (info) => {
					// 	this.dat.summary.info[info.info] = { count: info.count, notes: '' };
					// });

					this.dat.info = {
						createdAt: new Date().getTime(), event: { name: this.event.name, id: this.event.id },
						days: [], dates: this.newData.dates2,
						minTxnAt: response.data.info2[0].min ?? '', maxTxnAt: response.data.info2[0].max ?? '',
					};

					this.setDaysInfo();

					response.data.modeData.forEach((mode) => {
						if (mode.count > 0) {

							switch (mode.mode) {
								case 'NFC':
								case 'UPDATE':
									if (typeof this.dat.monies.cashless === 'undefined') this.dat.monies.cashless = {};
									if (mode.type === 'topup')
										this.dat.monies.cashless[mode.invoice] = { amount: mode.amount, count: mode.count, rate: 0, handover: mode.invoice === 'CASH' || mode.invoice === 'COUPON' || mode.invoice === 'COMP', };
									break;
								default:
									if (typeof this.dat.monies.pos === 'undefined') this.dat.monies.pos = {};
									if (typeof this.dat.monies.pos[mode.mode] === 'undefined') {
										this.dat.monies.pos[mode.mode] = { amount: mode.amount, count: mode.count, rate: 0, handover: mode.mode === 'CASH' || mode.mode === 'COUPON' || mode.mode === 'COMP', };
									} else {
										this.dat.monies.pos[mode.mode].amount += mode.amount;
										this.dat.monies.pos[mode.mode].count += mode.count;
									}
									break;
							}
						}
					});

					// console.log(this.dat);
					this.isLoading.load = false;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.load = false;
				});
		},
		createLog() {
			this.isLoading.createNew = true;
			let data = this.dat;
			axios.post(
				this.$baseURL + '/Event/EventDataSave/',
				data,
				{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						this.$emit('newData', response.data.log);
						this.dat = {};
					}

					this.isLoading.createNew = false;
				})
				.catch((error) => {
					ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
					console.log("err:", error);
					this.isLoading.createNew = false;
				});
		},
	},
	mounted() {
		// setTimeout(this.loadDetails, 500);
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if (u && Object.keys(u).length === 0 && u.constructor === Object) return this.$router.push('/login');
		// this.loadDetails();
		switch (u.loginType) {
			case 'event':
			case 'operator':
				this.code = u.id;
				break;
			case 'admin':
				this.code = this.$route.params.code;
				break;
			default:
				return;
				break;
		}
	},
	watch: {
		$route(to, from) {
			this.dataDialog = false;
		},
	}
}
</script>
