<template>
	<div class="panel-header panel-header-lg row mx-auto">
		<div class="col-12 d-none d-print-inline-block position-absolute fixed-top mt-4 text-white"><h2 class="text-uppercase">{{  }}</h2></div>

		<div class="col-md-8 h-100">
			<canvas :id="headerChartID"/>
		</div>
		<div class="col-md-4 m-auto  d-md-inline d-none">
			<div class="card card-stats card-raised aml-bg-orange-2 aml-border-radius-1 text-white mb-0 py-1" style="box-shadow: 0 1px 15px 1px rgb(39 39 39 / 30%)">
				<div class="card-body pr-0 pl-2">
<!--					<div class=" h-50 ">-->
<!--						<canvas :id="headerChartID2"/>-->
<!--					</div>-->
					<div >
						<div class="col-md text-right row py-1 pr-0">
							<div class="col-3 px-0 my-auto text-left">
								<span class="badge bg-white aml-orange-2 border-0">Pending Bills</span>
							</div>
							<div class="col-9 px-0">
								<h5 class="info-title m-0 d-inline col-10 text-right px-0">
									<small class=""> ₹ </small>
									<strong class="">{{ $numFrm2(+info.billAmount - +info.paidAmount) }}</strong>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>i	</div>
</template>

<script>
	import headerChart from "../../dashboard/HeaderChart";
	import {headerChart as headerChart2} from "../../dashboard/HeaderChart2";
	// import doughnutChart1 from "./DoughnutChart";

	export default {
		name: "overview-header",
		inject: ['$numFrm2'],
		props: ['info',],
		data() {
			return {
				headerChartID: "headerChart",
				headerChartID2: "headerChart2",
				user: {},
			};
		},
		mounted() {
			this.user = JSON.parse(localStorage.getItem('user'));
		},
		watch:{
		},
	};
</script>

<style scoped></style>
