import Chart from "chart.js";

export const headerChart = {
  createChart(chartId, v = [ 'CASH', 'CARD' ], d = [ 0, 0 ]) {

    let chartColor = "#FFFFFF";
    const ctx = document.getElementById(chartId).getContext("2d");
    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);

    let gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.24)");
    var chart_options_a = {
	    type: 'doughnut',
	    data: {
		    borderColor: '#fff',
		    backgroundColor: '#fff',
		    pointBorderColor: '#fff',
		    datasets: [{
			    data: d,
			    backgroundColor: [
				    "#fff",
				    "#dadada",
				    "#eeeeee",
			    ],
			    label: 'Dataset 1'
		    }],
		    labels: v
	    },
	    options: {
		    circumference: Math.PI,
		    rotation: -Math.PI ,
		    responsive: true,
		    layout: {
			    padding: {
				    left: 10,
				    right: 20,
				    top: 0,
				    bottom: 0,
			    },
		    },
		    legend: {
			    position: 'top',
			    display: false,
		    },
		    title: {
			    display: false,
			    text: 'Chart.js Doughnut Chart'
		    },
		    animation: {
			    animateScale: true,
			    animateRotate: true
		    }
	    }
    };
    const myChart = new Chart(ctx, chart_options_a);
  },
	props: ['data']
};

export default headerChart;
