<template>
	<div>
		<div class="row " >
			<div class="col-12 mx-auto">
				<event-devices v-if="components.eventDevices" :event="event"/>
			</div>
			<div class="col-12 col-md-9 mx-auto">
				<perso-devices v-if="components.persoDevices" :event="event"/>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import EventDevices from './EventDevices';
	import PersoDevices from './PersoDevices';
	import { ElDivider, ElBadge, ElMessage } from "element-plus";

	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			EventDevices, PersoDevices,
			ElDivider, ElBadge
		},
		inject: ['$baseURL', '$numFrm', '$formatDateTime', '$isCheckEventType'],
		emits: [ 'event-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: { name: '', country: 'india', type: '' },
					summary: {},
					eventDevices: [],
					persoDevices: [],
					devices: [],
					components: { eventDevices: true, persoDevices: true, },
					isLoading: { eventDevices: true, persoDevices: true, },
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				summary: {},
				eventDevices: [],
				persoDevices: [],
				devices: [],
				components: { eventDevices: true, persoDevices: true, },
				isLoading: { eventDevices: true, persoDevices: true, },
			};
		},
		methods: {
			setComponentsDisplay () {
				this.setDefault('components');
				// console.log(this.event.type);

				switch(this.event.type) {
					case 'event':
						this.components.eventDevices = true;
						this.components.persoDevices = true;
						// this.components.reports = true;
						break;
					case 'clg-access':
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			devicesUpdated(devices) { console.log('device-added', devices) },
			eventUpdated(event) {
				if(!event)  return;
				this.event = event;
				this.$emit('event-fetched', event);
			},
			dataLoad() {
				if(this.$isCheckEventType('fnb', this.event.type)) {
					// this.loadConfigData('event-wise');
					// this.loadConfigData('perso-wise');
					// this.loadConfigData('perso-all-wise');
				}
			},
			loadData(dataType) {
				this.isLoading[dataType] = true;
				axios.get(
					this.$baseURL + '/Vendors/ViewAll',
					{
						id: this.$route.params.code, dataType, eventType: this.event.type,
					},
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// console.log(response);
						if(response.data.success) { this.updateLocal(dataType, response.data); }
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});

			},
			loadConfigData(dataType) {
				if(!dataType)   return;
				// debugger;
				axios.get(
					this.$baseURL + '/Devices/List/',
					{ params: { code: this.event.id, type: dataType },
					headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(dataType === 'event-wise') {
							this.setDefault('eventDevices');
							this.eventDevices = response.data.devices;
							this.isLoading.eventDevices = false;
						} else if(dataType === 'perso-wise') {
							this.setDefault('persoDevices');
							this.persoDevices = response.data.devices;
							this.isLoading.persoDevices = false;
						}else if(dataType === 'perso-all-wise') {
							this.setDefault('devices');
							this.devices = response.data.devices;
							this.isLoading.devices = false;
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.isLoading[dataType.toLowerCase()] = false;
					});
			},
			loadDetails() {
				let u = this.loginUser, code = 0, url = '';
				switch (u.loginType) {
					case 'event':
					case 'operator':
						code = u.id;
						break;
					case 'admin':
						code = this.$route.params.code;
						break;
					default:
						return ;
						break;
				}
				axios.get(
					this.$baseURL + '/Event/Details/',
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						this.setDefault('event');
						this.event = response.data.event;
						this.isLoading.event = false;
						// this.dataLoad();
						this.$emit('event-fetched', this.event);
						this.$emit('loading-complete');
						this.setComponentsDisplay();
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});
			},
		},
		mounted() {
			// setTimeout(this.loadDetails, 500);
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			this.loadDetails();
		},
		watch: {
			$route (to, from){
				let u = JSON.parse(localStorage.getItem('user'));
				this.loginUser = u;
				if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
				this.loadDetails();
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
