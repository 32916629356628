<template>
	<div class="">
		<div class="card card-stats card-raised">
			<div class="card-header">
				<h5 class="card-category">Access Logs ( {{ logs.length }} )</h5>
				<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
			  <span class="badge badge-primary" v-if="isLoading">
				<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
			  </span>
				</div>
			</div>
			<div class="card-body">
				<el-input placeholder="Search Mobile" size="mini" prefix-icon="el-icon-search" v-model="search"/>
				<el-table class="w-100 aml-font-12" :data="logs" :height="400">
					<!--			  <el-table-column label="#" prop="id" width="75"/>-->
					<el-table-column sortable label="MOBILE" prop="mobile"/>
					<el-table-column sortable label="TIMESTAMP" prop="timestamp.createdAt">
						<template #default="scope">
							<span class="col">{{ displaydt(scope.row.timestamp.createdAt) }}</span>
						</template>
					</el-table-column>
					<el-table-column sortable align="center" label="HAS ACCESS" prop="hasAccess">
						<template #default="scope">
							<span class="badge mx-auto" :class="scope.row.hasAccess ? 'badge-success' : 'badge-danger'">{{ scope.row.hasAccess ? 'YES' : 'NO' }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

	</div>

	<span class="aml-dialog">
	</span>
</template>

<script>
import moment from "moment";
import {
	ElTable, ElTableColumn, ElInput,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider,   ElMessage
} from "element-plus";
	import axios from 'axios';


	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput,  ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider,
			moment
		},
		props: [ 'event', 'isLoading', 'logs', ],
		inject: [ '$baseURL', ],
		data() {
			return {
				search: '',
				loginUser: {},
			}
		},
		methods: {
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).local().format('DD-MM-YYYY HH:mm:ss') : ''; },
		},
		mounted() {
      console.log("aml", this.gatesmasters, this.categories);
		},
    watch: {
      categories(to, from) {
        console.log(to, from);
      }
    }
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-form-item__content {
		line-height: 20px !important;
	}
</style>
