<template>
	<Form ref="form" @submit="validate" :validation-schema="schema">
		<div class="row justify-content-center">
			<div class="row col-12 px-0">
				<div class="row mt-2 col-12 aml-text-teal-3 mx-auto px-0">
					<div class="col">
						<icon-checkbox v-model="event_.roundOff" icon="fas fa-sort-numeric-down-alt" title="Round Off Decimal"/>
					</div>
					<div class="col">
						<icon-checkbox v-model="event_.happyHour" icon="fas fa-laugh" title="Start Happy Hour"/>
					</div>
					<div class="col">
						<icon-checkbox v-model="event_.linkMobile" icon="fas fa-mobile-alt" title="Mobile Compulsory"/>
					</div>
					<div class="col">
						<icon-checkbox v-model="event_.useClubCard" icon="fas fa-cubes" title="Use Club Card"/>
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mt-4">
			<span class="col-6 col-md mx-auto text-center aml-text-teal-3">
				<h6 class="mb-0"><i class="el-icon-printer"></i> Printer</h6>
				<el-select class="select-primary" v-model="event_.printer" filterable allow-create placeholder="Select Printer">
					<el-option v-for="printer in defaultPrinters"
					           :key="printer.value" :label="printer.label.toUpperCase()" :value="printer.value"/>
				</el-select>
			</span>
		</div>

		<div class="row justify-content-center mt-3">
			<div class="col-md-10 mx-auto row">
				<!--<div class="text-left mt-2 col-12"><small>PASSWORDS</small></div>-->
				<el-divider class=""><small><i class="fas fa-key text-secondary"></i> POS PASSWORDS</small></el-divider>
				<div class="col-6 col-md mt-2" v-if="event.useNfc">
					<h6 class="aml-text-teal-3 aml-font-10">TOPUP PASSWORD</h6>
					<el-input placeholder="Topup Password" prefix-icon="fas fa-key" v-model="event_.devicePasswordTopup"/>
				</div>
				<div class="col-6 col-md mt-2">
					<h6 class="aml-text-teal-3 aml-font-10">SALE PASSWORD</h6>
					<el-input placeholder="Sale Password" prefix-icon="fas fa-key" v-model="event_.devicePassword"/>
				</div>
				<div class="col-6 col-md mt-2 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">ADMIN PASSWORD</h6>
					<el-input placeholder="Admin Password" prefix-icon="fas fa-key" v-model="event_.devicePasswordAdmin"/>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mt-3 col-12 mx-auto" v-if="event.useNfc && event.country === 'india'">
			<el-divider class=""><small><i class="fas fa-university text-secondary"></i> MSWIPE DETAILS</small></el-divider>
			<div class="row col-12">
				<div class="col-6 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">USERNAME</h6>
					<el-input placeholder="Username" prefix-icon="fas fa-user" v-model="event_.bankUsername"/>
				</div>
				<div class="col-6 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">PASSWORD</h6>
					<el-input placeholder="Password" prefix-icon="fas fa-key" v-model="event_.bankPassword"/>
				</div>
			</div>
			<div class="row mt-4 col-12">
				<div class="col-6 col-md-4 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">VERIFY CLIENT CODE</h6>
					<el-input placeholder="Client Code" prefix-icon="fas fa-user" v-model="event_.bankCredentials.mswipeVerifyClientCode"/>
				</div>
				<div class="col-6 col-md-4 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">VERIFY USER ID</h6>
					<el-input placeholder="Verify User Id" prefix-icon="fas fa-key" v-model="event_.bankCredentials.mswipeVerifyUserId"/>
				</div>
				<div class="col-6 col-md-4 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">VERIFY PASSWORD</h6>
					<el-input placeholder="Veridy Password" prefix-icon="fas fa-key" v-model="event_.bankCredentials.mswipeVerifyPassword"/>
				</div>
			</div>
		</div>

	</Form>
</template>
<script>
	import {
		ElUpload, ElSelect, ElOption,
		ElDatePicker, ElDivider, ElInput, ElForm, ElFormItem,
	} from "element-plus";
	import { IconCheckbox } from "@/components";
	import { Form } from "vee-validate";
	import * as Yup from "yup";

	export default {
		props: [ 'user', 'event', ],
		components: {
			IconCheckbox,
			Form,
			ElUpload, ElSelect, ElOption,
			ElDatePicker, ElDivider, ElInput, ElForm, ElFormItem,
		},
		data() {
			const schema = Yup.object().shape({
				// your validation
			});

			return {
				schema,
				event_: {
					roundOff: true, happyHour: false, linkMobile: true, printer: 'none', useClubCard: false,
					devicePasswordTopup: '', devicePassword: '', devicePasswordAdmin: '',
					bankUsername: '9400100344', bankPassword: '555555', isProduction: 1,
					bankCredentials_atomx: {
						mswipeUsername: '9400100344', mswipePassword: '555555',
						mswipeUpiApi: 'sdk', mswipeVerifyClientCode: 'ATOMXLIVE',
						mswipeVerifyUserId: 'ATOMXLIVE@SOL', mswipeVerifyPassword: 'ATOMX~DsD@281119',

						sunmipayMerchantName: ' ', sunmipayTransactionType: ' ', sunmipayMobileUserId: ' ', sunmipayCashlezPackageName: ' ',

						worldlineTid: ' ', worldlinePassword: ' ', worldlineSimUsername: ' ', worldlineSimPassword: ' ', worldlineBankFunctionPassword: ' ',
						worldlineCustomerQrAction: ' ', worldlineCustomerCardAction: ' ',
					},
					bankCredentials_bms: {
						mswipeUsername: '9300066508', mswipePassword: '555555',
						mswipeUpiApi: 'sdk', mswipeVerifyClientCode: 'BMSLIVE',
						mswipeVerifyUserId: 'BMSLIVE@SOL', mswipeVerifyPassword: 'BMSLIVE~DsD@061219',

						sunmipayMerchantName: ' ', sunmipayTransactionType: ' ', sunmipayAggregatorId: ' ', sunmipayMobileUserId: ' ', sunmipayCashlezPackageName: ' ',

						worldlineTid: ' ', worldlinePassword: ' ', worldlineSimUsername: ' ', worldlineSimPassword: ' ', worldlineBankFunctionPassword: ' ',
						worldlineCustomerQrAction: ' ', worldlineCustomerCardAction: ' ',
					},
					bankCredentials_new: {
						mswipeUsername: '', mswipePassword: '',
						mswipeUpiApi: 'sdk', mswipeVerifyClientCode: '',
						mswipeVerifyUserId: '', mswipeVerifyPassword: '',

						sunmipayMerchantName: ' ', sunmipayTransactionType: ' ', sunmipayAggregatorId: ' ', sunmipayMobileUserId: ' ', sunmipayCashlezPackageName: ' ',

						worldlineTid: ' ', worldlinePassword: ' ', worldlineSimUsername: ' ', worldlineSimPassword: ' ', worldlineBankFunctionPassword: ' ',
						worldlineCustomerQrAction: ' ', worldlineCustomerCardAction: ' ',
					},
					bankCredentials: {
						mswipeUsername: '9400100344', mswipePassword: '555555',
						mswipeUpiApi: 'sdk', mswipeVerifyClientCode: 'ATOMXLIVE',
						mswipeVerifyUserId: 'ATOMXLIVE@SOL', mswipeVerifyPassword: 'ATOMX~DsD@281119',

						sunmipayMerchantName: ' ', sunmipayTransactionType: ' ', sunmipayAggregatorId: ' ', sunmipayMobileUserId: ' ', sunmipayCashlezPackageName: ' ',

						worldlineTid: ' ', worldlinePassword: ' ', worldlineSimUsername: ' ', worldlineSimPassword: ' ', worldlineBankFunctionPassword: ' ',
						worldlineCustomerQrAction: ' ', worldlineCustomerCardAction: ' ',
					},
				},
				defaultPrinters2: [ 'rugtek', 'blue2', 'wisepos+', 'worldline', 'sprin_3', 'sprin_2', 'panda', 'none', ],
				defaultPrinters: [
					{ label: 'NONE', value: 'none' },
					{ label: 'Wisepos+', value: 'wisepos+' },
					{ label: 'EZETAP', value: 'airpay' },
					{ label: 'SunmiPay', value: 'sunmiPay+' },
					{ label: 'MOSAMBEE', value: 'MF919' },
					{ label: 'AIRPAY', value: 'airpay' },
					{ label: 'Worldline+', value: 'worldline' },
					{ label: 'Rugtek', value: 'rugtek' },
					{ label: 'BT-2inch', value: 'blue2' }, // { label: 'BT-3inch', value: 'blue3' },
					{ label: 'SPrin-3inch', value: 'sprin_3' }, { label: 'SPrin-2inch', value: 'sprin_2' },
					{ label: 'Panda-3inch', value: 'panda' },
				],
			};
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
		},
		methods: {
			validate() {
				return this.$refs.form.validate().then((res) => {
					res.event = this.event_;
					this.$emit("on-validated", res);
					return res;
				});
			},
		},
		watch: {
			event(to, from) {
				if(this.event.controller === 'bms') {
					this.event_.bankUsername = '9300066508';
					this.event_.bankPassword = '555555';
					this.event_.bankCredentials = this.event_.bankCredentials_bms;
				}
			},
			'event_.bankUsername': function (to, from){
				// handler(val){
					// do stuff
					console.log(to, from);
					if( to === '9400100344' ) this.event_.bankCredentials = this.event_.bankCredentials_atomx;
					else if( to === '9300066508' ) this.event_.bankCredentials = this.event_.bankCredentials_bms;
					else	this.event_.bankCredentials = this.event_.bankCredentials_new;
				// },
			},
		}
	};
</script>
<style>
	.card-wizard .choice.active:hover .icon, .card-wizard .choice:hover .icon {
		border: 1px solid #E3E3E3 !important;
		color: #888 !important;
	}
	.card-wizard .choice.active .icon {
		border: 1px solid #429e81 !important;
		color: #429e81 !important;
	}
	.card-wizard .choice .icon {
		border: 1px solid #e3596d !important;
		color: #e3596d !important;
	}

	.el-input__prefix { position: absolute !important; }
	.el-input__prefix .el-input__icon { left: 0px !important; position: relative !important; }
</style>
