import axios from "axios";
import Constants from "./Constants";

export default {
	getBase46(file) {
		const reader = new FileReader()
		reader.readAsDataURL(file);
		reader.onload = e => {
			if(!e.target.result)	alert('File Error');
			return e.target.result
		}
		reader.onerror = e => { alert('File Error'); return null; }
	},
	upload(data) { return axios.post(Constants.baseURL + "/Employee/UploadBill", data) }
}
