<template>
	<div class="card card-stats card-raised">
		<div class="card-header">
			<h5 class="card-category">Filter</h5>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-10 mx-auto">

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.dates" active-color="#13ce66" @change="(filter.settings.dates ? clearDay() : '');" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							<i class="far fa-clock ml-2"></i> Dates
						</h6>
						<div class="col-8 mx-auto aml-report-date-filter">
							<el-date-picker
								v-model="filter.dates2"
								type="datetimerange"
								align="right"
								unlink-panels
								:disabled="!filter.settings.dates"
								range-separator="-"
								start-placeholder="Start"
								end-placeholder="End"
								:shortcuts="shortcuts"
								ref="dates"
							/>
						</div>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right">
							<el-switch v-model="filter.settings.day" active-color="#13ce66" @change="(filter.settings.day ? filter.settings.dates = false : filter.day = []); $refs.day.focus()" inactive-color="#ccc" :active-value="true" :inactive-value="false"/>
							<i class="far fa-clock ml-2"/> Days
						</h6>
						<el-select ref="day" multiple :multiple-limit="2"
								   class="select-primary col-7 ml-2" v-model="filterDays"
								   :disabled="!filter.settings.day" filterable placeholder="Select Day">
							<el-option v-if="loginUser.type === 'admin'" label="Current Day" :value="-1"/>
							<el-option-group label="Days" v-if="event.dayCloseLogs && event.dayCloseLogs.length > 0">
								<el-option v-for="(day, index) in event.dayCloseLogs.slice().reverse()" :key="day.id" :label="day.name" :value="index"/>
							</el-option-group>
						</el-select>
						<i class="fas fa-spinner fa-spin my-auto text-primary" v-if="isLoading"/>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right"><i class="now-ui-icons education_atom"/> <span v-if="loginUser.loginType !== 'vendor'">Event / </span><span>Vendor</span></h6>
						<el-select class="select-primary col-7 ml-2" v-model="filter.report" filterable
								   placeholder="Select Report" :disabled="loginUser.loginType === 'vendor'">
							<el-option label="Event Report" value="0" v-if="loginUser.loginType !== 'vendor'"/>
							<el-option-group label="Vendors">
								<el-option v-for="report in defaultReports" :key="report.value" :label="report.label" :value="report.value"/>
							</el-option-group>
						</el-select>
						<i class="fas fa-spinner fa-spin my-auto text-primary" v-if="isLoading"/>
					</span>

					<span class="row mt-3 mx-auto">
						<h6 class="mb-0 aml-text-teal-3 col-4 my-auto text-right"><i class="el-icon-printer"/> Type</h6>
						<el-select class="select-primary col-7 ml-2" v-model="filter.type" filterable placeholder="Select Type" @change="reportTypeSelected">
							<el-option v-for="type in checkSettings('report_type')"
									   :key="type.value" :label="type.label.toUpperCase()" :value="type.value"/>
							<!--							<el-option v-for="type in [ 'summary', 'redemption_analytics', 'all_txns', 'balances' ]" :key="type" :label="type.toUpperCase()" :value="type"/>-->
						</el-select>
					</span>

					<span class="row mt-3 mx-auto" v-if="filter.settings.summary">
						<span class="col text-center py-2">
							<el-switch v-model="filter.settings.type" active-text="EXCEL" inactive-text="PDF" disabled active-color="#36b9ac" inactive-color="#108a9e" active-value="xlsx" inactive-value="pdf"></el-switch>
						</span>
					</span>

					<span class="row mt-3 mx-auto" v-if="filter.settings.details">
						<span class="col bg-light text-center pt-2">
							<el-switch v-model="filter.settings.mobile" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.mobile ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Mobile</div>
						</span>
						<span class="col bg-light text-center pt-2 d-none">
							<el-switch v-model="filter.settings.discount" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.discount ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Discount</div>
						</span>
						<span class="col bg-light text-center pt-2 d-none">
							<el-switch v-model="filter.settings.sc" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.sc ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Service Charge</div>
						</span>
						<span class="col bg-light text-center pt-2">
							<el-switch v-model="filter.settings.nfc" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.nfc ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> NFC Card Details</div>
						</span>
						<span class="col bg-light text-center pt-2">
							<el-switch v-model="filter.settings.bank" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.bank ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Bank Details</div>
						</span>
						<span class="col bg-light text-center pt-2 d-none">
							<el-switch v-model="filter.settings.mode" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.mode ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Mode Details</div>
						</span>
						<span class="col bg-light text-center pt-2 d-none">
							<el-switch v-model="filter.settings.pass" active-color="#13ce66" inactive-color="#ccc" :active-value="true" :inactive-value="false"></el-switch>
							<div class="badge aml-text-teal-3 border-0 col-12"><i :class="filter.settings.pass ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Pass Details</div>
						</span>
					</span>

					<span class="row my-3 text-center">
						<el-button class="mx-auto" type="primary" icon="fas fa-file-invoice mr-2" :loading="reportLoading" v-on:click="reportDownload()">Download</el-button>
					</span>

				</div>
			</div>
		</div>
	</div>

	<span class="aml-dialog-1">
		<el-dialog title="!! RESET DASHBOARD FAILED !!" v-model="pendingDeviceDialog" class="my-4">
			<h6 class="mt-2">KINDLY CLOSE ALL DEVICES TO PROCEED WITH RESET.</h6>
			<el-input placeholder="Search" class="my-2" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-11 aml-devices-table" :data="pendingDevices" :stripe="true" :border="true">
				<el-table-column sortable label="DEVICE" prop="print_id" align="center">
					<template #default="scope">
						<h5><b>{{ scope.row.print_id }}</b></h5>
					</template>
				</el-table-column>
				<el-table-column sortable label="TYPE" prop="device_type" align="center">
					<template #default="scope">
						<span>{{ scope.row.device_type }}</span> <el-divider class="my-1" direction="horizontal"/> <span>{{ scope.row.device_model }}</span>
					</template>
				</el-table-column>
				<el-table-column sortable label="MAC" prop="hardware_id" align="center">
					<template #default="scope">
						<span>{{ scope.row.hardware_id }}</span> <el-divider class="my-1" direction="horizontal"/> <span>{{ scope.row.android_id }}</span>
					</template>
				</el-table-column>
				<el-table-column sortable label="TIMESTAMPS" prop="last_login_at" align="center">
					<template #default="scope">
						<div class="text-danger font-weight-bold d-md-inline">LAST LOGIN AT: </div><span>{{ displaydt(scope.row.last_login_at) }}</span>
						<el-divider class="my-1" direction="horizontal"/>
						<div class="text-success font-weight-bold d-md-inline">LAST CLOSED AT: </div><span>{{ displaydt(scope.row.day_closed_at) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<template #footer>
					<span class="dialog-footer">
					  <el-button @click="pendingDeviceLoading = false; pendingDeviceDialog = false;" v-if="!pendingDeviceLoading">Cancel</el-button>
					  <el-button class="aml-bg-teal-3 border-0" type="primary" :loading="pendingDeviceLoading" @click="rDownload">PROCEED</el-button>
					</span>
				</template>
		</el-dialog>
	</span>

</template>

<script>
import {
	ElTable, ElTableColumn, ElInput, ElButton,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElDatePicker, ElOptionGroup, ElMessage
} from "element-plus";
import axios from 'axios';
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import moment from "moment";

export default {
	components: {
		ElTable,
		ElTableColumn,
		ElInput, ElButton, ElDialog,
		ElForm, ElFormItem, ElSelect, ElOption, ElOptionGroup,
		ElSwitch, ElDivider, ElDatePicker,
	},
	props: [ 'vendors', 'isLoading', 'event' ],
	inject: [ '$baseURL', ],
	emits: [  ],
	data() {
		return {
			loginUser: {},
			reportLoading: false,
			pendingDeviceLoading: false,
			pendingDeviceDialog: false,
			pendingDevices: [],
			search: '',
			defaultTypesAtomX: [
				{ value: 'summary', label: 'SUMMARY' },
				{ value: 'inventory_analytics', label: 'INVENTORY CARD WISE SUMMARY' },
				{ value: 'inventory_stock', label: 'INVENTORY STOCK SUMMARY' },
				{ value: 'mobile_dump', label: 'MOBILE DUMP' },
				{ value: 'stall_stock', label: 'STALLS STOCK SUMMARY' },
				{ value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' },
				{ value: 'all_txns', label: 'DETAILED TRANSACTION DUMP' },
				{ value: 'basic_txn_dump', label: 'BASIC TRANSACTION DUMP' },
				{ value: 'access_txns', label: 'ACCESSX DUMP' },
				{ value: 'access_summary', label: 'ACCESSX SUMMARY' },
				{ value: 'whitelist_persons_dump', label: 'WHITELIST PERSONS DUMP' },
				{ value: 'comp_txns', label: 'COMP TRANSACTION DUMP' },
				// { value: 'balances', label: 'Remaining Balances' },
				{ value: 'perso', label: 'PERSO DUMP' },
				{ value: 'tapx_dump', label: 'TAPX ONLINE TRANSACTION DUMP' },
				// { value: 'partials', label: 'Partial Transactions' },
			],
			defaultTypes: [
				{ value: 'summary', label: 'SUMMARY' },
				{ value: 'stall_stock', label: 'STALLS STOCK SUMMARY' },
				{ value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' },
				{ value: 'all_txns', label: 'BASIC TRANSACTION DUMP' },
				{ value: 'access_txns', label: 'ACCESSX DUMP' },
				{ value: 'access_summary', label: 'ACCESSX SUMMARY' },
				{ value: 'whitelist_persons_dump', label: 'WHITELIST PERSONS DUMP' },
				{ value: 'comp_txns', label: 'COMP TRANSACTION DUMP' },
				// { value: 'balances', label: 'Remaining Balances' },
				{ value: 'perso', label: 'PERSO DUMP' },
				{ value: 'tapx_dump', label: 'TAPX ONLINE TRANSACTION DUMP' },
				// { value: 'partials', label: 'Partial Transactions' },
			],
			defaultTypes3: [
				{ value: 'summary', label: 'SUMMARY' },
				// { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' },
				{ value: 'all_txns', label: 'BASIC TRANSACTION DUMP' },
				{ value: 'comp_txns', label: 'COMP TRANSACTION DUMP' },
				// { value: 'balances', label: 'Remaining Balances' },
				// { value: 'perso', label: 'PERSO DUMP' },
				{ value: 'tapx_dump', label: 'TAPX ONLINE TRANSACTION DUMP' },
				// { value: 'partials', label: 'Partial Transactions' },
			],
			defaultTypes_: [
				{ value: 'summary', label: 'SUMMARY' },
				{ value: 'comp_txns', label: 'COMP TRANSACTION DUMP' },
				{ value: 'perso', label: 'PERSO DUMP' },
				{ value: 'inventory_stock', label: 'INVENTORY STOCK SUMMARY' },
				{ value: 'stall_stock', label: 'STALLS STOCK SUMMARY' },
				{ value: 'access_txns', label: 'ACCESSX DUMP' },
				{ value: 'access_summary', label: 'ACCESSX SUMMARY' },
				// { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' },
			],
			defaultTypes2: [ 'summary', 'redemption_analytics', 'all_txns', 'balances', 'perso' ],
			defaultTypes2_: [ 'summary', 'redemption_analytics', ],
			defaultReports: [ ],
			filterDays: [],
			filter: { day: [], dates: [], dates2: [], report: "0", type: 'summary', settings: { details: false, summary: false, type: 'xlsx', day: false, dates: false, mobile: false, discount: false, sc: false, nfc: true, mode: false, bank: false, } },
			searchLoading: false,
			shortcuts: [{
				text: 'Last week',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					return [start, end]
				})(),
			}, {
				text: 'Last month',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					return [start, end]
				})(),
			}, {
				text: 'Last 3 months',
				value: (() => {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					return [start, end]
				})(),
			}],
		}
	},
	mounted() {
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');

		this.defaultReports = [  ];
		this.vendors.forEach(vendor => { this.defaultReports.push({ value: vendor.id, label: vendor.name }) });

		if(this.loginUser.loginType === 'vendor' && this.vendors.length > 0)	this.filter.report = this.vendors[0].id;
	},
	methods: {
		runToast(text, pos, type, ownText, ownIcon) {
			// const text =
			// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
			const icon = "now-ui-icons ui-1_bell-53";
			const content = {
				component: Notification,

				props: {
					ownText: ownText,
					ownIcon: ownIcon,
					icon: icon,
					text: text,
					type: type,
				},
			};
			const toast = useToast();
			toast(content, {
				hideProgressBar: true,
				icon: false,
				closeButton: false,
				position: pos,
			});
		},
		checkSettings( param ) {
			switch (param) {
				case 'report_type':
					switch (this.loginUser.loginType) {
						case 'admin':
							if (this.loginUser.id === 1480) return this.defaultTypesAtomX;
							return this.defaultTypes;
							break;
						case 'vendor':
							return this.defaultTypes_;
							break;
						case 'event':
							if ([545, 973].includes(this.loginUser.id)) this.defaultTypes3;
							if ([1235].includes(this.loginUser.id)) {
								// this.defaultTypes_.push({ value: 'mobile_dump', label: 'MOBILE DUMP' });
								return Array.prototype.concat(this.defaultTypes_, { value: 'mobile_dump', label: 'MOBILE DUMP' });
								// return this.defaultTypes_;
							}
							return this.defaultTypes_;
							break;
						default:
							return this.defaultTypes_;
							break;
					}
					// return this.loginUser.loginType === 'admin' && this.loginUser.id === 1480 ? this.defaultTypesAtomX :
					//  	this.loginUser.loginType === 'admin' ? this.defaultTypes :
					// 	this.loginUser.loginType === 'vendor' ? this.defaultTypes_ :
					// 	( this.loginUser.loginType === 'event' &&  [545, 973].includes(this.loginUser.id) ) ? this.defaultTypes3 :
					// 	( this.loginUser.loginType === 'event' &&  [1235].includes(this.loginUser.id) ) ? { ...this.defaultTypes_, ...{ value: 'mobile_dump', label: 'MOBILE DUMP' }} :
					// 	this.defaultTypes_
					// ;
					break;
			}
		},
		reportTypeSelected(type) {
			this.filter.settings.details = type === 'all_txns';
			this.filter.settings.summary = type === 'summary';
		},
		clearDay() {
			this.filter.settings.day = false; this.filterDays = this.filter.day = [];
		},
		displaydt(dt) { return dt ? moment(dt).local().format('LLL') : 'NA'; },
		checkDeviceClose() {
			this.reportLoading = true;
			axios.get(
				this.$baseURL + '/Devices/deviceClosedList/',
				{ params: { code: this.event.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						this.pendingDeviceLoading = false;
						debugger;
						if(response.data.devices && response.data.devices.length > 0) {
							this.pendingDevices = response.data.devices;
							this.pendingDeviceDialog = true;
							this.reportLoading = false;
							return ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>KINDLY CLOSE ALL DEVICES TO PROCEES.</b><br>Please try again.",
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						} else this.rDownload();
					} else
						this.reportLoading = false;
						return ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>" + response.data.message + "</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
				})
				.catch( (error) => {
					console.log("err:", error);
					this.pendingDeviceLoading = false;
					this.reportLoading = false;
					return ElMessage({
						showClose: true,
						dangerouslyUseHTMLString: true,
						message: "<b>Something went wrong</b><br>Please try again.",
						type: 'danger',
						grouping: true,
						duration: 5000,
					});
				});
		},
		reportDownload() {
			this.filter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			if(this.filter.settings.dates && this.filter.dates2.length !== 2)   return ;
			if(this.filter.settings.day && this.filterDays.length === 0)   return ;
			if(!this.filter.report)   return ;
			if(!this.filter.type)   return ;
			this.filter.eventId = this.event.id;
			if(!this.filter.eventId)   return ;
			if(this.filter.settings.dates)	{
				this.filter.dates[0] = moment(this.filter.dates2[0]).subtract(this.filter.dates2[0].getTimezoneOffset(), 'minutes');
				this.filter.dates[1] = moment(this.filter.dates2[1]).subtract(this.filter.dates2[1].getTimezoneOffset(), 'minutes');
				this.filterDays = [];
				this.filter.day = [];
			} else this.filter.dates = [];
			if(this.filter.settings.day)	{
				this.filter.dates = [];
				let d = Object.values(this.filterDays), d_;
				this.filter.day = d.length === 1 ? d : d.sort((a, b) => { return a - b; });
			}
			if(this.filter.type === 'summary' && !this.filter.settings.dates && !this.filter.settings.day)	this.checkDeviceClose();
			else this.rDownload();
		},
		rDownload() {
			this.pendingDeviceDialog = false;
			const reportURL = this.$baseURL + '/Reports/Report?filter=' + JSON.stringify(this.filter);
			this.reportLoading = false;
			return window.open( reportURL, '_blank' );
		},
	},
	watch: {
		vendors(to, from) {
			this.defaultReports = [  ];
			to.forEach(vendor => { this.defaultReports.push({ value: vendor.id, label: vendor.name }) });
		},
		$route (to, from){
			this.filter.day = [];
		},
	}
};
</script>
<style>
.el-table td, .el-table th {
	padding: 0.55rem 0 !important;
}
.el-input__inner {
	/*border-color: #0f7a8c !important;*/
}
.aml-dialog .el-dialog__header {
	background-color: #0f7a8c !important;

}
.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }
.aml-report-date-filter .el-date-editor .el-range-input { width: 80% !important; }

.aml-dialog-1 .el-dialog__header { background-color: #cc7b12 !important; }
.aml-dialog-1 .el-dialog__header .el-dialog__title { color: white !important; font-weight: bold !important; }
</style>
