<template>
	<div class="">
		<div class="col-12 col-md-6 card card-stats card-raised aml-bg-accessx">
			<div class="card-header">
				<h5 class="card-category">AccessX Categories Masterlist ( {{ categories.length }} )</h5>
				<div class="px-0" style="top: 15px;right: 5px; position: absolute;">
					<span class="col-3 text-right font-weight-bold aml-text-teal-4 cursor-pointer"
						@click="dialogOpen('catg', 'create' )">
						<i class="fa fa-plus"/> ADD
					</span>
          			<span class="badge badge-primary" v-if="isLoading"><small>LOADING</small> <i class="fas fa-spinner fa-spin"/></span>
				</div>
			</div>
			<div class="card-body">
				<el-input placeholder="Search Categories" size="mini" prefix-icon="el-icon-search" v-model="search_catg"/>
				<el-table class="w-100 aml-font-12 aml-accessx-table" :height="categories.length <= 3 ? '200' : '400'"
						  :data="categories.filter(data => !search_catg || data.name.toLowerCase().includes(search_catg.toLowerCase()))">
					<el-table-column label="#" prop="position" width="40"/>
					<el-table-column label="id" prop="id" width="50" v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))"/>
					<el-table-column sortable label="CATEGORY" prop="name"/>
					<el-table-column label="ALLOW" prop="allowCount" width="60">
						<template #default="scope"><span>{{ scope.row.allowCount === 0 ? 'ALL' : scope.row.allowCount }}</span></template>
					</el-table-column>
					<el-table-column label="EDIT" align="center" width="50">
						<template #default="scope">
							<span class="d-block cursor-pointer" :style="'background-color:#'+ (scope.row.colourCode ?? 'fff' )" @click="dialogOpen('catg', 'edit', scope.row )"> <i class="fas fa-edit" /></span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<div class="col-12 col-md-6 card card-stats card-raised aml-bg-accessx">
			<div class="card-header">
				<h5 class="card-category">AccessX Gates Masterlist ( {{ gatesmasters.length }} )</h5>
				<div class="px-0" style="top: 15px;right: 5px; position: absolute;">
					<span class="col-3 text-right font-weight-bold aml-text-teal-4 cursor-pointer"
						  @click="dialogOpen('gatesmaster', 'create' )">
						<i class="fa fa-plus"/> ADD
					</span>
					<span class="badge badge-primary" v-if="isLoading">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body">
				<el-input placeholder="Search Gates" size="mini" prefix-icon="el-icon-search" v-model="search_gatesmaster"/>
				<el-table class="w-100 aml-font-12 aml-accessx-table" :height="gatesmasters.length <= 3 ? '200' : '400'"
						  :data="gatesmasters.filter(data => !search_gatesmaster || data.name.toLowerCase().includes(search_gatesmaster.toLowerCase()))">
					<el-table-column label="#" prop="position" width="40"/>
					<el-table-column label="id" prop="id" width="50" v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))"/>
					<el-table-column sortable label="GATE" prop="name"/>
					<el-table-column label="COUNT CONFIG" v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">
						<template #default="scope">{{ scope.row.useCatgCounts === 1 ? 'CATEGORY' : 'GATE' }}</template>
					</el-table-column>
					<el-table-column label="EDIT" align="center" width="50">
						<template #default="scope">
							<span class="d-block cursor-pointer" @click="dialogOpen('gatesmaster', 'edit', scope.row )"> <i class="fas fa-edit" /></span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>

	<div class="col-12 col-md-6 card card-stats card-raised aml-bg-accessx">
		<div class="card-header">
			<h5 class="card-category">AccessX Gate Config ( {{ gates.length }} )</h5>
			<div class="px-0" style="top: 15px;right: 5px; position: absolute;">
				<span class="col-3 text-right font-weight-bold aml-text-teal-4 cursor-pointer"
					  @click="dialogOpen('gate', 'create')">
						<i class="fa fa-plus"/> ADD
					</span>
				<span class="badge badge-primary" v-if="isLoading"><small>LOADING</small> <i class="fas fa-spinner fa-spin"/></span>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Gates" size="mini" prefix-icon="el-icon-search" v-model="search_gate"/>
			<el-table class="w-100 aml-font-12 aml-accessx-table" :height="gates.length <= 3 ? '200' : '400'"
					  :data="gates.filter(data => !search_gate || data.gatesmaster_name.toLowerCase().includes(search_gate.toLowerCase()) || data.category_name.toLowerCase().includes(search_gate.toLowerCase()))">
				<el-table-column label="#" type="index" width="40"/>
				<el-table-column sortable label="GATE" prop="gatesmaster_name"/>
				<el-table-column sortable label="CATEGORY" prop="category_name"/>
				<el-table-column label="ALLOW" prop="allowCount" width="50" v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">
					<template #default="scope">{{ scope.row.allowCount === 0 ? 'ALL' : scope.row.allowCount }}</template>
				</el-table-column>
				<el-table-column label="EDIT" align="center" width="50">
					<template #default="scope">
						<span class="d-block cursor-pointer" @click="dialogOpen('gate', 'edit', scope.row )"> <i class="fas fa-edit" /></span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<span class="aml-dialog">
		<el-dialog :title="dialogOperation === 'edit' ? 'Edit AccessX Category' : 'Create AccessX Category'" v-model="catgDialog">
			<el-form :model="catg" class="mt-5">
				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-md-right text-left">Name</span>
						<el-input class="col-8" v-model="catg.name" autocomplete="off"/>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">Access Allow Count</span>
						<el-select class="select-aml-teal col-6" v-model="catg.allowCount" automatic-dropdown placeholder="Select Count">
							<el-option v-for="allowCount in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
									   :key="allowCount" :label="allowCount === 0 ? 'Always Allow' : allowCount.toString() + ' Times'" :value="allowCount"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row" v-if="dialogOperation === 'edit'">
						<span slot="label" class="col-md-3 col-3 text-md-right text-left">Position</span>
						<el-tag effect="dark" class="aml-bg-accessx aml-text-teal-4 border-0 font-weight-bold aml-font-20">{{ catg.position }}</el-tag>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-md-right text-left my-auto">ISSUER ID</span>
						<el-input class="col-md-2 col-4" v-model="catg.qrLogicIssuerId" minlength="2" maxlength="2" autocomplete="off" />
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-6 text-md-right text-left">SECTOR ID</span>
						<el-input class="col-md-2 col-4" v-model="catg.qrLogicSectorId" autocomplete="off"/>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-6 text-md-right text-left">DISCOUNT ID</span>
						<el-input class="col-md-2 col-4" v-model="catg.qrLogicDiscountId" autocomplete="off"/>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-6 text-md-right text-left">TICKET TYPE</span>
						<el-input class="col-md-2 col-4" v-model="catg.qrLogicTicketType" autocomplete="off"/>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-6 text-md-right text-left">TICKET / ACCRED</span>
						<el-input class="col-md-2 col-4" v-model="catg.qrLogicTktAccred" autocomplete="off"/>
					</span>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="catgDialog = false" v-if="!catgLoading">Cancel</el-button>
					<el-button type="primary" :loading="catgLoading" @click="saveCatg">Confirm</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog :title="dialogOperation === 'edit' ? 'Edit AccessX Gates Masterlist' : 'Create AccessX Gates Masterlist'" v-model="gatesmasterDialog">
			<el-form :model="gatesmaster" class="mt-5">
				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-10 text-md-right text-left">Name</span>
						<el-input class="col-8" v-model="gatesmaster.name" autocomplete="off"/>
					</span>
				</el-form-item>

				<el-form-item v-if="(loginUser.type === 'admin' && (loginUser.id === 1480 || loginUser.id === 45))">
					<span class="row">
						<el-switch v-model="gatesmaster.useCatgCounts" id="gatemasterEdit_useCatgCounts"
								   active-text="Use Category Counts" inactive-text="Use Gate Counts" active-color="#13ce66"
								   inactive-color="#ccc" :active-value="1" :inactive-value="0" />
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row" v-if="dialogOperation === 'edit'">
						<span slot="label" class="col-md-3 col-3 text-md-right text-left">Position</span>
						<el-tag effect="dark" class="aml-bg-accessx aml-text-teal-4 border-0 font-weight-bold aml-font-20">{{ gatesmaster.position }}</el-tag>
					</span>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="gatesmasterDialog = false" v-if="!gatesmasterLoading">Cancel</el-button>
					<el-button type="primary" :loading="gatesmasterLoading" @click="saveGatesmaster">Confirm</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog :title="dialogOperation === 'edit' ? 'Edit AccessX Gate' : 'Create AccessX Gate'" v-model="gateDialog">
			<el-form :model="gatesmaster" class="mt-5">
				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">Select Gate Master</span>
						<el-select class="select-aml-teal col-6" v-model="gate.gateId"
								   :disabled="dialogOperation === 'edit'" :multiple="dialogOperation === 'create'"
								   filterable automatic-dropdown placeholder="Select Gate Master">
							<el-option v-for="gatesmaster in gatesmasters" :key="gatesmaster.id"
									   :label="gatesmaster.position + ' | ' + gatesmaster.name" :value="gatesmaster.id"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">Select Category</span>
						<el-select class="select-aml-teal col-6" v-model="gate.categoryId"
							   :disabled="dialogOperation === 'edit'" :multiple="dialogOperation === 'create'"
							   filterable automatic-dropdown placeholder="Select Category">
							<el-option v-for="category in categories" :key="category.id"
									   :label="category.position + ' | ' + category.name" :value="category.id"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item>
					<span class="row">
						<span slot="label" class="col-md-3 col-12 text-md-right text-left">Access Allow Count</span>
						<el-select class="select-aml-teal col-6" v-model="gate.allowCount" automatic-dropdown placeholder="Select Count">
							<el-option v-for="allowCount in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
									   :key="allowCount" :label="allowCount === 0 ? 'Always Allow' : allowCount.toString() + ' Times'" :value="allowCount"/>
						</el-select>
					</span>
				</el-form-item>

				<el-form-item v-if="dialogOperation === 'edit'">
					<span class="row">
						<el-switch v-model="gate.status" id="gate_status"
								   active-text="ACTIVE" inactive-text="INACTIVE" active-color="#13ce66"
								   inactive-color="#ccc" active-value="active" inactive-value="inactive" />
					</span>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="gateDialog = false" v-if="!gateLoading">Cancel</el-button>
					<el-button type="primary" :loading="gateLoading" @click="saveGate()">Confirm</el-button>
				</span>
			</template>
		</el-dialog>
	</span>
</template>

<script>
import {
	ElTable, ElTableColumn, ElInput,
	ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
	ElSwitch, ElDivider, ElMessage, ElButton, ElTag
} from "element-plus";
import axios from 'axios';
import { color } from "d3";

export default {
	components: {
		ElTag,
		ElButton,
		ElTable,
		ElTableColumn,
		ElInput,  ElDialog,
		ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider,
	},
	props: [ 'event', 'isLoading', 'categories', 'gatesmasters', 'gates' ],
	inject: [ '$baseURL', '$accessxURL', ],
	emits: [ 'devices-updated' ],
	data() {
		return {
			search: '',
			search_catg: '',
			search_gatesmaster: '',
			search_gate: '',
			loginUser: {},

			catgDialog: false,
			catgData: {},
			catg: {},
			catgLoading: false,

			gatesmasterDialog: false,
			gatesmasterData: {},
			gatesmaster: {},
			gatesmasterLoading: false,

			gateDialog: false,
			gateData: {},
			gate: {},
			gateLoading: false,

			dialogOperation: 'create',
		}
	},
	methods: {
		color,
		dialogOpen( type = 'catg', operation = 'edit', dataRow = {} ) {
			this.dialogOperation = operation;
			switch (type) {
				case 'catg':
					if(operation === 'create')	{
						dataRow.eventId = this.event.id;
						dataRow.allowCount = 0;
					}
					break;
				case 'gatesmaster':
					if(operation === 'create')	{
						dataRow.eventId = this.event.id;
						dataRow.useCatgCounts = 1;
					}
					break;
				case 'gate':
					break;
			}
			this[type] = JSON.parse(JSON.stringify(dataRow));
			this[type + 'Data'] = dataRow;
			this[type + 'Dialog'] = true;
			// console.log(type, operation, dataRow);
		},
		saveCatg() {
			this.catgLoading = true;
			axios.post(
				this.$accessxURL + '/Category/' + this.dialogOperation.charAt(0).toUpperCase() + this.dialogOperation.slice(1),
				this.catg, { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
			.then(response => {
				if (response.data.success) {
					if(this.dialogOperation === "create") {
						// this.$router.go() ;
						this.catg.id = response.data.category.insertId;
						this.categories.push(this.catg);
					} else  this.$router.go() ;// this.catgData = { ...this.catgData, ...this.catg };
				} else ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000,  });

				this.catgLoading = false;
				this.catgDialog = false;
			})
			.catch((error) => {
				ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please Try Again", type: 'danger', grouping: true, duration: 5000,  });
				console.log("err:", error);
				this.catgLoading = false;
			});
		},
		saveGatesmaster() {
			this.gatesmasterLoading = true;
			axios.post(
				this.$accessxURL + '/GatesMaster/' + this.dialogOperation.charAt(0).toUpperCase() + this.dialogOperation.slice(1),
				this.gatesmaster, { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						if(this.dialogOperation === "create") {
							// this.$router.go() ;
							this.gatesmaster.id = response.data.gatesmaster.insertId;
							this.gatesmasters.push(this.gatesmaster);
						} else  this.$router.go() //this.gatesmasterData = {...this.gatesmasterData, ...this.gatesmaster };
					} else ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000,  });

					this.gatesmasterLoading = false;
					this.gatesmasterDialog = false;
				})
				.catch((error) => {
					ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please Try Again", type: 'danger', grouping: true, duration: 5000,  });
					console.log("err:", error);
					this.gatesmasterLoading = false;
				});
		},
		saveGate() {
			if(!this.gate.gateId || !this.gate.categoryId )	return ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Some Values are empty</b><br>", type: 'warning', grouping: true, duration: 5000,  });
			this.gateLoading = true;
			axios.post(
				this.$accessxURL + '/Gate/' + this.dialogOperation.charAt(0).toUpperCase() + this.dialogOperation.slice(1),
				this.gate, { headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` } }
			)
				.then(response => {
					if (response.data.success) {
						// if(this.dialogOperation === "create") {
						// 	//this.$router.go();
						// 	this.gates.push(response.data.gate);
						// } else  this.$router.go() //this.gateData = { ...this.gateData, ...this.gate };
						this.$router.go();
					} else ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>" + response.data.message, type: 'warning', grouping: true, duration: 5000,  });

					this.gateLoading = false;
					this.gateDialog = false;
				})
				.catch((error) => {
					ElMessage({  showClose: true, dangerouslyUseHTMLString: true, message: "<b>Something went wrong</b><br>Please Try Again", type: 'danger', grouping: true, duration: 5000,  });
					console.log("err:", error);
					this.gateLoading = false;
				});
		},
	},
	mounted() { this.loginUser = JSON.parse(localStorage.getItem('user')); },
	watch: {
		categories(to, from) {
			// console.log(to, from);
		}
	}
};
</script>
<style>
.el-table td, .el-table th {
	padding: 0.55rem 0 !important;
}
.el-form-item__content {
	line-height: 20px !important;
}
</style>
