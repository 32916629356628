<template>
	<div>
		<qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
			<div v-if="validationSuccess" class="validation-success">
				{{ result }}
			</div>

			<div v-if="validationFailure" class="validation-failure">
				{{ error }}
			</div>

			<div v-if="validationPending" class="validation-pending">
				Please wait...
			</div>

		</qrcode-stream>

		<p class="decode-result">Last result: <b>{{ result }}</b></p>


		<div class="card">
			<span class="text-primary"><i class="fas fa-close fa-3x"></i></span>
			<button class="btn btn-primary" v-if="camera === 'off'" @click="resetCamera">reset</button>
		</div>
	</div>
</template>

<script>
	import { QrcodeStream } from 'vue3-qrcode-reader'

	export default {

		components: {
			QrcodeStream,
		},

		data () {
			return {
				isValid: undefined,
				camera: 'auto',
				result: null,
				error: null,
			}
		},

		setup() {
			alert(btoa(JSON.stringify({personId: 1, bloodGroup: 'O+', address: 'Mandrem, Goa'})));
		},

		computed: {

			validationPending () {
				return this.isValid === undefined
					&& this.camera === 'off'
			},

			validationSuccess () {
				return this.isValid === true
			},

			validationFailure () {
				return this.isValid === false
			},

		},

		methods: {

			onInit (promise) {
				promise
					.catch(console.error)
					.then(this.resetValidationState)
			},

			resetValidationState () {
				this.isValid = undefined
			},

			validate(content) {
				try {
					this.result = JSON.parse(atob(content));
					return true;
				}catch (e) {
					this.error = "Invalid QR";
					console.log(e.message);
					return false;
				}
			},

			async onDecode (content) {
				// alert(content);
				this.result = content;
				this.turnCameraOff();

				// pretend it's taking really long
				await this.timeout(100);
				this.isValid = this.validate(content) // content.startsWith('http')

				// some more delay, so users have time to read the message
				// await this.timeout(2000)
				//
				// this.turnCameraOn()
			},

			resetCamera () {
				this.turnCameraOn();
			},

			turnCameraOn () {
				this.camera = 'auto'
			},

			turnCameraOff () {
				this.camera = 'off'
			},

			timeout (ms) {
				return new Promise(resolve => {
					window.setTimeout(resolve, ms)
				})
			}
		}
	}
</script>

<style scoped>
	.validation-success,
	.validation-failure,
	.validation-pending {
		position: absolute;
		width: 100%;
		height: 100%;

		background-color: rgba(255, 255, 255, .8);
		text-align: center;
		font-weight: bold;
		font-size: 1.4rem;
		padding: 10px;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
	.validation-success {
		color: green;
	}
	.validation-failure {
		color: red;
	}
</style>
