<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">

		<side-bar class="d-print-none" v-if="showSidebar" :title="user.name">
			<template v-slot:links>
				<!--<user-menu></user-menu>-->

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item">
					<!--<template v-slot:links>-->
					<sidebar-item v-for="child in item.children" :key="child.name" :link="child" />
					<!--</template>-->
				</sidebar-item>

				<sidebar-item v-for="item in sidebarItems" :key="item.name" :link="item" />

			</template>
		</side-bar>
		<div class="main-panel w-print-100" :class="showSidebar ? '' : 'w-100'">
			<div class="position-fixed h-100 w-100" style="z-index: 1029; background-color: #f6f0f0 !important"
				v-if="loading">
				<div class="custom-loading text-center position-relative" style="top: 30%">
					<svg class="circular" viewBox="-10, -10, 50, 50">
						<path class="path" d="
							M 30 15
							L 28 17
							M 25.61 25.61
							A 15 15, 0, 0, 1, 15 30
							A 15 15, 0, 1, 1, 27.99 7.5
							L 15 15
						  " style="stroke-width: 1px; fill: rgba(0, 0, 0, 0)" />
					</svg>
					<h5 class="text-primary">ALMOST THERE</h5>
				</div>
			</div>

			<top-navbar :pageHeading="pageHeading" :code="eventInfo && eventInfo.id ? eventInfo.id : ''" :event="eventInfo"
				:periodType="periodType" @periodSelected="periodSelected" @dayClosed="dayClosed" />
			<router-view name="header" :summaryInfo="summaryInfo" :eventInfo="eventInfo"
				:collegeAccessInfo="collegeAccessInfo" :user="user" :periodType="periodType" />

			<div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
				<router-view @event-fetched="eventFetched" @summary-fetched="summaryFetched"
					@colgaccess-fetched="colgaccessFetched" :user="user" :periodType="periodType"
					:newDayClosed="newDayClosed" @newDayClosedTurnOFF="newDayClosedTurnOFF"
					@loading-complete="loadingComplete" @loading-update="loadingUpdate" @setPageHeading="setPageHeading" />
			</div>
			<content-footer v-if="!$route.meta.hideFooter" />
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// function hasElement(className) {
//   return document.getElementsByClassName(className).length > 0;
// }
//
// function initScrollbar(className) {
//   if (hasElement(className)) {
//     new PerfectScrollbar(`.${className}`);
//   } else {
//     // try to init it later in case this component is loaded async
//     setTimeout(() => {
//       initScrollbar(className);
//     }, 100);
//   }
// }

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import UserMenu from "./extra/UserMenu.vue";
import SidebarItem from "../../../components/SidebarPlugin/SidebarItem";

export default {
	components: {
		SidebarItem,
		TopNavbar,
		ContentFooter,
		UserMenu,
	},
	data() {
		return {
			sidebarItems: [],
			pageHeading: 'Dashboard',
			summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
			event: { name: '', country: 'india', type: '' },
			user: {},
			eventInfo: { name: '', country: 'india', type: '' },
			collegeAccessInfo: { present: 0, absent: 0 },
			showSidebar: true,

			periodType: 'current',

			loading: true,

			defaultInfo: {
				eventInfo: { name: '', country: 'india', type: '' },
				collegeAccessInfo: { present: 0, absent: 0 },
				pageHeading: 'Dashboard',
				summaryInfo: { topup: { amount: 0, count: 0 }, sale: { amount: 0, count: 0 }, return: { amount: 0, count: 0 }, no_nfc_sale: { amount: 0, count: 0 } },
			},
			newDayClosed: false,
		};
	},
	methods: {
		setDefault(key = 'all') {
			if (key !== 'all') this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}

			// if( this.$isCheckEventType('clg-access', this.event.type) && this.event.useAccessx === 0 ) this.setColgFilterDefaults();
		},
		eventFetched(event) {
			// if(!event)  return;
			this.eventInfo = event;
			this.pageHeading = !event ? '' : event.name;
			if (event && Object.keys(event).length === 0) this.updateSidebarItems('all');
			else if (this.user.loginType === 'admin') this.updateSidebarItems('admin');
		},
		setPageHeading(pageHeading) { this.pageHeading = pageHeading; },
		loadingComplete() { this.loading = false; },
		loadingUpdate() { this.loading = true; },
		periodSelected(period) { this.periodType = period; },
		dayClosed() { this.newDayClosed = true; },
		newDayClosedTurnOFF() { this.newDayClosed = false; },
		summaryFetched(summary) { this.summaryInfo = summary; },
		colgaccessFetched(accessInfo) { this.collegeAccessInfo = accessInfo; },
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		updateSidebarItems(userType = 'all') {
			switch (userType) {
				case 'all':
					if (!localStorage.getItem('xToken') || typeof localStorage.getItem('xToken') === "undefined" || !localStorage.getItem('user')) {
						this.$router.push('/Login')
					} else {
						this.user = JSON.parse(localStorage.getItem('user'));
						this.sidebarItems = [];
						switch (this.user.loginType) {
							case 'admin':
								// this.sidebarItems.push({ name: 'Dashboard', icon: 'now-ui-icons design_app', path: '/' });
								this.sidebarItems.push({ name: 'Device Masterlist', icon: 'fas fa-bezier-curve', path: '/Masterlist/All' });
								// this.sidebarItems.push({ name: 'Volunteer Masterlist', icon: 'fas fa-users', path: '/VolunteerMasterlist' });
								this.sidebarItems.push({
									name: 'Event', icon: 'now-ui-icons education_atom',
									children: [
										{ name: 'Create', icon: 'now-ui-icons design_app', path: '/Create' },
										{ name: 'View All', icon: 'now-ui-icons design_app', path: '/ViewAll' }
									]
								});
								if (typeof this.eventInfo.id !== 'undefined' && this.eventInfo.id === 1356 ) {
									this.sidebarItems.push({ name: 'Logs', icon: 'fas fa-th-list', path: '/Pacha/Logs' });
									this.sidebarItems.push({ name: 'Reservations', icon: 'fas fa-th-list', path: '/Whitelist' });
								}
								if (typeof this.eventInfo.id !== 'undefined' && typeof this.eventInfo !== 'undefined' && this.eventInfo.useAccessx === 1) {
									this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist' });
									// this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist2' });
									this.sidebarItems.push({ name: 'AccessXLogs', icon: 'fas fa-th-list', path: '/AccessX/Logs' });
								}
								if (this.user.admin_id === 1480 && this.user.admin_id === 45) this.sidebarItems.push({ name: 'Payouts', icon: 'fas fa-database', path: '/Payouts/' });
								this.sidebarItems.push({ name: 'TapX Transactions', icon: 'fas fa-stroopwafel', path: '/TapXTransactions' });
								break;
							case 'event':
								if(this.user.id !== 1356) {
									// console.log('aaa1', this.user);
									if (this.user.product === 'event') {
										this.sidebarItems.push({ name: 'Device Masterlist', icon: 'fas fa-bezier-curve', path: '/Masterlist/All' });
										// this.sidebarItems.push({ name: 'Volunteer Masterlist', icon: 'fas fa-users', path: '/VolunteerMasterlist' });
										if (this.user.use_accessx === 1){
											this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist' });
											// this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist2' });
											this.sidebarItems.push({ name: 'AccessXLogs', icon: 'fas fa-th-list', path: '/AccessX/Logs' });
										}
										this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics' });
										this.sidebarItems.push({ name: 'Configuration', icon: 'el-icon-set-up', path: '/Config' });
										this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/Reports' });
										this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Transactions' });
										this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-mobile', path: '/Devices' });
										// if( this.user.admin_id === 1480 ) this.sidebarItems.push({ name: 'EventData', icon: 'fas fa-database', path: '/EventData' });
										this.sidebarItems.push({ name: 'Freebies', icon: 'fas fa-people-arrows', path: '/Freebies' });
										this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/Blocked' });
										this.sidebarItems.push({ name: 'TapX Transactions', icon: 'fas fa-stroopwafel', path: '/TapXTransactions' });
									} else if (this.user.product === 'armourx') {
										this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics' });
										this.sidebarItems.push({ name: 'Configuration', icon: 'el-icon-set-up', path: '/Config' });
									}
								} else {
									this.sidebarItems.push({ name: 'Logs', icon: 'fas fa-th-list', path: '/Pacha/Logs' });
									this.sidebarItems.push({ name: 'Reservations', icon: 'fas fa-th-list', path: '/Whitelist' });
								}
								break;
							case 'reports':
								this.sidebarItems.push({ name: 'View All', icon: 'now-ui-icons design_app', path: '/ViewAll' });
								if (typeof this.eventInfo.id !== 'undefined' && typeof this.eventInfo !== 'undefined') {
									this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Transactions/' + this.eventInfo.id });
									this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-credit-card', path: '/Reports/' + this.eventInfo.id });
								}

								break;
							case 'operator':
								// console.log('aaa1', this.user);
								if (this.user.product === 'event') {
									if (typeof this.eventInfo.id !== 'undefined' && this.eventInfo.id === 1356 ) {
										this.sidebarItems.push({ name: 'Logs', icon: 'fas fa-th-list', path: '/Pacha/Logs' });
										this.sidebarItems.push({ name: 'Reservations', icon: 'fas fa-th-list', path: '/Whitelist' });
										break;
									}
									this.sidebarItems.push({ name: 'Device Masterlist', icon: 'fas fa-bezier-curve', path: '/Masterlist/All' });
									// this.sidebarItems.push({ name: 'Volunteer Masterlist', icon: 'fas fa-users', path: '/VolunteerMasterlist' });
									if (typeof this.eventInfo.id !== 'undefined' && typeof this.eventInfo !== 'undefined' && this.eventInfo.useAccessx === 1){
										this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist' });
										// this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist2' });
										this.sidebarItems.push({ name: 'AccessXLogs', icon: 'fas fa-th-list', path: '/AccessX/Logs' });
									}
									this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics' });
									this.sidebarItems.push({ name: 'Configuration', icon: 'el-icon-set-up', path: '/Config' });
									this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/Reports' });
									this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Transactions' });
									this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-mobile', path: '/Devices' });
									// if( this.user.admin_id === 1480 ) this.sidebarItems.push({ name: 'EventData', icon: 'fas fa-database', path: '/EventData' });
									this.sidebarItems.push({ name: 'Freebies', icon: 'fas fa-people-arrows', path: '/Freebies' });
									this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/Blocked' });
									this.sidebarItems.push({ name: 'TapX Transactions', icon: 'fas fa-stroopwafel', path: '/TapXTransactions' });
								} else if (this.user.product === 'armourx') {
									this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics' });
									this.sidebarItems.push({ name: 'Uploads', icon: 'fas fa-archive', path: '/Uploads' });
								} else {
									this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics' });
								}
								break;
						}
						this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });
					}
					break;
				case 'admin':
					this.sidebarItems = [];
					if (false && typeof this.eventInfo.id !== 'undefined' && this.eventInfo.id === 1356 ) {
						this.sidebarItems.push({ name: 'Logs', icon: 'fas fa-th-list', path: '/Pacha/Logs/' + this.eventInfo.id });
						this.sidebarItems.push({ name: 'Reservations', icon: 'fas fa-th-list', path: '/Whitelist/' + this.eventInfo.id });
						this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });
						// this.sidebarItems.push({ name: 'Reservations', icon: 'fas fa-th-list', path: '/Whitelist2/' + this.eventInfo.id });
					} else {
						this.sidebarItems.push({ name: 'Device Masterlist', icon: 'fas fa-bezier-curve', path: '/Masterlist/All' });
						// this.sidebarItems.push({ name: 'Volunteer Masterlist', icon: 'fas fa-users', path: '/VolunteerMasterlist' });
						this.sidebarItems.push({
							name: 'Event', icon: 'now-ui-icons education_atom',
							children: [
								{ name: 'Create', icon: 'now-ui-icons design_app', path: '/Create' },
								{ name: 'View All', icon: 'now-ui-icons design_app', path: '/ViewAll' }
							]
						});
						// this.sidebarItems.push({ name: 'Configuration', icon: 'fas fa-cogs', path: '/Analytics/' + this.eventInfo.id + '/Config' });
						// this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/Analytics/' + this.eventInfo.id + '/Reports' });
						// this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Analytics/' + this.eventInfo.id + '/Transactions' });
						// this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-mobile', path: '/Analytics/' + this.eventInfo.id + '/Devices' });
						// this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/logout' });
						if (typeof this.eventInfo.id !== 'undefined' && typeof this.eventInfo !== 'undefined') {
							if (this.eventInfo.useAccessx === 1) {
								this.sidebarItems.push({ name: 'Whitelist', icon: 'fas fa-th-list', path: '/Whitelist/' + this.eventInfo.id });
								this.sidebarItems.push({ name: 'AccessXLogs', icon: 'fas fa-th-list', path: '/AccessX/Logs/' + this.eventInfo.id });
							}
							if (typeof this.eventInfo.id !== 'undefined' && this.eventInfo.id === 1356 ) {
								this.sidebarItems.push({ name: 'PACHA LOGS', icon: 'fas fa-th-list', path: '/Pacha/Logs/' + this.eventInfo.id });
								// this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });
							}

							this.sidebarItems.push({ name: 'Analytics', icon: 'fas fa-chart-area', path: '/Analytics/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Configuration', icon: 'el-icon-set-up', path: '/Config/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Reports', icon: 'fas fa-file-contract', path: '/Reports/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Transactions', icon: 'fas fa-credit-card', path: '/Transactions/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Devices', icon: 'fas fa-mobile', path: '/Devices/' + this.eventInfo.id });
							if (this.eventInfo.adminId === 1480) this.sidebarItems.push({ name: 'EventData', icon: 'fas fa-database', path: '/EventData/' + this.eventInfo.id });
							// this.sidebarItems.push({ name: 'Payouts', icon: 'fas fa-people-arrows', path: '/Payouts/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Freebies', icon: 'fas fa-people-arrows', path: '/Freebies/' + this.eventInfo.id });
							this.sidebarItems.push({ name: 'Blocked', icon: 'fas fa-ban', path: '/Blocked/' + this.eventInfo.id });
						}
						this.sidebarItems.push({ name: 'TapX Transactions', icon: 'fas fa-stroopwafel', path: '/TapXTransactions' });
						this.sidebarItems.push({ name: 'Logout', icon: 'fas fa-sign-out-alt', path: '/login' });
					}

					break;
			}

		},
	},
	mounted() {
		// let docClasses = document.body.classList;
		// let isWindows = navigator.platform.startsWith("Win");
		// if (isWindows) {
		//   // if we are on windows OS we activate the perfectScrollbar function
		//   initScrollbar("sidebar");
		//   initScrollbar("sidebar-wrapper");
		//
		//   docClasses.add("perfect-scrollbar-on");
		// } else {
		//   docClasses.add("perfect-scrollbar-off");
		// }

		let e = this.$route.query.e;
		if (!e) {
			this.updateSidebarItems();
			this.user = JSON.parse(localStorage.getItem('user'));
			if (!this.user || this.user.v < 2) this.$router.push('/Login');
		} else this.showSidebar = false;
	},
	watch: {
		$route(to, from) {
			this.loading = true;
		}
	}
};
</script>
<style>
.custom-loading .circular {
	margin-right: 6px;
	width: 200px;
	height: 200px;
	animation: loading-rotate 2s linear infinite;
}

.custom-loading .circular .path {
	animation: loading-dash 1.5s ease-in-out infinite;
	stroke-dasharray: 90, 150;
	stroke-dashoffset: 0;
	stroke-width: 2;
	stroke: #f96332;
	stroke-linecap: round;
}
</style>
