<template>
	<Form ref="form" @submit="validate" v-if="event.useNfc">

		<el-divider class=""><small><i class="fas fa-key text-secondary mr-2"></i> CARD SETTINGS</small></el-divider>

		<div class="row justify-content-center col-12 col-md-11 mx-auto px-0">
			<span class="col-md-6 col-10 row mx-auto text-center aml-text-teal-3">
				<h6 class="mb-0 col-5 my-auto"><i class="fas fa-key"></i> CARD SETTING</h6>
				<el-select class="select-primary col-7 px-0"
				           v-model="event_.cardSetting" @change="updateBalanceSetting"
				           filterable allow-create default-first-option placeholder="Select Old Event">
					<el-option v-for="event in defaultEvents" :key="event.id" :label="(event.id ? event.id + ' | ' : '') + event.name" :value="event.id"/>
				</el-select>
			</span>
			<span class="col-md-6 col-10 row mx-auto text-center aml-text-teal-3" v-show="components.balanceSetting">
				<h6 class="mb-0 col-5 my-auto"><i class="fas fa-coins"></i> BALANCE</h6>
				<el-select class="select-primary col-7 px-0" v-model="event_.balanceSetting"
				           filterable allow-create default-first-option placeholder="Select Old Event">
					<el-option v-for="event in events" :key="event.id" :label="(event.id ? event.id + ' | ' : '') + event.name" :value="event.id"/>
				</el-select>
			</span>
		</div>

		<el-divider class=""><small><i class="fas fa-plus text-secondary mr-2"></i> TOPUP</small></el-divider>

		<div class="row justify-content-center col-12 col-md-11 mx-auto px-0">

			<div class="row mx-auto mt-2 col-12 px-0" v-if="event_.gstSetting">
				<span class="col-6 text-right px-0">
					<h6 class=" mt-2 col-12 aml-text-teal-3">TOPUP GST</h6>
					<span class="col-12">
						<el-switch
							v-model="event_.topupGstType"
							active-text="SGST" inactive-text="IGST"
							active-color="#999" inactive-color="#ccc"
							active-value="s" inactive-value="i"
						/>
					</span>
				</span>
				<span class="col-6 px-0">
					<h6 class="text-left mt-2 col-12 aml-text-teal-3">TOPUP GST %</h6>
					<el-input-number class="col-12 col-md-7" v-model="event_.topupGstPer" :precision="2" controls-position="right" :max="100"/>
				</span>
			</div>

			<div class="row col-12 px-0 mt-3">
				<!--<div class="row mt-4 col-12 col-md-10 mx-auto px-0">-->
					<!--<div class="text-left mt-2 col-10 mx-auto"><small>TOPUP BUTTONS</small></div>-->
						<!--<span v-for="v in [0,1,2,3]" :key="v" class="badge aml-bg-teal-3 border-0 text-white ml-1">-->

				<div class="row mb-4 col-12 aml-text-teal-3 mx-auto px-0">
					<div class="col">
						<icon-checkbox v-model="event_.manualTopup" icon="fas fa-edit" title="Custom Topup Input"/>
					</div>
				</div>

					<span class="col-12 px-0 mb-2">
						<div class="row col-12 mx-auto px-0">
							<h6 class="aml-text-teal-3 col-2 my-auto text-right px-0">#</h6>
							<h6 class="col-5 aml-text-teal-3 text-center">Topup Name</h6>
							<h6 class="col-5 aml-text-teal-3 text-center">Topup Amount</h6>
						</div>
					</span>

					<span v-for="v in [0,1,2,3]" :key="v" class="col-12 px-0">
						<!--<small>{{ event_.topupBtns[v] }}:</small> {{ event_.topupVals[v] }}-->
						<div class="row col-12 mx-auto px-0">
							<h6 class="aml-text-teal-3 col-2 my-auto text-right px-0">#{{ v+1 }}</h6>
							<span class="col-5"><el-input :placeholder="'Button #' + v" prefix-icon="fas fa-wallet" v-model="event_.topupBtns[v]"></el-input></span>
							<span class="col-5"><el-input type="number" :placeholder="'Amount #' + v" prefix-icon="fas fa-coins" v-model="event_.topupVals[v]"></el-input></span>
						</div>
					</span>
					<!--</span>-->
				<!--</div>-->
			</div>

			<div class="row my-4">
				<div class="col-6 col-md mt-3">
					<h6 class="aml-text-teal-3 aml-font-10">CARD FEE</h6>
					<el-input type="number" placeholder="Card Fee" prefix-icon="fas fa-monument" v-model="event_.cardFee"/>
				</div>
				<div class="col-6 col-md mt-3">
					<h6 class="aml-text-teal-3 aml-font-10">1st TOPUP</h6>
					<el-input type="number" placeholder="1st Min Topup" prefix-icon="fas fa-monument" v-model="event_.minTopup"/>
				</div>
				<div class="col-6 col-md mt-3 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">MAX WALLET</h6>
					<el-input type="number" placeholder="Max Wallet" prefix-icon="fas fa-monument" v-model="event_.maxTopupWallet"/>
				</div>
				<div class="col-6 col-md mt-3 mx-auto">
					<h6 class="aml-text-teal-3 aml-font-10">TOKEN CONVERSION RATE</h6>
					<el-input type="number" :min="0.01" placeholder="Conversion Rate" prefix-icon="fas fa-monument" v-model="event_.tokenConversion"/>
				</div>
			</div>
		</div>

		<div class="row mx-auto mt-2 col-11 mx-auto">
			<el-divider class=""><small><i class="fas fa-undo-alt text-secondary mr-2"></i> RETURN</small></el-divider>
		</div>

		<div class="text-center">

			<div class="row mt-4">
				<span class="col">
					<el-switch v-model="event_.returnCardFee" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i :class="event_.returnCardFee === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Return Card Fee</div>
				</span>
				<span class="col">
					<el-switch v-model="event_.returnCartValue" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i :class="event_.returnCartValue === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Return Balance</div>
				</span>
				<span class="col mx-auto">
					<el-switch v-model="event_.returnCardFull" active-color="#13ce66" inactive-color="#ccc" :active-value="0" :inactive-value="1"/>
					<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i :class="event_.returnCardFull === 0 ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Return Bank Card Balance</div>
				</span>
				<span class="col mx-auto">
					<el-switch v-model="event_.returnCardFeeCash" active-color="#13ce66" inactive-color="#ccc" :active-value="1" :inactive-value="0"/>
					<div class="badge aml-text-teal-3 border-0 bg-white col-12"><i :class="event_.returnCardFeeCash === 1 ? 'fas fa-check text-success' : 'fas fa-times text-danger'"/> Return Card Fee In Cash</div>
				</span>
			</div>

			<div class="row m-4">
				<div class="col-6 col-md mt-3">
					<h6 class="aml-text-teal-3 aml-font-10">MIN RETURN BALANCE</h6>
					<el-input type="number" :min="0" placeholder="Card Fee" prefix-icon="fas fa-monument" v-model="event_.returnMinAmount"/>
				</div>
				<div class="col-6 col-md mt-3">
					<h6 class="aml-text-teal-3 aml-font-10">MAX RETURN AMOUNT</h6>
					<el-input type="number" :min="0" placeholder="1st Min Topup" prefix-icon="fas fa-monument" v-model="event_.returnMaxAmount"/>
				</div>
			</div>

		</div>

	</Form>
</template>
<script>
	import {
		ElDivider, ElBadge, ElInput, ElInputNumber,
		ElSelect, ElOption, ElDatePicker,
		ElTooltip, ElSwitch,
	} from 'element-plus';
	import { IconCheckbox } from "@/components";
	import { VeeInput } from "@/components";
	import { Form } from "vee-validate";
	import * as Yup from "yup";

	export default {
		props: [ 'user', 'event', 'events', 'defaultEvents', 'selectedCardSetting', ],
		components: {
			VeeInput,
			Form,
			ElDivider, ElBadge, ElInput, ElInputNumber,
			ElSelect, ElOption, ElDatePicker,
			ElTooltip, ElSwitch,
			IconCheckbox,
		},
		data() {
			return {
				event_: {
					topupBtns: [ 50, 100, 500, 1000 ], topupVals: [ 50, 100, 500, 1000 ],
					manualTopup: false,
					topupGstType: 'S', topupGstPer: 0,
					cardFee: ( this.user.type === 'admin' && this.user.id === 1480 ) ? 100 : 0, minTopup: 500, maxTopupWallet: 100000,
					returnCardFee: 0, returnCartValue: 0, returnCardFull: 1,
					cardSetting: ( this.user.type === 'admin' && this.user.id === 1480 ) ? 928 : 0, balanceSetting: 0,
					tokenConversion: 1,
					returnMinAmount: 0, returnMaxAmount: 0,
				},
				components: { balanceSetting: true },
			};
		},
		methods: {
			validate() {
				return this.$refs.form.validate().then((res) => {
					if (!res.valid) {
						return !res;
					}
					res.event = this.event_;
					this.$emit("on-validated", res);
					return res;
				});
			},
			updateBalanceSetting() {
				// console.log('idhar');
				// this.components.balanceSetting = ( this.event_.cardSetting === 0 );
				// this.event_.balanceSetting = this.event_.cardSetting;
			},
		},
		watch: {
			selectedCardSetting(to, from) {
				this.event_.cardSetting = to;
			},
		}
	};
</script>
<style></style>
