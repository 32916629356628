<template>
	<Form ref="form" @submit="validate" :validation-schema="schema">
		<el-form :model="event_" ref="form">
			<div class="row justify-content-center">
				<div class="col-12 col-sm-8 mt-3 aml-text-teal-3">
					<div class="mt-3 text-center row">
						<el-form-item class="col-12" prop="name" :rules="[ { required: true, message: 'Name is required'}, ]" >
						<span class="row">
							<h6 class="col-md-3 col-12 my-auto px-md-0 text-md-right text-left"><i class="now-ui-icons education_atom"/> Name</h6>
							<el-input class="col-md-8 col-12"
									  v-model="event_.name" @blur="onEventNameBlur"
									  prefix-icon="now-ui-icons education_atom" placeholder="Name"/>
						</span>
						</el-form-item>
					</div>

					<div class="mt-3 text-center row">
						<span class="mx-auto row">
							<span class="d-inline mt-3 my-auto">
								<div class="text-center row" v-if="user.type === 'admin' && user.id === 1480">
									<h6 class="col-3 my-auto px-0 text-right"><i class="now-ui-icons education_atom"/> Acc Manager</h6>
									<span class="col-9 ">
										<el-select class="select-primary " v-model="event_.mgrId" filterable placeholder="Select Account Manager" autocomplete="off" clearable>
											<el-option v-for="mgr in accmgrs" :key="mgr.id" :label="mgr.name.toUpperCase()" :value="mgr.id"/>
										</el-select>
									</span>
								</div>
							</span>
						</span>
					</div>

					<div class="mt-3 text-center row">
					<span class="mx-auto row">
						<span class="d-inline mt-3 my-auto">
							<div class="text-center row" v-if="user.type === 'admin' && user.id === 1480">
								<h6 class="col-4 my-auto px-0 text-right"><i class="now-ui-icons education_atom"/> Type</h6>
								<span class="col-8 mr-md-1">
									<el-select class="select-primary border-right pr-4" v-model="event_.type" filterable allow-create placeholder="Select Type" autocomplete="off">
										<el-option v-for="type in defaultTypes" :key="type" :label="type.toUpperCase()" :value="type"/>
									</el-select>
								</span>
							</div>
							<h6 v-else class="aml-text-teal-3 border-0 bg-white my-auto text-right border-right"><i class="fas fa-archway"/> {{ event_.type }}</h6>
						</span>
						<span class=" d-inline ml-4 my-auto mt-3 text-center">
							<div class="text-center row" v-if="user.type === 'admin' && user.id === 1480">
								<h6 class="col-5 my-auto px-0 text-right"><i class="now-ui-icons education_atom"/> Controller</h6>
								<span class="col-7 mr-md-1">
									<el-select class="select-primary " v-model="event_.controller" filterable allow-create placeholder="Select Controller" autocomplete="off">
										<el-option v-for="controller in [ 'atomx', 'skypay', 'rizzpay' ]" :key="controller" :label="controller.toUpperCase()" :value="controller.toLowerCase()"/>
									</el-select>
								</span>
							</div>
							<h6 v-else class="aml-text-teal-3 border-0 bg-white my-auto"><i class="fas fa-sitemap mr-2"/> {{ event_.controller }}</h6>
						</span>
					</span>
					</div>

					<div class="row aml-text-teal-3 mt-3">
					<span class="col-6 col-md mt-3 mx-auto text-center">
						<h6 class="mb-0"><i class="fas fa-globe-asia"/> Country</h6>
						<el-select class="select-primary" v-model="event_.country" filterable allow-create placeholder="Select Country">
							<el-option v-for="country in defaultCountries"
									   :key="country" :label="country.toUpperCase()" :value="country"/>
						</el-select>
					</span>

						<span class="col-6 col-md mt-3 mx-auto text-center">
						<h6 class="mb-0"><i class="fas fa-globe-asia"/> Timezone</h6>
						<el-select class="select-primary" v-model="event_.tz" filterable allow-create placeholder="Select Timezone">
							<el-option v-for="tz in defaultTZ"
									   :key="tz" :label="tz" :value="tz"/>
						</el-select>
					</span>

						<span class="col-6 col-md mt-3 mx-auto text-center">
						<h6 class="mb-0"><i class="fas fa-map-pin"/> City</h6>
						<el-select class="select-primary" v-model="event_.locationCity" filterable allow-create placeholder="Select City">
							<el-option v-for="city in defaultCities"
									   :key="city" :label="city.toUpperCase()" :value="city"/>
						</el-select>
					</span>

						<span class="col-6 col-md mt-3 mx-auto text-center">
						<h6 class="mb-0"><i class="fas fa-money-bill-wave-alt"/> Currency</h6>
						<el-select class="select-primary" v-model="event_.currency" filterable allow-create placeholder="Select Currency">
							<el-option v-for="currency in defaultCurrencies"
									   :key="currency" :label="currency.toUpperCase()" :value="currency"/>
						</el-select>
					</span>

						<span class="row col-12">
						<span class="col-6 col-md mt-3 mx-auto text-center">
							<h6 class="mb-0"><i class="fas fa-globe-asia"/> Organizer</h6>
							<el-input v-model="event_.organizer"
									  prefix-icon="now-ui-icons education_atom" placeholder="Organizer"/>
						</span>

						<span class="col-6 col-md mt-3 mx-auto text-center">
							<h6 class="mb-0"><i class="fas fa-globe-asia"/> Client</h6>
							<el-input v-model="event_.client"
									  prefix-icon="now-ui-icons education_atom" placeholder="Client"/>
						</span>

						<span class="col-6 col-md mt-3 mx-auto text-center">
							<h6 class="mb-0"><i class="fas fa-globe-asia"/> Venue</h6>
							<el-input v-model="event_.venue"
									  prefix-icon="now-ui-icons education_atom" placeholder="Venue"/>
						</span>
					</span>
					</div>

					<div class="row my-2 mx-auto mt-5">
						<h6 class="aml-text-teal-3 col-12 col-md-2 my-auto text-md-right text-left"><i class="fas fa-hourglass"/> Dates</h6>
						<div class="col-12 col-md-8 mt-3 my-auto">
							<el-date-picker
								v-model="event_.dates"
								:class="eventDatesError ? 'border-danger' : ''"
								type="daterange"
								align="right"
								unlink-panels
								range-separator="-"
								start-placeholder="Start date"
								end-placeholder="End date"
								@blur="eventDatesError = event_.dates && event_.dates.length !== 2"
							/>
							<span class="el-form-item__error" v-if="eventDatesError">Dates Are Required</span>
						</div>
					</div>

					<!--<el-divider class="my-5"><i class="fas fa-info fa-2x"/></el-divider>-->

					<div class="row justify-content-center mt-5">
						<div class="col-12">
							<div class="row">
								<div class="col">
									<icon-checkbox v-model="event_.useNfc" icon="icon-nfc-icon" title="NFC Cashless"/>
								</div>
								<div class="col" v-if="event_.useNfc">
									<icon-checkbox v-model="event_.onlineTopup" icon="fas fa-piggy-bank" title="Online Topup"/>
								</div>
								<div class="col" v-if="event_.useNfc">
									<icon-checkbox v-model="event_.usePin" icon="fas fa-fingerprint" title="Use Pin"/>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<icon-checkbox v-model="event_.useAccessx" icon="icon-feed" title="use AccessX"/>
								</div>
								<div class="col">
									<icon-checkbox v-model="event_.gstSetting" icon="fas fa-percentage" :title="'use ' + (event_.country === 'india' ? 'GST' : 'TAX') "/>
								</div>
								<div class="col">
									<icon-checkbox v-model="event_.makerChecker" icon="fas fa-user-shield" title="Maker Checker"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="row justify-content-center mt-5 col-11 col-md-11 mx-auto">
				<el-divider class=""><small><i class="fas fa-university text-secondary"/> DASH SETTINGS</small></el-divider>

				<div class="row col-12">
					<div class="mt-3 mx-auto">
						<h6 class="aml-text-teal-3 aml-font-10">PASSWORD</h6>
						<el-input placeholder="Dashboard Password" prefix-icon="fas fa-key" v-model="event_.password"/>
					</div>
				</div>

				<div class="row justify-content-center mt-5">
					<div class="col-12">
						<div class="row">
							<div class="col" v-if="event_.useNfc">
								<icon-checkbox v-model="event_.dashSettings.addActivationTopup" icon="fas fa-plus-square" title="Activation Topup"/>
								<el-tooltip content="Dashboard Summary Topup will show/hide activation amounts separately" placement="bottom"> <i class="fas fa-info-circle cursor-pointer"/> </el-tooltip>
							</div>
							<div class="col" v-if="event_.useNfc">
								<icon-checkbox v-model="event_.dashSettings.addCouponTopup" icon="fas fa-credit-card" title="Coupon Topup"/>
								<el-tooltip content="Dashboard Summary Topup will show/hide Coupon Topup amounts separately" placement="bottom"> <i class="fas fa-info-circle cursor-pointer"/> </el-tooltip>
							</div>
							<div class="col" v-if="event_.useNfc">
								<icon-checkbox v-model="event_.dashSettings.addCompTopup" icon="fas fa-gift" title="Complimentary Topup"/>
								<el-tooltip content="Dashboard Summary Topup will show/hide Complimentary topup amounts separately" placement="bottom"> <i class="fas fa-info-circle cursor-pointer"/> </el-tooltip>
							</div>
							<div class="col">
								<icon-checkbox v-model="event_.dashSettings.autoDayClose" icon="fas fa-power-off" title="Auto Reset Dashboard"/>
								<el-tooltip content="Dashboard Summary will reset after 00:00 daily if active" placement="bottom"> <i class="fas fa-info-circle cursor-pointer"/> </el-tooltip>
							</div>
						</div>
					</div>
					<div class="col-12 row mt-4">
						<div class="col-3 text-right align-middle align-content-around">
							<el-switch id="vendor_kot" v-model="event_.dashSettings.useLivelinkaccess" class="ml-3"
									   active-color="#13ce66" inactive-color="#ccc"
									   :active-value="1" :inactive-value="0" />
						</div>
						<div class="col-6 ">
							<h6 class="aml-text-teal-3 aml-font-12">Enter (+91) Mobile Numbers for Access to Event Live Link</h6>
							<el-input maxlength="100" :disabled="event_.dashSettings.useLivelinkaccess === 0" placeholder="Live Link Access" prefix-icon="fas fa-users" v-model="event_.dashSettings.livelinkAccess"/>
							<h5 class="aml-text-teal-3 aml-font-11 mt-2">Mobile Numbers should be separated by commas (,) in CSV format</h5>
						</div>
					</div>
				</div>
			</div>

		</el-form>
	</Form>
</template>
<script>
import {
	ElUpload, ElSelect, ElOption, ElTooltip, ElSwitch,
	ElDatePicker, ElDivider, ElInput, ElForm, ElFormItem,
} from "element-plus";
import { VeeInput, IconCheckbox } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
	props: [ 'user', 'event', 'accmgrs', ],
	emits: [ 'on-validated', 'on-event-sunburn', ],
	components: {
		[ElUpload.name]: ElUpload,
		ElDatePicker, ElDivider, ElInput, ElForm, ElFormItem,
		Form, ElSwitch,
		VeeInput, IconCheckbox,
		ElSelect, ElOption, ElTooltip,
	},
	data() {
		const schema = Yup.object().shape({
			// name: Yup.string().required().label("Name"),
			// email: Yup.string().email().required().label("The Email"),
			// lastName: Yup.string().required().label("The Last Name"),
		});

		return {
			event_: {
				type: 'event', country: 'india', tz: 'GMT+05:30', locationCity: 'Pune',
				venue: '', organizer: '', client: '', currency: 'inr',
				useNfc: true, onlineTopup: true, usePin: false,
				useAccessx: false, gstSetting: false, makerChecker: false,
				controller: 'atomx', password: '',
				dates: [], status: 'active',
				dashSettings: {
					id: 0,
					addActivationTopup: true,
					addCompTopup: true,
					addCouponTopup: true,
					autoDayClose: false,
					showEdit: true,
					useLivelinkaccess: 0,
					livelinkAccess: "",
				},
			},
			imageUrl: "img/default-avatar.png",
			defaultCities: [ 'goa', 'bali', 'pune', 'mumbai', 'delhi', 'bengaluru', 'chennai', 'jaipur', 'jakarta', 'hyderabad' ],
			defaultCountries: [ 'india', 'indonesia', 'singapore', 'malaysia', 'dubai', 'srilanka' ],
			defaultTZ: [ ],
			defaultCurrencies: [ 'inr', 'idr', 'sgd', 'myr', 'aed', 'lkr', ],
			defaultTypes: [ 'event', 'merchant', 'access', 'clg-access', ],
			defaultEventDates: [  ],
			eventDatesError: false,
			schema,
		};
	},
	mounted() {
		// console.log(this.user);
		if(this.user) {
			this.event_.controller = this.user.controller;
		}

		this.defaultTZ = [];
		for( let i = 0; i < 24; i++ ) {
			this.defaultTZ.push('GMT-' + ("00" + i).substr(-2) + ':' + '00');
			this.defaultTZ.push('GMT-' + ("00" + i).substr(-2) + ':' + '30');
		}
		for( let i = 0; i < 24; i++ ) {
			this.defaultTZ.push('GMT+' + ("00" + i).substr(-2) + ':' + '00');
			this.defaultTZ.push('GMT+' + ("00" + i).substr(-2) + ':' + '30');
		}

	},
	methods: {
		handlePreview(file) {
			this.model.imageUrl = URL.createObjectURL(file.raw);
		},
		// onEventNameChange(eventName) {
		// 	this.$emit("on-event-sunburn", false);
		// 	if(eventName.toLowerCase().search('sunburn') > -1 )
		// 		if( confirm("Sunburn Event detected in name. Update Card Settings accordingly?") )
		// 			this.$emit("on-event-sunburn", true);
		// },
		onEventNameBlur(event) {
			// console.log('blur', event);
			// this.$emit("on-event-sunburn", false);
			// if(this.event_.name && this.event_.name.toLowerCase().search('sunburn') > -1 )
			// 	if( confirm("Sunburn Event detected in name. Update Card Settings accordingly??") )
			// 		this.$emit("on-event-sunburn", true);
		},
		validate() {
			// debugger;
			// return this.$refs.form.validate().then((res) => {
			// 	this.$emit("on-validated", res);
			// 	return res;
			// });

			let validate = true;
			if( this.event_.name && this.event_.name.length === 0 ) validate = false;
			if( this.event_.dates && this.event_.dates.length < 2 ) {
				// debugger;
				validate = false;
				this.eventDatesError = true;
			} else this.eventDatesError = false;
			debugger

			return this.$refs.form.validate().then((res) => {
				res.event = this.event_;
				this.$emit("on-validated", res);
				return res;
			});

			// return validate;
		},
	},
	watch:{
		user (to, from){
			// console.log(this.user, to);
			if(to) this.event_.controller = to.controller;
			// if(this.user.controller === 'bms') this.event_.onlineTopup = false;
		},
	}
};
</script>
<style>
.card-wizard .choice.active:hover .icon, .card-wizard .choice:hover .icon {
	border: 1px solid #E3E3E3 !important;
	color: #888 !important;
}
.card-wizard .choice.active .icon {
	border: 1px solid #429e81 !important;
	color: #429e81 !important;
}
.card-wizard .choice .icon {
	border: 1px solid #e3596d !important;
	color: #e3596d !important;
}
.el-form-item__error { left: 160px !important; }
.el-input__prefix { position: absolute !important; }
.el-input__prefix .el-input__icon { left: 10px !important; position: relative !important; }
</style>
