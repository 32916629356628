<template>
	<div style="min-height: 2000px;">
		<div class="row " >
			<div class="col-12">
				<Items v-if="components.items" :event="event" :stall="stall"/>
			</div>

		</div>
	</div>
</template>
<script>
	import {
		Card,
		Table as NTable,
		AnimatedNumber,
		Progress as NProgress,
	} from "@/components";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import Items from './items/items';
	import { ElDivider, ElBadge, ElMessage } from "element-plus";


	export default {
		components: {
			Card,
			NTable,
			AnimatedNumber,
			NProgress,
			ElDivider, ElBadge,
			Items
		},
		inject: ['$baseURL', '$numFrm', ],
		emits: [ 'event-fetched', 'info-fetched', 'loading-complete' ],
		data() {
			return {
				defaultInfo: {
					event: {  },
					stall: {  },
					components: { items: false,  },
					isLoading: { items: true, },
				},
				loginUser: {},
				event: { name: '', country: 'india', type: '' },
				stall: {  },
				components: { items: false,  },
				isLoading: { items: true, },
			};
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			setComponentsDisplay () {
				this.setDefault('components');
				switch(this.event.type) {
					case 'fnb':
					case 'event':
						this.components.items = true;
						break;
				}
			},
			setDefault(key = 'all') {
				if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
				else {
					for (const [key, value] of Object.entries(this.defaultInfo)) {
						this[key] = JSON.parse(JSON.stringify(value));
					}
				}
			},
			loadDetails() {
				let u = this.loginUser;
				let code = 0;
				let url = '';
				switch (u.loginType) {
					case 'event':
						url = this.$baseURL + '/Event/Details/';
						code = u.id;
						break;
					case 'admin':
						url = this.$baseURL + '/Event/Details/';
						code = this.$route.params.code;
						break;
					case 'vendor':
						code = u.id;
						url = this.$baseURL + '/Vendors/Details/';
						break;
					default:
						return ;
						break;
				}

				axios.get(
					url,
					{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						if(response.data.success) {
							if (this.loginUser.loginType !== 'vendor') {
								this.setDefault('event');
								this.event = response.data.event;
								// console.log('dashSettings', this.dashSettings);
								this.isLoading.event = false;
								this.$emit('event-fetched', this.event);
							} else {
								this.vendor = response.data.vendor;
								this.event = response.data.event;
								this.isLoading.event = false;
								this.$emit('info-fetched', this.vendor);
								this.$emit('event-fetched', response.data.event);
								// this.$emit('loading-complete');
							}
							this.$emit('loading-complete');
							this.setComponentsDisplay();
						} else {
							ElMessage({
								showClose: true,
								dangerouslyUseHTMLString: true,
								message: "<b>Something went wrong</b><br>" + response.data.message,
								type: 'danger',
								grouping: true,
								duration: 5000,
							});
						}
					})
					.catch( (error) => {
						ElMessage({
							showClose: true,
							dangerouslyUseHTMLString: true,
							message: "<b>Something went wrong</b><br>Please try again.",
							type: 'danger',
							grouping: true,
							duration: 5000,
						});
						console.log("err:", error);
						this.$emit('loading-complete');
					});

				axios.get(
					this.$baseURL + '/Stalls/Details/',
					{ params: { code: this.$route.params.stallCode }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
					.then(response => {
						// debugger;
						this.setDefault('stall');
						// console.log(this.event);
						this.stall = response.data.stall;
						console.log(this.stall);
						// this.isLoading.stall = false;
						// this.dataLoad();
						// this.$emit('event-fetched', this.event);
						// this.setComponentsDisplay();
					})
					.catch( (error) => {
						this.runToast('', 'top-right', 'danger', "<b>Something went wrong. Unable to load Stall Details</b><br>Please try again.", "fas fa-bomb fa-4x");
						console.log("err:", error);
					});

			},
		},
		mounted() {
			let u = JSON.parse(localStorage.getItem('user'));
			this.loginUser = u;
			if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
			// setTimeout(this.loadDetails, 500);
			this.loadDetails();
			window.scrollTo(0,0);
		},
		watch: {
			$route (to, from){
				console.log('route change detected');
				// this.setLoading();
				// clearInterval(this.interval_fn);
				this.setDefault();
				this.loadDetails();
				// this.event = {};
			},
		}
	};
</script>
<style>
	.aml-card-header {
		border-radius: .5875rem !important;
		min-height: 100px;
		/*background-color: #2198af !important;*/
	}
	.aml-event-card {
		/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
		box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
	}
</style>
