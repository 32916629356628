import Chart from "chart.js";

export const headerChart = {
	createChart(chartId, v = [ ], ds = [],) {
		let chartColor = "#FFFFFF";
		const ctx = document.getElementById(chartId).getContext("2d");
		let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
		gradientStroke.addColorStop(0, "#80b6f4");
		gradientStroke.addColorStop(1, chartColor);

		let gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
		gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
		gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.8)");

		let colourStop = { 4: '0.3', 5: '0.6', 6: '0.9' };
		let bgColours = {
			sale: "#fff",
			topup: gradientFill,
			return: "#04404a",
			activations: "#fa5238",
		};

		let datasets = [];

		for (const [key, value] of Object.entries(ds)) {
			datasets.push({
				type: key !== 'activations' ? "bar" : "line",
				label: key.toUpperCase(),
				data: value,
				borderColor: chartColor,
				backgroundColor: bgColours[key],
				pointBorderColor: chartColor,
				pointBackgroundColor: "#1e3d60",
				pointHoverBackgroundColor: "#1e3d60",
				pointHoverBorderColor: chartColor,
				pointBorderWidth: 1,
				pointHoverRadius: 7,
				pointHoverBorderWidth: 2,
				pointRadius: 5,
				fill: true,
				borderWidth: 2,
			});
		}

		// console.log(datasets);

		const myChart = new Chart(ctx, {
			// type: "line",
			data: {
				labels: v,
				datasets,
			},
			options: {
				responsive: true,
				layout: {
					padding: {
						left: 20,
						right: 20,
						top: 0,
						bottom: 0,
					},
				},
				maintainAspectRatio: false,
				tooltips: {
					backgroundColor: "#fff",
					titleFontColor: "#333",
					bodyFontColor: "#666",
					bodySpacing: 4,
					xPadding: 12,
				},
				legend: {
					position: "top",
					fillStyle: "#FFF",
					display: false,
				},
				scales: {
					yAxes: [
						{
							ticks: {
								fontColor: "rgba(255,255,255,0.4)",
								fontStyle: "bold",
								beginAtZero: true,
								maxTicksLimit: 5,
								padding: 10,
							},
							gridLines: {
								drawTicks: true,
								drawBorder: false,
								display: true,
								color: "rgba(255,255,255,0.1)",
								zeroLineColor: "transparent",
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								zeroLineColor: "transparent",
								display: false,
							},
							ticks: {
								padding: 10,
								fontColor: "rgba(255,255,255,0.4)",
								fontStyle: "bold",
							},
						},
					],
				},
			},
		});
	},
};

export default headerChart;
