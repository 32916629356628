<template>
	<div class="card card-stats card-raised">
		<div class="card-header" :class="showBlockField ? 'bg-light' : ''">
			<h5 class="card-category">BLOCKED CARDS</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute; z-index: 1500">
				<span class="col-3 text-right text-info cursor-pointer" v-if="!showBlockField" @click="showBlockField = true;"><i class="fa fa-plus"/> Block CARD</span>
				<span class="badge badge-primary" v-if="isLoading.cards">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
			</div>
			<div class="col-11 mx-auto my-3" v-if="showBlockField">
				<el-input class="col-9" placeholder="Card ID" size="mini" prefix-icon="el-icon-search" v-model="cardId"/>
				<el-button size="small" type="warning" :disabled="cardId === null || cardId === '' || cardId === 0" @click="blockCard()" :loading="isLoading.blockCard">
					<template #loading>
						<div class="custom-loading">
							<svg class="circular" viewBox="-10, -10, 50, 50">
								<path
									class="path"
									d="
									M 30 15
									L 28 17
									M 25.61 25.61
									A 15 15, 0, 0, 1, 15 30
									A 15 15, 0, 1, 1, 27.99 7.5
									L 15 15
								  "
									style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"
								/>
							</svg>
						</div>
					</template>
					Block
				</el-button>
				<i class="fa fa-close ml-3 text-secondary cursor-pointer" @click="showBlockField = false;"/>
				<el-divider/>
			</div>
		</div>
		<div class="card-body">
			<el-input placeholder="Search Card" size="mini" prefix-icon="el-icon-search" v-model="search"/>
			<el-table class="w-100 aml-font-12"
					  :data="cards.filter(data => !search || data.cardId?.toString().includes(search.toLowerCase()))"
					  :height="cards.length > 3 ? '300' : '200'"
			>
				<el-table-column label="#" type="index" align="center"/>
				<el-table-column sortable label="CARD ID" align="center" prop="cardId"/>
				<el-table-column sortable label="STATUS" align="center" prop="status"/>
				<el-table-column class="cursor-pointer" label="UNBLOCK" align="center" width="125">
					<template #default="scope">
						<span v-if="!isLoading.unblockCard" class="d-block cursor-pointer" @click="unblockCard(scope.row)">
							<i class="fas fa-lock-open" v-if="scope.row.status==='active'"/>
						</span>
						<span v-else><i class="fas fa-spin fa-spinner"/></span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
import {
	Card,
	Table as NTable,
	AnimatedNumber,
	Progress as NProgress,
} from "@/components";
import axios from 'axios';
import { useToast } from "vue-toastification";
import { Notification } from "@/components";
import {
	ElDivider, ElBadge, ElDialog,
	ElButton, ElTable, ElTableColumn,
} from 'element-plus';


export default {
	components: {
		Card,
		NTable,
		AnimatedNumber,
		NProgress,
		ElDivider, ElBadge, ElDialog,
		ElButton, ElTable, ElTableColumn,
	},
	inject: ['$baseURL', '$numFrm2', '$formatDateTime', ],
	props: ['event'],
	data() {
		return {
			isLoading: { cards: true, event: true, blockCard: false, unblockCard: false, },
			showBlockField: false,
			cardId: null,
			search: null,
			cards: [],
		};
	},
	methods: {
		runToast(text, pos, type, ownText, ownIcon) {
			// const text =
			// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
			const icon = "now-ui-icons ui-1_bell-53";
			const content = {
				component: Notification,

				props: {
					ownText: ownText,
					ownIcon: ownIcon,
					icon: icon,
					text: text,
					type: type,
				},
			};
			const toast = useToast();
			toast(content, {
				hideProgressBar: true,
				icon: false,
				closeButton: false,
				position: pos,
			});
		},
		setComponentsDisplay () {
			this.setDefault('components');

			switch(this.event.type) {
				case 'fnb':
				case 'event':
					this.components.vendors = true;
					this.components.stalls = true;
					this.components.eventDetails = true;
					break;
				case 'clg-access':
					break;
				case 'armourx':
					this.components.armourxLinks = true;
					break;
			}
		},
		setDefault(key = 'all') {
			if(key !== 'all')  this[key] = JSON.parse(JSON.stringify(this.defaultInfo[key]));
			else {
				for (const [key, value] of Object.entries(this.defaultInfo)) {
					this[key] = JSON.parse(JSON.stringify(value));
				}
			}
		},
		loadDetails() {
			let u = this.loginUser;
			let code = 0;
			let url = '/Event/Blocked/';
			switch (u.loginType) {
				case 'event':
				case 'operator':
					code = u.id;
					break;
				case 'admin':
					code = this.$route.params.code;
					break;
				default:
					return ;
					break;
			}
			this.isLoading.cards = true;
			this.cards = [];
			axios.get(
				this.$baseURL + url,
				{ params: { code }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) this.cards = response.data.cards;
					else	this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message , "fas fa-bomb fa-4x");
					this.isLoading.cards = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.cards = false;
				});
		},
		blockCard() {
			if(!this.cardId)	return;
			let u = this.loginUser;
			let code = 0;
			let url = '/Event/Block/';
			switch (u.loginType) {
				case 'event':
				case 'operator':
					code = u.id;
					break;
				case 'admin':
					code = this.$route.params.code;
					break;
				default:
					return ;
					break;
			}
			this.isLoading.blockCard = true;
			axios.get(
				this.$baseURL + url,
				{ params: { code, cardId: this.cardId }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) {
						this.cards.push(response.data.card);
						this.cardId = null;
					}
					else	this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message , "fas fa-bomb fa-4x");
					this.isLoading.blockCard = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.blockCard = false;
				});
		},
		unblockCard(cardLog) {
			if( !cardLog )	return;
			if(!confirm("Do you want to unblock card: " + cardLog.cardId)) return;
			let u = this.loginUser;
			let code = 0;
			let url = '/Event/Unblock/';
			switch (u.loginType) {
				case 'event':
				case 'operator':
					code = u.id;
					break;
				case 'admin':
					code = this.$route.params.code;
					break;
				default:
					return ;
					break;
			}
			this.isLoading.unblockCard = true;
			axios.get(
				this.$baseURL + url,
				{ params: { code, cardId: cardLog.cardId, id: cardLog.id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
			)
				.then(response => {
					if(response.data.success) cardLog.status = 'unblocked';
					else	this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>" + response.data.message , "fas fa-bomb fa-4x");
					this.isLoading.unblockCard = false;
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.isLoading.unblockCard = false;
				});
		},
	},
	mounted() {
		let u = JSON.parse(localStorage.getItem('user'));
		this.loginUser = u;
		if(u && Object.keys(u).length === 0 && u.constructor === Object)	return this.$router.push('/login');
		// setTimeout(this.loadDetails, 500);
		if(this.event.id) this.loadDetails();
	},
	watch: {
		event (to, from){
			// console.log('$event change detected');
			this.loadDetails();
		},
	}
};
</script>
<style>
.aml-card-header {
	border-radius: .5875rem !important;
	min-height: 100px;
	/*background-color: #2198af !important;*/
}
.aml-event-card {
	/*box-shadow: 0 1px 15px 1px rgb(39 39 39 / 35%) !important;*/
	box-shadow: 0 1px 15px 1px rgb(256 256 256) !important;
}
</style>
