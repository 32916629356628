<template>
	<div class="card card-stats card-raised" ref="resultsSection">
		<div class="card-header">
			<h5 class="card-category">List ({{ transactions.length }})</h5>
			<div class="px-0" style="top: 5px;right: 5px; position: absolute;">
				<span class="badge badge-primary" v-if="loading">
					<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
				</span>
				<span v-else>
					<span v-for="f in filter" class="badge badge-info">{{ f.label }}: {{ f.value }}</span>
					<el-button @click="downloadCSVData"><i class="fas fa-download"/> Download Dump</el-button>
				</span>
			</div>
		</div>
		<div class="card-body">
			<div class="row col-12 mx-auto" v-if="!loading && transactions.length > 0">
				<el-input placeholder="Search" size="mini" prefix-icon="el-icon-search" v-model="search"/>
				<el-table class="w-100 aml-font-11 aml-devices-table"
						  :data="transactions.slice().reverse().filter(
	          	data => !search ||
	          		data.txn_id.toString().toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_type.toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_status.toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_invoice.toString().toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_receipt.toString().toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_mode.toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_mode_info.toLowerCase().includes(search.toLowerCase()) ||
	          		data.stall_name.toLowerCase().includes(search.toLowerCase()) ||
	          		data.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_card_uid.toLowerCase().includes(search.toLowerCase()) ||
	          		data.txn_card_id.toString().includes(search.toLowerCase()) ||
	          		data.txn_device_print_id.toString().includes(search.toLowerCase()) ||
	          		data.txn_amount.toString().includes(search.toLowerCase()) ||
	          		data.txn_counter.toString().includes(search.toLowerCase())
				)"
				  :height="transactions.length <= 10 ? '1000' : transactions.length <= 20 ? '2000' : '3000' "
				  row-key="txn_id"
				  :stripe="true" :border="true"
				  @expand-change="expandDetails"
				>
<!--					<el-table-column type="selection" label="VOID" width="55" />-->
<!--					<el-table-column type="expand" label="DETAILS" width="55" />-->
					<el-table-column type="expand">
						<template #default="scope" class="p-0">
							<span class="bg-light p-0 m-0 d-block">
								<span v-if="scope.row.details" class="row col-11 mx-auto">

									<div class="my-2 row col-6">
										<el-divider><small><i class="fas fa-university text-secondary"/> Transaction Info</small></el-divider>
										<div class=" col-8 mx-auto row">
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">CARD ID: </small> <h5 class="d-inline">{{ scope.row.txn_card_id }}</h5></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">CARD UID: </small> <h5 class="d-inline">{{ scope.row.txn_card_uid }}</h5></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">TXN COUNTER: </small> <h5 class="d-inline">{{ scope.row.txn_counter }}</h5></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">TXN ID: </small> <h5 class="d-inline">{{ scope.row.txn_id }}</h5></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">RECEIPT: </small> <a @click="copyLink('http://fnb.atomx.in/rcpt?r=' + scope.row.txn_id)" :href="'http://fnb.atomx.in/rcpt?r=' + scope.row.txn_id" target="_blank" class="text-decoration-none"><h5 class="d-inline">{{ scope.row.txn_receipt }}</h5> <i class="fa fa-external-link-alt"/> </a></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">BALANCE BEFORE: </small> <h5 class="d-inline">{{ scope.row.txn_bal_before }}</h5></div>
											<div class="col-12 mx-auto row border-bottom"><small class="col-4">BALANCE AFTER: </small> <h5 class="d-inline">{{ scope.row.txn_bal_after }}</h5></div>
										</div>
									</div>

									<div class="col-md-9 col-12 border-right">
										<div v-if="scope.row.details[0].item_name" class="my-2">
											<!--80444210930583-->
											<el-divider class=""><small><i class="fas fa-utensils text-secondary"></i> ITEMS</small></el-divider>
											<el-table class="w-100 aml-font-11 aml-devices-table" :data="scope.row.details">
												<el-table-column label="ITEM" prop="item_name"/>
												<el-table-column label="QTY" prop="item_qty"/>
												<el-table-column label="PRICE" prop="item_price"/>
												<el-table-column label="AMOUNT" prop="item_amount"/>
												<el-table-column v-if="scope.row.gst_amount > 0" label="GST%" prop="item_gst_slab"/>
												<el-table-column v-if="scope.row.gst_amount > 0" label="GST AMOUNT" prop="item_gst_amount"/>
												<el-table-column v-if="scope.row.cess_amount > 0" label="VAT AMOUNT" prop="item_cess_amount"/>
												<el-table-column v-if="scope.row.discount_amount > 0" label="DISCOUNT AMOUNT" prop="item_discount_amount"/>
											</el-table>
										</div>

										<div v-if="scope.row.details[0].bt_id" class="my-2">
											<el-divider><small><i class="fas fa-university text-secondary"></i> Bank Transaction Info</small></el-divider>
											<div v-if="scope.row.details[0].bt_status === 'completed'">
												<span class="row">
													<span class="col-3 text-right my-auto">AMOUNT: </span>
													<h5 class="col-9 text-left mb-0">{{ $numFrm(scope.row.details[0].bt_amount) }}</h5>
												</span>
												<span class="row">
													<span class="col-3 text-right">MERCHANT: </span>
													<h6 class="col-9 text-left">{{ scope.row.details[0].bt_merchant }}</h6>
												</span>
												<span class="row">
													<span class="col-3 text-right">CUSTOMER: </span>
													<h6 class="col-9 text-left">{{ scope.row.details[0].bt_holder }}</h6>
												</span>
												<span class="row">
													<span class="col-3 text-right">MOBILE: </span>
													<h6 class="col-9 text-left">{{ scope.row.details[0].bt_mobile }}</h6>
												</span>
												<span class="row">
													<span class="row col-6">
														<span class="col-6 text-right p-0">BANK CARD: </span>
														<h6 class="col-6 text-left">{{ scope.row.details[0].bt_card }}</h6>
													</span>
													<span class="row col-6">
														<span class="col-4 text-right p-0">INVOICE: </span>
														<h6 class="col-8 text-left">{{ scope.row.details[0].bt_invoice }}</h6>
													</span>
												</span>
												<span class="row">
													<span class="col-3 text-right">MESSAGE: </span>
													<h6 class="col-9 text-left">{{ scope.row.details[0].bt_reason }}</h6>
												</span>
												<span class="row">
													<span class="row col-6">
														<span class="col-6 text-right p-0">AUTH CODE: </span>
														<h6 class="col-6 text-left">{{ scope.row.details[0].bt_authcode }}</h6>
													</span>
													<span class="row col-6">
														<span class="col-4 text-right p-0">RR NO.: </span>
														<h6 class="col-8 text-left">{{ scope.row.details[0].bt_rrno }}</h6>
													</span>
												</span>
												<span class="row">
													<span class="row col-6">
														<span class="col-6 text-right p-0">TID: </span>
														<h6 class="col-6 text-left">{{ scope.row.details[0].bt_tid }}</h6>
													</span>
													<span class="row col-6">
														<span class="col-4 text-right p-0">MID: </span>
														<h6 class="col-8 text-left pr-0">{{ scope.row.details[0].bt_mid }}</h6>
													</span>
												</span>
												<span class="row">
													<span class="row col-6">
														<span class="col-6 text-right p-0">TVR: </span>
														<h6 class="col-6 text-left pr-0">{{ scope.row.details[0].bt_tvr }}</h6>
													</span>
													<span class="row col-6">
														<span class="col-4 text-right p-0">TSI: </span>
														<h6 class="col-8 text-left">{{ scope.row.details[0].bt_tsi }}</h6>
													</span>
												</span>
												<span class="row">
													<span class="row col-6">
														<span class="col-6 text-right p-0">STAN ID: </span>
														<h6 class="col-6 text-left">{{ scope.row.details[0].bt_stanid }}</h6>
													</span>
													<span class="row col-6">
														<span class="col-4 text-right p-0 my-auto">STATUS: </span>
														<span class="col-8 text-left">
															<span class="badge" :class="scope.row.details[0].bt_status === 'completed' ? 'badge-success': 'badge-default'">{{ scope.row.details[0].bt_status }}</span>
														</span>
													</span>
												</span>
											</div>
											<div v-else>
												<span class="row">
													<span class="col-4 text-right">REASON: </span>
													<h6 class="col-8 text-left">{{ scope.row.details[0].bt_reason }}</h6>
												</span>
												<span class="row">
													<span class="col-4 text-right my-auto">STATUS: </span>
													<span class="col-8 text-left">
														<span class="badge" :class="scope.row.details[0].bt_status === 'completed' ? 'badge-success': 'badge-default'">{{ scope.row.details[0].bt_status }}</span>
													</span>
												</span>
											</div>
										</div>
									</div>
									<div class="col-md-3 col-12 my-auto text-center">
										<div v-if="scope.row.txn_status === 'completed' || scope.row.txn_status === 'void'">
											<h6>Update Transaction Status to:</h6>
											<el-button @click="updateStatus(scope.row)" class="text-white" :loading="statusLoading"
												   :class="scope.row.txn_status === 'completed' ? 'bg-danger' : 'bg-success'">
												<strong>{{ scope.row.txn_status === 'completed' ? 'VOID' : 'COMPLETE' }}</strong>
											</el-button>
										</div>
									</div>
								</span>
								<span v-else class="row col-10 mx-auto">
									<el-skeleton :rows="5" animated />
								</span>
							</span>
						</template>
					</el-table-column>
<!--					<el-table-column label="#" type="index" width="30"/>-->
					<el-table-column sortable label="TYPE" prop="txn_type" align="center">
						<template #default="scope">
							<h5
							   :class="scope.row.txn_type === 'topup' ? 'aml-text-teal' : scope.row.txn_type === 'sale' ? 'aml-text-yellow' : 'aml-text-gray'"
							>
								<b>{{ scope.row.txn_type ? scope.row.txn_type.split('_').join(' ').toUpperCase() : '' }}</b>
							</h5>
						</template>
					</el-table-column>
					<el-table-column sortable label="TXNID | REF" prop="txn_id" align="center">
						<template #default="scope">
							<span>{{ scope.row.txn_id }}</span>
							<el-divider class="my-1" direction="horizontal"/>
							<a @click="copyLink('https://fnbxapi.atomx.in/receipts?r=' + scope.row.txn_id)" :href="'https://fnbxapi.atomx.in/receipts?r=' + scope.row.txn_id" target="_blank" class="text-decoration-none">{{ scope.row.txn_receipt }} </a>
						</template>
					</el-table-column>
<!--					<el-table-column sortable label="RECEIPT" prop="txn_receipt" align="center">-->
<!--						<template #default="scope">-->
<!--							<a @click="copyLink('https://fnbxapi.atomx.in/receipts?r=' + scope.row.txn_id)" :href="'https://fnbxapi.atomx.in/receipts?r=' + scope.row.txn_id" target="_blank" class="text-decoration-none">{{ scope.row.txn_receipt }} <i class="fa fa-external-link-alt"/> </a>-->
<!--						</template>-->
<!--					</el-table-column>-->
<!--					<el-table-column sortable :resizable="true" label="VENDOR" prop="vendor_name"/>-->
					<el-table-column sortable label="VENDOR | STALL" prop="stall_name">
						<template #default="scope">
							<span>{{ scope.row.vendor_name }}</span>
							<el-divider class="my-1" direction="horizontal"/>
							<span>{{ scope.row.stall_name }}</span>
						</template>
					</el-table-column>
					<el-table-column sortable label="DEVICE" prop="txn_device_print_id" align="center">
						<template #default="scope">
							<span>{{ scope.row.txn_device_print_id }}</span> | {{ scope.row.txn_app_version }}
						</template>
					</el-table-column>
					<el-table-column sortable label="CARD ID" prop="txn_card_id" align="center">
						<template #default="scope">
							<h5 class="m-0">{{ scope.row.txn_card_id }}</h5>
							<el-divider class="my-1" direction="horizontal"/>
							<span class="aml-text-underline">{{ scope.row.txn_card_uid }}</span>
						</template>
					</el-table-column>
					<el-table-column sortable label="TXN COUNTER" prop="txn_counter" align="center">
						<template #default="scope">
							<span class="m-0">{{ scope.row.txn_counter }}</span>
							<el-divider class="my-1" direction="horizontal"/>
							<span class="aml-text-underline">{{ show_mobile(scope.row.txn_mobile) }}</span>
						</template>
					</el-table-column>
					<!--<el-table-column sortable label="DEVICE" prop="devices_main_type" align="center"/>-->
					<el-table-column sortable label="MODE" align="center" prop="txn_mode">
						<template #default="scope">
							<small>{{ scope.row.txn_mode }}</small> | {{ scope.row.txn_invoice }} <span v-if="scope.row.txn_mode_info || scope.row.txn_mode_info !== ''"> <br> {{ scope.row.txn_mode_info }}</span>
						</template>
					</el-table-column>
					<el-table-column sortable label="AMOUNT" align="center" prop="txn_amount">
						<template #default="scope">
							{{ $numFrm(scope.row.txn_amount, 'float', event.country) }}
						</template>
					</el-table-column>
<!--					<el-table-column sortable label="STATUS" align="center" prop="txn_status">-->
<!--						<template #default="scope">-->
<!--							<span class="badge "-->
<!--								  :class="-->
<!--									  scope.row.txn_status==='completed' ? 'badge-success' :-->
<!--									  scope.row.txn_status==='void' ? 'badge-danger' :-->
<!--									  scope.row.txn_status==='reversed' ? 'badge-warning' :-->
<!--									  'badge-default'-->
<!--									"-->
<!--							>-->
<!--								{{ scope.row.txn_status }}-->
<!--							</span>-->
<!--						</template>-->
<!--					</el-table-column>-->
					<el-table-column sortable label="TIME" align="center">
						<template #default="scope">
							<span class="text-uppercase"
								  :class="
									  scope.row.txn_status.toLowerCase()==='completed' ? 'text-success' :
									  scope.row.txn_status.toLowerCase()==='void' ? 'text-danger' :
									  scope.row.txn_status.toLowerCase()==='reversed' ? 'text-warning' :
									  'aml-text-gray'
									"
							>
								<b>{{ scope.row.txn_status }}</b>
							</span>
							<el-divider class="my-1" direction="horizontal"/>
							{{ displaydt(scope.row.txn_at) }}
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		ElTable, ElTableColumn, ElInput, ElButton,
		ElDialog, ElForm, ElFormItem, ElSelect, ElOption,
		ElSwitch, ElDivider, ElDatePicker, ElSkeleton, ElTag,
	} from "element-plus";
	import axios from 'axios';
	import { useToast } from "vue-toastification";
	import { Notification } from "@/components";
	import moment from 'moment';
	import { copyText } from 'vue3-clipboard';

	export default {
		components: {
			ElTable,
			ElTableColumn,
			ElInput, ElButton, ElDialog,
			ElForm, ElFormItem, ElSelect, ElOption,
			ElSwitch, ElDivider, ElDatePicker,
			ElSkeleton, ElTag,
			moment,
			copyText,
		},
		props: [ 'event', 'transactions', 'loading', 'filter', 'loginUser', ],
		inject: [ '$baseURL', '$numFrm', '$formatDateTime', ],
		emits: [  ],
		data() {
			return {
				search: '',
				reportLoading: false,
				statusLoading: false,
				defaultTypes: [ { value: 'redemption_analytics', label: 'CARD-ITEM WISE TRANSACTION DUMP' }, { value: 'all_txns', label: 'BASIC TRANSACTION DUMP' }, { value: 'balances', label: 'Remaining Balances' }, { value: 'partials', label: 'Partial Transactions' }, { value: 'summary', label: 'Summary' }, ],
				defaultReports: [ { value: 0, label: 'Event Report' }, ],
				defaultStatus: [ 'completed', 'void' ],
				shortcuts: [{
					text: 'Last week',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
						return [start, end]
					})(),
				}, {
					text: 'Last month',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
						return [start, end]
					})(),
				}, {
					text: 'Last 3 months',
					value: (() => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
						return [start, end]
					})(),
				}],
			}
		},
		mounted() {
		},
		methods: {
			runToast(text, pos, type, ownText, ownIcon) {
				// const text =
				// 	"Welcome to <b>Vue Now UI Dashboard Pro</b> - a beautiful premium dashboard for every web developer";
				const icon = "now-ui-icons ui-1_bell-53";
				const content = {
					component: Notification,

					props: {
						ownText: ownText,
						ownIcon: ownIcon,
						icon: icon,
						text: text,
						type: type,
					},
				};
				const toast = useToast();
				toast(content, {
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: pos,
				});
			},
			show_mobile(mobile) {
				return ( this.loginUser.loginType === 'admin' && this.loginUser.id === 1480 ) ? mobile : mobile.substring(0, 2) + "*****" + mobile.substr(mobile.length - 3)
			},
			displaydt(dt) { return moment(dt).isValid() ? moment(dt).utc().format('YYYY-MM-DD HH:mm:ss') : ''; },
			formatDT(txn_at){
				return moment(txn_at).subtract(new Date(txn_at).getTimezoneOffset(), 'minutes').add(1, 'hours').format('DD-MM-YY hh:mm:ss');
			},
			copyLink(url) {
				copyText(url, undefined, (error, event) => {
					if (error) {
						// alert('Can not copy')
						// console.log(error)
					} else {
						// alert('Copied')
						// console.log(event)
					}
				})
			},
			updateStatus(txn) {
				this.statusLoading = true;
				axios.post(
					this.$baseURL + '/Transactions/UpdateStatus',
					{ id: txn.txn_id, },
					{ headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }}
				)
				.then(response => {
					if(response.data.success) {
						txn.txn_status = response.data.status;
						this.statusLoading = false;
						// window.scrollTo(0, this.$refs.resultsSection.offsetTop);
					}
				})
				.catch( (error) => {
					this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
					console.log("err:", error);
					this.statusLoading = false;
				});
			},
			expandDetails(row, column, event) {
				if(!row.details) {
					// console.log('fetch details');
					// row.details = [{ a:1, }, { a: 2, },];
					axios.get(
						this.$baseURL + '/Transactions/details',
						{
							params: { txnId: row.txn_id }, headers: { Authorization: `Bearer ${localStorage.getItem('xToken')}` }
						}
					)
						.then(response => {
							// console.log(response);
							if(response.data.success) { row.details = response.data.details; }
						})
						.catch( (error) => {
							this.runToast('', 'top-right', 'danger', "<b>Something went wrong</b><br>Please try again.", "fas fa-bomb fa-4x");
							console.log("err:", error);
							// this.isLoading[dataType.toLowerCase()] = false;
						});
				}
				// else console.log('details exist');
				// return console.log(row, column, event);
				// this.$refs.txnResults.toggleRowExpansion(row, true);
				// resolve([treeNode]);
			},
			downloadCSVData(){
				// let csv = 'Item,Cost,MRP,Price,Happy Price,HSN,Barcode,Type(food/drink/ticket/other)\n';
				// csv += 'Item Name 1,0,0,0,0,,,food\n';
				// csv += 'Item Name 2,0,0,0,0,,,food\n';
				// this.csvdata.forEach((row) => {
				// 	csv += row.join(',');
				// 	csv += "\n";
				// });

				let csv = 'TxnID,Type,Receipt,Vendor,Stall,Device,Card,TxnCounter,Mode,Amount,Status,Timestamp\n';
				csv = [ 'TxnID', 'Type', 'Receipt', 'Vendor', 'Stall', 'Device', 'Card', 'Txn Counter', 'Mode', 'Amount', 'Status', 'Timestamp' ].join(',') + '\n';
				this.transactions.forEach( txn => {
					csv += [txn.txn_id, txn.txn_type, txn.txn_receipt, txn.vendor_name, txn.stall_name, txn.txn_device_print_id, txn.txn_card_id, txn.txn_counter, txn.txn_mode + ' | ' + txn.txn_invoice + ' | ' + txn.txn_mode_info, txn.txn_amount, txn.txn_status, txn.txn_at ].join(',') + '\n';
				});

				console.log(this.transactions, csv);
				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'FilterTransactions.csv';
				anchor.click();
			},
		},
		watch: {
			transactions(to, from) {
				if(to.length > 0)	{
					this.$nextTick(function(){
						this.$refs.resultsSection.scrollTop = this.$refs.resultsSection.scrollHeight;
					});
					this.$refs.resultsSection.scrollTop = this.$refs.resultsSection.scrollHeight;
				}
			}
		}
	};
</script>
<style>
	.el-table td, .el-table th {
		padding: 0.55rem 0 !important;
	}
	.el-input__inner {
		/*border-color: #0f7a8c !important;*/
	}
	.aml-dialog .el-dialog__header {
		background-color: #0f7a8c !important;

	}
	.aml-dialog .el-dialog__title, .aml-dialog .el-dialog__close { color: white !important; }

</style>
