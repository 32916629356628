<template>
	<div class="col-12 col-md-9">
		<div class="card card-chart">
			<div class="card-header">
				<h5 class="card-category">TOP ATTENDACE</h5>
				<div v-if="isLoading" style="top: 5px;right: 5px; position: absolute;">
					<span class="badge badge-primary">
						<small>LOADING</small> <i class="fas fa-spinner fa-spin"/>
					</span>
				</div>
			</div>
			<div class="card-body aml-height-275">
				<div class="table-responsive">
					<table class="table aml-font-11 ">
						<thead class="text-primary">
						<th class="text-left">STUDENT</th>
						<th class="text-center">ATTENDANCE</th>
						</thead>
						<tbody>
						<tr v-for="d in data" :key="d.id">
							<td class="">
								<div class="row">
									<span class="col-5">
										<el-image class="rounded rounded-circle" style="width: 100px; height: 100px" :src="d.photo" fit="cover"/>
									</span>
									<span class="col-6 ml-1">
										<h4 class="my-2">{{ d.name }}</h4>
										<h6><small1>SECTION: </small1> {{ d.section }}</h6>
										<div><small1>Student ID: </small1> {{ d.ref_id }}</div>
										<!--<div><small1>Card ID: {{ d.card_id }}</small1></div>-->
									</span>
								</div>
							</td>
							<td class="text-center">
								<h4>{{ d.attendance.toFixed(2) }} %</h4>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { ElImage } from "element-plus"
	export default {
		components: {
			ElImage,
		},
		props: [ 'data', 'isLoading' ],
		methods: {
		},
	};
</script>
<style></style>
